import { MemoizedSelector, createSelector } from '@ngrx/store';

import { FleetDto } from 'core/dtos';
import { GuidString } from 'core/models';
import * as fromFleetsReducer from '../reducers/fleets.reducer';
import * as fromVehicleFeature from '../reducers/index';

const selectFleetsState = createSelector(
  fromVehicleFeature.getVehiclesFeatureState,
  fromVehicleFeature.getFleetsState
);

export const selectFleetEntities = createSelector(selectFleetsState, fromFleetsReducer.getEntities);

export const selectAllFleets = createSelector(selectFleetsState, fromFleetsReducer.getFleets);

export const selectFleetsLoading = createSelector(selectFleetsState, fromFleetsReducer.getLoading);

export const selectFleetsLoaded = createSelector(selectFleetsState, fromFleetsReducer.getLoaded);

export const selectSelectedFleetId = createSelector(
  selectFleetsState,
  fromFleetsReducer.getSelectedFleetId
);

export const selectSelectedFleet = createSelector(
  selectFleetEntities,
  selectSelectedFleetId,
  (entities, fleetId) => entities[String(fleetId)]
);

export const selectFleetById = (id: GuidString): MemoizedSelector<object, FleetDto | undefined> =>
  createSelector(selectFleetEntities, entities => entities[String(id)]);
