import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { NavigationLayerCreateModel, NavigationLayerResponseModel } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { Observable, firstValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class NavigationLayerService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.NavigationLayers;
  protected apiUrl = environment.Services.MapManager;

  constructor(
    httpClient: HttpClient,
    store: Store<RootState>,
    protected readonly translate: AtsTranslationService
  ) {
    super(httpClient, store);
  }

  getNavigationLayers(): Observable<NavigationLayerResponseModel[]> {
    return this.get<NavigationLayerResponseModel[]>(`${this.servicePath}/NavigationLayerList`);
  }

  getNavigationLayerImage(navigationLayerId: GuidString): Observable<string> {
    return this.get<string>(`${this.servicePath}/${navigationLayerId}/downloadurl`);
  }

  uploadNavigationLayerImage(navigationLayerId: GuidString, file: Blob): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'image/png' });

    return this.get<string>(`${this.servicePath}/${navigationLayerId}/uploadurl/false`).pipe(
      switchMap(url => this.put<string>(url, file, { headers }))
    );
  }

  createNavigationLayer(
    newNavigationLayer: NavigationLayerCreateModel
  ): Observable<NavigationLayerResponseModel> {
    const formData = new FormData();
    newNavigationLayer.imgFile && formData.append('mapImage', newNavigationLayer.imgFile);

    formData.append('mapId', newNavigationLayer.mapId.toString());
    formData.append('vehicle_group', newNavigationLayer.vehicleGroup.toString());
    formData.append('resolution', newNavigationLayer.resolution?.toString() ?? '');
    formData.append('image_offset_x', '0');
    formData.append('image_offset_y', '0');
    formData.append('image_offset_rotation', '0');
    formData.append(
      'coordinate_system_offset_translation_x',
      newNavigationLayer.coordinateSystemOffset?.translation.x.toString() ?? '0'
    );
    formData.append(
      'coordinate_system_offset_translation_y',
      newNavigationLayer.coordinateSystemOffset?.translation.y.toString() ?? '0'
    );
    formData.append('coordinate_system_offset_scale_x', '1');
    formData.append('coordinate_system_offset_scale_y', '1');
    formData.append('coordinate_system_offset_rotation', '0');

    return this.post<NavigationLayerResponseModel>(
      `${this.servicePath}/postmapfromimage`,
      formData
    );
  }

  updateMapName(map: NavigationLayerResponseModel): Observable<NavigationLayerResponseModel> {
    return this.put<NavigationLayerResponseModel>(`${this.servicePath}`, map);
  }

  updateNavigationLayerOffset(navigationLayer: NavigationLayerCreateModel): Observable<void> {
    return this.put<void>(`${this.servicePath}/offset`, navigationLayer);
  }

  updateNavigationLayerOrder(layers: NavigationLayerResponseModel[]): Observable<void> {
    return this.put<void>(`${this.servicePath}/batch`, layers);
  }

  updateNavigationLayerBatchOffset(
    layers: NavigationLayerResponseModel[]
  ): Observable<NavigationLayerResponseModel[]> {
    return this.put<NavigationLayerResponseModel[]>(`${this.servicePath}/batchOffset`, layers);
  }

  deleteNavigationLayer(navigationLayer: NavigationLayerResponseModel): Observable<GuidString> {
    return this.delete<GuidString>(`${this.servicePath}/${navigationLayer.id}`);
  }

  async deleteNavigationLayerByMap(mapId: GuidString): Promise<void> {
    return firstValueFrom(this.delete<void>(`${this.servicePath}/byMap/${mapId}`));
  }
}
