import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  viewChild,
} from '@angular/core';
import { IpstServiceFeatures, getDefaultIpstServiceFeatures } from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { IpstAlertNowErrorMessagesViewModel } from './ipst-alertnow-error-messages.viewmodel';
import { AtsTranslationService } from 'core/services';
import { ToggleComponent } from '../toggle/toggle.component';

@Component({
  selector: 'app-ipst-alertnow-error-messages-view',
  templateUrl: './ipst-alertnow-error-messages-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowErrorMessagesViewComponent implements OnChanges, AfterViewInit {
  @Input() readonly ipstServiceFeatures: IpstServiceFeatures = getDefaultIpstServiceFeatures();
  @Input() ipstServiceFeaturesLoaded = false;

  toggle = { isToggledOn: false, dateUpdated: null };
  viewModel: IpstAlertNowErrorMessagesViewModel;
  toggleEl = viewChild(ToggleComponent);

  constructor(
    private readonly translationService: AtsTranslationService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.viewModel = this.generateViewModel();
  }

  ngAfterViewInit(): void {
    const header = document.createElement('h5');
    header.style.color = 'var(--ds-color-typography-neutral)';
    header.style.whiteSpace = 'pre';
    header.textContent = this.translationService
      .get('settings.functions.includeTuggerTrainErrors.titleRelevant')
      .toUpperCase();
    this.toggleEl()?.appendTextToHeaderElement(header);
  }

  ngOnChanges({ ipstServiceFeatures }: TypedChanges<IpstAlertNowErrorMessagesViewComponent>): void {
    if (ipstServiceFeatures?.currentValue) {
      this.viewModel = {
        ...this.viewModel,
        includeTuggerTrainErrors:
          objectHelper.cloneDeep(ipstServiceFeatures.currentValue.includeTuggerTrainErrorsToggle) ??
          this.viewModel.includeTuggerTrainErrors,
      };
      this.cdr.markForCheck();
    }
  }

  generateViewModel(): IpstAlertNowErrorMessagesViewModel {
    return {
      includeTuggerTrainErrors: { isToggledOn: false, dateUpdated: null },
    };
  }
}
