import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GuidString, ProcessChain } from 'core/models';

import * as ProcessChainActions from '../actions/process-chain.actions';

export const featureKey = 'processChains';

export interface ProcessChainState extends EntityState<ProcessChain> {
  loading: boolean;
  loaded: boolean;
  selectedProcessChainId: GuidString;
  errorMessage: string;
}

export const processChainAdapter: EntityAdapter<ProcessChain> = createEntityAdapter<ProcessChain>();

export const initialState: ProcessChainState = processChainAdapter.getInitialState({
  loading: false,
  loaded: false,
  selectedProcessChainId: '',
  errorMessage: '',
});

export const processChainReducer = createReducer(
  initialState,
  on(ProcessChainActions.loadProcessChains, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(ProcessChainActions.loadProcessChainsSuccess, (state, { processChains }) =>
    processChainAdapter.setAll(processChains, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(ProcessChainActions.loadProcessChainsFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(ProcessChainActions.addProcessChainSuccess, (state, { processChainCreated }) =>
    processChainAdapter.addOne(processChainCreated, state)
  ),

  on(ProcessChainActions.updateProcessChainSuccess, (state, { processChain }) =>
    processChainAdapter.updateOne(processChain, state)
  ),

  on(
    ProcessChainActions.activateProcessChainsSuccess,
    ProcessChainActions.deactivateProcessChainsSuccess,
    (state, { processChains }) =>
      processChainAdapter.updateMany(
        processChains.map(p => {
          return { id: p.id.toString(), changes: { isActive: p.isActive } };
        }),
        state
      )
  ),

  on(ProcessChainActions.deleteProcessChainSuccess, (state, { processChain }) =>
    processChainAdapter.removeOne(processChain.id.toString(), state)
  ),

  on(
    ProcessChainActions.addProcessChain,
    ProcessChainActions.updateProcessChain,
    ProcessChainActions.deleteProcessChain,
    ProcessChainActions.activateProcessChains,
    ProcessChainActions.deactivateProcessChains,
    state => ({
      ...state,
      errorMessage: '',
    })
  ),

  on(
    ProcessChainActions.addProcessChainFailure,
    ProcessChainActions.updateProcessChainFailure,
    ProcessChainActions.deleteProcessChainFailure,
    ProcessChainActions.activateProcessChainsFailure,
    ProcessChainActions.deactivateProcessChainsFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
    })
  ),

  on(ProcessChainActions.selectProcessChain, (state, { processChainId }) => ({
    ...state,
    selectedProcessChainId: processChainId,
  }))
);

export function reducer(state: ProcessChainState | undefined, action: Action): ProcessChainState {
  return processChainReducer(state, action);
}

export const { selectEntities, selectAll } = processChainAdapter.getSelectors();
export const getProcessChains = selectAll;
export const getEntities = selectEntities;
export const getLoading = (state: ProcessChainState): boolean => (state || initialState).loading;
export const getLoaded = (state: ProcessChainState): boolean => state.loaded;
export const getSelectedProcessChainId = (state: ProcessChainState): GuidString =>
  (state || initialState).selectedProcessChainId;
