<app-sidemenu-content-layout
  [pageHeading]="'workingAreaSettings.pageHeading'"
  [secondaryPageHeading]="selectedSetting?.label"
  [sidemenuHeading]="'shared.overview'">
  <app-tool-bar #toolbar toolbar></app-tool-bar>
  <ng-container *ngFor="let item of settings" sidemenu>
    <ds-list-item
      class="navigation-list-item"
      (click)="selectSetting(item)"
      [isSelected]="item === selectedSetting"
      [isSelectable]="true"
      [isHoverable]="true"
      [attr.data-cy]="item.label"
      >{{ item.label }}</ds-list-item
    >
  </ng-container>
  <router-outlet content></router-outlet>
</app-sidemenu-content-layout>
