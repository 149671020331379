import { TreeNode } from 'primeng/api';

export class TreeViewSelectedItem {
  constructor(
    public id: string,
    public node: TreeNode,
    public hasChildren: boolean,
    public hasParent: boolean
  ) {}
}
