import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectListOption } from '@bmw-ds/components';
import { select, Store } from '@ngrx/store';
import { EMPTY_GUID } from 'core/constants';
import { VehicleDto } from 'core/dtos';
import { GuidString, HelpToolFilterDto, Zone } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import * as fromRoot from 'store/index';
import { ErrorForwardingOptionListModel } from '../ipst-alert-now-help-tool/ipst-alert-now-help-tool.component';
@Component({
  selector: 'app-ipst-alert-now-help-tool-filters',
  templateUrl: './ipst-alert-now-help-tool-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './ipst-alert-now-help-tool-filters.component.scss',
})
export class IpstAlertNowHelpToolFiltersComponent implements OnChanges, OnInit, OnDestroy {
  @Input() reset = false;

  @Input() vehicles$: Observable<VehicleDto[]> = of([]);
  @Input() zones: (Zone | undefined)[] = [];
  @Input() errors: ErrorForwardingOptionListModel[] = [];
  @Output() readonly retrieveRecipientKeys = new EventEmitter<HelpToolFilterDto>();

  selectedVehicle: GuidString = EMPTY_GUID;
  selectedZone: GuidString = EMPTY_GUID;
  selectedErrorMessage = '';
  form: FormGroup;
  actionDisabled = true;
  disabledTooltip = 'test';
  vehicleSelectList: SelectListOption[] = [];
  vehicleList: VehicleDto[] = [];
  ngUnsubscribe = new Subject<void>();
  selectedWorkingArea?: GuidString;

  constructor(
    protected readonly atsTranslateService: AtsTranslationService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly cdRef: ChangeDetectorRef,
    private readonly fb: FormBuilder
  ) {
    this.disabledTooltip = this.atsTranslateService.get(
      'settings.ipstAlertNowSettings.helpTool.disabledErrorNotificationTooltipMessage'
    );
    this.form = this.createHelpToolFormGroup();
  }

  ngOnInit(): void {
    this.rootStore
      .pipe(select(fromRoot.selectSelectedWorkingAreaId), takeUntil(this.ngUnsubscribe))
      .subscribe(selectedWA => {
        this.selectedWorkingArea = selectedWA;

        this.cdRef.markForCheck();
      });
    this.vehicles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((vehicles: VehicleDto[]) => {
      this.vehicleList = vehicles;
      const groupedItems = vehicles.reduce((acc: { [key: string]: VehicleDto[] }, item) => {
        if (!acc[item.vehicleType]) {
          acc[item.vehicleType] = [];
        }
        acc[item.vehicleType].push(item);
        return acc;
      }, {});

      const groupedArray = Object.keys(groupedItems).map(type => groupedItems[type]);

      this.vehicleSelectList = groupedArray.map(group => {
        return {
          id: group[0].vehicleType.toString(),
          label: this.atsTranslateService.get(`enums.vehicleType.${group[0].vehicleType}`),
          group: true,
          children: group.map(item => {
            return {
              label: item.name,
              id: item.id.toString(),
            };
          }),
        };
      });
    });
  }

  ngOnChanges({ reset }: TypedChanges<IpstAlertNowHelpToolFiltersComponent>): void {
    if (reset?.currentValue !== reset?.previousValue) {
      this.form.reset({
        selectedErrorMessage: '',
        selectedVehicle: EMPTY_GUID,
        selectedZone: EMPTY_GUID,
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createHelpToolFormGroup(): FormGroup {
    return this.fb.group({
      selectedErrorMessage: ['', Validators.required],
      selectedVehicle: [EMPTY_GUID, Validators.required],
      selectedZone: [EMPTY_GUID, Validators.required],
    });
  }

  handleSelection(_event: SelectListOption | SelectListOption[] | null): void {
    const { selectedErrorMessage, selectedVehicle, selectedZone } = this.form.value;

    if (selectedErrorMessage && selectedVehicle !== EMPTY_GUID && selectedZone !== EMPTY_GUID) {
      this.retrieveRecipientKeys.emit({
        zoneId: selectedZone,
        vehicleId: selectedVehicle,
        errorNotificationType: selectedErrorMessage,
        workingAreaId: this.selectedWorkingArea ?? '',
        vehicleType: this.vehicleList.find(v => v.id === selectedVehicle)?.vehicleType,
      });
    }
  }
}
