import { ValueFormatterParams, ValueGetterFunc, ValueGetterParams } from 'ag-grid-community';
import { NO_VALUE_DEFAULT } from 'core/constants';
import { NavigationLayerListViewItem } from 'core/models';

export class MapGridHelper {
  static getMapIdValueGetterFn(): ValueGetterFunc<NavigationLayerListViewItem> {
    return ({ data }: ValueGetterParams<NavigationLayerListViewItem>): string => {
      if (!data) return NO_VALUE_DEFAULT;

      const { id, mapId } = data;
      return `${mapId};${id}`;
    };
  }

  static getMapIdFilterValueGetterFn(): ValueGetterFunc<NavigationLayerListViewItem> {
    return ({ data }: ValueGetterParams<NavigationLayerListViewItem>): string => {
      if (!data) return NO_VALUE_DEFAULT;
      return `${data.id}`;
    };
  }

  static mapIdFormatter({
    data,
  }: ValueFormatterParams<NavigationLayerListViewItem, string>): string {
    return data?.id.toString() ?? NO_VALUE_DEFAULT;
  }
}
