import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import * as fromRoot from '../store';

@Injectable({
  providedIn: 'root',
})
export class SetOrganizationWorkingAreaGuard implements CanActivate {
  constructor(private readonly store: Store<fromRoot.RootState>, private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const orgName = decodeURI(route.params.organization);
    const waName = decodeURI(route.params.workingArea);
    return this.store.pipe(
      select(fromRoot.selectAllOrganizations),
      filter(x => x != null && x.length > 0),
      take(1),
      switchMap(async organizations => {
        const requestedOrganization = organizations.find(org => org.name === orgName);
        const requestedWorkingArea = requestedOrganization?.workAreas?.find(
          wa => wa.name === waName
        );
        const isWorkAreaValid = !requestedOrganization || !requestedWorkingArea;
        if (!requestedOrganization || !requestedWorkingArea) {
          await this.router.navigateByUrl('/wa-not-found');
        } else {
          this.store.dispatch(
            fromRoot.loadWorkingAreasSuccess({
              workingAreas: requestedOrganization.workAreas,
            })
          );
          this.store.dispatch(
            fromRoot.selectOrganization({ organizationId: requestedOrganization.id })
          );

          this.store
            .pipe(select(fromRoot.selectSelectedWorkingArea), take(1))
            .subscribe(workArea => {
              if (workArea?.id !== requestedWorkingArea.id) {
                this.store.dispatch(
                  fromRoot.selectWorkingArea({ workingAreaId: requestedWorkingArea.id })
                );
              }
            });
        }
        return !isWorkAreaValid;
      }),
      map(isWorkAreaValid => isWorkAreaValid)
    );
  }
}
