<div class="flex-stretch-vertically">
  <div *ngIf="pageHeading || hasToolbar">
    <app-page-header [pageHeading]="pageHeading" [secondaryPageHeading]="secondaryPageHeading">
      <app-tool-bar *ngIf="hasToolbar" [showItems]="showToolbarItems">
        <ng-content select="[tool-bar-content]"></ng-content>
      </app-tool-bar>
    </app-page-header>
  </div>
  <div
    class="body-area"
    [class.edit]="(isEditMode$ | async) && !isNewLayout"
    [class.view]="(isEditMode$ | async) !== true && !isNewLayout"
    [class.editView]="isNewLayout">
    <ng-content select="[base-page-content]"></ng-content>
  </div>
</div>
