<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-5 pt-18x center-horizontal">
      <img
        src="./assets/global/notauthorized_icon.png"
        alt="Not authorized"
        style="max-width: 220px" />
    </div>
  </div>

  <div class="row justify-content-center">
    <hr class="col-5 lineSep" />
  </div>

  <div class="row justify-content-center">
    <div class="col-5 center-horizontal title-md">
      <p data-cy="notAuthorizedMessage">
        {{ 'shared.notAuthorized.message' | translate }}<br />{{
          'shared.notAuthorized.contactMessage' | translate
        }}
      </p>
    </div>

    <div class="row justify-content-center pt-7x">
      <div class="col-5 center-horizontal">
        <a ds-button href="/welcome" class="redirect-button">
          {{ 'shared.pageNotFound.redirectButtonText' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
