import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { DsSelectLabellingConfiguration } from '@bmw-ds/components/ds-interfaces/select/config.interface';
import { OpcuaDeviceResponseModel } from 'core/dtos';
import {
  ATSMenuItem,
  BasicMenuItem,
  EvacuationDeviceTriggerItem,
  EvacuationModeType,
} from 'core/models';
import { AtsTranslationService, EditBarService } from 'core/services';
import { TableComponent } from 'library/components/table/table.component';
import { TableColumns } from 'library/models';

@Component({
  selector: 'app-wago-evacuation-devices-config-triggers-table',
  templateUrl: './wago-evacuation-devices-config-triggers-table.component.html',
  styleUrls: ['wago-evacuation-devices-config-triggers-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WagoEvacuationDevicesConfigTriggersTableComponent implements OnInit, OnChanges {
  @ViewChild(TableComponent) child!: TableComponent<EvacuationDeviceTriggerItem>;
  @Input() hasEvacuationDeviceUpdatePermission = false;
  @Input() device: OpcuaDeviceResponseModel | undefined;
  @Input() tableContent: EvacuationDeviceTriggerItem[] = [];
  @Output() readonly isChanged = new EventEmitter();

  displayedColumns: TableColumns[] = [
    { field: 'node', type: 'text', size: 150 },
    {
      field: 'value',
      type: 'component',
      size: 140,
    },
    { field: 'empty', type: 'text', size: 20, isHidden: true },
  ];

  nodeDropdownList = {};
  telemetryTypeNodes: EvacuationModeType[] | undefined = [];
  unselectedTelemetryTypeNodes: EvacuationModeType[] | undefined = [];
  selectedNewRowNodes: SelectListOption | SelectListOption[] | null = [];
  menuItems: ATSMenuItem[] = [];
  labellingConfig: DsSelectLabellingConfiguration = {
    placeholder: this.translationService.get('settings.functions.placeHolders.addNodes'),
  };

  constructor(
    private readonly editBarService: EditBarService,
    private readonly translationService: AtsTranslationService
  ) {}

  ngOnInit(): void {
    this.menuItems = this.createMenuItems();
  }

  ngOnChanges({
    device,
    tableContent,
  }: TypedChanges<WagoEvacuationDevicesConfigTriggersTableComponent>): void {
    if (tableContent && tableContent.currentValue && device && device.currentValue) {
      this.updateDeviceChanges(tableContent.currentValue, device.currentValue);
    } else if (tableContent && tableContent.currentValue && this.device) {
      this.updateDeviceChanges(tableContent.currentValue, this.device);
    } else if (device && device.currentValue) {
      this.updateDeviceChanges(this.tableContent, device.currentValue);
    }
  }

  updateDeviceChanges(
    tableContent: EvacuationDeviceTriggerItem[],
    device: OpcuaDeviceResponseModel
  ): void {
    this.editBarService.setIsNewObject(true);
    this.telemetryTypeNodes = device.nodes
      .filter(i => i.type === 'Telemetry')
      .map(data => {
        return { name: data.name, value: data.name };
      });
    tableContent.forEach((item: EvacuationDeviceTriggerItem) => {
      this.getNodeDefaultValue(item.node, 'value');
    });
  }

  createMenuItems(): ATSMenuItem[] {
    return [
      this.createMenuItem(
        'shared.treeTable.actionMenu.delete',
        this.onDeleteSelectedItem.bind(this),
        true,
        !this.hasEvacuationDeviceUpdatePermission
      ),
    ];
  }

  onDeleteSelectedItem(): void {
    const index = this.tableContent.findIndex(i => i.node === this.child.selectedRow?.node);
    this.tableContent.splice(index, 1);
    this.isChanged.emit();
  }

  onChangeTriggerValue(value: string): void {
    if (this.child.selectedRow) {
      this.child.selectedRow.value = value;
    }
  }

  getNodeDefaultValue(nodeName: string, columnName: string): string {
    const selectedNode = this.device?.nodes.find(i => i.name === nodeName);
    if (selectedNode && selectedNode.values.length > 0) {
      this.nodeDropdownList[nodeName + '_' + columnName] = this.fillEvacuationModeTypes(
        selectedNode.values
      );

      return selectedNode.values[0];
    }

    return '';
  }

  fillEvacuationModeTypes(values: string[]): EvacuationModeType[] {
    return values.map(data => {
      return { name: data, value: data };
    });
  }

  onNewRowItemIsOpen(value: boolean): void {
    if (value) {
      const content = this.tableContent;
      this.unselectedTelemetryTypeNodes = this.telemetryTypeNodes?.filter(function (item) {
        return !content.some(i => i.node === item.value);
      });
    } else if (!value && this.selectedNewRowNodes !== null) {
      this.selectedNewRowNodes.forEach((selectedItem: SelectListOption) => {
        const newItem = new EvacuationDeviceTriggerItem(
          selectedItem.label,
          this.getNodeDefaultValue(selectedItem.label, 'value')
        );

        this.tableContent.push(newItem);
        this.isChanged.emit();
      });

      this.selectedNewRowNodes = null;
    }
  }

  onNewRowItemValue(values: SelectListOption | SelectListOption[] | null): void {
    this.selectedNewRowNodes = values;
  }

  createMenuItem(key: string, command: Function, visible = true, disabled = false): BasicMenuItem {
    return {
      label: this.translationService.get(key),
      key,
      command,
      visible: visible,
      disabled,
    };
  }
}
