/* eslint-disable max-lines */
/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { VehicleMapDataService } from 'core/api-services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import * as VehicleMapsDataActions from '../actions/vehicle-map-data.actions';

@Injectable()
export class VehicleMapsDataEffects {
  loadVehicleMapData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.loadVehicleMapData),
      concatMap(() =>
        this.vehicleMapDataService.loadVehicleMapData().pipe(
          map(vehicleMapData =>
            VehicleMapsDataActions.loadVehicleMapDataSuccess({ vehicleMapData })
          ),
          catchError(errorMessage =>
            of(VehicleMapsDataActions.loadVehicleMapDataFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadVehicleMapDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.loadVehicleMapDataFailure),
        tap(() => {
          this.toastService.createErrorToast('mapData.actions.loadVehicleMapDataFailure');
        })
      ),
    { dispatch: false }
  );

  uploadVdaVehicleMapData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.uploadVdaVehicleMapData),

      concatMap(state =>
        this.vehicleMapDataService
          .uploadVdaVehicleMapData(state.uploadVdaVehicleMapDataRequest)
          .pipe(
            switchMap(vehicleMapData => {
              return [
                VehicleMapsDataActions.uploadVehicleMapDataSuccess({
                  vehicleMapData,
                }),
              ];
            }),
            catchError(errorMessage =>
              of(VehicleMapsDataActions.uploadVehicleMapDataFailure({ errorMessage }))
            )
          )
      )
    )
  );

  uploadVehicleMapData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.uploadRosVehicleMapData),

      concatMap(state =>
        this.vehicleMapDataService
          .uploadRosVehicleMapData(state.fromVehicleId, state.vehicleMapData)
          .pipe(
            map(vehicleMapData =>
              VehicleMapsDataActions.uploadVehicleMapDataSuccess({ vehicleMapData })
            ),
            catchError(errorMessage =>
              of(VehicleMapsDataActions.uploadVehicleMapDataFailure({ errorMessage }))
            )
          )
      )
    )
  );

  uploadVehicleMapDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.uploadVehicleMapDataFailure),
        tap(() => {
          this.toastService.createErrorToast('mapData.actions.uploadVehicleMapDataFailure');
        })
      ),
    { dispatch: false }
  );

  massEnableMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.massEnableMap),
      concatMap(state =>
        this.vehicleMapDataService.massEnableMap(state.request).pipe(
          map(response =>
            VehicleMapsDataActions.massEnableMapSuccess({
              response,
            })
          ),
          catchError(errorMessage =>
            of(VehicleMapsDataActions.downloadVehicleMapDataFailure({ errorMessage }))
          )
        )
      )
    )
  );

  downloadVehicleMapData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.downloadVehicleMapData),
      concatMap(state =>
        this.vehicleMapDataService.downloadVehicleMapData(state.downloadRequest).pipe(
          map(() =>
            VehicleMapsDataActions.downloadVehicleMapDataSuccess({
              vehicleName: state.downloadRequest.vehicleName ?? '',
            })
          ),
          catchError(errorMessage =>
            of(VehicleMapsDataActions.downloadVehicleMapDataFailure({ errorMessage }))
          )
        )
      )
    )
  );

  downloadVehicleMapDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.downloadVehicleMapDataSuccess),
        tap(vehicleName => {
          this.toastService.createSuccessToast(
            'mapData.vehicleMapDataDownloadDialog.downloadSuccess',
            vehicleName
          );
        })
      ),
    { dispatch: false }
  );

  downloadVehicleMapDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.downloadVehicleMapDataFailure),
        tap(() => {
          this.toastService.createErrorToast('mapData.vehicleMapDataDownloadDialog.downloadFailed');
        })
      ),
    { dispatch: false }
  );

  previewDeleteVehicleMapData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.previewDeleteVehicleMapData),

      concatMap(state =>
        this.vehicleMapDataService.getVehicleIdsByMapData(state.vehicleMapDataId).pipe(
          map(vehicleIds =>
            VehicleMapsDataActions.previewDeleteVehicleMapDataSuccess({
              response: {
                vehicles: vehicleIds.length,
              },
            })
          ),
          catchError(errorMessage =>
            of(VehicleMapsDataActions.loadVehicleMapDataFailure({ errorMessage }))
          )
        )
      )
    )
  );

  downloadMultiVehiclesMapData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.downloadMultiVehiclesMapData),
      concatMap(state =>
        this.vehicleMapDataService.downloadMultiVehiclesMapData(state.downloadRequest).pipe(
          map(response =>
            VehicleMapsDataActions.downloadMultiVehiclesMapDataSuccess({
              response,
            })
          ),
          catchError(errorMessage =>
            of(VehicleMapsDataActions.downloadVehicleMapDataFailure({ errorMessage }))
          )
        )
      )
    )
  );

  deleteVehicleMapData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.deleteVehicleMapData),

      concatMap(({ vehicleMapData }) =>
        this.vehicleMapDataService.deleteVehicleMapData(vehicleMapData).pipe(
          map(() =>
            VehicleMapsDataActions.deleteVehicleMapDataSuccess({
              vehicleMapData: vehicleMapData,
            })
          ),
          catchError(errorMessage =>
            of(VehicleMapsDataActions.deleteVehicleMapDataFailure({ errorMessage }))
          )
        )
      )
    )
  );

  deleteVehicleMapDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.deleteVehicleMapDataSuccess),
        tap(response => {
          this.toastService.createSuccessToast('mapData.actions.deleteVehicleMapDataSuccess', {
            name: response.vehicleMapData.name,
          });
        })
      ),
    { dispatch: false }
  );

  deleteVehicleMapDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.deleteVehicleMapDataFailure),
        tap(() => {
          this.toastService.createErrorToast('mapData.actions.deleteVehicleMapDataFailure');
        })
      ),
    { dispatch: false }
  );

  resetVehicleMapDataActionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        VehicleMapsDataActions.uploadVehicleMapDataFailure,
        VehicleMapsDataActions.uploadVehicleMapDataSuccess
      ),
      map(() => VehicleMapsDataActions.resetVehicleMapDataActionStatus())
    )
  );

  getVehicleIdsByMapData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleMapsDataActions.getVehicleIdsByMapData),
      concatMap(state =>
        this.vehicleMapDataService.getVehicleIdsByMapData(state.vehicleMapDataId).pipe(
          map(vehicleIds => VehicleMapsDataActions.getVehicleIdsByMapDataSuccess({ vehicleIds })),
          catchError(errorMessage =>
            of(VehicleMapsDataActions.loadVehicleMapDataFailure({ errorMessage }))
          )
        )
      )
    );
  });

  enableDisabledMaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.enableDisabledMaps),

      concatMap(({ vehicleId, mapToEnable: mapToEnable }) =>
        this.vehicleMapDataService.enableDisabledMaps(vehicleId, mapToEnable).pipe(
          map(() =>
            VehicleMapsDataActions.enableDisabledMapsSuccess({
              vehicleId,
              mapToEnable: mapToEnable,
            })
          ),
          catchError(errorMessage =>
            of(VehicleMapsDataActions.enableDisabledMapsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  enableDisabledMapsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.enableDisabledMapsSuccess),
        tap(response => {
          this.toastService.createSuccessToast('shared.actions.enableDisabledMapSuccess', {
            name: response.mapToEnable.mapId,
          });
        })
      ),
    { dispatch: false }
  );

  enableDisabledMapsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.enableDisabledMapsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.enableDisabledMapFailure');
        })
      ),
    { dispatch: false }
  );

  deleteDisabledMaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMapsDataActions.deleteDisabledMaps),

      concatMap(({ vehicleId, mapToDelete }) =>
        this.vehicleMapDataService.deleteDisabledMaps(vehicleId, mapToDelete).pipe(
          map(() =>
            VehicleMapsDataActions.deleteDisabledMapsSuccess({
              vehicleId,
              mapToDelete: mapToDelete,
            })
          ),
          catchError(errorMessage =>
            of(VehicleMapsDataActions.deleteDisabledMapsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  deleteDisabledMapsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.deleteDisabledMapsSuccess),
        tap(response => {
          this.toastService.createSuccessToast('shared.actions.deleteDisabledMapSuccess', {
            name: response.mapToDelete.mapId,
          });
        })
      ),
    { dispatch: false }
  );

  deleteDisabledMapsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleMapsDataActions.deleteDisabledMapsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.deleteDisabledMapFailure');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly vehicleMapDataService: VehicleMapDataService,
    private readonly toastService: ToastService
  ) {}
}
