<ngx-loading-bar height="5px" [includeSpinner]="false"></ngx-loading-bar>

<app-confirm-modal></app-confirm-modal>

<header>
  <app-header-bar
    [closePanel]="closeNotificationPanelFlag"
    (openNotificationPanel)="onReceiveOpenNotificationPanel($event)"></app-header-bar>
</header>

<div class="container-fluid g-0 stretch-height">
  <div class="row stretch-height">
    <div class="col layout-container">
      <app-information-bar></app-information-bar>
      <main class="main" [class.main-content-open]="openNotificationPanelFlag">
        <app-sidemenu class="welcome-sidemenu"></app-sidemenu>
        <div class="flex-router-outlet-wrapper p-6x pt-0x"><router-outlet></router-outlet></div>
      </main>

      <app-notification-panel-container
        *ngIf="!disableNotificationPanel && openNotificationPanelFlag"
        [openPanel]="openNotificationPanelFlag"
        (sendClosePanel)="onSendClosePanel($event)"></app-notification-panel-container>
      <footer *ngIf="showFooter && isEditMode">
        <app-edit-bar></app-edit-bar>
      </footer>
    </div>
  </div>
</div>
