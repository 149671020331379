import { GraphNodeShape, GraphParkingChargingType } from 'core/models';
import { MapColors, MapLabelColors, NodeColors } from 'library/styles';
import { RotationStyle } from '../map-item-rotation-container.graphic';

export const SHOW_WAYPOINT_LABELS = true;
export const MORE_OPTIONS_INDEX = 3;
export const MAX_ACTION_ICON_LIMIT = 4;

export const EdgeStyle = {
  EdgeSize: 0.1,
  EdgeAlpha: 0.6,
  ArrowAlpha: 1,
  ArrowSize: 0.25,
  ArrowSmallSize: 0.15,
  ArrowDistance: 1.5,
};

export const NodeStyle = {
  WaypointSize: 2,
  NodeSize: 3.5,
  NodeAlpha: 0.8,
  NodeScale: 0.05,
  MinScaleFactor: 1.54,
  NodeOutlineAlignment: 2,
  NodeOutlineAlpha: 1,
  NodeOutlineWidth: 0.6,
  NodeSelectionSize: 0.1,

  NodeRotation: 4.71239,
  NodeBorderWidth: 0.5,
  NodeBorderColorSelected: MapColors.BorderSelected,
  NodeFooterBackgroundColor: NodeColors.Footer,
  NodeHeaderBackgroundColor: MapColors.Base,
  NodeHeaderX: 16.3,
  NodeHeaderY: 9.5,
  NodeHeaderWidth: 1.7,
  NodeHeaderLength: 19,
  NodeHeaderRadius: 2,
  NodeOutlineX: -17.4,
  NodeOutlineY: -10.3,
  NodeOutlineWidth2: 35.1,
  NodeOutlineLength: 20.5,
  NodeOutlineRadius: 2.5,
  NodeLabelOffset: 0.6,
  NodeWidth: 0.3,

  NodeActionOutlineX: -8.3,
  NodeActionOutlineY: -8.3,
  NodeSingularActionOutline: -5,
  NodeActionOutlineLength: 16.5,
  SingleIconBackground: 10,
  MultiIconBackground: 16.5,
};

export const NodeStyles = {
  WaypointSize: 2,
  NodeSize: 3.5,
  NodeAlpha: 0.8,
  NodeScale: 0.05,
  MinScaleFactor: 1.54,
  NodeOutlineAlignment: 2,
  NodeOutlineAlpha: 1,
  NodeOutlineWidth: 0.6,
  NodeSelectionSize: 0.1,
};

export const ActionNodePositions = {
  CenterPosition: 72,
  TwoActionsX1: 135,
  TwoActionsX2: 5,
  FourActionsX1: 130,
  FourActionsY1: 120,
  FourActionsX2: 10,
  FourActionsY2: 120,
  FourActionsX3: 130,
  FourActionsY3: 10,
  FourActionsX4: 10,
  FourActionsY4: 10,
};

export const NodeBgStyle = {
  NodeBgX: 10,
  NodeBgY: -10,
  NodeBgWidth1: 7.5,
  NodeBgWidth2: 2.5,
  NodeBgHeight: 20,
  NodeBgWidthRadius1: 2,
  NodeBgWidthRadius2: 0,
  NodeTextColor: MapLabelColors.WhiteText,
  NodeTextAlignX: -11.5,
  NodeTextAlignY: -6,
  NodeScale: 0.1,
};

export const NodeArrowStyle = {
  Offset: 0.35,
  SquareOffset: 0.6,
  Size: 0.14,
  Scale: 10,
  MinScaleFactor: 4,
  MinResolution: 0.1,
  Color: NodeColors.Arrow,
  Orientation: 90,
};

export const NodeName = {
  background: MapLabelColors.Background,
  alpha: 0.8,
  padding: 0.02,
  scale: 0.005,
  yOffset: 0.35,
  cornerRadius: 0.05,
};

export const NodeRotation = {
  SquareOffset: 1.2,
};

export const NodeRotationStyle: RotationStyle = {
  iconScale: 150,
  offset: 0.9,
  labelYOffset: -0.17,
  textSize: 0.005,
  opacity: 0.95,
};

export const NodeShape: Record<GraphParkingChargingType, GraphNodeShape> = {
  [GraphParkingChargingType.None]: GraphNodeShape.SmallRound,
  [GraphParkingChargingType.Fueling]: GraphNodeShape.MediumRound,
  [GraphParkingChargingType.Charging]: GraphNodeShape.MediumRound,
  [GraphParkingChargingType.Parking]: GraphNodeShape.MediumRound,
};
