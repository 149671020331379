import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  CollectivePauseDto,
  CollectivePauseUpdateDto,
  EvacuationModeDto,
  FleetManagerFeatures,
  ShutdownModeDto,
  ShutdownRequestDto,
} from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class FleetSettingsService extends TenantHttpClient {
  protected apiUrl = environment.Services.FleetManager;
  private readonly servicePath = API_SERVICES.Features;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getShutdownValues(): Observable<ShutdownRequestDto[]> {
    return this.get<ShutdownRequestDto[]>(API_SERVICES.VehicleShutdownStatus);
  }

  activateShutdown(numberOfVehiclesToExclude: number): Observable<ShutdownRequestDto> {
    return this.put<ShutdownRequestDto>(
      `${API_SERVICES.VehicleShutdownAll}/${numberOfVehiclesToExclude}`
    );
  }

  cancelShutdown(): Observable<ShutdownRequestDto> {
    return this.put<ShutdownRequestDto>(API_SERVICES.VehicleCancelShutdown);
  }

  // From here on is V2 toggles
  getFleetManagerFeaturesV2(): Observable<FleetManagerFeatures> {
    return this.get<FleetManagerFeatures>(`${this.servicePath}/v2`);
  }

  updateEvacuationMode(value: boolean): Observable<EvacuationModeDto> {
    return this.put<EvacuationModeDto>(`${this.servicePath}/EvacuationMode`, {
      isToggledOn: value,
    });
  }

  updateShutdownModeV2(value: ShutdownModeDto): Observable<ShutdownModeDto> {
    return this.put<ShutdownModeDto>(`${this.servicePath}/ShutdownMode`, value);
  }

  updateCollectivePause(value: CollectivePauseUpdateDto): Observable<CollectivePauseDto> {
    return this.put<CollectivePauseDto>(`${this.servicePath}/CollectivePause`, value);
  }
}
