import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { PillarsGridDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class PillarsGridService extends TenantHttpClient {
  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  private readonly servicePath = API_SERVICES.PillarsGrid;

  protected apiUrl = environment.Services.MapManager;

  getPillarsGridByMapId(mapId: GuidString): Observable<PillarsGridDto> {
    return this.get<PillarsGridDto>(`${this.servicePath}/map/${mapId.toString()}`);
  }

  createPillarsGrid(pillarsGrid: PillarsGridDto, mapId: GuidString): Observable<PillarsGridDto> {
    return this.post<PillarsGridDto>(`${this.servicePath}/map/${mapId}/`, pillarsGrid);
  }

  updatePillarsGrid(pillarsGrid: PillarsGridDto): Observable<PillarsGridDto> {
    return this.put<PillarsGridDto>(`${this.servicePath}`, pillarsGrid);
  }

  deletePillarsGrid(pillarsGrid: PillarsGridDto): Observable<void> {
    return this.delete(`${this.servicePath}/${pillarsGrid.id}`);
  }
}
