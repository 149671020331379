import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { FailureCommentDto, MissionFailureReasonDto } from 'core/dtos';
import { GuidString, MissionStatus, MissionTrace, VehicleType } from 'core/models';

import { MissionFilterModel } from '../../../modules/jobs/mission-monitoring/models/mission.models';
import * as MissionTraceActions from '../actions/mission-trace.actions';

export const featureKey = 'missionTrace';

export interface MissionTraceState extends EntityState<MissionTrace> {
  loading: boolean;
  loaded: boolean;
  selectedMissionTraceId: GuidString;
  filterActiveMission: MissionFilterModel;
  // filterHistoryMission: JobGql.MissionTraceArchiveFilterInput | null;
  errorMessage: string;
  missionFailureReasons: MissionFailureReasonDto[];
  failureComments: FailureCommentDto[];
  selectedVehicleTypesFilter: VehicleType[];
}
export const missionTraceAdapter: EntityAdapter<MissionTrace> = createEntityAdapter<MissionTrace>();

export const initialState: MissionTraceState = missionTraceAdapter.getInitialState({
  loading: false,
  loaded: false,
  selectedMissionTraceId: '',
  filterActiveMission: {} as MissionFilterModel,
  // filterHistoryMission: {} as JobGql.MissionTraceArchiveFilterInput | null,
  failureComments: [],
  errorMessage: '',
  missionFailureReasons: [],
  selectedVehicleTypesFilter: [],
});

export const missionTraceReducer = createReducer(
  initialState,

  on(
    MissionTraceActions.loadMissionTraces,
    MissionTraceActions.loadMissionFailureReasons,
    MissionTraceActions.addCommentToMission,
    MissionTraceActions.loadMissionTraceById,
    MissionTraceActions.loadFailureCommentsByIdList,
    MissionTraceActions.loadAndSelectMissionTraceById,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),

  on(MissionTraceActions.loadMissionTracesSuccess, (state, { missionTrace }) =>
    missionTraceAdapter.setAll(missionTrace, {
      ...state,
      loading: false,
    })
  ),

  on(MissionTraceActions.loadActiveToursSuccess, (state, { missionTrace }) =>
    missionTraceAdapter.upsertMany(missionTrace, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(MissionTraceActions.loadMissionFailureReasonsSuccess, (state, { missionFailureReasons }) => ({
    ...state,
    missionFailureReasons: missionFailureReasons,
    loading: false,
  })),

  on(MissionTraceActions.loadFailureCommentsByIdListSuccess, (state, { failureComments }) => ({
    ...state,
    failureComments: failureComments,
    loading: false,
  })),

  on(MissionTraceActions.loadMissionTracesFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(MissionTraceActions.loadFailureCommentsByIdListFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(MissionTraceActions.loadAndSelectMissionTraceByIdSuccess, (state, { missionTrace }) =>
    missionTraceAdapter.upsertOne(missionTrace, {
      ...state,
      selectedMissionTraceId: missionTrace.id,
      loading: false,
    })
  ),

  on(MissionTraceActions.loadMissionTraceByIdSuccess, (state, { missionTrace }) => {
    if (
      missionTrace.status === MissionStatus.Aborted ||
      missionTrace.status === MissionStatus.Completed
    ) {
      return {
        ...state,
        loading: true,
      };
    }
    return missionTraceAdapter.upsertOne(missionTrace, {
      ...state,
      loading: false,
    });
  }),

  on(MissionTraceActions.selectMissionTrace, (state, { missionTraceId }) => ({
    ...state,
    selectedMissionTraceId: missionTraceId,
  })),

  on(MissionTraceActions.retryMission, state => ({
    ...state,
    loading: true,
  })),

  on(
    MissionTraceActions.retryMissionSuccess,
    MissionTraceActions.addCommentToMissionSuccess,
    state => ({
      ...state,
      loading: false,
    })
  ),

  on(
    MissionTraceActions.retryMissionFailure,
    MissionTraceActions.loadAndSelectMissionTraceByIdFailure,
    MissionTraceActions.loadMissionFailureReasonsFailure,
    MissionTraceActions.addCommentToMissionFailure,
    MissionTraceActions.loadMissionTraceByIdFailure,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      errorMessage,
    })
  ),

  on(MissionTraceActions.signalRAddMissionTrace, (state, { missionTrace }) =>
    missionTraceAdapter.addOne(missionTrace, state)
  ),

  on(MissionTraceActions.signalRUpdateMissionTrace, (state, { missionTrace }) =>
    missionTraceAdapter.upsertOne(missionTrace, state)
  ),

  on(
    MissionTraceActions.signalRDeleteMissionTrace,
    MissionTraceActions.removeCompletedMissionTrace,
    (state, { missionTrace }) => missionTraceAdapter.removeOne(missionTrace.id.toString(), state)
  ),

  on(MissionTraceActions.setSelectedVehicleTypesFilter, (state, { selectedVehicleTypesFilter }) => {
    return {
      ...state,
      selectedVehicleTypesFilter,
    };
  }),

  on(MissionTraceActions.setActiveMissionFilters, (state, { filterActiveMission }) => ({
    ...state,
    filterActiveMission,
  }))
);

export function reducer(state: MissionTraceState | undefined, action: Action): MissionTraceState {
  return missionTraceReducer(state, action);
}

export const { selectEntities, selectAll } = missionTraceAdapter.getSelectors();

export const getLoaded = (state: MissionTraceState): boolean => state.loaded;
export const getLoading = (state: MissionTraceState): boolean => state.loading;

export const getAllMissionFailureReasons = (state: MissionTraceState): MissionFailureReasonDto[] =>
  state.missionFailureReasons;

export const getSelectedMissionTraceId = (state: MissionTraceState): GuidString =>
  (state || initialState).selectedMissionTraceId;
export const getEntities = selectEntities;
export const getAllMissionTraces = selectAll;

export const getMissionActiveFilters = (state: MissionTraceState): MissionFilterModel =>
  state.filterActiveMission;

export const getFailureCommentsOfActiveMissionTraces = (
  state: MissionTraceState
): FailureCommentDto[] => state.failureComments;

// export const getMissionHistoryFilters = (
//   state: MissionTraceState
// ): JobGql.MissionTraceArchiveFilterInput | null => state.filterHistoryMission;

export const getSelectedVehicleTypesFilter = (state: MissionTraceState): VehicleType[] => {
  return state.selectedVehicleTypesFilter;
};
