<h4 class="pt-4x ps-4x pe-4x m-0x">
  {{ 'notificationsPanel.title' | translate }}
  <i
    class="iwp-icon-gen_info"
    ds-tooltip="{{ 'notificationsPanel.info' | translate }}"
    [dsTooltipConfig]="{ origin: 'bottom', width: '200px' }"></i>
  <i class="iwp-icon-gen_close" data-cy="notificationCard-gen-close" (click)="onClose()"></i>
</h4>

<div class="p-4x panel-content">
  <ds-box
    *ngFor="let notification of notificationViewModelList"
    data-cy="notificationCard"
    (click)="onClick(notification)"
    [style]="{ 'border-color': notification.isNew ? '#e82626' : '#c2c2c2' }"
    class="notification-card mb-4x">
    <ds-box-content [isClickable]="true">
      <div class="notification-details-div">
        <label class="name" data-cy="notificationName">{{ notification.name }}</label>
        <label class="location" data-cy="notificationLocation">{{ notification.location }}</label>
      </div>

      <div class="notification-details-div">
        <label class="notification-details" data-cy="missionName">{{
          notification.missionName
        }}</label>
        <label class="dueDate" data-cy="missionDueDate">{{
          notification?.dueTime | formatVehicleDueDate
        }}</label>
      </div>

      <label
        *ngFor="let error of notification.errors"
        class="error"
        data-cy="notificationDescription"
        ds-tooltip="{{ error.description }}"
        [dsTooltipConfig]="{ width: '200px' }"
        >{{ error.description }}</label
      >
    </ds-box-content>
  </ds-box>
</div>
