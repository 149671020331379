<div class="boxcontainer">
  <ng-container>
    <app-ipst-groups-workarea-edit
      *ngFor="
        let workingAreaControl of workAreaSettingsArray?.controls;
        index as i;
        trackBy: $any(trackByWorkingAreaId)
      "
      [formControl]="$any(workingAreaControl)"
      ngDefaultControl>
    </app-ipst-groups-workarea-edit>
  </ng-container>
</div>
