import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoPermissionComponent {
  readonly imageUrl = '/assets/global/common/no_permissions.svg';
}
