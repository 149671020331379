import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HardwareVersion, Poi, VehicleType } from 'core/models';
import { AtsTranslationService, EditBarService } from 'core/services';

import { NodeDto, NodeGroupDto, RouteConfigurationDto } from 'core/dtos';
import { SecondarySideMenuService } from 'core/services/secondary-sidemenu.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuggerTrainSendStepInputModel } from '../vehicle-actions/vehicle-actions.models';
import { BaseSendStepDirective } from './base-send-step.directive';
import { VehicleSendStepModel } from './vehicle-send-steps.viewmodel';

export interface SendStepModel {
  vehicleSteps: VehicleSendStepModel[];
  tuggerTrainSteps?: TuggerTrainSendStepInputModel;
  isValid: boolean;
}

@Component({
  selector: 'app-vehicle-send-steps',
  templateUrl: './vehicle-send-steps.component.html',
  styleUrls: ['./vehicle-send-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleSendStepsComponent
  extends BaseSendStepDirective
  implements OnInit, OnChanges, OnDestroy
{
  @Input() vehicleType: VehicleType = VehicleType.UnitLoad;
  @Input() hardwareVersion?: HardwareVersion;
  @Input() allPois: Poi[] = [];
  @Input() allNodes?: NodeDto[];
  @Input() nodeGroups?: NodeGroupDto[];
  @Input() allRouteConfigurations: RouteConfigurationDto[] = [];

  @Output() readonly populateVehicleSendSteps = new EventEmitter<SendStepModel>();

  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    protected readonly formBuilder: UntypedFormBuilder,
    protected readonly translate: AtsTranslationService,
    private readonly editBarService: EditBarService,
    private readonly secondarySideMenuService: SecondarySideMenuService,
    protected readonly cdRef: ChangeDetectorRef
  ) {
    super(formBuilder, translate, cdRef);
  }

  ngOnInit(): void {
    this.stepItems = this.createStepMenuItems(this.vehicleType);
    this.waypointItems = this.createWaypointMenuItems(this.vehicleType);
    this.subscribeToSecondarySideMenuService();
    this.registerForFormEvents();
  }

  ngOnChanges({ allPois }: TypedChanges<VehicleSendStepsComponent>): void {
    if (allPois) {
      this.pois = allPois.currentValue;
    }

    this.prepareDropDownOptions();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  subscribeToSecondarySideMenuService(): void {
    this.secondarySideMenuService.cancelSideBarChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (this.isSendingStepsToVehicle) {
          this.secondarySideMenuService.setVehicleSendStep(false);
        }
      });

    this.secondarySideMenuService.vehicleSendStep$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(sendSteps => {
        this.isSendingStepsToVehicle = sendSteps;
      });
  }

  registerForFormEvents(): void {
    this.stepForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (!this.stepForm.pristine && !this.hasChanges) {
        this.setHasChanges(true);
      }
    });

    this.stepForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      const sendStep: SendStepModel =
        this.vehicleType === VehicleType.UnitLoad
          ? {
              vehicleSteps: this.createVehicleSendStepArray(),
              isValid: !this.stepForm.invalid,
            }
          : {
              vehicleSteps: [],
              tuggerTrainSteps: this.createTuggerTrainSendStepArray(),
              isValid: !this.stepForm.invalid,
            };

      this.populateVehicleSendSteps.emit(sendStep);
    });
  }

  setHasChanges(hasChanges: boolean): void {
    this.hasChanges = hasChanges;
    this.editBarService.setHasChanges(this.hasChanges);
  }
}
