import { createSelector } from '@ngrx/store';

import { ProcessChainGroupDto } from 'core/dtos';
import { selectMapId, selectMapsEntities } from 'store-modules/maps-store/selectors/maps.selectors';
import * as ProcessConfiguratorFeatureState from '../reducers/index';
import * as fromProcessChainGroupReducer from '../reducers/process-chain-group.reducer';
import { convertToTableProcessChainGroup } from './process-chain-group.selectors.helpers';

const selectProcessChainGroupState = createSelector(
  ProcessConfiguratorFeatureState.getProcessConfiguratorFeatureState,
  ProcessConfiguratorFeatureState.getProcessChainGroupState
);

export const selectProcessChainGroupLoaded = createSelector(
  selectProcessChainGroupState,
  fromProcessChainGroupReducer.getLoaded
);

export const selectAllProcessChainGroups = createSelector(
  selectProcessChainGroupState,
  fromProcessChainGroupReducer.getProcessChainGroups
);

export const selectAllTableProcessChainGroups = createSelector(
  selectAllProcessChainGroups,
  selectMapsEntities,
  (processChains, mapEntities) => {
    return processChains.map(processChain =>
      convertToTableProcessChainGroup(processChain, mapEntities)
    );
  }
);

export const selectAllTableProcessChainGroupsByMapId = createSelector(
  selectAllTableProcessChainGroups,
  selectMapId,
  (processChainGroups, mapId) => {
    return processChainGroups.filter(pcg => (mapId ? pcg.mapId === mapId : true));
  }
);

export const selectProcessChainGroupsLoading = createSelector(
  selectProcessChainGroupState,
  fromProcessChainGroupReducer.getLoading
);

export const selectSelectedProcessChainGroupId = createSelector(
  selectProcessChainGroupState,
  fromProcessChainGroupReducer.getSelectedProcessChainGroupId
);

export const selectProcessChainGroupEntities = createSelector(
  selectProcessChainGroupState,
  fromProcessChainGroupReducer.getEntities
);

export const selectSelectedProcessChainGroup = createSelector(
  selectSelectedProcessChainGroupId,
  selectProcessChainGroupEntities,
  (processChainGroupId, processChainGroups): ProcessChainGroupDto | undefined =>
    processChainGroups[String(processChainGroupId)]
);
