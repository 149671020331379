import { MissionTraceEffects } from './mission-trace.effects';
import { MissionListItemEffects } from './mission-list-item.effects';
import { OrderEffects } from './order.effects';
import { TourEffects } from './tour.effects';
import { ProcessChainGroupTraceEffects } from './process-chain-group-trace.effects';

export const effects = [
  MissionTraceEffects,
  MissionListItemEffects,
  OrderEffects,
  TourEffects,
  ProcessChainGroupTraceEffects,
];
