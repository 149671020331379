import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { StartPageOptionCard, StartPageOptions } from 'core/models';

@Component({
  selector: 'app-start-page-settings',
  templateUrl: './start-page-settings.component.html',
  styleUrls: ['./start-page-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartPageSettingsComponent implements OnChanges {
  @Input() readonly initiallySelectedStartPage: StartPageOptions | null = 'maps';
  @Output() readonly selectedPreference = new EventEmitter<StartPageOptions>();

  selectedStartPage: StartPageOptions = 'maps';

  startPageOptionCards: StartPageOptionCard[] = [
    { name: 'mapsView', picture: 'assets/global/userSettingsMap.jpg', route: 'maps' },
    {
      name: 'activeMissions',
      picture: 'assets/global/userSettingsActive.jpg',
      route: 'jobs/missions/active',
    },
    { name: 'vehicles', picture: 'assets/global/userSettingsVehicles.jpg', route: 'vehicles' },
  ];

  ngOnChanges({ initiallySelectedStartPage }: TypedChanges<StartPageSettingsComponent>): void {
    if (initiallySelectedStartPage && initiallySelectedStartPage.currentValue) {
      this.selectedStartPage = initiallySelectedStartPage.currentValue;
    }
  }

  onCardClick(card: StartPageOptionCard): void {
    this.selectedStartPage = card.route;
    this.selectedPreference.emit(this.selectedStartPage);
  }
}
