import { FeatureToggle } from 'core/dtos';

export type PktInterfaceToggleDto = FeatureToggle;

export interface OrderGatewayFeatures {
  pktInterfaceToggle: PktInterfaceToggleDto;
}

export function getDefaultOrderGatewayFeatures(): OrderGatewayFeatures {
  return {
    pktInterfaceToggle: {
      isToggledOn: false,
      dateUpdated: null,
    },
  };
}
