import { createAction, props } from '@ngrx/store';
import { OrderDto } from 'core/dtos';
import { OrderFilter } from 'core/models';

export enum OrderTypes {
  LoadOrders = '[Monitoring] Load Orders',
  LoadOrdersSuccess = '[Monitoring] Load Orders Success',
  LoadOrdersFailure = '[Monitoring] Load Orders Failure',
  HistoryOrderFilterOptionsLoaded = '[Monitoring] History Order Filter Options Loaded',
  LoadHistoryOrderFilterOptions = '[Monitoring] Load History Order Filter Options',
  RemoveOrder = '[Monitoring] Remove Order',
  SignalRAddOrder = '[SignalR] Add Order',
  SignalRUpdateOrder = '[SignalR] Update Order',
  SetActiveOrderFilter = '[Monitoring] Set Active Order Filter',
  SetHistoryOrderFilter = '[Monitoring] Set History Order Filter',
}

export const loadOrders = createAction(OrderTypes.LoadOrders);

export const loadOrdersSuccess = createAction(
  OrderTypes.LoadOrdersSuccess,
  props<{ orders: OrderDto[] }>()
);

export const loadOrdersFailure = createAction(
  OrderTypes.LoadOrdersFailure,
  props<{ errorMessage: string }>()
);

export const loadHistoryOrderFilterOptions = createAction(OrderTypes.LoadHistoryOrderFilterOptions);

export const historyOrderFilterOptionsLoaded = createAction(
  OrderTypes.HistoryOrderFilterOptionsLoaded,
  props<{ historyOrderFilterOptions: OrderFilter }>()
);

export const signalRAddOrder = createAction(
  OrderTypes.SignalRAddOrder,
  props<{ order: OrderDto }>()
);

export const signalRUpdateOrder = createAction(
  OrderTypes.SignalRUpdateOrder,
  props<{ order: OrderDto }>()
);

export const removeOrder = createAction(OrderTypes.RemoveOrder, props<{ order: OrderDto }>());

export const setActiveOrderFilter = createAction(
  OrderTypes.SetActiveOrderFilter,
  props<{ activeOrderFilter: OrderFilter }>()
);

export const setHistoryOrderFilter = createAction(
  OrderTypes.SetHistoryOrderFilter,
  props<{ historyOrderFilter: OrderFilter }>()
);
