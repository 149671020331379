<form [formGroup]="stepForm" class="g-2x">
  <div *ngIf="vehicleType | isTuggerTrain">
    <ds-form-field>
      <label ds-label for="routeConfigurationId">
        {{ 'shared.vehicles.sendStep.routeConfigName' | translate }}
      </label>
      <ds-select
        [showClearAll]="true"
        ds-form-validation
        [labellingConfig]="labellingConfig"
        [options]="allRouteConfigurations | dsDropdown : 'name' : 'id'"
        formControlName="routeConfigurationId"
        id="routeConfigurationId"
        data-cy="routeConfigurationIdDropDown"></ds-select>
    </ds-form-field>
    <br />
  </div>

  <div class="row">
    <ng-container class="step" *ngFor="let step of stepsFormArray.controls; let stepIndex = index">
      <ng-container [formGroup]="$any(stepsFormArray.controls[stepIndex])">
        <div class="col-12">
          <div style="display: flex; align-items: end">
            <label
              data-cy="sequenceNumber"
              class="text-label me-2x mb-2x"
              style="min-width: 12px"
              >{{ stepIndex + 1 }}</label
            >

            <div style="flex-grow: 1">
              <app-step-type-selection
                formControlName="stepType"
                ngDefaultControl
                [vehicleType]="vehicleType"
                [hardwareVersion]="hardwareVersion"
                (ngModelChange)="
                  onStepTypeChanged($event, $any(stepsFormArray.controls[stepIndex]))
                "></app-step-type-selection>
            </div>
            <div style="display: flex; align-items: end">
              <app-action-menu
                class="ms-2x"
                data-cy="stepMenuButton"
                [menuItems]="stepItems"
                (menuOpened)="showHideDeleteAction(); setStepIndex(stepIndex)"></app-action-menu>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!isWait($any(step)); else inputTimeTemplate">
          <ng-container *ngIf="vehicleType === 0; else nodeTemplate">
            <app-step-attribute-poi
              class="ps-6x pe-11x"
              *ngIf="!attributeDisabled($any(step))"
              [stepType]="step.value.stepType"
              [allPois]="allPois"
              formControlName="pointOfInterestGroup"
              ngDefaultControl></app-step-attribute-poi>
          </ng-container>
        </ng-container>

        <ng-template #nodeTemplate>
          <app-step-attribute-node
            class="ps-6x pe-11x"
            *ngIf="!attributeDisabled($any(step)) && !(vehicleType | isUnitLoad)"
            [isVisible]="step.value.stepType !== 24"
            [stepType]="step.value.stepType"
            [allNodes]="allNodes"
            [nodeGroups]="nodeGroups"
            formControlName="pointOfInterestGroup"
            ngDefaultControl></app-step-attribute-node>
        </ng-template>

        <ng-template #inputTimeTemplate>
          <app-step-attribute-timespan
            class="ps-6x pe-11x"
            formControlName="timeSpan"
            ngDefaultControl></app-step-attribute-timespan>
        </ng-template>

        <div
          *ngIf="stepWaypointArray(stepIndex).controls?.length"
          cdkDropList
          (cdkDropListDropped)="dropWaypoint($event, stepIndex)">
          <div
            class="row mb-2x"
            *ngFor="
              let waypoint of stepWaypointArray(stepIndex).controls;
              let waypointIndex = index
            "
            cdkDrag>
            <ng-container [formGroup]="$any(stepWaypointArray(stepIndex).controls[waypointIndex])">
              <div style="display: flex">
                <div cdkDragHandle class="alignLabelEnd pe-2x" style="cursor: grab">
                  <span class="text-label">{{ 'shared.vehicles.sendStep.via' | translate }}</span>
                </div>
                <div style="flex-grow: 1">
                  <ds-form-field>
                    <ds-select
                      appendTo="body"
                      ds-form-validation
                      name="waypoint"
                      formControlName="waypoint"
                      required
                      [labellingConfig]="labellingConfig"
                      [options]="waypoints | sortDropdown | dsDropdown : 'name' : 'id'"></ds-select>
                  </ds-form-field>
                </div>
                <div style="display: flex; align-items: end">
                  <app-action-menu
                    class="ms-2x"
                    data-cy="waypointMenuButton"
                    [menuItems]="waypointItems"
                    (menuOpened)="
                      setStepIndex(stepIndex); setWaypointIndex(waypointIndex)
                    "></app-action-menu>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <button
    class="ms-5x my-2x"
    ds-button
    type="button"
    data-cy="addStepButton"
    (click)="addStep()"
    [icon]="'add'"
    [variant]="'ghost'">
    {{ 'shared.vehicles.actions.addStep' | translate }}
  </button>
</form>
