import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DATE_FORMAT } from 'core/constants/date-formats.constant';
import { ATSMenuItem, GuidString } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { SortOrder, TableColumns } from 'library/models';
import { FilterService, SortEvent } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { isKeyDefined } from 'library/helpers/key-defined.helper';
import { TableTemplateContext } from 'library/models/template-context.models';
import { TreeTablePaginatorState } from 'primeng/treetable';
import { ActionMenuConfiguration, TreeTableSortOptions } from '../tree-table/tree-table.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements OnInit, OnChanges, OnDestroy {
  readonly LAYOUT_HEIGHT = 258;
  readonly DEFAULT_ROWS = 10;
  readonly DEFAULT_SCREEN_HEIGHT = 200;

  @Input() header = '';
  @Input() displayedColumns: TableColumns[] = [];
  @Input() menuItems: ATSMenuItem[] = [];
  @Input() menuItemsStyleClass?: string;
  @Input() hideMenuItemPopup = false;
  @Input() records: {}[] = [];
  @Input() totalNumberOfRecords = 0;
  @Input() hasPaginator = false;
  @Input() first = 0;
  @Input() translationContext = '';
  @Input() loading = false;
  @Input() rows = this.DEFAULT_ROWS;
  @Input() isLazy = true;
  @Input() searchTerm = '';
  @Input() itemTemplate!: TemplateRef<TableTemplateContext>;
  @Input() emptyTableTemplate!: TemplateRef<TableTemplateContext>;
  @Input() newRowItemTemplate!: TemplateRef<TableTemplateContext>;

  @Input() sortField = 'name';
  @Input() sortOrder = SortOrder.Asc;
  @Input() isEditMode = false;
  @Input() rowHighlight?: Function;
  @Input() actionMenu: ActionMenuConfiguration = 'none';
  @Input() isSortable = false;
  @Input() showFullLabel = false;
  @Input() reorderableColumns = false;
  @Input() isTableConfigurationModeActive = false;

  defaultDisplayedColumns: TableColumns[] = [];
  tempDisplayedColumns: TableColumns[] = [];

  screenHeight = this.DEFAULT_SCREEN_HEIGHT;
  selectedRow?: T;
  dateTimeFormat = DATE_FORMAT.FULL_DATE_TIME_SECONDS;
  nodeEvent: 'NodeSelect' | 'NodeAction' = 'NodeSelect';
  ngUnsubscribe = new Subject<void>();

  @Output() readonly paginate = new EventEmitter<TreeTablePaginatorState>();
  @Output() readonly navigateTo = new EventEmitter<T>();
  @Output() readonly setSelectedRow = new EventEmitter<T>();
  @Output() readonly rowClick = new EventEmitter<T>();
  @Output() readonly inputSwitchClick = new EventEmitter<InputSwitchEvent<T>>();
  @Output() readonly textFieldChange = new EventEmitter<T>();
  @Output() readonly saveTableSort = new EventEmitter<TreeTableSortOptions>();
  @Output() readonly lazyLoad = new EventEmitter<TableLazyLoadEvent>();
  @Output() readonly colReordered = new EventEmitter<boolean>();

  @ViewChild(Table) table!: Table;

  constructor(
    protected readonly datePipe: DatePipe,
    protected readonly filterService: FilterService,
    protected readonly translate: AtsTranslationService
  ) {}

  get showActionMenu(): boolean {
    return (this.menuItems ? this.menuItems.length : 0) > 0;
  }

  ngOnInit(): void {
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.updateMenuTranslation();
    });
  }

  ngOnChanges({ searchTerm, records }: TypedChanges<TableComponent<T>>): void {
    if (searchTerm && this.table) {
      this.table.filterGlobal(searchTerm.currentValue, 'contains');
    }

    if (this.rowHighlight !== undefined && records?.currentValue) {
      for (const r of records.currentValue) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        r['highlightClass'] = this.rowHighlight(r).class;
        // eslint-disable-next-line @typescript-eslint/dot-notation
        r['sortHighlightedToTop'] = this.rowHighlight(r).sortHighlightedToTop;
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onPage(event: TreeTablePaginatorState): void {
    this.paginate.emit(event);
  }

  onActionMenuClick(rowData: T, event: Event): void {
    this.selectedRow = rowData;
    this.setSelectedRow.emit(this.selectedRow);
    event.stopPropagation();
  }

  onRowSelect(rowData: T): void {
    this.selectedRow = rowData;
    this.rowClick.emit(this.selectedRow);
  }

  hideColumn(event: Event, column: TableColumns): void {
    column.isHidden = !column.isHidden;
    this.colReordered.emit(true);
    event.stopPropagation();
  }

  onNavigateTo(rowData: T): void {
    this.navigateTo.emit(rowData);
  }

  sortFunction({ data = [], order = SortOrder.Asc, field = 'name' }: SortEvent): object[] {
    this.saveTableSort.emit({ field: field, order: order });

    return data.sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      if (a['sortHighlightedToTop'] && !b['sortHighlightedToTop']) {
        return -1;
        // eslint-disable-next-line @typescript-eslint/dot-notation
      } else if (!a['sortHighlightedToTop'] && b['sortHighlightedToTop']) {
        return 1;
      }

      if (isKeyDefined(a, field) && isKeyDefined(b, field)) {
        return order * String(a[field]).localeCompare(b[field], undefined, { numeric: true });
      } else {
        return Number(isKeyDefined(b, field)) - Number(isKeyDefined(a, field));
      }
    });
  }

  @HostListener('window:resize')
  getScreenSize(): void {
    this.screenHeight = window.innerHeight - this.LAYOUT_HEIGHT;
  }

  onInputSwitchClick(rowData: T, checked: boolean): void {
    if (this.isEditMode) {
      this.inputSwitchClick.emit({ object: rowData, checked: checked });
    }
  }

  onTextFieldChange(rowData: T): void {
    if (this.isEditMode) {
      this.textFieldChange.emit(rowData);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customContainsFilter(value: any, filter: any): boolean {
    const contains = this.filterService.filters.contains(value, filter);
    if (contains) {
      return true;
    }

    const notNumber = isNaN(value);
    if (notNumber && this.isDate(value)) {
      const formattedDate = this.datePipe.transform(value, this.dateTimeFormat);
      return this.filterService.filters.contains(formattedDate, filter);
    }

    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isDate(value: any): boolean {
    return Date.parse(value).toString() !== 'NaN';
  }

  loadLazy(event: TableLazyLoadEvent): void {
    this.lazyLoad.emit(event);
  }

  updateMenuTranslation(): void {
    for (const menuItem of this.menuItems) {
      if ('key' in menuItem) {
        menuItem.label = this.translate.get(menuItem.key);
      }
    }
  }

  onColReorder(): void {
    this.colReordered.emit(true);
  }

  trackByFunction = (index: number, item: { id: string | GuidString }): string | number => {
    if (isKeyDefined(item, 'id')) {
      return item.id.toString();
    }
    return index;
  };
}
