<div class="three-columns">
  <div class="labeled-static">
    <label ds-label>{{ 'settings.ipst.url' | translate }}</label>
    <div data-cy="ipstUrl">{{ ipstSettings?.url | notApplicable }}</div>
  </div>
  <div class="labeled-static">
    <label ds-label>{{ 'settings.ipst.user' | translate }}</label>
    <div data-cy="ipstUser">{{ ipstSettings?.user | notApplicable }}</div>
  </div>
  <div class="labeled-static">
    <label ds-label>{{ 'settings.ipst.password' | translate }}</label>
    <div
      data-cy="ipstPassword"
      class="form-plaintext"
      [style.-webkit-text-security]="ipstSettings?.password ? 'disc' : 'none'">
      {{ ipstSettings?.password | notApplicable }}
    </div>
  </div>
</div>

<hr />
