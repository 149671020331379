<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="mt-18x center-horizontal">
      <img
        class="filter-gray"
        src="./assets/global/logo/ATS_new.svg"
        alt="ATS logo"
        style="max-width: 220px" />
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="center-horizontal title-md">
      <div data-cy="waNotFoundMessage">
        {{ 'shared.waNotFound.message' | translate }}
      </div>
      <br />
    </div>

    <div class="row justify-content-center pt-6x">
      <div class="center-horizontal">
        <a ds-button href="/welcome" class="redirect-button">
          {{ 'shared.waNotFound.contactMessage' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
