import { PillarGridColors } from 'library/styles';

export const GridStyle = {
  color: PillarGridColors.Lines,
  alpha: 0.5,
  native: true,
  width: 1,
};

export const GridName = {
  alpha: 0.8,
  scale: 0.008,
};

export const GridIconStyle = {
  color: PillarGridColors.Icon,
  alpha: 0.8,
  size: 0.2,
  offset: 0.1,
};
