import { createAction, props } from '@ngrx/store';
import { OpcuaStreamingServiceDto } from 'core/dtos';

export enum StreamingServicesTypes {
  LoadOpcuaStreamingServices = '[Devices] Load All StreamingServices Devices',
  LoadOpcuaStreamingServicesSuccess = '[Devices] Load All Opcua StreamingServices Success',
  LoadOpcuaStreamingServicesFailure = '[Devices] Load All Opcua StreamingServices Failure',

  CreateOpcuaStreamingService = '[Devices] Create Opcua StreamingService',
  CreateOpcuaStreamingServiceSuccess = '[Devices] Create Opcua StreamingService Success',
  CreateOpcuaStreamingServiceFailure = '[Devices] Create Opcua StreamingService Failure',

  UpdateOpcuaStreamingService = '[Devices] Update Opcua StreamingService',
  UpdateOpcuaStreamingServiceSuccess = '[Devices] Update Opcua StreamingService Success',
  UpdateOpcuaStreamingServiceFailure = '[Devices] Update Opcua StreamingService Failure',

  DeleteOpcuaStreamingService = '[Devices] Delete Opcua StreamingService',
  DeleteOpcuaStreamingServiceSuccess = '[Devices] Delete Opcua StreamingService Success',
  DeleteOpcuaStreamingServiceFailure = '[Devices] Delete Opcua StreamingService Failure',
}

export enum SignalRStreamingServiceTypes {
  SignalrCreateOpcuaStreamingService = '[SignalR] Create Opcua StreamingService',
  SignalrUpdateOpcuaStreamingService = '[SignalR] Update Opcua StreamingService',
  SignalrDeleteOpcuaStreamingService = '[SignalR] Delete Opcua StreamingService',
}

export const loadOpcuaStreamingServices = createAction(
  StreamingServicesTypes.LoadOpcuaStreamingServices
);

export const loadOpcuaStreamingServicesSuccess = createAction(
  StreamingServicesTypes.LoadOpcuaStreamingServicesSuccess,
  props<{ streamingServices: OpcuaStreamingServiceDto[] }>()
);

export const loadOpcuaStreamingServicesFailure = createAction(
  StreamingServicesTypes.LoadOpcuaStreamingServicesFailure,
  props<{ errorMessage: string }>()
);

export const signalRCreateOpcuaStreamingService = createAction(
  SignalRStreamingServiceTypes.SignalrCreateOpcuaStreamingService,
  props<{ streamingService: OpcuaStreamingServiceDto }>()
);

export const signalRUpdateOpcuaStreamingService = createAction(
  SignalRStreamingServiceTypes.SignalrUpdateOpcuaStreamingService,
  props<{ streamingService: OpcuaStreamingServiceDto }>()
);

export const signalRDeleteOpcuaStreamingServices = createAction(
  SignalRStreamingServiceTypes.SignalrDeleteOpcuaStreamingService,
  props<{ streamingService: OpcuaStreamingServiceDto }>()
);

export const createOpcuaStreamingServiceSuccess = createAction(
  StreamingServicesTypes.CreateOpcuaStreamingServiceSuccess,
  props<{ streamingService: OpcuaStreamingServiceDto }>()
);

export const createOpcuaStreamingService = createAction(
  StreamingServicesTypes.CreateOpcuaStreamingService,
  props<{ streamingService: OpcuaStreamingServiceDto }>()
);

export const createOpcuaStreamingServiceFailure = createAction(
  StreamingServicesTypes.CreateOpcuaStreamingServiceFailure,
  props<{ errorMessage: string }>()
);

export const updateOpcuaStreamingService = createAction(
  StreamingServicesTypes.UpdateOpcuaStreamingService,
  props<{ streamingService: OpcuaStreamingServiceDto }>()
);

export const updateOpcuaStreamingServiceSuccess = createAction(
  StreamingServicesTypes.UpdateOpcuaStreamingServiceSuccess,
  props<{ streamingService: OpcuaStreamingServiceDto }>()
);

export const updateOpcuaStreamingServiceFailure = createAction(
  StreamingServicesTypes.UpdateOpcuaStreamingServiceFailure,
  props<{ errorMessage: string }>()
);

export const deleteOpcuaStreamingService = createAction(
  StreamingServicesTypes.DeleteOpcuaStreamingService,
  props<{ name: string }>()
);

export const deleteOpcuaStreamingServiceSuccess = createAction(
  StreamingServicesTypes.DeleteOpcuaStreamingServiceSuccess,
  props<{ name: string }>()
);

export const deleteOpcuaStreamingServiceFailure = createAction(
  StreamingServicesTypes.DeleteOpcuaStreamingServiceFailure,
  props<{ errorMessage: string }>()
);
