import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ToolbarService } from 'core/services';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/index';

import { ToolBarControls, ToolBarItem } from 'core/models';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolBarComponent implements OnInit, OnDestroy {
  @Input() showItems = true;

  actionItems$: Observable<ToolBarItem[]> = of([]);
  showSeparator$: Observable<boolean> = of(true);
  showSearchBar$: Observable<boolean> = of(true);
  isEditToggleVisible$: Observable<boolean> = of(false);

  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly toolbarService: ToolbarService,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {}

  get ActionType(): typeof ToolBarControls {
    return ToolBarControls;
  }

  ngOnInit(): void {
    this.actionItems$ = this.toolbarService.configuredToolBarItems$;
    this.isEditToggleVisible$ = this.rootStore.pipe(select(fromRoot.selectIsEditToggleVisible));
    this.showSeparator$ = this.actionItems$.pipe(map(items => items.length > 0));
    this.showSearchBar$ = this.rootStore.pipe(select(fromRoot.selectShowSearchBar));
  }

  onButtonClick(command?: Function): void {
    command && command();
  }

  onSearch(term: string): void {
    this.toolbarService.search(term);
  }

  trackByKey(_index: number, item: ToolBarItem): string {
    return item.key;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
