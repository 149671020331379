import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AtsTranslationService } from 'core/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-message-link',
  templateUrl: './message-link.component.html',
  styleUrls: ['./message-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageLinkComponent implements OnInit, OnChanges, OnDestroy {
  @Input() messageKey = '';
  @Input() route = '';
  @Input() icon?: string;
  @Input() iconTone?: 'info' | 'positive' | 'caution' | 'critical' | 'neutral';

  messageLinkText = '';

  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    protected readonly atsTranslateService: AtsTranslationService
  ) {}

  ngOnInit(): void {
    this.atsTranslateService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.setMessageLink();
    });
  }

  ngOnChanges({ route }: TypedChanges<MessageLinkComponent>): void {
    if (route?.currentValue) {
      this.setMessageLink();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setMessageLink(): void {
    this.messageLinkText = this.atsTranslateService.get(this.messageKey, {
      URL: this.route,
    });

    this.cdRef.markForCheck();
  }
}
