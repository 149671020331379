import { Observable, OperatorFunction, pipe, UnaryFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

export function filterUndefined<T>(): UnaryFunction<Observable<T | undefined>, Observable<T>> {
  return pipe(filter(x => x != null) as OperatorFunction<T | undefined, T>);
}

export async function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
