import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { environment } from '@environment';
import { Store, select } from '@ngrx/store';
import { RolesService, UserService } from 'core/api-services';
import { OrganizationDto, RoleDto } from 'core/dtos';
import {
  AtsTreeRoleItem,
  DateString,
  GuidString,
  User,
  UserRoleModel,
  UserRoleModelWithChildren,
  UserWithRole,
  UserWithUserRoles,
} from 'core/models';
import { AtsTranslationService } from 'core/services';
import { Observable, firstValueFrom, of } from 'rxjs';
import { AssignUserRoleDialogInput } from 'shared/components';
import * as fromRoot from 'store/index';
import { OrganizationChartTableComponent } from '../organization-chart-table/organization-chart-table.component';

@Component({
  selector: 'app-organization-chart',
  templateUrl: './organization-chart.component.html',
  styleUrls: ['./organization-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationChartComponent implements OnDestroy, OnChanges {
  @Input() userDetailsData?: AssignUserRoleDialogInput;
  @Input() organizationList: OrganizationDto[] = [];
  @Input() editEnabled = false;
  @ViewChild(forwardRef(() => OrganizationChartTableComponent))
  orgChartTable!: OrganizationChartTableComponent;

  currentUser?: User;
  translateKey!: string;
  currentLang = '';
  $organizationUsers: Observable<UserWithRole[]> = of([]);
  roles!: RoleDto[];
  currentRole?: RoleDto;
  areaText!: string;
  users!: User[];
  isEditMode$ = of(false);
  selectedRole: string | string[] | null = '';
  allUserAccessList: UserRoleModel[] = [];
  userAccessList: UserRoleModelWithChildren[] | undefined = [];
  userId = 0;
  accessExpiry: DateString = '';
  readonly editTranslateKey = 'shared.editUserRoleModal';
  selectedNode?: AtsTreeRoleItem = undefined;
  currentUserRoles: UserWithUserRoles[] = [];
  envUsers: UserWithUserRoles[] = [];
  allRoles: RoleDto[] = [];
  menuItems: AtsTreeRoleItem[] = [];

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly userService: UserService,
    private readonly rolesService: RolesService,
    protected readonly translate: AtsTranslationService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  async ngOnChanges({ userDetailsData }: TypedChanges<OrganizationChartComponent>): Promise<void> {
    if (userDetailsData?.currentValue) {
      Object.assign(this, userDetailsData.currentValue);
      this.userId = Number(userDetailsData.currentValue.currentUser?.id);
      this.currentLang = this.translate.currentLang;
      await this.initializeSubscriptions();
    }
  }

  ngOnDestroy(): void {
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
  }

  async initializeSubscriptions(): Promise<void> {
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
    this.currentUserRoles = await firstValueFrom(
      this.userService.getUserWithRoles(this.currentUser?.id)
    );
    this.allRoles = await firstValueFrom(this.rolesService.getRoles());
    this.envUsers = await firstValueFrom(this.userService.getEnvironmentUsers());
    this.allUserAccessList = this.fetchUserAccess();
    this.menuItems = this.generateOrganogram();

    this.cdRef.markForCheck();
  }

  fetchUserAccess(): UserRoleModel[] {
    const envUser = this.envUsers.find(u => u.id === this.userId);
    const envAccess: UserRoleModel = {
      id: 'env',
      level: environment.config.environment,
      roleName: envUser?.roles[0].name ?? '',
      roleAssigned: '',
    };

    const userRole = this.currentUserRoles.find(u => u.id === this.userId);
    const orgAndWaAccess: UserRoleModel[] = this.organizationList.flatMap(org =>
      [
        {
          id: org.id,
          level: 'Organization : ' + org.name,
          roleName: userRole?.roles.find(r => r.organizationId === org.id)?.name ?? '',
          roleAssigned: '',
        },
      ].concat(
        org.workAreas.map(wa => ({
          id: wa.id,
          level: 'Work Area : ' + wa?.name ?? '',
          roleName: userRole?.roles.find(r => r.workingAreaId === wa.id)?.name ?? '',
          roleAssigned: '',
        }))
      )
    );

    return [envAccess].concat(orgAndWaAccess);
  }

  async save(): Promise<void> {
    await this.orgChartTable.save();
    this.orgChartTable.gridApi.redrawRows();
  }

  cancel(): void {
    this.orgChartTable.Cancel();
    this.selectedNode = undefined;
  }

  async refreshOrganogram(): Promise<void> {
    await this.initializeSubscriptions();
  }

  private generateOrganogram(): AtsTreeRoleItem[] {
    const orgNodes = this.organizationList.map(org => {
      const waTree: AtsTreeRoleItem[] = org.workAreas.map(wa =>
        this.createMenuNode(wa.name, 'workingArea', wa.id.toString(), undefined)
      );
      return this.createMenuNode(org.name, 'organization', org.id.toString(), waTree);
    });
    return [this.createMenuNode(environment.config.environment, 'environment', 'env', orgNodes)];
  }

  private createMenuNode(
    label: string,
    type: string,
    key: string,
    children?: AtsTreeRoleItem[]
  ): AtsTreeRoleItem {
    let icon = '';
    if (this.userHasPermissions(key)) {
      icon = 'checkbox_circle';
      if (type === 'organization') {
        type = 'organizationWithPermission';
      } else if (type === 'environment') {
        type = 'environmentWithPermission';
      } else if (type === 'workingArea') {
        type = 'workingAreaWithPermission';
      }
    }
    return {
      label: label,
      icon: icon,
      group: false,
      id: key,
      type: type,
      isOpen: false,
      children: children ?? [],
    };
  }

  userHasPermissions(orgId: GuidString): boolean {
    if (orgId !== 'env') {
      const userAcccessToOrg = this.allUserAccessList.find(
        a => a.id === orgId && a.roleName !== ''
      );

      const userHasAccessToWorkArea = this.organizationList
        .find(org => org.id === orgId)
        ?.workAreas.find(wa =>
          this.allUserAccessList?.find(access => access.id === wa.id && access.roleName !== '')
        );

      return userAcccessToOrg !== undefined || userHasAccessToWorkArea !== undefined;
    }
    const userHasAccessToEnvironment = this.allUserAccessList.find(
      a => a.id === orgId && a.roleName !== ''
    );

    return userHasAccessToEnvironment !== undefined;
  }

  getSelectedRole(selectedRole: string | string[] | null): void {
    this.selectedRole = selectedRole;
  }

  handleItemSelect(item: AtsTreeRoleItem): void {
    this.selectedNode = item;
  }

  onNodeUnselect(): void {
    this.userAccessList = [];
  }
}
