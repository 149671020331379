import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  ChartDataDto,
  ChartDescriptionDataDto,
  ChartDto,
  FleetDto,
  Last30ProductiveDays,
  MapDto,
  NIOMissionsDetails,
  ODataDto,
  ReportFilterDto,
  ReportFiltersMissionOverviewDownloadDto,
  VehicleDto,
} from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { saveAs } from 'file-saver';
import { Observable, firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.MissionArchiveMissionReports;
  private readonly servicePathOdata = '/odata';

  protected apiUrl = environment.Services.MissionArchive;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  async getMissionsOverview(filters: ReportFilterDto): Promise<ChartDataDto[]> {
    return firstValueFrom(
      this.post<ChartDataDto[]>(`${this.servicePath}/missionOverview`, filters)
    );
  }

  async getCumulativeMissions(filters: ReportFilterDto): Promise<ChartDto[]> {
    return firstValueFrom(this.post<ChartDto[]>(`${this.servicePath}/cumulativeMissions`, filters));
  }

  async getReasonNIOMissions(filters: ReportFilterDto): Promise<ChartDescriptionDataDto[]> {
    return firstValueFrom(
      this.post<ChartDescriptionDataDto[]>(`${this.servicePath}/reasonsForNioMission`, filters)
    );
  }

  async getTimeNIOMissions(filters: ReportFilterDto): Promise<ChartDataDto[]> {
    return firstValueFrom(
      this.post<ChartDataDto[]>(`${this.servicePath}/timeToSolveNioMission`, filters)
    );
  }

  async getTimeEmergencyProcess(filters: ReportFilterDto): Promise<ChartDataDto[]> {
    return firstValueFrom(
      this.post<ChartDataDto[]>(`${this.servicePath}/timeForEmergencyProcessNioMission`, filters)
    );
  }

  async getLast30ProductiveDays(filters: ReportFilterDto): Promise<Last30ProductiveDays> {
    return firstValueFrom(
      this.post<Last30ProductiveDays>(`${this.servicePath}/last30ProductiveDays`, filters)
    );
  }

  getNIOMissionsDetailsList(
    filters: ReportFilterDto,
    skip: number
  ): Observable<ODataDto<NIOMissionsDetails>> {
    const params = {
      $count: 'true',
      $top: '10',
      $skip: skip.toString(),
    };
    return this.post<ODataDto<NIOMissionsDetails>>(
      `${this.servicePathOdata}/DetailsForNioMissionReport`,
      filters,
      { params }
    );
  }

  async checkIfMissionOverviewDownloadHasData(
    filters: ReportFiltersMissionOverviewDownloadDto
  ): Promise<Blob> {
    return firstValueFrom(
      this.post<Blob>(`${this.servicePath}/missionOverviewExport`, filters, {
        responseType: 'blob' as 'json',
      })
    );
  }

  getMissionOverviewDataFileDownload(
    filters: ReportFiltersMissionOverviewDownloadDto,
    fileName: string
  ): void {
    this.post<Blob>(`${this.servicePath}/missionOverviewExport`, filters, {
      responseType: 'blob' as 'json',
    })
      .pipe(take(1))
      .subscribe(data => {
        saveAs(data, `${fileName}.csv`);
      });
  }

  getVehicles(organizationName: string): Observable<VehicleDto[]> {
    return this.httpClient.get<VehicleDto[]>(
      `${environment.Services.FleetManager}${API_SERVICES.Reports}` +
        `/GetVehiclesByOrganization?organizationName=${organizationName}`
    );
  }

  getAllMaps(): Observable<MapDto[]> {
    return this.httpClient.get<MapDto[]>(`${environment.Services.MapManager}${API_SERVICES.Maps}`);
  }

  getFleets(organizationName: string): Observable<FleetDto[]> {
    return this.httpClient.get<FleetDto[]>(
      `${environment.Services.FleetManager}${API_SERVICES.Reports}` +
        `/GetFleetsByOrganization?organizationName=${organizationName}`
    );
  }
}
