import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment';

import { LicenseManager } from 'ag-grid-enterprise';
import { AppModule } from './app/app.module';

// Angular startup
if (environment.production) {
  enableProdMode();
}

const stored = localStorage.getItem('flags') ?? '{}';
const initialFlags = {
  logSignalRLatency: false,
  logSignalRMeta: false,
  logSignalRCalls: 0,
  ...JSON.parse(stored),
};

// Feature flags
window.flags = window.flags || initialFlags;
window.metrics = { signalRLatency: undefined };

// ag grid license
LicenseManager.setLicenseKey(
  // eslint-disable-next-line max-len
  'Using_this_AG_Grid_Enterprise_key_( AG-044530 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Bayerische Motoren Werke AG )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 September 2024 )____[v2]_MTcyNTY2MzYwMDAwMA==e3c15a8f4e4f6c8670ef491da6a08a90'
);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(async moduleRef => {
    if (!environment.production) {
      if (environment.useMsw) {
        const { worker } = await import('./mocks/browser');
        await worker.start();
      }
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(componentRef);
    }
  })
  .catch(err => {
    console.error(err);
  });
