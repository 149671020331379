<ds-select
  panelStyleClass="permission-table-dropdown"
  data-cy="RoleDropdown"
  appendTo="body"
  optionLabel="nameTranslation"
  [options]="optionList | dsDropdown"
  optionValue="id"
  (valueChange)="onChange($event)"
  [showClearAll]="getRevokeMode()"
  [(ngModel)]="roleId"
  *ngIf="calculatedEditMode"></ds-select>

<span *ngIf="!calculatedEditMode">{{ roleName }}</span>
