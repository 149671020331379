import { Pipe, PipeTransform } from '@angular/core';
import { HardwareVersion, ReducedVehicle, VehicleType } from 'core/models';

@Pipe({
  name: 'isUnitLoad',
})
export class IsUnitLoadPipe implements PipeTransform {
  transform(vehicleType: VehicleType | undefined): boolean {
    return vehicleType === VehicleType.UnitLoad;
  }
}
@Pipe({
  name: 'isTuggerTrain',
})
export class IsTuggerTrainPipe implements PipeTransform {
  transform(vehicleType: VehicleType): boolean {
    return vehicleType === VehicleType.TuggerTrain;
  }
}

@Pipe({
  name: 'is4AmTuggerTrain',
})
export class Is4AmTuggerTrainPipe implements PipeTransform {
  transform(vehicle: ReducedVehicle | undefined): boolean {
    return vehicle?.hardwareVersion === HardwareVersion.TuggerTrainFourAmV2;
  }
}

@Pipe({
  name: 'isForklift',
})
export class IsForkliftPipe implements PipeTransform {
  transform(vehicleType: VehicleType): boolean {
    return vehicleType === VehicleType.Forklift;
  }
}

@Pipe({
  name: 'isAgiloxForklift',
})
export class IsAgiloxForkliftPipe implements PipeTransform {
  transform(vehicle: ReducedVehicle | undefined): boolean {
    return (
      vehicle?.hardwareVersion === HardwareVersion.AgiloxONE ||
      vehicle?.hardwareVersion === HardwareVersion.AgiloxOCF
    );
  }
}

@Pipe({
  name: 'isNoEmulator',
})
export class IsNoEmulatorPipe implements PipeTransform {
  transform(vehicle: ReducedVehicle): boolean {
    return !vehicle.isEmulator;
  }
}

@Pipe({
  name: 'isDSTuggertrain',
})
export class IsDSTuggertrainPipe implements PipeTransform {
  transform(vehicle: ReducedVehicle): boolean {
    return (
      vehicle.vehicleType === VehicleType.TuggerTrain &&
      vehicle.hardwareVersion === HardwareVersion.TuggerTrainDsV1
    );
  }
}
