import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromErrors from './errors.reducer';

export const featureKey = 'errors';

export interface ErrorsFeatureState {
  [fromErrors.featureKey]: fromErrors.ErrorsState;
}

export function reducers(
  state: ErrorsFeatureState | undefined,
  action: Action
): ErrorsFeatureState {
  return combineReducers({
    [fromErrors.featureKey]: fromErrors.reducer,
  })(state, action);
}

export const getErrorsFeatureState = createFeatureSelector<ErrorsFeatureState>(featureKey);
export const getErrorsState = (state: ErrorsFeatureState): fromErrors.ErrorsState => state.errors;
