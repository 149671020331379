import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FunctionsModalInput } from '../functions-modal/functions-modal.component';

@Component({
  selector: 'app-evacuation-mode-modal',
  templateUrl: './evacuation-mode-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvacuationModeModalComponent implements OnChanges {
  @Input() isModalOpen = false;
  @Input() modalInputData: FunctionsModalInput = {
    title: '',
    description: '',
    heading: '',
    toggleOn: false,
  };

  @Output() readonly confirm = new EventEmitter<boolean>();
  form: UntypedFormGroup = new UntypedFormGroup({});

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnChanges({ isModalOpen }: TypedChanges<EvacuationModeModalComponent>): void {
    if (isModalOpen) {
      this.form = this.createFormGroup();
    }
  }

  private createFormGroup() {
    return this.formBuilder.group({
      confirm: [
        undefined,
        Validators.pattern(
          /^(deactivate|DEACTIVATE|Deactivate|deaktivieren|Deaktivieren|DEAKTIVIEREN)$/
        ),
      ],
    });
  }

  onClose(): void {
    this.confirm.emit(false);
  }

  onConfirm(): void {
    this.confirm.emit(true);
  }
}
