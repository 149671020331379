import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromFleets from './fleets.reducer';
import * as fromSoftwareUpdates from './software-update.reducer';
import * as fromVehicles from './vehicles.reducer';
import * as fromFactsheets from './factsheet.reducer';

export const featureKey = 'vehicles';

export interface VehiclesFeatureState {
  [fromVehicles.featureKey]: fromVehicles.VehiclesState;
  [fromFleets.featureKey]: fromFleets.FleetsState;
  [fromSoftwareUpdates.featureKey]: fromSoftwareUpdates.SoftwareUpdateState;
  [fromFactsheets.featureKey]: fromFactsheets.FactsheetState;
}

export function reducers(
  state: VehiclesFeatureState | undefined,
  action: Action
): VehiclesFeatureState {
  return combineReducers({
    [fromVehicles.featureKey]: fromVehicles.reducer,
    [fromFleets.featureKey]: fromFleets.reducer,
    [fromSoftwareUpdates.featureKey]: fromSoftwareUpdates.reducer,
    [fromFactsheets.featureKey]: fromFactsheets.reducer,
  })(state, action);
}

export const getVehiclesState = (state: VehiclesFeatureState): fromVehicles.VehiclesState =>
  state.vehicles;
export const getFleetsState = (state: VehiclesFeatureState): fromFleets.FleetsState => state.fleets;
export const getVehiclesFeatureState = createFeatureSelector<VehiclesFeatureState>(featureKey);
export const getSoftwareUpdatesState = (
  state: VehiclesFeatureState
): fromSoftwareUpdates.SoftwareUpdateState => state.softwareUpdates;

export const getFactsheetState = (state: VehiclesFeatureState): fromFactsheets.FactsheetState =>
  state.factsheets;
