import { GuidString } from '../core/string-typings';
import { PoiType } from '../pois/poi.enum';
import { LoadType } from '../vehicles/vehicle.enum';

export interface LoadTypeConfigurationDto {
  id: GuidString;
  isActive: boolean;
  poiTypes: PoiType[];
  loadType: LoadType;
  workAreaId: GuidString;
}

export interface LoadTypeConfigurationViewModel extends LoadTypeConfigurationDto {
  identifier: string;
}

export const LoadTypeIdentifier: Record<LoadType, string> = {
  [LoadType.Unknown]: 'Unknown',
  [LoadType.GltStrStandard]: 'GLT_1400x1250',
  [LoadType.Uglt]: 'UGLT_1680x1250',
  [LoadType.SpaMedium]: 'M_SPA_1260x1160',
  [LoadType.SeatDeb]: 'SEAT_DEB_820x1680',
  [LoadType.GltPalletCrosswise]: 'GLT_PD_1200x1000',
  [LoadType.GltPalletLengthwise]: 'GLT_PD_835x1240',
};
