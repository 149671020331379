import { PillarsGridDto } from 'core/dtos';
import { MapItemType, MapsFilter, Vector2D } from 'core/models';
import { MapLayerView } from 'modules/maps/models';
import { Container, Graphics, IPointData, Point } from 'pixi.js';
import { MapItemBase, MapItemContainer } from '../map-item-container';
import { PillarLabelMapItemGraphic } from './pillar-grid-label.graphic';
import { GridIconStyle, GridStyle } from './pillar-grid.constant';

export class PillarGridMapItem implements MapItemBase {
  readonly container: MapItemContainer;
  private readonly graphic: Graphics;
  private readonly namesContainer: Container;

  constructor(view: MapLayerView, grid: PillarsGridDto, private filter?: MapsFilter) {
    this.container = view.addChild(
      new MapItemContainer(grid.id.toString(), MapItemType.PillarGrid)
    );
    this.container.interactive = true;
    this.container.buttonMode = true;
    this.container.scale.set(1, -1);

    this.graphic = new Graphics();
    this.namesContainer = new Container();
    this.namesContainer.name = 'names';

    this.container.addChild(this.graphic, this.namesContainer);

    this.update(grid);
  }

  update(grid: PillarsGridDto): void {
    this.container.id = grid.id.toString();
    this.setPosition(grid.startPose2D);
    this.createGrid(grid);
    this.createNames(grid);
    this.setVisibility(this.filter);
  }

  private createGrid({
    columns,
    rows,
    distanceBetweenColumnsInMeters: width,
    distanceBetweenRowsInMeters: height,
  }: PillarsGridDto): void {
    this.graphic.clear().lineTextureStyle(GridStyle);

    const gridWidth = columns.length * width;
    const gridHeight = rows.length * height;

    for (let row = 0; row <= rows.length; row++) {
      this.addLine(new Point(0, row * height), new Point(gridWidth, row * height));
    }

    for (let column = 0; column <= columns.length; column++) {
      this.addLine(new Point(column * width, 0), new Point(column * width, gridHeight));
    }

    this.createEditIcons(gridWidth, gridHeight);
  }

  private createEditIcons(width: number, height: number): void {
    this.graphic.beginFill(GridIconStyle.color, GridIconStyle.alpha);

    this.addSquare(0, 0);
    this.addSquare(width, 0);
    this.addSquare(width, height);
    this.addSquare(0, height);

    this.graphic.endFill();
  }

  private createNames({
    id,
    columns,
    rows,
    distanceBetweenColumnsInMeters: width,
    distanceBetweenRowsInMeters: height,
  }: PillarsGridDto): void {
    this.namesContainer.removeChildren();

    for (let row = 0; row < rows.length; row++) {
      for (let column = 0; column < columns.length; column++) {
        const textItem = new PillarLabelMapItemGraphic(
          id.toString(),
          rows[row] + columns[column],
          width,
          height,
          row,
          column
        );

        this.namesContainer.addChild(textItem);
      }
    }
  }

  private addLine(from: Point, to: Point): Graphics {
    return this.graphic.moveTo(from.x, from.y).lineTo(to.x, to.y);
  }

  private addSquare(
    x: number,
    y: number,
    size: number = GridIconStyle.size,
    offset: number = GridIconStyle.offset
  ): Graphics {
    return this.graphic.drawRect(x - offset, y - offset, size, size);
  }

  setPosition(point: IPointData): void {
    this.container.position.copyFrom(point);
  }

  getPosition(): Vector2D {
    const { x, y } = this.container.position;
    return { x, y };
  }

  setVisibility(filter?: MapsFilter): void {
    if (!filter) {
      return;
    }

    this.filter = filter;
    this.container.visible = filter.pillarsGrid;
  }

  remove(): void {
    this.container.removeChildren();
    this.container.parent.removeChild(this.container);
  }

  toggleSelection(_isSelected: boolean): void {}
}
