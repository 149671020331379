<div *ngIf="(latestMessageObs | async) || isLoaded">
  <div data-cy="infobarSettings" class="container-fluid g-4x p-6x infobar">
    <div class="row">
      <ds-form-field class="col-3">
        <label ds-label for="infobarMessageText">
          {{ 'settings.infobarSettings.messageSeverity' | translate }}</label
        >
        <ds-select
          data-cy="infobarSeverity"
          [options]="optionItems"
          [(ngModel)]="infoType"
          placeholder="{{ 'placeholders.select' | translate }}"
          [filter]="false">
        </ds-select>
      </ds-form-field>

      <ds-form-field class="col-3">
        <label ds-label for="infobarExpiryTime">
          {{ 'settings.infobarSettings.messageExpiryDate' | translate }}</label
        >
        <p-calendar
          data-cy="infobarExpiryTime"
          id="infobarExpiryTime"
          hourFormat="24"
          [showIcon]="true"
          [minDate]="minDate"
          [defaultDate]="expiryTime"
          [dateFormat]="calendarLocale?.dateFormat || ''"
          [showTime]="true"
          [showButtonBar]="true"
          [(ngModel)]="expiryTime"
          [disabled]="false"></p-calendar>
      </ds-form-field>
    </div>

    <div class="row mt-4x">
      <div class="col">
        <ds-form-field class="pt-3x">
          <label ds-label for="infobarMessageText">
            {{ 'settings.infobarSettings.message' | translate }}</label
          >
          <textarea
            ds-input
            id="infobarMessageText"
            placeholder="{{ 'placeholders.define' | translate }}"
            [(ngModel)]="message"
            data-cy="infobarMessageText">
          </textarea>
        </ds-form-field>

        <button
          [disabled]="!infoType || !message"
          data-cy="infobarMessageSave"
          ds-button
          class="button mt-2x"
          (click)="onSave()">
          {{ 'settings.infobarSettings.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
