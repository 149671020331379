/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { RouterEvent } from '@angular/router';
import objectHelper from 'core/helpers/object.helper';
import { BasicMenuItem } from 'core/models';
import { AtsTranslationService } from 'core/services/ats-translation.service';
import { ExpandCollapseButtonsService } from 'library/components/expand-collapse-buttons/service/expand-collapse-buttons.service';
import { TreeTableComponent } from 'library/components/tree-table/tree-table.component';
import { TreeTableSortOptions } from 'library/components/tree-table/tree-table.model';
import { TableColumns } from 'library/models';
import { TreeNode } from 'primeng/api';
import { TreeTablePaginatorState } from 'primeng/treetable';
import * as localStorageHelper from '../../helpers/local-storage.helper';

@Component({
  selector: 'app-tree-table-reorderable',
  templateUrl: './tree-table-reorderable.component.html',
  styleUrls: [
    './tree-table-reorderable.component.scss',
    '../../../library/components/tree-table/tree-table.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeTableReorderableComponent extends TreeTableComponent implements OnInit {
  @Input() actionItems = new Array<BasicMenuItem>();
  defaultDisplayedColumns: TableColumns[] = [];
  tempDisplayedColumns: TableColumns[] = [];
  isResetButtonVisible = false;

  constructor(
    protected readonly expandCollapseButtonsService: ExpandCollapseButtonsService,
    protected readonly translate: AtsTranslationService,
    protected readonly cdRef: ChangeDetectorRef
  ) {
    super(expandCollapseButtonsService, translate, cdRef);
  }

  ngOnInit(): void {
    this.isTableConfigurationModeActive = false;
    this.updateColumnConfigurableColumnSettings();
  }

  saveReorderedColumns(_event: Event | RouterEvent): void {
    this.isTableConfigurationModeActive = false;
    localStorageHelper.setTableColumnOrder(this.columns, this.translationContext);
    this.checkStoredColumnOrder(this.columns);
  }

  resetReorderedColumns(_event: Event | RouterEvent): void {
    this.columns = objectHelper.cloneDeep(this.defaultDisplayedColumns);
    this.checkStoredColumnOrder(this.columns);
  }

  editReorderedColumns(_event: Event | RouterEvent): void {
    this.isTableConfigurationModeActive = !this.isTableConfigurationModeActive;

    if (this.isTableConfigurationModeActive) {
      this.tempDisplayedColumns = objectHelper.cloneDeep(this.columns);
    } else {
      this.columns = this.tempDisplayedColumns;
    }

    this.checkStoredColumnOrder(this.columns);
  }

  tableColumnsToString(tableColumns: TableColumns[]): string {
    for (const col of tableColumns) {
      col.isHidden = col.isHidden !== true ? false : true;
    }

    return JSON.stringify(tableColumns);
  }

  checkStoredColumnOrder(savedColumnOrder: TableColumns[]): boolean {
    const savedColumns = this.tableColumnsToString(savedColumnOrder);
    const defaultColumns = this.tableColumnsToString(this.defaultDisplayedColumns);
    return (this.isResetButtonVisible =
      savedColumnOrder.length > 0 && savedColumns !== defaultColumns);
  }

  getSavedReorderedColumns(): void {
    const savedValue = localStorageHelper.getTableColumnOrder(this.translationContext);
    if (this.checkStoredColumnOrder(savedValue)) {
      this.columns = savedValue;
    }
  }

  updateColumnConfigurableColumnSettings(): void {
    if (this.defaultDisplayedColumns.length === 0) {
      this.defaultDisplayedColumns = objectHelper.cloneDeep(this.columns);
    }

    if (!this.isTableConfigurationModeActive) {
      this.getSavedReorderedColumns();
    }
  }

  onNodeClick(node: TreeNode): void {
    this.selectedNode = node;
    this.nodeClick.emit(node);
  }

  onNodeNavigate(node: TreeNode): void {
    this.nodeNavigate.emit(node);
  }

  onChangePage(node: TreeTablePaginatorState): void {
    this.changePage.emit(node);
  }

  onSaveTableSort(sortOptions: TreeTableSortOptions): void {
    this.saveTableSort.emit(sortOptions);
  }

  onColReordered(): void {
    this.checkStoredColumnOrder(this.columns);
  }

  onToolbarSecondaryButtonClick(command?: Function): void {
    command && command();
  }
}
