import { DateString } from 'core/models';

export interface GraphManagerSettings {
  enableGraphManager?: boolean;
  maxVehicleDistance?: number;
  isBeginShiftModeEnabled?: boolean;
  enableLatestDeliveryTimeAssignment?: boolean;
}

export interface GraphManagerSettingsDto {
  settings: GraphManagerSettings;
  graphManagerEnabledUpdatedTimeUtc: DateString | null;
  isBeginShiftModeEnabledUpdatedTimeUtc: DateString | null;
  latestDeliveryTimeAssignmentEnabledModifiedUtc: DateString | null;
}

export interface GmParkingAndChargingSettingsDto {
  isEnabled: boolean;
  thresholds: GmParkingAndChargingThresholds;
  vehicleToggles: GmParkingAndChargingVehicleSettings;
  enabledUpdatedTimeUtc: DateString | null;
}

export interface GmParkingAndChargingThresholds {
  minimumBatteryThreshold?: BatteryThresholdPerVehicle;
  adequateBatteryThreshold?: BatteryThresholdPerVehicle;
  maximumBatteryThreshold?: BatteryThresholdPerVehicle;
  fullBatteryThreshold?: BatteryThresholdPerVehicle;
}

export interface BatteryThresholdPerVehicle {
  dsTugger: number;
  dsUagv: number;
  schillerForklift: number;
}

export interface GmParkingAndChargingVehicleSettings {
  dsTugger: boolean;
  dsUagv: boolean;
  schillerForklift: boolean;
}

export function getDefaultGraphManagerFeatures(): GraphManagerSettingsDto {
  return {
    graphManagerEnabledUpdatedTimeUtc: null,
    isBeginShiftModeEnabledUpdatedTimeUtc: null,
    latestDeliveryTimeAssignmentEnabledModifiedUtc: null,
    settings: {
      enableGraphManager: false,
      isBeginShiftModeEnabled: false,
    },
  };
}

export function getDefaultGraphManagerParkingAndChargingSettings(): GmParkingAndChargingSettingsDto {
  return {
    isEnabled: false,
    thresholds: {
      minimumBatteryThreshold: {
        dsTugger: 40,
        dsUagv: 40,
        schillerForklift: 40,
      },
      adequateBatteryThreshold: {
        dsTugger: 60,
        dsUagv: 60,
        schillerForklift: 60,
      },
      maximumBatteryThreshold: {
        dsTugger: 80,
        dsUagv: 80,
        schillerForklift: 80,
      },
    },
    vehicleToggles: {
      dsTugger: false,
      dsUagv: false,
      schillerForklift: false,
    },
    enabledUpdatedTimeUtc: null,
  };
}
