import { EdgeSegment, GraphLayout, Pose2D } from 'core/models';
import { Colors } from 'library/styles';
import { first } from 'lodash';
import { MapPixiHelper } from 'modules/maps/helpers';
import { Graphics } from 'pixi.js';
import { MapLayerDrawing } from '../map-layer-drawing';
import { EdgeContainerBase } from './base-edge-container.graphic';
import { EdgeStyle } from './graph-layer.constant';

export class SegmentContainer extends EdgeContainerBase {
  constructor(drawing: MapLayerDrawing, protected layout: GraphLayout) {
    super();

    drawing.addChild(this);
    this.interactive = false;

    layout.segments.forEach(s => this.createSegmentGraphic(s));
  }

  createSegmentGraphic(segment: EdgeSegment): void {
    const line = new Graphics().lineStyle(
      EdgeStyle.EdgeSize,
      Colors.graphLayer.Edges,
      EdgeStyle.EdgeAlpha
    );

    const start = first(segment.positions);

    if (start) {
      line.moveTo(start.x, start.y);
    }

    let prevPose: Pose2D | undefined;

    for (const currentPose of segment.positions.slice(1)) {
      if (prevPose) {
        line.lineTo(currentPose.x, currentPose.y);
        this.addDirectionArrow(prevPose, currentPose);
      } else {
        line.lineTo(currentPose.x, currentPose.y);
      }

      prevPose = currentPose;
    }

    this.addChild(line);
  }

  addDirectionArrow(prevPose: Pose2D, currentPose: Pose2D): void {
    const arrow = MapPixiHelper.createArrowBetweenPositions(prevPose, currentPose, {
      color: Colors.graphLayer.Edges,
      alpha: EdgeStyle.ArrowAlpha,
    });

    this.addChild(arrow);
  }
}
