export interface Role {
  id: number;
  name: string;
  scope: RoleScope;
  permissions: string[];
}

export enum RoleScope {
  Environment,
  Organization,
  WorkingArea,
}

export interface RolesTreeViewModel {
  id: string;
  groupName: string;
  children?: Record<string, unknown>[];
}

export interface RoleMenuModal {
  id: string;
  type: string;
}
