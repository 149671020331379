<ds-form-field>
  <ds-select
    ds-form-validation
    data-cy="poisDropdown"
    [labellingConfig]="labellingConfig"
    [options]="options | dsDropdown : 'pointOfInterestName' : 'pointOfInterestId'"
    [(ngModel)]="selectedPoiId"
    [disabled]="disabled"
    required
    (optionSelected)="onPoiSelectionChange()"></ds-select>
</ds-form-field>
