import { MapLabelColors } from 'library/styles';

export const NavigationLayerStyle = {
  x: 3,
  y: 1,
  border: 2,
  borderX: -1,
  borderY: -1,
};

export const OffSetPanelStyle = {
  scale: 0.11,
  panelColor: MapLabelColors.Background,
  width: 6,
  height: 6,
  rounding: 1,
  positionX: 6,
  positionY: 4,
};
