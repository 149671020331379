<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="endOfShiftToggle"
      translationKey="settings.functions.endOfShift"
      description="settings.featureToggles.endOfShiftSection.endOfShiftDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleEndOfShift' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [isLoaded]="jobManagerSettingsLoaded"
      [toggle]="viewModel.endOfShiftMode"
      [requiredPermission]="'ToggleEndOfShift'"
      (saveToggle)="onChangeEndOfShift($event)"></app-toggle>
  </ds-box-content>
</ds-box>
<ds-box class="mb-4x" data-cy="card">
  <ds-box-header>
    <h5>{{ 'settings.functions.shutdownMode.title' | translate }}</h5>
  </ds-box-header>
  <ds-box-content>
    <ng-container *ngFor="let shutdownModeToggle of viewModel.shutdownMode; let isLast = last">
      <app-shutdown-mode-toggle
        data-cy="shutdownModeToggle"
        [toggle]="shutdownModeToggle"
        [isLoaded]="fleetSettingsLoaded"
        [disabled]="!viewModel.endOfShiftMode.isToggledOn"
        [requiredPermission]="'ToggleMassShutdown'"
        [allVehicles]="allVehicles"
        [showVehiclesToExclude]="
          showVehiclesToExcludeConfiguration[shutdownModeToggle.vehicleShutdownType]
        "
        (saveToggle)="onChangeShutDown($event)">
      </app-shutdown-mode-toggle>
      <div *ngIf="!isLast" class="mb-10x"></div>
    </ng-container>
  </ds-box-content>
</ds-box>
<app-collective-pause-toggle
  data-testid="collective-pause-toggle"
  [isLoaded]="fleetSettingsLoaded"
  [collectivePauseToggle]="fleetManagerSettings.collectivePauseToggle"
  description="settings.featureToggles.collectivePauseSection.collectivePauseDescription"></app-collective-pause-toggle>
<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-evacuation-mode-toggle
      data-cy="evacuationModeToggle"
      translationKey="settings.functions.evacuationMode"
      description="settings.featureToggles.evacuationModeSection.evacuationModeDescription"
      tooltipText="{{
        fireFighterHasEnabledEvacuationMode
          ? ('settings.roles.permissions.Tooltip_Pretext' | translate) +
            ('settings.roles.permissions.ToggleEmergencyStrategyWelcomePage' | translate) +
            ('settings.roles.permissions.Tooltip_Posttext' | translate)
          : ('settings.roles.permissions.Tooltip_Pretext' | translate) +
            ('settings.roles.permissions.ToggleEmergencyStrategyWorkingArea' | translate) +
            ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [disabled]="fireFighterHasEnabledEvacuationMode"
      [toggle]="viewModel.evacuationMode"
      [isLoaded]="fleetSettingsLoaded"
      [requiredPermission]="'ToggleEmergencyStrategyWorkingArea'"
      [evacuationModeTriggerActive]="evacuationModeTriggerActive"
      (saveToggle)="onChangeEvacuation($event)"></app-evacuation-mode-toggle>
    <div class="d-flex">
      <app-labeled-input
        label="{{
          'settings.featureToggles.evacuationModeSection.evacuationDeviceConfigLabel' | translate
        }}">
      </app-labeled-input>
      <span data-cy="evacuationDevicesConfigButton" (click)="openWagoTerminalsConfig()"
        ><i class="pi pi-pencil ms-2x"></i
      ></span>
    </div>
  </ds-box-content>
</ds-box>
<ds-box *ngIf="graphManagerEnabled" class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="beginShiftModeToggle"
      translationKey="settings.functions.beginShiftMode"
      description="settings.featureToggles.beginShiftModeSection.beginShiftModeDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleEndOfShift' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [isLoaded]="graphManagerSettingsLoaded"
      [toggle]="viewModel.beginShiftMode"
      [requiredPermission]="'ToggleEmergencyStrategyWorkingArea'"
      (saveToggle)="onChangeBeginShift($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<app-wago-evacuation-devices-config-modal
  [evacuationDevices]="evacuationDevices"
  [allOpcuaDevices]="allOpcuaDevices"
  [currentWorkingArea]="currentWorkingArea"
  [isModalOpen]="isModalOpen"
  (confirm)="onEvacuationDevicesModalConfirm($event)"
  (dismissModal)="dismissModal()"
  (selectOpcuaDevice)="onSelectOpcuaDevice($event)">
</app-wago-evacuation-devices-config-modal>
