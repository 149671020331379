import { PoiDto } from 'core/dtos';
import { OccupancyStatus, PoiType } from '../pois/poi.enum';
import { LoadType } from '../vehicles/vehicle.enum';

export const initPoiDto: PoiDto = {
  id: '',
  entryVectorDistance: 0,
  exitVectorDistance: 0,
  isCloseToWall: null,
  mainPose: { x: 0, y: 0, orientation: 0 },
  mapId: '',
  name: '',
  occupancy: {
    occupancyStatus: OccupancyStatus.Free,
  },
  poseAccuracy: null,
  type: PoiType.WayPoint,
  occupancyDevice: null,
  booked: false,
  loadType: LoadType.GltStrStandard,
};
