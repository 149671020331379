import { Params } from 'core/http/base-http-client';

export class ODataQueryBuilder {
  constructor(
    public orderBy: string | string[] | null | undefined,
    public direction: string,
    public pageSize?: number,
    public pageSkip: number = 0,
    public filter?: string
  ) {}

  getHttpParams(): Params {
    const params: Params = {};

    params.$orderby = `${this.orderBy} ${this.direction}`;
    params.$count = 'true';

    if (this.filter) {
      params.$filter = this.filter;
    }

    if (this.pageSkip !== undefined && this.pageSize !== undefined) {
      params.$top = this.pageSize;
      params.$skip = this.pageSkip;
    }

    return params;
  }
}
