import { OpcuaDeviceTrafficLightStatus, PoiDeviceOccupancy } from 'core/models';

export const opcuaDeviceTrafficLightStatus = {
  [OpcuaDeviceTrafficLightStatus.Connected]: '#61D13E',
  [OpcuaDeviceTrafficLightStatus.NotInitialized]: '#8f8f8f',
  [OpcuaDeviceTrafficLightStatus.LostConnection]: '#EB555A',
};

export const deviceOccupancy = {
  [PoiDeviceOccupancy.Unknown]: '#c1c5cb',
  [PoiDeviceOccupancy.Loaded]: '#008ff0',
  [PoiDeviceOccupancy.Empty]: '#c1c5cb',
};
