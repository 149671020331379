import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { EvacuationDeviceSettingsService } from 'core/api-services';
import {
  EvacuationDeviceDto,
  FeatureToggle,
  FleetManagerFeatures,
  JobManagerFeatures,
  OpcuaDeviceResponseModel,
  ShutdownModeDto,
  VehicleDto,
  WorkingAreaDto,
  getDefaultFleetManagerFeatures,
  getDefaultJobManagerFeatures,
  getDefaultShutdownModeDto,
} from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { EvacuationDeviceItem, EvacuationDevices, VehiclePowerSavingType } from 'core/models';
import { AtsTranslationService, ToastService } from 'core/services';
import { OperationsViewModel } from 'modules/settings/components/operation/operations.viewmodel';
import { Subject, take } from 'rxjs';
import * as fromSettings from 'store-modules/settings-store';
@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationComponent implements OnChanges {
  ngUnsubscribe = new Subject<void>();
  viewModel: OperationsViewModel;
  fireFighterHasEnabledEvacuationMode = false;
  isModalOpen = false;

  @Input()
  readonly fleetManagerSettings: FleetManagerFeatures = getDefaultFleetManagerFeatures();
  @Input() fleetSettingsLoaded = false;

  @Input() readonly beginShiftSetting: FeatureToggle = {
    isToggledOn: false,
    dateUpdated: null,
  };
  @Input() readonly enableGraphManager: FeatureToggle = {
    isToggledOn: false,
    dateUpdated: null,
  };
  @Input() graphManagerSettingsLoaded = false;

  @Input() readonly jobManagerSettings: JobManagerFeatures = getDefaultJobManagerFeatures();
  @Input() jobManagerSettingsLoaded = false;

  @Input() readonly evacuationDevices: EvacuationDeviceDto[] = [];
  @Input() readonly allOpcuaDevices: OpcuaDeviceResponseModel[] = [];
  @Input() readonly currentWorkingArea: WorkingAreaDto | undefined;

  @Input() readonly evacuationModeTriggerActive: boolean = false;
  @Input() allVehicles: VehicleDto[] = [];

  @Output() readonly saveEndOfShiftMode = new EventEmitter<boolean>();
  @Output() readonly saveShutDownMode = new EventEmitter<ShutdownModeDto>();
  @Output() readonly saveEvacuationMode = new EventEmitter<boolean>();
  @Output() readonly saveBeginShiftMode = new EventEmitter<boolean>();

  @Output() readonly selectOpcuaDevice = new EventEmitter<OpcuaDeviceResponseModel>();

  @Input() readonly beginShiftModeTriggerActive: boolean = false;

  graphManagerEnabled = false;

  showVehiclesToExcludeConfiguration = {
    [VehiclePowerSavingType.Str]: true,
    [VehiclePowerSavingType.Vda5050]: false,
  };

  constructor(
    private readonly toastService: ToastService,
    private readonly evacuationDeviceSettingsService: EvacuationDeviceSettingsService,
    protected readonly translate: AtsTranslationService,
    private readonly store: Store<fromSettings.SettingsFeatureState>,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.viewModel = this.generateViewModel();
  }

  ngOnChanges({
    jobManagerSettings,
    fleetManagerSettings,
    beginShiftSetting,
    enableGraphManager,
  }: TypedChanges<OperationComponent>): void {
    if (jobManagerSettings?.currentValue) {
      this.viewModel = {
        ...this.viewModel,
        endOfShiftMode:
          objectHelper.cloneDeep(jobManagerSettings.currentValue.endOfShiftModeToggle) ??
          this.viewModel.endOfShiftMode,
      };
    }

    if (enableGraphManager?.currentValue) {
      this.graphManagerEnabled =
        objectHelper.cloneDeep(enableGraphManager?.currentValue.isToggledOn) ??
        this.graphManagerEnabled;
    }

    if (beginShiftSetting?.currentValue) {
      this.viewModel = {
        ...this.viewModel,
        beginShiftMode:
          objectHelper.cloneDeep(beginShiftSetting.currentValue) ?? this.viewModel.beginShiftMode,
      };
    }

    if (fleetManagerSettings?.currentValue) {
      this.viewModel = {
        ...this.viewModel,
        shutdownMode:
          objectHelper.cloneDeep(fleetManagerSettings.currentValue.shutdownModeToggle) ??
          this.viewModel.shutdownMode,
      };

      this.viewModel = {
        ...this.viewModel,
        evacuationMode:
          objectHelper.cloneDeep(fleetManagerSettings.currentValue.evacuationModeToggle) ??
          this.viewModel.evacuationMode,
      };

      this.fireFighterHasEnabledEvacuationMode = fleetManagerSettings.currentValue
        .firefighterEvacuationModeToggle
        ? fleetManagerSettings.currentValue.firefighterEvacuationModeToggle.isToggledOn
        : false;

      this.cdRef.markForCheck();
    }
  }

  generateViewModel(): OperationsViewModel {
    return {
      endOfShiftMode: { isToggledOn: false, dateUpdated: null },
      shutdownMode: getDefaultShutdownModeDto(),
      evacuationMode: { isToggledOn: false, dateUpdated: null },
      beginShiftMode: { isToggledOn: false, dateUpdated: null },
    };
  }

  onChangeEndOfShift(value: boolean): void {
    this.saveEndOfShiftMode.emit(value);

    const toggled = this.viewModel.shutdownMode.filter(s => s.isToggledOn);
    if (!value && toggled.length) {
      toggled.forEach(s => {
        s.isToggledOn = false;
        s.endShutdownAndWakeUpVehiclesDateTime = null;
        this.saveShutDownMode.emit(s);
      });
    }
  }

  onChangeShutDown(value: ShutdownModeDto): void {
    this.saveShutDownMode.emit(value);
  }

  onChangeEvacuation(value: boolean): void {
    this.saveEvacuationMode.emit(value);
  }

  openWagoTerminalsConfig(): void {
    this.isModalOpen = true;
  }

  onChangeBeginShift(value: boolean): void {
    this.saveBeginShiftMode.emit(value);
  }

  async deleteDevices(deletedItems: EvacuationDeviceItem[]): Promise<number> {
    return new Promise<number>(resolve => {
      if (deletedItems.length === 0) {
        resolve(0);
      } else {
        deletedItems.forEach((deleteItem, index, array) => {
          this.evacuationDeviceSettingsService
            .deleteByDeviceName(deleteItem.device)
            .pipe(take(1))
            .subscribe({
              error: () => {
                resolve(-1);
              },
              complete: () => {
                const isLastDeleteItem = index === array.length - 1;
                if (isLastDeleteItem) {
                  resolve(array.length);
                }
              },
            });
        });
      }
    });
  }

  async updateDevices(updatedItems: EvacuationDeviceItem[]): Promise<number> {
    return new Promise<number>(resolve => {
      if (updatedItems.length === 0) {
        resolve(0);
      } else {
        updatedItems.forEach((updateItem, index, array) => {
          this.evacuationDeviceSettingsService
            .updateDeviceSetting(updateItem)
            .pipe(take(1))
            .subscribe({
              error: () => {
                resolve(-1);
              },
              complete: () => {
                const isLasUpdateItem = index === array.length - 1;
                if (isLasUpdateItem) {
                  resolve(array.length);
                }
              },
            });
        });
      }
    });
  }

  async onEvacuationDevicesModalConfirm(content: EvacuationDevices): Promise<void> {
    const deletedItems = content.baseContent.filter(function (item: EvacuationDeviceItem) {
      return content?.devices.filter(i => i.device === item.device).length === 0;
    });

    await this.updateDevices(content.devices).then(async updateResponse => {
      if (updateResponse !== -1) {
        await this.deleteDevices(deletedItems).then(deleteResponse => {
          if (deleteResponse !== -1) {
            this.toastService.createSuccessToast(
              'settings.functions.wagoConfigDialog.messages.successToastMessage'
            );
            this.store.dispatch(
              fromSettings.loadEvacuationDeviceSettingsSuccess({ devices: content.devices })
            );
            this.dismissModal();
          } else {
            this.toastService.createErrorToast(
              'settings.functions.wagoConfigDialog.messages.errorToastMessage'
            );
          }
        });
      } else {
        this.toastService.createErrorToast(
          'settings.functions.wagoConfigDialog.messages.errorToastMessage'
        );
      }
    });
  }

  dismissModal(): void {
    this.isModalOpen = false;
  }

  onSelectOpcuaDevice(device: OpcuaDeviceResponseModel): void {
    this.selectOpcuaDevice.emit(device);
  }
}
