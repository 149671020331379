<div class="flex-stretch-vertically">
  <div class="page-main-header wrap-reverse">
    <div class="header-container">
      <div
        data-cy="pageHeading"
        [ngClass]="{ heading: secondaryPageHeading }"
        class="title-lg"
        [attr.data-translatekey]="pageHeading">
        {{ pageHeading | translate }}
      </div>
      <ds-icon
        *ngIf="secondaryPageHeading"
        class="mx-1x pt-1x"
        icon="arrow_right_small"
        tone="neutral"
        [size]="26"></ds-icon>
      <div
        data-cy="secondaryPageHeading"
        [attr.data-translatekey]="secondaryPageHeading"
        class="title-lg">
        {{ secondaryPageHeading | translate }}
      </div>
    </div>
    <ng-content class="content"></ng-content>
  </div>
</div>
