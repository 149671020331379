import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { firstValueFrom } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.VehicleCommands;

  protected apiUrl = environment.Services.FleetManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  async pauseVehicle(id: GuidString): Promise<void> {
    const options = { connectionTimeoutInSeconds: 5, responseTimeoutInSeconds: 8 };
    return firstValueFrom(this.post<void>(`${this.servicePath}/pause/${id}`, options));
  }

  async resumeVehicle(id: GuidString): Promise<void> {
    const options = { connectionTimeoutInSeconds: 5, responseTimeoutInSeconds: 8 };
    return firstValueFrom(this.post<void>(`${this.servicePath}/resume/${id}`, options));
  }
}
