import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { NewPsaNodeMappingDto, PsaNodeMappingDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class PsaNodeMappingService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.PsaNodeMappings;

  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getAllPsaNodeMappings(): Observable<PsaNodeMappingDto[]> {
    return this.get<PsaNodeMappingDto[]>(`${this.servicePath}`);
  }

  createPsaNodeMapping(psaNodeMapping: NewPsaNodeMappingDto): Observable<PsaNodeMappingDto> {
    return this.post<PsaNodeMappingDto>(`${this.servicePath}`, psaNodeMapping);
  }

  updatePsaNodeMapping(psaNodeMapping: PsaNodeMappingDto): Observable<PsaNodeMappingDto> {
    return this.put<PsaNodeMappingDto>(`${this.servicePath}/${psaNodeMapping.id}`, psaNodeMapping);
  }

  deletePsaNodeMapping(id: GuidString): Observable<void> {
    return this.delete<void>(`${this.servicePath}/${id}`);
  }
}
