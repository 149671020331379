import { createAction, props } from '@ngrx/store';
import {
  MissionTraceAbortOptionsDto,
  ProcessChainGroupTraceDto,
  ProcessChainTraceInGroupDto,
} from 'core/dtos';
import { GuidString } from 'core/models';

export enum ProcessChainGroupTraceTypes {
  AbortProcessChainGroupTrace = '[Monitoring] Abort Process Chain Group Trace',
  AbortProcessChainGroupTraceSuccess = '[Monitoring] Abort Process Chain Group Trace Success',
  AbortProcessChainGroupTraceFailure = '[Monitoring] Abort Process Chain Group Trace Failure',
  LoadProcessChainGroupTraces = '[Monitoring] Load Process Chain Group Traces',
  LoadProcessChainGroupTracesSuccess = '[Monitoring] Load Process Chain Group Traces Success',
  LoadProcessChainGroupTracesFailure = '[Monitoring] Load Process Chain Group Traces Failure',
  DeleteCompletedProcessChainGroupTrace = '[Monitoring] Remove Completed Process Chain Group Trace',
  SignalRDeleteProcessChainGroupTrace = '[Monitoring] Remove Process Chain Group Trace',
  SignalRAddProcessChainGroupTrace = '[SignalR] Add Process Chain Group Trace',
  SignalRUpdateProcessChainGroupTrace = '[SignalR] Update Process Chain Group Trace',
  SignalRDeleteProcessChainTrace = '[Monitoring] Remove Process Chain Trace',
  SignalRAddProcessChainTrace = '[SignalR] Add Process Chain Trace',
  SignalRUpdateProcessChainTrace = '[SignalR] Update Process Chain Trace',
}

export const abortProcessChainGroupTrace = createAction(
  ProcessChainGroupTraceTypes.AbortProcessChainGroupTrace,
  props<{ processChainGroupTraceId: GuidString; abortOptions: MissionTraceAbortOptionsDto }>()
);

export const abortProcessChainGroupTraceSuccess = createAction(
  ProcessChainGroupTraceTypes.AbortProcessChainGroupTraceSuccess
);

export const abortProcessChainGroupTraceFailure = createAction(
  ProcessChainGroupTraceTypes.AbortProcessChainGroupTraceFailure,
  props<{ errorMessage: string }>()
);

export const loadProcessChainGroupTraces = createAction(
  ProcessChainGroupTraceTypes.LoadProcessChainGroupTraces
);

export const loadProcessChainGroupTracesSuccess = createAction(
  ProcessChainGroupTraceTypes.LoadProcessChainGroupTracesSuccess,
  props<{ processChainGroupTraces: ProcessChainGroupTraceDto[] }>()
);

export const loadProcessChainGroupTracesFailure = createAction(
  ProcessChainGroupTraceTypes.LoadProcessChainGroupTracesFailure,
  props<{ errorMessage: string }>()
);

export const signalRAddProcessChainGroupTrace = createAction(
  ProcessChainGroupTraceTypes.SignalRAddProcessChainGroupTrace,
  props<{ processChainGroupTrace: ProcessChainGroupTraceDto }>()
);

export const signalRUpdateProcessChainGroupTrace = createAction(
  ProcessChainGroupTraceTypes.SignalRUpdateProcessChainGroupTrace,
  props<{ processChainGroupTrace: ProcessChainGroupTraceDto }>()
);

export const deleteCompletedProcessChainGroupTrace = createAction(
  ProcessChainGroupTraceTypes.DeleteCompletedProcessChainGroupTrace,
  props<{ processChainGroupTrace: ProcessChainGroupTraceDto }>()
);

export const signalRDeleteProcessChainGroupTrace = createAction(
  ProcessChainGroupTraceTypes.SignalRDeleteProcessChainGroupTrace,
  props<{ processChainGroupTrace: ProcessChainGroupTraceDto }>()
);

export const signalRAddProcessChainTrace = createAction(
  ProcessChainGroupTraceTypes.SignalRAddProcessChainTrace,
  props<{ processChainTrace: ProcessChainTraceInGroupDto }>()
);

export const signalRUpdateProcessChainTrace = createAction(
  ProcessChainGroupTraceTypes.SignalRUpdateProcessChainTrace,
  props<{ processChainTrace: ProcessChainTraceInGroupDto }>()
);

export const signalRDeleteProcessChainTrace = createAction(
  ProcessChainGroupTraceTypes.SignalRDeleteProcessChainTrace,
  props<{ processChainTrace: ProcessChainTraceInGroupDto }>()
);
