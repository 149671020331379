<ds-modal
  size="md"
  #userSettingsModal
  [ds-modal-trigger-for]="userSettingsModal"
  [(isModalOpen)]="isModalOpen"
  (modalDismiss)="onClose()">
  <ds-box-header data-cy="dialogHeader">{{
    'userProfile.myAtsSettings' | translate
  }}</ds-box-header>
  <ds-box-content divider="full" data-cy="dialog-content">
    <div class="options">
      <app-user-settings-list-options
        (selectListOption)="setSelectedListItem($event)"></app-user-settings-list-options>
      <ng-container *ngIf="selectedListItem === 'userProfile.defineStartPage'">
        <app-start-page-settings
          [initiallySelectedStartPage]="settings.defaultStartPage"
          (selectedPreference)="setStartPageSettings($event)"></app-start-page-settings>
      </ng-container>
    </div>
  </ds-box-content>
  <ds-box-footer data-cy="dialog-footer">
    <button ds-button data-cy="cancelButton" [variant]="'outline'" (click)="onClose()">
      {{ 'shared.modalButtons.CancelButton' | translate }}
    </button>
    <button
      ds-button
      data-cy="modalConfirm"
      class="ms-2x"
      [disabled]="!settings"
      (click)="onSave()">
      {{ 'shared.modalButtons.SaveButton' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
