import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ToastService } from 'core/services';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyButtonComponent {
  @Input() value = '';
  @Input() tooltip = 'shared.copyToClipboard.tooltip';

  constructor(private readonly toastService: ToastService) {}

  onCopy(success: boolean): void {
    if (success) {
      this.toastService.createSuccessToast('shared.copyToClipboard.copySuccess');
    } else {
      this.toastService.createErrorToast('shared.copyToClipboard.copyFailed');
    }
  }
}
