/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { LifService, NavigationLayerService } from 'core/api-services';
import {
  LIFUploadModel,
  LayerCreateStatus,
  LayersCreateModel,
  LifUpdatePreview,
  LifUploadResponse,
  NavigationLayerCreateModel,
  NavigationLayerResponseModel,
} from 'core/dtos';
import { GuidString, MapMode } from 'core/models';
import { ToastService } from 'core/services/toast.service';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { setHasChanges } from 'store/actions/ui.actions';
import { NavigationLayerTypes } from '../actions/navigation-layers.actions';

import * as MapsActions from '../actions/maps.actions';
import * as NavigationLayersActions from '../actions/navigation-layers.actions';

type PostCreateActionType =
  | Action<NavigationLayerTypes.CreateLayerSuccess>[]
  | ({
      navigationLayer: NavigationLayerCreateModel;
    } & Action<NavigationLayerTypes.UpdateNavigationLayerOffset>)[];

type PostUpdateActionType =
  | Action<NavigationLayerTypes.UpdateNavigationLayerBatchOffsetSuccess>[]
  | ({
      navigationLayers: NavigationLayerResponseModel[];
    } & Action<NavigationLayerTypes.UpdateNavigationLayerBatchOffset>)[];

@Injectable()
export class NavigationLayersEffects {
  loadNavigationLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.loadNavigationLayers),
      concatMap(() =>
        this.navigationLayerService.getNavigationLayers().pipe(
          map(navigationLayers =>
            NavigationLayersActions.loadNavigationLayersSuccess({ navigationLayers })
          ),
          catchError(errorMessage =>
            of(NavigationLayersActions.loadNavigationLayersFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadNavigationLayersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationLayersActions.loadNavigationLayersFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.loadNavigationLayersFailure');
        })
      ),
    { dispatch: false }
  );

  createSignalRNavigationLayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.signalRCreateNavigationLayer),
      concatMap(state =>
        this.navigationLayerService.getNavigationLayerImage(state.navigationLayer.id).pipe(
          map(imageUrl =>
            NavigationLayersActions.addNavigationLayerSuccess({
              newNavigationLayer: { ...state.navigationLayer, imageUrl },
            })
          )
        )
      )
    )
  );

  updateSignalRNavigationLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.signalRUpdateNavigationLayer),
      concatMap(state =>
        combineLatest([
          this.navigationLayerService.getNavigationLayerImage(state.navigationLayer.id),
        ]).pipe(
          map(([imageUrl]) =>
            NavigationLayersActions.signalRUpdateNavigationLayerSuccess({
              navigationLayer: { ...state.navigationLayer, imageUrl },
            })
          )
        )
      )
    )
  );

  getCreateLayer(newLayer: LayersCreateModel): Observable<NavigationLayerResponseModel> {
    return this.navigationLayerService.createNavigationLayer(newLayer).pipe(
      concatMap(layer => {
        return of(layer);
      })
    );
  }

  importLifFile(lifFile: LIFUploadModel): Observable<LifUploadResponse> {
    return this.lifService.importLifFile(lifFile).pipe(
      concatMap(response => {
        return of(response);
      })
    );
  }

  previewUpdateLif(lifFile: LIFUploadModel): Observable<LifUpdatePreview> {
    return this.lifService.previewUpdateLif(lifFile).pipe(
      concatMap(response => {
        return of(response);
      })
    );
  }

  updateLif(lifUpdatePreview: LifUpdatePreview): Observable<LifUpdatePreview> {
    return this.lifService.updateLif(lifUpdatePreview).pipe(
      concatMap(response => {
        return of(response);
      })
    );
  }

  getNavigationLayersIdsWithLif(mapId: GuidString): Observable<GuidString[]> {
    return this.lifService.getNavigationLayersIdsWithLif(mapId).pipe(
      concatMap(ids => {
        return of(ids);
      })
    );
  }

  getPostCreateAction(
    request: LayersCreateModel,
    response: NavigationLayerResponseModel
  ): PostCreateActionType {
    return [
      NavigationLayersActions.updateNavigationLayerOffset({
        navigationLayer: { ...request, id: response.id },
      }),
    ];
  }

  createLayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.createLayer),
      concatMap(state =>
        this.getCreateLayer(state.newLayer).pipe(
          concatMap(m => this.getPostCreateAction(state.newLayer, m)),
          catchError(errorMessage =>
            of(NavigationLayersActions.createLayerFailure({ errorMessage }))
          )
        )
      )
    )
  );

  importLifFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.importLIFFile),
      concatMap(state =>
        this.importLifFile(state.lifFile).pipe(
          map((response: LifUploadResponse) => {
            if (response.status === LayerCreateStatus.Success && state.lifFile.navigationLayerId)
              return NavigationLayersActions.importLIFFileSuccess({
                id: state.lifFile.navigationLayerId,
              });
            else
              return NavigationLayersActions.importLIFFileFailure({
                errorMessage: response.message,
              });
          }),
          catchError(errorMessage => {
            return of(NavigationLayersActions.importLIFFileFailure({ errorMessage }));
          })
        )
      )
    )
  );

  previewUpdateLif$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.previewUpdateLif),
      concatMap(state =>
        this.previewUpdateLif(state.lifFile).pipe(
          map(response => {
            if (response === undefined || response.validationStatus === undefined) {
              return NavigationLayersActions.previewUpdateLifFailure({
                errorMessage: 'navigationLayers.manage.previewUpdateError',
              });
            } else {
              return NavigationLayersActions.previewUpdateLifSuccess({ response });
            }
          }),
          catchError(errorMessage => {
            return of(NavigationLayersActions.previewUpdateLifFailure({ errorMessage }));
          })
        )
      )
    )
  );

  getNavigationLayersIdsWithLif$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.getNavigationLayerIdsWithLif),
      concatMap(state =>
        this.getNavigationLayersIdsWithLif(state.mapId).pipe(
          map(response => {
            return NavigationLayersActions.getNavigationLayerIdsWithLifSuccess({ ids: response });
          }),
          catchError(errorMessage => {
            return of(
              NavigationLayersActions.getNavigationLayerIdsWithLifFailure({ errorMessage })
            );
          })
        )
      )
    )
  );

  updateLif$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.updateLif),
      concatMap(state =>
        this.updateLif(state.lifUpdatePreview).pipe(
          map(response => {
            return NavigationLayersActions.updateLifSuccess({ response });
          }),
          catchError(errorMessage => {
            return of(NavigationLayersActions.updateLifFailure({ errorMessage }));
          })
        )
      )
    )
  );

  updateNavigationLayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.updateNavigationLayer),
      concatMap(state =>
        this.navigationLayerService.updateMapName(state.navigationLayer).pipe(
          map(() =>
            NavigationLayersActions.updateNavigationLayerSuccess({
              navigationLayer: state.navigationLayer,
            })
          ),
          catchError(errorMessage =>
            of(NavigationLayersActions.updateNavigationLayerFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateNavigationLayerOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.updateNavigationLayerOrder),
      concatMap(state =>
        this.navigationLayerService.updateNavigationLayerOrder(state.navigationLayers).pipe(
          map(() =>
            NavigationLayersActions.updateNavigationLayerOrderSuccess({
              navigationLayers: state.navigationLayers,
            })
          ),
          catchError(errorMessage =>
            of(NavigationLayersActions.updateNavigationLayerFailure({ errorMessage }))
          )
        )
      )
    )
  );

  getUpdateLayersOrder(
    navigationLayers: NavigationLayerResponseModel[]
  ): Observable<NavigationLayerResponseModel[]> {
    return this.navigationLayerService
      .updateNavigationLayerOrder(navigationLayers)
      .pipe(map(() => navigationLayers));
  }

  getPostUpdateAction(request: NavigationLayerResponseModel[]): PostUpdateActionType {
    return [
      NavigationLayersActions.updateNavigationLayerBatchOffset({
        navigationLayers: request,
      }),
    ];
  }

  updateNavigationLayerOffset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.updateNavigationLayerOffset),
      concatMap(state =>
        this.navigationLayerService.updateNavigationLayerOffset(state.navigationLayer).pipe(
          map(() =>
            NavigationLayersActions.updateNavigationLayerOffsetSuccess({
              navigationLayer: state.navigationLayer,
            })
          ),
          catchError(errorMessage =>
            of(NavigationLayersActions.addNavigationLayerOffsetFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateNavigationLayerBatchOffset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.updateNavigationLayerBatchOffset),
      concatMap(state =>
        this.navigationLayerService.updateNavigationLayerBatchOffset(state.navigationLayers).pipe(
          map(() =>
            NavigationLayersActions.updateNavigationLayerBatchOffsetSuccess({
              navigationLayers: state.navigationLayers,
            })
          ),
          catchError(errorMessage =>
            of(NavigationLayersActions.updateNavigationLayerBatchOffsetFailure({ errorMessage }))
          )
        )
      )
    )
  );

  deleteNavigationLayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.deleteNavigationLayer),
      concatMap(state =>
        this.navigationLayerService.deleteNavigationLayer(state.navigationLayer).pipe(
          map(() =>
            NavigationLayersActions.deleteNavigationLayerSuccess({
              navigationLayer: state.navigationLayer,
            })
          ),
          catchError(errorMessage =>
            of(NavigationLayersActions.deleteNavigationLayerFailure({ errorMessage }))
          )
        )
      )
    )
  );

  createNavigationLayerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NavigationLayersActions.createLayerSuccess,
        NavigationLayersActions.updateNavigationLayerOffset
      ),
      tap(() => {
        this.toastService.createSuccessToast('maps.navigationLayerActions.created');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  deleteNavigationLayerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.deleteNavigationLayerSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.navigationLayerActions.deleted');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  importLIFFileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.importLIFFileSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.navigationLayerActions.lifFIleUploaded');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NavigationLayersActions.updateNavigationLayerFailure,
          NavigationLayersActions.updateNavigationLayerBatchOffsetFailure,
          NavigationLayersActions.deleteNavigationLayerFailure,
          NavigationLayersActions.createLayerFailure,
          NavigationLayersActions.updateNavigationLayerImageFailure,
          NavigationLayersActions.addNavigationLayerOffsetFailure,
          NavigationLayersActions.importLIFFileFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  addNavigationLayerImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.updateNavigationLayerImage),
      concatMap(state =>
        this.navigationLayerService
          .uploadNavigationLayerImage(state.navigationLayerId, state.navigationLayerImageFile)
          .pipe(
            map(() => NavigationLayersActions.updateNavigationLayerImageSuccess()),
            catchError(errorMessage => {
              return of(
                NavigationLayersActions.updateNavigationLayerImageFailure({ errorMessage })
              );
            })
          )
      )
    )
  );

  addNavigationLayerImageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationLayersActions.updateNavigationLayerImageSuccess),
      concatMap(() => [
        setHasChanges({ hasChanges: false }),
        MapsActions.setMapMode({ mode: MapMode.None }),
      ])
    )
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NavigationLayersActions.updateNavigationLayerOrderSuccess,
        NavigationLayersActions.updateNavigationLayerSuccess,
        NavigationLayersActions.updateNavigationLayerBatchOffsetSuccess
      ),
      tap(() => {
        this.toastService.createSuccessToast('maps.navigationLayerActions.updated');
      }),
      concatMap(() => [
        setHasChanges({ hasChanges: false }),
        MapsActions.setMapMode({ mode: MapMode.None }),
      ])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly navigationLayerService: NavigationLayerService,
    private readonly lifService: LifService,
    private readonly toastService: ToastService
  ) {}
}
