import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CollectivePauseDto, CollectivePauseUpdateDto, FeatureToggle } from 'core/dtos';
import { AtsActions, VehicleType } from 'core/models';
import { AtsTranslationService, PermissionService } from 'core/services';
import { Subject, switchMap, take, takeUntil } from 'rxjs';
import * as fromSettings from 'store-modules/settings-store';
import { RootState } from 'store/index';
import { FunctionsModalInput } from '../functions-modal/functions-modal.component';
import { CollectivePauseForm } from './collective-pause-toggle.model';

@Component({
  selector: 'app-collective-pause-toggle',
  templateUrl: './collective-pause-toggle.component.html',
  styleUrls: ['./collective-pause-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectivePauseToggleComponent implements OnInit, OnChanges, OnDestroy {
  VehicleTypeEnum = VehicleType;
  ngUnsubscribe = new Subject<void>();
  activateWarning: FunctionsModalInput = {
    title: this.translation.get(
      'settings.featureToggles.collectivePauseSection.warning.activate.title'
    ),
    toggleOn: true,
  };
  deactivateWarning: FunctionsModalInput = {
    title: this.translation.get(
      'settings.featureToggles.collectivePauseSection.warning.deactivate.title'
    ),
    toggleOn: false,
  };
  isActivatingCollectivePause = false;
  isModalOpen = false;
  selectedVehicleType?: VehicleType;
  confirmToggleAction$ = new Subject<boolean>();
  canEdit = false;

  @Input() translationKey = '';
  @Input() collectivePauseToggle?: CollectivePauseDto;
  @Input() toggle: FeatureToggle = { isToggledOn: false, dateUpdated: null };
  @Input() description = '';
  @Input() isLoaded = false;

  collectivePauseForm = new FormGroup<CollectivePauseForm>({
    pauseStr: new FormControl(false, { nonNullable: true }),
    pauseTuggertrain: new FormControl(false, { nonNullable: true }),
    pauseForklift: new FormControl(false, { nonNullable: true }),
    pauseUAGV: new FormControl(false, { nonNullable: true }),
  });

  constructor(
    private readonly translation: AtsTranslationService,
    private readonly rootStore: Store<RootState>,
    private readonly permissionService: PermissionService
  ) {}

  checkPermissions(): void {
    this.canEdit = this.permissionService.actionAllowed(AtsActions.ToggleCollectivePause);

    const controls = this.collectivePauseForm.controls;

    if (!this.canEdit) {
      controls.pauseStr.disable({ emitEvent: false });
      controls.pauseTuggertrain.disable({ emitEvent: false });
      controls.pauseForklift.disable({ emitEvent: false });
      controls.pauseUAGV.disable({ emitEvent: false });
    } else {
      controls.pauseStr.enable({ emitEvent: false });
      controls.pauseTuggertrain.enable({ emitEvent: false });
      controls.pauseForklift.enable({ emitEvent: false });
      controls.pauseUAGV.enable({ emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.translation.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.updateTranslation();
    });
    this.subscribeToFormChange('pauseStr', VehicleType.UnitLoad);
    this.subscribeToFormChange('pauseTuggertrain', VehicleType.TuggerTrain);
    this.subscribeToFormChange('pauseForklift', VehicleType.Forklift);
    this.subscribeToFormChange('pauseUAGV', VehicleType.U_AGV);
  }

  ngOnChanges({ collectivePauseToggle }: TypedChanges<CollectivePauseToggleComponent>): void {
    if (collectivePauseToggle?.currentValue) {
      const { unitLoad, tuggerTrain, forklift, uagv } = collectivePauseToggle.currentValue;

      this.updateCollectivePauseForm({
        unitLoadValue: unitLoad.isToggledOn,
        tuggerTrainValue: tuggerTrain.isToggledOn,
        forkliftValue: forklift.isToggledOn,
        uagvValue: uagv.isToggledOn,
      });
    }

    this.checkPermissions();
  }

  onModalAction(confirmToggleAction: boolean): void {
    this.hideModal();
    this.confirmToggleAction$.next(confirmToggleAction);
  }

  toggleCollectivePause(isActivatingCollectivePause: boolean): void {
    if (isActivatingCollectivePause) {
      this.isActivatingCollectivePause = true;
      this.showModal();
    } else {
      this.isActivatingCollectivePause = false;
      this.showModal();
    }
  }

  subscribeToFormChange(
    formControlName: keyof CollectivePauseForm,
    vehicleType: VehicleType
  ): void {
    this.collectivePauseForm.controls[formControlName].valueChanges
      .pipe(
        switchMap(pauseVehicle => {
          // set the variable according to the toggle value
          this.toggleCollectivePause(pauseVehicle);
          this.selectedVehicleType = vehicleType;

          return this.confirmToggleAction$.pipe(take(1));
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(confirmToggleAction => {
        // initiate action when toggle is confirmed+

        if (!confirmToggleAction) {
          this.resetToggleValue(formControlName);
          return;
        }

        this.updateCollectivePauseToggleRequest();
      });
  }

  resetToggleValue(formControlName: keyof CollectivePauseForm): void {
    const currentCollectivePauseToggle = this.collectivePauseForm.controls[formControlName].value;
    this.collectivePauseForm.controls[formControlName].setValue(!currentCollectivePauseToggle, {
      emitEvent: false,
    });
  }

  showModal(): void {
    this.isModalOpen = true;
  }

  hideModal(): void {
    this.isModalOpen = false;
  }

  updateCollectivePauseToggleRequest(): void {
    const toggle = this.transformFormToDto(this.collectivePauseForm.controls);
    this.rootStore.dispatch(fromSettings.toggleCollectivePause({ toggle }));
  }

  updateCollectivePauseForm({
    unitLoadValue,
    tuggerTrainValue,
    forkliftValue,
    uagvValue,
  }: {
    unitLoadValue: boolean;
    tuggerTrainValue: boolean;
    forkliftValue: boolean;
    uagvValue: boolean;
  }): void {
    this.collectivePauseForm.setValue(
      {
        pauseStr: unitLoadValue,
        pauseTuggertrain: tuggerTrainValue,
        pauseForklift: forkliftValue,
        pauseUAGV: uagvValue,
      },
      { emitEvent: false }
    );
  }

  updateTranslation(): void {
    this.activateWarning.title = this.translation.get(
      'settings.featureToggles.collectivePauseSection.warning.activate.title'
    );
    this.deactivateWarning.title = this.translation.get(
      'settings.featureToggles.collectivePauseSection.warning.deactivate.title'
    );
  }

  transformFormToDto(form: CollectivePauseForm): CollectivePauseUpdateDto {
    const collectiveFormDto: CollectivePauseUpdateDto = {
      unitLoad: {
        isToggledOn: form.pauseStr.getRawValue(),
      },
      tuggerTrain: {
        isToggledOn: form.pauseTuggertrain.getRawValue(),
      },
      forklift: {
        isToggledOn: form.pauseForklift.getRawValue(),
      },
      uagv: {
        isToggledOn: form.pauseUAGV.getRawValue(),
      },
    };

    return collectiveFormDto;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
