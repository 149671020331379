import { GraphLayerEffects } from './graph-layer.effects';
import { MapsEffects } from './maps.effects';
import { NavigationLayersEffects } from './navigation-layers.effects';
import { PillarsGridEffects } from './pillars-grid.effects';
import { VehicleMapsDataEffects } from './vehicle-map-data.effects';
import { ZoneSetsEffects } from './zone-sets.effects';
import { ZonesEffects } from './zones.effects';

export const effects = [
  MapsEffects,
  ZonesEffects,
  NavigationLayersEffects,
  GraphLayerEffects,
  ZoneSetsEffects,
  VehicleMapsDataEffects,
  PillarsGridEffects,
];
