/* eslint-disable max-lines */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'core/guards/auth.guard';
import { AuthorizationGuard } from 'core/guards/authorization.guard';
import { PreventUnsavedChangesGuard } from 'core/guards/prevent-unsaved-changes-guard.guard';
import { TabChangedGuard } from 'core/guards/tab-changed.guard';
import { AtsActions } from 'core/models';
import { LandingPageComponent } from 'modules/home/pages/landing-page/landing-page.component';
import { ErrorForwardingGuard } from 'modules/settings/Guards/error-forwarding.guard';
import { MapSettingsGuard } from 'modules/settings/Guards/map-settings.guard';
import { SettingsGuard } from 'modules/settings/Guards/settings.guard';
import { AllEnvironmentUsersContainerComponent } from 'modules/settings/containers/all-environment-users-container/all-environment-users-container.component';
import { AllOrganizationUsersContainerComponent } from 'modules/settings/containers/all-organization-users-container/all-organization-users-container.component';
import { AllWorkingAreaUsersContainerComponent } from 'modules/settings/containers/all-working-area-users-container/all-working-area-users-container.component';
import { AuditLogContainerComponent } from 'modules/settings/containers/auditlog-container/auditlog-container.component';
import { EnvironmentRolesContainerComponent } from 'modules/settings/containers/environment-roles-container/environment-roles-container.component';
import { IpstSettingsContainerComponent } from 'modules/settings/containers/ipst-settings-container/ipst-settings-container.component';
import { OrganizationSettingsComponent } from 'modules/settings/containers/organization-settings/organization-settings.component';
import { UserRolesAndPermissionsContainerComponent } from 'modules/settings/containers/user-roles-and-permissions-container/user-roles-and-permissions-container.component';
import { InfobarSettingsPageComponent } from 'modules/settings/page/infobar-settings/infobar-settings-page.component';
import { UserRolesAndPermissionPageComponent } from 'modules/settings/page/user-roles-and-permission-page.component';
import { VehiclesGuard } from 'modules/vehicles/Guards/vehicles.guard';
import {
  LogoutComponent,
  NoPermissionComponent,
  NotauthorizedComponent,
  PageNotFoundComponent,
  RegisterUserComponent,
  VersionInformationComponent,
  WorkingAreaNotFoundComponent,
} from 'shared/components';
import { OrganizationsGuard } from './guards/organizations.guard';
import { SetOrganizationWorkingAreaGuard } from './guards/set-organization-working-area.guard';
import { AtsWelcomePageLayoutComponent } from './layouts/ats-welcome-page-layout/ats-welcome-page-layout.component';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { WorkingAreaResolver } from './resolvers/working-area.resolver';

const selectedSideMenuItemRoles = 'sideMenu.settings.roles';
const selectedSideMenuItemAuditlog = 'sideMenu.settings.auditLogs';

const routes: Routes = [
  { path: '', component: LandingPageComponent, canActivate: [AuthGuard] },
  {
    path: 'welcome',
    canActivate: [OrganizationsGuard, AuthorizationGuard, TabChangedGuard],
    component: AtsWelcomePageLayoutComponent,
    canActivateChild: [AuthorizationGuard, TabChangedGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule),
        data: {
          workingAreaAgnostic: true,
          selectedSideMenuItem: 'sideMenu.home.overview',
        },
      },
      {
        path: 'ticket-management',
        loadChildren: () =>
          import('./modules/ticket-management/ticket-management.module').then(
            m => m.TicketManagementModule
          ),
        data: { breadcrumb: 'breadcrumb.supportRequest' },
      },
      {
        path: 'version-information',
        component: VersionInformationComponent,
        data: { breadcrumb: 'breadcrumb.versionInformation' },
      },
    ],
  },
  {
    path: 'settings',
    component: AtsWelcomePageLayoutComponent,
    canActivate: [OrganizationsGuard],
    children: [
      {
        path: '',
        data: { breadcrumb: 'breadcrumb.settings.environmentSettings' },
        children: [],
      },
      {
        path: 'infobar',
        canActivate: [AuthGuard],
        component: InfobarSettingsPageComponent,
      },
      {
        path: 'auditlog',
        canActivate: [AuthGuard, OrganizationsGuard],
        component: AuditLogContainerComponent,
        data: {
          selectedSideMenuItem: selectedSideMenuItemAuditlog,
        },
      },
      {
        path: '',
        component: UserRolesAndPermissionPageComponent,
        data: {
          breadcrumb: 'breadcrumb.settings.userRolesAndPermissions',
          selectedSideMenuItem: selectedSideMenuItemRoles,
        },
        children: [
          {
            path: 'global-user-roles-permission',
            component: UserRolesAndPermissionsContainerComponent,
            data: { selectedSideMenuItem: selectedSideMenuItemRoles },
            children: [
              {
                path: 'environment-users',
                component: AllEnvironmentUsersContainerComponent,
                data: {
                  selectedSideMenuItem: selectedSideMenuItemRoles,
                },
              },
              {
                path: 'organization-users/:organizationId',
                component: AllOrganizationUsersContainerComponent,
                data: {
                  selectedSideMenuItem: selectedSideMenuItemRoles,
                },
              },
              {
                path: 'working-area-users/:organizationId/:workAreaId',
                component: AllWorkingAreaUsersContainerComponent,
                data: {
                  selectedSideMenuItem: selectedSideMenuItemRoles,
                },
              },
              {
                path: 'environment-roles',
                component: EnvironmentRolesContainerComponent,
                data: {
                  selectedSideMenuItem: selectedSideMenuItemRoles,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: [
      {
        path: ':organizationName/organization-settings',
        canActivate: [OrganizationsGuard],
        component: OrganizationSettingsComponent,
        data: {
          breadcrumb: 'breadcrumb.settings.organizationSettings',
        },
        children: [
          {
            path: 'ipst',
            canDeactivate: [PreventUnsavedChangesGuard],
            component: IpstSettingsContainerComponent,
            data: {
              breadcrumb: 'breadcrumb.settings.ipst',
              permissions: [AtsActions.IpstSettingsEdit],
            },
          },
        ],
      },
    ],
  },
  {
    path: ':organization/:workingArea',
    canActivate: [
      AuthGuard,
      AuthorizationGuard,
      OrganizationsGuard,
      SetOrganizationWorkingAreaGuard,
      VehiclesGuard,
      ErrorForwardingGuard,
      MapSettingsGuard,
      TabChangedGuard,
      SettingsGuard,
    ],
    canActivateChild: [AuthorizationGuard, TabChangedGuard],
    children: [
      { path: '', redirectTo: 'maps', pathMatch: 'full' },
      {
        path: '',
        component: ContentLayoutComponent,
        children: [
          {
            path: 'home',
            loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
          },
          {
            path: 'maps',
            loadChildren: () => import('./modules/maps/maps.module').then(m => m.MapsModule),
            data: {
              breadcrumb: 'breadcrumb.maps',
              workingAreaAgnostic: true,
              selectedSideMenuItem: 'sideMenu.maps.parent',
            },
          },
          {
            path: 'pois',
            loadChildren: () => import('./modules/pois/pois.module').then(m => m.PoisModule),
            data: {
              breadcrumb: 'breadcrumb.pois.pointOfInterest',
              workingAreaAgnostic: true,
              selectedSideMenuItem: 'sideMenu..pois.poiGroups',
            },
          },
          {
            path: 'mapping',
            loadChildren: () =>
              import('./modules/mapping/mapping.module').then(m => m.MappingModule),
            data: {
              breadcrumb: 'breadcrumb.mapping',
              workingAreaAgnostic: true,
              selectedSideMenuItem: 'sideMenu..pois.mappingTable',
            },
          },
          {
            path: 'jobs',
            loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
            data: { workingAreaAgnostic: true, selectedSideMenuItem: 'sideMenu.jobs.parent' },
          },
          {
            path: 'vehicles',
            loadChildren: () =>
              import('./modules/vehicles/vehicles.module').then(m => m.VehiclesModule),
            data: {
              breadcrumb: 'breadcrumb.vehicles.vehicles',
              workingAreaAgnostic: true,
              selectedSideMenuItem: 'sideMenu.vehicles.vehiclesOverview',
            },
          },
          {
            path: 'settings',
            loadChildren: () =>
              import('./modules/settings/settings.module').then(m => m.SettingsModule),
            data: {
              breadcrumb: 'breadcrumb.settings.settings',
              workingAreaAgnostic: true,
              selectedSideMenuItem: 'sideMenu.settings.settings',
            },
          },
          {
            path: 'devices',
            loadChildren: () =>
              import('./modules/opcua-devices/opcua-devices.module').then(
                m => m.OpcuaDevicesModule
              ),
            data: {
              breadcrumb: 'breadcrumb.opcua-devices.devices',
              workingAreaAgnostic: true,
              selectedSideMenuItem: 'sideMenu.opcua-devices.parent',
            },
          },
          {
            path: 'failure',
            loadChildren: () =>
              import('./modules/failure-commenting/failure-commenting.module').then(
                m => m.FailureCommentingModule
              ),
            data: {
              breadcrumb: 'breadcrumb.failureCommenting.failureCommenting',
              workingAreaAgnostic: true,
              selectedSideMenuItem: 'sideMenu.failureCommenting.parent',
            },
          },
          {
            path: 'auditlog',
            component: AuditLogContainerComponent,
            data: {
              breadcrumb: 'breadcrumb.settings.auditLog',
              workingAreaAgnostic: true,
              selectedSideMenuItem: selectedSideMenuItemAuditlog,
            },
          },
          {
            path: 'working-area-users',
            loadChildren: () =>
              import('./modules/settings/settings.module').then(m => m.SettingsModule),
            data: {
              breadcrumb: 'breadcrumb.settings.roles',
              workingAreaAgnostic: true,
              selectedSideMenuItem: 'sideMenu.settings.roles',
            },
          },
          {
            path: 'version-information',
            component: VersionInformationComponent,
            data: { breadcrumb: 'breadcrumb.versionInformation' },
          },
          {
            path: 'call-off',
            loadChildren: () =>
              import('./modules/call-off/call-off.module').then(m => m.CallOffModule),
            data: {
              breadcrumb: 'breadcrumb.callOff.callOff',
              workingAreaAgnostic: true,
              selectedSideMenuItem: 'sideMenu.callOff.callOff',
            },
          },
        ],
      },
    ],
  },
  {
    path: ':organization/:workingArea',
    canActivate: [
      AuthGuard,
      AuthorizationGuard,
      OrganizationsGuard,
      SetOrganizationWorkingAreaGuard,
      VehiclesGuard,
      ErrorForwardingGuard,
      MapSettingsGuard,
      TabChangedGuard,
      SettingsGuard,
    ],
    canActivateChild: [AuthorizationGuard, TabChangedGuard],
    children: [
      {
        path: '',
        component: BaseLayoutComponent,
        children: [
          {
            path: 'ticket-management',
            // eslint-disable-next-line sonarjs/no-identical-functions
            loadChildren: () =>
              import('./modules/ticket-management/ticket-management.module').then(
                m => m.TicketManagementModule
              ),
            data: { breadcrumb: 'breadcrumb.supportRequest' },
          },
        ],
      },
    ],
  },
  {
    path: 'wa-not-found',
    component: WorkingAreaNotFoundComponent,
  },
  {
    path: 'register-user',
    component: RegisterUserComponent,
  },
  {
    path: 'not-authorized',
    component: NotauthorizedComponent,
  },
  {
    path: 'no-permission',
    component: NoPermissionComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'reload',
    component: BaseLayoutComponent,
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [WorkingAreaResolver],
})
export class AppRoutingModule {}
