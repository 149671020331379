import { MAX_ORIENTATION } from 'core/constants';
import { removeObsoleteDecimalPlaces } from './math.helper';

export const GeometryConstant = {
  Degree90InRadians: 1.570797346063,
  Degree270InRadians: 4.7123899996450005,
};

const PI_Degrees = 180;

// NOTE: HELPERS FOR CONVERTING GEOMETRIC VALUES
// Do not use the formatting functions below for calculations, only for presentation
export function formatToPositiveRadians(radian = 0): number {
  return normalizeToPositiveValueRadians(Math.PI / 2 - radian);
}

export function formatRadiansToDegrees(radians = 0): number {
  const piInDegrees = 180;
  return Math.round((radians / Math.PI) * piInDegrees);
}

export function formatRadiansToPositiveDegrees(radians = 0): number {
  const piInDegrees = 180;
  const degrees = (normalizeToPositiveValueRadians(radians) / Math.PI) * piInDegrees;
  return Math.round(degrees);
}

export function formatDegreesToRadians(degrees = 0): number {
  const piInDegrees = 180;
  return removeObsoleteDecimalPlaces((degrees / piInDegrees) * Math.PI);
}

export function normalizeToPositiveValueRadians(radians = 0): number {
  const angle = (radians + Math.PI * 2) % (Math.PI * 2);
  return removeObsoleteDecimalPlaces(angle);
}

export function calculateInverseAngle(rotation: number): number {
  return MAX_ORIENTATION - rotation;
}

// Pure calculated values without any rounding or decimal truncation
export class GeometryConverter {
  static degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / PI_Degrees);
  }

  static radiansToDegrees(radians: number): number {
    return radians * (PI_Degrees / Math.PI);
  }

  static degreesToPositiveRadians(degree: number): number {
    const radian = GeometryConverter.degreesToRadians(degree);
    return this.radianToPositiveValue(radian);
  }

  static radianToPositiveValue(radian: number): number {
    return (Math.PI / 2 - radian + Math.PI * 2) % (Math.PI * 2);
  }
}
