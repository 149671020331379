<ds-modal
  [dimensionsConfig]="{ width: '535px' }"
  #functionModeModal
  [ds-modal-trigger-for]="functionModeModal"
  [accentBarTone]="'caution'"
  [(isModalOpen)]="isModalOpen"
  (modalDismiss)="onClose()">
  <ds-box-header data-cy="dialogHeader">{{ modalInputData?.title }}</ds-box-header>
  <ds-box-content divider="full" data-cy="dialog-content">
    <ng-container
      [ngTemplateOutlet]="
        modalInputData?.heading && modalInputData?.description
          ? defaultModalContentTemplate
          : modalContentTemplate
      "
      [ngTemplateOutletContext]="{ modalInputData: modalInputData }"></ng-container>
  </ds-box-content>
  <ds-box-footer data-cy="dialog-footer">
    <button ds-button [variant]="'outline'" (click)="onClose()" data-cy="cancelButton">
      {{ 'shared.modalButtons.CancelButton' | translate }}
    </button>
    <button ds-button class="ms-2x" (click)="onConfirm()" data-cy="saveButton">
      {{
        modalInputData && modalInputData.customText !== undefined
          ? (modalInputData.customText | translate)
          : modalInputData?.toggleOn
          ? ('settings.functions.enable' | translate)
          : ('settings.functions.disable' | translate)
      }}
    </button>
  </ds-box-footer>
</ds-modal>

<ng-template #defaultModalContentTemplate
  ><div class="modalMessageText1">
    <span data-cy="heading"> {{ modalInputData?.heading }}</span>
  </div>
  <div class="modalMessageText2">
    <span data-cy="description"> {{ modalInputData?.description }}</span>
  </div></ng-template
>
