import { ChartDto } from 'core/dtos';

export const chartTotals = {
  calculateTotal: (data: ChartDto[]): number[] => {
    return data.map(item => item.value || 0);
  },

  calculateTotalCount: (totals: number[]): number => {
    return totals.reduce((acc, cur) => acc + cur, 0);
  },

  formatAsPercentage: (dataArr: number[], element: number): string => {
    const sum = dataArr.reduce((acc, cur) => acc + cur, 0);
    const percentDenominator = 100;
    return ((element * percentDenominator) / sum).toFixed(2) + '%';
  },
  formatAsPercentageAGCharts: (total: number, element: number): string => {
    const percentDenominator = 100;
    return ((element * percentDenominator) / total).toFixed(2) + '%';
  },
};
