/* eslint-disable max-lines */
import { createAction, props } from '@ngrx/store';
import { Factsheet } from 'core/dtos';

export enum FactsheetActions {
  LoadAllFactsheets = '[Factsheets] Load All',
  LoadAllFactsheetsSuccess = '[Factsheets] Load All Success',
  LoadAllFactsheetsFailure = '[Factsheets] Load All Failure',
  UpdateVehicleFactsheet = '[Factsheets] Update Vehicle Factsheet',
}

export const loadFactsheets = createAction(FactsheetActions.LoadAllFactsheets);

export const loadFactsheetsSuccess = createAction(
  FactsheetActions.LoadAllFactsheetsSuccess,
  props<{ factsheets: Factsheet[] }>()
);

export const loadFactsheetsFailure = createAction(
  FactsheetActions.LoadAllFactsheetsFailure,
  props<{ errorMessage: string }>()
);

export const signalRUpdateVehicleFactsheet = createAction(
  FactsheetActions.UpdateVehicleFactsheet,
  props<{ factsheet: Factsheet }>()
);
