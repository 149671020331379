<button
  icon="arrow_go_back"
  *ngIf="isEditMode"
  ds-button
  [variant]="'ghost'"
  ds-tooltip="{{ 'settings.users.resetToDefaultsToolTip' | translate }}"
  [dsTooltipConfig]="{ disabled: !!hasBeenEdited, width: '250px' }"
  [disabled]="!hasBeenEdited"
  class="access-button"
  data-cy="resetToDefaults"
  (click)="resetToDefaults()">
  {{ 'settings.users.resetToDefaults' | translate | uppercase }}
</button>

<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-density zebra"
  translationContext="settings.roles"
  data-cy="rolesAndPermissions"
  [rowData]="rows"
  [gridOptions]="gridOptions"
  [getRowId]="getRowIdForChangeDetection"
  [columnDefs]="translatedColumnDefs"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  (selectionChanged)="onRowSelected()"
  [groupDisplayType]="groupDisplayType"
  (gridReady)="onGridReady($event)"></ag-grid-angular>
