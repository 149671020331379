import { VehicleDto } from 'core/dtos';
import { Graphics } from 'pixi.js';
import { GeometryHelper } from 'shared/helpers';
import { LayerGraphicHelper } from '../../helpers';
import { ContainerEx } from '../../pixi';
import { VehicleGraphicHelper } from '../helpers/vehicle-graphic.helper';
import { UnderrideDimensions } from '../vehicle-dimensions.model';
import { VehicleName } from '../vehicle-layer.constant';
import { VehicleMapItem } from './vehicle.graphic';

export abstract class UnderrideMapItem extends VehicleMapItem {
  protected dimensions!: UnderrideDimensions;
  protected setup(_vehicle: VehicleDto, _itemContainer: ContainerEx): void {}

  protected createVehicleBase(): Graphics {
    return VehicleGraphicHelper.createVehicleBase(this.dimensions);
  }

  protected createLoad(): Graphics {
    const loaded = VehicleGraphicHelper.createLoaded(this.dimensions);

    if (this.dimensions.loadPosition) {
      LayerGraphicHelper.positionToXOffset(loaded, this.dimensions, this.dimensions.loadPosition);
    } else {
      LayerGraphicHelper.positionToItemCenter(loaded, this.dimensions);
    }

    return loaded;
  }

  protected calculateLabelOffset(orientation: number, xPositionOffset: number): number {
    return -(
      GeometryHelper.calculateOffsetFromOrigin(
        orientation,
        this.vehicle.isLoaded
          ? this.dimensions.load.length + VehicleName.Padding
          : this.dimensions.width,
        this.dimensions.length,
        this.dimensions.origin + xPositionOffset
      ) + VehicleName.Padding
    );
  }
}
