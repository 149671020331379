import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

type TrafficLightSize = 'smallTrafficLight' | 'mediumTrafficLight' | 'largeTrafficLight';

@Component({
  selector: 'app-traffic-light',
  templateUrl: './traffic-light.component.html',
  styleUrls: ['./traffic-light.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficLightComponent implements OnInit {
  @Input() color = '';
  @Input() size: TrafficLightSize = 'smallTrafficLight';
  @Input() toolTipTranslateKey = '';
  @Input() toolTipExtraText = '';

  cssClass = 'pi pi-circle-on ';
  cssClassLargeTrafficLight = 'trafficLightLarge';
  cssClassMediumTrafficLight = 'trafficLightMedium';

  ngOnInit(): void {
    switch (this.size) {
      case 'mediumTrafficLight':
        this.cssClass += this.cssClassMediumTrafficLight;
        break;
      case 'largeTrafficLight':
        this.cssClass += this.cssClassLargeTrafficLight;
        break;
    }
  }
}
