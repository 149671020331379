import { AdxCollisionRecordDto, CollisionPoint, MapVehicle, VehicleDto } from 'core/dtos';
import { arePositionsEqual, GuidString, Pos2D, Vector2D } from 'core/models';

export function setVehicleDefaultValues(vehicles: VehicleDto[]): MapVehicle[] {
  const fillTheta = (t: Vector2D): Pos2D => ({
    x: t.x,
    y: t.y,
    theta: t.theta ?? 0,
  });

  return vehicles.map(v => ({
    ...v,
    path: v.path?.map(fillTheta) ?? [],
    trailers: v.trailers ?? null,
  }));
}

export function setStateToVehicle(
  state: Map<GuidString, MapVehicle>,
  vehicle: MapVehicle
): MapVehicle {
  const sv = state.get(vehicle.id);

  return {
    ...vehicle,
    pose2D: sv?.pose2D ?? vehicle.pose2D,
    path: sv?.path ?? vehicle.path,
    isLoaded: sv?.isLoaded ?? vehicle.isLoaded,
    hasError: sv?.hasError ?? vehicle.hasError,
    isPaused: sv?.isPaused ?? vehicle.isPaused,
    trailers: sv?.trailers ?? vehicle.trailers,
    maintenanceModeEnabled: sv?.maintenanceModeEnabled ?? vehicle.maintenanceModeEnabled,
    isConnected: sv?.isConnected ?? vehicle.isConnected,
    isSwitchedOff: sv?.isSwitchedOff ?? vehicle.isSwitchedOff,
    isBusy: sv?.isBusy ?? vehicle.isBusy,
    isCharging: sv?.isCharging ?? vehicle.isCharging,
    isRetired: sv?.isRetired ?? vehicle.isRetired,
  };
}

export function isMapVehicleUpdated(current: MapVehicle, old: VehicleDto): boolean {
  return (
    !arePositionsEqual(current.pose2D, old.pose2D) ||
    current.isLoaded !== old.isLoaded ||
    current.isPaused !== old.isPaused ||
    current.isBusy !== old.isBusy ||
    current.isCharging !== old.isCharging ||
    current.isConnected !== old.isConnected ||
    current.hasError !== old.hasError ||
    current.maintenanceModeEnabled !== old.maintenanceModeEnabled ||
    current.isSwitchedOff !== old.isSwitchedOff ||
    current.mode !== old.mode ||
    current.isRetired !== old.isRetired ||
    current.name !== old.name ||
    !arePathsEqual(current.path, old.path)
  );
}

export function arePathsEqual(
  path1: Vector2D[] | undefined,
  path2: Vector2D[] | undefined
): boolean {
  if (!path1 || !path2) return path1 === path2;
  if (path1.length !== path2.length) return false;

  return path1.every(
    (point, index) =>
      point.x === path2[index].x && point.y === path2[index].y && point.theta === path2[index].theta
  );
}

export function mapAdxRecordToCollision(record: AdxCollisionRecordDto): CollisionPoint {
  const collision: CollisionPoint = {
    id: record.message.collisionId,
    mapId: record.message.mapId,
    prioritizedVehicleId: record.message.prioritizedVehicle.vehicleId,
    pausedVehicleId: record.message.pausedVehicle.vehicleId,
    pose2D: {
      orientation: 0,
      x: record.message.collisionPoint.x,
      y: record.message.collisionPoint.y,
    },
    collisionCase: record.message.collisionCase,
    releaseType: record.message.releaseType,
    isDeadlockCollision: record.message.isDeadlockCollision,
  };
  return collision;
}
