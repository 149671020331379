/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { WorkingAreasService } from 'core/api-services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import * as OrganizationsActions from 'store/actions/organizations.actions';
import * as WorkingAreasActions from 'store/actions/working-areas.actions';
import * as fromRoot from 'store/reducers/';
import { selectSelectedOrganization } from 'store/selectors';
@Injectable()
export class WorkingAreasEffects {
  addWorkingArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkingAreasActions.addWorkingArea),
      concatMap(action =>
        this.workingAreasService
          .createWorkingArea(action.input)
          .pipe(concatMap(() => this.workingAreasService.getCreatedWorkingArea(action.input)))
          .pipe(
            map(workingArea => WorkingAreasActions.addWorkingAreaSuccess({ workingArea })),
            catchError(errorMessage =>
              of(WorkingAreasActions.addWorkingAreaFailure({ errorMessage }))
            )
          )
      )
    )
  );

  updateWorkingArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkingAreasActions.updateWorkingArea),
      concatMap(action =>
        this.workingAreasService
          .updateWorkingArea(action.oldWorkingAreaName, action.input)
          .pipe(concatMap(() => this.workingAreasService.getCreatedWorkingArea(action.input)))
          .pipe(
            map(workingArea =>
              WorkingAreasActions.updateWorkingAreaSuccess({
                workingArea,
              })
            ),
            catchError(errorMessage =>
              of(WorkingAreasActions.updateWorkingAreaFailure({ errorMessage }))
            )
          )
      )
    )
  );

  deleteWorkingArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkingAreasActions.deleteWorkingArea),
      concatMap(action =>
        this.workingAreasService.deleteWorkingArea(action.input).pipe(
          map(() =>
            WorkingAreasActions.deleteWorkingAreaSuccess({ workingArea: action.input.workingArea })
          ),
          catchError(errorMessage =>
            of(WorkingAreasActions.deleteWorkingAreaFailure({ errorMessage }))
          )
        )
      )
    )
  );

  addWorkingAreaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkingAreasActions.addWorkingAreaSuccess),
      tap(({ workingArea }) => {
        this.toastService.createSuccessToast('shared.actions.created', workingArea);
      }),
      map(() => OrganizationsActions.loadOrganizations())
    )
  );

  updateWorkingAreaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkingAreasActions.updateWorkingAreaSuccess),
      map(({ workingArea }) => workingArea),
      tap(workingArea => {
        this.toastService.createSuccessToast('shared.actions.saved', workingArea);
      }),
      map(() => OrganizationsActions.loadOrganizations())
    )
  );

  deleteWorkingAreaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkingAreasActions.deleteWorkingAreaSuccess),
      tap(({ workingArea }) => {
        this.toastService.createSuccessToast('shared.actions.deleted', workingArea);
        this.rootStore.pipe(select(selectSelectedOrganization), take(1)).subscribe(org => {
          this.router
            .navigate(['/welcome/plant/view/', org?.name])
            .catch(errorMessage =>
              WorkingAreasActions.deleteWorkingAreaFailure({ errorMessage: errorMessage })
            );
        });
      }),

      map(() => OrganizationsActions.loadOrganizations())
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WorkingAreasActions.loadWorkingAreasFailure,
          WorkingAreasActions.addWorkingAreaFailure,
          WorkingAreasActions.deleteWorkingAreaFailure,
          WorkingAreasActions.updateWorkingAreaFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  selectWorkingArea$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkingAreasActions.selectWorkingArea),
        tap(() => {
          this.rootStore.dispatch(WorkingAreasActions.changeWorkingArea());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly workingAreasService: WorkingAreasService,
    private readonly toastService: ToastService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly router: Router
  ) {}
}
