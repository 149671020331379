import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DsNavigationBarComponent } from '@bmw-ds/components';
import { Store, select } from '@ngrx/store';
import { Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentLayoutComponent implements OnInit, OnDestroy {
  @Output() readonly sendClosePanel = new EventEmitter<boolean>();
  showNav = true;
  showFooter = true;
  navBar!: DsNavigationBarComponent;
  ngUnsubscribe = new Subject<void>();
  openNotificationPanelFlag = false;
  hasNotifications = false;
  closeNotificationPanelFlag = false;
  isEditMode = false;
  isEditMode$ = of(false);

  constructor(
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {}

  ngOnInit(): void {
    this.showFooter = !this.isMapTab(this.router.url);

    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.showFooter = !this.isMapTab(value.url);
        this.cdRef.markForCheck();
      }
    });
    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
    this.rootStore
      .pipe(select(fromRoot.selectIsEditMode), takeUntil(this.ngUnsubscribe))
      .subscribe(isEditMode => {
        this.isEditMode = isEditMode;
      });
  }

  isMapTab(url: string): boolean {
    return url.includes('/maps/');
  }

  onSendClosePanel(closePanel: boolean): void {
    this.closeNotificationPanelFlag = closePanel;
    this.sendClosePanel.emit(closePanel);
    this.cdRef.markForCheck();
  }

  onReceiveOpenNotificationPanel(openPanel: boolean): void {
    this.closeNotificationPanelFlag = false;
    this.openNotificationPanelFlag = openPanel;
    this.cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
