import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { NavigationLayerResponseModel } from 'core/dtos';
import { GuidString } from 'core/models';

import * as navigationLayerActions from '../actions';

export const featureKey = 'navigationLayers';

export interface NavigationLayersState extends EntityState<NavigationLayerResponseModel> {
  loaded: boolean;
  errorMessage: string;
  selectedNavigationLayerId: GuidString;
}

// eslint-disable-next-line max-len
export const navigationLayersAdapter: EntityAdapter<NavigationLayerResponseModel> =
  createEntityAdapter<NavigationLayerResponseModel>();

export const initialState: NavigationLayersState = navigationLayersAdapter.getInitialState({
  loaded: false,
  errorMessage: '',
  selectedNavigationLayerId: '',
});

const mapsReducer = createReducer(
  initialState,

  on(navigationLayerActions.loadNavigationLayers, state => ({
    ...state,
    loaded: false,
  })),
  on(navigationLayerActions.loadNavigationLayersSuccess, (state, { navigationLayers }) =>
    navigationLayersAdapter.setAll(
      navigationLayers.map(navigationLayer => ({ ...navigationLayer })),
      {
        ...state,
        loaded: true,
      }
    )
  ),
  on(navigationLayerActions.loadNavigationLayersFailure, (state, { errorMessage }) => ({
    ...state,
    loaded: false,
    errorMessage,
  })),

  on(navigationLayerActions.addNavigationLayerSuccess, (state, { newNavigationLayer }) =>
    navigationLayersAdapter.addOne(newNavigationLayer, state)
  ),

  on(
    navigationLayerActions.updateNavigationLayer,
    navigationLayerActions.signalRUpdateNavigationLayer,
    (state, { navigationLayer }) =>
      navigationLayersAdapter.updateOne(
        { id: navigationLayer.id.toString(), changes: navigationLayer },
        state
      )
  ),

  on(
    navigationLayerActions.signalRDeleteNavigationLayer,
    navigationLayerActions.deleteNavigationLayerSuccess,
    (state, { navigationLayer }) =>
      navigationLayersAdapter.removeOne(navigationLayer.id.toString(), {
        ...state,
        selectedNavigationLayerId:
          state.selectedNavigationLayerId === navigationLayer.id
            ? ''
            : state.selectedNavigationLayerId,
      })
  ),

  on(navigationLayerActions.selectNavigationLayer, (state, { navigationLayerId }) => ({
    ...state,
    selectedNavigationLayerId: navigationLayerId,
  })),

  on(navigationLayerActions.selectNavigationLayerByMapId, (state, { mapId }) => {
    const layer = Object.values(state.entities).find(l => l?.mapId === mapId);

    return {
      ...state,
      selectedNavigationLayerId: layer ? layer.id : '',
    };
  }),

  // eslint-disable-next-line sonarjs/no-identical-functions
  on(
    navigationLayerActions.updateNavigationLayerSuccess,
    navigationLayerActions.signalRUpdateNavigationLayerSuccess,
    (state, { navigationLayer }) =>
      navigationLayersAdapter.updateOne(
        { id: navigationLayer.id.toString(), changes: navigationLayer },
        state
      )
  ),

  on(navigationLayerActions.getNavigationLayerIdsWithLifSuccess, (state, { ids }) =>
    navigationLayersAdapter.updateMany(
      ids.map(id => {
        return { id: id.toString(), changes: { hasLif: true } };
      }),
      state
    )
  ),

  on(navigationLayerActions.importLIFFileSuccess, (state, { id }) =>
    navigationLayersAdapter.updateOne({ id: id.toString(), changes: { hasLif: true } }, state)
  ),

  on(navigationLayerActions.deleteNavigationLayerFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(
  state: NavigationLayersState | undefined,
  action: Action
): NavigationLayersState {
  return mapsReducer(state, action);
}

export const { selectEntities, selectAll } = navigationLayersAdapter.getSelectors();

export const getLoaded = (state: NavigationLayersState): boolean => state.loaded;
export const getErrorMessage = (state: NavigationLayersState): string => state.errorMessage;
export const getSelectedNavigationLayerId = (state: NavigationLayersState): GuidString =>
  state.selectedNavigationLayerId;
export const getNavigationLayerEntities = selectEntities;
export const getAllNavigationLayers = selectAll;
