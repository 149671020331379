import { createAction, props } from '@ngrx/store';
import { ErrorAggregate } from 'core/dtos';
import { Params } from 'core/http/base-http-client';
import { GuidString } from 'core/models';

export enum ErrorTypes {
  LoadErrors = '[Error Aggregate] Load Error Aggregates',
  LoadErrorsByVehicleId = '[Error Aggregate] Load Error Aggregates By Vehicle Id',
  LoadErrorsSuccess = '[Error Aggregate] Load Error Aggregates Success',
  LoadErrorFailure = '[Error Aggregate] Load Error Aggregate Failure',
}

export enum SignalRErrorsTypes {
  SignalRUpsertError = '[SignalR] Upsert Error Aggregate',
  SignalRDeleteError = '[SignalR] Delete Error Aggregate',
}

export const loadErrors = createAction(ErrorTypes.LoadErrors, props<{ httpParams: Params }>());

export const loadErrorsByVehicleId = createAction(
  ErrorTypes.LoadErrorsByVehicleId,
  props<{ vehicleId: GuidString }>()
);

export const loadErrorAggregateSuccess = createAction(
  ErrorTypes.LoadErrorsSuccess,
  props<{ errors: ErrorAggregate[]; count: number }>()
);

export const loadErrorAggregateFailure = createAction(
  ErrorTypes.LoadErrorFailure,
  props<{ errorMessage: string }>()
);

export const signalRUpsertError = createAction(
  SignalRErrorsTypes.SignalRUpsertError,
  props<{ error: ErrorAggregate }>()
);

export const signalRRemoveError = createAction(
  SignalRErrorsTypes.SignalRDeleteError,
  props<{ error: ErrorAggregate }>()
);
