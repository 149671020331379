import { InjectionToken } from '@angular/core';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromOrganizations from 'store/reducers/organizations.reducer';
import { RouterStateUrl } from 'store/reducers/router.reducer';
import * as fromWorkingAreas from 'store/reducers/working-areas.reducer';

import * as fromRouter from './router.reducer';
import * as fromUI from './ui.reducer';

export interface RootState {
  [fromUI.uiFeatureKey]: fromUI.UIState;
  [fromRouter.routerFeatureKey]: RouterReducerState<RouterStateUrl>;
  [fromOrganizations.featureKey]: fromOrganizations.OrganizationsState;
  [fromWorkingAreas.featureKey]: fromWorkingAreas.WorkingAreasState;
}

export const reducers = new InjectionToken<ActionReducerMap<RootState, Action>>(
  'root reducers token',
  {
    factory: () => ({
      [fromUI.uiFeatureKey]: fromUI.reducer,
      [fromRouter.routerFeatureKey]: routerReducer,
      [fromOrganizations.featureKey]: fromOrganizations.reducer,
      [fromWorkingAreas.featureKey]: fromWorkingAreas.reducer,
    }),
  }
);

export function debug(reducer: ActionReducer<RootState>): ActionReducer<RootState> {
  return (state, action) => reducer(state, action);
}

export const getUIState = createFeatureSelector<fromUI.UIState>(fromUI.uiFeatureKey);
export const getRouterState = createFeatureSelector<RouterReducerState<fromRouter.RouterStateUrl>>(
  fromRouter.routerFeatureKey
);
export const getOrganizationsState = createFeatureSelector<fromOrganizations.OrganizationsState>(
  fromOrganizations.featureKey
);
export const getWorkingAreasState = createFeatureSelector<fromWorkingAreas.WorkingAreasState>(
  fromWorkingAreas.featureKey
);

export function resetState(reducer: ActionReducer<RootState>): ActionReducer<RootState> {
  return (state: RootState | undefined, action) => {
    if (action.type === '[Home] Change Working Area') {
      return reducer(
        {
          ui: state?.[fromUI.uiFeatureKey] ?? ({} as fromUI.UIState),
          routerReducer:
            state?.[fromRouter.routerFeatureKey] ?? ({} as RouterReducerState<RouterStateUrl>),
          organizations:
            state?.[fromOrganizations.featureKey] ?? ({} as fromOrganizations.OrganizationsState),
          workingAreas:
            state?.[fromWorkingAreas.featureKey] ?? ({} as fromWorkingAreas.WorkingAreasState),
        },
        action
      );
    }
    return reducer(state, action);
  };
}
