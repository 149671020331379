import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RuleDto } from 'core/dtos';
import * as RouteCustomizationRuleActionTypes from '../actions/route-customization-rule.actions';

export const featureKey = 'routeCustomizationRules';

export interface RouteCustomizationRuleState extends EntityState<RuleDto> {
  loading: boolean;
  loaded: boolean;
  errorMessage: string;
  rules: RuleDto[];
}

export const routeCustomizationRuleAdapter: EntityAdapter<RuleDto> = createEntityAdapter<RuleDto>();

export const initialState: RouteCustomizationRuleState =
  routeCustomizationRuleAdapter.getInitialState({
    loading: false,
    loaded: false,
    errorMessage: '',
    rules: [],
  });

export const routeCustomizationRuleReducer = createReducer(
  initialState,
  on(RouteCustomizationRuleActionTypes.loadRouteCustomizationRules, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(RouteCustomizationRuleActionTypes.loadRouteCustomizationRulesSuccess, (state, { rules }) => {
    return routeCustomizationRuleAdapter.setAll(rules, {
      ...state,
      rules: rules,
      loading: false,
      loaded: true,
    });
  }),

  on(
    RouteCustomizationRuleActionTypes.createRouteCustomizationRule,
    RouteCustomizationRuleActionTypes.updateRouteCustomizationRule,
    RouteCustomizationRuleActionTypes.deleteRouteCustomizationRule,
    state => ({
      ...state,
      errorMessage: '',
    })
  ),

  on(RouteCustomizationRuleActionTypes.createRouteCustomizationRuleSuccess, (state, { rule }) =>
    routeCustomizationRuleAdapter.addOne(rule, state)
  ),

  on(RouteCustomizationRuleActionTypes.updateRouteCustomizationRuleSuccess, (state, { rule }) =>
    routeCustomizationRuleAdapter.updateOne(rule, state)
  ),

  on(RouteCustomizationRuleActionTypes.deleteRouteCustomizationRuleSuccess, (state, { id }) =>
    routeCustomizationRuleAdapter.removeOne(id.toString(), state)
  ),

  on(
    RouteCustomizationRuleActionTypes.loadRouteCustomizationRulesFailure,
    RouteCustomizationRuleActionTypes.createRouteCustomizationRuleFailure,
    RouteCustomizationRuleActionTypes.updateRouteCustomizationRuleFailure,
    RouteCustomizationRuleActionTypes.deleteRouteCustomizationRuleFailure,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      loaded: false,
      errorMessage,
    })
  )
);

export function reducer(
  state: RouteCustomizationRuleState | undefined,
  action: Action
): RouteCustomizationRuleState {
  return routeCustomizationRuleReducer(state, action);
}

export const { selectEntities, selectAll } = routeCustomizationRuleAdapter.getSelectors();
export const getEntities = selectEntities;
export const getLoading = (state: RouteCustomizationRuleState): boolean => state.loading;
export const getLoaded = (state: RouteCustomizationRuleState): boolean => state.loaded;
export const getRules = (state: RouteCustomizationRuleState): RuleDto[] => state.rules;

export const getRouteCustomizationRules = selectAll;
