<ds-box
  [withBorder]="true"
  [elevation]="'base'"
  data-cy="copilot-chat-card"
  class="chat-card"
  [ngClass]="{
    fixed: isCardFixed,
    transperant: !isCardFixed
  }"
  *ngIf="isOpen">
  <ds-box-header
    [isDismissable]="true"
    [icon]="'user'"
    divider="full"
    (dismissEvent)="dismissChat()"
    >{{ 'shared.atsCopilot.title' | translate }}</ds-box-header
  >
  <div class="overflow-auto" style="max-height: 450px" #scroll>
    <ds-box-content spacing="none md md md">
      <div class="row g-0">
        <div class="col-auto rounded p-2x mb-1x bg-neutral border-neutral copilot-text">
          <h5>{{ 'copilot.welcomeText' | translate }}</h5>
          <p>{{ 'copilot.welcomeTextSelectBot' | translate }}</p>
        </div>
      </div>

      <div class="row g-0">
        <div class="col-auto rounded pt-2x mb-2x border-neutral copilot-text">
          <p class="pb-2x px-2x" *ngFor="let bot of availableBots">
            <button
              data-cy="selectBotButton"
              style="width: 100%"
              ds-button
              (click)="setSelectedBot(bot)">
              {{ bot.label }}
            </button>
          </p>
        </div>
      </div>

      <div class="row g-0">
        <div class="col-auto d-flex align-items-center">
          <ds-icon
            data-cy="messageIcon"
            icon="user_voice"
            tone="positive"
            size="sm"
            class="me-1x"
            title="{{ 'tooltip.messageIcon' | translate }}"></ds-icon>
          <span class="text-smallprint">{{ 'shared.atsCopilot.botName' | translate }}</span>
        </div>
      </div>
    </ds-box-content>

    <ds-box-content spacing="none md md md" *ngFor="let msg of messages$ | async">
      <div
        class="row g-0"
        [ngClass]="{
          'justify-content-end': msg.type === 'user'
        }">
        <div
          class="col-auto rounded p-2x mb-1x copilot-text"
          [ngClass]="{
            'bg-neutral border-neutral': msg.type === 'bot',
            'bg-action border-action': msg.type === 'user'
          }">
          <p
            *ngIf="convertMarkdown(msg.text) | async as safeHtml; else loading"
            [innerHTML]="safeHtml"></p>
        </div>
      </div>
      <div
        class="row g-0"
        [ngClass]="{
          'justify-content-end': msg.type === 'user'
        }">
        <div class="col-auto d-flex align-items-center">
          <ds-icon
            data-cy="messageIcon"
            icon="user_voice"
            tone="positive"
            size="sm"
            class="me-1x"
            title="{{ 'tooltip.messageIcon' | translate }}"></ds-icon>
          <span class="text-smallprint"
            >{{
              msg.type === 'bot'
                ? ('shared.atsCopilot.botName' | translate)
                : ('shared.atsCopilot.userName' | translate)
            }}
            - {{ msg.time }}</span
          >
        </div>
      </div>
    </ds-box-content>

    <ds-box-content spacing="none md md md" *ngIf="(waitingForResponse$ | async) === true">
      <div class="row g-0 justify-content-start">
        <div
          class="col-auto d-flex align-items-center rounded p-2x mb-1x bg-neutral border-neutral">
          <ds-progress-circle
            data-cy="waitingForResponseIcon"
            variant="indeterminate"
            class="me-1x">
          </ds-progress-circle>
          <p>{{ 'shared.atsCopilot.loading' | translate }}</p>
        </div>
      </div>
    </ds-box-content>
  </div>

  <ds-box-content divider="full">
    <div class="row g-0">
      <ds-select
        [value]="selectedBotId"
        data-cy="selectBotDropdown"
        size="md"
        [isMultiselect]="false"
        [options]="'CopilotBot' | dsEnumDropdown"
        [labellingConfig]="{ placeholder: ('shared.atsCopilot.menu' | translate) }"
        (optionSelected)="setSelectedBot($event)"></ds-select>
    </div>
  </ds-box-content>

  <ds-box-footer>
    <div class="row g-0">
      <div class="col">
        <input
          ds-input
          aria-label="message-input"
          style="width: 100%"
          type="text"
          class="form-control"
          [(ngModel)]="userInput"
          [disabled]="waitingForResponse$ | async"
          (keyup.enter)="sendMessage()"
          placeholder="{{ 'shared.atsCopilot.sendMsgPlaceholder' | translate }}" />
      </div>
      <div class="col-auto">
        <button
          (click)="sendMessage()"
          [disabled]="waitingForResponse$ | async"
          ds-button
          icon="send"
          variant="primary"
          aria-label="send-message"
          size="md"></button>
      </div>
    </div>
  </ds-box-footer>
</ds-box>

<ng-template #loading>{{ 'shared.atsCopilot.loading' | translate }}</ng-template>
