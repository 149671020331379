import { ZoneType } from 'core/dtos';
import { GuidString, Line, Zone, ZoneFilter } from 'core/models';
import { MapLayerView } from 'modules/maps/models';
import { Graphics, Point, Polygon, Rectangle } from 'pixi.js';
import { getEdgesFromPoints } from 'shared/helpers';
import { MapItemBase } from '../map-item-container';
import { ZoneMapItemContainer } from './zone-map-item-container';
import { ZoneVertices } from './zone-vertices.graphic';
import {
  ZoneAlpha,
  ZoneBorder,
  ZoneColor,
  ZoneErrorColor,
  ZoneInactiveBorderColor,
  ZoneInactiveColor,
} from './zone.constant';

export class ZoneMapItem implements MapItemBase {
  private index = 0;

  readonly vertexContainer: ZoneVertices;
  protected graphic: Graphics;
  protected isEditable = false;

  container: ZoneMapItemContainer;
  hasCollision = false;
  isSelected = false;

  get id(): GuidString {
    return this.zone?.id;
  }

  get type(): ZoneType {
    return this.zone?.zoneType;
  }

  get verticesCount(): number {
    return this.vertexContainer.count;
  }

  get points(): Point[] {
    if (!this.zone) {
      return [];
    }
    return this.zone.polygon.map(p => new Point(p.x, p.y));
  }

  constructor(view: MapLayerView, protected zone: Zone, protected readonly filters?: ZoneFilter) {
    this.container = view.addChild(
      new ZoneMapItemContainer(
        this.zone.id.toString(),
        zone.masterZoneId,
        zone.zoneType,
        zone.polygon
      )
    );
    this.container.interactive = true;
    this.container.buttonMode = true;

    this.container.addChild(
      (this.graphic = new Graphics()),
      (this.vertexContainer = new ZoneVertices())
    );

    this.resetIndex();
    this.update(zone);
  }

  protected createZoneGraphic(zone: Zone): void {
    let borderColor = ZoneBorder[this.zone.zoneType];
    let zoneFillColor = ZoneColor[this.zone.zoneType];
    const points = zone.polygon.map(p => new Point(p.x, p.y));

    if (
      (zone.zoneType === ZoneType.Interaction || zone.zoneType === ZoneType.Intersection) &&
      zone.device != null &&
      !zone.allConditionsMet
    ) {
      zoneFillColor = ZoneErrorColor;
      borderColor = ZoneBorder.error;
    }

    if ('isActive' in zone && !zone.isActive) {
      borderColor = ZoneInactiveBorderColor;
      zoneFillColor = ZoneInactiveColor;
    }

    if (this.isSelected) {
      borderColor = ZoneBorder.selected;
    }

    this.graphic
      .clear()
      .beginFill(zoneFillColor, ZoneAlpha[this.zone.zoneType] || ZoneAlpha.default);

    if (borderColor) {
      this.graphic.lineStyle(ZoneBorder.size, borderColor, ZoneBorder.alpha, ZoneBorder.alignment);
    }

    this.graphic.drawPolygon(points).endFill();
    this.createZoneVertices(points);
  }

  protected createZoneVertices(points: Point[]): void {
    if (this.isSelected && this.isEditable) {
      this.vertexContainer.createVertices(this.id.toString(), points);
    } else {
      this.vertexContainer.visible = false;
    }
  }

  update(zone?: Zone): void {
    if (zone) {
      this.zone = zone;
    }

    this.graphic.clear();
    this.createZoneGraphic(this.zone);
    this.setVisibility(this.filters);
  }

  showVertices(isEditing: boolean): void {
    this.isEditable = isEditing;
    this.createZoneGraphic(this.zone);
  }

  remove(): void {
    this.container.removeChildren();
    this.container.parent.removeChild(this.container);
  }

  setPosition(point: Point): void {
    this.container.position.copyFrom(point);
  }

  toggleSelection(isSelected = false, isEditing = false): void {
    this.isSelected = isSelected;
    this.isEditable = isEditing;

    this.vertexContainer.clearSelection();
    this.createZoneGraphic(this.zone);

    if (this.isSelected) {
      this.moveToFront();
    } else {
      this.moveToOrder();
    }
  }

  private resetIndex(): void {
    this.index = this.container.parent?.getChildIndex(this.container);
  }

  setIndex(index: number): void {
    this.container.parent?.setChildIndex(this.container, index);
  }

  moveToFront(): void {
    this.resetIndex();
    this.container.parent?.setChildIndex(this.container, this.container.parent.children.length - 1);
  }

  moveToOrder(): void {
    if (!this.container.parent) return;

    const current = this.container.parent.getChildIndex(this.container);

    if (current !== this.index) {
      this.container.parent.setChildIndex(this.container, this.index);
    }
  }

  setVisibility(filter?: ZoneFilter): void {
    if (!filter) {
      return;
    }
    this.container.visible = !!filter.type.find(x => x.id === this.zone.zoneType)?.selected;
  }

  getShape(): Point[] {
    const { x, y } = this.container.position;

    return this.zone.polygon.map(p => new Point(p.x + x, p.y + y));
  }

  getEdges(): Line[] {
    return getEdgesFromPoints(this.points);
  }

  getBounds(): Rectangle {
    return this.graphic.getLocalBounds();
  }

  getPolygon(): Polygon {
    return new Polygon(this.zone.polygon);
  }
}
