import { GuidString, ZoneMembershipStatus } from 'core/models';
import { ZoneAccessPriorityReasonEnum } from '../vehicles/intersection-zone-vehicle-priority.dto';

export interface ZoneMembership {
  vehicleId: GuidString;
  vehicleName: string;
  masterZoneId: GuidString;
  status: ZoneMembershipStatus;
  waitingRank?: number;
  requestedDateTimeUtc: Date;
  grantedDateTimeUtc: Date;
  zoneAccessPriority: ZoneAccessPriorityReasonEnum;
  reason?: ZoneEntryGrantedReasonEnum;
  notGrantedReason?: ZoneEntryNotGrantedReasonEnum;
}

export enum ZoneEntryGrantedReasonEnum {
  Unknown = 0,
  DeadlockResolution = 1,
  ZoneHasCapacity = 2,
  EntryWasForceGranted = 3,
  ZoneIsInactive = 4,
  InteractionConditionsMet = 5,
  ZoneWasDeactivated = 6,
  ReGrant = 7,
  EntryWasGrantedByExternalService = 8,
  EntryGrantedForManualVehicle = 9,
}

export enum ZoneMembershipAction {
  Allow,
  Prioritize,
  Ignore,
  Remove,
}

export enum ZoneEntryNotGrantedReasonEnum {
  Unknown = 0,
  VehicleTooFar = 1,
}
