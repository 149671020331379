import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GuidString, SoftwareUpdateDto } from 'core/models';

import * as SoftwareUpdateActions from '../actions/software-update.actions';

export const featureKey = 'softwareUpdates';

export interface SoftwareUpdateState extends EntityState<SoftwareUpdateDto> {
  loading: boolean;
  loaded: boolean;
  selectedSoftwareUpdateId: GuidString;
  errorMessage: string;
}

export const entityIdMapper = (model: SoftwareUpdateDto): string =>
  `${model.hardwareVersion}${model.versionName}`;

export const softwareUpdatesAdapter: EntityAdapter<SoftwareUpdateDto> =
  createEntityAdapter<SoftwareUpdateDto>({
    selectId: entityIdMapper,
  });

export const initialState: SoftwareUpdateState = softwareUpdatesAdapter.getInitialState({
  loading: false,
  loaded: false,
  selectedSoftwareUpdateId: '',
  errorMessage: '',
});

export const softwareUpdatesReducer = createReducer(
  initialState,
  on(
    SoftwareUpdateActions.loadAllSoftwareUpdates,
    SoftwareUpdateActions.loadSoftwareUpdates,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),

  on(SoftwareUpdateActions.loadAllSoftwareUpdatesSuccess, (state, { softwareUpdates }) =>
    softwareUpdatesAdapter.setAll(softwareUpdates, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  // Add or replace multiple software update entities for a specific hardware version
  on(SoftwareUpdateActions.loadSoftwareUpdatesSuccess, (state, { softwareUpdates }) =>
    softwareUpdatesAdapter.setMany(softwareUpdates, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(
    SoftwareUpdateActions.loadSoftwareUpdatesFailure,
    SoftwareUpdateActions.loadAllSoftwareUpdatesFailure,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      loaded: false,
      errorMessage,
    })
  )
);

export function reducer(
  state: SoftwareUpdateState | undefined,
  action: Action
): SoftwareUpdateState {
  return softwareUpdatesReducer(state, action);
}

export const { selectEntities, selectAll } = softwareUpdatesAdapter.getSelectors();

export const getLoading = (state: SoftwareUpdateState): boolean => (state || initialState).loading;
export const getLoaded = (state: SoftwareUpdateState): boolean => (state || initialState).loaded;

export const getErrorMessage = (state: SoftwareUpdateState): string =>
  (state || initialState).errorMessage;

export const getEntities = selectEntities;
export const getAllSoftwareUpdates = selectAll;
