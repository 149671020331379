import { IBitmapTextStyle, ITextStyle } from 'pixi.js';

export enum FontName {
  Default = 'DEFAULT',
  White = 'WHITE',
  BoldWhite = 'BOLD_WHITE',
}

interface IFontStyle extends Partial<IBitmapTextStyle>, Partial<ITextStyle> {
  fontName: string;
  fontSize: number;
}

const FontStyle_Default: Partial<IFontStyle> = {
  fontName: FontName.Default,
  fontSize: 36,
  fontWeight: 'bold',
  fill: 0x444444,
};

const FontStyle_White: Partial<IFontStyle> = {
  fontName: FontName.White,
  fontSize: 45,
  fill: 0xffffff,
};

const FontStyle_BoldWhite: Partial<IFontStyle> = {
  fontName: FontName.BoldWhite,
  fontSize: 45,
  fontWeight: 'bold',
  fill: 0xffffff,
};

export const MapFonts: Record<FontName, Partial<IFontStyle>> = {
  [FontName.Default]: FontStyle_Default,
  [FontName.White]: FontStyle_White,
  [FontName.BoldWhite]: FontStyle_BoldWhite,
};
