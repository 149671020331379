import { createSelector } from '@ngrx/store';
import { getConnectivityTrafficLightStatus } from 'store-modules/opcua-devices-store/selectors/opcua-devices.selectors.helpers';
import { selectReducedWorkingAreas, selectSelectedWorkingAreaId } from 'store/selectors';

import * as fromOpcuaStreamingServices from '../reducers/index';
import * as fromOpcuaStreamingServiceReducer from '../reducers/opcua-streaming-service.reducer';

import { selectAllOpcuaDevices } from './opcua-devices.selectors';

const selectOpcuaStreamingServicesState = createSelector(
  fromOpcuaStreamingServices.getOpcuaDevicesFeatureState,
  fromOpcuaStreamingServices.getOpcuaStreamingServicesState
);

export const selectOpcuaStreamingServicesLoaded = createSelector(
  selectOpcuaStreamingServicesState,
  fromOpcuaStreamingServiceReducer.getOpcuaStreamingServiceLoaded
);

export const selectAllOpcuaStreamingServices = createSelector(
  selectOpcuaStreamingServicesState,
  fromOpcuaStreamingServiceReducer.getAllOpcuaStreamingServices
);

export const selectOpcuaStreamingServicesEntities = createSelector(
  selectOpcuaStreamingServicesState,
  fromOpcuaStreamingServiceReducer.getOpcuaStreamingServiceEntities
);

export const selectDevicesForTable = createSelector(
  selectReducedWorkingAreas,
  selectSelectedWorkingAreaId,
  selectAllOpcuaDevices,
  (workingAreas, currentWorkingAreaId, devices) => {
    return devices
      .filter(d => d.workAreas.find(w => w.workAreaId === currentWorkingAreaId) !== undefined)
      .map(device => ({
        id: device.name,
        name: device.name,
        description: device.description,
        server: device.server,
        streamingServiceName: device.streamingServiceName,
        pingNodeName: device.pingNodeName,
        akz: device.akz,
        nodes: device.nodes,
        workingAreaNames: workingAreas
          .filter(w => device.workAreas.find(dw => dw.workAreaId === w.id) !== undefined)
          .map(o => o.name)
          .join(', '),
        workingAreas: workingAreas.filter(
          w => device.workAreas.find(dw => dw.workAreaId === w.id) !== undefined
        ),
        lastUpdated: device.pingNodeName ? device.lastPingReceivedDate : null,
        info: getConnectivityTrafficLightStatus(device).valueOf().toString(),
        trafficLightStatus: getConnectivityTrafficLightStatus(device),
      }));
  }
);

export const selectOpcuaStreamingServicesForTable = createSelector(
  selectAllOpcuaStreamingServices,
  selectReducedWorkingAreas,
  selectSelectedWorkingAreaId,
  selectAllOpcuaDevices,
  streamingServices => {
    return streamingServices.map(streamingService => ({
      id: streamingService.name,
      name: streamingService.name,
      workingAreaNames: '',
      children: [],
    }));
  }
);
