import { createSelector } from '@ngrx/store';
import { ZoneSetDto } from 'core/dtos';
import { GuidString, Zone } from 'core/models';

import { selectMapId } from './maps.selectors';
import { selectAllZoneSets, selectZoneSetId } from './zone-sets.selectors';

import objectHelper from 'core/helpers/object.helper';
import { sortZonesByLayerOrder } from 'modules/maps/helpers';
import * as fromMapsFeatureState from '../reducers';
import * as fromZones from '../reducers/zones.reducer';

const selectZonesState = createSelector(
  fromMapsFeatureState.getMapsFeatureState,
  fromMapsFeatureState.getZonesState
);

export const selectZonesLoading = createSelector(selectZonesState, fromZones.getZonesLoading);

export const selectZonesLoaded = createSelector(selectZonesState, fromZones.getZonesLoaded);

export const selectZoneId = createSelector(selectZonesState, fromZones.getSelectedZoneId);

export const selectZonesErrorMessage = createSelector(
  selectZonesState,
  fromZones.getZoneErrorMessage
);

export const selectActionStatus = createSelector(selectZonesState, fromZones.getZonesActionStatus);

export const selectZonesEntities = createSelector(selectZonesState, fromZones.getZoneEntities);

export const selectAllZones = createSelector(selectZonesState, fromZones.getAllZones);

export const selectSelectedZone = createSelector(
  selectZoneId,
  selectZonesEntities,
  (selectedZoneId, zones) => zones[selectedZoneId.toString()]
);

export const selectZonesByMapId = createSelector(selectAllZones, selectMapId, (zones, mapId) =>
  zones.filter(zone => zone.mapId === mapId).sort(sortZonesByLayerOrder())
);

export const selectZonesByZoneSetId = createSelector(
  selectAllZones,
  selectZoneSetId,
  (zones, zoneSetId) => {
    return zones.filter(zones => zones.zoneSetId === zoneSetId).sort(sortZonesByLayerOrder());
  }
);

export const selectZoneSetAndZonesById = createSelector(
  selectAllZoneSets,
  selectAllZones,
  (zoneSets: ZoneSetDto[], zones: Zone[], zoneSetId: GuidString) => {
    const storeZones = zones.filter(z => z.zoneSetId === zoneSetId);
    const storeZoneSet = zoneSets.find(zoneSet => zoneSet.id === zoneSetId);
    const result = objectHelper.cloneDeep(storeZoneSet);

    if (result) {
      result.zones = storeZones;
    }

    return result;
  }
);
