import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ErrorAggregate } from 'core/dtos';
import { SignalRNextMessage } from 'core/models';
import { Subject } from 'rxjs';
import { ErrorsFeatureState } from 'store-modules/errors-store';

import { SignalRNextService } from '../signalr-next.service';
import { SignalrRoutes } from '../signalr-routes';
import { errorAggregateMessageReceived } from '../store/actions/signalr.actions';

@Injectable({
  providedIn: 'root',
})
export class ErrorAggregateSignalrService {
  errorAggregateMessageReceived = new Subject<SignalRNextMessage<ErrorAggregate>>();
  currentErrorAggregates?: string;

  constructor(
    private readonly signalRService: SignalRNextService,
    private readonly store: Store<ErrorsFeatureState>
  ) {
    this.registerConnections();
  }

  signalrSubscriberFactory(componentName: string): ErrorAggregateSignalrSubscriber {
    const joinErrorAggregateGroup = (): Promise<void> => {
      return this.signalRService.joinGroup(SignalrRoutes.ErrorsAggregates, componentName);
    };

    const leaveErrorAggregateGroup = async (): Promise<void> => {
      await this.signalRService.leaveRoute(SignalrRoutes.ErrorsAggregates, null, componentName);
    };

    return { joinErrorAggregateGroup, leaveErrorAggregateGroup };
  }

  protected registerConnections(): void {
    this.signalRService.registerConnectionNext(
      SignalrRoutes.ErrorsAggregates,
      this.errorAggregateMessageReceived
    );

    this.errorAggregateMessageReceived.subscribe(errorAggregateMessage => {
      this.store.dispatch(errorAggregateMessageReceived({ errorAggregateMessage }));
    });
  }
}

export interface ErrorAggregateSignalrSubscriber {
  joinErrorAggregateGroup(): Promise<void>;
  leaveErrorAggregateGroup(): Promise<void>;
}
