import { createSelector } from '@ngrx/store';
import * as fromGraphManagerFeatureState from '../reducers';
import * as fromRouteCustomizationRuleReducer from '../reducers/route-customization-rule.reducer';

const selectGraphManagerState = createSelector(
  fromGraphManagerFeatureState.getGraphManagerFeatureState,
  fromGraphManagerFeatureState.getRouteCustomizationRuleState
);

export const selectRouteCustomizationRuleLoading = createSelector(
  selectGraphManagerState,
  fromRouteCustomizationRuleReducer.getLoading
);

export const selectRouteCustomizationRuleLoaded = createSelector(
  selectGraphManagerState,
  fromRouteCustomizationRuleReducer.getLoaded
);

export const selectAllRouteCustomizationRules = createSelector(
  selectGraphManagerState,
  fromRouteCustomizationRuleReducer.getRules
);
