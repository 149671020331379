import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { SensorLookupMapping } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { ToastService } from 'core/services';
import { Observable, catchError, firstValueFrom, of } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class SensorLookupMappingService extends TenantHttpClient {
  protected apiUrl = environment.Services.OrderGateway;
  private readonly servicePath = API_SERVICES.SensorLookupMappings;
  readonly toastService = inject(ToastService);

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getSensorLookupMappingForDevice(deviceName: string): Observable<SensorLookupMapping[]> {
    return this.get<SensorLookupMapping[]>(`${this.servicePath}/${deviceName}`).pipe(
      catchError((error: unknown) => {
        const errorMessage = typeof error === 'string' ? error : JSON.stringify(error);
        this.toastService.createErrorToast(errorMessage);
        return of([]);
      })
    );
  }

  async updateSensorLookupMappings(
    deviceName: string,
    mappings: SensorLookupMapping[]
  ): Promise<void> {
    await firstValueFrom(this.post<void>(`${this.servicePath}/${deviceName}`, mappings))
      .then(() =>
        this.toastService.createSuccessToast('shared.actions.updated', {
          name: deviceName,
        })
      )
      .catch(error => this.toastService.createErrorToast(error));
  }
}
