import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { ODataDto, OrganizationDto } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends BaseHttpClient {
  protected apiUrl = environment.Services.Auth;
  private readonly oDataUrl = '/odata/Organizations?$expand=workAreas';
  private readonly servicePath = API_SERVICES.Organization;

  getOrganizations(): Observable<OrganizationDto[]> {
    return this.get<ODataDto<OrganizationDto>>(this.oDataUrl).pipe(map(response => response.value));
  }

  getCreatedOrganization(orgName: string): Observable<OrganizationDto> {
    return this.get<ODataDto<OrganizationDto>>(this.oDataUrl, {
      $filter: `name eq '${orgName}'`,
    }).pipe(map(response => response.value[0]));
  }

  createOrganization(organization: OrganizationDto): Observable<OrganizationDto> {
    return this.post<OrganizationDto>(this.servicePath, organization);
  }

  updateOrganization(oldOrgName: string, updatedOrg: OrganizationDto): Observable<OrganizationDto> {
    return this.put<OrganizationDto>(`${this.servicePath}/${oldOrgName}`, updatedOrg);
  }

  deleteOrganization(organization: OrganizationDto): Observable<void> {
    return this.delete<void>(`${this.servicePath}/${organization.name}`);
  }
}
