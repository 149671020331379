export enum AlertNowServiceTab {
  RecipientKeys = 'recipientKeysTab',
  ZoneAlertNowGroups = 'zoneAlertNowGroupsTab',
}

export enum IpstAlertNowSettingsTab {
  ErrorForwardingService = 'ErrorForwardingService',
  IpstService = 'IpstService',
  AlertNowService = 'AlertNowService',
  HelpTool = 'HelpTool',
}
