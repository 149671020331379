/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import {
  EvacModeToggleFireFighterGetResponse,
  EvacModeToggleFireFighterPutResponse,
  EvacuationModeToggleFireFighterUpdateRequest,
} from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FireFighterSettingsService extends BaseHttpClient {
  protected apiUrl = environment.Services.FleetManager;
  private readonly fireServicePath = API_SERVICES.FireFighter;

  getAllFireFighterSettings(): Observable<EvacModeToggleFireFighterGetResponse[]> {
    return this.get<EvacModeToggleFireFighterGetResponse[]>(
      `${this.fireServicePath}/fireFighterSettings`
    );
  }

  updateEvacuationModeFireFighter(
    evacuationModeToggle: EvacuationModeToggleFireFighterUpdateRequest
  ): Observable<EvacModeToggleFireFighterPutResponse> {
    return this.put<EvacModeToggleFireFighterPutResponse>(
      `${this.fireServicePath}/evacuationModeFireFighter?organizationId=${evacuationModeToggle.organizationId}&workAreaId=${evacuationModeToggle.workAreaId}`,
      {
        isToggledOn: evacuationModeToggle.isToggledOn,
      }
    );
  }
}
