import { deviceOccupancy, opcuaDeviceTrafficLightStatus } from './colors-devices.constant';
import { ManageMapDataColors, ManageMapDataTones } from './colors-map-data.constant';
import { GraphLayerColors, RouteConfigPickerColors } from './colors-maps-base-routes.constant';
import { deliveryStatus, missionStatus, report } from './colors-missions.constant';
import {
  maintenanceModeColor,
  softwareUpdateVersionStatus,
  vehicleStatus,
  vehicleTrafficLightStatus,
} from './colors-vehicles.constant';

export const Colors = {
  vehicleStatus,
  report,
  maintenanceModeColor,
  deliveryStatus,
  missionStatus,
  vehicleTrafficLightStatus,
  opcuaDeviceTrafficLightStatus,

  graphLayer: GraphLayerColors,
  routeConfigPickerColors: RouteConfigPickerColors,
  deviceOccupancy,
  softwareUpdateVersionStatus,
  manageMapData: ManageMapDataColors,
};

export const Tones = {
  manageMapData: ManageMapDataTones,
};
