/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable sonarjs/no-identical-functions */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ZonesService } from 'core/api-services';
import { Zone } from 'core/models';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import * as ZonesActions from 'store-modules/maps-store/actions/zones.actions';
import { setHasChanges } from 'store/actions/ui.actions';
import * as ZoneSetActions from '../actions/zone-set.actions';

@Injectable()
export class ZonesEffects {
  loadZonesByZoneSetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZonesActions.loadZonesByZoneSetId),
      concatMap(({ zoneSetId }) => {
        return this.zonesService.getZonesByZoneSetId(zoneSetId).pipe(
          map((zones: Zone[]) => ZonesActions.loadZonesByZoneSetIdSuccess({ zones: zones })),
          catchError(errorMessage => of(ZonesActions.loadZonesByZoneSetIdFailure({ errorMessage })))
        );
      })
    )
  );

  addZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZonesActions.addZone),
      concatMap(({ zone, zoneSet }) => {
        if (zoneSet) {
          return this.zonesService.createZoneFrom(zone, zoneSet).pipe(
            concatMap(zs => [
              ZonesActions.addZonesSuccess({ zones: zs.zones }),
              ZoneSetActions.addZoneSetSuccessAndNavigate({ zoneSet: zs }),
            ]),
            catchError(errorMessage => of(ZoneSetActions.addZoneSetFailure({ errorMessage })))
          );
        } else {
          return this.zonesService.createZone(zone).pipe(
            map(zone => ZonesActions.addZoneSuccess({ zone })),
            catchError(errorMessage => of(ZonesActions.addZoneFailure({ errorMessage })))
          );
        }
      })
    )
  );

  addZoneSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZonesActions.addZoneSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.zoneActions.created');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  addZonesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZonesActions.addZonesSuccess),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  updateZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZonesActions.updateZone),
      concatMap(({ zone, zoneSet }) => {
        if (zoneSet) {
          return this.zonesService.updateZoneFrom(zone, zoneSet).pipe(
            concatMap(zs => [
              ZonesActions.addZonesSuccess({ zones: zs.zones }),
              ZoneSetActions.addZoneSetSuccessAndNavigate({ zoneSet: zs }),
            ]),
            catchError(errorMessage => of(ZonesActions.addZonesFailure({ errorMessage })))
          );
        } else {
          return this.zonesService.updateZone(zone).pipe(
            map(updatedZone =>
              ZonesActions.updateZoneSuccess({ zone: updatedZone, zoneId: zone.id })
            ),
            catchError(errorMessage => of(ZonesActions.updateZoneFailure({ errorMessage })))
          );
        }
      })
    )
  );

  updateZoneSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZonesActions.updateZoneSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.zoneActions.updated');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  deleteZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZonesActions.deleteZone),
      concatMap(({ zone, zoneSet }) => {
        if (zoneSet) {
          return this.zonesService.deleteZoneFrom(zone, zoneSet).pipe(
            concatMap(zs => {
              if (zs.zones && zs.zones.length > 0) {
                return [
                  ZonesActions.addZonesSuccess({ zones: zs.zones }),
                  ZoneSetActions.addZoneSetSuccessAndNavigate({ zoneSet: zs }),
                ];
              } else {
                return [ZoneSetActions.addZoneSetSuccessAndNavigate({ zoneSet: zs })];
              }
            }),
            catchError(errorMessage => of(ZonesActions.addZonesFailure({ errorMessage })))
          );
        } else {
          return this.zonesService.deleteZone(zone).pipe(
            map(() => ZonesActions.deleteZoneSuccess({ zone })),
            catchError(errorMessage => of(ZonesActions.deleteZoneFailure({ errorMessage })))
          );
        }
      })
    )
  );

  deleteZoneSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZonesActions.deleteZoneSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.zoneActions.deleted');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  deleteZoneFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZonesActions.deleteZoneFailure),
        tap(() => {
          this.toastService.createErrorToast('maps.zoneActions.deleteFailure');
        })
      ),
    { dispatch: false }
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ZonesActions.addZoneFailure,
          ZonesActions.addZonesFailure,
          ZonesActions.updateZoneFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  resetActionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ZonesActions.addZoneSuccess,
        ZonesActions.addZonesSuccess,
        ZonesActions.updateZoneSuccess,
        ZonesActions.deleteZoneSuccess,
        ZonesActions.addZoneFailure,
        ZonesActions.addZonesFailure,
        ZonesActions.updateZoneFailure,
        ZonesActions.deleteZoneFailure
      ),
      map(() => ZonesActions.resetActionStatus())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly zonesService: ZonesService,
    private readonly toastService: ToastService
  ) {}
}
