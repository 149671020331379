import { MapDto } from 'core/dtos';
import { BackgroundImage, ReducedMap } from 'core/models';
import { min } from 'lodash';
import { MapContainerDetails } from './map-container-details';

export interface SelectedMapData {
  selectedMap: MapDto;
  details: MapContainerDetails;
}

export function mapToReducedSplit(map: MapDto, details: MapContainerDetails): ReducedMap {
  return {
    id: map.id,
    name: map.name,
    workAreaId: map.workAreaId,
    layers: details.layers,
    resolution: min(details.layers.map(l => l.resolution)) ?? 0,
    height: details.totalHeight,
    backgroundImage: mapToBackgroundImage(map, details),
  };
}

export function mapToReduced(data: SelectedMapData): ReducedMap {
  return mapToReducedSplit(data.selectedMap, data.details);
}

export function mapToBackgroundImage(map: MapDto, details: MapContainerDetails): BackgroundImage {
  return {
    height: details.content.imageHeight ?? 0,
    width: details.content.imageWidth ?? 0,
    transparency: map.backgroundTransparency,
    imageUrl: map.mapBackgroundImageUrl,
  };
}
