import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { OrganizationDto, WorkingAreaDto } from 'core/dtos';
import { GuidString } from 'core/models';
import { TenantRouterService } from 'core/services';
import { ExpandCollapseButtonsService } from 'library/components';

import { Observable, Subject, combineLatest, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/index';

export interface AtsTreeItem {
  id: string;
  label: string;
  isOpen: boolean;
  children: AtsTreeItem[];
}

const GLOBAL_URL = 'global-user-roles-permission';

@Component({
  selector: 'app-environment-organization-working-area-overview-container',
  templateUrl: './environment-organization-working-area-overview-container.component.html',
  styleUrls: ['./environment-organization-working-area-overview-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentOrganizationWorkingAreaOverviewContainerComponent
  implements OnInit, OnDestroy
{
  treeViewInputModel$: Observable<AtsTreeItem[]> = of([]);
  selectedId$: Observable<GuidString> = of('');
  isLoading$: Observable<boolean> = of(false);
  parentSelected = false;

  ngUnsubscribe = new Subject<void>();
  nodesState: { [id: string]: boolean } = {};

  selectedMapId = '';
  allNodesExpanded = false;

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly route: Router,
    private readonly tenantRouterService: TenantRouterService,
    private readonly expandCollapseButtonsService: ExpandCollapseButtonsService
  ) {}

  ngOnInit(): void {
    this.treeViewInputModel$ = combineLatest([
      this.rootStore.select(fromRoot.selectAllOrganizations),
      this.rootStore.select(fromRoot.getRouterInfo),
    ]).pipe(
      map(([organizations, routerInfo]) => {
        if (routerInfo.params.organizationId) {
          this.parentSelected = true;
        }
        const environmentName = environment.config.environment.toLowerCase();
        return [
          {
            id: '',
            label: environmentName,
            isOpen: this.parentSelected,
            children: this.mapTreeViewInputModel(organizations, routerInfo.params.organizationId),
          },
        ];
      })
    );

    this.expandCollapseButtonsService.setDisableCollapseAllButton(true);
    this.expandCollapseButtonsService.setDisableExpandAllButton(false);
    this.subscribeToExpandCollapseEvents();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  subscribeToExpandCollapseEvents(): void {
    this.expandCollapseButtonsService
      .getClickExpandAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.onExpandCollapseAll(true);
      });
    this.expandCollapseButtonsService
      .getClickCollapseAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.onExpandCollapseAll(false);
      });
  }

  mapTreeViewInputModel(
    organizations: OrganizationDto[] | WorkingAreaDto[],
    orgId: string
  ): AtsTreeItem[] {
    return organizations.map(orgs => {
      let workAreas: WorkingAreaDto[] = [];
      if ('workAreas' in orgs) {
        workAreas = orgs.workAreas;
      }

      const isOpen = this.nodesState[orgs.id.toString()] ?? orgs.id === orgId;
      return {
        id: orgs.id.toString(),
        label: orgs.name,
        isOpen,
        children: this.mapTreeViewInputModel(workAreas, orgId),
      };
    });
  }

  updateNodesState(nodes: AtsTreeItem[], isOpen: boolean): AtsTreeItem[] {
    return nodes.map(node => {
      this.nodesState[node.id] = isOpen;
      return {
        ...node,
        isOpen: isOpen,
        children: node.children ? this.updateNodesState(node.children, isOpen) : [],
      };
    });
  }

  async onSelectEnvironment(): Promise<void> {
    this.parentSelected = true;
    if (this.route.url.includes(GLOBAL_URL))
      await this.route.navigate(['/settings/global-user-roles-permission/environment-users']);
    else
      await this.tenantRouterService.navigate([
        '/settings/user-roles-permission/environment-users',
      ]);
  }

  async onSelectOrg(orgId: string): Promise<void> {
    if (this.route.url.includes(GLOBAL_URL))
      await this.route.navigate([
        '/settings/global-user-roles-permission/organization-users/',
        orgId,
      ]);
    else
      await this.tenantRouterService.navigate([
        '/settings/user-roles-permission/organization-users/' + orgId,
      ]);
  }

  async onSelectWorkArea(orgId: string, workAreaId: string): Promise<void> {
    if (this.route.url.includes(GLOBAL_URL))
      await this.route.navigate([
        '/settings/global-user-roles-permission/working-area-users/',
        orgId,
        workAreaId,
      ]);
    else
      await this.tenantRouterService.navigate([
        '/settings/user-roles-permission/working-area-users/' + orgId + '/' + workAreaId,
      ]);
  }

  onExpandCollapseAll(isOpen: boolean): void {
    this.allNodesExpanded = isOpen;
    this.treeViewInputModel$
      .pipe(
        take(1),
        map(nodes => this.updateNodesState(nodes, isOpen))
      )
      .subscribe(updatedNodes => {
        this.treeViewInputModel$ = of(updatedNodes);
      });
  }
}
