import { UserManualRouteNames } from './user-manual-route.enum';

export const UserManualLinks = [
  {
    name: UserManualRouteNames.Welcome,
    route: 'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/00_willkommen/',
  },
  {
    name: UserManualRouteNames.Reports,
    route: 'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/1_home/3_berichte/',
  },
  {
    name: UserManualRouteNames.Maps,
    route: 'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/2_maps/2_kartenansicht/',
  },
  {
    name: UserManualRouteNames.ManageMaps,
    route: 'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/2_maps/3_kartenverwaltung/',
  },
  {
    name: UserManualRouteNames.ManageZoneset,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/2_maps/4_zonensetverwaltung/',
  },
  {
    name: UserManualRouteNames.ActiveMissions,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/3_auftraege/2_aktive_auftraege/',
  },
  {
    name: UserManualRouteNames.ActiveMissionsView,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/3_auftraege/2_aktive_auftraege/#2-detailansicht-aktiver-auftrage',
  },
  {
    name: UserManualRouteNames.HistoryMissions,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/3_auftraege/3_vergangene_auftraege/',
  },
  {
    name: UserManualRouteNames.HistoryMissionsView,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/3_auftraege/3_vergangene_auftraege/#2-detailansicht-vergangener-missionen',
  },
  {
    name: UserManualRouteNames.ProcessConfigurator,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/3_auftraege/4_prozesskonfigurator/',
  },
  {
    name: UserManualRouteNames.Vehicles,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/4_fahrzeuge/2_tabellenansicht_fahrzeuge/',
  },
  {
    name: UserManualRouteNames.VehiclesNew,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/4_fahrzeuge/4_neue_fahrzeuge/',
  },
  {
    name: UserManualRouteNames.VehiclesView,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/4_fahrzeuge/3_detailansicht_fahrzeuge/',
  },
  {
    name: UserManualRouteNames.Pois,
    route: 'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/5_pois/1_allgmein/',
  },
  {
    name: UserManualRouteNames.Mapping,
    route: 'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/5_pois/3_mapping-tabelle/',
  },
  {
    name: UserManualRouteNames.Devices,
    route: 'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/6_geraete/1_geraete/',
  },
  {
    name: UserManualRouteNames.EnvironmentUsers,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/7_einstellungen/3_rollen_und_berechtigungen/',
  },
  {
    name: UserManualRouteNames.ErrorForwarding,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/7_einstellungen/2_fehlerweiterleitung/',
  },
  {
    name: UserManualRouteNames.Ipst,
    route: 'https://handbook.ats.azure.cloud.bmw/3-externe-anbindungen/1_ipst/',
  },
  {
    name: UserManualRouteNames.SettingsFunction,
    route:
      'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/7_einstellungen/1_funktionen/',
  },
];
