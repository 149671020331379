/* eslint-disable @typescript-eslint/no-magic-numbers */
import '@pixi/math';
import { DATE_FORMAT, LOCALSTORAGE } from 'core/constants';
import { CoordinateSystemOffset, NavigationLayerCardModel } from 'core/dtos';
import { GuidString } from 'core/models';
import { formatDate, round } from 'shared/helpers';
import { NAVIGATION_LAYER_DISTANCE_PRECISION } from '../constants/map.constant';

const SCALES = [10, 5, 2];

export function normalizeScale(value: number, minSize: number): number {
  const significant = getSignificant(value);
  const minSizeSign = getSignificant(minSize);
  let result = getSignificant(significant / minSizeSign);

  for (const n of SCALES) {
    if (result >= n) {
      result /= n;
    }
  }

  return result;
}

function getSignificant(x: number): number {
  return x * Math.pow(10, Math.ceil(-Math.log10(x)));
}

export function getMinMaxValue(value: number, maxValue: number, minValue: number): number {
  return Math.min(Math.max(value, maxValue), minValue);
}

export function getOrientationFromLocalStorage(mapId: GuidString): number {
  let orientation = 0;
  const json = localStorage.getItem(LOCALSTORAGE.MAP_ORIENTATION_KEY);
  if (json) {
    const mapOrientationItems = new Map(JSON.parse(json));
    orientation = Number(mapOrientationItems.get(mapId) ?? 0);
  }
  return orientation;
}

export function setOrientationInLocalStorage(mapId: GuidString, orientation: number): void {
  let mapOrientationItems = new Map([[mapId, orientation]]);
  const jsonString = localStorage.getItem(LOCALSTORAGE.MAP_ORIENTATION_KEY);
  if (jsonString) {
    mapOrientationItems = new Map(JSON.parse(jsonString));
    mapOrientationItems.set(mapId, orientation);
  }
  localStorage.setItem(
    LOCALSTORAGE.MAP_ORIENTATION_KEY,
    JSON.stringify(Array.from(mapOrientationItems))
  );
}

export function getMapIdFromLocalStorage(workingAreaId: GuidString | undefined): string | null {
  return localStorage.getItem(LOCALSTORAGE.MAP_KEY + workingAreaId);
}

export function setMapIdInLocalStorage(
  workingAreaId: GuidString | undefined,
  mapId: GuidString
): void {
  if (workingAreaId) {
    localStorage.setItem(LOCALSTORAGE.MAP_KEY + workingAreaId, mapId.toString());
  }
}

export const orientationInLocalStorage = {
  setOrientationInLocalStorage: (mapId: GuidString, orientation: number): void => {
    let mapOrientationItems = new Map([[mapId, orientation]]);
    const json = localStorage.getItem(LOCALSTORAGE.MAP_ORIENTATION_KEY);
    if (json) {
      mapOrientationItems = new Map(JSON.parse(json));
      mapOrientationItems.set(mapId, orientation);
    }
    localStorage.setItem(
      LOCALSTORAGE.MAP_ORIENTATION_KEY,
      JSON.stringify(Array.from(mapOrientationItems))
    );
  },

  getOrientationFromLocalStorage: (mapId: GuidString): number => {
    let orientation = 0;
    const jsonString = localStorage.getItem(LOCALSTORAGE.MAP_ORIENTATION_KEY);
    if (jsonString) {
      const mapOrientationItems = new Map(JSON.parse(jsonString));
      orientation = Number(mapOrientationItems.get(mapId) ?? 0);
    }
    return orientation;
  },
};

export function getNewNavigationLayerName(mapName: string, vehicleTypeText: string): string {
  const fullDate = new Date();
  const mapNameAndVehicleType = mapName
    .concat('_NL_')
    .concat(vehicleTypeText ?? '')
    .concat('_');

  fullDate.setMinutes(new Date().getMinutes());

  const date = formatDate(fullDate, DATE_FORMAT.DATE_DAY_MONTH_YEAR);
  const time = formatDate(fullDate, DATE_FORMAT.TIME_HOURS_MINUTES);
  const dateTime = date
    ?.toString()
    .concat('_')
    .concat(time ? time.toString() : '');

  return mapNameAndVehicleType.concat(dateTime);
}

export function covertPixelsToDistance(
  pixels: number,
  resolution: number,
  precision: number = NAVIGATION_LAYER_DISTANCE_PRECISION
): number {
  if (!pixels || resolution === 0) {
    return 0;
  }
  return round(pixels * resolution, precision);
}

export function covertDistanceToPixels(distance: number, resolution: number): number {
  if (!distance || resolution === 0) {
    return 0;
  }

  return round(Number(distance) / Number(resolution), NAVIGATION_LAYER_DISTANCE_PRECISION);
}

export function updateCoordinateSystem(
  changedLayer: NavigationLayerCardModel,
  card: NavigationLayerCardModel
): CoordinateSystemOffset | undefined {
  return changedLayer.applyCoordinateOffset
    ? {
        translation: {
          x: Number(card.imageOffset.x),
          y: Number(card.imageOffset.y),
        },
        rotation: Number(card.imageOffset.rotation),
        scale: {
          x: 1,
          y: 1,
        },
      }
    : undefined;
}
