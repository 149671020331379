import { Circle, Graphics, Point } from 'pixi.js';
import { ZoneEditorStyle } from '../zone.constant';

export class DrawPointerGraphic extends Graphics {
  private readonly size = ZoneEditorStyle.verticesSize;
  private readonly centre = this.size / 2;
  private _hasSnap = false;

  get distance(): number {
    return ZoneEditorStyle.verticesSize * this.scale.x;
  }

  get hasSnapPoint(): boolean {
    return this._hasSnap;
  }

  get hasClosePoint(): boolean {
    return this.hitArea !== null;
  }

  constructor() {
    super();
  }

  drawGraphic(isCreateMode: boolean): void {
    if (isCreateMode) {
      this.clear()
        .beginFill(ZoneEditorStyle.closeZoneColor)
        .lineStyle(ZoneEditorStyle.closeZoneBorder, ZoneEditorStyle.borderColor)
        .drawRect(
          -this.centre,
          -this.centre,
          ZoneEditorStyle.verticesSize,
          ZoneEditorStyle.verticesSize
        )
        .endFill();
    } else {
      this.clear()
        .beginFill(ZoneEditorStyle.verticesColor)
        .lineStyle(ZoneEditorStyle.verticesBorder, ZoneEditorStyle.verticesBorderColor)
        .drawRect(
          -this.centre,
          -this.centre,
          ZoneEditorStyle.verticesSize,
          ZoneEditorStyle.verticesSize
        )
        .endFill();
    }
  }

  showPointer(visible: boolean, position?: Point | null, snapped = false): void {
    this.visible = visible;
    this._hasSnap = snapped;

    if (position) {
      this.position.copyFrom(position);
    }
  }

  setScale(scale: number): void {
    this.scale.set(1 / scale + ZoneEditorStyle.pointerScale);
  }

  setCloseZoneHitArea(point: Point): void {
    this.hitArea = new Circle(point.x, point.y, ZoneEditorStyle.closeZoneHitArea * this.scale.x);
  }

  hitClosingPoint(point: Point): boolean {
    return this.hitArea?.contains(point.x, point.y) ?? false;
  }
}
