<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<h4 *ngIf="header" class="marginSeparator">{{ header }}</h4>
<app-menu
  #menu
  [popup]="true"
  [hidePopup]="hideMenuItemPopup"
  [model]="menuItems"
  [styleClass]="menuItemsStyleClass"
  appendTo="body"></app-menu>
<p-table
  data-cy="table"
  [columns]="displayedColumns"
  [value]="records"
  [responsive]="false"
  [rows]="rows"
  [loading]="loading"
  [paginator]="hasPaginator"
  [totalRecords]="totalNumberOfRecords"
  (onPage)="onPage($event)"
  [lazy]="isLazy"
  [first]="first"
  (onLazyLoad)="loadLazy($event)"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  (sortFunction)="sortFunction($event)"
  [customSort]="true"
  selectionMode="single"
  [selection]="selectedRow"
  scrollDirection="vertical"
  [rowsPerPageOptions]="[25, 50, 100]"
  [reorderableColumns]="reorderableColumns"
  [autoLayout]="true"
  [rowTrackBy]="trackByFunction"
  (onColReorder)="onColReorder()">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        [style.min-width.px]="col.size"
        [style.display]="isTableConfigurationModeActive || !col.isHidden ? '' : 'none'"
        [pSortableColumn]="
          col.isSortable !== undefined ? col.isSortable : isSortable ? col.field : null
        "
        pReorderableColumn>
        <div>
          <span>
            {{ translationContext + '.' + col.field | translate }}
          </span>

          <div class="alignRight">
            <div *ngIf="isTableConfigurationModeActive">
              <span
                [ngClass]="col.isHidden ? 'iwp-icon-gen_hide' : 'iwp-icon-gen_show_all'"
                class="icon-toolbar-button-eye"
                (click)="hideColumn($event, col)"></span>
            </div>
            <p-sortIcon
              [field]="col.field"
              *ngIf="col.isSortable !== undefined ? col.isSortable : isSortable"></p-sortIcon>
          </div>
        </div>
      </th>

      <th class="actionMenuHeader" *ngIf="showActionMenu">
        <span>{{ 'shared.treeTable.actionMenu.actionMenuHeader' | translate }}</span>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr
      [pSelectableRow]="rowData"
      (click)="onRowSelect(rowData)"
      [ngClass]="rowData['highlightClass']">
      <td
        *ngFor="let col of columns; last as isLast"
        [style.min-width.px]="col.size"
        [style.display]="isTableConfigurationModeActive || !col.isHidden ? '' : 'none !important'">
        <div [ngClass]="{ 'container-last': isLast, 'show-full-label': showFullLabel }">
          <div [ngClass]="{ lastColumnData: isLast }">
            <ng-container
              *ngTemplateOutlet="
                itemTemplate;
                context: {
                  $implicit: rowData,
                  isLast: isLast,
                  field: col.field,
                  type: col.type,
                  icon: col.icon,
                  searchTerm: searchTerm
                }
              "></ng-container>
            <span
              *ngIf="col.type === 'timestamp'"
              [innerHTML]="
                (rowData[col.field] | date : dateTimeFormat | notApplicable).toString()
                  | highlight : searchTerm
              ">
            </span>
            <span
              *ngIf="col.type === 'timestamp-today'"
              [innerHTML]="
                (rowData[col.field] | formatDateToday | notApplicable).toString()
                  | highlight : searchTerm
              ">
            </span>
            <span
              *ngIf="col.type === 'timestamp-includeIn'"
              [innerHTML]="
                (rowData[col.field] | formatDateIncludeIn | notApplicable).toString()
                  | highlight : searchTerm
              ">
            </span>
            <span *ngIf="col.type === 'relative-timestamp'">
              <span *ngIf="rowData[col.field]; else elseBlock">
                {{ rowData[col.field] | formatDateNow }} ({{
                  rowData[col.field] | date : 'dd.MM.yyyy, H:mm:ss'
                }})
              </span>
              <ng-template #elseBlock>-</ng-template>
            </span>
            <span *ngIf="col.type === 'mediumTime'">
              {{ rowData[col.field] | date : 'H:mm:ss' }}
            </span>
            <span *ngIf="col.type === 'text'">
              <span
                [innerHTML]="
                  (rowData[col.field] | notApplicable).toString() | highlight : searchTerm
                "></span>
            </span>
            <a
              *ngIf="col.type === 'routerLink'"
              (click)="$event.stopPropagation()"
              (click)="onNavigateTo(rowData)"
              [innerHTML]="
                (rowData[col.field] | notApplicable).toString() | highlight : searchTerm
              ">
            </a>
            <span
              *ngIf="col.type === 'enum'"
              [innerHTML]="
                (rowData[col.field] | enumTranslation : col.enumType | notApplicable).toString()
                  | highlight : searchTerm
              ">
            </span>
            <div style="height: 24px" *ngIf="col.type === 'inputSwitch'">
              <ds-switch
                data-cy="tableSwitch"
                (ngModelChange)="onInputSwitchClick(rowData, $event)"
                [(ngModel)]="rowData[col.field]"
                [disabled]="!isEditMode"></ds-switch>
            </div>
          </div>
        </div>
      </td>
      <td class="actionMenuCell" *ngIf="showActionMenu">
        <div class="center">
          <i
            data-cy="actionMenu"
            class="pi pi-ellipsis-h"
            (click)="onActionMenuClick(rowData, $event); menu.toggle($event)"></i>
        </div>
      </td>
    </tr>
    <tr *ngIf="newRowItemTemplate && rowIndex === records.length - 1">
      <ng-container
        *ngTemplateOutlet="
          newRowItemTemplate;
          context: {
            searchTerm: searchTerm,
            columnLength: displayedColumns.length
          }
        ">
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr *ngIf="emptyTableTemplate !== undefined">
      <ng-container
        *ngTemplateOutlet="
          emptyTableTemplate;
          context: {
            searchTerm: searchTerm,
            columnLength: columns.length + 1
          }
        ">
      </ng-container>
    </tr>
    <tr [ngStyle]="{ 'height.px': screenHeight }" *ngIf="emptyTableTemplate === undefined">
      <td class="noContent tableNoContent" [attr.colspan]="columns.length + 1">
        <div class="content-message-header tableNoContentHeading">
          {{ 'shared.treeTable.noContentMessage1' | translate }}
        </div>
        <div class="content-message">
          {{ 'shared.treeTable.noContentMessage2' | translate }}
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
