import { Ease } from 'pixi-ease';
import { IPointData } from 'pixi.js';
import { MapItemContainer } from './map-item-container';

export class MoveMapItemContainer extends MapItemContainer {
  private readonly ease = new Ease({});
  private targetPose = { x: 0, y: 0 };
  private readonly positionTolerance = 0.1;

  smoothMovement = true;

  moveTo({ x, y }: IPointData): void {
    if (x === this.x && y === this.y) {
      return;
    }

    if (this.targetPose.x === 0 || this.targetPose.y === 0) {
      this.position.set(x, y);
      this.targetPose = { x, y };
      return;
    }

    if (
      !(
        Math.abs(this.targetPose.x - x) < this.positionTolerance &&
        Math.abs(this.targetPose.y - y) < this.positionTolerance
      )
    ) {
      this.position.copyFrom(this.targetPose);
      this.ease.removeAll();
      this.targetPose = { x, y };

      if (this.smoothMovement) {
        this.ease.add(
          this,
          { x, y },
          {
            duration: 1000,
            ease: 'linear',
          }
        );
        return;
      }
      this.position.set(x, y);
    }
  }
}
