import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FleetManagerFeatures,
  InfobarMessageResponse,
  JobManagerFeatures,
  MapManagerFeatures,
  ShutdownRequestDto,
  TrafficManagementSettingsMessage,
} from 'core/dtos';
import { SignalRNextMessage } from 'core/models';
import { Subject } from 'rxjs';

import { SignalRNextService } from '../signalr-next.service';
import { SignalrRoutes } from '../signalr-routes';
import {
  fleetManagerFeaturesMessageReceived,
  infobarMessageReceived,
  jobManagerFeaturesMessageReceived,
  mapManagerFeaturesMessageReceived,
  shutdownModeMessageReceived,
  trafficManagerTrafficModeMessageReceived,
} from '../store/actions/signalr.actions';

export interface SettingsSignalrSubscriber {
  joinJobManagerFeatures(): Promise<void>;
  joinMapManagerFeatures(): Promise<void>;
  joinShutDownMode(): Promise<void>;
  joinInfobarMessage(): Promise<void>;
  joinFleetManagerFeatures(): Promise<void>;
  joinTrafficManagerFeatures(): Promise<void>;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsSignalRService {
  jobManagerFeaturesMessageReceivedNext = new Subject<SignalRNextMessage<JobManagerFeatures>>();
  mapManagerFeaturesMessageReceivedNext = new Subject<SignalRNextMessage<MapManagerFeatures>>();
  trafficManagerFeaturesMessageReceivedNext = new Subject<
    SignalRNextMessage<TrafficManagementSettingsMessage>
  >();
  shutdownModeMessageReceivedNext = new Subject<SignalRNextMessage<ShutdownRequestDto>>();
  fleetManagerFeaturesMessageReceivedNext = new Subject<SignalRNextMessage<FleetManagerFeatures>>();
  infobarMessageReceived = new Subject<SignalRNextMessage<InfobarMessageResponse>>();
  constructor(
    private readonly signalrNextService: SignalRNextService,
    private readonly store: Store<JobManagerFeatures>
  ) {
    this.registerConnections();
  }
  signalrSubscriberFactory(componentName: string): SettingsSignalrSubscriber {
    const joinJobManagerFeatures = (): Promise<void> => {
      return this.signalrNextService.joinGroup(SignalrRoutes.JobManagerFeatures, componentName);
    };

    const joinMapManagerFeatures = (): Promise<void> => {
      return this.signalrNextService.joinGroup(SignalrRoutes.MapManagerFeatures, componentName);
    };

    const joinShutDownMode = (): Promise<void> => {
      return this.signalrNextService.joinGroup(SignalrRoutes.ShutdownMode, componentName);
    };

    const joinInfobarMessage = (): Promise<void> => {
      return this.signalrNextService.joinGroup(SignalrRoutes.InfobarMessage, componentName);
    };

    const joinFleetManagerFeatures = (): Promise<void> => {
      return this.signalrNextService.joinGroup(SignalrRoutes.FleetManagerFeatures, componentName);
    };

    const joinTrafficManagerFeatures = (): Promise<void> => {
      return this.signalrNextService.joinGroup(SignalrRoutes.TrafficManagerFeatures, componentName);
    };

    return {
      joinJobManagerFeatures,
      joinMapManagerFeatures,
      joinShutDownMode,
      joinInfobarMessage,
      joinFleetManagerFeatures,
      joinTrafficManagerFeatures,
    };
  }

  protected registerConnections(): void {
    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.JobManagerFeatures,
      this.jobManagerFeaturesMessageReceivedNext
    );

    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.MapManagerFeatures,
      this.mapManagerFeaturesMessageReceivedNext
    );

    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.TrafficManagerFeatures,
      this.trafficManagerFeaturesMessageReceivedNext
    );

    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.ShutdownMode,
      this.shutdownModeMessageReceivedNext
    );

    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.FleetManagerFeatures,
      this.fleetManagerFeaturesMessageReceivedNext
    );

    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.InfobarMessage,
      this.infobarMessageReceived
    );

    this.infobarMessageReceived.subscribe(infobarMessage => {
      this.store.dispatch(infobarMessageReceived({ infobarMessage: infobarMessage.payload }));
    });

    this.jobManagerFeaturesMessageReceivedNext.subscribe(jobManagerFeaturesMessage => {
      this.store.dispatch(
        jobManagerFeaturesMessageReceived({ jobManagerFeatures: jobManagerFeaturesMessage.payload })
      );
    });

    this.mapManagerFeaturesMessageReceivedNext.subscribe(mapManagerFeaturesMessage => {
      this.store.dispatch(
        mapManagerFeaturesMessageReceived({ mapManagerFeatures: mapManagerFeaturesMessage.payload })
      );
    });

    this.trafficManagerFeaturesMessageReceivedNext.subscribe(trafficManagerTrafficModeMessage => {
      this.store.dispatch(
        trafficManagerTrafficModeMessageReceived({
          trafficManagerFeatures: trafficManagerTrafficModeMessage.payload,
        })
      );
    });

    this.shutdownModeMessageReceivedNext.subscribe(shutdownMessage => {
      this.store.dispatch(
        shutdownModeMessageReceived({ shutdownRequest: shutdownMessage.payload })
      );
    });

    this.fleetManagerFeaturesMessageReceivedNext.subscribe(fleetManagerFeaturesMessage => {
      this.store.dispatch(
        fleetManagerFeaturesMessageReceived({
          fleetManagerFeatures: fleetManagerFeaturesMessage.payload,
        })
      );
    });
  }
}
