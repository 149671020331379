/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RolesService } from 'core/api-services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions';

import * as RolesActions from '../actions/roles.actions';

@Injectable()
export class RolesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly rolesService: RolesService
  ) {}

  saveRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.saveRoles),
      concatMap(({ roles }) => {
        return this.rolesService.saveRoles(roles).pipe(
          map(() => RolesActions.saveRolesSuccess({ roles: roles })),
          catchError(errorMessage => of(RolesActions.saveRolesFailure({ errorMessage })))
        );
      })
    )
  );

  saveRolesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.saveRolesSuccess),
      tap(() => {
        this.toastService.createSuccessToast('settings.roles.updateRolesSuccess');
      }),
      switchMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  saveRolesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RolesActions.saveRolesFailure),
        tap(() => {
          this.toastService.createErrorToast('settings.roles.updateRolesFailure');
        })
      ),
    { dispatch: false }
  );

  loadRolesAndPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.loadRolesAndPermissions),
      concatMap(() =>
        this.rolesService.getRoles().pipe(
          map(roles => RolesActions.loadRolesAndPermissionsSuccess({ roles })),
          catchError(errorMessage =>
            of(RolesActions.loadRolesAndPermissionsFailure({ errorMessage }))
          )
        )
      )
    )
  );
}
