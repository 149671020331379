import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IpstSettingsDto } from 'core/dtos';

@Component({
  selector: 'app-ipst-alertnow-general-info-view',
  templateUrl: './ipst-alertnow-general-info-view.component.html',
  styleUrls: ['./ipst-alertnow-general-info-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowGeneralInfoViewComponent {
  @Input() generalInformation?: IpstSettingsDto;
}
