import { IMapItem, MapItemType } from 'core/models';
import { DisplayObject } from 'pixi.js';
import { MapLayerDrawing } from './map-layer-drawing';
import { ContainerEx } from './pixi/container-extended';

export interface MapItemBase {
  container: MapItemContainer;

  toggleSelection(isSelected: boolean): void;
  // create(vehicle: VehicleDto): void
  // update(vehicle: VehicleDto): void
  // setOrientation(orientation: number): void
  // onMapRotation(mapOrientation: number): void
}

export interface RotateMapItem {
  toggleRotationMode(enable: boolean): void;
}

export class MapItemContainer extends ContainerEx implements IMapItem {
  get id(): string {
    return this.name;
  }
  set id(val: string) {
    this.name = val;
  }

  type: MapItemType;

  get drawing(): MapLayerDrawing {
    if (this.parent instanceof MapLayerDrawing) {
      return this.parent;
    }
    throw new Error('Parent is not an instance of MapLayerDrawing');
  }

  constructor(dtoId: string, type: MapItemType) {
    super();
    this.id = dtoId;
    this.type = type;
  }

  setScaleReverse(): void {
    this.scale.set(1, -1);
  }

  remove(): void {
    this.children.forEach(c => c.destroy());
    this.removeChildren();
  }

  setOnTop(): void {
    this.drawing.setChildOnTop(this);
  }
}

export function isMapItemContainer(
  item: DisplayObject | MapItemContainer
): item is MapItemContainer {
  return 'id' in item && 'type' in item && 'drawing' in item;
}
