/* eslint-disable @typescript-eslint/no-magic-numbers */
import { VehicleColors } from 'library/styles';
import { BitmapText, Container, Graphics } from 'pixi.js';
import { GeometryConstant } from 'shared/helpers';
import { FontName, MapFonts } from '../../map-layer-fonts.constant';
import { MapIconAnchor } from '../../map-layer.constant';
import { MapLayerItemDimensions } from '../../map-layer.models';
import { VehicleDimensions, VehicleLoadDimensions } from '../vehicle-dimensions.model';
import { TagScale, VehicleLoadedStyle, VehicleName, VehicleStyle } from '../vehicle-layer.constant';

export interface TagOptions {
  text: string;
  font: FontName;
  scale: TagScale;
  color: number;
}

const TagWidth: Record<TagScale, number> = {
  [TagScale.TagScaleExtraLarge]: 44,
  [TagScale.TagScaleLarge]: 37,
  [TagScale.TagScaleMedium]: 38,
  [TagScale.TagScaleSmall]: 30,
  [TagScale.TagScaleExtraSmall]: 22,
};

export class VehicleGraphicHelper {
  // #region Base
  static createVehicleBase(dimensions: VehicleDimensions): Graphics {
    return new Graphics()
      .beginFill(VehicleColors.Base)
      .lineStyle(VehicleStyle.BorderSize, VehicleColors.Border)
      .drawRoundedRect(
        -dimensions.origin, // Distance from rear
        -dimensions.width / 2,
        dimensions.length,
        dimensions.width,
        VehicleStyle.Corner
      )
      .endFill();
  }

  static createVehicleBaseWithTag(
    dimensions: VehicleDimensions,
    tag: Graphics | Container,
    options: TagOptions
  ): Graphics {
    const base = new Graphics()
      .beginFill(VehicleColors.Base)
      .lineStyle(VehicleStyle.BorderSize, VehicleColors.Border)
      .drawRoundedRect(
        -dimensions.origin,
        -dimensions.width / 2,
        dimensions.length,
        dimensions.width,
        VehicleStyle.Corner
      )
      .endFill();

    const tagWidth = TagWidth[options.scale];
    const tagLength = dimensions.width - VehicleStyle.BorderSize;
    const tagY = -dimensions.width / 2 + VehicleStyle.BorderSize / 2;
    const tagX = dimensions.length - dimensions.origin - tagWidth;

    const tagBg = new Graphics()
      .beginFill(options.color)
      .drawRoundedRect(tagX, tagY, tagWidth, tagLength, VehicleStyle.Corner)
      .endFill()
      .beginFill(VehicleColors.Base)
      .drawRect(tagX, tagY, VehicleStyle.TagLine, tagLength)
      .endFill();

    tagBg.addChild(tag);
    base.addChild(tagBg);

    const labelX = dimensions.length - dimensions.origin - tag.height / 2;
    tag.position.set(labelX, 0);

    return base;
  }
  // #endregion

  // For loaded, the X, Y is swopped since the load sits side-ways on the vehicle
  static createLoaded(dimensions: VehicleLoadDimensions): Graphics {
    if (!dimensions.load) return new Graphics();

    const graphic = new Graphics()
      .beginFill(VehicleLoadedStyle.Color)
      .lineStyle(VehicleLoadedStyle.Border, VehicleLoadedStyle.Color)
      .drawRoundedRect(
        -dimensions.load.width / 2, // Centre of the load
        -dimensions.load.length / 2,
        dimensions.load.width,
        dimensions.load.length,
        VehicleLoadedStyle.Corner
      );

    graphic.visible = false;
    return graphic;
  }

  static calculateLabelOffset(
    dimensions: MapLayerItemDimensions,
    orientation: number,
    xPositionOffset: number,
    textHeight: number
  ): number {
    const minSpaceBetween = 0.8;
    const maxSpaceBetween = 1;

    const d = dimensions.length - dimensions.origin - xPositionOffset;

    const s = Math.sin(orientation);
    const calcY = Math.abs(Math.cos(orientation) * dimensions.width) / 2;
    const calcX = Math.abs(s) * d;
    const calcPx = s < -minSpaceBetween && s >= -maxSpaceBetween ? VehicleName.Padding : 0;

    return s >= 0
      ? Math.abs(calcX + calcY + textHeight + VehicleName.Padding)
      : -(calcX + calcY + calcPx);
  }

  // #region Tag
  static createTag(options: TagOptions): BitmapText {
    const text = new BitmapText(options.text, MapFonts[options.font]);
    text.interactive = false;
    text.buttonMode = false;
    text.align = 'center';

    text.anchor.set(MapIconAnchor.Middle);
    text.scale.set(options.scale);

    return text;
  }

  static calculateTagRotation(relativeOrientation: number): number {
    return Math.sin(relativeOrientation) >= 0
      ? GeometryConstant.Degree90InRadians
      : Math.PI + GeometryConstant.Degree90InRadians;
  }

  // #endregion
}
