import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ExpandCollapseButtonsService } from 'library/components/expand-collapse-buttons/service/expand-collapse-buttons.service';
import { Colors } from 'library/styles';
import { Subject, delay, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidepanel-list-layout',
  templateUrl: './sidepanel-list-layout.component.html',
  styleUrls: ['./sidepanel-list-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidepanelListLayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() pageHeading = '';
  @Input() sidepanelHeading = '';
  @Input() listHeading = '';
  @Input() isEditMode = false;
  @Input() openClosePanelFlag = true;
  @Input() showSidePanel = true;
  @Input() hasToolbar = true;
  @Input() explicitlyHideExpandCollapseButtons = false;
  @Input() isNewLayout = false;

  showCollapseAndExpandButtons = false;

  maintenanceModeColor = Colors.maintenanceModeColor;
  isSidePanelOpen = true;
  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly expandCollapseButtonsService: ExpandCollapseButtonsService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.expandCollapseButtonsService
      .getShowExpandCollapseAllButtons()
      .pipe(delay(0), takeUntil(this.ngUnsubscribe))
      .subscribe(show => {
        if (this.explicitlyHideExpandCollapseButtons) {
          this.showCollapseAndExpandButtons = false;
          return;
        }
        this.showCollapseAndExpandButtons = show;
        this.cdRef.markForCheck();
      });
  }

  ngOnChanges(): void {
    this.isSidePanelOpen = this.openClosePanelFlag;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openClosePanel(): void {
    this.isSidePanelOpen = !this.isSidePanelOpen;
  }
}
