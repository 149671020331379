<div class="user-options">
  <h3 class="list-header options">
    {{ 'userProfile.defineStartPage' | translate }}
  </h3>
  <hr class="lineSep thin" />
  <p class="marginSeparator options">
    {{ 'userProfile.startPage' | translate }}
  </p>
  <div class="grid">
    <div *ngFor="let card of startPageOptionCards">
      <ds-box class="cards" (click)="onCardClick(card)" [attr.data-cy]="card.route + '-card'">
        <ds-box-header>
          <img [src]="card.picture" />
        </ds-box-header>
        <ds-box-content [isClickable]="true">
          <ds-radio-group variant="inline">
            <label [for]="card.route">
              <input
                [attr.data-cy]="card.route + '-radioButton'"
                ds-input
                [id]="card.route"
                type="radio"
                [value]="card.route"
                [(ngModel)]="selectedStartPage" />
              <span>{{ 'userProfile.startPagePreference.' + card.name | translate }}</span>
            </label>
          </ds-radio-group>
        </ds-box-content>
      </ds-box>
    </div>
  </div>
</div>
