import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

type TrafficLightSize = 'smallTrafficLight' | 'mediumTrafficLight' | 'largeTrafficLight';
@Component({
  selector: 'app-traffic-light-cell',
  templateUrl: './traffic-light-cell.component.html',
  styleUrls: ['./traffic-light-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficLightCellComponent implements ICellRendererAngularComp {
  @Input() colorKey = '';
  @Input() tooltipTranslationKey = '';
  @Input() toolTipExtraText = '';

  displayName = '';
  trafficLightStatus = -1;
  size: TrafficLightSize = 'smallTrafficLight';

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  agInit(params: TrafficLightCellParams & ICellRendererParams): void {
    this.displayName = params.name;
    this.trafficLightStatus = params.trafficLightStatus;
    this.colorKey = params.colorKey;
    this.tooltipTranslationKey = `${params.tooltipTranslationKey}.${params.trafficLightStatus}`;
  }

  refresh(params: TrafficLightCellParams & ICellRendererParams): boolean {
    this.displayName = params.name;
    this.trafficLightStatus = params.trafficLightStatus;
    this.colorKey = params.colorKey;
    this.tooltipTranslationKey = `${params.tooltipTranslationKey}.${params.trafficLightStatus}`;
    this.cdRef.detectChanges();
    return true;
  }
}

export interface TrafficLightCellParams {
  name: string;
  trafficLightStatus: number;
  colorKey: string;
  tooltipTranslationKey: string;
}
