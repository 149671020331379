import { createSelector } from '@ngrx/store';

import * as fromRoot from '../reducers';
import * as fromUIReducer from '../reducers/ui.reducer';

export const selectIsEditToggleVisible = createSelector(
  fromRoot.getUIState,
  state => state.isEditToggleVisible
);

export const selectIsEditMode = createSelector(fromRoot.getUIState, fromUIReducer.getIsEditMode);

export const selectIsMassAbortMode = createSelector(
  fromRoot.getUIState,
  fromUIReducer.getIsMassAbortMode
);

export const selectIsMassAbortOrderMode = createSelector(
  fromRoot.getUIState,
  fromUIReducer.getIsMassAbortOrderMode
);

export const selectHasChanges = createSelector(fromRoot.getUIState, fromUIReducer.getHasChanges);

export const selectShowSearchBar = createSelector(
  fromRoot.getUIState,
  state => state.showSearchBar
);

export const selectIsMassUpdateMaintenanceMode = createSelector(
  fromRoot.getUIState,
  fromUIReducer.getIsMassUpdateMaintenanceMode
);

export const selectIsMassCommentMode = createSelector(
  fromRoot.getUIState,
  fromUIReducer.getIsMassCommentMode
);
