import { createSelector } from '@ngrx/store';
import { Poi } from 'core/models';
import { selectMapId, selectMapsEntities } from 'store-modules/maps-store/selectors';
import {
  selectAllPois,
  selectPoiEntities,
  selectPoiId,
} from 'store-modules/pois-store/selectors/pois.selectors';

export const selectPoisBySelectedMapId = createSelector(selectAllPois, selectMapId, (pois, mapId) =>
  mapId ? pois.filter(poi => poi.mapId === mapId) : pois
);

export const selectPoisWithMap = createSelector(
  selectAllPois,
  selectMapsEntities,
  (pois, mapsEntities): Poi[] =>
    pois.map(poi => ({
      ...poi,
      map: mapsEntities[poi.mapId.toString()],
    }))
);

export const selectSelectedPoi = createSelector(
  selectPoiId,
  selectPoiEntities,
  selectMapsEntities,
  (selectedPoiId, pois, maps): Poi | undefined => {
    const poi = pois[selectedPoiId.toString()];
    return poi && { ...poi, map: maps[poi.mapId.toString()] };
  }
);
