import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { ensureStoreLoaded } from 'shared/helpers';

import * as fromRoot from '../store';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsGuard implements CanActivate, CanActivateChild {
  constructor(private readonly store: Store<fromRoot.RootState>, private readonly router: Router) {}

  async canActivate(): Promise<boolean> {
    await ensureStoreLoaded(
      this.store,
      fromRoot.selectOrganizationsLoaded,
      fromRoot.loadOrganizations()
    );
    return true;
  }

  canActivateChild({ data = {} }: ActivatedRouteSnapshot): boolean | UrlTree {
    const roles = data.roles;

    if (roles) {
      return this.router.parseUrl('/not-authorized');
    }

    return true;
  }
}
