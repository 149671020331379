import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromPoiGroups from './poi-groups.reducer';
import * as fromPois from './pois.reducer';

export const featureKey = 'pois';

export interface PoisFeatureState {
  [fromPois.featureKey]: fromPois.PoisState;
  [fromPoiGroups.featureKey]: fromPoiGroups.PoiGroupsState;
}

export function reducers(state: PoisFeatureState | undefined, action: Action): PoisFeatureState {
  return combineReducers({
    [fromPois.featureKey]: fromPois.reducer,
    [fromPoiGroups.featureKey]: fromPoiGroups.reducer,
  })(state, action);
}

export const getPoisState = (state: PoisFeatureState): fromPois.PoisState => state.pois;
export const getPoiGroupsState = (state: PoisFeatureState): fromPoiGroups.PoiGroupsState =>
  state.poiGroups;
export const getPoisFeatureState = createFeatureSelector<PoisFeatureState>(featureKey);
