import { createAction, props } from '@ngrx/store';
import { AtsInternalTourDto } from 'core/dtos';

export enum TourTypes {
  LoadTours = '[Monitoring] Load Tours',
  LoadToursSuccess = '[Monitoring] Load Tours Success',
  LoadToursFailure = '[Monitoring] Load Tours Failure',
  SelectTour = '[Monitoring] SelectTour',
  RemoveTour = '[Monitoring] Remove Tour',
  SignalRAddTour = '[SignalR] Add Tour',
  SignalRUpdateTour = '[SignalR] Update Tour',
}

export const loadTours = createAction(TourTypes.LoadTours);

export const loadToursSuccess = createAction(
  TourTypes.LoadToursSuccess,
  props<{ tours: AtsInternalTourDto[] }>()
);

export const loadToursFailure = createAction(
  TourTypes.LoadToursFailure,
  props<{ errorMessage: string }>()
);

export const selectTour = createAction(TourTypes.SelectTour, props<{ tourId: string }>());

export const signalRAddTour = createAction(
  TourTypes.SignalRAddTour,
  props<{ tour: AtsInternalTourDto }>()
);

export const signalRUpdateTour = createAction(
  TourTypes.SignalRUpdateTour,
  props<{ tour: AtsInternalTourDto }>()
);

export const removeTour = createAction(TourTypes.RemoveTour, props<{ tour: AtsInternalTourDto }>());
