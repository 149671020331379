/* eslint-disable max-lines */
import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY_GUID } from 'core/constants';
import { AdxCollisionRecordDto, AdxZoneRecordDto, ZoneSetDto } from 'core/dtos';
import { isZoneVertex } from 'core/guards/type-guards';
import {
  GuidString,
  IMapItem,
  MapItem,
  MapItemSelectMode,
  MapItemType,
  MapMode,
  MapSelectionMode,
  Pose2D,
  SelectedMapItem,
  WorkingArea,
  Zone,
  isSelectedMapItem,
} from 'core/models';
import { EditBarService } from 'core/services';
import { SecondarySideMenuService } from 'core/services/secondary-sidemenu.service';
import { ModalDialogService } from 'library/components/modal-dialog';
import { Point } from 'pixi.js';
import { BehaviorSubject, Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { UpdatePoiPoseFromVehicle } from 'shared/components';

import { Store, select } from '@ngrx/store';
import * as fromMaps from 'store-modules/maps-store';
import * as fromRoot from 'store/index';
import { ZoneMapItemContainer } from '../layers';

@Injectable({
  providedIn: 'root',
})
export class MapCommunicationService implements OnDestroy {
  private readonly ngUnsubscribe = new Subject<void>();
  private showModalSubscription: Subscription | undefined;
  private _selectedMapItem: MapItem | undefined;
  private _selectionMode: MapSelectionMode = MapSelectionMode.Single;
  private isLoading = false;
  private _isEditMode = false;
  private _mapItemType: MapItemType | undefined = undefined;

  readonly isEditMode$: Observable<boolean>;

  private readonly selectionChange = new BehaviorSubject<SelectedMapItem<MapItem> | undefined>(
    undefined
  );
  readonly selectionChange$ = this.selectionChange.asObservable();

  private readonly itemUnselect = new Subject<MapItem>();
  readonly itemUnselect$ = this.itemUnselect.asObservable();

  private readonly locationChange = new Subject<Point>();
  readonly locationChange$ = this.locationChange.asObservable();

  private readonly setPoiAtVehiclePositionChange = new BehaviorSubject<
    UpdatePoiPoseFromVehicle | undefined
  >(undefined);
  readonly setPoiAtVehiclePositionChange$ = this.setPoiAtVehiclePositionChange.asObservable();

  private readonly createPoiAtVehiclePositionChange = new BehaviorSubject<Pose2D | undefined>(
    undefined
  );
  readonly createPoiAtVehiclePositionChange$ = this.createPoiAtVehiclePositionChange.asObservable();

  private readonly isTrafficAnalysis = new BehaviorSubject<boolean>(false);
  readonly isTrafficAnalysis$ = this.isTrafficAnalysis.asObservable();

  private readonly trafficAnalysisTimeChanged = new BehaviorSubject<boolean>(false);
  readonly trafficAnalysisTimeChanged$ = this.trafficAnalysisTimeChanged.asObservable();

  private readonly trafficConflictDetails = new BehaviorSubject<{
    conflictDetails: AdxCollisionRecordDto | undefined;
    selectedWorkArea: WorkingArea | undefined;
    time: string;
    isTmVehicleMessage: boolean;
  }>({
    conflictDetails: undefined,
    selectedWorkArea: undefined,
    time: '',
    isTmVehicleMessage: true,
  });
  readonly trafficConflictDetails$ = this.trafficConflictDetails.asObservable();

  private readonly zoneAccessDetails = new BehaviorSubject<{
    accessDetails: AdxZoneRecordDto | undefined;
    selectedWorkArea: WorkingArea | undefined;
    time: string;
    isTmVehicleMessage: boolean;
  }>({ accessDetails: undefined, selectedWorkArea: undefined, time: '', isTmVehicleMessage: true });
  readonly zoneAccessDetails$ = this.zoneAccessDetails.asObservable();

  private readonly selectedRuleZoneId = new Subject<GuidString>();
  readonly selectedRuleZoneId$ = this.selectedRuleZoneId.asObservable();

  private readonly selectedRuleZoneType = new Subject<number>();
  readonly selectedRuleZoneType$ = this.selectedRuleZoneType.asObservable();

  private readonly routeConfigTypes = [MapItemType.RouteConfig_Node, MapItemType.RouteConfig_Edge];

  get isEditMode(): boolean {
    return this._isEditMode;
  }

  get isSelectViewMode(): boolean {
    return this._selectionMode === MapSelectionMode.View;
  }

  get isSelectMultipleMode(): boolean {
    return this._selectionMode === MapSelectionMode.Multiple;
  }

  get selectedMapItem(): MapItem | undefined {
    return this._selectedMapItem;
  }

  get selectedItemType(): MapItemType | undefined {
    return this._mapItemType;
  }

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly mapsStore: Store<fromMaps.MapsFeatureState>,
    private readonly modalService: ModalDialogService,
    private readonly editBarService: EditBarService,
    private readonly secondarySideMenuService: SecondarySideMenuService
  ) {
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
  }

  subscribeToEvents(): void {
    this.unsubscribeFromEvents();

    this.mapsStore
      .pipe(select(fromMaps.isSelectViewMode), takeUntil(this.ngUnsubscribe))
      .subscribe((enabled: boolean) => {
        this.setSelectViewMode(enabled);
      });

    this.secondarySideMenuService.localizeVehicle$.subscribe((enabled: boolean) => {
      this.setSelectViewMode(enabled);
    });

    this.isEditMode$.subscribe(edit => (this._isEditMode = edit));
  }

  ngOnDestroy(): void {
    this.unsubscribeFromEvents();
  }

  unsubscribeFromEvents(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // #region Selection
  setSelectViewMode(allowSelectView: boolean): void {
    this.setSelectionMode(allowSelectView ? MapSelectionMode.View : MapSelectionMode.Single);
  }

  setSelectionMode(mode: MapSelectionMode): void {
    this._selectionMode = mode;
  }

  setSelectedItem(selected: SelectedMapItem<MapItem> | undefined): void {
    if (
      this._selectedMapItem !== undefined &&
      (!selected || (selected && selected.item.id !== this._selectedMapItem?.id))
    ) {
      this.itemUnselect.next(this._selectedMapItem);
    }

    this._mapItemType = selected?.item?.type;
    this._selectedMapItem = selected?.item;
    this.selectionChange.next(selected);
  }

  clearSelection(): void {
    this.setSelectedItem(undefined);
    this.closeSecondaryPanel();
  }

  requestClearSelection(): void {
    this.checkShowConfirmChange(undefined);
  }

  requestSelectionNewItem(type: MapItemType | undefined): void {
    const item = { id: EMPTY_GUID, type: type } as MapItem;
    const selected = this.getSelectedValue(item);

    if (selected) {
      this.checkShowConfirmChange({ ...selected, mode: MapItemSelectMode.New });
    }
  }

  requestSelectionChange(item: MapItem | IMapItem | undefined): void {
    if (item) {
      this.checkShowConfirmChange(item);
    } else {
      this.requestClearSelection();
    }
  }

  requestSelectMultiple(item: MapItem | undefined): void {
    if (item !== undefined && this.routeConfigTypes.includes(item?.type))
      this.setSelectedItem(this.getSelectedValue(item));
    else this.requestSelectionChange(item);
  }

  selectMapLocation(point: Point): void {
    this.locationChange.next(point);
  }

  private getSelectedValue(item: MapItem | undefined): SelectedMapItem<MapItem> | undefined {
    if (item === undefined) {
      return item;
    }

    return {
      item: item,
      isFocused: false,
    };
  }
  // #endregion

  // #region Changes
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  private checkShowConfirmChange(selected: SelectedMapItem<MapItem> | MapItem | undefined): void {
    const hasChanges = this.editBarService.hasChanges;
    const item = isSelectedMapItem(selected) ? selected.item : selected;

    const isVertexItem = item && isZoneVertex(item) && item.index >= 0;
    const isUnselected = hasChanges && item === undefined;
    const isOtherWithChanges = hasChanges && !isVertexItem && this.selectedMapItem?.id !== item?.id;
    const isOtherThanNew =
      hasChanges &&
      this.selectedMapItem?.id === EMPTY_GUID &&
      this.selectedMapItem?.id !== item?.id;

    if ((isUnselected || isOtherThanNew || isOtherWithChanges) && !this.isLoading) {
      this.showHasChangesDialog(this.getSelectedValue(item));
    } else {
      const selectedItem = isSelectedMapItem(selected) ? selected : this.getSelectedValue(item);
      this.setSelectedItem(selectedItem);
      this.mapsStore.dispatch(fromMaps.setMapMode({ mode: MapMode.None }));
      this.closeSecondaryPanel();
    }
  }

  private showHasChangesDialog(newSelected: SelectedMapItem<IMapItem> | undefined): void {
    if (!this.showModalSubscription) {
      this.showModalSubscription = this.modalService
        .createModal('maps.modalCancelChanges')
        .subscribe((accept: boolean) => {
          this.showModalSubscription?.unsubscribe();
          this.showModalSubscription = undefined;

          if (accept) {
            this.setSelectedItem(newSelected);
            this.closeSecondaryPanel();
            this.mapsStore.dispatch(fromMaps.setMapMode({ mode: MapMode.None }));
            this.editBarService.setHasChanges(false);
          }
        });
    }
  }

  private closeSecondaryPanel(): void {
    this.secondarySideMenuService.setDisplaySideBar(false);

    if (this.editBarService.hasChanges) {
      this.secondarySideMenuService.setCancelSideBarChange(true);
    }
  }

  // #endregion

  // #region Poi Position
  createPoiAtVehiclePosition(position: Pose2D | undefined): void {
    this.createPoiAtVehiclePositionChange.next(position);
  }

  clearPoiAtVehiclePosition(): void {
    this.createPoiAtVehiclePosition(undefined);
  }

  setPoiAtVehiclePosition(position: UpdatePoiPoseFromVehicle | undefined): void {
    this.setPoiAtVehiclePositionChange.next(position);
  }

  clearPoiAtPosition(): void {
    this.setPoiAtVehiclePosition(undefined);
  }
  // #endregion

  // #region Traffic
  setTrafficAnalysis(isTrafficAnalysis: boolean): void {
    this.isTrafficAnalysis.next(isTrafficAnalysis);
  }

  setTrafficAnalysisTimeChanged(timeChanged: boolean): void {
    this.trafficAnalysisTimeChanged.next(timeChanged);
  }

  setTrafficConflictDetails(
    conflictDetails: AdxCollisionRecordDto | undefined,
    selectedWorkArea: WorkingArea,
    time: string,
    isTmVehicleMessage: boolean
  ): void {
    this.trafficConflictDetails.next({
      conflictDetails,
      selectedWorkArea,
      time,
      isTmVehicleMessage,
    });
  }

  setZoneAccessDetails(
    accessDetails: AdxZoneRecordDto | undefined,
    selectedWorkArea: WorkingArea,
    time: string,
    isTmVehicleMessage: boolean
  ): void {
    this.zoneAccessDetails.next({ accessDetails, selectedWorkArea, time, isTmVehicleMessage });
  }
  // #endregion

  // #region Node & Interaction Zone Rules
  setRuleZoneId(id: GuidString): void {
    this.selectedRuleZoneId.next(id);
  }

  updateRuleZone(item: ZoneMapItemContainer): void {
    this.setRuleZoneId(item.masterZoneId);
    this.selectedRuleZoneType.next(item.zoneType);
  }
  // #endregion
}

export interface ZoneOptionsDialogData {
  zone: Zone;
  zoneSet: ZoneSetDto;
}
