/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { OrganizationsService } from 'core/api-services';
import { OrganizationDto } from 'core/dtos';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, filter, first, map, switchMap, tap } from 'rxjs/operators';
import * as fromRootActions from 'store/actions';
import * as fromRoot from 'store/reducers';

import * as fromOrganizationSelectors from '../selectors/organizations.selectors';

const path = '/welcome/plant/view';
@Injectable()
export class OrganizationsEffects {
  loadOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRootActions.loadOrganizations),
      concatMap(() =>
        this.organizationsService.getOrganizations().pipe(
          map(organizations => fromRootActions.loadOrganizationsSuccess({ organizations })),
          catchError(errorMessage => of(fromRootActions.loadOrganizationsFailure({ errorMessage })))
        )
      )
    )
  );

  addOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRootActions.addOrganization),
      concatMap(action =>
        this.organizationsService
          .createOrganization(action.organization)
          .pipe(
            concatMap(() =>
              this.organizationsService.getCreatedOrganization(action.organization.name)
            )
          )
          .pipe(
            map(organization => fromRootActions.addOrganizationSuccess({ organization })),
            catchError(errorMessage => of(fromRootActions.addOrganizationFailure({ errorMessage })))
          )
      )
    )
  );

  updateOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRootActions.updateOrganization),
      concatMap(action =>
        this.organizationsService
          .updateOrganization(action.orgName, action.organization)
          .pipe(
            concatMap(() =>
              this.organizationsService.getCreatedOrganization(action.organization.name)
            )
          )
          .pipe(
            map(organization =>
              fromRootActions.updateOrganizationSuccess({
                orgName: action.orgName,
                organization: organization,
              })
            ),
            catchError(errorMessage =>
              of(fromRootActions.updateOrganizationFailure({ errorMessage }))
            )
          )
      )
    )
  );

  deleteOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRootActions.deleteOrganization),
      map(action => action.organization),
      concatMap(organization =>
        this.organizationsService.deleteOrganization(organization).pipe(
          map(() => fromRootActions.deleteOrganizationSuccess({ organization: organization })),
          catchError(errorMessage =>
            of(fromRootActions.deleteOrganizationFailure({ errorMessage }))
          )
        )
      )
    )
  );

  addOrganizationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRootActions.addOrganizationSuccess),
      map(action => action.organization),
      tap(organization => {
        this.toastService.createSuccessToast('shared.actions.created', organization);
      }),
      map(organization => {
        return this.routeToOrganization(organization);
      })
    )
  );

  deleteOrganizationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRootActions.deleteOrganizationSuccess),
      tap(action => {
        this.toastService.createSuccessToast('shared.actions.deleted', action.organization);
      }),
      switchMap(() => {
        return this.rootStore.pipe(
          select(fromOrganizationSelectors.selectAllOrganizations),
          filter(organizations => !!(organizations && organizations.length)),
          first(),
          map(organizations => {
            const defaultOrganizationWithWA = organizations.find(org => org.workAreas.length > 0);
            const firstOrganizationId = defaultOrganizationWithWA
              ? defaultOrganizationWithWA.id
              : '';
            const firstWAId = defaultOrganizationWithWA
              ? defaultOrganizationWithWA.workAreas[0].id
              : '';
            fromRootActions.selectWorkingArea({ workingAreaId: firstWAId });
            return fromRootActions.selectOrganization({ organizationId: firstOrganizationId });
          })
        );
      })
    )
  );

  updateOrganizationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRootActions.updateOrganizationSuccess),
      map(({ organization }) => organization),
      tap(organization => {
        this.toastService.createSuccessToast('shared.actions.saved', organization);
      }),
      map(organization => {
        return this.routeToOrganization(organization);
      })
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromRootActions.loadOrganizationsFailure,
          fromRootActions.addOrganizationFailure,
          fromRootActions.updateOrganizationFailure,
          fromRootActions.deleteOrganizationFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly organizationsService: OrganizationsService,
    private readonly toastService: ToastService,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {}

  private routeToOrganization(organization: OrganizationDto): Action {
    return fromRootActions.Go({
      payload: {
        path: [path, organization.name],
      },
    });
  }
}
