<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <div class="title">
      <ng-container
        [ngTemplateOutlet]="header ? header : defaultHeader"
        [ngTemplateOutletContext]="{ translationKey: translationKey }">
      </ng-container>
      <ng-content select="[title]"></ng-content>
    </div>
    <ng-content select="[main]"></ng-content>
  </ds-box-content>
</ds-box>

<ng-template #defaultHeader
  ><h5>{{ translationKey + '.title' | translate }}</h5></ng-template
>
