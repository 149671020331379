import { Pipe, PipeTransform } from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { SortOrder } from 'library/models';

@Pipe({
  name: 'dsSortGroupedDropdown',
})
export class DsSortGroupedDropdownPipe implements PipeTransform {
  transform(
    options: SelectListOption[] | undefined,
    sortBy = 'name',
    sortOrder: SortOrder = SortOrder.Asc
  ): SelectListOption[] | undefined {
    if (options) {
      for (const groupedItem of options) {
        groupedItem.children?.sort(
          (a, b) =>
            sortOrder * String(a[sortBy]).localeCompare(b[sortBy], undefined, { numeric: true })
        );
      }
      return options;
    } else {
      return undefined;
    }
  }
}
