import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { EmulatorConfigurationDto, EmulatorHostFeaturesDto, VehicleSpecification } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { GuidString, HardwareVersion, VehicleMode, VehicleType } from 'core/models';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmulatorService extends BaseHttpClient {
  private readonly servicePath = '/api';
  protected apiUrl = environment.Services.EmulatorManager;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getEmulatorManagerFeatures(workAreaId: GuidString): Observable<EmulatorHostFeaturesDto> {
    return this.get<EmulatorHostFeaturesDto>(`${this.servicePath}/features/${workAreaId}`);
  }

  createEmulator(newVehicle: VehicleSpecification): Observable<EmulatorConfigurationDto> {
    const emulatorConfiguration: EmulatorConfigurationDto = {
      vehicles: [newVehicle],
    };
    return this.post<EmulatorConfigurationDto>(
      `${this.servicePath}/Configurations`,
      emulatorConfiguration
    );
  }

  updateEmulator(vehicle: VehicleSpecification): Observable<EmulatorConfigurationDto> {
    const emulatorConfiguration: EmulatorConfigurationDto = {
      id: vehicle.emulatorConfigurationId,
      vehicles: [vehicle],
    };
    return this.patch<EmulatorConfigurationDto>(
      `${this.servicePath}/Configurations`,
      emulatorConfiguration
    );
  }

  async getEmulatorById(vehicleId?: GuidString): Promise<EmulatorConfigurationDto> {
    return firstValueFrom(
      this.get<EmulatorConfigurationDto>(`${this.servicePath}/Configurations/Vehicles/${vehicleId}`)
    );
  }

  resetEmulator(vehicleId?: GuidString): Observable<boolean> {
    return this.get<boolean>(`${this.servicePath}/Configurations/ResetVehicle/${vehicleId}`);
  }

  deleteEmulator(vehicleId: GuidString): Observable<void> {
    return this.delete<void>(`${this.servicePath}/Configurations/Vehicles/${vehicleId}`);
  }

  activateEmulators(workingAreaId: GuidString): Observable<EmulatorConfigurationDto> {
    return this.post<EmulatorConfigurationDto>(
      `${this.servicePath}/Configurations/Activate/${workingAreaId}`
    );
  }

  deactivateEmulators(workingAreaId: GuidString): Observable<void> {
    return this.delete<void>(`${this.servicePath}/Configurations/Deactivate/${workingAreaId}`);
  }

  setEmulatorMode(vehicleId: GuidString, mode: VehicleMode): Observable<void> {
    return this.post<void>(`${this.servicePath}/Configurations/VehicleMode/${vehicleId}/${mode}`);
  }

  toggleEmulatorPath(vehicleId: GuidString, isRealPathActive: boolean): Observable<void> {
    return this.post<void>(
      `${this.servicePath}/Configurations/PathPlanner/${vehicleId}/${isRealPathActive}`
    );
  }
  triggerEmulatorError(
    vehicleId: GuidString,
    vehicleType: VehicleType,
    hardwareVersion: HardwareVersion
  ): Observable<void> {
    return this.post<void>(
      `${this.servicePath}/Configurations/SetError/${vehicleId}/${vehicleType}/${hardwareVersion}`
    );
  }
  triggerClearEmulatorError(vehicleId: GuidString): Observable<void> {
    return this.post<void>(`${this.servicePath}/Configurations/ClearError/${vehicleId}`);
  }

  async triggerFailOnNextNode(vehicleId: GuidString): Promise<void> {
    return firstValueFrom(
      this.post<void>(`${this.servicePath}/Configurations/FlagForErrorOnAction/${vehicleId}`)
    );
  }

  async pushVehicleSideButton(vehicleId: GuidString): Promise<void> {
    return firstValueFrom(
      this.post<void>(`${this.servicePath}/Configurations/PushButton/${vehicleId}`)
    );
  }

  async shutdownVehicle(vehicleId: GuidString): Promise<void> {
    return firstValueFrom(
      this.post<void>(`${this.servicePath}/Configurations/ShutdownVehicle/${vehicleId}`)
    );
  }

  async restartVehicle(vehicleId: GuidString): Promise<void> {
    return firstValueFrom(
      this.post<void>(`${this.servicePath}/Configurations/RestartVehicle/${vehicleId}`)
    );
  }

  async forceShutdownVehicle(vehicleId: GuidString): Promise<void> {
    return firstValueFrom(
      this.post<void>(`${this.servicePath}/Configurations/ForceShutdownVehicle/${vehicleId}`)
    );
  }

  updateEmulatorSettings(
    emulatorSettings: EmulatorHostFeaturesDto,
    workAreaId: GuidString
  ): Observable<EmulatorHostFeaturesDto> {
    return this.put<EmulatorHostFeaturesDto>(
      `${this.servicePath}/features/${workAreaId}`,
      emulatorSettings
    );
  }
}
