import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PoiGroupDto } from 'core/dtos';
import { GuidString, PoiGroupStrategy } from 'core/models';

import * as PoiGroupsActions from '../actions/poi-groups.actions';

export const featureKey = 'poiGroups';

export interface PoiGroupsState extends EntityState<PoiGroupDto> {
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
  selectedPoiGroupId: GuidString;
}

export const poiGroupsAdapter: EntityAdapter<PoiGroupDto> = createEntityAdapter<PoiGroupDto>();

export const initialState: PoiGroupsState = poiGroupsAdapter.getInitialState({
  loaded: false,
  loading: false,
  errorMessage: '',
  selectedPoiGroupId: '',
});

const poiGroupsReducer = createReducer(
  initialState,

  on(PoiGroupsActions.loadPoiGroups, state => ({
    ...state,
    loading: true,
    loaded: false,
    errorMessage: '',
  })),

  on(PoiGroupsActions.loadPoiGroupsSuccess, (state, { poiGroups }) =>
    poiGroupsAdapter.setAll(
      poiGroups.filter(poiGroup => poiGroup && poiGroup.strategy !== PoiGroupStrategy.Single),
      {
        ...state,
        loaded: true,
        loading: false,
      }
    )
  ),

  on(PoiGroupsActions.loadPoiGroupsFailure, (state, { errorMessage }) => ({
    ...state,
    loaded: false,
    loading: false,
    errorMessage,
  })),

  on(
    PoiGroupsActions.addPoiGroupSuccess,
    PoiGroupsActions.signalRCreatePoiGroup,
    (state, { poiGroup }) => poiGroupsAdapter.addOne(poiGroup, state)
  ),

  on(
    PoiGroupsActions.updatePoiGroupSuccess,
    PoiGroupsActions.signalRUpdatePoiGroup,
    (state, { poiGroup }) =>
      poiGroupsAdapter.updateOne({ id: poiGroup.id.toString(), changes: poiGroup }, state)
  ),

  on(
    PoiGroupsActions.deletePoiGroupSuccess,
    PoiGroupsActions.ungroupPoiGroupSuccess,
    PoiGroupsActions.signalRDeletePoiGroup,
    (state, { poiGroup }) => poiGroupsAdapter.removeOne(poiGroup.id.toString(), state)
  ),

  on(
    PoiGroupsActions.addPoiGroup,
    PoiGroupsActions.updatePoiGroup,
    PoiGroupsActions.deletePoiGroup,
    PoiGroupsActions.ungroupPoiGroup,
    state => ({
      ...state,
      errorMessage: '',
    })
  ),

  on(
    PoiGroupsActions.addPoiGroupFailure,
    PoiGroupsActions.updatePoiGroupFailure,
    PoiGroupsActions.deletePoiGroupFailure,
    PoiGroupsActions.ungroupPoiGroupFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
    })
  ),

  on(PoiGroupsActions.selectPoiGroup, (state, { poiGroupId }) => ({
    ...state,
    selectedPoiGroupId: poiGroupId,
  }))
);

export function reducer(state: PoiGroupsState | undefined, action: Action): PoiGroupsState {
  return poiGroupsReducer(state, action);
}

export const { selectEntities, selectAll } = poiGroupsAdapter.getSelectors();

export const getPoiGroupsLoading = (state: PoiGroupsState): boolean => state.loading;
export const getPoiGroupsLoaded = (state: PoiGroupsState): boolean => state.loaded;
export const getPoiGroupsErrorMessage = (state: PoiGroupsState): string => state.errorMessage;
export const getSelectedPoiGroupId = (state: PoiGroupsState): GuidString =>
  state.selectedPoiGroupId;
export const getPoiGroupsEntities = selectEntities;
export const getAllPoiGroups = selectAll;
