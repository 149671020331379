import { createAction, props } from '@ngrx/store';
import { RoleDto } from 'core/dtos';
import { Role } from 'core/models';

export enum RolesTypes {
  SaveRoles = '[Settings] Save Roles',
  SaveRolesSuccess = '[Settings] Save Roles Success',
  SaveRolesFailure = '[Settings] Save Roles Failure',
  LoadRolesAndPermissions = '[Settings] Load Roles and Permissions',
  LoadRolesAndPermissionSuccess = '[Settings] Load Roles and Permissions Success',
  LoadRolesAndPermissionFailure = '[Settings] Load Roles and Permissions Failure',
}

export const saveRoles = createAction(RolesTypes.SaveRoles, props<{ roles: Role[] }>());

export const saveRolesSuccess = createAction(
  RolesTypes.SaveRolesSuccess,
  props<{ roles: Role[] }>()
);

export const saveRolesFailure = createAction(
  RolesTypes.SaveRolesFailure,
  props<{ errorMessage: string }>()
);

export const loadRolesAndPermissions = createAction(RolesTypes.LoadRolesAndPermissions);

export const loadRolesAndPermissionsSuccess = createAction(
  RolesTypes.LoadRolesAndPermissionSuccess,
  props<{ roles: RoleDto[] }>()
);

export const loadRolesAndPermissionsFailure = createAction(
  RolesTypes.LoadRolesAndPermissionFailure,
  props<{ errorMessage: string }>()
);
