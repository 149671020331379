import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { FailureCategoryDto } from 'core/dtos';

@Component({
  selector: 'app-switch-setting-cell',
  templateUrl: './switch-setting-cell.component.html',
  styleUrls: ['./switch-setting-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchSettingCellComponent implements ICellRendererAngularComp {
  params?: ICellRendererParams<FailureCategoryDto>;
  failureSetting?: FailureCategoryDto;
  canEdit?: boolean;
  editEnabled?: boolean = false;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  agInit(params: ICellRendererParams<FailureCategoryDto>): void {
    this.params = params;
    this.canEdit = this.params?.context?.componentParent?.canEdit;

    this.refresh(params);
  }

  refresh(params: ICellRendererParams<FailureCategoryDto>): boolean {
    this.failureSetting = Object.assign({}, params.data);
    this.editEnabled = this.canEdit;
    this.cdRef.markForCheck();
    return true;
  }

  onToggle(): void {
    this.failureSetting &&
      this.params?.context.componentParent.onChangeFailureSettingStatus(this.failureSetting);
  }
}
