<div style="display: flex">
  <button
    ds-button
    icon="arrow_down_small"
    size="lg"
    class="me-1x"
    data-cy="expandAll"
    ds-tooltip="{{ 'shared.treeView.expandAll' | translate }}"
    [dsTooltipConfig]="{
      origin: 'top'
    }"
    [variant]="'ghost'"
    [disabled]="disableExpand$ | async"
    (click)="expandAllClicked()"></button>
  <button
    ds-button
    icon="arrow_up_small"
    size="lg"
    data-cy="collapseAll"
    ds-tooltip="{{ 'shared.treeView.collapseAll' | translate }}"
    [dsTooltipConfig]="{
      origin: 'top'
    }"
    [variant]="'ghost'"
    [disabled]="disableCollapse$ | async"
    (click)="collapseAllClicked()"></button>
</div>
