import { ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { DATE_FORMAT, NO_VALUE_DEFAULT } from 'core/constants';
import { DateString, MissionStatus } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { formatDistance } from 'date-fns';
import { de, enGB } from 'date-fns/esm/locale';
import { DateFormatEnum } from 'library/helpers/standard-date-formatter.helper';
import { StandardDateTimePipe } from 'library/pipes/standard-date-time.pipe';
import { StandardTimeFramePipe } from 'library/pipes/standard-time-frame.pipe';
import { capitalize } from 'lodash';
import { formatDateString } from 'shared/helpers';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class GridValueFormatters {
  static dateTimeFormatter(params: ValueFormatterParams<any, DateString>): string {
    return formatDateString(params.value, DATE_FORMAT.FULL_DATE_TIME_SECONDS);
  }

  static dateTimeFormatterExclSeconds(params: ValueFormatterParams<any, DateString>): string {
    return formatDateString(params.value, DATE_FORMAT.FULL_DATE_TIME);
  }

  static relativeDateTimeFormatterFactory(translateService: AtsTranslationService) {
    return (params: ValueFormatterParams<any, DateString>): string => {
      if (!params.value) return '-';

      const locale = {
        de: de,
        en: enGB,
      };

      return formatDistance(new Date(params.value), new Date(), {
        addSuffix: true,
        locale: locale[translateService.currentLang],
      });
    };
  }

  static emptyStringFormatter() {
    return (params: ValueFormatterParams): string => {
      if (params.value === null || params.value.toString() === '') return NO_VALUE_DEFAULT;
      else return params.value;
    };
  }

  static booleanFormatter(
    translationService: AtsTranslationService,
    enumName?: string
  ): ValueFormatterFunc<string> {
    return (params: ValueFormatterParams): string => {
      if (params.colDef?.field && translationService) {
        const value = params.data[params.colDef.field].toString();
        return translationService.translateEnum(
          value,
          enumName ? enumName : params.column.getColId()
        );
      }

      return NO_VALUE_DEFAULT;
    };
  }

  static relativeDateTimeWithDetailsFormatterFactory(translateService: AtsTranslationService) {
    return (params: ValueFormatterParams<any, DateString>): string => {
      if (!params.value) return '-';

      const locale = {
        de: de,
        en: enGB,
      };

      const distance = formatDistance(new Date(params.value), new Date(), {
        addSuffix: true,
        locale: locale[translateService.currentLang],
      });

      const dateTime = formatDateString(params.value, DATE_FORMAT.FULL_DATE_TIME_SECONDS);
      return `${distance} (${dateTime})`;
    };
  }

  static capitalizeFormatter(params: ValueFormatterParams<any, string>): string {
    return capitalize(params.value);
  }

  static getEnumValueFormatterFn(
    translationService: AtsTranslationService,
    enumName?: string
  ): ValueFormatterFunc<string> {
    return (params: ValueFormatterParams): string => {
      if (params.colDef?.field && params.data && translationService) {
        const value = params.data[params.colDef.field];
        return translationService.translateEnum(
          value,
          enumName ? enumName : params.column.getColId()
        );
      }

      return NO_VALUE_DEFAULT;
    };
  }

  static standardDateTimeFormatterFn(
    translationService: AtsTranslationService,
    formatType: DateFormatEnum
  ): ValueFormatterFunc<string> {
    return (params: ValueFormatterParams): string => {
      if (params.colDef?.field && params.data && translationService) {
        const valueToTransform = params.value?.toString() ?? '';
        return (
          new StandardDateTimePipe(translationService).transform(valueToTransform, formatType) ?? ''
        );
      }

      return NO_VALUE_DEFAULT;
    };
  }

  static standardDateTimeWithTimeFrameFormatterFn(
    translateService: AtsTranslationService,
    formatType: DateFormatEnum
  ): ValueFormatterFunc<string> {
    return (params: ValueFormatterParams): string => {
      if (params.colDef?.field && params.data && translateService) {
        const valueToTransform = params.value?.toString() ?? '';

        const dateTimeFrame =
          new StandardTimeFramePipe(translateService).transform(valueToTransform) ?? '';

        const dateTime =
          new StandardDateTimePipe(translateService).transform(valueToTransform, formatType) ?? '';

        return `${dateTimeFrame} (${dateTime})`;
      }

      return NO_VALUE_DEFAULT;
    };
  }

  // Accept date with 'dd.mm.yy hh:mm:ss'
  static convertDateStringToDate(dateAsString: string): Date {
    let dateParts = dateAsString.split('.');
    // Set date
    const day = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    dateParts = dateParts[2].split(' ');
    const year = Number(dateParts[0]);

    // Set time
    dateParts = dateParts[1].split(':');
    const hour = Number(dateParts[0]);
    const minute = Number(dateParts[1]);
    const second = dateParts.length > 2 ? Number(dateParts[2]) : undefined;

    if (second !== undefined) {
      return new Date(year, month, day, hour, minute, second);
    } else {
      return new Date(year, month, day, hour, minute);
    }
  }

  static missionStatus(
    translationService: AtsTranslationService,
    params: ValueFormatterParams<{ hasManualSupport: boolean }, MissionStatus>
  ): string {
    let st = params.value;
    if (params.data?.hasManualSupport === true) {
      if (st === MissionStatus.Completed) st = MissionStatus.CompletedWithSupport;
      if (st === MissionStatus.Aborted) st = MissionStatus.AbortedWithSupport;
    }
    return translationService.get(`enums.MissionStatus.${st}`);
  }
}
