import { ZoneType } from 'core/dtos';
import { OccupancyStatus, PoiType, VehicleMode, VehicleStatus } from 'core/models';
import { VehicleLoadStatus, VehicleMaintenanceStatus, VehicleType } from '../vehicles/vehicle.enum';

export enum FiltersKeys {
  Filters = 'filters',
  Vehicles = 'vehicles',
  TrafficManagement = 'trafficManagement',
  Pois = 'pois',
  Zones = 'zones',
  Maps = 'maps',
}

export enum ManualVehiclesFilter {
  Name = 1,
}

export interface Filters {
  vehicles?: VehicleFilter;
  trafficManagement?: TrafficManagementFilter;
  pois?: PoisFilter;
  zones?: ZoneFilter;
  maps?: MapsFilter;
}

export interface VehicleFilterType {
  name: boolean;
  path: boolean;
  pathSegments: boolean;
}

export interface VehicleTypeItem extends CheckboxModel {
  key: string;
  control: string;
  children: VehicleTypeItemChild[];
}

export interface VehicleTypeItemChild {
  property: string;
  translationKey: string;
  selected: boolean;
}

export interface VehicleTypeFilter {
  key: boolean;
  filter: VehicleFilterType[];
}
export interface VehicleFilter {
  type: { [key in VehicleType]: VehicleFilterType };
  mode: CheckboxModel[];
  status: CheckboxModel[];
  load: CheckboxModel[];
  maintenance: CheckboxModel[];
  manual: CheckboxModel[];
  search: string;
}

export interface PoisFilter {
  name: boolean;
  entryExitVector: boolean;
  occupancy: CheckboxModel[];
  type: CheckboxModel[];
}

export interface ZoneFilter {
  type: CheckboxModel[];
}

export interface MapsFilter {
  backgroundMap: boolean;
  baseMap: boolean;
  pillarsGrid: boolean;
  graphMap: boolean;
  pathPlannerMap: boolean;
}

export interface TrafficManagementFilter {
  collisionPoint: boolean;
  intersectionCollisionPoint: boolean;
  vehicleStoppedByTM: boolean;
  stoppingArea: boolean;
  collisionArea: boolean;
  deadlockArea: boolean;
  dependencies: boolean;
}

export type FilterTypes =
  | VehicleType
  | ManualVehiclesFilter
  | VehicleMode
  | VehicleStatus
  | VehicleMaintenanceStatus
  | VehicleLoadStatus
  | PoiType
  | OccupancyStatus
  | ZoneType;

export interface CheckboxModel {
  id: FilterTypes;
  selected: boolean;
}

export function isCheckboxModel(item: CheckboxModel | object): item is VehicleFilterType {
  return 'id' in item && 'selected' in item;
}

export function isCheckboxModelArray(items: CheckboxModel[] | object[]): items is CheckboxModel[] {
  return Array.isArray(items) && items.some(i => isCheckboxModel(i));
}
