import { createAction, props } from '@ngrx/store';
import { MissionListSignalr } from 'core/dtos';
import { GuidString } from 'core/models';

export enum MissionListItemTypes {
  SignalRUpdateMissionList = '[SignalR] Update Active Missions List',
  SignalRCreateMissionList = '[SignalR] Create Active Missions List',
  SignalRRemoveCompletedMissions = '[SignalR] Removing Completed Missions',
  SelectMissionTrace = '[Monitoring] Select Mission Trace',
  LoadActiveTourListItems = '[Monitoring] Load Active Tour List Items',
  LoadActiveTourListItemsSuccess = '[Monitoring] Load Active Tour List Items Success',
}

export const signalRUpdateMissionList = createAction(
  MissionListItemTypes.SignalRUpdateMissionList,
  props<{ message: MissionListSignalr[] }>()
);

export const signalRCreateMissionList = createAction(
  MissionListItemTypes.SignalRCreateMissionList,
  props<{ message: MissionListSignalr[] }>()
);

export const signalRRemoveCompletedMissions = createAction(
  MissionListItemTypes.SignalRRemoveCompletedMissions
);

export const loadActiveTourListItems = createAction(MissionListItemTypes.LoadActiveTourListItems);

export const loadActiveTourListItemsSuccess = createAction(
  MissionListItemTypes.LoadActiveTourListItemsSuccess,
  props<{ items: MissionListSignalr[] }>()
);

export const selectMissionListItem = createAction(
  MissionListItemTypes.SelectMissionTrace,
  props<{ missionTraceId: GuidString }>()
);
