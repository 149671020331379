/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ExpandCollapseButtonsService } from 'components/expand-collapse-buttons/service/expand-collapse-buttons.service';
import { ATSMenuItem, BasicMenuItem, GuidString } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { FAKE_PARENT_ID, FAKE_PARENT_ID2, isFakeNode } from 'library/helpers';
import {
  CombinedTreeTableColumns,
  EnumTableColumns,
  TableColumns,
  TableContentMessage,
} from 'library/models';
import { differenceWith, isEmpty, isEqual } from 'lodash';
import { TreeNode } from 'primeng/api';
import {
  TreeTable,
  TreeTableFilterEvent,
  TreeTableNodeExpandEvent,
  TreeTablePaginatorState,
  TreeTableSortEvent,
} from 'primeng/treetable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuComponent } from '../menu/menu.component';

import { TableTemplateContext } from 'library/models/template-context.models';
import {
  ActionMenuConfiguration,
  RowNode,
  TableInputTypes,
  TreeTableSortOptions,
} from './tree-table.model';

@Component({
  selector: 'app-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeTableComponent implements OnChanges, OnInit, OnDestroy {
  @Input() header = '';
  @Input() records: TableInputTypes[] = [];
  @Input() columns: TableColumns[] = [];
  @Input() menuItems: ATSMenuItem[] = [];
  @Input() sortField = 'name';
  @Input() sortOrder = 1;
  @Input() translationContext = '';
  @Input() searchTerm = '';
  @Input() selectedRowId?: GuidString;
  @Input() isLoading = false;
  @Input() hasPaginator = false;
  @Input() actionMenu: ActionMenuConfiguration = 'all';
  @Input() showExpandCollapseButtons = true;
  @Input() totalNumberOfRecordsPaginator = 0;
  @Input() lazyLoad = false;
  @Input() itemTemplate: TemplateRef<TableTemplateContext> | undefined;
  @Input() isInitialExpandAll = false;
  @Input() showTreeTableToggler = true;
  @Input() showSortIcons = true;
  @Input() reorderableColumns = false;
  @Input() isTableConfigurationModeActive = false;
  @Input() tooltipDisabled = true;
  @Input() noContentMessage: TableContentMessage | undefined;

  @Output() readonly nodeClick = new EventEmitter<TreeNode>();
  @Output() readonly nodeNavigate = new EventEmitter<TreeNode>();
  @Output() readonly changePage = new EventEmitter<TreeTablePaginatorState>();
  @Output() readonly saveTableSort = new EventEmitter<TreeTableSortOptions>();
  @Output() readonly colReordered = new EventEmitter<boolean>();

  @ViewChild(TreeTable) treeTable!: TreeTable;
  @ViewChild('menu') menu!: MenuComponent;
  @ViewChild('defaultItemTemplate', { static: false })
  defaultItemTemplateRef!: TemplateRef<TableTemplateContext>;

  viewModel: TreeNode[] = [];
  selectedNode?: TreeNode;
  // Put any because the type in primeng is any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedNodes: any;
  ngUnsubscribe = new Subject<void>();
  enumsMap = {};
  combinedMap: Array<CombinedTreeTableColumns> = [];
  readonly screenHeight = 200;
  datasource: TreeNode[] = [];
  previousValue: TreeNode[] = [];

  nodeEvent: 'NodeSelect' | 'NodeExpandCollapse' | 'NodeAction' = 'NodeSelect';
  FAKE_PARENT_ID = FAKE_PARENT_ID;
  FAKE_PARENT_ID2 = FAKE_PARENT_ID2;

  get showActionMenuColumn(): boolean {
    return this.menuItems && !isEmpty(this.menuItems);
  }

  get showActionMenu(): boolean {
    return (
      this.actionMenu &&
      this.actionMenu !== 'none' &&
      this.showActionMenuColumn &&
      this.menuItems.some(i => i.visible)
    );
  }

  get columnItemTemplate(): TemplateRef<TableTemplateContext> {
    return this.itemTemplate ? this.itemTemplate : this.defaultItemTemplateRef;
  }

  get noContentHeadingKey(): string {
    if (this.noContentMessage) {
      return this.noContentMessage.headingKey;
    }
    return this.searchTerm
      ? 'shared.treeTable.noContentWithoutSearchResultsMessage1'
      : 'shared.treeTable.noContentMessage1';
  }

  get noContentMessageKey(): string {
    if (this.noContentMessage) {
      return this.noContentMessage.messageKey;
    }
    return this.searchTerm
      ? 'shared.treeTable.noContentWithoutSearchResultsMessage2'
      : 'shared.treeTable.noContentMessage2';
  }

  constructor(
    protected readonly expandCollapseButtonsService: ExpandCollapseButtonsService,
    protected readonly translate: AtsTranslationService,
    protected readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.buildViewModel(this.records);
      this.updateMenuTranslation();
    });
    this.expandCollapseButtonsService.setShowExpandCollapseAllButtons(
      this.showExpandCollapseButtons
    );
    this.expandCollapseButtonsService
      .getClickExpandAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.expandCollapseAll(true);
        this.enableCollapseButton();
        this.disableExpandButton();
        this.cdRef.markForCheck();
      });
    this.expandCollapseButtonsService
      .getClickCollapseAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.expandCollapseAll(false);
        this.enableExpandButton();
        this.disableCollapseButton();
        this.cdRef.markForCheck();
      });
  }

  ngOnChanges({
    columns,
    records,
    searchTerm,
    selectedRowId,
  }: TypedChanges<TreeTableComponent>): void {
    if (columns?.currentValue) {
      this.mapEnumsColumns();
      this.mapCombinedColumns();
    }

    if (
      records?.currentValue &&
      (differenceWith(records.currentValue, records.previousValue, isEqual) ||
        records.currentValue.length !== records.previousValue.length)
    ) {
      this.buildViewModel(records.currentValue);
    }

    if (selectedRowId?.currentValue) {
      this.updateSelectedNode();
    }

    if (searchTerm && this.treeTable) {
      this.treeTable.filterGlobal(searchTerm.currentValue, 'contains');
    }
  }

  ngOnDestroy(): void {
    this.expandCollapseButtonsService.setShowExpandCollapseAllButtons(false);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onActionMenuClick($event: Event, node: TreeNode): void {
    this.nodeEvent = 'NodeAction';
    this.setSelectedNode(node);

    if (this.showActionMenu) {
      this.menu.toggle($event);
    }
  }

  onNodeSelect(node: TreeNode): void {
    if (this.nodeEvent !== 'NodeSelect') {
      this.nodeEvent = 'NodeSelect';
      return;
    }
    if (isFakeNode(node) || isFakeNode(node, FAKE_PARENT_ID2)) {
      this.setSelectedNode(node);
      return;
    }

    this.setSelectedNode(node);
    this.nodeNavigate.emit(node);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onNodeExpand(event: TreeTableNodeExpandEvent<any>): void {
    this.nodeEvent = 'NodeExpandCollapse';
    this.setSelectedNode(event.node);
  }

  setSelectedNode(node: TreeNode): void {
    this.verifyEnableDisableExpandCollapseButtons();

    if (node !== this.selectedNode) {
      this.nodeClick.emit(node);
    }
    this.highlightNode(node);
  }

  updateSelectedNode(): void {
    const selectedNode =
      this.selectedRowId && this.findNodeRecursive(this.selectedRowId.toString(), this.viewModel);

    if (selectedNode) {
      this.expandSelectedNode(selectedNode);
    }
    this.setDefaultNodeExpand();
  }

  setDefaultNodeExpand(): void {
    const hasExpandedNode = this.viewModel.find(v => v.expanded)?.expanded;

    if (!hasExpandedNode) {
      this.expandSelectedNode(this.viewModel[0]);
    }
  }

  findNodeRecursive(id: string, nodes: TreeNode[]): TreeNode | undefined {
    const foundNode = nodes.find(node => node.data.id === id);
    if (foundNode) {
      return foundNode;
    }
    for (const node of nodes) {
      if (node.children) {
        const found = this.findNodeRecursive(id, node.children);
        if (!found) {
          continue;
        } else {
          return found;
        }
      }
    }
    return undefined;
  }

  hideColumn(event: Event, column: TableColumns): void {
    column.isHidden = !column.isHidden;
    this.colReordered.emit(true);
    event.stopPropagation();
  }

  verifyNodesOpen(): boolean {
    return this.viewModel.some(node => node.expanded === true);
  }

  disableCollapseButton(): void {
    this.expandCollapseButtonsService.setDisableCollapseAllButton(true);
  }

  disableExpandButton(): void {
    this.expandCollapseButtonsService.setDisableExpandAllButton(true);
  }

  enableCollapseButton(): void {
    this.expandCollapseButtonsService.setDisableCollapseAllButton(false);
  }

  enableExpandButton(): void {
    this.expandCollapseButtonsService.setDisableExpandAllButton(false);
  }

  onNodeCollapse(): void {
    this.nodeEvent = 'NodeExpandCollapse';
    this.verifyEnableDisableExpandCollapseButtons();
  }

  verifyEnableDisableExpandCollapseButtons(): void {
    if (this.verifyNodesOpen()) {
      this.enableCollapseButton();
    } else {
      this.disableCollapseButton();
    }
  }

  highlightNode(node: TreeNode): void {
    this.selectedNode = node;
    this.selectedNodes = [node];

    if (node.parent) {
      this.selectedNodes.push(node.parent);
    }
  }

  buildViewModel(currentValue: TableInputTypes[]): void {
    const currentValueMapped = currentValue.map(record => this.createTreeNode(record));

    this.getExpandPropertyForParentNodes(currentValueMapped);
    this.viewModel = [...currentValueMapped];
  }

  getExpandPropertyForParentNodes(nodes: TreeNode[]): void {
    nodes
      .filter(row => !row.leaf)
      .forEach(row => {
        const alreadyExpanded = this.viewModel.find(x => x.data.id === row.data.id)?.expanded;
        row.expanded = alreadyExpanded !== undefined ? alreadyExpanded : true;
      });

    this.verifyEnableDisableExpandCollapseButtons();
  }

  createTreeNode(record: TableInputTypes): TreeNode {
    const children = this.createNodeChildren(record);

    return {
      key: record.id?.toString(),
      data: this.formatData({ ...record }),
      leaf: children.length === 0,
      children,
    };
  }

  formatData(data: TableInputTypes): TableInputTypes {
    Object.keys(data)
      .filter(key => this.enumsMap[key])
      .forEach(key => {
        data[`_${key}`] = data[key];
        if (data[key] === undefined) {
          return;
        }
        data[key] = this.translate.get(`enums.${this.enumsMap[key]}.${data[key]}`);
      });

    this.formatCombinedValue(data);

    if ((data.id === FAKE_PARENT_ID || data.id === FAKE_PARENT_ID2) && 'name' in data) {
      data.name = this.translate.get(data.name || '');
    }
    return data;
  }

  createNodeChildren(record: TableInputTypes): TreeNode[] {
    const hasChildren = 'children' in record;
    return ((!hasChildren ? [] : record.children) || []).map(this.createTreeNode.bind(this));
  }

  expandCollapseAll(isExpand: boolean): void {
    this.viewModel.forEach(node => {
      this.expandRecursive(node, isExpand);
    });
    this.viewModel = [...this.viewModel];
  }

  expandRecursive(node: TreeNode, isExpand: boolean): void {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  updateMenuTranslation(): void {
    (this.menuItems as BasicMenuItem[])
      .filter(menuItem => menuItem.key)
      .forEach(menuItem => {
        menuItem.label = this.translate.get(menuItem.key);
      });
  }

  mapEnumsColumns(): void {
    this.enumsMap = (this.columns as EnumTableColumns[])
      .filter(column => column.enumType)
      .reduce((acc, cur) => ({ ...acc, [cur.field]: cur.enumType }), {});
  }

  mapCombinedColumns(): void {
    this.combinedMap = (this.columns as CombinedTreeTableColumns[]).filter(c => c.children);
  }

  onFilter({ filters }: TreeTableFilterEvent): void {
    this.expandCollapseAll(!!filters?.global);
  }

  expandSelectedNode(node: TreeNode): void {
    if (node) {
      this.nodeEvent = 'NodeSelect';
      this.highlightNode(node);
      if (node.children?.length) {
        node.expanded = true;
        this.viewModel = [...this.viewModel];
      }
      this.verifyEnableDisableExpandCollapseButtons();
    }
  }

  onDefaultSort(): void {
    if (!this.selectedNode) {
      setTimeout(() => {
        if (this.isInitialExpandAll) {
          this.expandCollapseButtonsService.setClickExpandAll();
        }
        const [defaultNode] = this.viewModel;

        if (defaultNode) {
          this.expandSelectedNode(defaultNode);
          this.nodeClick.emit(defaultNode);
        }
      }, 0);
    }
  }

  customSort({ data = [], order = 1, field = 'name' }: TreeTableSortEvent): object[] {
    this.saveTableSort.emit({ field: field, order: order });
    return data.sort(
      (a, b) =>
        order * String(a.data[field]).localeCompare(b.data[field], undefined, { numeric: true })
    );
  }

  rowTrackBy(_index: number, item: RowNode): string {
    return item.node.data.id;
  }

  paginate(event: TreeTablePaginatorState): void {
    this.changePage.emit(event);
  }

  isLeaf(node: TreeNode): boolean {
    return (
      !isFakeNode(node) &&
      !isFakeNode(node, FAKE_PARENT_ID2) &&
      (this.actionMenu === 'leaf' ? !!node.leaf : true)
    );
  }

  isSelected(node: TreeNode): boolean | undefined {
    const isSelectedId = this.selectedNode?.data.id === node.data.id;

    return (
      !isFakeNode(node) &&
      !isFakeNode(node, FAKE_PARENT_ID2) &&
      (this.actionMenu === 'leaf' ? !!node.leaf : true) &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (isSelectedId || this.selectedNodes?.some((n: any) => n.data.id === node.data.id))
    );
  }

  formatCombinedValue(data: TableInputTypes): void {
    let combinedValue = '';

    this.combinedMap.forEach((combined: CombinedTreeTableColumns) => {
      for (const child of combined.children) {
        if (data[child.field]) {
          if (child.type === 'enum') {
            combinedValue = combinedValue + ' ' + this.translate.get(data[child.field]);
          } else {
            combinedValue = combinedValue + ' ' + data[child.field];
          }
        }
      }
      data[combined.field] = combinedValue.trim();
    });
  }

  onColReorder(): void {
    this.colReordered.emit(true);
  }
}
