import { DecimalPipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationGuard } from 'core/guards/authorization.guard';
import { TabChangedGuard } from 'core/guards/tab-changed.guard';
import { AtsTranslationService, PermissionService } from 'core/services';
import { CapabilitiesService } from './services/capabilities.service';

import * as fromRoot from 'store/reducers';
import { DataPublisherService } from './api-services/data-publisher.service';
import { EmulatorSettingsService } from './api-services/emulator-settings.service';
import { EmulatorService } from './api-services/emulator.service';
import { EvacuationDeviceSettingsService } from './api-services/evacuation-device-settings.service';
import { FleetSettingsService } from './api-services/fleet-settings.service';
import { FleetService } from './api-services/fleet.service';
import { JobSettingsService } from './api-services/job-settings.service';
import { JobVehicleService } from './api-services/job-vehicle.service';
import { MappingService } from './api-services/mapping.service';
import { MapsService } from './api-services/maps.service';
import { MissionDispatchService } from './api-services/mission-dispatch.service';
import { PoiGroupsService } from './api-services/poi-groups.service';
import { PoiService } from './api-services/poi.service';
import { ProcessChainGroupService } from './api-services/process-chain-group.service';
import { ProcessChainTraceService } from './api-services/process-chain-trace.service';
import { ProcessChainsService } from './api-services/process-chains.service';
import { ReportService } from './api-services/reports.service';
import { TrafficSettingsService } from './api-services/traffic-settings.service';
import { UserService } from './api-services/user.service';
import { VehicleService } from './api-services/vehicle.service';
import { VersionService } from './api-services/version.service';
import { ZoneMembershipService } from './api-services/zone-membership.service';
import { AuthGuard } from './guards/auth.guard';
import { EnsureModuleLoadedOnceGuard } from './guards/ensure-module-loaded-once-guard';
import { PreventUnsavedChangesGuard } from './guards/prevent-unsaved-changes-guard.guard';
import { BaseHttpClient } from './http/base-http-client';

import { LoadTypeService, TourChainConfigurationService } from './api-services';
import { FireFighterSettingsService } from './api-services/fire-fighter-settings.service';
import { ArchivedToursRetryService } from './api-services/graph-manager/archived-tours-retry.service';
import { SessionService } from './services/session.service';
import { ToastService } from './services/toast.service';
import { MockSignalRNextService } from './signalR/mocks/mock-signalr-next.service';
import { SignalRNextService } from './signalR/signalr-next.service';

@NgModule({ imports: [], providers: [BaseHttpClient, provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }

  static getSignalRNextService(
    s: SessionService,
    rootStore: Store<fromRoot.RootState>,
    ts: ToastService
  ): SignalRNextService | MockSignalRNextService {
    return environment.useSignalR
      ? new SignalRNextService(s, rootStore, ts)
      : new MockSignalRNextService();
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        PoiGroupsService,
        MapsService,
        PoiService,
        VehicleService,
        JobVehicleService,
        FleetService,
        MissionDispatchService,
        ReportService,
        UserService,
        SessionService,
        PermissionService,
        EmulatorService,
        CapabilitiesService,
        AuthGuard,
        AuthorizationGuard,
        TabChangedGuard,
        {
          provide: SignalRNextService,
          useFactory: CoreModule.getSignalRNextService,
          deps: [SessionService, Store, ToastService, TranslateService, AtsTranslationService],
        },
        PreventUnsavedChangesGuard,
        MappingService,
        ProcessChainsService,
        ProcessChainTraceService,
        ZoneMembershipService,
        EmulatorService,
        FleetSettingsService,
        JobSettingsService,
        EmulatorSettingsService,
        EvacuationDeviceSettingsService,
        TrafficSettingsService,
        VersionService,
        DataPublisherService,
        DecimalPipe,
        ProcessChainGroupService,
        FireFighterSettingsService,
        ArchivedToursRetryService,
        TourChainConfigurationService,
        LoadTypeService,
      ],
    };
  }
}
