import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  AdHocTourStepsDispatch,
  CallOffTourTriggerResult,
  MissionTraceDto,
  TourAbortOptionsDto,
  TourChain,
  TourChainDto,
} from 'core/dtos';

import { convertMissionTraceDtoToModel, mapMissionTraceDtosToModels } from 'core/helpers';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, MissionTrace, Pose2D, Vector2D } from 'core/models';
import { Observable, catchError, map, of } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class TourChainService extends TenantHttpClient {
  private readonly servicePathTourTraces = API_SERVICES.TourTraces;
  private readonly servicePathTourDispatcher = API_SERVICES.Dispatcher;
  private readonly servicePathTours = API_SERVICES.Tours;
  private readonly servicePathTourChains = API_SERVICES.TourChains;

  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  triggerTourConfiguration(id: GuidString): Observable<void> {
    return this.post(`${this.servicePathTourDispatcher}/AdHocTourChainConfiguration/${id}`);
  }

  triggerCallOffTourConfig(
    tourConfigId: GuidString,
    vehicleId: GuidString
  ): Observable<CallOffTourTriggerResult> {
    return this.requestWithStatus(
      'post',
      `${this.servicePathTourDispatcher}/CallOffTourConfiguration/${tourConfigId}/${vehicleId}`
    ).pipe(
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError(error => of(error)),
      map(res => {
        if (res.status === HttpStatusCode.NoContent || res.status === HttpStatusCode.Ok) {
          return { isSuccessful: true };
        } else {
          return { isSuccessful: false, errorMessage: res };
        }
      })
    );
  }

  triggerAdHocSteps(steps: AdHocTourStepsDispatch): Observable<void> {
    return this.post(`${this.servicePathTourDispatcher}/AdHocTourSteps`, steps);
  }

  getAllActiveTours(): Observable<MissionTrace[]> {
    return this.get<MissionTraceDto[]>(`${this.servicePathTourTraces}`).pipe(
      map(missionTraceDtos => mapMissionTraceDtosToModels(missionTraceDtos))
    );
  }

  triggerAdHocPose(vehicleId: GuidString, pose: Pose2D): Observable<CallOffTourTriggerResult> {
    return this.requestWithStatus(
      'post',
      `${this.servicePathTourDispatcher}/vehicle/${vehicleId}/pose`,
      { Pose: pose }
    ).pipe(
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError(error => of(error)),
      map(res => {
        if (res.status === HttpStatusCode.NoContent || res.status === HttpStatusCode.Ok) {
          return { isSuccessful: true };
        } else {
          return { isSuccessful: false, errorMessage: res };
        }
      })
    );
  }

  cancelTour(id: GuidString, abortOptions: TourAbortOptionsDto): Observable<void> {
    return this.post<void>(`${this.servicePathTours}/cancel/${id}`, abortOptions);
  }

  getTourById(id: GuidString): Observable<MissionTrace> {
    return this.get<MissionTraceDto>(`${this.servicePathTourTraces}/${id}`).pipe(
      map(missionTraceDto => convertMissionTraceDtoToModel(missionTraceDto))
    );
  }

  getTourChainById(id: GuidString): Observable<TourChain | null> {
    return this.get<TourChainDto>(`${this.servicePathTourChains}/${id}`).pipe(
      map(tourChain =>
        tourChain
          ? {
              ...tourChain,
              tours: tourChain.tours.map(convertMissionTraceDtoToModel.bind(this)),
            }
          : null
      )
    );
  }

  getVehiclePathById(tourId: GuidString, vehicleId: GuidString): Observable<Vector2D[]> {
    return this.get<Vector2D[]>(`${this.servicePathTourTraces}/${tourId}/${vehicleId}`);
  }

  raisesInterlock(tourId: GuidString): Observable<boolean> {
    return this.get<boolean>(`${this.servicePathTourTraces}/raisesInterlock/${tourId}`);
  }

  cancelTourByVehicleId(vehicleId: GuidString): Observable<void> {
    return this.post(`${this.servicePathTours}/cancel/vehicle/${vehicleId}`);
  }
}
