import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { VehicleDto } from 'core/dtos';
import { GuidString } from 'core/models';
import { TenantRouterService } from 'core/services';
import { compareAsc } from 'date-fns';

import { NotificationPanelViewModel } from './notification-panel.viewmodel';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPanelComponent implements OnChanges, AfterViewChecked {
  @Input() allVehicles: VehicleDto[] = [];
  @Input() notificationViewModelList: NotificationPanelViewModel[] = [];

  @Output() readonly closeWindow = new EventEmitter<NotificationPanelViewModel | undefined>();
  @Output() readonly selectItem = new EventEmitter<VehicleDto>();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly router: TenantRouterService
  ) {}

  ngOnChanges(): void {
    this.removeNotificationsFromVehiclesThatNotExists();
    this.sortNotificationsByDueTimeAndAscByOccurredDateTime();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  removeNotificationsFromVehiclesThatNotExists(): void {
    if (this.notificationViewModelList) {
      this.notificationViewModelList = this.notificationViewModelList.filter(
        n =>
          (n.originArea === 'Vehicles' && this.vehicleExistOnList(n.id)) ||
          n.originArea !== 'Vehicles'
      );
    }
  }

  sortNotificationsByDueTimeAndAscByOccurredDateTime(): void {
    this.notificationViewModelList.sort((a, b) => {
      if (a.dueTime && b.dueTime) {
        return compareAsc(new Date(a.dueTime), new Date(b.dueTime));
      }
      if (!a.dueTime && !b.dueTime) {
        return compareAsc(new Date(b.occurredDateTimeUtc), new Date(a.occurredDateTimeUtc));
      }
      return a.dueTime ? -1 : 1;
    });
  }

  vehicleExistOnList(vehicleId: GuidString): boolean {
    return this.allVehicles.some(
      vehicle => vehicle.id === vehicleId && !vehicle.maintenanceModeEnabled
    );
  }

  onClick(notification: NotificationPanelViewModel): void {
    const vehicle = this.allVehicles?.find(v => v.id === notification.id);
    if (notification.originArea === 'Vehicles' && vehicle) {
      this.onClose(notification);
      this.selectItem.emit(vehicle);
    } else if (notification.originArea === 'OpcuaDevices') {
      this.onClose(notification);
      void this.router.navigate([`/devices/device/${notification.id}`]);
    } else if (notification.originArea === 'OpcuaNotifications') {
      this.onClose(notification);
      void this.router.navigate([`/devices/device/${notification.name}`]);
    }
  }

  onClose(notification?: NotificationPanelViewModel): void {
    this.closeWindow.emit(notification);
  }
}
