<div class="breadcrumb-bar-container">
  <button
    *ngIf="!shouldHideSideMenuOnOrgSettings && selectedWorkingArea"
    data-cy="workingAreaDropdown"
    id="workingAreaDropdown"
    ds-button
    [ds-menu-trigger-for]="menu"
    ds-button
    size="sm"
    data-cy="workingAreaDropdown"
    id="workingAreaDropdown">
    {{ selectedWorkingArea.name }}
  </button>
  <ds-menu
    id="workingAreaDropdown"
    #menu
    [menuItems]="workingAreasList"
    (itemSelect)="setSelectedWorkingArea($event)"></ds-menu>

  <ds-icon
    *ngIf="!shouldHideBreadCrumb"
    class="mx-1x"
    [size]="12"
    tone="neutral"
    icon="arrow_right_small"></ds-icon>

  <button
    data-cy="breadcrumbDropdown"
    dataKey="id"
    ds-button
    [ds-menu-trigger-for]="dsMenu"
    ds-button
    size="sm"
    *ngIf="allDropdownItems.length">
    {{ selectedItem.name }}
  </button>
  <ds-menu
    #dsMenu
    [menuItems]="mapDropDownList | sortDropdown"
    (itemSelect)="changeItemSelected($event)"></ds-menu>

  <ds-icon
    *ngIf="allDropdownItems.length"
    class="mx-1x"
    [size]="12"
    tone="neutral"
    icon="arrow_right_small"></ds-icon>

  <ds-breadcrumb
    *ngIf="!shouldHideBreadCrumb"
    data-cy="breadcrumb"
    [items]="breadcrumbItems"></ds-breadcrumb>
</div>
