import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { GuidString, ProcessChain } from 'core/models';

export enum ProcessChainActionTypes {
  LoadProcessChains = '[ProcessChains] Load Process Chains',
  LoadProcessChainsSuccess = '[ProcessChains] Load Process Chains Success',
  LoadProcessChainsFailure = '[ProcessChains] Load Process Chains Failure',
  SelectProcessChain = '[ProcessChains] Select Process Chain',
  AddProcessChain = '[ProcessChains] Add Process Chain',
  AddProcessChainSuccess = '[ProcessChains] Add Process Chain Success',
  AddProcessChainFailure = '[ProcessChains] Add Process Chain Failure',
  UpdateProcessChain = '[ProcessChains] Update Process Chain',
  UpdateProcessChainSuccess = '[ProcessChains] Update Process Chain Success',
  UpdateProcessChainFailure = '[ProcessChains] Update Process Chain Failure',
  DeleteProcessChain = '[ProcessChains] Delete Process Chain',
  DeleteProcessChainSuccess = '[ProcessChains] Delete Process Chain Success',
  DeleteProcessChainFailure = '[ProcessChains] Delete Process Chain Failure',
  TriggerProcessChain = '[ProcessChains] Trigger Process Chain',
  TriggerProcessChainSuccess = '[ProcessChains] Trigger Process Chain Success',
  TriggerProcessChainFailure = '[ProcessChains] Trigger Process Chain Failure',
  ActivateProcessChains = '[ProcessChains] Activate Process Chain',
  ActivateProcessChainsSuccess = '[ProcessChains] Activate Process Chains Success',
  ActivateProcessChainsFailure = '[ProcessChains] Activate Process Chains Failure',
  DeactivateProcessChains = '[ProcessChains] Deactivate Process Chains',
  DeactivateProcessChainsSuccess = '[ProcessChains] Deactivate Process Chains Success',
  DeactivateProcessChainsFailure = '[ProcessChains] Deactivate Process Chains Failure',
}
export const loadProcessChains = createAction(ProcessChainActionTypes.LoadProcessChains);

export const loadProcessChainsSuccess = createAction(
  ProcessChainActionTypes.LoadProcessChainsSuccess,
  props<{ processChains: ProcessChain[] }>()
);

export const loadProcessChainsFailure = createAction(
  ProcessChainActionTypes.LoadProcessChainsFailure,
  props<{ errorMessage: string }>()
);

export const selectProcessChain = createAction(
  ProcessChainActionTypes.SelectProcessChain,
  props<{ processChainId: GuidString }>()
);

export const addProcessChain = createAction(
  ProcessChainActionTypes.AddProcessChain,
  props<{ newProcessChain: ProcessChain }>()
);

export const addProcessChainSuccess = createAction(
  ProcessChainActionTypes.AddProcessChainSuccess,
  props<{ processChainCreated: ProcessChain }>()
);

export const addProcessChainFailure = createAction(
  ProcessChainActionTypes.AddProcessChainFailure,
  props<{ errorMessage: string; processChain: ProcessChain }>()
);

export const updateProcessChain = createAction(
  ProcessChainActionTypes.UpdateProcessChain,
  props<{ processChain: ProcessChain }>()
);

export const updateProcessChainSuccess = createAction(
  ProcessChainActionTypes.UpdateProcessChainSuccess,
  props<{ processChain: Update<ProcessChain> }>()
);

export const updateProcessChainFailure = createAction(
  ProcessChainActionTypes.UpdateProcessChainFailure,
  props<{ errorMessage: string; processChain: ProcessChain }>()
);

export const deleteProcessChain = createAction(
  ProcessChainActionTypes.DeleteProcessChain,
  props<{ processChain: ProcessChain }>()
);

export const deleteProcessChainSuccess = createAction(
  ProcessChainActionTypes.DeleteProcessChainSuccess,
  props<{ processChain: ProcessChain }>()
);

export const deleteProcessChainFailure = createAction(
  ProcessChainActionTypes.DeleteProcessChainFailure,
  props<{ errorMessage: string }>()
);

export const triggerProcessChain = createAction(
  ProcessChainActionTypes.TriggerProcessChain,
  props<{ processChain: ProcessChain }>()
);

export const triggerProcessChainSuccess = createAction(
  ProcessChainActionTypes.TriggerProcessChainSuccess,
  props<{ processChain: ProcessChain }>()
);

export const triggerProcessChainFailure = createAction(
  ProcessChainActionTypes.TriggerProcessChainFailure,
  props<{ errorMessage: string; processChain: ProcessChain }>()
);

export const activateProcessChains = createAction(
  ProcessChainActionTypes.ActivateProcessChains,
  props<{ processChainIds: GuidString[] }>()
);

export const activateProcessChainsSuccess = createAction(
  ProcessChainActionTypes.ActivateProcessChainsSuccess,
  props<{ processChains: ProcessChain[] }>()
);

export const activateProcessChainsFailure = createAction(
  ProcessChainActionTypes.ActivateProcessChainsFailure,
  props<{ errorMessage: string }>()
);

export const deactivateProcessChains = createAction(
  ProcessChainActionTypes.DeactivateProcessChains,
  props<{ processChainIds: GuidString[] }>()
);

export const deactivateProcessChainsSuccess = createAction(
  ProcessChainActionTypes.DeactivateProcessChainsSuccess,
  props<{ processChains: ProcessChain[] }>()
);

export const deactivateProcessChainsFailure = createAction(
  ProcessChainActionTypes.DeactivateProcessChainsFailure,
  props<{ errorMessage: string }>()
);
