import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  OrderGatewayFeatures,
  PktInterfaceToggleDto,
} from 'core/dtos/settings/order-gateway-features.dto';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class OrderGatewayFeatureService extends TenantHttpClient {
  protected apiUrl = environment.Services.OrderGateway;
  private readonly servicePath = API_SERVICES.Features;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getOrderGatewayFeatures(): Observable<OrderGatewayFeatures> {
    return this.get<OrderGatewayFeatures>(`${this.servicePath}`);
  }

  updatePktInterfaceToggle(value: boolean): Observable<PktInterfaceToggleDto> {
    return this.put<PktInterfaceToggleDto>(`${this.servicePath}/pktInterface`, {
      isToggledOn: value,
    });
  }
}
