import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { BaseHttpClient } from 'core/http/base-http-client';

import {
  GPPCalculationRequestModel,
  GPPCalculationResponseModel,
  PreCalculateMapRequestModel,
} from 'modules/maps/models/gpp-calculation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PathPlannerService extends BaseHttpClient {
  constructor(httpClient: HttpClient) {
    super(httpClient);
    this.apiUrl = environment.Services.PathPlanner + API_SERVICES.PathPlanner;
  }

  /** Returns a coarse path between two points */
  requestGPPCalculation(
    request: GPPCalculationRequestModel
  ): Observable<GPPCalculationResponseModel> {
    return this.post<GPPCalculationResponseModel>('/GppCalculation', request);
  }

  /** Returns a detailed path between two points */
  requestFinePath(request: GPPCalculationRequestModel): Observable<GPPCalculationResponseModel> {
    return this.post<GPPCalculationResponseModel>('/FullPathCalculation', request);
  }

  /** Triggers a GPP map precalc */
  preCalculateMap(request: PreCalculateMapRequestModel): Observable<string> {
    return this.post<string>('/PreCalculateMap', request);
  }
}
