import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SecondarySideMenuService {
  private readonly displaySideBar = new BehaviorSubject<boolean>(false);
  displaySideBar$ = this.displaySideBar.asObservable();

  private readonly cancelSideBarChange = new BehaviorSubject<boolean>(false);
  cancelSideBarChange$ = this.cancelSideBarChange.asObservable();

  private readonly localizeVehicle = new BehaviorSubject<boolean>(false);
  localizeVehicle$ = this.localizeVehicle.asObservable();

  private readonly vehicleSendStep = new BehaviorSubject<boolean>(false);
  vehicleSendStep$ = this.vehicleSendStep.asObservable();

  setDisplaySideBar(display: boolean): void {
    this.displaySideBar.next(display);
  }

  setCancelSideBarChange(cancel: boolean): void {
    this.cancelSideBarChange.next(cancel);
  }

  setLocalizeVehicle(localize: boolean): void {
    this.localizeVehicle.next(localize);
  }

  setVehicleSendStep(vehicleSendStep: boolean): void {
    this.vehicleSendStep.next(vehicleSendStep);
  }
}
