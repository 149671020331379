import { NodeGroupEffects } from './node-group.effects';
import { RouteConfigurationEffects } from './route-configuration.effects';
import { RouteCustomizationRuleEffects } from './route-customization-rule.effects';
import { TourChainConfigurationEffects } from './tour-chain-configuration.effects';
import { TourConfigurationEffects } from './tour-configuration.effects';

export const effects = [
  RouteConfigurationEffects,
  TourConfigurationEffects,
  TourChainConfigurationEffects,
  NodeGroupEffects,
  RouteCustomizationRuleEffects,
];
