import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { EnableVehicleSmoothingSettingsDto, MapManagerFeatures } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class MapSettingsService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.Features;
  protected apiUrl = environment.Services.MapManager;

  constructor(protected httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getMapManagerFeaturesV2(): Observable<MapManagerFeatures> {
    return this.get<MapManagerFeatures>(`${this.servicePath}/v2`);
  }

  updateVehicleSmoothingSettings(value: boolean): Observable<EnableVehicleSmoothingSettingsDto> {
    return this.put<EnableVehicleSmoothingSettingsDto>(
      `${this.servicePath}/EnableVehicleSmoothing`,
      {
        isToggledOn: value,
      }
    );
  }
}
