import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class BrakeTestService extends TenantHttpClient {
  protected apiUrl = environment.Services.FleetManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  sendBrakeTest(vehicleId: GuidString): Observable<GuidString> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.post<GuidString>(`${API_SERVICES.BrakeTest}/${vehicleId}`, headers);
  }
}
