import interpolate from 'b-spline';
import { Coordinate, EdgeTrajectory } from 'core/dtos';
import { Pose2D, Vector2D, arePositionsEqual } from '../positions.models';

export const NURB_INTERVAL = 0.3;

export class Nurb {
  path: Coordinate[] = [];
  length = 0;

  controlPositions: number[][];
  weights: number[];
  knots: number[];

  get isValid(): boolean {
    return this.path.length > 2;
  }

  constructor(
    public startPosition: Pose2D,
    public endPosition: Pose2D,
    private readonly trajectory: EdgeTrajectory
  ) {
    this.controlPositions = trajectory.controlPoints.map(p => [p.x, p.y]);
    this.weights = trajectory.controlPoints.map(p => p.weight ?? 1);
    this.knots = trajectory.knotVector;

    const length = this.calculateDistance(startPosition, endPosition);
    const amount = length / NURB_INTERVAL < 2 ? 2 : length / NURB_INTERVAL;

    for (let i = 0; i < amount; i++) {
      const point = this.getNurbPosition(i / amount);

      if (point && !arePositionsEqual(point, endPosition)) this.path.push(point);
    }
  }

  getTotalNurbLength(): number {
    if (this.length > 0) {
      return this.length;
    }

    let total = 0;
    for (let i = 1; i < this.path.length; i++) {
      total += this.calculateDistance(this.path[i - 1], this.path[i]);
    }

    this.length = total;
    return total;
  }

  calculatePointOnNurb(distance: number): Coordinate | undefined {
    const length = this.getTotalNurbLength();

    if (distance > length) {
      console.warn('Unable to calculate the nurb distance is larger than the path');
    }

    return this.getNurbPosition(distance / length);
  }

  getNurbMiddlePoints(): [Coordinate, Coordinate] {
    const middle = Math.round(this.path.length / 2);
    return [this.path[middle - 1], this.path[middle]];
  }

  // Position in percentage of the total length or the nurb
  private getNurbPosition(position: number): Coordinate | undefined {
    try {
      const point = interpolate(
        position,
        this.trajectory.degree,
        this.controlPositions,
        this.knots,
        this.weights
      );

      return {
        x: point[0],
        y: point[1],
      };
    } catch (error) {
      console.warn('Unable to calculate the nurb value', JSON.stringify(error));
    }
    return undefined;
  }

  calculateDistance(a: Vector2D, b: Vector2D): number {
    return Math.hypot(a.x - b.x, a.y - b.y);
  }
}
