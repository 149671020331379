import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  NewToMappingDto,
  NewToMappingPoiLookupDto,
  ToMappingDto,
  ToMappingPoiLookupDto,
} from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, MappingTableReducedModel } from 'core/models';
import { Observable, firstValueFrom } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class MappingService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.Mappings;
  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getMappings(): Observable<ToMappingDto[]> {
    return this.get<ToMappingDto[]>(this.servicePath);
  }

  createToMapping(mapping: NewToMappingDto): Observable<ToMappingDto> {
    return this.post<ToMappingDto>(this.servicePath, mapping);
  }

  createToMappingLookup(mapping: NewToMappingPoiLookupDto): Observable<ToMappingPoiLookupDto> {
    return this.post<ToMappingPoiLookupDto>(`${this.servicePath}/${mapping.tmId}/lookups`, mapping);
  }

  updateByToMappingLookup(mapping: ToMappingPoiLookupDto): Observable<ToMappingPoiLookupDto> {
    return this.put<ToMappingPoiLookupDto>(`${this.servicePath}/${mapping.tmId}/lookups/`, mapping);
  }

  deleteToMappingLookup(mapping: MappingTableReducedModel): Observable<GuidString> {
    return this.delete<GuidString>(`${this.servicePath}/${mapping.tmId}/lookups/${mapping.id}`);
  }

  async deleteToMappingsByMapId(mapId: GuidString): Promise<void> {
    return firstValueFrom(this.delete<void>(`${this.servicePath}/maps/${mapId}`));
  }
}
