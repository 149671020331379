import { Injectable } from '@angular/core';
import { MissionTraceDto } from 'core/dtos';
import { GuidString, Timestamped } from 'core/models';
import { SignalrRoutes } from '../signalr-routes';
import { MockSignalRService } from './mock-signalr.service';

@Injectable()
export class MockSignalRNextService extends MockSignalRService {
  registerConnectionNext(): void {}

  hasRouteJoinedGroup(_route: SignalrRoutes, _arg: GuidString | string): boolean {
    return false;
  }

  async fetchChangedMissionTraces(
    _missionTraceId: GuidString,
    _fromTicks: string | null
  ): Promise<Timestamped<MissionTraceDto>[]> {
    return Promise.resolve([]);
  }
}
