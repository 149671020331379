import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ROUTER_DATA_BREADCRUMB } from 'core/constants';
import { filterUndefined } from 'core/helpers';
import { Breadcrumb } from 'core/models';
import { clone, remove } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/index';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  ngUnsubscribe = new Subject<void>();
  breadcrumbChanged = new BehaviorSubject<Breadcrumb[]>([]);

  breadcrumbs: Breadcrumb[] = [];

  constructor(private readonly rootStore: Store<fromRoot.RootState>) {
    this.rootStore
      .pipe(select(fromRoot.getRouterInfo), filterUndefined(), takeUntil(this.ngUnsubscribe))
      .subscribe(({ breadcrumbs }) => {
        this.breadcrumbs = breadcrumbs;
        this.breadcrumbChanged.next(this.breadcrumbs);
      });
  }

  changeBreadcrumb(
    route: ActivatedRouteSnapshot,
    name: string,
    removeItemFromUrl = false,
    item: string | undefined = undefined
  ): void {
    const routeData = route.data;
    const index = this.breadcrumbs.findIndex(
      bc =>
        bc.originalName === routeData[ROUTER_DATA_BREADCRUMB] ||
        bc.displayName === routeData[ROUTER_DATA_BREADCRUMB]
    );

    if (removeItemFromUrl && item) {
      this.breadcrumbs = clone(this.breadcrumbs);
      const breadCrumb = clone(this.breadcrumbs[index]);
      breadCrumb.url = breadCrumb.url.replace(item, '');

      this.breadcrumbs[index] = breadCrumb;
    }

    if (index !== -1) {
      const breadcrumb = {
        ...this.breadcrumbs[index],
        displayName: name,
        originalName: this.breadcrumbs[index].originalName || this.breadcrumbs[index].displayName,
      };
      this.breadcrumbs = [
        ...this.breadcrumbs.slice(0, index),
        breadcrumb,
        ...this.breadcrumbs.slice(index + 1),
      ];
      this.breadcrumbChanged.next(this.breadcrumbs);
    }
  }

  addBreadcrumb(breadcrumb: Breadcrumb): void {
    this.breadcrumbs = Object.assign([], this.breadcrumbs);
    this.breadcrumbs.push(breadcrumb);
    this.breadcrumbChanged.next(this.breadcrumbs);
  }

  removeBreadcrumb(getCrumb: (items: Breadcrumb[]) => Breadcrumb | undefined): void {
    const item = getCrumb(this.breadcrumbs);

    if (item) {
      remove(this.breadcrumbs, item);
    }
    this.breadcrumbChanged.next(this.breadcrumbs);
  }

  replaceLastBreadcrumb(breadcrumb: Breadcrumb): void {
    this.breadcrumbs = Object.assign([], this.breadcrumbs);
    if (this.breadcrumbs.length > 1) {
      this.breadcrumbs.pop();
    }
    this.breadcrumbs.push(breadcrumb);
    this.breadcrumbChanged.next(this.breadcrumbs);
  }
}
