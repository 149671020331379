import {
  NodeType,
  OpcuaDeviceNodeRangeDto,
  OpcuaDeviceNodeResponseModel,
  OpcuaNodeStateDto,
  OpcuaStreamingServiceDto,
} from 'core/dtos';
import { DateString, GuidString, WorkingArea } from 'core/models';

export interface StreamingService extends OpcuaStreamingServiceDto {
  name: string;
}

export interface OpcuaDeviceUnderStreamingService {
  id: GuidString;
  name: string;
  description: string;
  server: string;
  streamingServiceName: string;
  pingNodeName: string;
  akz: string;
  info: string;
  lastUpdated: DateString | null;
  workingAreas: WorkingArea[];
  trafficLightStatus: OpcuaDeviceTrafficLightStatus;
  nodes: OpcuaDeviceNodeResponseModel[];
}

export interface OpcuaNodeConfig {
  name: string;
  nodeId: string;
  type: NodeType;
  values: string[];
  range: OpcuaDeviceNodeRangeDto[];
}

export interface OpcuaNodeState extends OpcuaNodeStateDto {
  comment?: string | null;
}

export interface StreamingServiceWithOpcuaDeviceTreeViewModel {
  id: GuidString;
  name: string;
  children: OpcuaDeviceUnderStreamingService[];
}

export enum OpcuaDeviceTrafficLightStatus {
  Connected = 0,
  NotInitialized = 1,
  LostConnection = 2,
}

export interface OpcUaDeviceReferenceDto {
  workAreaName: string;
  resources: string[];
}
