import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { FleetDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, NewFleetInput } from 'core/models';
import { Observable, firstValueFrom } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class FleetService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.Fleets;

  protected apiUrl = environment.Services.FleetManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getFleets(): Observable<FleetDto[]> {
    return this.get<FleetDto[]>(`${this.servicePath}/GetAllFleets`);
  }

  createFleet(newFleet: NewFleetInput): Observable<FleetDto> {
    return this.post<FleetDto>(this.servicePath, newFleet);
  }

  updateFleet(fleet: FleetDto): Observable<FleetDto> {
    return this.put<FleetDto>(`${this.servicePath}/${fleet.id}`, fleet);
  }

  async deleteFleetsByMapId(id: GuidString): Promise<void> {
    return firstValueFrom(this.delete<void>(`${this.servicePath}/maps/${id}`));
  }

  ungroupFleet(id: GuidString): Observable<void> {
    return this.delete<void>(`${this.servicePath}/${id}`);
  }
}
