import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from 'core/services';

@Directive({
  selector: '[appAllowAction]',
})
export class AllowActionDirective<T> {
  conditional = true;
  isAllow = false;
  viewRef?: EmbeddedViewRef<T>;

  constructor(
    private readonly templateRef: TemplateRef<T>,
    private readonly viewContainer: ViewContainerRef,
    private readonly permissionService: PermissionService
  ) {}

  @Input() set appAllowAction(action: string) {
    this.isAllow = this.permissionService.actionAllowed(action);
    this.updateView();
  }

  @Input() set appAllowActionAnd(conditional: T) {
    this.conditional = !!conditional;
    this.updateView();
  }

  updateView(): void {
    if (this.isAllow && this.conditional) {
      if (!this.viewRef) {
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewRef = undefined;
      this.viewContainer.clear();
    }
  }
}
