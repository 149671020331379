import { OtherColorsNumber, PoiColors } from 'library/styles';
import { IconOptions } from 'modules/maps/helpers';
import { RotationStyle } from '../map-item-rotation-container.graphic';

export const PoiSelection = {
  Border: 10,
  Color: PoiColors.BorderSelected,
  Radius: 12,
};

export const VectorStyle = {
  EntryColor: PoiColors.EntryVector,
  ExitColor: PoiColors.ExitVector,
  Size: 4,
};

export const SensorIconStyle = {
  Scale: 5,
  OffsetX: 25,
  OffsetY: -25,
};

export const IconStyle: IconOptions = {
  resourceOptions: { scale: 5 },
  scale: 2.7,
};

export const PoiPath = {
  size: 0.1,
  color: OtherColorsNumber.GreyBlue,
  alpha: 0.6,
};

export const lineArrow = {
  color: 0x6b7485,
  alpha: 1,
  size: 0.15,
  distance: 1.5,
};

export const PoiRotationStyle: RotationStyle = {
  iconScale: 2,
  offset: 20,
  labelYOffset: -15,
  textSize: 0.7,
  opacity: 0.95,
  labelCorner: 5,
};
