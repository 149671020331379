import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCopilotReducer from '../reducers/ats-copilot.reducer';
import { AtsCopilotState, getAllChatMessages } from '../reducers/ats-copilot.reducer';

export const selectAtsCopilotState = createFeatureSelector<AtsCopilotState>('copilot');

export const selectAllChatMessages = createSelector(selectAtsCopilotState, getAllChatMessages);

export const selectCopilotLoadingState = createSelector(
  selectAtsCopilotState,
  fromCopilotReducer.getLoading
);
