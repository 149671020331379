import { Filters } from 'core/models';
import { cloneDeep } from 'lodash';
import { FilterSchema } from '../schemas/filters-schema';

export function mergeFilters(
  initialFilters: Required<Filters>,
  storedFilters: FilterSchema
): Filters {
  const defaultFilters = cloneDeep(initialFilters);
  return {
    vehicles: {
      mode: MergeArray(defaultFilters.vehicles.mode, storedFilters.vehicles.mode),
      manual: MergeObj(defaultFilters.vehicles.manual, storedFilters.vehicles.manual),
      status: MergeArray(defaultFilters.vehicles.status, storedFilters.vehicles.status),
      load: MergeArray(defaultFilters.vehicles.load, storedFilters.vehicles.load),
      maintenance: MergeArray(
        defaultFilters.vehicles.maintenance,
        storedFilters.vehicles.maintenance
      ),
      type: To5Tuple(storedFilters.vehicles.type) ?? defaultFilters.vehicles.type,
      search: storedFilters.vehicles.search ?? defaultFilters.vehicles.search,
    },
    pois: {
      ...storedFilters.pois,
      occupancy: MergeArray(defaultFilters.pois.occupancy, storedFilters.pois.occupancy),
      type: MergeArray(defaultFilters.pois.type, storedFilters.pois.type),
    },
    trafficManagement: MergeObj(defaultFilters.trafficManagement, storedFilters.trafficManagement),
    zones: {
      type: MergeArray(defaultFilters.zones.type, storedFilters.zones.type),
    },
    maps: MergeObj(defaultFilters.maps, storedFilters.maps),
  };
}

function To5Tuple<T>(arr: T[]): [T, T, T, T, T] | undefined {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (arr.length === 5) return [arr[0], arr[1], arr[2], arr[3], arr[4]];
  else return undefined;
}

function MergeArray<T extends { id: unknown }>(base: T[], values?: T[]): T[] {
  const valueMap = new Map(values?.map(it => [it.id, it]));
  return base.map(it => valueMap.get(it.id) ?? it);
}

function MergeObj<T extends object>(base: T, values?: object): T {
  values = values ?? {};
  for (const key of Object.keys(values)) {
    if (key in base) {
      base[key] = values[key];
    }
  }
  return base;
}
