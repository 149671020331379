import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-chatbot-icon',
  templateUrl: './chatbot-icon.component.html',
  styleUrls: ['./chatbot-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatbotIconComponent {
  @Output() readonly openChat = new EventEmitter<void>();
  onOpenChat(): void {
    this.openChat.emit();
  }
}
