import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ATSMenuItem } from 'core/models';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnChanges {
  @ViewChild(Menu) appMenu!: Menu;

  private _items: ATSMenuItem[] = [];

  @Input() popup = false;
  @Input() hidePopup = false;
  @Input() appendTo?: string;
  @Input() styleClass?: string;

  @Input() set model(val: ATSMenuItem[]) {
    if (val) {
      this._items = val;
    }
  }

  get model(): ATSMenuItem[] {
    return this._items.filter(f => f.visible !== false);
  }

  get items(): MenuItem[] {
    return this.model as MenuItem[];
  }

  ngOnChanges({ hidePopup }: TypedChanges<MenuComponent>): void {
    if (hidePopup?.currentValue) {
      this.hide();
    }
  }

  toggle(event: Event): void {
    this.appMenu.toggle(event);
  }

  show(event: Event): void {
    this.appMenu.show(event);
  }

  hide(): void {
    this.appMenu?.hide();
  }

  isVisible(): boolean | undefined {
    return this.appMenu.visible ?? false;
  }
}
