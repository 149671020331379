import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { OpcuaStreamingServiceDto } from 'core/dtos';

import * as OpcuaStreamingServiceActions from '../actions/opcua-streamingservices.actions';

export const featureKey = 'opcuaStreamingServices';

export interface StreamingServicesState extends EntityState<OpcuaStreamingServiceDto> {
  loaded: boolean;
}

export const devicesAdapter: EntityAdapter<OpcuaStreamingServiceDto> =
  createEntityAdapter<OpcuaStreamingServiceDto>({
    selectId: (model: OpcuaStreamingServiceDto) => model.name,
  });

export const initialState: StreamingServicesState = devicesAdapter.getInitialState({
  loaded: false,
});

const opcuaDevicesReducer = createReducer(
  initialState,

  on(OpcuaStreamingServiceActions.loadOpcuaStreamingServices, state => ({
    ...state,
    errorMessage: '',
    loaded: false,
  })),

  on(
    OpcuaStreamingServiceActions.loadOpcuaStreamingServicesSuccess,
    (state, { streamingServices }) =>
      devicesAdapter.setAll(streamingServices, {
        ...state,
        loaded: true,
      })
  ),

  on(OpcuaStreamingServiceActions.loadOpcuaStreamingServicesFailure, (state, { errorMessage }) => ({
    ...state,
    loaded: false,
    errorMessage,
  })),

  on(
    OpcuaStreamingServiceActions.createOpcuaStreamingServiceSuccess,
    (state, { streamingService }) => devicesAdapter.addOne(streamingService, state)
  ),

  on(
    OpcuaStreamingServiceActions.updateOpcuaStreamingServiceSuccess,
    (state, { streamingService }) => devicesAdapter.setOne(streamingService, state)
  ),

  on(OpcuaStreamingServiceActions.deleteOpcuaStreamingServiceSuccess, (state, { name }) =>
    devicesAdapter.removeOne(name, state)
  ),

  on(
    OpcuaStreamingServiceActions.createOpcuaStreamingService,
    OpcuaStreamingServiceActions.updateOpcuaStreamingService,
    OpcuaStreamingServiceActions.deleteOpcuaStreamingService,
    state => ({
      ...state,
      errorMessage: '',
    })
  ),

  on(
    OpcuaStreamingServiceActions.signalRCreateOpcuaStreamingService,
    (state, { streamingService }) => devicesAdapter.addOne(streamingService, state)
  ),

  on(
    OpcuaStreamingServiceActions.signalRUpdateOpcuaStreamingService,
    (state, { streamingService }) =>
      devicesAdapter.updateOne({ id: streamingService.name, changes: streamingService }, state)
  ),

  on(
    OpcuaStreamingServiceActions.signalRDeleteOpcuaStreamingServices,
    (state, { streamingService }) => devicesAdapter.removeOne(streamingService.name, state)
  )
);

export function reducer(
  state: StreamingServicesState | undefined,
  action: Action
): StreamingServicesState {
  return opcuaDevicesReducer(state, action);
}

export const { selectEntities, selectAll } = devicesAdapter.getSelectors();

export const getOpcuaStreamingServiceLoaded = (state: StreamingServicesState): boolean =>
  state.loaded;
export const getOpcuaStreamingServiceEntities = selectEntities;
export const getAllOpcuaStreamingServices = selectAll;
