<div class="tree-view-items-container">
  <ds-tree data-cy="tree-leaf">
    <ds-tree-item
      data-cy="nodeEnvironment"
      *ngFor="let environment of treeViewInputModel$ | async"
      id="{{ environment.id }}"
      label="{{ environment.label }}"
      [isOpen]="environment.isOpen"
      (click)="onSelectEnvironment(); $event.stopPropagation()">
      <ds-tree-item
        aria-label="{{ org.label }}"
        *ngFor="let org of environment.children"
        label="{{ org.label }}"
        [isOpen]="org.isOpen"
        id="{{ org.id }}"
        (click)="onSelectOrg(org.id); $event.stopPropagation()">
        <ds-tree-item
          *ngFor="let wa of org.children"
          aria-label="{{ wa.label }}"
          label="{{ wa.label }}"
          id="{{ wa.id }}"
          (click)="onSelectWorkArea(org.id, wa.id); $event.stopPropagation()">
        </ds-tree-item>
      </ds-tree-item>
    </ds-tree-item>
  </ds-tree>
</div>
<hr class="smallLineSep" />
<div class="tree-view-actions-container">
  <app-expand-collapse-buttons class="float-end"></app-expand-collapse-buttons>
</div>
