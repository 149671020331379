import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidemenu-content-layout',
  templateUrl: './sidemenu-content-layout.component.html',
  styleUrls: ['./sidemenu-content-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidemenuContentLayoutComponent {
  @Input() pageHeading = '';
  @Input() secondaryPageHeading = '';
  @Input() sidemenuHeading = '';
}
