/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { RolesService, UserService } from 'core/api-services';
import { OrganizationDto, RoleDto, WorkingAreaDto } from 'core/dtos';
import { filterUndefined } from 'core/helpers';
import {
  AssignUserRoleCommand,
  AtsActions,
  BasicMenuItem,
  ExtendedDsMenuItem,
  RoleScope,
  ToolBarControlKeys,
  ToolBarControls,
  ToolBarItem,
  User,
  UserWithRole,
} from 'core/models';
import { AtsTranslationService } from 'core/services/ats-translation.service';
import { PermissionService } from 'core/services/permission.service';
import { ToolbarService } from 'core/services/toolbar.service';
import { ModalDialogService } from 'library/components/modal-dialog';
import { Observable, Subject, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { AssignUserRoleDialogInput, AssignUserRoleModalInputModal } from 'shared/components';

import { DEBOUNCE_TIME } from 'core/constants';
import { ToastService } from 'core/services';
import * as fromRoot from 'store/index';
type SpecialWaRoles = 'Viewer' | 'Operator' | 'Supervisor' | 'WorkingArea Admin';

@Component({
  selector: 'app-all-working-area-users-container',
  templateUrl: './all-working-area-users-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllWorkingAreaUsersContainerComponent implements OnInit, OnDestroy {
  $workingAreaUsers: Observable<UserWithRole[]> = of([]);
  TOOLBAR_ITEMS: ToolBarItem[] = [];
  searchTerm$: Observable<string> = of('');
  menuItems: ExtendedDsMenuItem[] = [];
  selectedWorkingArea!: WorkingAreaDto;
  selectedOrganization?: OrganizationDto;
  selectedUser?: UserWithRole;
  private users: User[] = [];
  private workingAreaRoles: RoleDto[] = [];
  ngUnsubscribe = new Subject<void>();
  private canEditWorkingAreaUsers = false;
  private readonly workingAreaKey = 'settings.users.workingArea';
  selectedWorkAreaId = '';
  selectedOrganizationId = '';
  canCreateViewerOperatorSupervisor = false;
  canCreateWorkingAreaAdmin = false;
  canAddWorkingAreaUser = false;
  isAssignUserModalOpen = false;
  assignUserRoleModalInput: AssignUserRoleModalInputModal | undefined;

  isModalOpen = false;
  userDetailsData?: AssignUserRoleDialogInput;

  constructor(
    private readonly userService: UserService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly toolbarService: ToolbarService,
    private readonly atsTranslateService: AtsTranslationService,
    private readonly modalService: ModalDialogService,
    private readonly toastService: ToastService,
    private readonly rolesService: RolesService,
    private readonly permissionService: PermissionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dispatchActionsAndQuerySelectors();
    this.initializeSubscriptions();
    this.getUserPermissions();
    this.buildToolBarItems();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  buildToolBarItems(): void {
    this.menuItems = this.getMenuItems();
    this.TOOLBAR_ITEMS = [
      {
        key: ToolBarControlKeys.AddUser,
        type: ToolBarControls.Button,
        hasSeparatorBorder: true,
        command: this.addUser.bind(this),
        visible: this.canAddWorkingAreaUser,
      },
      {
        key: ToolBarControlKeys.Search,
        type: ToolBarControls.Search,
      },
    ];
    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
    this.cdRef.markForCheck();
  }

  private dispatchActionsAndQuerySelectors(): void {
    this.searchTerm$ = this.toolbarService.searchTerm$.pipe(
      debounceTime(DEBOUNCE_TIME),
      distinctUntilChanged()
    );
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
  }

  private initializeSubscriptions(): void {
    this.route.paramMap
      ?.pipe(
        switchMap(params => {
          this.selectedWorkAreaId = params.get('workAreaId') || '';
          this.selectedOrganizationId = params.get('organizationId') || '';
          return this.rootStore.pipe(
            select(fromRoot.selectWorkingAreaById(this.selectedWorkAreaId)),
            takeUntil(this.ngUnsubscribe)
          );
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(workingArea => {
        if (workingArea) {
          this.selectedWorkingArea = workingArea;
          this.loadUsers();
          this.getUserPermissions();
          this.buildToolBarItems();
          this.cdRef.markForCheck();
        }
      });

    this.userService
      .getUsers()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(users => {
        this.users = users;
      });

    this.rolesService
      .getRolesByScopeWithoutPermissions(RoleScope.WorkingArea)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(roles => {
        const nonAdminRoles: SpecialWaRoles[] = ['Viewer', 'Operator', 'Supervisor'];
        const nonAdminRoleSet = new Set<string>(nonAdminRoles);
        const adminRole: SpecialWaRoles = 'WorkingArea Admin';

        this.workingAreaRoles = roles
          .filter(r => this.canCreateViewerOperatorSupervisor && nonAdminRoleSet.has(r.name))
          .concat(roles.filter(r => this.canCreateWorkingAreaAdmin && r.name === adminRole));
      });

    this.rootStore
      .pipe(
        select(fromRoot.getRouterInfo),
        switchMap(({ params: { organization } }) =>
          this.rootStore.pipe(
            select(fromRoot.selectOrganizationByName(organization)),
            filterUndefined(),
            take(1)
          )
        )
      )
      .pipe(take(1))
      .subscribe(org => {
        this.selectedOrganization = org;
      });
  }

  getPermissionForAction(action: string): boolean {
    return this.permissionService.actionAllowedInWa(
      action,
      this.selectedWorkingArea?.id,
      this.selectedOrganizationId
    );
  }

  addUser(): void {
    const data = {
      users: this.users,
      roles: this.workingAreaRoles,
      areaText: this.selectedWorkingArea?.name,
      translateKey: 'shared.assignUserRoleModal',
    };

    this.assignUserRoleModalInput = data;
    this.isAssignUserModalOpen = true;
    this.cdRef.markForCheck();
  }

  cancelAssignUserModal(): void {
    this.isAssignUserModalOpen = false;
  }

  createMenuItem(key: string, command: Function, visible: boolean): BasicMenuItem {
    return {
      label: this.atsTranslateService.get(key),
      key,
      command,
      visible: visible,
      disabled: false,
      automationId: `menu-${key}`,
    };
  }

  getMenuItems(): ExtendedDsMenuItem[] {
    return [
      this.createMenuItem('shared.treeTable.actionMenu.viewEdit', this.onEdit.bind(this), true),
      this.createMenuItem(
        'shared.treeTable.actionMenu.revokeAccess',
        this.onDelete.bind(this),
        this.canEditWorkingAreaUsers
      ),
    ];
  }

  onEdit(): void {
    const data = {
      currentUser: this.users.find(u => u.id === this.selectedUser?.id),
      currentRole: this.workingAreaRoles.find(r => r.id === this.selectedUser?.roleId),
      users: this.users,
      roles: this.workingAreaRoles,
      areaText: this.selectedWorkingArea?.name,
      translateKey: 'shared.editUserRoleModal',
    };

    this.isModalOpen = true;
    this.userDetailsData = data;
    this.cdRef.markForCheck();
  }

  onDelete(): void {
    const userData = {
      name: this.selectedUser?.name,
      areaName: this.selectedWorkingArea?.name,
      area: this.atsTranslateService.get(this.workingAreaKey),
    };

    this.modalService
      .createModal('settings.users.modalRevokeUserRole', userData, 'critical')
      .pipe(filter(Boolean), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        void this.userService
          .removeUserRole({
            id: this.selectedUser?.id,
            roleId: this.selectedUser?.userRoleId,
            workingAreaId: this.selectedWorkingArea.id,
            organizationId: this.selectedOrganizationId,
          })
          .then(() => {
            this.toastService.createSuccessToast(
              'settings.users.RemoveUserRoleSuccessMessage',
              userData
            );
            this.loadUsers();
          })
          .catch(() => {
            this.toastService.createErrorToast('settings.users.RemoveUserRoleFailureMessage');
          });
      });
  }

  private loadUsers(): void {
    this.$workingAreaUsers = this.userService
      .getWorkingAreaUsers(this.selectedWorkingArea?.id)
      .pipe(
        map(a =>
          a.map(o => {
            return {
              id: o.id,
              name: o.name,
              email: o.email,
              userRoleId: o.roles[0].id,
              role: o.roles[0].name,
              roleId: o.roles[0].roleId,
              department: o.department,
            };
          })
        )
      );
    this.cdRef.markForCheck();
  }

  onSelectedItem(userData: UserWithRole): void {
    this.selectedUser = userData;
  }

  showUserDetailsDialog(data: AssignUserRoleDialogInput): void {
    this.isModalOpen = true;
    this.userDetailsData = data;
  }

  onCloseModal(): void {
    this.isModalOpen = false;
  }

  async assignUserRole(result: AssignUserRoleCommand): Promise<void> {
    if (result) {
      result.workingAreaId = this.selectedWorkingArea.id;
      this.isAssignUserModalOpen = false;
      return this.assignUserRoleThenToastMessage(result);
    }
  }
  private async assignUserRoleThenToastMessage(result: AssignUserRoleCommand): Promise<void> {
    try {
      await this.userService.assignUserRole(result);
      this.toastService.createSuccessToast('settings.users.AssignUserRoleSuccessMessage');
      this.loadUsers();
    } catch (error) {
      this.toastService.createErrorToast('settings.users.AssignUserRoleFailureMessage');
    } finally {
      this.isAssignUserModalOpen = false;
      this.cdRef.markForCheck();
    }
  }
  getUserPermissions(): void {
    this.canEditWorkingAreaUsers = this.getPermissionForAction(AtsActions.WorkingArea_Users_Edit);
    this.canCreateWorkingAreaAdmin = this.getPermissionForAction(
      AtsActions.WorkingAreaAdmin_Create
    );
    this.canCreateViewerOperatorSupervisor = this.getPermissionForAction(
      AtsActions.Viewer_Operator_Supervisor_Create
    );

    this.canAddWorkingAreaUser =
      this.canCreateWorkingAreaAdmin || this.canCreateViewerOperatorSupervisor;
  }
  async onSettingsEnvironment(): Promise<void> {
    await this.router.navigate(['/settings/environment/view/users']);
  }
  async onSettingsOrganization(): Promise<void> {
    await this.router.navigate([this.selectedOrganization?.name, 'organization-settings', 'users']);
  }
}
