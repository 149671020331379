import { createSelector } from '@ngrx/store';
import * as fromSettings from 'store-modules/settings-store';

import { selectAllErrors } from './errors.selectors';

export const selectAllErrorsWithErrorForwarding = createSelector(
  selectAllErrors,
  fromSettings.selectAllErrorForwarding,
  (allErrors, allErrorForwardings) => {
    return allErrors.filter(error =>
      allErrorForwardings.find(ef => ef.type === error.type && ef.sendNotification)
    );
  }
);
