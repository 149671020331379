import { FailureCommentDto, MissionTraceDto } from 'core/dtos';
import { MissionTrace } from '../jobs/mission-trace.model';
import {
  MissionDeliveryStatus,
  MissionDisplayHighlightLevel,
  MissionFormat,
  MissionPriorityLevel,
  MissionStatus,
  MissionTraceTrigger,
} from '../jobs/mission.enum';
import { ProcessChainTraceStatus } from '../jobs/process-chain-trace-status.enum';
import { ProcessChainTraceModel } from '../jobs/process-chain-trace.model';
import { StepType, StepTypeBackend } from '../jobs/step.enum';
import { LoadType, VehicleType } from '../vehicles/vehicle.enum';

export const initProcessChainTraceModel: ProcessChainTraceModel = {
  id: '',
  createdDateTime: '',
  updatedDateTime: '',
  processChainId: '',
  name: '',
  materialNumber: '',
  source: '',
  destination: '',
  missionTraces: [],
  dedupIdentifier: '',
  status: ProcessChainTraceStatus.Scheduled,
  workAreaId: '',
};

export const initMissionTrace: MissionTrace = {
  id: '',
  missionId: '',
  vehicleId: '',
  vehicleName: '',
  vehicleType: VehicleType.UnitLoad,
  missionName: '',
  status: MissionStatus.QueuedOnSystem,
  currentStepAttributeText: '',
  currentStepSequenceNumber: 0,
  stepTraces: [],
  missionStepNo: '',
  createdDateTime: '',
  completedDateTime: '',
  updatedDateTime: '',
  trigger: MissionTraceTrigger.None,
  materialNumber: '',
  processChainName: '',
  processChainId: '',
  processChainTraceId: '',
  provisioningTime: null,
  forecastedEndTime: null,
  hasManualSupport: false,
  failureComment: {
    missionFailureReasonId: null,
    missionFailureReasonComments: '',
    missionFailureMinutesToSolve: null,
    missionFailureReasonEn: '',
    missionFailureReasonDe: '',
    missionFailureMinutesForEmergencyProcess: null,
    missionFailureLocationId: null,
    missionFailureShiftGroupId: null,
    durationOfMissionInFailed: 0,
    missionFailureStartDateTime: '',
    missionFailureEndDateTime: '',
  },
  assignableWaypointId: null,
  lateDeliveryAlarmTime: 5,
  isAlarmForLateDeliveryActive: true,
  maxExecutionTime: 0,
  isMaxExecutionTimeActive: false,
  deliveryStatus: MissionDeliveryStatus.Normal,

  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  canBeReAssigned: false,
  canBeContinuedFromStep: false,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
  assignmentConditions: [],
  stepCount: 1,
  isAssignedToVehicle: true,
  currentStepType: StepTypeBackend.Goto,
  currentStepTypeFrontEnd: StepType.Goto,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndDateTime: '',
  missionFormat: MissionFormat.ROS,
  loadType: LoadType.GltStrStandard,
  tourChainId: null,
  tourChainName: null,
  scanValue: null,
  acknowledgeable: false,
};

export const initMissionFailureReason: FailureCommentDto = {
  missionFailureReasonId: null,
  missionFailureReasonComments: '',
  missionFailureMinutesToSolve: null,
  missionFailureReasonEn: '',
  missionFailureReasonDe: '',
  durationOfMissionInFailed: 0,
  missionFailureStartDateTime: '',
  missionFailureEndDateTime: '',
  missionFailureLocationId: '',
  missionFailureShiftGroupId: null,
  vehicleName: '',
  missionName: '',
  missionFailureMinutesForEmergencyProcess: null,
};

export const initMissionTraceDto: MissionTraceDto = {
  id: '',
  missionId: '',
  vehicleId: '',
  vehicleName: '',
  vehicleType: VehicleType.UnitLoad,
  missionName: '',
  status: MissionStatus.QueuedOnSystem,
  currentStepAttributeText: '',
  currentStepSequenceNumber: 0,
  stepTraces: [],
  missionStepNo: '',
  createdDateTime: '',
  updatedDateTime: '',
  forecastedEndTime: '',
  trigger: MissionTraceTrigger.None,
  materialNumber: '',
  processChainName: '',
  processChainId: '',
  processChainTraceId: '',
  eventTraces: [],
  interlockDetails: [],
  failureComment: {
    missionFailureReasonId: null,
    missionFailureReasonComments: '',
    missionFailureMinutesToSolve: null,
    missionFailureReasonEn: '',
    missionFailureReasonDe: '',
    missionFailureMinutesForEmergencyProcess: null,
    missionFailureLocationId: null,
    missionFailureShiftGroupId: null,
    durationOfMissionInFailed: 0,
    missionFailureStartDateTime: '',
    missionFailureEndDateTime: '',
  },
  assignableWaypointId: null,
  assignableWaypointName: '',
  lateDeliveryAlarmTime: 5,
  isAlarmForLateDeliveryActive: true,
  maxExecutionTime: 0,
  isMaxExecutionTimeActive: false,
  deliveryStatus: MissionDeliveryStatus.Normal,

  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  canBeReAssigned: false,
  canBeContinuedFromStep: false,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
  assignmentConditions: [],
  stepCount: 1,
  isAssignedToVehicle: true,
  currentStepType: StepTypeBackend.Goto,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndDateTime: '',
  missionFormat: MissionFormat.ROS,
  loadType: LoadType.GltStrStandard,
  tourChainId: null,
  tourChainName: null,
  scanValue: null,
  acknowledgeable: false,
};
