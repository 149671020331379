export enum MissionReportCategory {
  IO = 0,
  Organizational = 1,
  CallOffSystem = 2,
  Vehicle = 3,
  Services = 4,
  NoReasonAssigned = 5,
  NotRelevant = 6,
  SystemOptimization = 7,
}
