import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { PoiDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, OpcUaDeviceReferenceDto } from 'core/models';
import { Observable, firstValueFrom } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class PoiService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.POIs;
  private readonly poiGroupsServicePath = API_SERVICES.POIGroups;

  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  createPoi(poi: PoiDto): Observable<PoiDto> {
    return this.post<PoiDto>(this.servicePath, poi);
  }

  updatePoi(poi: PoiDto): Observable<void> {
    return this.put<void>(`${this.servicePath}/${poi.id}`, poi);
  }

  deletePoi(poi: PoiDto): Observable<void> {
    return this.delete<void>(`${this.servicePath}/${poi.id}`);
  }

  async deletePoisByMapId(mapId: GuidString): Promise<PoiDto> {
    return firstValueFrom(this.delete<PoiDto>(`${this.servicePath}/map/${mapId}`));
  }

  ungroupPoi(poi: PoiDto): Observable<void> {
    return this.put<void>(`${this.poiGroupsServicePath}/ungroup/${poi.poiGroupId}/${poi.id}`);
  }

  getPoiWithDevice(device: string): Observable<OpcUaDeviceReferenceDto[]> {
    return this.get<OpcUaDeviceReferenceDto[]>(`${this.servicePath}/devices/${device}`);
  }
}
