import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { RuleDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class RuleService extends TenantHttpClient {
  private readonly servicePathRouteConfigurations = API_SERVICES.Rules;

  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getAllRules(): Observable<RuleDto[]> {
    return this.get<RuleDto[]>(`${this.servicePathRouteConfigurations}`);
  }

  getRuleById(ruleId: GuidString): Observable<RuleDto> {
    return this.get<RuleDto>(`${this.servicePathRouteConfigurations}/${ruleId}`);
  }

  getRuleByStopNodeId(stopNodeId: GuidString): Observable<RuleDto[]> {
    return this.get<RuleDto[]>(`${this.servicePathRouteConfigurations}/byStopNode/${stopNodeId}`);
  }

  createRule(rule: RuleDto): Observable<RuleDto> {
    return this.post<RuleDto>(`${this.servicePathRouteConfigurations}`, rule);
  }

  deleteRuleById(ruleId: GuidString): Observable<void> {
    return this.delete<void>(`${this.servicePathRouteConfigurations}/${ruleId}`);
  }

  updateRule(rule: RuleDto): Observable<RuleDto> {
    return this.put<RuleDto>(`${this.servicePathRouteConfigurations}`, rule);
  }
}
