import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { EditBarService } from 'core/services';
import { ModalDialogService } from 'library/components';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-edit-toggle',
  templateUrl: './edit-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditToggleComponent implements OnInit, OnDestroy {
  isEditMode = false;
  hasChanges = false;

  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly modalService: ModalDialogService,
    private readonly editBarService: EditBarService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.rootStore
      .pipe(select(fromRoot.selectIsEditMode), takeUntil(this.ngUnsubscribe))
      .subscribe(isEditMode => {
        const searchValue = !isEditMode ? 'edit' : 'view';
        const replaceValue = isEditMode ? 'edit' : 'view';

        this.location.replaceState(this.router.url.replace(searchValue, replaceValue));

        this.isEditMode = isEditMode;
        this.cdRef.markForCheck();
      });

    this.editBarService.hasChanges$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(hasChanges => (this.hasChanges = hasChanges));
  }

  editModeSwitchChanged(): void {
    if (!this.isEditMode && this.hasChanges) {
      this.openModalExitEditMode();
    } else {
      this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: this.isEditMode }));
    }
  }

  openModalExitEditMode(): void {
    this.modalService
      .createModal('shared.modalExitEditMode')
      .pipe(take(1))
      .subscribe(response => {
        if (response) {
          this.exitEditMode();
          this.location.replaceState(this.router.url.replace('edit', 'view'));
        } else {
          this.isEditMode = true;
          this.cdRef.markForCheck();
        }
      });
  }

  private exitEditMode(): void {
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: this.isEditMode }));
    this.editBarService.setHasChanges(false);
    this.editBarService.cancel();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
