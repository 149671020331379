import { createAction, props } from '@ngrx/store';
import { InferenceRequestDto, InferenceResponseDto } from 'core/dtos';

export enum AtsCopilotTypes {
  SendMessage = '[Chatbot] Send Message',
  MessageSentSuccess = '[Chatbot] Message Sent Success',
  MessageSentFailure = '[Chatbot] Message Sent Failure',

  PollInference = '[Chatbot] Poll Inference',
  PollInferenceSuccess = '[Chatbot] Poll Inference Success',
  PollInferenceFailure = '[Chatbot] Poll Inference Failure',
}

export const sendMessage = createAction(
  AtsCopilotTypes.SendMessage,
  props<{ botId: string; message: InferenceRequestDto }>()
);

export const messageSentSuccess = createAction(
  AtsCopilotTypes.MessageSentSuccess,
  props<{ botId: string; asyncId: string }>()
);

export const messageSentFailure = createAction(
  AtsCopilotTypes.MessageSentFailure,
  props<{ errorMessage: string }>()
);

export const pollInference = createAction(
  AtsCopilotTypes.PollInference,
  props<{ botId: string; asyncId: string; sub: string }>()
);

export const pollInferenceSuccess = createAction(
  AtsCopilotTypes.PollInferenceSuccess,
  props<{ response: InferenceResponseDto }>()
);

export const pollInferenceFailure = createAction(
  AtsCopilotTypes.PollInferenceFailure,
  props<{ errorMessage: string }>()
);
