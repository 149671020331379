import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { CellClickedEvent, ColDef, RowSelectedEvent } from 'ag-grid-community';
import { ExtendedDsMenuItem, TooltipItem } from 'core/models';
import { AtsTranslationService, ToolbarService } from 'core/services';

import { ActionMenuCellComponent } from '../../action-menu-cell/action-menu-cell.component';
import { BaseAgGridTableDirective } from '../base-ag-grid-table/base-ag-grid-table.directive';

@Directive()
export abstract class AgGridActionDirective<T> extends BaseAgGridTableDirective implements OnInit {
  constructor(
    protected readonly translateService: AtsTranslationService,
    protected readonly toolbarService: ToolbarService,
    protected readonly cdRef: ChangeDetectorRef
  ) {
    super(translateService, toolbarService, cdRef);
  }

  menuItems: ExtendedDsMenuItem[] = [];
  actionMenuColumn: ColDef = {
    headerName: 'shared.table.actionMenuHeader',
    colId: 'actions',
    pinned: 'right',
    type: 'rightAligned',
    cellRenderer: ActionMenuCellComponent,
    sortable: false,
    resizable: false,
    filter: false,
    width: 100,
    minWidth: 100,
    lockPinned: true,
    cellClass: 'suppress-movable-col',
    lockPosition: 'right',
  };

  ngOnInit(): void {
    this.addActionMenuColumn();
    super.ngOnInit();
  }

  addActionMenuColumn(): void {
    this.setGridMenuItems();

    this.actionMenuColumn.cellRendererParams = {
      value: this.menuItems,
    };

    if (!this.columns.find(x => x.colId === this.actionMenuColumn.colId)) {
      this.columns.push(this.actionMenuColumn);
    }

    this.translateHeader();

    this.gridOptions = {
      ...this.gridOptions,
      onCellClicked: this.checkSelectedCell.bind(this),
      onRowSelected: this.checkSelectedRow.bind(this),
    };
  }

  updateActionMenuColumn(): void {
    const index = this.columns.findIndex(c => c.headerName === 'shared.table.actionMenuHeader');
    this.columns.splice(index, 1);
    this.addActionMenuColumn();
  }

  createMenuItem(
    key: string,
    command: Function,
    visible = true,
    disabled = false,
    icon?: string,
    tooltipOptions?: TooltipItem
  ): ExtendedDsMenuItem {
    return {
      automationId: `menu-${key}`,
      label: this.translateService.get(key),
      key,
      command,
      visible,
      disabled,
      icon,
      tooltipOptions: tooltipOptions
        ? {
            tooltipLabel: this.translateService.get(tooltipOptions.tooltipLabel || ''),
            tooltipConfig: tooltipOptions.tooltipConfig,
          }
        : tooltipOptions,
    };
  }

  private checkSelectedCell(event: CellClickedEvent<T>): void {
    if (event.colDef.colId === this.actionMenuColumn.colId) {
      this.updateGridMenuItems(event.data);
      this.gridApi.refreshCells({ force: true });
      event.event?.stopImmediatePropagation();
    } else {
      this.onCellSelected(event);
    }

    this.cdRef.markForCheck();
  }

  private checkSelectedRow(event: RowSelectedEvent<T>): void {
    const isParentNode = !!event.node.allChildrenCount;
    if (isParentNode) {
      this.updateGridMenuItems(event.data);
      this.cdRef.markForCheck();
    }
  }

  abstract onCellSelected(event: CellClickedEvent<T>): void;
  abstract setGridMenuItems(item?: T): void;
  abstract updateGridMenuItems(item?: T): void;
}
