import { Injectable } from '@angular/core';
import { MenuButton, ToolBarItem } from 'core/models';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {
  private items: { [name: string]: MenuButton[] } = {};

  private readonly toolBarItems = new ReplaySubject<ToolBarItem[]>(1);
  configuredToolBarItems$ = this.toolBarItems.asObservable();

  private readonly searchTerms = new BehaviorSubject<string>('');
  searchTerm$ = this.searchTerms.asObservable();

  private readonly sendGroupItemsMessage = new BehaviorSubject<{ [name: string]: MenuButton[] }>(
    this.items
  );
  private readonly sendActionMessage = new Subject<number>();

  configureItems(items: ToolBarItem[]): void {
    this.toolBarItems.next(items);
    this.search('');
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  setDropdownItems(key: string, menuItems: MenuButton[]): void {
    this.items[key] = menuItems;
    this.sendGroupItemsMessage.next(this.items);
  }

  getDropdownItems(key: string): Observable<MenuButton[]> {
    return this.sendGroupItemsMessage.pipe(
      filter(() => Object.keys(this.items).some(k => k === key)),
      map(i => i[key])
    );
  }

  clearDropdownItems(): void {
    this.items = {};
  }

  sendButtonAction(message: number): void {
    this.sendActionMessage.next(message);
  }

  getButtonActions(): Observable<number> {
    return this.sendActionMessage.asObservable();
  }
}
