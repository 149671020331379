<div class="container center">
  <div class="vertical-center">
    <h1 class="title-lg mb-18x notFoundDetailsMessage1">
      {{ 'shared.noPermission.headerText' | translate }}
    </h1>

    <img class="mt-18x" [src]="imageUrl" alt="Not Found" />

    <div class="mt-8x">
      <p class="text-paragraph messageText notFoundDetailsMessage2" data-cy="no-permission-message">
        {{ 'shared.noPermission.messageText1' | translate }}
      </p>

      <div class="center-horizontal mt-10x">
        <a ds-button href="/welcome" class="redirect-button">
          {{ 'shared.noPermission.redirectButtonText' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
