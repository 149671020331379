import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ChatMessage } from 'core/models';
import * as AtsCopilotActions from '../actions/ats-copilot.actions';

export const featureKey = 'copilot';

export interface AtsCopilotState extends EntityState<ChatMessage> {
  loading: boolean;
  errorMessage: string;
}

export const copilotAdapter: EntityAdapter<ChatMessage> = createEntityAdapter<ChatMessage>({
  selectId: (model: ChatMessage) => `${model.type}_${model.time}`,
});

export const initialState: AtsCopilotState = copilotAdapter.getInitialState({
  loading: false,
  errorMessage: '',
});

export const AtsCopilotReducer = createReducer(
  initialState,
  on(AtsCopilotActions.sendMessage, (state, { message }) =>
    copilotAdapter.addOne(
      {
        text: message.text,
        type: 'user',
        time: new Date().toLocaleTimeString(),
      },
      { ...state, loading: true, actionStatus: AtsCopilotActions.AtsCopilotTypes.SendMessage }
    )
  ),
  on(AtsCopilotActions.messageSentSuccess, (state, { asyncId }) => ({
    ...state,
    asyncId,
    loading: false,
  })),
  on(AtsCopilotActions.messageSentFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),
  on(AtsCopilotActions.pollInference, state => ({
    ...state,
    loading: true,
  })),

  on(AtsCopilotActions.pollInferenceSuccess, (state, { response }) =>
    copilotAdapter.addOne(
      {
        text: response.answer,
        type: 'bot',
        time: new Date().toLocaleTimeString(),
      },
      {
        ...state,
        loading: false,
        actionStatus: AtsCopilotActions.AtsCopilotTypes.PollInferenceSuccess,
      }
    )
  ),

  on(AtsCopilotActions.pollInferenceSuccess, (state, { response }) => ({
    ...state,
    loading: false,
    response,
  })),
  on(AtsCopilotActions.pollInferenceFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  }))
);

export function reducer(state: AtsCopilotState | undefined, action: Action): AtsCopilotState {
  return AtsCopilotReducer(state, action);
}

export const { selectEntities, selectAll } = copilotAdapter.getSelectors();

export const getLoading = (state: AtsCopilotState): boolean => state.loading;

export const getEntities = selectEntities;
export const getAllChatMessages = selectAll;
