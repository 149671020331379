import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { OpcuaDeviceResponseModel } from 'core/dtos';

import { cloneDeep } from 'lodash';
import * as OpcuaDeviceActions from '../actions/opcua-devices.actions';

export const featureKey = 'opcuaDevices';

export interface DevicesState extends EntityState<OpcuaDeviceResponseModel> {
  loaded: boolean;
  errorMessage: string;
  selectedDevice: string;
}

export const devicesAdapter: EntityAdapter<OpcuaDeviceResponseModel> =
  createEntityAdapter<OpcuaDeviceResponseModel>({
    selectId: (model: OpcuaDeviceResponseModel) => model.name,
  });

export const initialState: DevicesState = devicesAdapter.getInitialState({
  loaded: false,
  errorMessage: '',
  selectedDevice: '',
});

const opcuaDevicesReducer = createReducer(
  initialState,

  on(OpcuaDeviceActions.loadOpcuaDevices, state => ({
    ...state,
    errorMessage: '',
    loaded: false,
  })),

  on(OpcuaDeviceActions.loadOpcuaDeviceSuccess, (state, { device }) =>
    devicesAdapter.setOne(device, {
      ...state,
      loaded: true,
    })
  ),

  on(OpcuaDeviceActions.loadOpcuaDevicesSuccess, (state, { devices }) =>
    devicesAdapter.setAll(devices, {
      ...state,
      loaded: true,
    })
  ),

  on(OpcuaDeviceActions.loadOpcuaDevicesFailure, (state, { errorMessage }) => ({
    ...state,
    loaded: false,
    errorMessage,
  })),

  on(OpcuaDeviceActions.setSelectedDevice, (state, { name }) => ({
    ...state,
    selectedDevice: name,
  })),

  on(OpcuaDeviceActions.createOpcuaDeviceSuccess, (state, { device }) =>
    devicesAdapter.addOne(device, state)
  ),

  on(OpcuaDeviceActions.updateOpcuaDeviceSuccess, (state, { device }) =>
    devicesAdapter.updateOne({ id: device.name, changes: device }, state)
  ),

  on(OpcuaDeviceActions.deleteOpcuaDeviceSuccess, (state, { name }) =>
    devicesAdapter.removeOne(name, state)
  ),

  on(OpcuaDeviceActions.updateOpcuaDeviceNodesSuccess, (state, { device }) =>
    devicesAdapter.updateOne({ id: device.name, changes: device }, state)
  ),

  on(
    OpcuaDeviceActions.createOpcuaDevice,
    OpcuaDeviceActions.updateOpcuaDevice,
    OpcuaDeviceActions.deleteOpcuaDevice,
    state => ({
      ...state,
      errorMessage: '',
    })
  ),

  on(OpcuaDeviceActions.signalRCreateOpcuaDevice, (state, { device }) =>
    devicesAdapter.addOne(device, state)
  ),

  on(OpcuaDeviceActions.signalRUpdateOpcuaDevice, (state, { device }) =>
    devicesAdapter.updateOne({ id: device.name, changes: device }, state)
  ),

  on(OpcuaDeviceActions.signalRDeleteOpcuaDevice, (state, { device }) =>
    devicesAdapter.removeOne(device.name, state)
  ),

  on(OpcuaDeviceActions.signalRUpdateOpcuaDeviceNodeState, (state, { telemetry }) => {
    const device = cloneDeep(state.entities[telemetry.device]);
    const node = device?.nodes.filter(n => n.nodeId === telemetry.nodeId)[0];

    if (device) {
      if (telemetry.nodeName === device.pingNodeName) {
        device.lastPingReceivedDate = telemetry.measurementDate;
      }

      if (device.latencyMs) {
        device.latencyMs = telemetry.latencyMs;
      }

      if (node) {
        node.state = {
          name: telemetry.nodeName,
          measurementDate: telemetry.measurementDate,
          value: telemetry.value,
          rawValue: telemetry.rawValue,
        };
      }

      return devicesAdapter.updateOne({ id: device.name, changes: device }, state);
    }
    return state;
  }),

  on(OpcuaDeviceActions.signalRUpdateOpcuaDeviceOnlineState, (state, { statusMessage }) => {
    const device = cloneDeep(state.entities[statusMessage.name]);

    if (!device) {
      return state;
    }

    device.lastPingReceivedDate = statusMessage.lastPingReceivedDate;

    return devicesAdapter.updateOne(
      {
        id: device.name,
        changes: device,
      },
      state
    );
  })
);

export function reducer(state: DevicesState | undefined, action: Action): DevicesState {
  return opcuaDevicesReducer(state, action);
}

export const { selectEntities, selectAll } = devicesAdapter.getSelectors();

export const getOpcuaDeviceLoaded = (state: DevicesState): boolean => state.loaded;
export const getOpcuaDeviceEntities = selectEntities;
export const getAllOpcuaDevices = selectAll;
export const getSelectedDevice = (state: DevicesState): string => state.selectedDevice;
