<ds-modal
  size="lg"
  #userSettingsModal
  [ds-modal-trigger-for]="userSettingsModal"
  [(isModalOpen)]="isModalOpen"
  style="height: 80%"
  (modalDismiss)="onCancel()">
  <ds-box-header data-cy="dialogHeader">{{
    'shared.users.userDetailsDialog.editHeader' | translate
  }}</ds-box-header>
  <ds-box-content divider="full" data-cy="dialog-content" class="container-fluid roles-content">
    <ds-box style="width: 20%">
      <ds-box-content>
        <div class="container-fluid g-0 headerContainer">
          <div class="container-fluid p-4x">
            <div class="row ms-2x bold-labels">
              {{ 'shared.users.userDetailsDialog.userProfile' | translate }}
            </div>
          </div>

          <div class="container-fluid p-4x">
            <div class="row ms-2x profile-titles">
              {{ 'shared.users.userDetailsDialog.username' | translate }}
            </div>
            <div class="row ms-2x">
              {{ currentUser?.name }}
            </div>
          </div>
          <hr class="graphLineSep thin overview" />

          <div class="container-fluid p-4x">
            <div class="row ms-2x profile-titles">
              {{ 'shared.users.userDetailsDialog.department' | translate }}
            </div>
            <div class="row ms-2x">
              {{ currentUser?.department }}
            </div>
          </div>
          <hr class="graphLineSep thin overview" />

          <div class="container-fluid p-4x">
            <div class="row ms-2x profile-titles">
              {{ 'shared.users.userDetailsDialog.email' | translate }}
            </div>
            <div
              class="row ms-2x"
              style="overflow: hidden; white-space: nowrap"
              [title]="currentUser?.email">
              {{ currentUser?.email }}
            </div>
          </div>
          <hr class="graphLineSep thin overview" />

          <div class="container-fluid p-4x">
            <div class="row ms-2x profile-titles">
              {{ 'shared.users.userDetailsDialog.accessExpiry' | translate }}
            </div>
            <div class="row ms-2x">
              {{
                accessExpiry.toString()
                  | date : 'dd.MM.yyyy HH:mm' : 'locale' : currentLang
                  | notApplicable
              }}
            </div>
          </div>
        </div></ds-box-content
      >
    </ds-box>
    <ds-box style="width: 80%">
      <ds-box-content style="height: 100%">
        <div>
          <app-organization-chart
            style="display: flex; flex-direction: column; height: 100%"
            [editEnabled]="editEnabled"
            [userDetailsData]="userDetailsData"
            [organizationList]="organizationList$ | async"></app-organization-chart>
        </div>
      </ds-box-content>
    </ds-box>
  </ds-box-content>

  <ds-box-footer data-cy="dialog-footer">
    <button ds-button (click)="onCancel()" data-cy="modalCancel">
      {{ 'shared.modalButtons.CloseButton' | translate }}
    </button>
    &nbsp;
    <button ds-button (click)="onSave()" *ngIf="isEditMode$ | async; $">
      {{ 'shared.modalButtons.SaveButton' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
