import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DsTableConfig } from '@bmw-ds/components';
import { environment } from '@environment';
import { EmulatorHostFeaturesDto } from 'core/dtos';
import { EditBarService } from 'core/services';
import { clone, isEqual } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { EmulatorSettingsViewModel } from './emulator-settings.viewmodel';

@Component({
  selector: 'app-emulator-settings',
  templateUrl: './emulator-settings.component.html',
  styleUrls: ['emulator-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmulatorSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isEditMode = false;
  @Input() emulatorSettings?: EmulatorHostFeaturesDto;

  @Output() readonly activateEmulators = new EventEmitter();
  @Output() readonly deactivateEmulators = new EventEmitter();
  @Output() readonly setEmulatorSettings = new EventEmitter<EmulatorSettingsViewModel>();

  viewModel: EmulatorSettingsViewModel;
  currentViewModel?: EmulatorSettingsViewModel;
  emulatorSettingsForm: UntypedFormGroup;
  environment = environment;
  ngUnsubscribe = new Subject<void>();
  config: Partial<DsTableConfig> = {
    hasRowDivider: true,
    variant: 'regular',
    hasZebraRows: true,
    hasOuterDivider: true,
    hasColumnDivider: true,
  };
  constructor(
    private readonly editBarService: EditBarService,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    this.viewModel = this.generateViewModel();
    this.emulatorSettingsForm = this.createEmulatorSettingsFormGroup();
  }

  ngOnInit(): void {
    this.editBarService.onSave = this.onSave.bind(this);
    this.emulatorSettingsForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.checkFormUpdates());
  }

  ngOnChanges({ emulatorSettings, isEditMode }: TypedChanges<EmulatorSettingsComponent>): void {
    if (emulatorSettings?.currentValue) {
      this.viewModel = {
        ...this.viewModel,
        enableAddingEmulators: emulatorSettings.currentValue.enableAddingEmulators || false,
        speedFactor: emulatorSettings.currentValue.speedFactor || 1,
        enableCompletePath: emulatorSettings.currentValue.enableCompletePath || false,
        enableAwarenessField: emulatorSettings.currentValue.enableAwarenessField || false,
        minimumAwarenessDistance: emulatorSettings.currentValue.minimumAwarenessDistance || 0,
        vehicleFrontOffset: emulatorSettings.currentValue.vehicleFrontOffset || 0,
        vehicleLoadedExtraWidth: emulatorSettings.currentValue.vehicleLoadedExtraWidth || 0,
        vehicleWidth: emulatorSettings.currentValue.vehicleWidth || 0,
      };
      this.currentViewModel = clone(this.viewModel);
      this.updateEmulatorSettingsFormGroup();
    }

    if (isEditMode?.currentValue) {
      this.emulatorSettingsForm.enable();
      this.editBarService.setCanDelete(false);
    }

    if (isEditMode?.currentValue === false) {
      this.emulatorSettingsForm.disable();
    }

    if (!isEditMode?.currentValue && this.currentViewModel) {
      this.viewModel = {
        ...this.currentViewModel,
      };
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  generateViewModel(): EmulatorSettingsViewModel {
    return {
      enableAddingEmulators: false,
      speedFactor: 1,
      enableCompletePath: false,
      enableAwarenessField: false,
      minimumAwarenessDistance: 0,
      vehicleFrontOffset: 0,
      vehicleLoadedExtraWidth: 0,
      vehicleWidth: 0,
    };
  }

  createEmulatorSettingsFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      enableAddingEmulators: this.viewModel.enableAddingEmulators,
      enableCompletePath: this.viewModel.enableCompletePath,
      enableAwarenessField: this.viewModel.enableAwarenessField,
      emulatorSpeedFactor: [this.viewModel.speedFactor, Validators.min(1)],
    });
  }

  updateEmulatorSettingsFormGroup(): void {
    this.emulatorSettingsForm.patchValue({
      enableAddingEmulators: this.viewModel.enableAddingEmulators,
      enableCompletePath: this.viewModel.enableCompletePath,
      enableAwarenessField: this.viewModel.enableAwarenessField,
      emulatorSpeedFactor: this.viewModel.speedFactor,
    });
  }

  checkFormUpdates(): void {
    const { enableAddingEmulators, enableCompletePath, enableAwarenessField, emulatorSpeedFactor } =
      this.emulatorSettingsForm.value;

    this.currentViewModel = {
      ...this.viewModel,
      enableAddingEmulators: enableAddingEmulators,
      speedFactor: Number(emulatorSpeedFactor),
      enableCompletePath: enableCompletePath,
      enableAwarenessField: enableAwarenessField,
    };

    this.editBarService.setHasChanges(!isEqual(this.viewModel, this.currentViewModel));
    this.editBarService.setIsFormValid(this.emulatorSettingsForm.valid);
  }

  onSave(): void {
    this.setEmulatorSettings.emit(this.currentViewModel);
  }

  onActivateEmulators(): void {
    this.activateEmulators.emit();
  }

  onDeactivateEmulators(): void {
    this.deactivateEmulators.emit();
  }
}
