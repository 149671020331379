<ds-form-field style="width: 280px">
  <input
    #searchInput
    data-cy="searchInput"
    ds-input
    id="username"
    type="text"
    [placeholder]="placeHolder"
    (keyup)="onKeyup(searchInput.value)" />
  <button ds-button icon="search"></button>
</ds-form-field>
