import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Filters, FiltersKeys } from 'core/models';
import { StorageService } from 'core/services/storage.service';
import { map, tap } from 'rxjs/operators';
import * as FiltersActions from '../actions/filters.actions';
import { mergeFilters } from '../helpers/filters.helper';
import { initialState } from '../reducers/filters.reducer';
import { isValidFilter } from '../schemas/filters-schema';

@Injectable()
export class FiltersEffects {
  loadFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersActions.loadFilters),
      map(() => {
        const validateStoredFilters = isValidFilter(
          this.storageService.get<Filters>(FiltersKeys.Filters)
        );
        const defaultFilters = { ...initialState };

        if (validateStoredFilters.success) {
          const updatedFilters = mergeFilters(defaultFilters, validateStoredFilters.data);

          return FiltersActions.loadFiltersSuccess({
            filters: updatedFilters,
          });
        }
        this.storageService.set(FiltersKeys.Filters, defaultFilters);
        return FiltersActions.loadFiltersSuccess({
          filters: defaultFilters,
        });
      })
    )
  );

  resetFilters$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FiltersActions.resetFilters),
        tap(() => {
          this.storageService.set(FiltersKeys.Filters, initialState);
        })
      ),
    { dispatch: false }
  );

  updateFilters$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FiltersActions.updateFilters),
        tap(({ filters }) => {
          this.storageService.set(FiltersKeys.Filters, filters);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly storageService: StorageService
  ) {}
}
