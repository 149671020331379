import { Injectable } from '@angular/core';
import { SignalRNextMessage } from 'core/models';
import { SessionService } from 'core/services/session.service';
import { Subject } from 'rxjs';

import { SignalRNextService } from '../signalr-next.service';
import { SignalrRoutes } from '../signalr-routes';

export interface UserPermissionsSignalrSubscriber {
  joinUserPermissions(userId: string): Promise<void>;
}

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsSignalRService {
  userPermissionsMessageReceived = new Subject<SignalRNextMessage<number>>();

  constructor(
    private readonly signalrNextService: SignalRNextService,
    private readonly sessionService: SessionService
  ) {
    this.registerConnections();
  }

  signalrSubscriberFactory(componentName: string): UserPermissionsSignalrSubscriber {
    const joinUserPermissions = (userId: string): Promise<void> => {
      const group = SignalrRoutes.UserPermissions.replace('{0}', userId);
      return this.signalrNextService.joinGroup(group, componentName);
    };

    return { joinUserPermissions };
  }

  protected registerConnections(): void {
    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.UserPermissions,
      this.userPermissionsMessageReceived
    );

    this.userPermissionsMessageReceived.pipe().subscribe(() => {
      const forceRefresh = true;
      void this.sessionService.getUserInfo(forceRefresh);
    });
  }
}
