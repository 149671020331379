import { Coordinate } from 'core/dtos';
import { IconOptions, MapPixiHelper } from 'modules/maps/helpers';
import { Graphics, LINE_CAP, Sprite, Texture } from 'pixi.js';
import { VectorStyle } from '../poi.constant';

export class PoiGraphicHelper {
  static createIcon(texture: Texture, _options: IconOptions): Sprite {
    return MapPixiHelper.createIconCenter(texture);
  }

  static createVectorLine(color: number, from: Coordinate, to: Coordinate): Graphics {
    return new Graphics()
      .lineStyle({
        cap: LINE_CAP.ROUND,
        color,
        width: VectorStyle.Size,
      })
      .moveTo(from.x, from.y)
      .lineTo(to.x, to.y);
  }
}
