import { SapInternalTourService } from 'core/api-services';
/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TEN_SECONDS } from 'core/constants';
import { InternalTourStatus } from 'core/models';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, tap } from 'rxjs/operators';
import * as TourActions from '../actions/tour.actions';

@Injectable()
export class TourEffects {
  loadTours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TourActions.loadTours),
      concatMap(() =>
        this.sapInternalTourService.getInternalTours().pipe(
          map(tours => TourActions.loadToursSuccess({ tours })),
          catchError(errorMessage => of(TourActions.loadToursFailure({ errorMessage })))
        )
      )
    )
  );

  loadToursFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TourActions.loadToursFailure),
        tap(() => {
          this.toastService.createErrorToast('jobs.internalTours.loadToursFailure');
        })
      ),
    { dispatch: false }
  );

  signalRUpdateTour$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TourActions.signalRUpdateTour),
      filter(
        ({ tour }) =>
          tour.status === InternalTourStatus.Completed ||
          tour.status === InternalTourStatus.Canceled
      ),
      delay(TEN_SECONDS),
      map(({ tour }) => TourActions.removeTour({ tour }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sapInternalTourService: SapInternalTourService,
    private readonly toastService: ToastService
  ) {}
}
