import { createSelector, MemoizedSelector } from '@ngrx/store';
import { ZoneSetDto } from 'core/dtos';
import { GuidString, ZoneSetStatus } from 'core/models';
import * as fromMapsFeatureState from '../reducers';
import * as fromZoneSets from '../reducers/zone-sets.reducer';
import { selectMapId } from './maps.selectors';

const selectZoneSetState = createSelector(
  fromMapsFeatureState.getMapsFeatureState,
  fromMapsFeatureState.getZoneSetState
);

export const selectZoneSetLoading = createSelector(selectZoneSetState, fromZoneSets.getLoading);

export const selectZoneSetLoaded = createSelector(selectZoneSetState, fromZoneSets.getLoaded);

export const selectZoneSetId = createSelector(
  selectZoneSetState,
  fromZoneSets.getSelectedZoneSetId
);

export const selectZoneSetErrorMessage = createSelector(
  selectZoneSetState,
  fromZoneSets.getErrorMessage
);

export const selectZoneSetEntities = createSelector(
  selectZoneSetState,
  fromZoneSets.getZoneSetEntities
);

export const selectAllZoneSets = createSelector(selectZoneSetState, fromZoneSets.getAllZoneSets);

export const selectZoneSetsBySelectedMapId = createSelector(
  selectAllZoneSets,
  selectMapId,
  (zoneSets, mapId) => zoneSets.filter(zoneSet => zoneSet.mapId === mapId)
);

export const selectSelectedMapActiveZoneSet = createSelector(
  selectAllZoneSets,
  selectMapId,
  (zoneSets, mapId) => {
    return zoneSets.find(
      zoneSet => zoneSet.mapId === mapId && zoneSet.status === ZoneSetStatus.Active
    );
  }
);

export const selectZoneSetBySelectedZoneSetId = createSelector(
  selectZoneSetId,
  selectAllZoneSets,
  (zoneSetId, zoneSets) => {
    return zoneSets.find(zoneSet => zoneSet.id === zoneSetId);
  }
);

export const selectZoneSetByZoneSetId = (
  zoneSetId: GuidString
): MemoizedSelector<object, ZoneSetDto | undefined> =>
  createSelector(selectAllZoneSets, (zoneSets: ZoneSetDto[]) => {
    return zoneSets.find(zoneSet => zoneSet.id === zoneSetId);
  });

export const selectZoneSetsByMapId = (mapId: GuidString): MemoizedSelector<object, ZoneSetDto[]> =>
  createSelector(selectAllZoneSets, (zoneSets: ZoneSetDto[]) =>
    zoneSets.filter(zoneSet => zoneSet.mapId === mapId)
  );

export const selectActiveZoneSetByMapId = (
  mapId: GuidString
): MemoizedSelector<object, ZoneSetDto> =>
  createSelector(selectAllZoneSets, (zoneSets: ZoneSetDto[]) => {
    return zoneSets.filter(
      zoneSet => zoneSet.mapId === mapId && zoneSet.status === ZoneSetStatus.Active
    )[0];
  });
