import { GridValueFormatters } from './ag-grid-formatters';

export class GridFilterComparator {
  static stringDateComparator(filterLocalDateAtMidnight: Date, cellValue: string): number {
    const cellDate = new Date(cellValue);
    cellDate.setHours(0, 0, 0, 0);
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }

  static dateSorter(filterLocalDateAtMidnight: string, cellValue: string | null): number {
    const dateAsString = cellValue;
    const dateAsStringMidNight = filterLocalDateAtMidnight;
    if (dateAsString === null) {
      return 0;
    }

    const cellDate = GridValueFormatters.convertDateStringToDate(dateAsString);
    const cellDateMidNight = GridValueFormatters.convertDateStringToDate(dateAsStringMidNight);

    if (cellDate < cellDateMidNight) {
      return -1;
    } else if (cellDate > cellDateMidNight) {
      return 1;
    }

    return 0;
  }

  static basicStringSorter(a: string, b: string): number {
    return a < b ? -1 : a > b ? 1 : 0;
  }
}
