import { Pipe, PipeTransform } from '@angular/core';
import { IconCollection } from './format-icon.pipe.constant';

@Pipe({
  name: 'formatIcon',
  pure: true,
})
export class FormatIconPipe implements PipeTransform {
  transform(value: string | number | undefined, key: string): string {
    return (IconCollection[key] && IconCollection[key][value]) || '';
  }
}
