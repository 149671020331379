import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromProcessChainGroups from './process-chain-group.reducer';
import * as fromProcessChains from './process-chain.reducer';

export const featureKey = 'processConfigurator';

export interface ProcessConfiguratorFeatureState {
  [fromProcessChains.featureKey]: fromProcessChains.ProcessChainState;
  [fromProcessChainGroups.featureKey]: fromProcessChainGroups.ProcessChainGroupState;
}

export function reducers(state: ProcessConfiguratorFeatureState, action: Action): unknown {
  return combineReducers({
    [fromProcessChains.featureKey]: fromProcessChains.reducer,
    [fromProcessChainGroups.featureKey]: fromProcessChainGroups.reducer,
  })(state, action);
}

export const getProcessConfiguratorFeatureState =
  createFeatureSelector<ProcessConfiguratorFeatureState>(featureKey);
export const getProcessChainState = (
  state: ProcessConfiguratorFeatureState
): fromProcessChains.ProcessChainState => state.processChains;
export const getProcessChainGroupState = (
  state: ProcessConfiguratorFeatureState
): fromProcessChainGroups.ProcessChainGroupState => state.processChainGroups;
