import { createSelector } from '@ngrx/store';
import * as fromMonitoringFeatureState from '../reducers/index';
import * as fromProcessChainGroupTrace from '../reducers/process-chain-group-trace.reducer';

const selectProcessChainGroupTraceState = createSelector(
  fromMonitoringFeatureState.getMonitoringFeatureState,
  fromMonitoringFeatureState.getProcessChainGroupTraceState
);

export const selectProcessChainGroupTraceLoading = createSelector(
  selectProcessChainGroupTraceState,
  fromProcessChainGroupTrace.getLoading
);

export const selectProcessChainGroupTraceLoaded = createSelector(
  selectProcessChainGroupTraceState,
  fromProcessChainGroupTrace.getLoaded
);

export const selectAllProcessChainGroupTraces = createSelector(
  selectProcessChainGroupTraceState,
  fromProcessChainGroupTrace.getAllProcessChainGroupTraces
);

export const selectSelectedProcessChainGroupTraceId = createSelector(
  selectProcessChainGroupTraceState,
  fromProcessChainGroupTrace.getSelectedProcessChainGroupTraceId
);

export const selectAllProcessChainGroupTraceEntities = createSelector(
  selectProcessChainGroupTraceState,
  fromProcessChainGroupTrace.getEntities
);
