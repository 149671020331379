import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { environment } from '@environment';
import { EffectsModule } from '@ngrx/effects';
import {
  NavigationActionTiming,
  RouterState,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  OAuthModule,
  OAuthModuleConfig,
  OAuthNoopResourceServerErrorHandler,
  OAuthResourceServerErrorHandler,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { CoreModule } from 'core/core.module';
import { AuthInterceptor } from 'core/http/auth-interceptor';
import { AtsTranslationService } from 'core/services';
import { LandingPageComponent } from 'modules/home/pages/landing-page/landing-page.component';
import { SettingsModule } from 'modules/settings/settings.module';
import { SidemenuComponent } from 'shared/components/sidemenu/sidemenu.component';
import { SharedModule } from 'shared/shared.module';
import { ErrorsStoreModule } from 'store-modules/errors-store/errors-store.module';
import { MissionMonitoringStoreModule } from 'store-modules/mission-monitoring-store/mission-monitoring-store.module';
import { OpcuaDevicesStoreModule } from 'store-modules/opcua-devices-store/opcua-devices-store.module';
import { VehiclesStoreModule } from 'store-modules/vehicles-store/vehicle-store.module';
import { CustomSerializer } from 'store/reducers/router.reducer';

import { ApolloModule } from 'apollo-angular';
import { AppSettingsLoader } from 'core/services/app-settings-loader';
import { GraphQLModule } from 'modules/graphql/graphql.module';
import { AtsCopilotStoreModule } from 'store-modules/ats-copilot-store/ats-copilot-store.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import translations_de from './i18n/de.json';
import translations_en from './i18n/en.json';
import { AtsWelcomePageLayoutComponent } from './layouts/ats-welcome-page-layout/ats-welcome-page-layout.component';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import * as fromRootStore from './store';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    SidemenuComponent,
    LandingPageComponent,
    AtsWelcomePageLayoutComponent,
    BaseLayoutComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    FormsModule,
    BrowserModule,
    environment.useAnimations ? BrowserAnimationsModule : NoopAnimationsModule,
    CoreModule.forRoot(),
    TranslateModule.forRoot(),
    AppRoutingModule,
    StoreModule.forRoot(fromRootStore.reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: false,
      },
      metaReducers: [fromRootStore.resetState],
    }),
    EffectsModule.forRoot(fromRootStore.effects),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'ATS UI.Next',
          maxAge: 25,
          connectInZone: true,
        })
      : [],
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: CustomSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    OAuthModule.forRoot(),
    SettingsModule,
    ErrorsStoreModule,
    OpcuaDevicesStoreModule,
    VehiclesStoreModule,
    AtsCopilotStoreModule,
    MissionMonitoringStoreModule,
    SharedModule,
    ApolloModule,
    GraphQLModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useValue: AppSettingsLoader, multi: true },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: OAuthModuleConfig,
      useFactory: () => ({
        resourceServer: {
          allowedUrls: Object.values(environment.Services),
          sendAccessToken: true,
        },
      }),
    },
    { provide: OAuthStorage, useValue: localStorage },
    { provide: OAuthResourceServerErrorHandler, useClass: OAuthNoopResourceServerErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(translator: AtsTranslationService) {
    translator.loadTranslations(translations_en, translations_de);
  }
}
