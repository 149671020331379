import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY_GUID } from 'core/constants';
import {
  GuidString,
  LoadType,
  LoadTypeConfigurationDto,
  LoadTypeConfigurationViewModel,
  LoadTypeIdentifier,
  PoiType,
  poiTypesWithoutLoadType,
} from 'core/models';
import * as fromSettings from 'store-modules/settings-store';
import * as fromRoot from 'store/reducers';

@Component({
  selector: 'app-load-type-table',
  templateUrl: './load-type-table.component.html',
  styleUrls: ['./load-type-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadTypeTableComponent implements OnInit, OnChanges {
  @Input() workAreaId: GuidString = EMPTY_GUID;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {}
  @Input() loadTypeConfigurations: LoadTypeConfigurationDto[] = [];

  configurationModels: LoadTypeConfigurationViewModel[] = [];
  loadTypePois = [
    { types: [PoiType.DollyPlace], translate: 'dolly' },
    { types: [PoiType.TurnTableSmall], translate: 'turnTableSmall' },
    { types: [PoiType.TurnTableLarge], translate: 'turnTableLarge' },
    { types: [PoiType.TripleTurnTable], translate: 'turnTableTriple' },
    { types: [PoiType.ParkingSpaceLarge], translate: 'parkingXl' },
    { types: [PoiType.Handover], translate: 'handover' },
    { types: [PoiType.PalletStationCrosswise], translate: 'palletPois' },
    { types: [PoiType.RackChangerDropoff], translate: 'rackPois' },
    { types: [PoiType.AutomaticConveyorLoading], translate: 'automaticConveyor' },
    { types: [PoiType.AssignableWaypoint], translate: 'assignmentPoi' },
  ];

  poiTypesWithoutLoadType = poiTypesWithoutLoadType;

  ngOnInit(): void {
    this.setLoadTypes();
    this.cdRef.markForCheck();
  }

  ngOnChanges({ loadTypeConfigurations }: TypedChanges<LoadTypeTableComponent>): void {
    if (loadTypeConfigurations?.currentValue) {
      this.setLoadTypes();
    }
  }

  private setLoadTypes(): void {
    this.configurationModels = this.loadTypeConfigurations.map(t => ({
      ...t,
      identifier: LoadTypeIdentifier[t.loadType],
    }));
  }

  isPoiEnabled(enabledPoiTypes: PoiType[], loadTypePois: PoiType[]): boolean {
    return enabledPoiTypes.some(poiType => loadTypePois.includes(poiType));
  }

  onToggleLoadTypeActivation(loadType: LoadType): void {
    const loadTypeDto = this.configurationModels?.find(type => type.loadType === loadType);
    if (!loadTypeDto) {
      return;
    }

    loadTypeDto.isActive = !loadTypeDto.isActive;

    this.rootStore.dispatch(fromSettings.updateLoadType({ configuration: loadTypeDto }));
  }

  setLoadTypesWithUpdatedLoadType(loadTypeModel: LoadTypeConfigurationViewModel): void {
    if (!this.configurationModels) {
      this.configurationModels = [];
    }
    this.configurationModels = this.configurationModels.map(type => {
      if (type.loadType !== loadTypeModel.loadType) {
        return type;
      }

      return loadTypeModel;
    });
  }
}
