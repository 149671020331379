import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { VehicleWaitingDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class JobVehicleService extends TenantHttpClient {
  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getVehicleWaitingState(vehicleId: GuidString): Observable<VehicleWaitingDto> {
    return this.get<VehicleWaitingDto>(`${API_SERVICES.Vehicles}/${vehicleId}/waitingState`);
  }
}
