import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { RoleDto } from 'core/dtos';
import { AssignUserRoleCommand, User } from 'core/models';
import { AtsTranslationService } from 'core/services';

export interface AssignUserRoleModalInputModal {
  users: User[];
  roles: RoleDto[];
  areaText: string;
  translateKey: string;
}

@Component({
  selector: 'app-assign-user-role-modal',
  templateUrl: './assign-user-role-modal.component.html',
  styleUrls: ['./assign-user-role-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignUserRoleModalComponent implements OnChanges {
  @Input() isModalOpen = false;
  @Input() assignUserRoleModalInput: AssignUserRoleModalInputModal | undefined = undefined;
  @Output() readonly cancelModal = new EventEmitter<void>();
  @Output() readonly saveModal = new EventEmitter<AssignUserRoleCommand>();

  areaText = '';
  currentUser?: User;
  currentRole?: RoleDto;
  users: User[] = [];
  roles: RoleDto[] = [];
  translateKey = '';
  readonly editTranslateKey = 'shared.editUserRoleModal';

  constructor(private readonly atsTranslationService: AtsTranslationService) {}

  ngOnChanges(): void {
    this.setValues();
  }

  onCancel(): void {
    this.cancelModal.emit();
  }

  setValues(): void {
    if (this.assignUserRoleModalInput) {
      this.areaText = this.assignUserRoleModalInput.areaText;
      this.users = this.assignUserRoleModalInput.users;
      this.roles = this.assignUserRoleModalInput.roles;
      this.translateKey = this.assignUserRoleModalInput.translateKey;
    }
    this.translateRoles();
  }

  translateRoles(): void {
    for (const role of this.roles) {
      role.nameTranslation = this.atsTranslationService.get('shared.roles.list.' + role.name);
    }
    this.roles = [...this.roles];
  }

  onSave(): void {
    this.saveModal.emit({
      id: this.currentUser,
      userId: this.currentUser,
      roleId: this.currentRole,
    } as AssignUserRoleCommand);
  }
}
