<div class="page-header wrap-reverse">
  <h2>{{ 'settings.infobarSettings.title' | translate }}</h2>
</div>
<app-infobar-settings
  [isLoaded]="isLoaded"
  [latestMessageObs]="latestMessageObs"
  [infoType]="viewModel.infoType"
  [minDate]="viewModel.minDate"
  [optionItems]="viewModel.optionItems"
  [expiryTime]="viewModel.expiryTime"
  [message]="viewModel.message"
  [dateFormat]="viewModel.calendarLocale?.dateFormat || ''"
  (save)="onSave($event)">
</app-infobar-settings>
