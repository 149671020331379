import { isDateString } from 'core/guards';
import { DateString } from 'core/models';
import { compareAsc } from 'date-fns';

export function addUnique<T>(list: T[], item: T): void {
  const index = list.indexOf(item, 0);

  if (index === -1) {
    list.push(item);
  }
}

export function getCircularItem<T>(list: T[], index: number): T | undefined {
  const length = list.length;
  if (length === 0) {
    return;
  }

  return list[Math.abs(index) % length];
}

export function removeConsecutiveDuplicates<T>(
  list: T[],
  equals: (value: T, compare: T) => boolean
): T[] {
  return list.filter((element, index, array) => index === 0 || !equals(element, array[index - 1]));
}

export function sortBy<T, R extends string | number | DateString>(
  items: T[],
  property: (item: T) => R
): T[] {
  if (items.length <= 1) {
    return items;
  }

  return items.sort((a, b) => {
    const valueA = property(a);
    const valueB = property(b);

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.localeCompare(valueB);
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return valueA > valueB ? 1 : -1;
    }

    if (isDateString(valueA) && isDateString(valueB)) {
      return compareAsc(new Date(valueA), new Date(valueB));
    }

    return 0;
  });
}
