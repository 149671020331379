import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { SessionService } from '../services/session.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private readonly router: Router, private readonly sessionService: SessionService) {}

  async canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    try {
      await this.sessionService.loginCompleted;

      if (this.sessionService.isUserAuthenticated() && this.sessionService.hasUser()) {
        const target = this.sessionService.getAndResetRedirect();
        if (target !== '') {
          return this.router.parseUrl(target);
        } else {
          return true;
        }
      } else if (this.sessionService.isUserAuthenticated() && !this.sessionService.hasUser()) {
        return this.router.parseUrl('register-user');
      } else {
        this.sessionService.login();
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
}
