import { ProcessChainGroupDto } from 'core/dtos';
import {
  EntityList,
  ProcessChainGroupModel,
  ProcessChainReducedModel,
  TableProcessChain,
} from 'core/models';

export function convertProcessChainGroupDtoToModel(
  groupDto: ProcessChainGroupDto,
  allProcessChains: TableProcessChain[],
  allMaps: EntityList[]
): ProcessChainGroupModel {
  const processChains: ProcessChainReducedModel[] = [];
  for (const pcId of groupDto.processChainIds) {
    const processChain = allProcessChains.find(p => p.id === pcId);
    if (processChain !== undefined) {
      processChains.push({
        id: processChain.id,
        name: processChain.name,
        mapId: processChain.mapId,
      });
    }
  }

  const processChainGroupModel: ProcessChainGroupModel = {
    id: groupDto.id,
    mapId: groupDto.mapId,
    mapName: allMaps.find(x => x.id === groupDto.mapId)?.name ?? '',
    workAreaId: groupDto.workAreaId,
    groupName: groupDto.name,
    processChains: processChains,
    executionMode: groupDto.executionMode,
  };
  return processChainGroupModel;
}

export function convertProcessChainGroupModelToDto(
  groupModel: ProcessChainGroupModel
): ProcessChainGroupDto {
  const groupDto: ProcessChainGroupDto = {
    id: groupModel.id,
    mapId: groupModel.mapId,
    name: groupModel.groupName,
    workAreaId: groupModel.workAreaId,
    processChainIds: groupModel.processChains.map(p => p.id),
    executionMode: groupModel.executionMode,
  };
  return groupDto;
}
