import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { OrganizationDto } from 'core/dtos';
import { GuidString } from 'core/models';
import * as OrganizationsActions from 'store/actions/organizations.actions';

export const featureKey = 'organizations';

export interface OrganizationsState extends EntityState<OrganizationDto> {
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
  selectedOrganizationId: GuidString;
}

export const organizationsAdapter: EntityAdapter<OrganizationDto> =
  createEntityAdapter<OrganizationDto>();

export const initialState: OrganizationsState = organizationsAdapter.getInitialState({
  loaded: false,
  loading: false,
  errorMessage: '',
  selectedOrganizationId: '',
});

const organizationsReducer = createReducer(
  initialState,

  on(OrganizationsActions.loadOrganizations, state => ({
    ...state,
    loaded: false,
    loading: true,
  })),

  on(OrganizationsActions.loadOrganizationsSuccess, (state, { organizations }) =>
    organizationsAdapter.setAll(organizations, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(OrganizationsActions.loadOrganizationsFailure, (state, { errorMessage }) => ({
    ...state,
    loaded: false,
    loading: false,
    errorMessage,
  })),

  on(OrganizationsActions.addOrganizationSuccess, (state, { organization }) =>
    organizationsAdapter.addOne(organization, {
      ...state,
    })
  ),

  on(OrganizationsActions.deleteOrganizationSuccess, (state, { organization }) =>
    organizationsAdapter.removeOne(organization.id.toString(), state)
  ),

  on(OrganizationsActions.updateOrganizationSuccess, (state, { organization }) =>
    organizationsAdapter.updateOne(
      { id: organization.id.toString(), changes: organization },
      {
        ...state,
      }
    )
  ),

  on(
    OrganizationsActions.addOrganizationFailure,
    OrganizationsActions.deleteOrganizationFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
    })
  ),

  on(OrganizationsActions.selectOrganization, (state, { organizationId }) => ({
    ...state,
    selectedOrganizationId: organizationId,
  }))
);

export function reducer(state: OrganizationsState | undefined, action: Action): OrganizationsState {
  return organizationsReducer(state, action);
}

export const { selectEntities, selectAll } = organizationsAdapter.getSelectors();

export const getOrganizationsLoading = (state: OrganizationsState): boolean => state.loading;
export const getOrganizationsLoaded = (state: OrganizationsState): boolean => state.loaded;
export const getOrganizationsErrorMessage = (state: OrganizationsState): string =>
  state.errorMessage;
export const getSelectedOrganizationId = (state: OrganizationsState): GuidString =>
  (state || initialState).selectedOrganizationId;
export const getOrganizationsEntities = selectEntities;
export const getAllOrganizations = selectAll;
