<app-user-list
  [menuItems]="menuItems"
  [searchTerm]="searchTerm$ | async"
  [users]="$workingAreaUsers | async"
  (selectedItem)="onSelectedItem($event)">
</app-user-list>

<app-assign-user-role-modal
  [assignUserRoleModalInput]="assignUserRoleModalInput"
  [isModalOpen]="isAssignUserModalOpen"
  (cancelModal)="cancelAssignUserModal()"
  (saveModal)="assignUserRole($event)">
</app-assign-user-role-modal>

<app-user-details-dialog
  [editEnabled]="canAddWorkingAreaUser"
  [isModalOpen]="isModalOpen"
  [userDetailsData]="userDetailsData"
  (dismissModal)="onCloseModal()">
</app-user-details-dialog>
