<ngx-loading-bar
  data-cy="loadingBar"
  height="5px"
  [includeSpinner]="false"
  color=""></ngx-loading-bar>

<app-confirm-modal></app-confirm-modal>

<header>
  <app-header-bar
    [closePanel]="closeNotificationPanelFlag"
    (openNotificationPanel)="onReceiveOpenNotificationPanel($event)"></app-header-bar>
</header>

<div class="container-fluid g-0 stretch-height">
  <div class="row stretch-height">
    <aside class="col" style="max-width: 40px">
      <app-sidemenu class="pt-10x"></app-sidemenu>
    </aside>
    <div class="col layout-container">
      <app-information-bar></app-information-bar>
      <main class="main" [class.main-content-open]="openNotificationPanelFlag">
        <router-outlet></router-outlet>
      </main>
      <app-notification-panel-container
        *ngIf="openNotificationPanelFlag"
        [openPanel]="openNotificationPanelFlag"
        (sendClosePanel)="onSendClosePanel($event)"></app-notification-panel-container>

      <footer *ngIf="showFooter" [class.footer-content-open]="openNotificationPanelFlag">
        <app-edit-bar [openPanel]="openNotificationPanelFlag"></app-edit-bar>
      </footer>
    </div>
  </div>
</div>
