import { Pipe, PipeTransform } from '@angular/core';
import { SortOrder } from 'library/models';

@Pipe({
  name: 'sortDropdown',
})
export class SortDropdownPipe<T> implements PipeTransform {
  transform(
    options: T[] | undefined | null,
    sortBy = 'name',
    sortOrder: SortOrder = SortOrder.Asc
  ): T[] | undefined {
    if (options) {
      return options.sort(
        (a, b) =>
          sortOrder * String(a[sortBy]).localeCompare(b[sortBy], undefined, { numeric: true })
      );
    } else {
      return undefined;
    }
  }
}
