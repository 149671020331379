/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  FailuresService,
  MissionTraceService,
  TourChainService,
  TourRetryService,
} from 'core/api-services';
import { MissionCommentInputModel } from 'core/models';
import { TenantRouterService } from 'core/services/tenant-router.service';
import { ToastService } from 'core/services/toast.service';

import { forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import * as MissionTraceActions from '../actions/mission-trace.actions';

@Injectable()
export class MissionTraceEffects {
  loadMissionTraces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.loadMissionTraces),
      concatMap(() =>
        this.missionTraceService.getAllActiveMissionTraces().pipe(
          map(activeMissionTraces => {
            return MissionTraceActions.loadMissionTracesSuccess({
              missionTrace: activeMissionTraces,
            });
          })
        )
      ),
      catchError(errorMessage => of(MissionTraceActions.loadMissionTracesFailure({ errorMessage })))
    )
  );

  loadMissionTraceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MissionTraceActions.loadMissionTracesFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.jobs.actions.loadActiveMissionsFailure');
        })
      ),
    { dispatch: false }
  );

  loadActiveTours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.loadMissionTracesSuccess),
      concatMap(() =>
        this.tourChainService.getAllActiveTours().pipe(
          map(missionTrace => MissionTraceActions.loadActiveToursSuccess({ missionTrace })),
          catchError(errorMessage =>
            of(MissionTraceActions.loadMissionTracesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadMissionTraceById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.loadMissionTraceById),
      concatMap(payload =>
        this.missionTraceService.getMissionTraceById(payload.missionTraceId).pipe(
          map(missionTrace => MissionTraceActions.loadMissionTraceByIdSuccess({ missionTrace })),
          catchError(errorMessage =>
            of(MissionTraceActions.loadMissionTraceByIdFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadFailureCommentsByIdList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.loadFailureCommentsByIdList),
      concatMap(payload =>
        this.failuresService.getFailureCommentsByTraceIdList(payload.idList).pipe(
          map(failureComments =>
            MissionTraceActions.loadFailureCommentsByIdListSuccess({ failureComments })
          ),
          catchError(errorMessage =>
            of(MissionTraceActions.loadFailureCommentsByIdListFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadAndSelectMissionTraceById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.loadAndSelectMissionTraceById),
      concatMap(payload =>
        this.missionTraceService.getMissionTraceById(payload.missionTraceId).pipe(
          map(missionTrace =>
            MissionTraceActions.loadAndSelectMissionTraceByIdSuccess({ missionTrace })
          ),
          catchError(errorMessage =>
            of(MissionTraceActions.loadAndSelectMissionTraceByIdFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadAndSelectMissionTraceByIdFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MissionTraceActions.loadAndSelectMissionTraceByIdFailure),
        tap(() => {
          this.toastService.createErrorToast('jobs.missionActive.loadMissionByIdFailure');
          this.router.navigate(['/jobs/missions/notFound']).catch(() => {});
        })
      ),
    { dispatch: false }
  );

  retryMission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.retryMission),
      concatMap(({ missionTraceId }) =>
        this.missionTraceService.retryMission(missionTraceId).pipe(
          map(() => MissionTraceActions.retryMissionSuccess({ missionTraceId })),
          catchError(errorMessage => of(MissionTraceActions.retryMissionFailure({ errorMessage })))
        )
      )
    )
  );

  retryMissionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MissionTraceActions.retryMissionSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.retryMissionSuccess');
        })
      ),
    { dispatch: false }
  );

  retryMissionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MissionTraceActions.retryMissionFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.retryMissionFailed');
        })
      ),
    { dispatch: false }
  );

  retryTour$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.retryTour),
      concatMap(({ tourId }) => {
        return this.tourRetryService.retryTour(tourId).pipe(
          map(() => MissionTraceActions.retryTourSuccess({ tourId })),
          catchError(errorMessage => of(MissionTraceActions.retryTourFailure({ errorMessage })))
        );
      })
    )
  );

  retryTourSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MissionTraceActions.retryTourSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.retryMissionSuccess');
        })
      ),
    { dispatch: false }
  );

  retryTourFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MissionTraceActions.retryTourFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.retryMissionFailed');
        })
      ),
    { dispatch: false }
  );

  loadMissionFailureReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.loadMissionFailureReasons),
      concatMap(() =>
        this.failuresService.getAllMissionFailureReasons().pipe(
          map(missionFailureReasons => {
            return MissionTraceActions.loadMissionFailureReasonsSuccess({ missionFailureReasons });
          }),
          catchError(errorMessage =>
            of(MissionTraceActions.loadMissionFailureReasonsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadMissionFailureReasonsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MissionTraceActions.loadMissionFailureReasonsFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'shared.jobs.actions.loadMissionFailureReasonsFailure'
          );
        })
      ),
    { dispatch: false }
  );

  hasActiveMissionsByMapId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.hasActiveMissionsByMapId),
      concatMap(state =>
        this.missionTraceService.hasActiveMissionsByMapId(state.mapId).pipe(
          map(action =>
            MissionTraceActions.hasActiveMissionsByMapIdSuccess({
              hasActiveMissions: action,
            })
          ),
          catchError(errorMessage =>
            of(MissionTraceActions.hasActiveMissionsByMapIdFailure({ errorMessage }))
          )
        )
      )
    )
  );

  addCommentToMission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionTraceActions.addCommentToMission),
      map(action => action.missionCommentInputModel),
      concatMap((payload: MissionCommentInputModel) => {
        const archiveCreateCommentForMission$ = this.failuresService.createCommentForMission(
          payload.missionTraceId,
          payload.missionComment
        );

        return forkJoin([archiveCreateCommentForMission$]).pipe(
          switchMap(() => [
            MissionTraceActions.addCommentToMissionSuccess({
              missionComment: payload.missionComment,
            }),
            MissionTraceActions.loadMissionTraceById({
              missionTraceId: payload.missionTraceId,
            }),
            MissionTraceActions.loadMissionTraces(),
          ]),
          catchError(errorMessage =>
            of(MissionTraceActions.addCommentToMissionFailure({ errorMessage }))
          )
        );
      })
    )
  );

  addCommentToMissionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MissionTraceActions.addCommentToMissionSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.missionComment.commentAddedSuccess');
        })
      ),
    { dispatch: false }
  );

  addCommentToMissionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MissionTraceActions.addCommentToMissionFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.missionComment.commentAddedFailure');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly missionTraceService: MissionTraceService,
    private readonly failuresService: FailuresService,
    private readonly tourChainService: TourChainService,
    private readonly toastService: ToastService,
    private readonly router: TenantRouterService,
    private readonly tourRetryService: TourRetryService
  ) {}
}
