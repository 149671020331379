// Also defines the Layer Order
export enum LayerViewType {
  NavigationLayers = 0,
  GppLayer,
  Background,
  PillarsGrid,
  Zones,
  GraphLayer,
  GraphLayer_RouteConfig,
  Pois,
  Vehicle_UnitLoad,
  Vehicle_TuggerTrain,
  Vehicle_Forklift,
  Vehicle_UAGV,
  CollisionPoints,
  ZoneCollisionPoints,
  ZonesEdit,
  GraphLayer_PoiPath,
}

export enum ImageLayers {
  Base = 'base', // Navigation Layers
  Background = 'background',
}

export enum LayerPivot {
  Top,
  Bottom,
}
