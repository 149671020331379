import { Injectable } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { ROUTER_DATA_BREADCRUMB } from 'core/constants';
import { Breadcrumb } from 'core/models';

export const routerFeatureKey = 'routerReducer';
export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: Params;
  breadcrumbs: Breadcrumb[];
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    let route = routerState.root;
    let breadcrumbUrl = '';

    const allParams = {};
    const allQueryParams = {};
    const allData = {};
    const breadcrumbs: Breadcrumb[] = [];

    while (route.firstChild) {
      Object.assign(allParams, route.params);
      Object.assign(allQueryParams, route.queryParams);
      Object.assign(allData, route.data);
      route = route.firstChild;

      const routeURL: string = route.url.map(segment => segment.path).join('/');

      if (routeURL !== '') {
        breadcrumbUrl += `/${routeURL}`;
      }

      const label = route.data[ROUTER_DATA_BREADCRUMB];
      if (label && !breadcrumbs.find(b => b.displayName === label)) {
        breadcrumbs.push({ displayName: label, url: breadcrumbUrl, data: route.data });
      }
    }

    Object.assign(allParams, route.params);
    Object.assign(allQueryParams, route.queryParams);
    Object.assign(allData, route.data);

    return {
      url,
      params: allParams,
      queryParams: allQueryParams,
      data: allData,
      breadcrumbs: breadcrumbs,
    };
  }
}
