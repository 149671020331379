import { createAction, props } from '@ngrx/store';
import { RouteConfigurationDto, RouteConfigurationRequest } from 'core/dtos';

export enum RouteConfigurationActionTypes {
  LoadConfigurations = '[GraphManager] Load Route Configurations',
  LoadRouteConfigurationsSuccess = '[GraphManager] Load Route Configurations Success',
  LoadRouteConfigurationsFailure = '[GraphManager] Load Route Configurations Failure',

  CreateRouteConfiguration = '[GraphManager] Create Route Configuration',
  CreateRouteConfigurationSuccess = '[GraphManager] Create Route Configuration Success',
  CreateRouteConfigurationFailure = '[GraphManager] Create Route Configuration Failure',

  UpdateRouteConfiguration = '[GraphManager] Update Route Configuration',
  UpdateRouteConfigurationSuccess = '[GraphManager] Update Route Configuration Success',
  UpdateRouteConfigurationFailure = '[GraphManager] Update Route Configuration Failure',

  UpdateBatchRouteConfiguration = '[GraphManager] Update Batch Route Configuration',
  UpdateBatchRouteConfigurationSuccess = '[GraphManager] Update Batch Route Configuration Success',
  UpdateBatchRouteConfigurationFailure = '[GraphManager] Update Batch Route Configuration Failure',

  DeleteRouteConfiguration = '[GraphManager] Delete Route Configuration',
  DeleteRouteConfigurationSuccess = '[GraphManager] Delete Route Configuration Success',
  DeleteRouteConfigurationFailure = '[GraphManager] Delete Route Configuration Failure',
}
export const loadRouteConfigurations = createAction(
  RouteConfigurationActionTypes.LoadConfigurations
);

export const loadRouteConfigurationsSuccess = createAction(
  RouteConfigurationActionTypes.LoadRouteConfigurationsSuccess,
  props<{ routeConfigurations: RouteConfigurationDto[] }>()
);

export const loadRouteConfigurationsFailure = createAction(
  RouteConfigurationActionTypes.LoadRouteConfigurationsFailure,
  props<{ errorMessage: string }>()
);

export const createRouteConfiguration = createAction(
  RouteConfigurationActionTypes.CreateRouteConfiguration,
  props<{ routeConfig: RouteConfigurationRequest }>()
);

export const createRouteConfigurationSuccess = createAction(
  RouteConfigurationActionTypes.CreateRouteConfigurationSuccess,
  props<{ routeConfig: RouteConfigurationDto }>()
);

export const createRouteConfigurationFailure = createAction(
  RouteConfigurationActionTypes.CreateRouteConfigurationFailure,
  props<{ errorMessage: string }>()
);

export const updateRouteConfiguration = createAction(
  RouteConfigurationActionTypes.UpdateRouteConfiguration,
  props<{ routeConfig: RouteConfigurationRequest }>()
);

export const updateRouteConfigurationSuccess = createAction(
  RouteConfigurationActionTypes.UpdateRouteConfigurationSuccess,
  props<{ routeConfig: RouteConfigurationDto }>()
);

export const updateRouteConfigurationFailure = createAction(
  RouteConfigurationActionTypes.UpdateRouteConfigurationFailure,
  props<{ errorMessage: string }>()
);

export const updateBatchRouteConfiguration = createAction(
  RouteConfigurationActionTypes.UpdateBatchRouteConfiguration,
  props<{ routeConfigList: RouteConfigurationDto[] }>()
);

export const updateBatchRouteConfigurationSuccess = createAction(
  RouteConfigurationActionTypes.UpdateBatchRouteConfigurationSuccess,
  props<{ routeConfigList: RouteConfigurationDto[] }>()
);

export const updateBatchRouteConfigurationFailure = createAction(
  RouteConfigurationActionTypes.UpdateBatchRouteConfigurationFailure,
  props<{ errorMessage: string }>()
);

export const deleteRouteConfiguration = createAction(
  RouteConfigurationActionTypes.DeleteRouteConfiguration,
  props<{ routeConfig: RouteConfigurationDto }>()
);

export const deleteRouteConfigurationSuccess = createAction(
  RouteConfigurationActionTypes.DeleteRouteConfigurationSuccess,
  props<{ routeConfig: RouteConfigurationDto }>()
);

export const deleteRouteConfigurationFailure = createAction(
  RouteConfigurationActionTypes.DeleteRouteConfigurationFailure,
  props<{ errorMessage: string }>()
);
