import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { MissionLog } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MissionLogService extends BaseHttpClient {
  private readonly servicePath = API_SERVICES.MissionTraceLogs;
  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getMissionLogByMissionId(missionId: GuidString): Observable<MissionLog[]> {
    return this.get<MissionLog[]>(`${this.servicePath}/${missionId}`);
  }

  getSecondsOfMissionInFailureState(missionId: GuidString): Observable<number> {
    return this.get<number>(`${this.servicePath}/failureStatusSeconds/${missionId}`);
  }
}
