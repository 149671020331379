/* eslint-disable max-lines */
import { EMPTY_GUID } from 'core/constants';
import {
  NodeType,
  OpcuaDeviceNodeTelemetryHistory,
  OpcuaDeviceResponseModel,
  OpcuaNodeStateDto,
  OpcuaStreamingServiceDto,
  SensorLookupMapping,
} from '../src/app/core/dtos';
import { OpcuaNodeState, OpcuaStreamingServiceType } from '../src/app/core/models';

export const streamingServices: OpcuaStreamingServiceDto[] = [
  {
    name: 'AMAStreamingService1',
    description: 'Ight, AMA head out!',
    telemetryTopic: 'Telemetry Topic',
    commandUpTopic: 'Command Up Topic',
    commandDownTopic: 'Command Down Topic',
    type: OpcuaStreamingServiceType.Opcua,
    devices: [
      {
        name: 'Device1',
        description: 'Device1',
        streamingServiceName: 'AMAStreamingService1',
        server: 'http://localhost',
        pingNodeName: 'ping1',
        workAreas: [
          {
            deviceName: 'Device1',
            workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
            workAreaName: 'wa',
          },
        ],
        akz: 'test1',
        lastPingReceivedDate: '2021-06-24T00:00:00.000Z',
        readonly: false,
        recipientKey: 'test',
        maxLatencyMs: 300,
        latencyMs: 400,
        nodes: [
          {
            device: 'Device1',
            name: 'Robot Move State',
            nodeId: 'ns=2;i=775',
            comment: 'Some comment',
            type: NodeType.Telemetry,
            subscriptionStatus: 'Pending',
            values: ['Stopped', 'Running', 'Error'],
            range: [],
            state: {
              name: 'Robot Move State',
              measurementDate: null,
              value: 'Stopped',
              rawValue: '',
            },
          },
          {
            device: 'Device1',
            name: 'Ping Node',
            nodeId: 'ns=1;i=123',
            comment: '',
            type: NodeType.Write,
            subscriptionStatus: 'Pending',
            values: ['Ping', 'Pong'],
            range: [],
            state: {
              name: 'Ping Node',
              measurementDate: null,
              value: null,
              rawValue: '',
            },
          },
          {
            device: 'Device1',
            name: 'Evacuation Mode Indicator 1',
            nodeId: 'ns=1;i=456',
            comment: '',
            type: NodeType.Write,
            subscriptionStatus: 'Pending',
            values: ['On', 'Off'],
            range: [],
            state: {
              name: 'Evacuation Mode Indicator 1',
              measurementDate: null,
              value: null,
              rawValue: '',
            },
          },
          {
            device: 'Device1',
            name: 'Evacuation Mode Indicator 2',
            nodeId: 'ns=1;i=789',
            comment: '',
            type: NodeType.Write,
            subscriptionStatus: 'Pending',
            values: ['On', 'Off'],
            range: [],
            state: {
              name: 'Evacuation Mode Indicator 2',
              measurementDate: null,
              value: null,
              rawValue: '',
            },
          },
        ],
        notifications: [
          {
            device: 'Device1',
            node: 'Robot Move State',
            value: 'Stopped',
            enabled: true,
            recipientKey: '',
          },
          {
            device: 'Device1',
            node: 'Robot Move State',
            value: 'Running',
            enabled: false,
            recipientKey: '',
          },
        ],
      },
      {
        name: 'Device2',
        description: 'Device2',
        streamingServiceName: 'AMAStreamingService1',
        server: 'http://localhost',
        pingNodeName: 'ping2',
        akz: 'test2',
        lastPingReceivedDate: null,
        readonly: false,
        recipientKey: '',
        maxLatencyMs: null,
        nodes: [
          {
            device: 'Device2',
            name: 'Robot Move State',
            nodeId: 'ns=2;i=775',
            comment: '',
            type: NodeType.Telemetry,
            subscriptionStatus: 'Pending',
            values: ['AMAStreamingService1'],
            range: [],
            state: {
              name: 'Robot Move State',
              measurementDate: null,
              value: null,
              rawValue: '',
            },
          },
          {
            device: 'Device2',
            name: 'Ping Node',
            nodeId: 'ns=12;i=103',
            comment: '',
            type: NodeType.Write,
            subscriptionStatus: 'Pending',
            values: ['Ping', 'Pong'],
            range: [],
            state: {
              name: 'Ping Node',
              measurementDate: null,
              value: null,
              rawValue: '',
            },
          },
        ],
        notifications: [],
        workAreas: [
          {
            deviceName: 'Device2',
            workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
            workAreaName: 'wa',
          },
        ],
      },
    ],
  },
  {
    name: 'StreamingService2',
    description: 'StreamingService2',
    telemetryTopic: 'telemetryTopic2',
    commandUpTopic: 'commandUpTopic2',
    commandDownTopic: 'commandDownTopic2',
    type: OpcuaStreamingServiceType.Opcua,
    devices: [
      {
        name: 'Device3',
        description: 'Device3',
        streamingServiceName: 'StreamingService2',
        server: 'http://localhost',
        pingNodeName: 'ping3',
        akz: 'test3',
        lastPingReceivedDate: null,
        readonly: true,
        recipientKey: '',
        maxLatencyMs: null,
        nodes: [
          {
            device: 'Device3',
            name: 'Robot Move State',
            nodeId: 'ns=2;i=775',
            comment: '',
            type: NodeType.Telemetry,
            subscriptionStatus: 'Pending',
            values: ['StreamingService2'],
            range: [],
            state: {
              name: 'Robot Move State',
              measurementDate: null,
              value: null,
              rawValue: '',
            },
          },
        ],
        notifications: [],
        workAreas: [
          {
            deviceName: 'Device3',
            workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
            workAreaName: 'wa',
          },
        ],
      },
    ],
  },
];

export const deviceNodesStateHistories: OpcuaDeviceNodeTelemetryHistory[] = [
  {
    streamingService: 'AMAStreamingService1',
    opcUaEndpointUri: 'Ight, AMA head out!',
    device: 'Device1',
    nodeName: 'Robot Move State',
    nodeId: 'ns=2;i=775',
    value: '10',
    dataType: 'string',
    nodeType: NodeType.Telemetry,
    status: 'Good',
    lastChangeDate: '2021-04-28T15:30:00.511Z',
    receivedDate: '2021-04-28T15:30:00.511Z',
    measurementDate: '2021-04-28T15:30:00.511Z',
  },
  {
    streamingService: 'AMAStreamingService1',
    opcUaEndpointUri: 'Ight, AMA head out!',
    device: 'Device1',
    nodeName: 'Robot Stop State',
    nodeId: 'ns=2;i=773',
    value: '10',
    dataType: 'string',
    nodeType: NodeType.None,
    status: 'Good',
    lastChangeDate: '2021-04-28T15:30:00.511Z',
    receivedDate: '2021-04-28T15:30:00.511Z',
    measurementDate: '2021-04-28T15:30:00.511Z',
  },
  {
    streamingService: 'AMAStreamingService1',
    opcUaEndpointUri: 'Ight, AMA head out!',
    device: 'Device1',
    nodeName: 'Robot Stop State',
    nodeId: 'ns=3;i=771',
    value: '10',
    dataType: 'Write',
    nodeType: NodeType.Write,
    status: 'Good',
    lastChangeDate: null,
    receivedDate: '2021-04-28T15:30:00.511Z',
    measurementDate: '2021-04-28T15:30:00.511Z',
  },
];

export const deviceResponseModels: OpcuaDeviceResponseModel[] = [
  {
    name: 'AMA',
    description: '',
    server: '',
    workAreas: [],
    streamingServiceName: 'streaming service 1',
    pingNodeName: '',
    akz: '',
    lastPingReceivedDate: null,
    nodes: [],
    notifications: [],
    readonly: false,
    recipientKey: '',
    maxLatencyMs: null,
  },
];

export const opcuaNodeState: OpcuaNodeStateDto[] = [
  {
    name: 'node 1',
    measurementDate: '2021-04-28T15:30:00.511Z',
    value: 'true',
    rawValue: 'true',
  },
  {
    name: 'node 2',
    measurementDate: '2021-04-29T15:30:00.511Z',
    value: '1',
    rawValue: '2',
  },
];

export const nodeState: OpcuaNodeState[] = [
  {
    name: 'node 1',
    measurementDate: '2021-04-28T15:30:00.511Z',
    value: 'true',
    rawValue: 'true',
    comment: '',
  },
  {
    name: 'node 2',
    measurementDate: '2021-04-29T15:30:00.511Z',
    value: '1',
    rawValue: '2',
    comment: '',
  },
];

export const sensorMappingData: SensorLookupMapping[] = [
  {
    device: 'test device',
    sourceBin: 'test source',
    destinationBin: 'test destination',
    sensorNodeName: 'test name',
    workAreaId: EMPTY_GUID,
  },
];
