import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadTypeConfigurationDto, SignalRNextMessage } from 'core/models';
import { Subject } from 'rxjs';
import { SettingsState } from 'store-modules/settings-store/reducers/settings.reducer';
import { SignalRNextService } from '../signalr-next.service';
import { SignalrRoutes } from '../signalr-routes';
import { loadTypeMessageReceived } from '../store/actions/signalr.actions';

export interface LoadTypeSignalRSubscriber {
  joinLoadTypes(): Promise<void>;
}

@Injectable({
  providedIn: 'root',
})
export class LoadTypeSignalRService {
  loadTypeMessageReceived = new Subject<SignalRNextMessage<LoadTypeConfigurationDto>>();

  constructor(
    private readonly signalRNextService: SignalRNextService,
    private readonly store: Store<SettingsState>
  ) {
    this.registerConnections();
  }

  signalrSubscriberFactory(componentName: string): LoadTypeSignalRSubscriber {
    const joinLoadTypes = (): Promise<void> => {
      return this.signalRNextService.joinGroup(SignalrRoutes.LoadType, componentName);
    };

    return {
      joinLoadTypes,
    };
  }

  protected registerConnections(): void {
    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.LoadType,
      this.loadTypeMessageReceived
    );

    this.loadTypeMessageReceived.subscribe(loadTypeMessage => {
      this.store.dispatch(loadTypeMessageReceived({ loadTypeMessage }));
    });
  }

  getConnectionId(): string | null {
    return this.signalRNextService.getConnectionId();
  }
}
