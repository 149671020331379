import { createAction, props } from '@ngrx/store';
import { FailureCommentDto, MissionFailureReasonDto } from 'core/dtos';
import { GuidString, MissionCommentInputModel, MissionTrace, VehicleType } from 'core/models';
import { MissionFilterModel } from '../../../modules/jobs/mission-monitoring/models/mission.models';

export enum MissionTraceTypes {
  LoadMissionTraces = '[Monitoring] Load Mission Traces',
  LoadMissionTracesSuccess = '[Monitoring] Load Mission Traces Success',
  LoadMissionTracesFailure = '[Monitoring] Load Mission Traces Failure',
  LoadMissionTraceById = '[Monitoring] Load Mission Trace By Id',
  LoadMissionTraceByIdSuccess = '[Monitoring] Load Mission Trace By Id Success',
  LoadMissionTraceByIdFailure = '[Monitoring] Load Mission Trace By Id Failure',
  loadFailureCommentsByIdList = '[Monitoring] Load Failure Comments By Id List',
  loadFailureCommentsByIdListSuccess = '[Monitoring] Load Failure Comments By Id List Success',
  loadFailureCommentsByIdListFailure = '[Monitoring] Load Failure Comments By Id List Failure',
  LoadAndSelectMissionTraceById = '[Monitoring] Load And Select Mission Trace By Id',
  LoadAndSelectMissionTraceByIdSuccess = '[Monitoring] Load And Select Mission Trace By Id Success',
  LoadAndSelectMissionTraceByIdFailure = '[Monitoring] Load And Select Mission Trace By Id Failure',
  SelectMissionTrace = '[Monitoring] Select Mission Trace',
  RetryMission = '[Monitoring] Retry Mission',
  RetryMissionSuccess = '[Monitoring] Retry Mission Success',
  RetryMissionFailure = '[Monitoring] Retry Mission Failure',
  RetryTour = '[Monitoring] Retry Tour',
  RetryTourSuccess = '[Monitoring] Retry Tour Success',
  RetryTourFailure = '[Monitoring] Retry Tour Failure',
  LoadMissionFailureReasons = '[Monitoring] Load Mission Failure Reason',
  LoadMissionFailureReasonsSuccess = '[Monitoring] Load Mission Failure Reason Success',
  LoadMissionFailureReasonsFailure = '[Monitoring] Load Mission Failure Reason Failure',
  AddCommentToMission = '[Monitoring] Add Comment To Mission',
  AddCommentToMissionSuccess = '[Monitoring] Add Comment To Mission Success',
  AddCommentToMissionFailure = '[Monitoring] Add Comment To Mission Failure',
  RemoveCompletedMissionTrace = '[Monitoring] Remove Completed Mission Trace',
  SetHistoryMissionFilters = '[Monitoring] Set History Mission Filters',
  SetActiveMissionFilters = '[Monitoring] Set Active Mission Filters',
  SetSelectedVehicleTypesFilter = '[Monitoring] Set Selected Vehicle Types Filter',
  HasActiveMissionsByMapId = '[Monitoring] Has Activemissions By Map Id',
  HasActiveMissionsByMapIdSuccess = '[Monitoring] Has Activemissions By Map Id Success',
  HasActiveMissionsByMapIdFailure = '[Monitoring] Has Activemissions By Map Id Failure',

  SignalRAddMissionTrace = '[SignalR] Add Mission Trace',
  SignalRUpdateMissionTrace = '[SignalR] Update Mission Trace',
  SignalRDeleteMissionTrace = '[SignalR] Delete Mission Trace',

  LoadActiveTours = '[Monitoring] Load Active Tours',
  LoadActiveToursSuccess = '[Monitoring] Load Active Tours Success',

  SignalRUpdateMissionList = '[SignalR] Update Active Missions List',
  SignalRCreateMissionList = '[SignalR] Create Active Missions List',
}

export const loadMissionTraces = createAction(MissionTraceTypes.LoadMissionTraces);
export const loadActiveTours = createAction(MissionTraceTypes.LoadActiveTours);

export const loadMissionTracesSuccess = createAction(
  MissionTraceTypes.LoadMissionTracesSuccess,
  props<{ missionTrace: MissionTrace[] }>()
);

export const loadActiveToursSuccess = createAction(
  MissionTraceTypes.LoadActiveToursSuccess,
  props<{ missionTrace: MissionTrace[] }>()
);

export const loadMissionTracesFailure = createAction(
  MissionTraceTypes.LoadMissionTracesFailure,
  props<{ errorMessage: string }>()
);

export const loadMissionTraceById = createAction(
  MissionTraceTypes.LoadMissionTraceById,
  props<{ missionTraceId: GuidString }>()
);

export const loadMissionTraceByIdSuccess = createAction(
  MissionTraceTypes.LoadMissionTraceByIdSuccess,
  props<{ missionTrace: MissionTrace }>()
);

export const loadMissionTraceByIdFailure = createAction(
  MissionTraceTypes.LoadMissionTraceByIdFailure,
  props<{ errorMessage: string }>()
);

export const loadFailureCommentsByIdList = createAction(
  MissionTraceTypes.loadFailureCommentsByIdList,
  props<{ idList: GuidString[] }>()
);

export const loadFailureCommentsByIdListSuccess = createAction(
  MissionTraceTypes.loadFailureCommentsByIdListSuccess,
  props<{ failureComments: FailureCommentDto[] }>()
);

export const loadFailureCommentsByIdListFailure = createAction(
  MissionTraceTypes.loadFailureCommentsByIdListFailure,
  props<{ errorMessage: string }>()
);

export const loadAndSelectMissionTraceById = createAction(
  MissionTraceTypes.LoadAndSelectMissionTraceById,
  props<{ missionTraceId: GuidString }>()
);

export const loadAndSelectMissionTraceByIdSuccess = createAction(
  MissionTraceTypes.LoadAndSelectMissionTraceByIdSuccess,
  props<{ missionTrace: MissionTrace }>()
);

export const loadAndSelectMissionTraceByIdFailure = createAction(
  MissionTraceTypes.LoadAndSelectMissionTraceByIdFailure,
  props<{ errorMessage: string }>()
);

export const selectMissionTrace = createAction(
  MissionTraceTypes.SelectMissionTrace,
  props<{ missionTraceId: GuidString }>()
);

export const retryMission = createAction(
  MissionTraceTypes.RetryMission,
  props<{ missionTraceId: GuidString }>()
);

export const retryMissionSuccess = createAction(
  MissionTraceTypes.RetryMissionSuccess,
  props<{ missionTraceId: GuidString }>()
);

export const retryMissionFailure = createAction(
  MissionTraceTypes.RetryMissionFailure,
  props<{ errorMessage: string }>()
);

export const retryTour = createAction(MissionTraceTypes.RetryTour, props<{ tourId: GuidString }>());

export const retryTourSuccess = createAction(
  MissionTraceTypes.RetryTourSuccess,
  props<{ tourId: GuidString }>()
);

export const retryTourFailure = createAction(
  MissionTraceTypes.RetryTourFailure,
  props<{ errorMessage: string }>()
);

export const signalRAddMissionTrace = createAction(
  MissionTraceTypes.SignalRAddMissionTrace,
  props<{ missionTrace: MissionTrace }>()
);

export const signalRUpdateMissionTrace = createAction(
  MissionTraceTypes.SignalRUpdateMissionTrace,
  props<{ missionTrace: MissionTrace }>()
);

export const signalRDeleteMissionTrace = createAction(
  MissionTraceTypes.SignalRDeleteMissionTrace,
  props<{ missionTrace: MissionTrace }>()
);

export const removeCompletedMissionTrace = createAction(
  MissionTraceTypes.RemoveCompletedMissionTrace,
  props<{ missionTrace: MissionTrace }>()
);

export const loadMissionFailureReasons = createAction(MissionTraceTypes.LoadMissionFailureReasons);

export const loadMissionFailureReasonsSuccess = createAction(
  MissionTraceTypes.LoadMissionFailureReasonsSuccess,
  props<{ missionFailureReasons: MissionFailureReasonDto[] }>()
);

export const loadMissionFailureReasonsFailure = createAction(
  MissionTraceTypes.LoadMissionFailureReasonsFailure,
  props<{ errorMessage: string }>()
);

export const addCommentToMission = createAction(
  MissionTraceTypes.AddCommentToMission,
  props<{ missionCommentInputModel: MissionCommentInputModel }>()
);

export const addCommentToMissionSuccess = createAction(
  MissionTraceTypes.AddCommentToMissionSuccess,
  props<{ missionComment: FailureCommentDto }>()
);

export const addCommentToMissionFailure = createAction(
  MissionTraceTypes.AddCommentToMissionFailure,
  props<{ errorMessage: string }>()
);

export const setHistoryMissionFilters = createAction(
  // TODO remove
  MissionTraceTypes.SetHistoryMissionFilters,
  props<{ filterHistoryMission: MissionFilterModel }>()
);

export const setSelectedVehicleTypesFilter = createAction(
  MissionTraceTypes.SetSelectedVehicleTypesFilter,
  props<{ selectedVehicleTypesFilter: VehicleType[] }>()
);

export const setActiveMissionFilters = createAction(
  // TODO remove
  MissionTraceTypes.SetActiveMissionFilters,
  props<{ filterActiveMission: MissionFilterModel }>()
);

export const hasActiveMissionsByMapId = createAction(
  MissionTraceTypes.HasActiveMissionsByMapId,
  props<{ mapId: GuidString }>()
);

export const hasActiveMissionsByMapIdSuccess = createAction(
  MissionTraceTypes.HasActiveMissionsByMapIdSuccess,
  props<{ hasActiveMissions: boolean }>()
);

export const hasActiveMissionsByMapIdFailure = createAction(
  MissionTraceTypes.HasActiveMissionsByMapIdFailure,
  props<{ errorMessage: string }>()
);

export const loadOrganizationsFailure = createAction(
  MissionTraceTypes.HasActiveMissionsByMapIdFailure,
  props<{ errorMessage: string }>()
);
