import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { MissionTraceAbortOptionsDto, ProcessChainGroupTraceDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { ProcessChainGroupTraceSignalRService } from 'core/signalR/modules/process-chain-group-trace-signalr.service';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ProcessChainGroupTraceService extends TenantHttpClient {
  protected apiUrl = environment.Services.JobManager;
  private readonly servicePath = API_SERVICES.ProcessChainGroupTraces;

  constructor(
    httpClient: HttpClient,
    store: Store<RootState>,
    private readonly processChainGroupTraceSignalRService: ProcessChainGroupTraceSignalRService
  ) {
    super(httpClient, store);
  }

  getAllProcessChainGroupTraces(): Observable<ProcessChainGroupTraceDto[]> {
    return this.get<ProcessChainGroupTraceDto[]>(`${this.servicePath}/GetAll`);
  }

  abortProcessChainGroupTrace(
    id: GuidString,
    abortOptions: MissionTraceAbortOptionsDto
  ): Observable<void> {
    const connectionId = this.processChainGroupTraceSignalRService.getConnectionId();
    return this.post<void>(
      `${this.servicePath}/abortWithOptions/${id}/${connectionId}`,
      abortOptions
    );
  }
}
