import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngrx/store';
import { OpcuaDeviceSignalrService } from 'core/signalR/modules/opcua-device-signalr.service';
import { ensureStoreLoadedWithRefresh } from 'shared/helpers';
import * as fromOpcuaDevices from 'store-modules/opcua-devices-store';

@Injectable({
  providedIn: 'root',
})
export class OpcuaDevicesGuard implements CanActivate, CanActivateChild {
  readonly opcuaDeviceSignalrSubscriber;
  constructor(
    private readonly store: Store<fromOpcuaDevices.OpcuaDevicesFeatureState>,
    private readonly opcuaDeviceSignalRService: OpcuaDeviceSignalrService
  ) {
    this.opcuaDeviceSignalrSubscriber = this.opcuaDeviceSignalRService.signalrSubscriberFactory(
      OpcuaDevicesGuard.name
    );
  }

  async canActivate(): Promise<boolean> {
    ensureStoreLoadedWithRefresh(this.store, fromOpcuaDevices.loadOpcuaStreamingServices());

    await this.opcuaDeviceSignalrSubscriber.joinTelemetryGroup();
    await this.opcuaDeviceSignalrSubscriber.joinOnlineStatusGroup();
    await this.opcuaDeviceSignalrSubscriber.joinNotificationsGroup();

    return true;
  }

  async canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
