import { saveAs } from 'file-saver';
import { toJpeg } from 'html-to-image';

const emailHeader = `To:
Subject: Mission Reports Charts
X-Unsent: 1
Content-Type: text/html


<br><br><br><br><br><br><br>`;

export async function downloadChartImageToMemory(chart: HTMLElement): Promise<string> {
  return toJpeg(chart, { quality: 0.95, backgroundColor: '#ffffff' });
}

export function createEmail(charts: string[]): void {
  const chartString = charts
    .map(
      chart =>
        `<img src="${chart}" style="border-color: white; border-radius: 16px;" /><br><br><br><br>`
    )
    .join('');

  saveAs(
    new Blob([`${emailHeader}${chartString}`], { type: 'message/rfc822 eml;charset=utf-8' }),
    'reportsChartsEmail.eml'
  );
}

export const download = {
  downloadChartImageToMemory: (chart: HTMLElement): Promise<string> => {
    return toJpeg(chart, { quality: 0.95, backgroundColor: '#ffffff' });
  },

  createEmail: (charts: string[]): void => {
    const chartStr = charts
      .map(
        chart =>
          `<img src="${chart}" style="border-color: white; border-radius: 16px;" /><br><br><br><br>`
      )
      .join('');

    saveAs(
      new Blob([`${emailHeader}${chartStr}`], { type: 'message/rfc822 eml;charset=utf-8' }),
      'reportsChartsEmail.eml'
    );
  },
};
