import { createSelector } from '@ngrx/store';
import * as fromMonitoringFeatureState from '../reducers/index';
import * as fromMissionTrace from '../reducers/mission-trace.reducer';

const selectMissionTraceState = createSelector(
  fromMonitoringFeatureState.getMonitoringFeatureState,
  fromMonitoringFeatureState.getMissionTraceState
);

export const selectMissionTraceLoading = createSelector(
  selectMissionTraceState,
  fromMissionTrace.getLoading
);

export const selectMissionTraceLoaded = createSelector(
  selectMissionTraceState,
  fromMissionTrace.getLoaded
);

export const selectAllMissionTraces = createSelector(
  selectMissionTraceState,
  fromMissionTrace.getAllMissionTraces
);

export const selectMissionFilters = createSelector(
  selectMissionTraceState,
  fromMissionTrace.getMissionActiveFilters
);

export const selectSelectedMissionTraceId = createSelector(
  selectMissionTraceState,
  fromMissionTrace.getSelectedMissionTraceId
);

export const selectAllMissionFailureReasons = createSelector(
  selectMissionTraceState,
  fromMissionTrace.getAllMissionFailureReasons
);

export const selectFailureCommentsOfActiveMissionTraces = createSelector(
  selectMissionTraceState,
  fromMissionTrace.getFailureCommentsOfActiveMissionTraces
);

// export const selectMissionHistoryFilter = createSelector(
//   selectMissionTraceState,
//   fromMissionTrace.getMissionHistoryFilters
// );

export const selectSelectedVehicleTypesFilter = createSelector(
  selectMissionTraceState,
  fromMissionTrace.getSelectedVehicleTypesFilter
);

export const selectMissionActiveFilter = createSelector(
  selectMissionTraceState,
  fromMissionTrace.getMissionActiveFilters
);
