<div
  class="container-fluid"
  *ngIf="!filterVehicleTypesFlag || (filterVehicleTypesFlag && VehicleTypeEnums.length > 1)">
  <div class="row" data-cy="filterVehicleType">
    <div class="col me-4x mb-4x" *ngFor="let vehicleType of VehicleTypeEnums">
      <ds-box surfaceTone="neutral" [elevation]="selection.includes(vehicleType) ? 'base' : 'none'">
        <ds-box-content [isClickable]="true" (boxClick)="onCardClick(vehicleType)">
          <div class="vehicleFilterTypeContent">
            <div>
              <img
                class="vehicleTypeFilterImage {{ size }}"
                [src]="
                  './assets/global/vehicles/VehicleType/icon/' + VehicleType[vehicleType] + '.png'
                "
                alt="{{ vehicleType }}" />
              <label class="vehicleTypeFilterLabel">
                {{ vehicleType | enumTranslation : 'vehicleType' | uppercase }}
              </label>
            </div>

            <ds-form-field
              class="vehicleTypeFilterCheckbox"
              [attr.data-cy]="VehicleType[vehicleType] + 'FilterInput'">
              <ds-checkbox-group variant="stacked">
                <input
                  ds-input
                  type="checkbox"
                  size="lg"
                  [checked]="selection.includes(vehicleType)" />
              </ds-checkbox-group>
            </ds-form-field>
          </div>
        </ds-box-content>
      </ds-box>
    </div>
    <div class="col col-md-1 vehicleFilterTypeReset">
      <button
        *ngIf="showResetButton"
        ds-button
        data-cy="vehicleTypeFilterResetButton"
        [variant]="'ghost'"
        [icon]="'arrow_go_back'"
        class="ms-4x"
        (click)="resetSelection()">
        {{ 'shared.tableColumnActions.reset' | translate }}
      </button>
    </div>
  </div>
</div>
