import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SignalRNextMessage } from 'core/models';
import { Subject } from 'rxjs';
import { SignalRNextService } from '../signalr-next.service';
import { SignalrRoutes } from '../signalr-routes';
import {
  processChainGroupTraceMessageReceived,
  processChainTraceMessageReceived,
} from '../store/actions/signalr.actions';
import { ProcessChainGroupTraceDto, ProcessChainTraceInGroupDto } from 'core/dtos';
import { MonitoringFeatureState } from 'store-modules/mission-monitoring-store';
export interface ProcessChainGroupSignalrSubscriber {
  joinProcessChainGroupTraces(): Promise<void>;
  joinProcessChainTraces(): Promise<void>;
}
@Injectable({
  providedIn: 'root',
})
export class ProcessChainGroupTraceSignalRService {
  processChainGroupTraceMessageReceivedNext = new Subject<
    SignalRNextMessage<ProcessChainGroupTraceDto>
  >();
  processChainTraceMessageReceivedNext = new Subject<
    SignalRNextMessage<ProcessChainTraceInGroupDto>
  >();

  constructor(
    private readonly signalrNextService: SignalRNextService,
    private readonly store: Store<MonitoringFeatureState>
  ) {
    this.registerConnections();
  }

  signalrSubscriberFactory(componentName: string): ProcessChainGroupSignalrSubscriber {
    const joinProcessChainGroupTraces = (): Promise<void> => {
      return this.signalrNextService.joinGroup(
        SignalrRoutes.ProcessChainGroupTraces,
        componentName
      );
    };

    const joinProcessChainTraces = (): Promise<void> => {
      return this.signalrNextService.joinGroup(SignalrRoutes.ProcessChainTraces, componentName);
    };

    return { joinProcessChainGroupTraces, joinProcessChainTraces };
  }

  protected registerConnections(): void {
    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.ProcessChainGroupTraces,
      this.processChainGroupTraceMessageReceivedNext
    );

    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.ProcessChainTraces,
      this.processChainTraceMessageReceivedNext
    );

    this.processChainGroupTraceMessageReceivedNext.subscribe(processChainGroupTraceMessage => {
      this.store.dispatch(processChainGroupTraceMessageReceived({ processChainGroupTraceMessage }));
    });

    this.processChainTraceMessageReceivedNext.subscribe(processChainTraceMessage => {
      this.store.dispatch(processChainTraceMessageReceived({ processChainTraceMessage }));
    });
  }

  getConnectionId(): string | null {
    return this.signalrNextService.getConnectionId();
  }
}
