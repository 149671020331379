import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject, of, takeUntil } from 'rxjs';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-ats-welcome-page-layout',
  templateUrl: './ats-welcome-page-layout.component.html',
  styleUrls: ['./ats-welcome-page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AtsWelcomePageLayoutComponent implements OnInit, OnDestroy {
  @Output() readonly sendClosePanel = new EventEmitter<boolean>();
  showFooter = true;
  ngUnsubscribe = new Subject<void>();
  isEditMode = false;
  isEditMode$ = of(false);
  closeNotificationPanelFlag = false;
  openNotificationPanelFlag = false;
  disableNotificationPanel = false;

  constructor(
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {}

  ngOnInit(): void {
    this.setNavigationSettings(this.router.url);
    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.setNavigationSettings(value.url);
        this.cdRef.markForCheck();
      }
    });
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
    this.rootStore
      .pipe(select(fromRoot.selectIsEditMode), takeUntil(this.ngUnsubscribe))
      .subscribe(isEditMode => {
        this.isEditMode = isEditMode;
      });
  }

  setNavigationSettings(url: string): void {
    this.disableNotificationPanel = this.isWelcomePage(url);
    this.showFooter = !this.isWelcomePage(url);
  }

  isWelcomePage(url: string): boolean {
    return url.startsWith('/welcome');
  }

  onSendClosePanel(closePanel: boolean): void {
    this.closeNotificationPanelFlag = closePanel;
    this.sendClosePanel.emit(closePanel);
    this.cdRef.markForCheck();
  }

  onReceiveOpenNotificationPanel(openPanel: boolean): void {
    this.closeNotificationPanelFlag = false;
    this.openNotificationPanelFlag = openPanel;
    this.cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
