import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromSettings from 'store-modules/settings-store';
import { ensureStoreLoadedWithRefresh } from 'shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class ErrorForwardingGuard implements CanActivate {
  constructor(private readonly store: Store<fromSettings.SettingsFeatureState>) {}

  canActivate(): boolean {
    ensureStoreLoadedWithRefresh(this.store, fromSettings.loadErrorForwarding());
    return true;
  }
}
