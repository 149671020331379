import { createAction, props } from '@ngrx/store';
import { PillarsGridDto } from 'core/dtos';
import { GuidString } from 'core/models';

export enum PillarsGridTypes {
  LoadPillarsGridForMap = '[Pillars Grid] Load Pillars Grids For Map',
  LoadPillarsGridSuccess = '[Pillars Grid] Load Pillars Grid Success',
  LoadPillarsGridFailure = '[Pillars Grid] Load Pillars Grid Failure',

  CreatePillarsGrid = '[Pillars Grid] Save Pillars Grid',
  CreatePillarsGridSuccess = '[Pillars Grid] Save Pillars Grid Success',
  CreatePillarsGridFailure = '[Pillars Grid] Save Pillars Grid Failure',

  UpdatePillarsGrid = '[Pillars Grid] Edit Pillars Grid',
  UpdatePillarsGridSuccess = '[Pillars Grid] Edit Pillars Grid Success',
  UpdatePillarsGridFailure = '[Pillars Grid] Edit Pillars Grid Failure',

  DeletePillarsGrid = '[Pillars Grid] Delete Pillars Grid',
  DeletePillarsGridSuccess = '[Pillars Grid] Delete Pillars Grid Success',
  DeletePillarsGridFailure = '[Pillars Grid] Delete Pillars Grid Failure',
}

export const loadPillarsGridForMap = createAction(
  PillarsGridTypes.LoadPillarsGridForMap,
  props<{ mapId: GuidString }>()
);

export const loadPillarsGridSuccess = createAction(
  PillarsGridTypes.LoadPillarsGridSuccess,
  props<{ pillarsGrid: PillarsGridDto }>()
);

export const loadPillarsGridFailure = createAction(
  PillarsGridTypes.LoadPillarsGridFailure,
  props<{ errorMessage: string }>()
);

export const createPillarsGrid = createAction(
  PillarsGridTypes.CreatePillarsGrid,
  props<{ pillarsGrid: PillarsGridDto; mapId: GuidString }>()
);

export const createPillarsGridSuccess = createAction(
  PillarsGridTypes.CreatePillarsGridSuccess,
  props<{ pillarsGrid: PillarsGridDto }>()
);

export const createPillarsGridFailure = createAction(
  PillarsGridTypes.CreatePillarsGridFailure,
  props<{ errorMessage: string }>()
);

export const updatePillarsGrid = createAction(
  PillarsGridTypes.UpdatePillarsGrid,
  props<{ pillarsGrid: PillarsGridDto }>()
);

export const updatePillarsGridSuccess = createAction(
  PillarsGridTypes.UpdatePillarsGridSuccess,
  props<{ pillarsGrid: PillarsGridDto }>()
);

export const updatePillarsGridFailure = createAction(
  PillarsGridTypes.UpdatePillarsGridFailure,
  props<{ errorMessage: string }>()
);

export const deletePillarsGrid = createAction(
  PillarsGridTypes.DeletePillarsGrid,
  props<{ pillarsGrid: PillarsGridDto }>()
);

export const deletePillarsGridSuccess = createAction(
  PillarsGridTypes.DeletePillarsGridSuccess,
  props<{ pillarsGridId: GuidString }>()
);

export const deletePillarsGridFailure = createAction(
  PillarsGridTypes.DeletePillarsGridFailure,
  props<{ errorMessage: string }>()
);
