import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { MissionTraceDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { AdHocMissionDispatch, GuidString, MissionDispatch, Pose2D } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class MissionDispatchService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.Dispatcher;

  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  sendMission(missionDispatch: MissionDispatch): Observable<GuidString> {
    return this.post<GuidString>(
      `${this.servicePath}/vehicles/${missionDispatch.vehicleId}/mission/${missionDispatch.id}`
    );
  }

  sendAdHocMission(missionDispatch: AdHocMissionDispatch): Observable<GuidString> {
    return this.post<GuidString>(
      `${this.servicePath}/vehicle/${missionDispatch.vehicleId}/mission`,
      missionDispatch
    );
  }

  sendAdHocPose(
    vehicleId: GuidString,
    pose: Pose2D,
    mapId: GuidString
  ): Observable<MissionTraceDto> {
    return this.post<MissionTraceDto>(`${this.servicePath}/vehicle/${vehicleId}/pose`, {
      pose,
      mapId,
    });
  }
}
