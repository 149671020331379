<div class="row">
  <div class="col-sm-12 col-lg-12">
    <ds-form-field>
      <label ds-label for="fromDate">{{ 'shared.filters.from' | translate }}</label>
      <ds-datepicker [config]="datePickerConfig" [viewDate]="fromDate">
        <input ds-date ds-form-validation required id="fromDate" [(ngModel)]="fromDate" />
      </ds-datepicker>
    </ds-form-field>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-lg-12">
    <ds-form-field>
      <ds-timepicker [config]="timePickerConfig">
        <input ds-time required id="fromTime" [(ngModel)]="fromTime" />
      </ds-timepicker>
    </ds-form-field>
  </div>
</div>
<div *ngIf="fromDatetimeIsInValid === true" class="row me-2x mt-2x">
  <div class="col-sm-12 col-lg-12 invalid-input-text">
    <span data-cy="shutdownDateInvalid">
      {{ 'shared.filters.validation.invalidDatetime' | translate }}
    </span>
  </div>
</div>
<br />
<div class="row">
  <div class="col-sm-12 col-lg-12">
    <ds-form-field>
      <label ds-label for="toDate">{{ 'shared.filters.to' | translate }}</label>
      <ds-datepicker [config]="datePickerConfig" [viewDate]="toDate">
        <input ds-date ds-form-validation required id="toDate" [(ngModel)]="toDate" />
      </ds-datepicker>
    </ds-form-field>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-lg-12">
    <ds-form-field>
      <ds-timepicker [config]="timePickerConfig">
        <input ds-time required id="toTime" [(ngModel)]="toTime" />
      </ds-timepicker>
    </ds-form-field>
  </div>
</div>
<div *ngIf="toDatetimeIsInValid === true" class="row me-2x mt-2x">
  <div class="col-sm-12 col-lg-12 invalid-input-text">
    <span data-cy="shutdownDateInvalid">
      {{ 'shared.filters.validation.invalidDatetime' | translate }}
    </span>
  </div>
</div>
<div class="row mt-2x">
  <button
    #btn
    cy-data="apply-filter-button"
    class="col-sm-6 col-lg-6"
    ds-button
    [variant]="'outline'"
    (click)="updateFilter()">
    {{ 'shared.filters.applyButton' | translate }}
  </button>
  <button
    #btn
    cy-data="clear-filter-button"
    class="col-sm-6 col-lg-6"
    ds-button
    [variant]="'outline'"
    (click)="clearFilter()">
    {{ 'shared.filters.clearButton' | translate }}
  </button>
</div>
<br />
