import { Pipe, PipeTransform } from '@angular/core';
import { AtsTranslationService } from 'core/services';
import { formatDistance } from 'date-fns';
import { de, enGB } from 'date-fns/esm/locale';

const locale = {
  de: de,
  en: enGB,
};

@Pipe({
  name: 'formatDateNow',
  pure: false,
})
export class FormatDateNowPipe implements PipeTransform {
  constructor(private readonly translateService: AtsTranslationService) {}

  transform(value: number | string | null | undefined): string {
    if (!value) {
      return '';
    }

    return formatDistance(new Date(value), new Date(), {
      addSuffix: true,
      locale: locale[this.translateService.currentLang],
    });
  }
}
