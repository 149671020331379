import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromErrorForwarding from './error-forwarding.reducer';
import * as fromRoles from './role.reducer';
import * as fromSettings from './settings.reducer';

export const featureKey = 'settingsFeature';

export interface SettingsFeatureState {
  [fromSettings.featureKey]: fromSettings.SettingsState;
  [fromErrorForwarding.featureKey]: fromErrorForwarding.ErrorForwardingState;
  [fromRoles.featureKey]: fromRoles.RoleState;
}

export function reducers(
  state: SettingsFeatureState | undefined,
  action: Action
): SettingsFeatureState {
  return combineReducers({
    [fromSettings.featureKey]: fromSettings.reducer,
    [fromErrorForwarding.featureKey]: fromErrorForwarding.reducer,
    [fromRoles.featureKey]: fromRoles.reducer,
  })(state, action);
}

export const getSettingsState = (state: SettingsFeatureState): fromSettings.SettingsState =>
  state.settings;
export const getErrorForwardingState = (
  state: SettingsFeatureState
): fromErrorForwarding.ErrorForwardingState => state.errorForwarding;
export const getRolesState = (state: SettingsFeatureState): fromRoles.RoleState => state.role;
export const getSettingsFeatureState = createFeatureSelector<SettingsFeatureState>(featureKey);
