import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  MissionTraceAbortDto,
  MissionTraceAbortOptionsDto,
  MissionTraceDto,
  MissionTracesAbortRequestDto,
} from 'core/dtos';
import { convertMissionTraceDtoToModel, mapMissionTraceDtosToModels } from 'core/helpers';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, MissionTrace } from 'core/models';
import { MissionTraceSignalRService } from 'core/signalR/modules/mission-trace-signalr.service';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class MissionTraceService extends TenantHttpClient {
  protected apiUrl = environment.Services.JobManager;
  private readonly servicePath = API_SERVICES.MissionTrace;

  constructor(
    httpClient: HttpClient,
    store: Store<RootState>,
    private readonly missionTraceSignalRService: MissionTraceSignalRService
  ) {
    super(httpClient, store);
  }

  getAllActiveMissionTraces(): Observable<MissionTrace[]> {
    return this.get<MissionTraceDto[]>(`${this.servicePath}/active`).pipe(
      map(missionTraceDtos => mapMissionTraceDtosToModels(missionTraceDtos))
    );
  }

  hasActiveMissionsByMapId(id: GuidString): Observable<boolean> {
    return this.get<boolean>(`${this.servicePath}/hasActiveMissions/${id}`);
  }

  getMissionTraceById(id: GuidString): Observable<MissionTrace> {
    return this.get<MissionTraceDto>(`${this.servicePath}/${id}`).pipe(
      map(missionTraceDto => convertMissionTraceDtoToModel(missionTraceDto))
    );
  }

  abortMission(
    id: GuidString,
    abortOptions: MissionTraceAbortOptionsDto
  ): Observable<MissionTraceAbortDto> {
    const connectionId = this.missionTraceSignalRService.getConnectionId();
    return this.post<void>(
      `${this.servicePath}/abortWithOptions/${id}/${connectionId}`,
      abortOptions
    ).pipe(
      mergeMap(() =>
        this.missionTraceSignalRService.missionTraceAbortMessageReceivedNext.pipe(
          take(1),
          map(m => {
            return m.payload;
          })
        )
      )
    );
  }

  retryMission(id: GuidString): Observable<void> {
    return this.post<void>(`${this.servicePath}/retryMissionWithLastStep/${id}`);
  }

  reassignMission(missionTraceId: GuidString): Observable<void> {
    return this.post<void>(`${this.servicePath}/reassign/${missionTraceId}`);
  }

  continueFromStep(missionTraceId: GuidString, stepNumber: number): Observable<void> {
    return this.post<void>(
      `${this.servicePath}/continueFromStep/` + missionTraceId,
      Object(stepNumber)
    );
  }

  massAbort(missions: MissionTracesAbortRequestDto): Observable<void> {
    const connectionId = this.missionTraceSignalRService.getConnectionId() ?? '';
    missions.replyConnectionId = connectionId;
    return this.post<void>(`${this.servicePath}/abort`, missions);
  }
}
