<form [formGroup]="form" class="three-columns">
  <div>
    <ds-form-field>
      <label ds-label for="url">{{ 'settings.ipst.url' | translate }}</label>
      <input
        ds-input
        id="url"
        type="text"
        required="true"
        placeholder="{{ 'placeholders.define' | translate }}"
        formControlName="url"
        data-cy="urlInput" />
    </ds-form-field>
    <div
      *ngIf="ipstFormDetails?.url?.touched && ipstFormDetails?.url?.invalid"
      class="invalid-input-text">
      <div data-cy="requiredUrl" *ngIf="ipstFormDetails?.url?.errors?.required">
        {{ 'settings.ipst.urlRequired' | translate }}
      </div>
      <div data-cy="invalidUrl" *ngIf="ipstFormDetails?.url?.errors?.pattern">
        {{ 'settings.ipst.urlInvalid' | translate }}
      </div>
    </div>
  </div>
  <div>
    <ds-form-field>
      <label ds-label for="user">{{ 'settings.ipst.user' | translate }}</label>
      <input
        ds-input
        id="user"
        type="text"
        required="true"
        placeholder="{{ 'placeholders.define' | translate }}"
        formControlName="user"
        data-cy="userInput" />
    </ds-form-field>
    <div class="invalid-input-text">
      <div *ngIf="ipstFormDetails?.user?.errors?.required" data-cy="invalidUser">
        {{ 'settings.ipst.userRequired' | translate }}
      </div>
    </div>
  </div>
  <div>
    <ds-form-field>
      <label ds-label for="password">{{ 'settings.ipst.password' | translate }}</label>
      <input
        ds-input
        id="password"
        type="text"
        required="true"
        placeholder="{{ 'placeholders.define' | translate }}"
        formControlName="password"
        data-cy="passwordInput"
        autocomplete="off" />
    </ds-form-field>
    <div *ngIf="ipstFormDetails?.password?.touched" class="invalid-input-text">
      <div *ngIf="ipstFormDetails?.password?.errors?.required">
        {{ 'settings.ipst.passwordRequired' | translate }}
      </div>
    </div>
  </div>
</form>

<hr />
