import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { IntersectionZoneVehiclePriorityDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { firstValueFrom, Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class IntersectionZoneVehiclePriorityService extends TenantHttpClient {
  protected apiUrl = environment.Services.TrafficManager;
  private readonly servicePath = API_SERVICES.IntersectionZoneVehiclePriority;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getIntersectionZonePriorityVehicles(
    masterZoneId: GuidString
  ): Observable<IntersectionZoneVehiclePriorityDto[]> {
    return this.get<IntersectionZoneVehiclePriorityDto[]>(
      `${this.servicePath}/masterZone/${masterZoneId}`
    );
  }

  async setUserTriggeredPriority(masterZoneId: GuidString, vehicleId: GuidString): Promise<void> {
    return firstValueFrom(
      this.post<void>(
        `${this.servicePath}/masterZone/${masterZoneId.toString()}/vehicle/${vehicleId.toString()}`,
        {}
      )
    );
  }
}
