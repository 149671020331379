import { createSelector } from '@ngrx/store';
import { EMPTY_GUID } from 'core/constants';
import { MapVehicle, VehicleDto } from 'core/dtos';
import { GuidString, VehicleType } from 'core/models';
import { selectAllActiveVehicles } from './vehicles.selectors';

import * as fromMapStore from 'store-modules/maps-store';
import { selectFactsheetDimensions } from './factsheet.selectors';

const checkNoMap = (vehicle: VehicleDto) => vehicle.vehicleType === VehicleType.Forklift;

const checkWithMap = (vehicle: VehicleDto, mapId: GuidString) =>
  (vehicle.vehicleType === VehicleType.UnitLoad || vehicle.vehicleType === VehicleType.U_AGV) &&
  vehicle?.map?.id === mapId;

const checkWithMapOrEmptyId = (vehicle: VehicleDto, mapId: GuidString) =>
  vehicle.vehicleType === VehicleType.TuggerTrain &&
  (vehicle?.map?.id === mapId || vehicle?.map?.id === EMPTY_GUID);

export const selectActiveVehiclesBySelectedMap = createSelector(
  selectAllActiveVehicles,
  fromMapStore.selectMapId,
  (vehicles, mapId) => {
    return vehicles.filter(
      v => checkNoMap(v) || checkWithMap(v, mapId) || checkWithMapOrEmptyId(v, mapId)
    );
  }
);

export const selectMapVehicles = createSelector(
  selectActiveVehiclesBySelectedMap,
  selectFactsheetDimensions,
  (vehicles, dimensions) => {
    return vehicles.map(v => {
      const dimension = dimensions.filter(d => d).find(d => d?.vehicleId === v.id);

      const mv: MapVehicle = {
        ...v,
        dimensions: dimension,
      };

      return mv;
    });
  }
);
