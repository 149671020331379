/* eslint-disable @typescript-eslint/prefer-readonly */
import { Injectable } from '@angular/core';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { AuthGql } from 'core/api-services';

import { AtsTranslationService } from 'core/services';

@Injectable({
  providedIn: 'root',
})
export class AuditLogDataService {
  isLoading = false;

  constructor(private readonly translationService: AtsTranslationService) {}

  criteriaCreatedUtcDate(filters: IServerSideGetRowsRequest): AuthGql.AuditLogFilterInput[] {
    if (filters?.filterModel?.createdUtc != null) {
      const dateFrom = filters.filterModel?.createdUtc.dateFrom && {
        createdUtc: { gte: filters.filterModel?.createdUtc.dateFrom },
      };
      const dateTo = filters.filterModel?.createdUtc.dateTo && {
        createdUtc: { lte: filters.filterModel?.createdUtc.dateTo },
      };
      return [dateFrom, dateTo];
    }
    return [];
  }

  criteriaUpdates(filters: IServerSideGetRowsRequest): AuthGql.AuditLogFilterInput | null {
    if (filters?.filterModel?.action?.filter != null) {
      if (this.translationService.currentLang === 'de') {
        return { textDe: { contains: filters?.filterModel.action?.filter ?? '' } };
      } else {
        return { textEn: { contains: filters?.filterModel.action?.filter ?? '' } };
      }
    }
    return null;
  }

  criteriaWorkingAreas(filters: IServerSideGetRowsRequest): AuthGql.AuditLogFilterInput | null {
    if (filters?.filterModel.waName?.values.length > 0)
      return {
        workArea: { name: { in: filters?.filterModel.waName.values } },
      };
    return null;
  }

  criteriaOrganization(filters: IServerSideGetRowsRequest): AuthGql.AuditLogFilterInput | null {
    if (filters?.filterModel.orgName?.values.length > 0)
      return {
        workArea: {
          organization: {
            name: { in: filters?.filterModel.orgName.values },
          },
        },
      };
    return null;
  }

  criteriaAuditEventType(filters: IServerSideGetRowsRequest): AuthGql.AuditLogFilterInput | null {
    if (filters?.filterModel.auditTableEventType?.values.length > 0)
      return {
        auditTableEventType: {
          in: filters?.filterModel.auditTableEventType.values,
        },
      };
    return null;
  }
}
