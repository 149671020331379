export enum TourGenerationFailureReason {
  Unknown = 0,
  TourConfigurationNotFound = 1,
  RouteConfigurationNotFound = 2,
  NodesAndEdgesNotFound = 3,
  VehicleNotFound = 4,
  TourCompilationFailed = 5,
  WrongTriggerType = 6,
  NoVehicleOnAssignableNode = 7,
  AssignableNodeNotSet = 8,
  TourPersistenceFailed = 9,
  TourDispatchFailed = 10,
  PsaNodeMappingMissing = 11,
  VehicleInManualMode = 14,
  NodeGroupResolutionFailed = 15,
  VehicleBookingFailed = 16,
  NodeBookingFailed = 17,
  NoAvailableParkyChargyNode = 18,
  NoEligibleVehicleOnAssignableNode = 19,
}
