import { createAction, props } from '@ngrx/store';
import {
  OpcuaDeviceNodeTelemetrySignalR,
  OpcuaDeviceNotification,
  OpcuaDeviceOnlineStatusChangedDto,
  OpcuaDeviceRequestModel,
  OpcuaDeviceResponseModel,
} from 'core/dtos';

export enum OpcuaDevicesTypes {
  LoadOpcuaDevices = '[Devices] Load All Opcua Devices',
  LoadOpcuaDeviceSuccess = '[Devices] Load Opcua Device Success',
  LoadOpcuaDevicesSuccess = '[Devices] Load All Opcua Devices Success',
  LoadOpcuaDevicesFailure = '[Devices] Load All Opcua Devices Failure',
  LoadOpcuaDeviceStateHistorySuccess = '[Devices] Load Opcua Device State History Success',

  CreateOpcuaDevice = '[Devices] Create Opcua Device',
  CreateOpcuaDeviceSuccess = '[Devices] Create Opcua Device Success',
  CreateOpcuaDeviceFailure = '[Devices] Create Opcua Device Failure',

  UpdateOpcuaDevice = '[Devices] Update Opcua Device',
  UpdateOpcuaDeviceSuccess = '[Devices] Update Opcua Device Success',
  UpdateOpcuaDeviceFailure = '[Devices] Update Opcua Device Failure',

  UpdateOpcuaDeviceNodes = '[Devices] Update Opcua Device Nodes',
  UpdateOpcuaDeviceNodesSuccess = '[Devices] Update Opcua Device Nodes Success',
  UpdateOpcuaDeviceNodesFailure = '[Devices] Update Opcua Device Nodes Failure',

  DeleteOpcuaDevice = '[Devices] Delete Opcua Device',
  DeleteOpcuaDeviceSuccess = '[Devices] Delete Opcua Device Success',
  DeleteOpcuaDeviceFailure = '[Devices] Delete Opcua Device Failure',

  SetSelectedDevice = '[Devices] Set Selected Opcua Device',
}

export enum SignalRDeviceTypes {
  SignalrCreateOpcuaDevice = '[SignalR] Create Opcua Device',
  SignalrUpdateOpcuaDevice = '[SignalR] Update Opcua Device',
  SignalrDeleteOpcuaDevice = '[SignalR] Delete Opcua Device',
  SignalrOpcuaNodeTelemetry = '[SignalR] Opcua Node Telemetry Received',
  SignalrOpcuaOnlineStatus = '[SignalR] Opcua Device Online Status Received',
  SignalrOpcuaNotificationTriggered = '[SignalR] Opcua Device Notification Triggered',
  SignalrOpcuaNotificationResolved = '[SignalR] Opcua Device Notification Resolved',
}

export const loadOpcuaDevices = createAction(OpcuaDevicesTypes.LoadOpcuaDevices);

export const loadOpcuaDevicesSuccess = createAction(
  OpcuaDevicesTypes.LoadOpcuaDevicesSuccess,
  props<{ devices: OpcuaDeviceResponseModel[] }>()
);

export const loadOpcuaDeviceSuccess = createAction(
  OpcuaDevicesTypes.LoadOpcuaDeviceSuccess,
  props<{ device: OpcuaDeviceResponseModel }>()
);

export const loadOpcuaDevicesFailure = createAction(
  OpcuaDevicesTypes.LoadOpcuaDevicesFailure,
  props<{ errorMessage: string }>()
);

export const signalRCreateOpcuaDevice = createAction(
  SignalRDeviceTypes.SignalrCreateOpcuaDevice,
  props<{ device: OpcuaDeviceResponseModel }>()
);

export const signalRUpdateOpcuaDevice = createAction(
  SignalRDeviceTypes.SignalrUpdateOpcuaDevice,
  props<{ device: OpcuaDeviceResponseModel }>()
);

export const signalRDeleteOpcuaDevice = createAction(
  SignalRDeviceTypes.SignalrDeleteOpcuaDevice,
  props<{ device: OpcuaDeviceResponseModel }>()
);

export const signalRUpdateOpcuaDeviceNodeState = createAction(
  SignalRDeviceTypes.SignalrOpcuaNodeTelemetry,
  props<{ telemetry: OpcuaDeviceNodeTelemetrySignalR }>()
);

export const signalRUpdateOpcuaDeviceOnlineState = createAction(
  SignalRDeviceTypes.SignalrOpcuaOnlineStatus,
  props<{ statusMessage: OpcuaDeviceOnlineStatusChangedDto }>()
);

export const signalROpcuaDeviceNotificationTriggered = createAction(
  SignalRDeviceTypes.SignalrOpcuaNotificationTriggered,
  props<{ notification: OpcuaDeviceNotification }>()
);

export const signalROpcuaDeviceNotificationResolved = createAction(
  SignalRDeviceTypes.SignalrOpcuaNotificationResolved,
  props<{ notification: OpcuaDeviceNotification }>()
);

export const createOpcuaDevice = createAction(
  OpcuaDevicesTypes.CreateOpcuaDevice,
  props<{ device: OpcuaDeviceRequestModel }>()
);

export const createOpcuaDeviceSuccess = createAction(
  OpcuaDevicesTypes.CreateOpcuaDeviceSuccess,
  props<{ device: OpcuaDeviceResponseModel }>()
);

export const createOpcuaDeviceFailure = createAction(
  OpcuaDevicesTypes.CreateOpcuaDeviceFailure,
  props<{ errorMessage: string }>()
);

export const updateOpcuaDevice = createAction(
  OpcuaDevicesTypes.UpdateOpcuaDevice,
  props<{ device: OpcuaDeviceRequestModel }>()
);

export const updateOpcuaDeviceSuccess = createAction(
  OpcuaDevicesTypes.UpdateOpcuaDeviceSuccess,
  props<{ device: OpcuaDeviceResponseModel }>()
);

export const updateOpcuaDeviceFailure = createAction(
  OpcuaDevicesTypes.UpdateOpcuaDeviceFailure,
  props<{ errorMessage: string }>()
);

export const updateOpcuaDeviceNodes = createAction(
  OpcuaDevicesTypes.UpdateOpcuaDeviceNodes,
  props<{ device: OpcuaDeviceResponseModel }>()
);

export const updateOpcuaDeviceNodesSuccess = createAction(
  OpcuaDevicesTypes.UpdateOpcuaDeviceNodesSuccess,
  props<{ device: OpcuaDeviceResponseModel }>()
);

export const updateOpcuaDeviceNodesFailure = createAction(
  OpcuaDevicesTypes.UpdateOpcuaDeviceNodesFailure,
  props<{ errorMessage: string }>()
);

export const deleteOpcuaDevice = createAction(
  OpcuaDevicesTypes.DeleteOpcuaDevice,
  props<{ name: string; streamingService: string }>()
);

export const deleteOpcuaDeviceSuccess = createAction(
  OpcuaDevicesTypes.DeleteOpcuaDeviceSuccess,
  props<{ name: string }>()
);

export const deleteOpcuaDeviceFailure = createAction(
  OpcuaDevicesTypes.DeleteOpcuaDeviceFailure,
  props<{ errorMessage: string }>()
);

export const setSelectedDevice = createAction(
  OpcuaDevicesTypes.SetSelectedDevice,
  props<{ streamingService: string; name: string }>()
);
