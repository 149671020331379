import { Zone, arePositionsEqual } from 'core/models';
import { Point } from 'pixi.js';

export function getClosedZone(zone: Zone): Zone {
  const shape =
    zone.polygon[0].x !== zone.polygon[zone.polygon.length - 1].x &&
    zone.polygon[0].y !== zone.polygon[zone.polygon.length - 1].y
      ? [...zone.polygon, zone.polygon[0]]
      : zone.polygon;

  return {
    ...zone,
    polygon: shape,
  };
}

export function removeZoneEndPoint(points: Point[]): Point[] {
  if (arePositionsEqual(points[0], points[points.length - 1])) {
    points.pop();
  }

  return points;
}
