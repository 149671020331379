import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { DifferenceInMillisecondsPipe } from 'library/pipes/difference-in-milliseconds.pipe';
import { FormatIconPipe } from 'library/pipes/format-icon.pipe';
import { DensityModule } from './density.module';
import { PrimeNgModule } from './primeNg.module';

import { ToastService } from 'core/services';

import { LabeledDropdownActionComponent } from 'components/labeled-dropdown-action/labeled-dropdown-action.component';
import { SearchInputComponent } from 'components/search-input/search-input.component';
import { TrafficLightComponent } from 'components/traffic-light/traffic-light.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BatteryBarComponent } from './components/battery-bar/battery-bar.component';
import { CardCollapsibleComponent } from './components/card-collapsible/card-collapsible.component';
import { CardStaticComponent } from './components/card-static/card-static.component';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';
import { ExpandCollapseButtonsComponent } from './components/expand-collapse-buttons/expand-collapse-buttons.component';
import { ExpandCollapseButtonsService } from './components/expand-collapse-buttons/service/expand-collapse-buttons.service';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { IconNumberInputComponent } from './components/icon-number-input/icon-number-input.component';
import { LabeledButtonActionComponent } from './components/labeled-button-action/labeled-button-action.component';
import { LabeledInputComponent } from './components/labeled-input/labeled-input.component';
import { LabeledTextLinkComponent } from './components/labeled-text-link/labeled-text-link.component';
import { LabeledTextComponent } from './components/labeled-text/labeled-text.component';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';
import { MenuComponent } from './components/menu/menu.component';
import { ConfirmModalComponent } from './components/modal-dialog/confirm-modal/confirm-modal.component';
import { CustomDialogComponent } from './components/modal-dialog/custom-dialog/custom-dialog.component';
import { SecondarySidePanelComponent } from './components/secondary-sidepanel/secondary-sidepanel.component';
import { SingleListSelectComponent } from './components/single-list-select/single-list-select.component';
import { StatusLabelComponent } from './components/status-label/status-label.component';
import { TabListComponent } from './components/tab-list/tab-list.component';
import { TableComponent } from './components/table/table.component';
import { TreeTableComponent } from './components/tree-table/tree-table.component';
import { TreeViewComponent } from './components/tree-view/tree-view.component';

import { AllowActionDirective } from './directives/allow-action.directive';
import { AllowConfigurationDirective } from './directives/allow-configuration.directive';
import { WhitespaceTrimmerDirective } from './directives/whitespace-trimmer.directive';

import { DsDropdownPipe, DsDropdownPrimitivePipe } from './pipes/ds-dropdown.pipe';
import { DsEnumDropdownPipe } from './pipes/ds-enum-dropdown.pipe';
import { EnumArrayTranslationPipe } from './pipes/enum-array-translation.pipe';
import { EnumDropdownPipe } from './pipes/enum-dropdown.pipe';
import { EnumTranslationPipe } from './pipes/enum-translation.pipe';
import { FindInArrayPipe } from './pipes/find-in-array.pipe';
import { FormatColorPipe } from './pipes/format-color.pipe';
import { FormatDateIncludeInPipe } from './pipes/format-date-include-in.pipe';
import { FormatDateNowPipe } from './pipes/format-date-now.pipe';
import { FormatDateTodayPipe } from './pipes/format-date-today.pipe';
import { FormatDistancePipe } from './pipes/format-distance.pipe';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { FormatVehicleDueDatePipe } from './pipes/format-vehicle-due-date.pipe';
import { MinutesSecondsTimerPipe } from './pipes/minutes-seconds-timer.pipe';
import { NotApplicablePipe } from './pipes/not-applicable.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { HighlightSearchPipe } from './pipes/search-term-highlight.pipe';
import { StandardDateRangePipe } from './pipes/standard-date-time-range.pipe';
import { StandardDateTimePipe } from './pipes/standard-date-time.pipe';
import { StandardTimeFramePipe } from './pipes/standard-time-frame.pipe';

import { DsSortGroupedDropdownPipe } from './pipes/ds-sort-grouped-dropdown.pipe';
import { SemicolonSeparatorPipe } from './pipes/semicolon-separator.pipe';
import { SortDropdownPipe } from './pipes/sort-dropdown.pipe';
import { UniqueByPipe } from './pipes/unique-by.pipe';

import { DsIconModule } from '@bmw-ds/components';
import { DraggableAccordionContainerComponent } from './components/draggable-accordion-component/draggable-accordion-container/draggable-accordion-container.component';
import { DraggableAccordionComponent } from './components/draggable-accordion-component/draggable-accordion/draggable-accordion.component';
import { LastChangedDateComponent } from './components/last-changed-date/last-changed-date.component';
import { LayerPositionInputComponent } from './components/layer-position-input/layer-position-input.component';
import { MessageLinkComponent } from './components/message-link/message-link.component';
import { ConvertUtcToTimePipe } from './pipes/convert-utc-to-time.pipe';
import { DefaultValuePipe } from './pipes/default-value.pipe';
import { DsEnumArrayDropdownPipe } from './pipes/ds-enum-array-dropdown.pipe';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { FormCastArrayToStringPipe } from './pipes/form-cast-number-to-string.pipe';
import { FormatTonePipe } from './pipes/format-tone.pipe';
import { GetNameFromIdPipe } from './pipes/get-name.pipe';
import { ReverseListPipe } from './pipes/reverse-list.pipe';

@NgModule({
  declarations: [
    TreeViewComponent,
    TreeTableComponent,
    BatteryBarComponent,
    IconNumberInputComponent,
    LabeledInputComponent,
    LabeledTextComponent,
    LabeledTextLinkComponent,
    LabeledDropdownActionComponent,
    LabeledButtonActionComponent,
    CopyButtonComponent,
    SearchInputComponent,
    LayerPositionInputComponent,
    MenuButtonComponent,
    MessageLinkComponent,
    SecondarySidePanelComponent,
    ExpandCollapseButtonsComponent,
    ConfirmModalComponent,
    WhitespaceTrimmerDirective,
    AllowConfigurationDirective,
    AllowActionDirective,
    TrafficLightComponent,
    TableComponent,
    AccordionComponent,
    FilterPanelComponent,
    MenuComponent,
    ListFilterComponent,
    CustomDialogComponent,
    SingleListSelectComponent,
    StatusLabelComponent,
    CardCollapsibleComponent,
    CardStaticComponent,
    CopyButtonComponent,
    NotApplicablePipe,
    EnumDropdownPipe,
    EnumTranslationPipe,
    EnumArrayTranslationPipe,
    FormatColorPipe,
    FormatIconPipe,
    FormatTonePipe,
    FormatDateNowPipe,
    FormatDateTodayPipe,
    FormatDateIncludeInPipe,
    DifferenceInMillisecondsPipe,
    HighlightSearchPipe,
    SemicolonSeparatorPipe,
    SortDropdownPipe,
    GetNameFromIdPipe,
    DsSortGroupedDropdownPipe,
    FormatTimePipe,
    FormatDistancePipe,
    FindInArrayPipe,
    SafePipe,
    StandardDateTimePipe,
    StandardDateRangePipe,
    StandardTimeFramePipe,
    FormatVehicleDueDatePipe,
    MinutesSecondsTimerPipe,
    UniqueByPipe,
    DsEnumDropdownPipe,
    DsEnumArrayDropdownPipe,
    DsDropdownPipe,
    DsDropdownPrimitivePipe,
    TabListComponent,
    FilterArrayPipe,
    ReverseListPipe,
    FormCastArrayToStringPipe,
    DefaultValuePipe,
    LastChangedDateComponent,
    DraggableAccordionComponent,
    ConvertUtcToTimePipe,
    DraggableAccordionContainerComponent,
  ],
  imports: [
    DragDropModule,
    ClipboardModule,
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    DensityModule,
    DsIconModule.forRoot({
      sprite: 'assets/global/ats-icons.symbol.svg',
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PrimeNgModule,
    DensityModule,
    ClipboardModule,
    DragDropModule,
    ClipboardModule,
    TreeTableComponent,
    BatteryBarComponent,
    IconNumberInputComponent,
    LabeledInputComponent,
    LabeledTextComponent,
    LabeledTextLinkComponent,
    LabeledDropdownActionComponent,
    LabeledButtonActionComponent,
    CopyButtonComponent,
    SearchInputComponent,
    LayerPositionInputComponent,
    MenuButtonComponent,
    MessageLinkComponent,
    SecondarySidePanelComponent,
    TreeViewComponent,
    ExpandCollapseButtonsComponent,
    TrafficLightComponent,
    TableComponent,
    AccordionComponent,
    FilterPanelComponent,
    MenuComponent,
    ListFilterComponent,
    CustomDialogComponent,
    SingleListSelectComponent,
    StatusLabelComponent,
    CardCollapsibleComponent,
    CardStaticComponent,
    ConfirmModalComponent,
    CopyButtonComponent,
    WhitespaceTrimmerDirective,
    AllowConfigurationDirective,
    AllowActionDirective,
    NotApplicablePipe,
    EnumDropdownPipe,
    EnumTranslationPipe,
    EnumArrayTranslationPipe,
    FormatColorPipe,
    FormatIconPipe,
    FormatTonePipe,
    FormatDateNowPipe,
    DifferenceInMillisecondsPipe,
    SemicolonSeparatorPipe,
    SortDropdownPipe,
    GetNameFromIdPipe,
    DsSortGroupedDropdownPipe,
    FormatTimePipe,
    FormatDistancePipe,
    HighlightSearchPipe,
    FindInArrayPipe,
    SafePipe,
    StandardDateTimePipe,
    StandardDateRangePipe,
    StandardTimeFramePipe,
    FormatVehicleDueDatePipe,
    MinutesSecondsTimerPipe,
    UniqueByPipe,
    DsEnumDropdownPipe,
    DsEnumArrayDropdownPipe,
    DsDropdownPipe,
    DsDropdownPrimitivePipe,
    ConfirmModalComponent,
    TabListComponent,
    FilterArrayPipe,
    ReverseListPipe,
    FormCastArrayToStringPipe,
    FormatDateTodayPipe,
    DefaultValuePipe,
    LastChangedDateComponent,
    DraggableAccordionComponent,
    ConvertUtcToTimePipe,
    DraggableAccordionContainerComponent,
  ],
  providers: [
    ExpandCollapseButtonsService,
    ToastService,
    PrimeNgModule,
    DensityModule,
    DatePipe,
    NotApplicablePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LibraryModule {
  static forRoot(): ModuleWithProviders<LibraryModule> {
    return {
      ngModule: LibraryModule,
    };
  }
}
