import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import * as fromRoot from 'store/index';
import { RootState } from 'store/reducers';

export interface TranslatedSelectItem extends SelectItem {
  translationKey: string;
}

@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSettingsComponent implements OnInit, OnDestroy {
  constructor(private readonly rootStore: Store<RootState>) {}

  ngOnInit(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: true }));
  }

  ngOnDestroy(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
  }
}
