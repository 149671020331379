<ds-modal
  [dimensionsConfig]="{ width: '535px' }"
  #shutdownModeModal
  [ds-modal-trigger-for]="shutdownModeModal"
  [(isModalOpen)]="isModalOpen"
  (modalDismiss)="onCancel()">
  <ds-box-header data-cy="dialogHeader"
    >{{ translateKey + '.heading' | translate }} {{ this.areaText }}
  </ds-box-header>
  <ds-box-content divider="full" data-cy="dialog-content">
    <div class="modalMessageText1">
      <span> {{ translateKey + '.messageText' | translate }}</span>
    </div>
    <div class="modalMessageText2 modalMessageItalics spaceBetweenProperties">
      <span> {{ translateKey + '.messageText2' | translate }}</span>
    </div>
    <ds-form-field>
      <label ds-label for="currentUser"> {{ translateKey + '.user' | translate }}</label>
      <ds-select
        appendTo="body"
        id="currentUser"
        data-cy="UserDropdown"
        required
        [options]="users | sortDropdown | dsDropdown"
        [labellingConfig]="{ placeholder: 'placeholders.search' | translate }"
        [(ngModel)]="currentUser"></ds-select>
    </ds-form-field>

    <ds-form-field class="mt-4x">
      <label ds-label for="currentRole"> {{ translateKey + '.role' | translate }}</label>
      <ds-select
        appendTo="body"
        id="currentRole"
        data-cy="RoleDropdown"
        required
        [options]="roles | sortDropdown | dsDropdown"
        [labellingConfig]="{ placeholder: 'placeholders.search' | translate }"
        [(ngModel)]="currentRole"></ds-select>
    </ds-form-field>
  </ds-box-content>
  <ds-box-footer data-cy="dialog-footer">
    <button ds-button [variant]="'outline'" (click)="onCancel()" data-cy="cancelButton">
      {{ 'shared.modalButtons.CancelButton' | translate }}
    </button>
    <button
      ds-button
      data-cy="saveButton"
      class="ms-2x"
      [disabled]="!currentUser || !currentRole"
      (click)="onSave()">
      {{ 'shared.modalButtons.SaveButton' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
