import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SelectListOption } from '@bmw-ds/components';
import { select, Store } from '@ngrx/store';
import { InferenceRequestDto } from 'core/dtos';
import { AvailableBots, ChatMessage } from 'core/models';
import { AtsTranslationService } from 'core/services';
import * as marked from 'marked';
import { from, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AtsCopilotState,
  selectAllChatMessages,
  selectCopilotLoadingState,
  sendMessage,
} from 'store-modules/ats-copilot-store';

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatCardComponent implements OnDestroy, AfterViewChecked {
  constructor(
    private readonly store: Store<AtsCopilotState>,
    private readonly translateService: AtsTranslationService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly sanitizer: DomSanitizer
  ) {
    this.translateService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateMenuItems();
    });
  }

  @Input() isOpen = false;
  @Input() isCardFixed = true;
  @Output() readonly closeChat = new EventEmitter<void>();
  @ViewChild('scroll', { read: ElementRef }) private readonly scroll!: ElementRef;

  ngUnsubscribe = new Subject<void>();
  userInput = '';
  selectedBotId: string | undefined;
  shouldScroll = false;

  ngAfterViewChecked(): void {
    if (this.shouldScroll) {
      this.scrollToBottom();
      this.shouldScroll = false;
    }
  }

  waitingForResponse$: Observable<boolean> = this.store.pipe(
    select(selectCopilotLoadingState),
    tap(() => {
      this.shouldScroll = true;
      this.userInput = '';
    }),
    takeUntil(this.ngUnsubscribe)
  );

  messages$: Observable<ChatMessage[]> = this.store.pipe(
    select(selectAllChatMessages),
    takeUntil(this.ngUnsubscribe)
  );

  availableBots: SelectListOption[] = Object.keys(AvailableBots).map(k => ({
    id: k,
    label: this.translateService.translateEnum(k, 'CopilotBot'),
  }));

  convertMarkdown(markdown: string): Observable<SafeHtml> {
    return of(marked.parse(markdown)).pipe(
      switchMap(result => {
        if (typeof result === 'string') {
          return of(this.sanitizer.bypassSecurityTrustHtml(result));
        }
        return from(result).pipe(map(html => this.sanitizer.bypassSecurityTrustHtml(html)));
      })
    );
  }

  scrollToBottom(): void {
    if (this.scroll) {
      this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
      this.cdRef.markForCheck();
    }
  }

  sendMessage(): void {
    if (this.userInput.trim() && this.selectedBotId) {
      const botId = AvailableBots[this.selectedBotId];
      const message = this.createInferenceRequest(this.userInput);
      this.store.dispatch(sendMessage({ botId, message }));
    }
  }

  dismissChat(): void {
    this.isOpen = false;
    this.closeChat.emit();
  }

  private createInferenceRequest(userInput: string): InferenceRequestDto {
    const returnValue: InferenceRequestDto = {
      text: userInput,
      files: [],
      history: [],
      sub: 'q123456',
      streaming: true,
    };
    return returnValue;
  }

  setSelectedBot($event: SelectListOption | SelectListOption[] | null): void {
    if ($event && !Array.isArray($event)) {
      this.selectedBotId = $event.id;
      this.cdRef.markForCheck();
    }
  }

  translateMenuItems(): void {
    this.availableBots = Object.keys(AvailableBots).map(k => ({
      id: k,
      label: this.translateService.translateEnum(k, 'CopilotBot'),
    }));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
