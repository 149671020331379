<div [routerLink]="['/welcome']" class="clickable-area" data-cy="bmw-logo"></div>
<ds-header
  appName="ATS"
  [navBarRef]="navBarRef"
  brandLogos="BMW"
  [(localeID)]="localeID"
  [localeOptions]="localeOptions"
  (localeIDChange)="changeLocale($event)"
  (requestLogout)="onLogout()"
  [environmentTag]="environmentTagConfig"
  [userInfo]="userInfo"
  [menuTemplate]="extraUserOptionsTemplate"
  data-cy="hamburger">
  <div class="header-container header-bar">
    <nav class="breadcrumb-container">
      <app-breadcrumb-bar [hidden]="!shouldShowBreadcrumbBar"></app-breadcrumb-bar>
    </nav>

    <div *ngIf="shouldShowNotificationButton" class="headerItem">
      <div
        ds-tooltip="{{ 'tooltip.notifications' | translate }}"
        [dsTooltipConfig]="{ origin: 'bottom' }">
        <button
          data-cy="notificationBell"
          ds-button
          [variant]="'ghost'"
          [icon]="'alert'"
          (click)="onBellClick()"></button>
      </div>
    </div>

    <button
      *ngIf="shouldShowSupportRequestButton"
      data-cy="createTicket"
      ds-tooltip="{{ 'tooltip.createTicket' | translate }}"
      [dsTooltipConfig]="{ origin: 'bottom' }"
      ds-button
      [variant]="'ghost'"
      [icon]="'mail'"
      class="headerItem"
      (click)="onTicketCreationClick()"></button>

    <button
      data-cy="userManual"
      ds-tooltip="{{ 'tooltip.userManual' | translate }}"
      [dsTooltipConfig]="{ origin: 'bottom' }"
      ds-button
      [variant]="'ghost'"
      [icon]="'help'"
      class="headerItem"
      (click)="onUserManualClick()"></button>

    <button
      data-cy="versionInformation"
      ds-tooltip="{{ 'tooltip.versionInformation' | translate }}"
      [dsTooltipConfig]="{ origin: 'bottom' }"
      ds-button
      [variant]="'ghost'"
      [icon]="'information'"
      class="headerItem"
      (click)="onVersionInformationClick()"></button>
  </div>
</ds-header>

<ng-template #extraUserOptionsTemplate>
  <ds-list-item *ngIf="isDevMode">
    <ds-switch (ngModelChange)="themeService.toggleDarkTheme()" [ngModel]="themeService.isDark">
      Dark (Experimental)
    </ds-switch>
  </ds-list-item>
  <ds-list-item icon="user" [isHoverable]="true" (click)="openMyAtsUserSettingsModal()">
    {{ 'userProfile.myAtsSettings' | translate }}
  </ds-list-item>
  <ds-list-item icon="settings" [isHoverable]="true" (click)="openMyPermissionsModal()">
    {{ 'userProfile.myPermissions' | translate }}
  </ds-list-item>
</ng-template>

<app-user-settings-modal [isModalOpen]="isUserSettingsModalOpen" (closeEvent)="onCloseModal()">
</app-user-settings-modal>
<app-user-details-dialog
  [editEnabled]="true"
  [isModalOpen]="isUserDetailsModalOpen"
  [userDetailsData]="userDetailsData"
  (dismissModal)="onCloseModal()">
</app-user-details-dialog>
