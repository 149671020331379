import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

export interface FunctionsModalInput {
  title: string;
  description?: string;
  heading?: string;
  toggleOn: boolean;
  customText?: string;
}

@Component({
  selector: 'app-functions-modal',
  templateUrl: './functions-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionsModalComponent {
  @Input() modalContentTemplate?: TemplateRef<HTMLElement>;
  @Input() isModalOpen = false;
  @Input() modalInputData: FunctionsModalInput = {
    title: '',
    description: undefined,
    heading: undefined,
    toggleOn: false,
    customText: undefined,
  };

  @Output() readonly confirm = new EventEmitter<boolean>();

  onClose(): void {
    this.confirm.emit(false);
  }

  onConfirm(): void {
    this.confirm.emit(true);
  }
}
