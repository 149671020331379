export enum GraphParkingChargingType {
  None = 0,
  Fueling = 2,
  Parking = 3,
  Charging = 4,
}

export enum ActionGraphNodeType {
  PickDrop,
  Battery,
  Parking,
  DetectObject,
  WaitForTrigger,
  Custom,
  MoreOptions,
}

export enum GraphNodeShape {
  SmallRound,
  MediumRound,
}

export enum RouteConfigState {
  Selected,
  Unavailable,
  Available,
}

export enum NodeOccupancyStatus {
  Free,
  Occupied,
  Booked,
}

export enum GraphNodeIcon {
  Fueling,
  Parking,
  Charging,
}

export enum ActionGraphNodeIcon {
  PickDrop,
  Battery,
  CrossBattery,
  Parking,
  DetectObject,
  WaitForTrigger,
  Custom,
  MoreOptions,
  Fueling,
}
