import { DATE_FORMAT } from 'core/constants';
import { DateString, TimeInterval } from 'core/models';
import {
  addDays,
  addHours,
  addMinutes,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay,
  endOfWeek,
  format,
  getMilliseconds,
  isSameDay,
  isValid,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { LocaleSettings } from 'primeng/calendar';
import { findWindows } from 'windows-iana';

import calendarDe from '../i18n/calendar-de.json';
import calendarEn from '../i18n/calendar-en.json';

const timeIntervalActions = {
  [TimeInterval.ThisMonth]: getMonth,
  [TimeInterval.ThisWeek]: getWeek,
  [TimeInterval.Today]: getToday,
  [TimeInterval.Yesterday]: getYesterday,
  [TimeInterval.LastWeek]: getLastWeek,
  [TimeInterval.Last30ProductiveDays]: getLastProd30Days,
  [TimeInterval.Custom]: getDates,
};

const millisecondsPerMinute = 60000;
const timeStringLength = 5;

export function getLocale(lang = 'de'): LocaleSettings {
  return lang === 'en' ? calendarEn : calendarDe;
}

export function getDatesForTimeInterval(value: number, date: Date): Date[] {
  return timeIntervalActions[value](date);
}

export function getDates(date: Date): Date[] {
  return [date, date];
}

export function getToday(date: Date): Date[] {
  return [startOfDay(date), date];
}

export function getYesterday(date: Date): Date[] {
  return [startOfDay(subDays(date, 1)), endOfDay(subDays(date, 1))];
}

export function getWeek(date: Date): Date[] {
  return [startOfWeek(date, { weekStartsOn: 1 }), date];
}

export function getLastWeek(date: Date): Date[] {
  const week = subWeeks(date, 1);
  return [startOfWeek(week, { weekStartsOn: 1 }), endOfWeek(week, { weekStartsOn: 1 })];
}

export function getLastMonth(): Date {
  return startOfDay(subMonths(new Date(), 1));
}

export function getPreviousMonth(date: Date, months: number): Date {
  return startOfMonth(subMonths(date, months));
}

export function getMonth(date: Date): Date[] {
  return [startOfMonth(date), date];
}

export function getLastProd30Days(data: Date[]): Date[] {
  return [data[0], data[1]];
}

export function formatStartDate(date: Date): string {
  return new Date(
    startOfDay(date).getTime() - date.getTimezoneOffset() * millisecondsPerMinute
  ).toISOString();
}

export function formatEndDate(date: Date): string {
  return new Date(
    startOfDay(addDays(date, 1)).getTime() - date.getTimezoneOffset() * millisecondsPerMinute
  ).toISOString();
}

export function getTimeZone(): string | undefined {
  return getTimezoneName(Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function getTimezoneName(timezone: string): string | undefined {
  return findWindows(timezone)?.toString();
}

export function getEndDateToPresent(date: Date): Date {
  return subDays(date, 1);
}

export function formatDateIncludingIn(
  minutesTranslation: string,
  nowTranslation: string,
  date: Date | DateString | null,
  overdueTranslation?: string
): string {
  if (date) {
    if (isSameDay(new Date(date.toString()), new Date())) {
      const differenceMinutes = differenceInMinutes(new Date(date.toString()), new Date());
      if (differenceMinutes === 0) {
        return `${format(new Date(date.toString()), DATE_FORMAT.TIME)} (${nowTranslation})`;
      } else if (differenceMinutes < 0 && overdueTranslation) {
        return `${format(new Date(date.toString()), DATE_FORMAT.TIME)} (${Math.abs(
          differenceMinutes
        )} min ${overdueTranslation})`;
      } else if (differenceMinutes < 0 && !overdueTranslation) {
        return `${format(new Date(date.toString()), DATE_FORMAT.TIME)}`;
      } else {
        return `${format(
          new Date(date.toString()),
          DATE_FORMAT.TIME
        )} (in ${differenceMinutes} ${minutesTranslation})`;
      }
    } else {
      return `${format(new Date(date.toString()), DATE_FORMAT.FULL_DATE_TIME_SECONDS)}`;
    }
  }

  return '';
}

export function formatDateIncludingMilliSecs(date: Date | DateString): number {
  if (date) {
    const differenceMinutes = getMilliseconds(new Date(date.toString()));
    if (differenceMinutes > 0) {
      return differenceMinutes;
    }
  }
  return 0;
}

export function formatDateString(date: DateString, dateFormat?: string): string {
  return formatDate(new Date(date.toString()), dateFormat);
}

export function formatDate(date?: Date | DateString | null, dateFormat?: string): string {
  const valid = date && ((typeof date === 'string' && isValid(new Date(date))) || isValid(date));

  if (valid) {
    if (dateFormat) {
      return `${format(new Date(date.toString()), dateFormat)}`;
    }

    if (isSameDay(new Date(date.toString()), new Date())) {
      return `${format(new Date(date.toString()), DATE_FORMAT.TIME)}`;
    } else {
      return `${format(new Date(date.toString()), DATE_FORMAT.FULL_DATE_TIME_SECONDS)}`;
    }
  }

  return '';
}

export function setStartTime(date: Date): Date {
  return startOfDay(date);
}

export function setEndTime(date: Date): Date {
  return endOfDay(date);
}

export function addTime(date: Date, time: { hours?: number; minutes?: number }): Date {
  date = addHours(date, time.hours ?? 0);

  return addMinutes(date, time.minutes ?? 0);
}

export function setTimeString(date: Date, time: string | null | undefined): Date | undefined {
  if (
    !date ||
    typeof time !== 'string' ||
    time.length < timeStringLength ||
    time.includes('AM') ||
    time.includes('PM')
  ) {
    // required time format "HH:mm" || "HH:mm:ss" , certain locales, pass in AM and PM
    return undefined;
  }
  date = startOfDay(date);
  const values = time.split(':');
  const hours = parseInt(values[0]);
  const minutes = parseInt(values[1]);
  const seconds = parseInt(values[2]) || 0;

  if (Number.isInteger(hours) && Number.isInteger(minutes)) {
    date.setHours(hours, minutes, seconds);
  }
  return date;
}

export function elapsed(from: Date | DateString): number {
  return differenceInSeconds(new Date(), new Date(from.toString()));
}
