import { createSelector } from '@ngrx/store';
import { EntityList } from 'core/models';
import { selectMapId } from './maps.selectors';

import * as fromNavigationLayersFeatureState from '../reducers';
import * as fromNavigationLayers from '../reducers/navigation-layers.reducer';

const selectNavigationLayersState = createSelector(
  fromNavigationLayersFeatureState.getMapsFeatureState,
  fromNavigationLayersFeatureState.getNavigationLayersState
);

export const selectNavigationLayersLoaded = createSelector(
  selectNavigationLayersState,
  fromNavigationLayers.getLoaded
);

export const selectNavigationLayersErrorMessage = createSelector(
  selectNavigationLayersState,
  fromNavigationLayers.getErrorMessage
);

export const selectNavigationLayerId = createSelector(
  selectNavigationLayersState,
  fromNavigationLayers.getSelectedNavigationLayerId
);

export const selectNavigationLayerEntities = createSelector(
  selectNavigationLayersState,
  fromNavigationLayers.getNavigationLayerEntities
);

export const selectAllNavigationLayers = createSelector(
  selectNavigationLayersState,
  fromNavigationLayers.getAllNavigationLayers
);

export const selectNavigationLayersList = createSelector(
  selectAllNavigationLayers,
  (navigationLayers): EntityList[] => navigationLayers.map(({ id, name }) => ({ id, name }))
);

export const selectNavigationLayersBySelectedMapId = createSelector(
  selectAllNavigationLayers,
  selectMapId,
  (navigationLayers, mapId) => navigationLayers.filter(layer => layer.mapId === mapId)
);

export const selectNoNavigationLayersBySelectedMapId = createSelector(
  selectNavigationLayersLoaded,
  selectMapId,
  selectNavigationLayersBySelectedMapId,
  selectAllNavigationLayers,
  (loaded, selectedMapId, navigationLayersForMap, allLayers) => {
    if (!loaded) {
      return;
    }

    if (selectedMapId === '') return;

    if (navigationLayersForMap.filter(l => l.mapId === selectedMapId).length > 0) {
      return false;
    }

    if (navigationLayersForMap.length === 0) {
      return true;
    }

    if (allLayers.length > 0 && navigationLayersForMap.length === 0) {
      return;
    }

    return allLayers.length === 0 && navigationLayersForMap.length === 0;
  }
);

export const selectSelectedNavigationLayer = createSelector(
  selectNavigationLayerId,
  selectNavigationLayerEntities,
  (selectedNavigationLayerId, navigationLayers) =>
    navigationLayers[selectedNavigationLayerId.toString()]
);
