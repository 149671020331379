/* eslint-disable max-lines */
/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ZoneSetService } from 'core/api-services';
import { MapMode } from 'core/models';
import { TenantRouterService } from 'core/services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { setHasChanges } from 'store/actions/ui.actions';

import * as MapsActions from '../actions/maps.actions';
import * as ZoneSetActions from '../actions/zone-set.actions';
import * as ZoneActions from '../actions/zones.actions';

@Injectable()
export class ZoneSetsEffects {
  loadZoneSets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.loadZoneSets),
      concatMap(() =>
        this.zoneSetService.getZoneSets().pipe(
          map(zs => ZoneSetActions.loadZoneSetsSuccess({ zoneSets: zs })),
          catchError(errorMessage => of(ZoneSetActions.loadZoneSetsFailure({ errorMessage })))
        )
      )
    )
  );

  selectZoneSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.selectZoneSet),
      map(({ zoneSetId }) => ZoneActions.loadZonesByZoneSetId({ zoneSetId }))
    )
  );

  loadZoneSetsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZoneSetActions.loadZoneSetsFailure),
        tap(() => {
          this.toastService.createErrorToast('zoneSets.actions.loadFailure');
        })
      ),
    { dispatch: false }
  );

  addZoneSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.addZoneSet),
      concatMap(state =>
        this.zoneSetService.createEmptyZoneSet(state.zoneSet).pipe(
          map(zoneSet => ZoneSetActions.addZoneSetSuccessAndNavigate({ zoneSet })),
          catchError(errorMessage => of(ZoneSetActions.addZoneSetFailure({ errorMessage })))
        )
      )
    )
  );

  addZoneSetFromTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.addZoneSetFromTemplate),

      concatMap(state =>
        this.zoneSetService.createZoneSetFromTemplate(state.fromZoneSetId, state.zoneSet).pipe(
          concatMap(zoneSet => {
            if (zoneSet.zones.length > 0) {
              return [
                ZoneSetActions.addZoneSetSuccessAndNavigate({ zoneSet }),
                ZoneActions.addZonesSuccess({ zones: zoneSet.zones }),
              ];
            } else {
              return [ZoneSetActions.addZoneSetSuccessAndNavigate({ zoneSet })];
            }
          }),
          catchError(errorMessage => of(ZoneSetActions.addZoneSetFailure({ errorMessage })))
        )
      )
    )
  );

  addZoneSetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.addZoneSetSuccess),
      tap(() => {
        this.toastService.createSuccessToast('zoneSets.actions.created');
      }),
      map(() => MapsActions.setMapMode({ mode: MapMode.None }))
    )
  );

  addZoneSetSuccessAndNavigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.addZoneSetSuccessAndNavigate),
      tap(({ zoneSet }) => {
        this.toastService.createSuccessToast('zoneSets.actions.created');
        void this.tenantRouter.navigate(['/maps', zoneSet.mapId.toString(), zoneSet.id.toString()]);
      }),
      concatMap(() => [
        setHasChanges({ hasChanges: false }),
        MapsActions.setMapMode({ mode: MapMode.None }),
      ])
    )
  );

  addZoneSetFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZoneSetActions.addZoneSetFailure),
        tap(() => {
          this.toastService.createErrorToast('zoneSets.actions.createFailure');
        })
      ),
    { dispatch: false }
  );

  updateZoneSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.updateZoneSet),
      concatMap(state => {
        return this.zoneSetService.updateZoneSet(state.zoneSet).pipe(
          switchMap(zoneSet => [
            ZoneSetActions.updateZoneSetSuccess({
              zoneSet: {
                id: zoneSet.id.toString(),
                changes: zoneSet,
              },
            }),
          ]),
          catchError(errorMessage => of(ZoneSetActions.updateZoneSetFailure({ errorMessage })))
        );
      })
    )
  );

  updateZoneSetSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZoneSetActions.updateZoneSetSuccess),
        tap(() => {
          this.toastService.createSuccessToast('zoneSets.actions.updated');
        })
      ),
    { dispatch: false }
  );

  updateZoneSetFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZoneSetActions.updateZoneSetFailure),
        tap(() => {
          this.toastService.createErrorToast('zoneSets.actions.updatedFailure');
        })
      ),
    { dispatch: false }
  );

  deleteZoneSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.deleteZoneSet),
      concatMap(state => {
        return this.zoneSetService.deleteZoneSet(state.zoneSetId).pipe(
          switchMap(() => [ZoneSetActions.deleteZoneSetSuccess({ zoneSetId: state.zoneSetId })]),
          catchError(errorMessage => of(ZoneSetActions.deleteZoneSetFailure({ errorMessage })))
        );
      })
    )
  );

  deleteZoneSetSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZoneSetActions.deleteZoneSetSuccess),
        tap(() => {
          this.toastService.createSuccessToast('zoneSets.actions.deleteZoneSet');
        })
      ),
    { dispatch: false }
  );

  deleteZoneSetFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZoneSetActions.deleteZoneSetFailure),
        tap(() => {
          this.toastService.createErrorToast('zoneSets.actions.deletedFailure');
        })
      ),
    { dispatch: false }
  );

  setActiveZoneSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.setActiveZoneSet),
      concatMap(state => {
        return this.zoneSetService.setActiveZoneSet(state.setToActiveInput).pipe(
          switchMap(updatedZoneSets => {
            return [
              ZoneSetActions.setActiveZoneSetSuccess({
                zoneSets: updatedZoneSets,
              }),
            ];
          }),
          catchError(errorMessage => of(ZoneSetActions.setActiveZoneSetFailure({ errorMessage })))
        );
      })
    )
  );

  setActiveZoneSetSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZoneSetActions.setActiveZoneSetSuccess),
        tap(() => {
          this.toastService.createSuccessToast('zoneSets.actions.updated');
        })
      ),
    { dispatch: false }
  );

  setActiveZoneSetFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZoneSetActions.setActiveZoneSetFailure),
        tap(() => {
          this.toastService.createErrorToast('zoneSets.actions.updatedFailure');
        })
      ),
    { dispatch: false }
  );

  getHasZoneOverlapsForZoneSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZoneSetActions.getHasZoneOverlapsForZoneSet),
      concatMap(state => {
        return this.zoneSetService.getHasZoneOverlapsForZoneSet(state.zoneSetId).pipe(
          switchMap(hasOverlaps => {
            return [
              ZoneSetActions.getHasZoneOverlapsForZoneSetSuccess({
                hasOverlaps: hasOverlaps,
              }),
            ];
          }),
          catchError(errorMessage =>
            of(ZoneSetActions.getHasZoneOverlapsForZoneSetFailure({ errorMessage }))
          )
        );
      })
    )
  );

  getHasZoneOverlapsForZoneSetSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(ZoneSetActions.getHasZoneOverlapsForZoneSetSuccess)),
    { dispatch: false }
  );

  getHasZoneOverlapsForZoneSetFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZoneSetActions.getHasZoneOverlapsForZoneSetFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly tenantRouter: TenantRouterService,
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly zoneSetService: ZoneSetService
  ) {}
}
