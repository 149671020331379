import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

interface ICellEditorParamsWithMultipleValues extends ICellEditorParams {
  values: string[];
}

@Component({
  selector: 'app-ag-grid-custom-dropdown-editor',
  templateUrl: './ag-grid-custom-dropdown-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridCustomDropdownEditorComponent implements ICellEditorAngularComp {
  cellWidth = 0;
  selectedValue = '';
  options: SelectListOption[] = [];
  private params: ICellEditorParamsWithMultipleValues | undefined;

  constructor(private readonly cdRef: ChangeDetectorRef, private readonly renderer: Renderer2) {}

  agInit(params: ICellEditorParamsWithMultipleValues): void {
    this.params = params;
    this.selectedValue = params.value;
    this.options = (params.values || []).map(value => ({
      id: value,
      label: value,
    }));
    this.cdRef.markForCheck();
  }

  optionSelected(): void {
    this.params?.stopEditing(false);
  }

  getValue(): string {
    return this.selectedValue;
  }

  isPopup(): boolean {
    return true;
  }

  onPopupClick(_event: MouseEvent): void {
    const popupElement = document.querySelector('.ds-select-list');
    this.renderer.addClass(popupElement, 'ag-custom-component-popup');
  }

  afterGuiAttached(): void {
    this.cellWidth = this.params?.eGridCell.clientWidth ?? 0;
  }
}
