type KeyOfType<T> = keyof T;
type UniqueValues<T, K extends KeyOfType<T>> = Array<T[K]>;

export const uniqueValuesByKeyOfObjectArray = <T, K extends KeyOfType<T>>(
  key: K,
  array: T[]
): UniqueValues<T, K> => {
  return [...new Set(array.map(element => element[key]))];
};

export function toMap<T, K>(arr: T[], keySelector: (item: T) => K | undefined): Map<K, T> {
  return arr.reduce((map, item) => {
    const key = keySelector(item);
    if (key != null) map.set(key, item);
    return map;
  }, new Map<K, T>());
}

export function* mergeOrAppend<T>(
  arr: T[],
  update: T,
  keySelector: (item: T) => object | undefined,
  action: (existing: T, update: T) => T
): Generator<T> {
  const updateKey = keySelector(update);
  let didMatch = false;

  for (const existing of arr) {
    if (keySelector(existing) === updateKey) {
      yield action(existing, update);
      didMatch = true;
    } else yield existing;
  }
  if (!didMatch) yield update;
}
