/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MapsService, PoiGroupsService } from 'core/api-services';
import { TenantRouterService } from 'core/services/tenant-router.service';
import { ToastService } from 'core/services/toast.service';
import { ModalDialogService } from 'library/components/modal-dialog';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions/ui.actions';
import * as MapsActions from '../../maps-store/actions/maps.actions';
import * as PoiGroupsActions from '../actions/poi-groups.actions';

@Injectable()
export class PoiGroupsEffects {
  loadPoiGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.loadPoiGroups),
      concatMap(() =>
        this.mapsService.getAllMaps().pipe(
          map(maps => PoiGroupsActions.loadMapsForPoiGroupsSuccess({ maps })),
          catchError(errorMessage => of(MapsActions.loadMapsFailure({ errorMessage })))
        )
      )
    )
  );

  loadMapsForPoiGroupsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.loadMapsForPoiGroupsSuccess),
      filter(({ maps }) => !!maps),
      concatMap(({ maps }) =>
        this.poiGroupsService.getPoiGroupsByMapIds(maps.map(x => x.id)).pipe(
          map(poiGroups => PoiGroupsActions.loadPoiGroupsSuccess({ poiGroups })),
          catchError(errorMessage => of(PoiGroupsActions.loadPoiGroupsFailure({ errorMessage })))
        )
      )
    )
  );

  loadPoiGroupsForMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.loadPoiGroupsForMap),
      filter(({ mapId }) => !!mapId),
      concatMap(({ mapId }) =>
        this.poiGroupsService.getPoiGroupsByMapIds([mapId]).pipe(
          map(poiGroups => PoiGroupsActions.loadPoiGroupsSuccess({ poiGroups })),
          catchError(errorMessage => of(PoiGroupsActions.loadPoiGroupsFailure({ errorMessage })))
        )
      )
    )
  );

  addPoiGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.addPoiGroup),
      concatMap(state =>
        this.poiGroupsService.createPoiGroup(state.poiGroup).pipe(
          map(poiGroup => PoiGroupsActions.addPoiGroupSuccess({ poiGroup })),
          catchError(errorMessage => of(PoiGroupsActions.addPoiGroupFailure({ errorMessage })))
        )
      )
    )
  );

  addPoiGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.addPoiGroupSuccess),
      tap(state => {
        this.toastService.createSuccessToast('shared.actions.created', {
          name: state.poiGroup.name,
        });
        this.router.navigate(['/pois']).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updatePoiGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.updatePoiGroup),
      concatMap(state =>
        this.poiGroupsService.updatePoiGroup(state.poiGroup).pipe(
          map(poiGroup => PoiGroupsActions.updatePoiGroupSuccess({ poiGroup })),
          catchError(errorMessage => of(PoiGroupsActions.updatePoiGroupFailure({ errorMessage })))
        )
      )
    )
  );

  updatePoiGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.updatePoiGroupSuccess),
      tap(state => {
        this.toastService.createSuccessToast('shared.actions.saved', {
          name: state.poiGroup.name,
        });
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  deletePoiGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.deletePoiGroup),
      concatMap(({ poiGroup }) =>
        this.poiGroupsService.deletePoiGroup(poiGroup).pipe(
          map(() => PoiGroupsActions.deletePoiGroupSuccess({ poiGroup })),
          catchError(errorMessage => of(PoiGroupsActions.deletePoiGroupFailure({ errorMessage })))
        )
      )
    )
  );

  deletePoiGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoiGroupsActions.deletePoiGroupSuccess),
        tap(state => {
          this.toastService.createSuccessToast('shared.actions.deleted', {
            name: state.poiGroup.name,
          });
        })
      ),
    { dispatch: false }
  );

  deletePoiFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoiGroupsActions.deletePoiGroupFailure),
        tap(state => this.modalService.createModal('shared.modalDeleteError', state, 'critical'))
      ),
    { dispatch: false }
  );

  ungroupPoiGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.ungroupPoiGroup),
      concatMap(({ poiGroup }) =>
        this.poiGroupsService.ungroupPoiGroup(poiGroup).pipe(
          map(() => PoiGroupsActions.ungroupPoiGroupSuccess({ poiGroup })),
          catchError(errorMessage => of(PoiGroupsActions.ungroupPoiGroupFailure({ errorMessage })))
        )
      )
    )
  );

  ungroupPoiGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoiGroupsActions.ungroupPoiGroupSuccess),
        tap(state => {
          this.toastService.createSuccessToast('shared.actions.ungrouped', {
            name: state.poiGroup.name,
          });
        })
      ),
    { dispatch: false }
  );

  ungroupPoiGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoiGroupsActions.ungroupPoiGroupFailure),
        tap(state =>
          this.modalService.createModal('poiGroups.modalUngroupError', state, 'critical')
        )
      ),
    { dispatch: false }
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PoiGroupsActions.loadPoiGroupsFailure,
          PoiGroupsActions.addPoiGroupFailure,
          PoiGroupsActions.updatePoiGroupFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly poiGroupsService: PoiGroupsService,
    private readonly toastService: ToastService,
    private readonly modalService: ModalDialogService,
    private readonly router: TenantRouterService,
    private readonly mapsService: MapsService
  ) {}
}
