import { createSelector, MemoizedSelector } from '@ngrx/store';
import { OrganizationDto, WorkingAreaDto } from 'core/dtos';
import { GuidString, WorkingArea } from 'core/models';
import { flatten } from 'lodash';
import * as fromWorkingAreas from 'store/reducers/working-areas.reducer';
import * as fromRoot from '../reducers/index';
import * as fromOrganizations from './organizations.selectors';

export const selectWorkingAreasLoading = createSelector(
  fromRoot.getWorkingAreasState,
  fromWorkingAreas.getWorkingAreasLoading
);

export const selectWorkingAreasLoaded = createSelector(
  fromRoot.getWorkingAreasState,
  fromWorkingAreas.getWorkingAreasLoaded
);

export const selectWorkingAreasErrorMessage = createSelector(
  fromRoot.getWorkingAreasState,
  fromWorkingAreas.getWorkingAreasErrorMessage
);

export const selectAllWorkingAreas = createSelector(
  fromRoot.getWorkingAreasState,
  fromWorkingAreas.getWorkingAreas
);

export const selectWorkingAreasEntities = createSelector(
  fromRoot.getWorkingAreasState,
  fromWorkingAreas.getWorkingAreasEntities
);

export const selectSelectedWorkingAreaId = createSelector(
  fromRoot.getWorkingAreasState,
  fromWorkingAreas.getSelectedWorkingAreaId
);

export const selectSelectedWorkingArea = createSelector(
  selectSelectedWorkingAreaId,
  selectWorkingAreasEntities,
  (workingAreaId, workingAreas) => workingAreas[String(workingAreaId)]
);

export const selectReducedWorkingAreas = createSelector(
  fromOrganizations.selectAllOrganizations,
  organizations => {
    return flatten(
      organizations.map(org =>
        org.workAreas.map(workArea => ({
          ...workArea,
          organizationName: org.name,
          organizationId: org.id,
        }))
      )
    );
  }
);

export const selectSelectedOrganizationReducedWorkingAreas = createSelector(
  fromOrganizations.selectSelectedOrganizationId,
  selectReducedWorkingAreas,
  (organizationId, workingAreas) => {
    return workingAreas.filter(workingArea => workingArea.organizationId === organizationId);
  }
);

export const selectReducedSelectedWorkingArea = createSelector(
  selectSelectedWorkingAreaId,
  fromOrganizations.selectSelectedOrganizationId,
  selectReducedWorkingAreas,
  (workingAreaId, organizationId, workingAreas) => {
    return workingAreas.find(
      workingArea =>
        workingArea.organizationId === organizationId && workingArea.id === workingAreaId
    );
  }
);

export const selectOrganizationByWorkingAreaId = (
  workingAreaId: GuidString
): MemoizedSelector<object, OrganizationDto | undefined> =>
  createSelector(
    fromOrganizations.selectAllOrganizations,
    selectReducedWorkingAreas,
    (organizations: OrganizationDto[], workingAreas: WorkingArea[] = []) => {
      const workingArea = workingAreas.find(wa => wa.id === workingAreaId);
      return organizations.find(org => org.id === workingArea?.organizationId);
    }
  );

export const selectWorkingAreaById = (
  workAreaId: GuidString
): MemoizedSelector<object, WorkingAreaDto | undefined> =>
  createSelector(fromOrganizations.selectAllOrganizations, (organizations: OrganizationDto[]) => {
    const workingAreas = flatten(organizations.map(o => o.workAreas || []));
    return workingAreas.find(wa => wa.id === workAreaId);
  });
