/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TourChainConfigurationService } from 'core/api-services';
import { TourChainConfigurationDto } from 'core/dtos';
import { TenantRouterService } from 'core/services/tenant-router.service';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions';
import * as TourChainConfigurationActions from '../actions/tour-chain-configuration.actions';

@Injectable()
export class TourChainConfigurationEffects {
  loadTourChainConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TourChainConfigurationActions.loadTourChainConfigurations),
      concatMap(() =>
        this.tourChainConfigurationService.getAllTourChainConfigurations().pipe(
          map(tourChainConfigurations => {
            return TourChainConfigurationActions.loadTourChainConfigurationsSuccess({
              tourChainConfigurations,
            });
          }),
          catchError(errorMessage =>
            of(TourChainConfigurationActions.loadTourChainConfigurationsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadTourChainConfigurationsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TourChainConfigurationActions.loadTourChainConfigurationsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.loadMapsFailure');
        })
      ),
    { dispatch: false }
  );

  createTourChainConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TourChainConfigurationActions.createTourChainConfiguration),
      concatMap(({ tourChainConfig }) =>
        this.tourChainConfigurationService.createTourChainConfiguration(tourChainConfig).pipe(
          map(tourChainConfig =>
            TourChainConfigurationActions.createTourChainConfigurationSuccess({ tourChainConfig })
          ),
          catchError(errorMessage =>
            of(TourChainConfigurationActions.createTourChainConfigurationFailure({ errorMessage }))
          )
        )
      )
    )
  );

  createTourChainConfigSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TourChainConfigurationActions.createTourChainConfigurationSuccess),
      tap(({ tourChainConfig }) => {
        this.toastService.createSuccessToast('shared.actions.created', {
          name: tourChainConfig.name,
        });
        this.router.navigate(['/jobs/processConfigurator']).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  createTourChainConfigFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TourChainConfigurationActions.createTourChainConfigurationFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  updateTourChainConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TourChainConfigurationActions.updateTourChainConfiguration),
      map(action => action.tourChainConfiguration),
      concatMap(payload => {
        return this.tourChainConfigurationService.updateTourChainConfiguration(payload).pipe(
          map(() =>
            TourChainConfigurationActions.updateTourChainConfigurationSuccess({
              tourChainConfiguration: {
                id: payload.id.toString(),
                changes: payload,
              },
            })
          ),
          catchError(errorMessage =>
            of(
              TourChainConfigurationActions.updateTourChainConfigurationFailure({
                errorMessage,
                tourChainConfiguration: payload,
              })
            )
          )
        );
      })
    )
  );

  updateTourChainConfigurationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TourChainConfigurationActions.updateTourChainConfigurationSuccess),
      tap(({ tourChainConfiguration }) => {
        this.toastService.createSuccessToast(
          'shared.actions.updated',
          tourChainConfiguration.changes
        );
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateTourChainConfigurationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TourChainConfigurationActions.updateTourChainConfigurationFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  deleteTourChainConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TourChainConfigurationActions.deleteTourChainConfiguration),
      map(action => action.tourChainConfiguration),
      concatMap((tourChainConfiguration: TourChainConfigurationDto) => {
        return this.tourChainConfigurationService
          .deleteTourChainConfiguration(tourChainConfiguration.id)
          .pipe(
            map(() =>
              TourChainConfigurationActions.deleteTourChainConfigurationSuccess({
                tourChainConfiguration,
              })
            ),
            catchError(errorMessage =>
              of(
                TourChainConfigurationActions.deleteTourChainConfigurationFailure({ errorMessage })
              )
            )
          );
      })
    )
  );

  deleteTourChainConfigurationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TourChainConfigurationActions.deleteTourChainConfigurationSuccess),
        tap(({ tourChainConfiguration }) => {
          this.toastService.createSuccessToast('shared.actions.deleted', {
            name: tourChainConfiguration.name,
          });
          void this.router.navigate(['jobs/processConfigurator']);
        })
      ),
    { dispatch: false }
  );

  deleteTourChainConfigurationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TourChainConfigurationActions.deleteTourChainConfigurationFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'jobs.tourChainConfig.deleteTourChainConfigurationFailure'
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly tourChainConfigurationService: TourChainConfigurationService,
    private readonly toastService: ToastService,
    private readonly router: TenantRouterService
  ) {}
}
