import { createSelector } from '@ngrx/store';
import * as fromGraphManagerFeatureState from '../reducers';
import * as fromRouteConfigurationsReducer from '../reducers/route-configuration.reducer';

const selectGraphManagerState = createSelector(
  fromGraphManagerFeatureState.getGraphManagerFeatureState,
  fromGraphManagerFeatureState.getRouteConfigurationsState
);

export const selectRouteConfigurationsLoading = createSelector(
  selectGraphManagerState,
  fromRouteConfigurationsReducer.getLoading
);

export const selectRouteConfigurationsLoaded = createSelector(
  selectGraphManagerState,
  fromRouteConfigurationsReducer.getLoaded
);

export const selectAllRouteConfigurations = createSelector(
  selectGraphManagerState,
  fromRouteConfigurationsReducer.getRouteConfigurations
);
