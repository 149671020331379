<ds-modal
  [dimensionsConfig]="{ width: '535px' }"
  #missionErrorModal
  [ds-modal-trigger-for]="missionErrorModal"
  [(isModalOpen)]="isModalOpen"
  (modalDismiss)="onClose()">
  <ds-box-header data-cy="dialogHeader"
    >{{ 'settings.functions.missionErrorHandlingDefaults.title' | translate }}
  </ds-box-header>
  <ds-box-content divider="full" data-cy="dialog-content">
    <div class="modalMessageText2">
      <span data-cy="description">
        {{ 'settings.functions.missionErrorHandlingDefaults.description' | translate }}</span
      >
    </div>

    <div class="container-fluid g-0">
      <div class="row mb-2x">
        <div class="col">
          <app-labeled-text
            label="{{
              'settings.featureToggles.jobAssignmentSection.missionErrorHandlingDefaultMainOption'
                | translate
            }}"></app-labeled-text>
        </div>
        <div class="col">
          <app-labeled-text
            label="{{
              'settings.featureToggles.jobAssignmentSection.defaultValuesForCancelAbort' | translate
            }}"></app-labeled-text>
        </div>
      </div>
      <div class="row">
        <div class="col" style="padding-top: 0">
          <div>
            <ds-radio-group variant="stacked">
              <label for="defaultErrorHandlingOptions">
                <input
                  ds-input
                  id="defaultErrorHandlingOptions"
                  name="defaultErrorHandlingOptions"
                  type="radio"
                  [value]="errorHandlingOptions.Abort"
                  [(ngModel)]="viewModel.defaultErrorHandlingOption"
                  data-cy="abortOption" />
                <span>{{ 'shared.missionErrorHandlingModal.abort' | translate }}</span>
              </label>

              <label for="continueFromStepOption">
                <input
                  ds-input
                  id="continueFromStepOption"
                  name="continueFromStepOption"
                  type="radio"
                  [value]="errorHandlingOptions.ContinueFromStep"
                  [(ngModel)]="viewModel.defaultErrorHandlingOption"
                  data-cy="continueFromStepOption" />
                <span>{{ 'shared.missionErrorHandlingModal.continueFromStep' | translate }}</span>
              </label>

              <label for="reassignMissionOption">
                <input
                  ds-input
                  id="reassignMissionOption"
                  name="reassignMissionOption"
                  type="radio"
                  [value]="errorHandlingOptions.ReassignMission"
                  [(ngModel)]="viewModel.defaultErrorHandlingOption"
                  data-cy="reassignMissionOption" />
                <span>{{ 'shared.missionErrorHandlingModal.reassignMission' | translate }}</span>
              </label>
            </ds-radio-group>
          </div>
        </div>
        <div class="col" style="padding-top: 0">
          <div class="py-2x errorHandlingLabel">
            <ds-switch
              data-cy="confirmInterlock"
              [(ngModel)]="viewModel.releaseInterlock"
              name="confirmInterlock"></ds-switch>
            <span data-cy="confirmInterlockText"
              >{{ 'shared.missionErrorHandlingModal.confirmInterlock' | translate }}
              <i
                class="pi pi-info-circle"
                ds-tooltip="{{
                  'shared.missionErrorHandlingModal.confirmInterlockTooltip' | translate
                }}"
                [dsTooltipConfig]="{ width: '200px' }"></i>
            </span>
          </div>

          <div class="py-2x errorHandlingLabel">
            <ds-switch
              data-cy="confirmToCallOffSystem"
              [(ngModel)]="viewModel.confirmAbortToSap"
              name="confirmToCallOffSystem"></ds-switch>
            <span data-cy="confirmToCallOffSystemText"
              >{{ 'shared.missionErrorHandlingModal.confirmToCallOffSystem' | translate }}
              <i
                class="pi pi-info-circle"
                ds-tooltip="{{
                  'shared.missionErrorHandlingModal.confirmToCallOffSystemTooltip' | translate
                }}"
                [dsTooltipConfig]="{ width: '200px' }"></i>
            </span>
          </div>

          <ng-container *ngIf="viewModel.confirmAbortToSap">
            <ds-radio-group variant="stacked" class="ms-12x">
              <label for="confirmAsFinished">
                <input
                  ds-input
                  id="confirmAsFinished"
                  name="confirmAsFinished"
                  type="radio"
                  [value]="sapConfirmationAfterAbort.SendAsConfirmation"
                  [(ngModel)]="viewModel.confirmAbortToSapAs"
                  data-cy="confirmAsFinished" />
                <span>{{ 'shared.missionErrorHandlingModal.confirmAsFinished' | translate }}</span>
              </label>

              <label for="confirmAsAborted">
                <input
                  ds-input
                  id="confirmAsAborted"
                  name="confirmAsAborted"
                  type="radio"
                  [value]="sapConfirmationAfterAbort.SendAsCancel"
                  [(ngModel)]="viewModel.confirmAbortToSapAs"
                  data-cy="confirmAsAborted" />
                <span>{{ 'shared.missionErrorHandlingModal.confirmAsAborted' | translate }}</span>
              </label>
            </ds-radio-group>
          </ng-container>
        </div>
      </div>
    </div>
  </ds-box-content>
  <ds-box-footer data-cy="dialog-footer">
    <button ds-button [variant]="'outline'" (click)="onClose()" data-cy="cancelButton">
      {{ 'shared.modalButtons.CancelButton' | translate }}
    </button>
    <button ds-button class="ms-2x" (click)="onConfirm()" data-cy="saveButton">
      {{ 'shared.editBar.save' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
