import { Pipe, PipeTransform } from '@angular/core';
import { uniqBy } from 'lodash';

@Pipe({
  name: 'uniqueBy',
})
export class UniqueByPipe implements PipeTransform {
  transform(options: object[] | undefined | null, filterBy = 'name'): object[] | undefined {
    return uniqBy(options, filterBy);
  }
}
