import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BlobUploadCommonResponse,
  BlockBlobClient,
  BlockBlobParallelUploadOptions,
} from '@azure/storage-blob';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { BaseHttpClient } from 'core/http/base-http-client';
import {
  GuidString,
  HardwareVersion,
  SoftwareUpdateDispatch,
  SoftwareUpdateDispatchResult,
  SoftwareUpdateDto,
  SoftwareUpdateStageResult,
  SoftwareUpdateVehicleRecord,
} from 'core/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SoftwareManagementService extends BaseHttpClient {
  protected apiUrl = environment.Services.FleetManager;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  private readonly servicePathSoftwareUpdates = API_SERVICES.SoftwareUpdates;
  private readonly servicePathSoftwareUpdateDispatch = API_SERVICES.SoftwareUpdateDispatch;
  private readonly servicePathSoftwareRetry = API_SERVICES.SoftwareUpdateDispatchRetry;

  getSoftwareUpdates(hardwareVersion: HardwareVersion): Observable<SoftwareUpdateDto[]> {
    return this.get<SoftwareUpdateDto[]>(`${this.servicePathSoftwareUpdates}/${hardwareVersion}`);
  }

  stageUpdate(
    hardwareVersion: HardwareVersion,
    version: string
  ): Observable<SoftwareUpdateStageResult> {
    return this.put<SoftwareUpdateStageResult>(
      `${this.servicePathSoftwareUpdates}/${hardwareVersion}/${version}/stage`
    );
  }

  commitUpdate(
    hardwareVersion: HardwareVersion,
    version: string,
    update: SoftwareUpdateDto,
    approved: boolean
  ): Observable<never> {
    return this.put<never>(
      `${this.servicePathSoftwareUpdates}/${hardwareVersion}/${version}?approved=${approved}`,
      update
    ).pipe();
  }

  deleteUpdate(hardwareVersion: HardwareVersion, version: string): Observable<void> {
    return this.delete<void>(`${this.servicePathSoftwareUpdates}/${hardwareVersion}/${version}`);
  }

  async uploadToAzure(
    sasUri: string,
    fileToUpload: File,
    options?: BlockBlobParallelUploadOptions
  ): Promise<BlobUploadCommonResponse> {
    const blockBlobClient = new BlockBlobClient(sasUri);

    return blockBlobClient.uploadData(fileToUpload, options);
  }

  dispatchUpdate(
    hardwareVersion: HardwareVersion,
    version: string,
    softwareUpdateDispatch: SoftwareUpdateDispatch
  ): Observable<SoftwareUpdateDispatchResult> {
    return this.post<SoftwareUpdateDispatchResult>(
      `${this.servicePathSoftwareUpdateDispatch}/${hardwareVersion}/${version}`,
      softwareUpdateDispatch
    );
  }

  retryUpdate(vehicleId: GuidString): Observable<SoftwareUpdateVehicleRecord> {
    return this.post<SoftwareUpdateVehicleRecord>(
      `${this.servicePathSoftwareRetry}/${vehicleId}`,
      {}
    );
  }

  approveUpdate(hardwareVersion: HardwareVersion, version: string): Observable<void> {
    return this.put<void>(
      `${this.servicePathSoftwareUpdates}/${hardwareVersion}/${version}/approve`
    );
  }
}
