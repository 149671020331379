import { MissionDeliveryStatus, MissionReportCategory, MissionStatus } from 'core/models';

export const missionStatus = {
  [MissionStatus.Completed]: '#00ff00',
  [MissionStatus.CompletedWithSupport]: '#00ff00',
  [MissionStatus.Aborted]: '#ff0000',
};

export const report = {
  [MissionReportCategory.IO]: '#7ed321',
  [MissionReportCategory.Organizational]: '#f5a623',
  [MissionReportCategory.CallOffSystem]: '#f8e71c',
  [MissionReportCategory.Vehicle]: '#f05960',
  [MissionReportCategory.Services]: '#00d9d8',
  [MissionReportCategory.NoReasonAssigned]: '#ccc',
  [MissionReportCategory.NotRelevant]: '#8f8f8f',
  [MissionReportCategory.SystemOptimization]: '#000000',
};

export const deliveryStatus = {
  [MissionDeliveryStatus.Overdue]: '#D12E24', //Icon/critical
  [MissionDeliveryStatus.DueShortly]: '#D12E24', //Icon/critical
  [MissionDeliveryStatus.DueShortlyPriority]: '#D12E24', //Icon/critical
  [MissionDeliveryStatus.Due]: '#F0CA1A', //Surfave/Signal/Caution/strong
  [MissionDeliveryStatus.DuePriority]: '#F0CA1A', //Surfave/Signal/Caution/strong
  [MissionDeliveryStatus.NotUrgent]: '#5BA161', //--ds-color-green-400
  [MissionDeliveryStatus.NotUrgentPriority]: '#5BA161', //--ds-color-green-400
  [MissionDeliveryStatus.Normal]: '#6A707A', //Icon/neutral
  [MissionDeliveryStatus.ValidationError]: '#D12E24', //Icon/critical
  [MissionDeliveryStatus.UnsupportedVehicleAction]: '#D12E24', //Icon/critical
  [MissionDeliveryStatus.VehicleOutDatedOrder]: '#D12E24', //Icon/critical
};
