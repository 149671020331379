import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { WorkingAreaDto } from 'core/dtos';
import { GuidString } from 'core/models';
import * as WorkingAreaActions from 'store/actions/working-areas.actions';
export const featureKey = 'workingAreas';

export interface WorkingAreasState extends EntityState<WorkingAreaDto> {
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
  selectedWorkingAreaId: GuidString;
}

export const workingAreasAdapter: EntityAdapter<WorkingAreaDto> =
  createEntityAdapter<WorkingAreaDto>();

export const initialState: WorkingAreasState = workingAreasAdapter.getInitialState({
  loaded: false,
  loading: false,
  errorMessage: '',
  selectedWorkingAreaId: '',
});

const workingAreasReducer = createReducer(
  initialState,
  on(WorkingAreaActions.loadWorkingAreasSuccess, (state, { workingAreas }) =>
    workingAreasAdapter.setAll(workingAreas, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(WorkingAreaActions.addWorkingAreaSuccess, (state, { workingArea }) =>
    workingAreasAdapter.addOne(workingArea, {
      ...state,
    })
  ),

  on(WorkingAreaActions.updateWorkingAreaSuccess, (state, { workingArea }) =>
    workingAreasAdapter.updateOne(
      { id: workingArea.id.toString(), changes: workingArea },
      {
        ...state,
      }
    )
  ),

  on(WorkingAreaActions.selectWorkingArea, (state, { workingAreaId }) => ({
    ...state,
    selectedWorkingAreaId: workingAreaId,
  })),

  on(WorkingAreaActions.deleteWorkingAreaSuccess, (state, { workingArea }) => {
    return workingAreasAdapter.removeOne(workingArea.id.toString(), state);
  }),

  on(
    WorkingAreaActions.addWorkingAreaFailure,
    WorkingAreaActions.loadWorkingAreasFailure,
    WorkingAreaActions.deleteWorkingAreaFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
    })
  )
);

export function reducer(state: WorkingAreasState | undefined, action: Action): WorkingAreasState {
  return workingAreasReducer(state, action);
}
export const { selectEntities, selectAll } = workingAreasAdapter.getSelectors();

export const getWorkingAreasLoading = (state: WorkingAreasState): boolean => state.loading;
export const getWorkingAreasLoaded = (state: WorkingAreasState): boolean => state.loaded;
export const getWorkingAreasErrorMessage = (state: WorkingAreasState): string => state.errorMessage;
export const getSelectedWorkingAreaId = (state: WorkingAreasState): GuidString =>
  (state || initialState).selectedWorkingAreaId;
export const getWorkingAreas = selectAll;
export const getWorkingAreasEntities = selectEntities;
