import { createSelector } from '@ngrx/store';
import * as fromHuLookupMappings from '../reducers/index';
import * as fromHuLookupMappingsReducer from '../reducers/hu-lookup-mapping.reducer';

const selectHuLookupMappingState = createSelector(
  fromHuLookupMappings.getOpcuaDevicesFeatureState,
  fromHuLookupMappings.getHuLookupMappingState
);

export const selectHuLookupMappingsLoaded = createSelector(
  selectHuLookupMappingState,
  fromHuLookupMappingsReducer.getHuLookupMappingsLoaded
);

export const selectAllHuLookupMappings = createSelector(
  selectHuLookupMappingState,
  fromHuLookupMappingsReducer.getAllHuLookupMappings
);

export const selectHuLookupMappingEntities = createSelector(
  selectHuLookupMappingState,
  fromHuLookupMappingsReducer.getHuLookupMappingEntities
);
