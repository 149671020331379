import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RouteConfigurationDto } from 'core/dtos';
import * as RouteConfigurationActionTypes from '../actions/route-configuration.actions';

export const featureKey = 'routeConfigurations';

export interface RouteConfigurationState extends EntityState<RouteConfigurationDto> {
  loading: boolean;
  loaded: boolean;
  errorMessage: string;
  ids: string[];
}

export const routeConfigurationAdapter: EntityAdapter<RouteConfigurationDto> =
  createEntityAdapter<RouteConfigurationDto>({
    selectId: (routeConfiguration: RouteConfigurationDto) => routeConfiguration.id.toString(),
  });

export const initialState: RouteConfigurationState = routeConfigurationAdapter.getInitialState({
  loading: false,
  loaded: false,
  errorMessage: '',
  ids: [],
});

export const routeConfigurationReducer = createReducer(
  initialState,
  on(RouteConfigurationActionTypes.loadRouteConfigurations, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(
    RouteConfigurationActionTypes.loadRouteConfigurationsSuccess,
    (state, { routeConfigurations }) =>
      routeConfigurationAdapter.setAll(routeConfigurations, {
        ...state,
        loading: false,
        loaded: true,
      })
  ),

  on(RouteConfigurationActionTypes.createRouteConfiguration, state => ({
    ...state,
    errorMessage: '',
  })),

  on(RouteConfigurationActionTypes.createRouteConfigurationSuccess, (state, { routeConfig }) =>
    routeConfigurationAdapter.addOne(routeConfig, {
      ...state,
    })
  ),

  on(RouteConfigurationActionTypes.updateRouteConfiguration, state => ({
    ...state,
    errorMessage: '',
  })),

  on(RouteConfigurationActionTypes.updateRouteConfigurationSuccess, (state, { routeConfig }) =>
    routeConfigurationAdapter.updateOne(
      { id: routeConfig.id.toString(), changes: routeConfig },
      state
    )
  ),

  on(
    RouteConfigurationActionTypes.updateBatchRouteConfigurationSuccess,
    (state, { routeConfigList }) => {
      return routeConfigurationAdapter.updateMany(
        routeConfigList.map(routeConfigList => updateRouteConfigList(routeConfigList)),
        { ...state }
      );
    }
  ),

  on(
    // RouteConfigurationActionTypes.signalRDeleteRouteConfiguration, should we also cater for signalR
    RouteConfigurationActionTypes.deleteRouteConfigurationSuccess,
    (state, { routeConfig }) =>
      routeConfigurationAdapter.removeOne(routeConfig.id.toString(), state)
  ),

  on(RouteConfigurationActionTypes.createRouteConfigurationFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  }))
);

export function reducer(
  state: RouteConfigurationState | undefined,
  action: Action
): RouteConfigurationState {
  return routeConfigurationReducer(state, action);
}

export const { selectEntities, selectAll } = routeConfigurationAdapter.getSelectors();
export const getProcessChains = selectAll;
export const getEntities = selectEntities;
export const getLoading = (state: RouteConfigurationState): boolean => state.loading;
export const getLoaded = (state: RouteConfigurationState): boolean => state.loaded;
export const getRouteConfigurations = selectAll;

const updateRouteConfigList = (route: RouteConfigurationDto): Update<RouteConfigurationDto> => {
  return {
    id: route.id.toString(),
    changes: {
      color: route.color,
      endNodeId: route.endNodeId,
      name: route.name,
      switches: route.switches,
    },
  };
};
