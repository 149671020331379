import { createAction, props } from '@ngrx/store';
import { IntersectionCollisionPoint } from 'core/dtos';
import { GuidString } from 'core/models';

export enum IntersectionCollisionPointsTypes {
  LoadIntersectionCollisionPoints = '[Intersection Collision] Load Intersection Collision Points',
  LoadIntersectionCollisionPointsSuccess = '[Intersection Collision] Load Intersection Collision Points Success',
  LoadIntersectionCollisionPointsFailure = '[Intersection Collision] Load Intersection Collision Points Fail',
}

export enum SignalRIntersectionCollisionPointsTypes {
  SignalrCreateIntersectionCollisionPoint = '[SignalR] Create Intersection Collision Point',
  SignalrDeleteIntersectionCollisionPoint = '[SignalR] Delete Intersection Collision Point',
  SignalrUpdateIntersectionCollisionPoint = '[SignalR] Update Intersection Collision Point',
  SignalRUpdateIntersectionCollisionPointList = '[SignalR] Update Intersection Collision Point List',
}

export const loadIntersectionCollisionPoints = createAction(
  IntersectionCollisionPointsTypes.LoadIntersectionCollisionPoints,
  props<{ mapId: GuidString }>()
);

export const loadIntersectionCollisionPointsSuccess = createAction(
  IntersectionCollisionPointsTypes.LoadIntersectionCollisionPointsSuccess,
  props<{ intersectionCollisionPoints: IntersectionCollisionPoint[] }>()
);

export const loadIntersectionCollisionPointsFailure = createAction(
  IntersectionCollisionPointsTypes.LoadIntersectionCollisionPointsFailure,
  props<{ errorMessage: string }>()
);

export const signalRCreateIntersectionCollisionPoint = createAction(
  SignalRIntersectionCollisionPointsTypes.SignalrCreateIntersectionCollisionPoint,
  props<{ intersectionCollisionPoints: IntersectionCollisionPoint }>()
);

export const signalRUpdateIntersectionCollisionPoint = createAction(
  SignalRIntersectionCollisionPointsTypes.SignalrUpdateIntersectionCollisionPoint,
  props<{ intersectionCollisionPoints: IntersectionCollisionPoint }>()
);

export const signalRUpdateIntersectionCollisionPointList = createAction(
  SignalRIntersectionCollisionPointsTypes.SignalRUpdateIntersectionCollisionPointList,
  props<{ intersectionCollisionPointList: IntersectionCollisionPoint[] }>()
);

export const signalRDeleteIntersectionCollisionPoint = createAction(
  SignalRIntersectionCollisionPointsTypes.SignalrDeleteIntersectionCollisionPoint,
  props<{ intersectionCollisionPoints: IntersectionCollisionPoint }>()
);
