/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { VehicleService } from 'core/api-services';
import { filterUndefined } from 'core/helpers';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { ToastService } from 'core/services/toast.service';
import * as ErrorActions from '../actions/errors.actions';

@Injectable()
export class ErrorEffects {
  loadErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorActions.loadErrors),
      map(action => action.httpParams),
      filterUndefined(),
      concatMap(params =>
        this.vehicleService.getErrorAggregatesViaOData(params).pipe(
          map(
            entities =>
              ErrorActions.loadErrorAggregateSuccess({
                errors: entities.value,
                count: entities['@odata.count'] || 0,
              }),
            catchError(errorMessage => of(ErrorActions.loadErrorAggregateFailure(errorMessage)))
          )
        )
      )
    )
  );

  loadErrorsByVehicleId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorActions.loadErrorsByVehicleId),
      concatMap(({ vehicleId }) =>
        this.vehicleService.getErrorsByVehicleId(vehicleId).pipe(
          map(
            ({ value: errors }) =>
              ErrorActions.loadErrorAggregateSuccess({ errors, count: errors.length }),
            catchError(errorMessage => of(ErrorActions.loadErrorAggregateFailure(errorMessage)))
          )
        )
      )
    )
  );

  fails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActions.loadErrorAggregateFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly vehicleService: VehicleService
  ) {}
}
