import { TreeTableSortOptions } from 'library/components/tree-table/tree-table.model';

export function saveTableSort(storageName: string, options: TreeTableSortOptions): void {
  localStorage.setItem(storageName, JSON.stringify(options));
}

export function getSavedTableSort(
  storageName: string,
  fieldname = 'name',
  sortOrder = 1
): TreeTableSortOptions {
  const tableSort = localStorage.getItem(storageName);
  if (tableSort) {
    return JSON.parse(tableSort) as TreeTableSortOptions;
  }

  return { field: fieldname, order: sortOrder };
}
