import { createSelector } from '@ngrx/store';

import * as fromMapsFeatureState from '../reducers';
import * as fromIntersectionCollisions from '../reducers/intersection-collision-points.reducer';

import { selectMapId } from './maps.selectors';

const selectIntersectionCollisionsState = createSelector(
  fromMapsFeatureState.getMapsFeatureState,
  fromMapsFeatureState.getIntersectionCollisionsState
);

export const selectIntersectionCollisionsLoaded = createSelector(
  selectIntersectionCollisionsState,
  fromIntersectionCollisions.getIntersectionCollisionsLoaded
);

export const selectIntersectionCollisionsErrorMessage = createSelector(
  selectIntersectionCollisionsState,
  fromIntersectionCollisions.getIntersectionCollisionErrorMessage
);

export const selectIntersectionCollisionsEntities = createSelector(
  selectIntersectionCollisionsState,
  fromIntersectionCollisions.getIntersectionCollisionEntities
);

export const selectAllIntersectionCollisions = createSelector(
  selectIntersectionCollisionsState,
  fromIntersectionCollisions.getAllIntersectionCollisions
);

export const selectIntersectionCollisionsByMapId = createSelector(
  selectAllIntersectionCollisions,
  selectMapId,
  (intersectionCollisions, mapId) =>
    intersectionCollisions.filter(intersectionCollision => intersectionCollision.mapId === mapId)
);
