/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { RolesService, UserService } from 'core/api-services';
import { OrganizationDto, RoleDto } from 'core/dtos';
import {
  AssignUserRoleCommand,
  AtsActions,
  BasicMenuItem,
  ExtendedDsMenuItem,
  RoleScope,
  ToolBarControlKeys,
  ToolBarControls,
  ToolBarItem,
  User,
  UserWithRole,
} from 'core/models';
import { AtsTranslationService } from 'core/services/ats-translation.service';
import { PermissionService } from 'core/services/permission.service';
import { ToolbarService } from 'core/services/toolbar.service';
import { ModalDialogService } from 'library/components/modal-dialog';
import { Observable, Subject, firstValueFrom, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { AssignUserRoleDialogInput, AssignUserRoleModalInputModal } from 'shared/components';

import { DEBOUNCE_TIME } from 'core/constants';
import { filterUndefined } from 'core/helpers';
import { ToastService } from 'core/services';
import * as fromRoot from 'store/index';

type SpecialOrgRoles = 'Organization Admin';
@Component({
  selector: 'app-all-organization-users-container',
  templateUrl: './all-organization-users-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllOrganizationUsersContainerComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  selectedOrganization?: OrganizationDto;
  $organizationUsers: Observable<UserWithRole[]> = of([]);
  TOOLBAR_ITEMS: ToolBarItem[] = [];
  searchTerm$: Observable<string> = of('');
  menuItems: ExtendedDsMenuItem[] = [];
  selectedUser?: UserWithRole;
  selectedOrganizationId = '';
  canCreateOrganizationAdmin = false;
  canAddOrganizationUser = false;
  isModalOpen = false;
  userDetailsData?: AssignUserRoleDialogInput;
  private canEditDeleteOrganizationUser = false;
  private users: User[] = [];
  private organizationRoles: RoleDto[] = [];
  private readonly organizationKey = 'settings.users.organization';
  isAssignUserModalOpen = false;
  assignUserRoleModalInput: AssignUserRoleModalInputModal | undefined;

  constructor(
    private readonly userService: UserService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly toolbarService: ToolbarService,
    private readonly atsTranslateService: AtsTranslationService,
    private readonly modalService: ModalDialogService,
    private readonly toastService: ToastService,
    private readonly rolesService: RolesService,
    private readonly permissionService: PermissionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getUserPermissions();
    this.buildToolBarItems();
    this.dispatchActionsAndQuerySelectors();
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private buildToolBarItems(): void {
    this.menuItems = this.getMenuItems();
    this.TOOLBAR_ITEMS = [
      {
        key: ToolBarControlKeys.AddUser,
        type: ToolBarControls.Button,
        hasSeparatorBorder: true,
        command: this.onAdd.bind(this),
        visible: this.canCreateOrganizationAdmin,
      },
      {
        key: ToolBarControlKeys.Search,
        type: ToolBarControls.Search,
      },
    ];

    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
  }

  private dispatchActionsAndQuerySelectors(): void {
    this.searchTerm$ = this.toolbarService.searchTerm$.pipe(
      debounceTime(DEBOUNCE_TIME),
      distinctUntilChanged()
    );
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
  }

  private initializeSubscriptions(): void {
    this.route.paramMap?.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.selectedOrganizationId = params.get('organizationId') || '';
      this.subscribeToSelectedOrganization();
      try {
        void firstValueFrom(this.userService.getUsers()).then(users => {
          this.users = users;
        });
      } catch (error) {
        if (error instanceof Error) {
          this.toastService.createErrorToast(error.message);
        }
      }
    });
    this.getOrganizationRoles();
  }

  private subscribeToSelectedOrganization(): void {
    this.rootStore
      .pipe(
        select(fromRoot.selectOrganizationById(this.selectedOrganizationId)),
        filterUndefined(),
        take(1)
      )
      .subscribe(org => {
        this.setSelectedOrganization(org);
        this.loadOrganizationUsers();
        this.getUserPermissions();
        this.buildToolBarItems();
        this.cdRef.markForCheck();
      });
  }

  private loadOrganizationUsers(): void {
    if (this.selectedOrganization) {
      this.$organizationUsers = this.userService
        .getOrganizationUsers(this.selectedOrganization.id)
        .pipe(
          map(a =>
            a.map(o => {
              return {
                id: o.id,
                name: o.name,
                email: o.email,
                userRoleId: o.roles[0].id,
                role: o.roles[0].name,
                roleId: o.roles[0].roleId,
                department: o.department,
              };
            })
          )
        );
      this.cdRef.markForCheck();
    }
  }

  private getOrganizationRoles(): void {
    this.rolesService
      .getRolesByScopeWithoutPermissions(RoleScope.Organization)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(roles => {
        const role: SpecialOrgRoles = 'Organization Admin';
        this.organizationRoles = roles.filter(
          r => this.canCreateOrganizationAdmin && r.name === role
        );
      });
  }

  setSelectedOrganization(organization: OrganizationDto | undefined): void {
    this.selectedOrganization = organization;
  }

  private createMenuItem(key: string, command: Function, visible = false): BasicMenuItem {
    return {
      label: this.atsTranslateService.get(key),
      key,
      command,
      visible: visible,
      disabled: false,
      automationId: `menu-${key}`,
    };
  }

  getMenuItems(): ExtendedDsMenuItem[] {
    return [
      this.createMenuItem('shared.treeTable.actionMenu.viewEdit', this.onEdit.bind(this), true),
      this.createMenuItem(
        'shared.treeTable.actionMenu.revokeAccess',
        this.onDelete.bind(this),
        this.canEditDeleteOrganizationUser
      ),
    ];
  }

  onSelectedItem(userData: UserWithRole): void {
    this.selectedUser = userData;
  }

  onAdd(): void {
    const data = {
      users: this.users,
      roles: this.organizationRoles,
      areaText: this.atsTranslateService.get(this.organizationKey),
      translateKey: 'shared.assignUserRoleModal',
      visible: this.canCreateOrganizationAdmin,
    };

    this.assignUserRoleModalInput = data;
    this.isAssignUserModalOpen = true;
    this.cdRef.markForCheck();
  }

  async saveAssignUserModal(result: AssignUserRoleCommand): Promise<void> {
    if (result) {
      await this.assignUserRole(result);
    }
    this.isAssignUserModalOpen = false;
    this.cdRef.markForCheck();
  }

  cancelAssignUserModal(): void {
    this.isAssignUserModalOpen = false;
  }

  onEdit(): void {
    const data = {
      currentUser: this.users.find(o => o.id === this.selectedUser?.id),
      currentRole: this.organizationRoles.find(r => r.id === this.selectedUser?.roleId),
      users: this.users,
      roles: this.organizationRoles,
      areaText: this.atsTranslateService.get(this.organizationKey),
      translateKey: 'shared.editUserRoleModal',
    };

    this.isModalOpen = true;
    this.userDetailsData = data;
    this.loadOrganizationUsers();
    this.cdRef.markForCheck();
  }

  showUserDetailsDialog(data: AssignUserRoleDialogInput): void {
    this.isModalOpen = true;
    this.userDetailsData = data;
  }

  onCloseModal(): void {
    this.isModalOpen = false;
  }

  onDelete(): void {
    const userData = {
      name: this.selectedUser?.name,
      area: this.atsTranslateService.get(this.organizationKey),
      areaName: this.selectedOrganization?.name,
    };

    this.modalService
      .createModal('settings.users.modalRevokeUserRole', userData, 'critical')
      .pipe(take(1), filter(Boolean))
      // eslint-disable-next-line rxjs/no-async-subscribe
      .subscribe(async () => {
        try {
          await this.userService.removeUserRole({
            id: this.selectedUser?.id,
            roleId: this.selectedUser?.userRoleId,
            organizationId: this.selectedOrganizationId,
            workingAreaId: this.selectedOrganization?.workAreas[0].id ?? undefined,
          });
          this.toastService.createSuccessToast('settings.users.RemoveUserRoleSuccessMessage');
          this.loadOrganizationUsers();
        } catch (error) {
          this.toastService.createErrorToast('settings.users.RemoveUserRoleFailureMessage');
        }
      });
  }

  async assignUserRole(result: AssignUserRoleCommand): Promise<void> {
    if (result && this.selectedOrganization) {
      result.organizationId = this.selectedOrganization?.id;

      try {
        await this.userService.assignUserRole(result);
        this.toastService.createSuccessToast('settings.users.AssignUserRoleSuccessMessage');
        this.loadOrganizationUsers();
      } catch (error) {
        this.toastService.createErrorToast('settings.users.AssignUserRoleFailureMessage');
      } finally {
        this.isAssignUserModalOpen = false;
        this.cdRef.markForCheck();
      }
    }
  }

  async onSettingsEnvironment(): Promise<void> {
    await this.router.navigate(['/settings/environment/view/users']);
  }

  async onSettingsOrganization(): Promise<void> {
    await this.router.navigate([this.selectedOrganization?.name, 'organization-settings', 'users']);
  }

  getUserPermissions(): void {
    this.canEditDeleteOrganizationUser = this.permissionService.actionAllowedInOrg(
      AtsActions.Organization_Users_Edit,
      this.selectedOrganizationId
    );

    this.canCreateOrganizationAdmin = this.permissionService.actionAllowedInOrg(
      AtsActions.OrganizationAdmin_Create,
      this.selectedOrganizationId
    );

    this.canAddOrganizationUser =
      this.canCreateOrganizationAdmin || this.canEditDeleteOrganizationUser;
    this.cdRef.markForCheck();
  }
}
