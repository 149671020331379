import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ShutdownModeDto, VehicleDto } from 'core/dtos';
import { AtsActions, VehiclePowerSavingType } from 'core/models';
import { AtsTranslationService, PermissionService } from 'core/services';
import { LocaleSettings } from 'primeng/calendar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getLocale } from 'shared/helpers';
import { ShutdownModeModalInput } from '../shutdown-mode-modal/shutdown-mode-modal.component';

@Component({
  selector: 'app-shutdown-mode-toggle',
  templateUrl: './shutdown-mode-toggle.component.html',
  styleUrls: ['./shutdown-mode-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShutdownModeToggleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled = false;
  @Input() isLoaded = false;
  @Input() requiredPermission: string = AtsActions.General_View;
  @Input() toggle: ShutdownModeDto = {
    isToggledOn: false,
    endShutdownAndWakeUpVehiclesDateTime: null,
    numberOfVehiclesToExclude: 1,
    latestWakeup: null,
    dateUpdated: null,
    vehicleShutdownType: VehiclePowerSavingType.Str,
  };
  @Input() allVehicles: VehicleDto[] = [];
  @Input() showVehiclesToExclude = false;
  @Output() readonly saveToggle = new EventEmitter<ShutdownModeDto>();

  shouldToggleBack = false;
  isToggledOnConfirmedState = false;
  isModalOpen = false;
  shutdownModeInputData: ShutdownModeModalInput | undefined = undefined;

  doesNotHaveRequiredPermission = false;
  calendarLocale?: LocaleSettings;
  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly translation: AtsTranslationService,
    private readonly permissionService: PermissionService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.calendarLocale = getLocale(this.translation.currentLang);
  }

  ngOnInit(): void {
    this.translation.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.calendarLocale = getLocale(this.translation.currentLang);
    });

    this.isToggledOnConfirmedState = this.toggle.isToggledOn;
  }

  ngOnChanges({ requiredPermission }: TypedChanges<ShutdownModeToggleComponent>): void {
    if (requiredPermission?.currentValue) {
      this.doesNotHaveRequiredPermission = !this.permissionService.actionAllowed(
        requiredPermission.currentValue
      );
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onToggle(checked: boolean): void {
    this.shutdownModeInputData = {
      toggle: checked,
      shutdownData: { ...this.toggle, endShutdownAndWakeUpVehiclesDateTime: null },
      mode: 'toggle',
    };
    this.openCloseModal(true);
    this.shouldToggleBack = true;
  }

  onEdit(): void {
    this.shutdownModeInputData = {
      toggle: this.toggle.isToggledOn,
      shutdownData: this.toggle,
      mode: 'edit',
    };
    this.openCloseModal(true);
    this.shouldToggleBack = false;
    this.cdRef.markForCheck();
  }

  onConfirmShutDownToggle(data: ShutdownModeDto): void {
    this.isToggledOnConfirmedState = data.isToggledOn;
    this.openCloseModal(false);
    this.saveToggle.emit(data);
  }

  onCancel(): void {
    this.toggle.isToggledOn = this.isToggledOnConfirmedState;
    this.openCloseModal(false);
  }

  openCloseModal(isModalOpen = false): void {
    if (!isModalOpen && this.shouldToggleBack) {
      this.toggle.isToggledOn = !this.shutdownModeInputData?.toggle;
    }

    this.isModalOpen = isModalOpen;
    this.shouldToggleBack = false;
    this.cdRef.markForCheck();
  }
}
