import { Injectable } from '@angular/core';
import { RolesService } from 'core/api-services';
import { RoleDto } from 'core/dtos';
import { AtsActions, GuidString, RoleScope, UserRoleModelWithChildren } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { PermissionService } from 'core/services/permission.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type SpecialEnvRoles = 'Environment Admin' | 'Environment Viewer' | 'ATS Support' | 'Fire Fighter';
type SpecialOrgRoles = 'Organization Admin';
type SpecialWaRoles = 'Viewer' | 'Operator' | 'Supervisor' | 'WorkingArea Admin';

@Injectable({
  providedIn: 'root',
})
export class PermissionHelper {
  constructor(
    private readonly rolesService: RolesService,
    private readonly permissionService: PermissionService,
    private readonly translate: AtsTranslationService
  ) {
    this.getUserPermissions(undefined, undefined);
    this.retrieveEnvironmentRoles();
    this.retrieveOrganizationRoles();
    this.retrieveWorkingAreaRoles();
  }

  canCreateViewerOperatorSupervisor = false;
  canCreateWorkingAreaAdmin = false;
  canCreateOrganizationAdmin = false;
  canCreateViewerSupport = false;
  canCreateEnvironmentAdmin = false;
  canEditRevokeEnvironmentUser = false;
  ngUnsubscribe = new Subject<void>();
  workingAreaRoles: RoleDto[] = [];
  organizationRoles: RoleDto[] = [];
  environmentRoles: RoleDto[] = [];

  clearAllRoles(): void {
    this.workingAreaRoles = [];
    this.organizationRoles = [];
    this.environmentRoles = [];
  }

  getEnvRoles(): RoleDto[] | undefined {
    this.getUserPermissions(undefined, undefined);
    return this.filterEnvironmentRoles();
  }

  getOrgRoles(orgId?: GuidString): RoleDto[] | undefined {
    this.getUserPermissions(orgId, undefined);
    return this.filterOrganizationRoles();
  }

  getWaRoles(orgId?: GuidString, waId?: GuidString): RoleDto[] | undefined {
    this.getUserPermissions(orgId, waId);
    return this.filterWorkingAreaRoles();
  }

  checkEnvBasedRole(data: UserRoleModelWithChildren): UserRoleModelWithChildren {
    data.creatable = this.canCreateEnvironmentAdmin;
    data.editable = this.canEditRevokeEnvironmentUser;
    data.deletable = this.canEditRevokeEnvironmentUser;
    return data;
  }

  checkOrgBasedRole(data: UserRoleModelWithChildren): UserRoleModelWithChildren {
    if (data.orgId) {
      data.editable = this.permissionService.actionAllowedInOrg(
        AtsActions.OrganizationAdmin_Create,
        data.orgId
      );
      data.deletable = this.permissionService.actionAllowedInOrg(
        AtsActions.Organization_Users_Edit,
        data.orgId
      );
      data.creatable = this.permissionService.actionAllowedInOrg(
        AtsActions.OrganizationAdmin_Create,
        data.orgId
      );
    }
    return data;
  }

  checkWaBasedRole(data: UserRoleModelWithChildren): UserRoleModelWithChildren {
    if (data.waId) {
      data.editable =
        this.getPermissionForAction(AtsActions.WorkingAreaAdmin_Create, data.waId, data.orgId) ||
        this.getPermissionForAction(
          AtsActions.Viewer_Operator_Supervisor_Create,
          data.waId,
          data.orgId
        );

      data.deletable = this.getPermissionForAction(
        AtsActions.WorkingArea_Users_Edit,
        data.waId,
        data.orgId
      );
      data.creatable = data.editable;
    }
    return data;
  }

  retrieveEnvironmentRoles(): void {
    this.rolesService
      .getRolesByScopeWithoutPermissions(RoleScope.Environment)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(roles => {
        this.environmentRoles = roles;
      });
  }

  retrieveOrganizationRoles(): void {
    this.rolesService
      .getRolesByScopeWithoutPermissions(RoleScope.Organization)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(roles => {
        this.organizationRoles = roles;
      });
  }

  retrieveWorkingAreaRoles(): void {
    this.rolesService
      .getRolesByScopeWithoutPermissions(RoleScope.WorkingArea)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(roles => {
        this.workingAreaRoles = roles;
      });
  }

  filterEnvironmentRoles(): RoleDto[] {
    const viewerRoles: SpecialEnvRoles[] = ['Environment Viewer', 'ATS Support', 'Fire Fighter'];
    const viewerRolesSet = new Set<string>(viewerRoles);
    const adminRole: SpecialEnvRoles = 'Environment Admin';

    const filteredRoles: RoleDto[] = this.environmentRoles
      .filter(r => this.canCreateViewerSupport && viewerRolesSet.has(r.name))
      .concat(
        this.environmentRoles.filter(r => this.canCreateEnvironmentAdmin && r.name === adminRole)
      );
    this.translateRoles(this.environmentRoles);
    return filteredRoles;
  }

  filterOrganizationRoles(): RoleDto[] {
    const role: SpecialOrgRoles = 'Organization Admin';
    const filteredRoles: RoleDto[] = this.organizationRoles.filter(
      r => this.canCreateOrganizationAdmin && r.name === role
    );
    this.translateRoles(filteredRoles);
    return filteredRoles;
  }

  filterWorkingAreaRoles(): RoleDto[] {
    const nonAdminRoles: SpecialWaRoles[] = ['Viewer', 'Operator', 'Supervisor'];
    const nonAdminRoleSet = new Set<string>(nonAdminRoles);
    const adminRole: SpecialWaRoles = 'WorkingArea Admin';
    const filteredRoles: RoleDto[] = this.workingAreaRoles
      .filter(r => this.canCreateViewerOperatorSupervisor && nonAdminRoleSet.has(r.name))
      .concat(
        this.workingAreaRoles.filter(r => this.canCreateWorkingAreaAdmin && r.name === adminRole)
      );
    this.translateRoles(filteredRoles);
    return filteredRoles;
  }

  translateRoles(roles: RoleDto[]): void {
    for (const role of roles) {
      role.nameTranslation = this.translate.get('shared.roles.list.' + role.name);
    }
  }

  getPermissionForAction(action: string, waId?: GuidString, orgId?: GuidString): boolean {
    return this.permissionService.actionAllowedInWa(action, waId ?? '', orgId ?? '');
  }

  getUserPermissions(orgId?: GuidString, waId?: GuidString): void {
    this.canCreateWorkingAreaAdmin = this.getPermissionForAction(
      AtsActions.WorkingAreaAdmin_Create,
      waId,
      orgId
    );

    this.canCreateViewerOperatorSupervisor = this.getPermissionForAction(
      AtsActions.Viewer_Operator_Supervisor_Create,
      waId,
      orgId
    );

    this.canCreateOrganizationAdmin = this.permissionService.actionAllowedInOrg(
      AtsActions.OrganizationAdmin_Create,
      orgId ?? ''
    );

    this.canEditRevokeEnvironmentUser = this.permissionService.actionAllowed(
      AtsActions.Environment_Users_Edit
    );

    this.canCreateEnvironmentAdmin = this.permissionService.actionAllowed(
      AtsActions.EnvironmentAdmin_Create
    );

    this.canCreateViewerSupport = this.permissionService.actionAllowed(
      AtsActions.Viewer_Support_Create
    );
  }
}
