import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY_GUID } from 'core/constants';
import { ensureStoreLoadedWithRefresh } from 'shared/helpers';

import * as fromSettings from 'store-modules/settings-store';
@Injectable({
  providedIn: 'root',
})
export class DeveloperSettingsGuard implements CanActivate {
  constructor(private readonly store: Store<fromSettings.SettingsFeatureState>) {}
  canActivate(): boolean {
    ensureStoreLoadedWithRefresh(
      this.store,
      fromSettings.loadEmulatorSettings({
        workAreaId: EMPTY_GUID,
      })
    );
    return true;
  }
}
