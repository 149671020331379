import { HttpErrorResponse, HttpResponse, HttpStatusCode } from '@angular/common/http';

export type HttpRequestMethod = 'get' | 'post';
export type HttpResponseType = 'blob';

export type ReducedHttpErrorResponse = Omit<
  HttpErrorResponse,
  'headers' | 'name' | 'url' | 'type' | 'message' | 'ok'
>;

export function isHttpResponse(
  response: object | boolean | string | number
): response is HttpResponse<void> {
  if (response === undefined || typeof response !== 'object') return false;

  return 'status' in response && 'statusText' in response;
}

export type ServerErrorResponse =
  | ValidationResponse
  | DetailErrorResponse
  | HttpErrorResponse
  | MessageErrorResponse
  | SimpleMessageErrorResponse;

export interface ValidationListResponse {
  status: HttpStatusCode;
  errors: {
    name: string[];
  };
}

export interface SimpleMessageErrorResponse {
  error: string;
}

export interface ValidationResponse extends ReducedHttpErrorResponse {
  error: {
    ErrorMessage: string;
  };
}

export interface DetailErrorResponse extends ReducedHttpErrorResponse {
  error: {
    title: string;
    detail: string;
  };
}

export interface MessageErrorResponse extends ReducedHttpErrorResponse {
  error: string;
}

export function isValidationError(response: ServerErrorResponse): response is ValidationResponse {
  return 'error' in response && response.error !== null && 'ErrorMessage' in response.error;
}

export function isValidationListError(
  response: ServerErrorResponse | ValidationListResponse
): response is ValidationListResponse {
  return 'errors' in response;
}

export function isDetailError(response: ServerErrorResponse): response is DetailErrorResponse {
  return 'error' in response && response.error !== null && 'title' in response.error;
}

export function isMessageError(response: ServerErrorResponse): response is MessageErrorResponse {
  return typeof response.error === 'string' && 'status' in response;
}

export function isSimpleMessageError(
  response: ServerErrorResponse
): response is SimpleMessageErrorResponse {
  return typeof response.error === 'string';
}
