import { OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { AbstractTranslatePipe } from './abstract-translate.pipe';

@Pipe({
  name: 'enumTranslation',
  pure: false, // required,since the translation is unpure
})
export class EnumTranslationPipe
  extends AbstractTranslatePipe<string>
  implements PipeTransform, OnDestroy
{
  transform(value: number | boolean | string | undefined, enumName?: string): string | null {
    this.ensureLangChangeReaction();

    if (value === undefined) {
      return '-';
    } else if (typeof value === 'boolean') {
      value = Number(value);
      enumName = enumName ? enumName : 'boolean';
    }
    const translationKey = `enums.${enumName}.${value}`;
    return this.getCachedOrFresh(translationKey, [], this.translateKey);
  }

  private translateKey(key: string): string {
    return this.atsTranslationService.get(key);
  }
}
