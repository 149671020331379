export enum UserManualRouteNames {
  Welcome = '/welcome',
  Reports = '/home/reports',
  Maps = '/maps/',
  ManageMaps = '/maps/manage',
  ManageZoneset = '/maps/zone-sets',
  ActiveMissions = '/active',
  ActiveMissionsView = '/active/view',
  HistoryMissions = '/history',
  HistoryMissionsView = '/history/view',
  ProcessConfigurator = '/processConfigurator',
  Vehicles = '/vehicles',
  VehiclesNew = '/vehicles/new',
  VehiclesView = '/vehicles/view',
  Pois = '/pois',
  Mapping = '/mapping',
  Devices = '/devices',
  EnvironmentUsers = '/environment-users',
  ErrorForwarding = '/error-forwarding',
  Ipst = '/ipst',
  SettingsFunction = '/settings/functions',
  Default = '/',
}
