import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { VehicleMapDataDto } from 'core/dtos';

import { GuidString } from 'core/models';
import * as VehicleMapDataActions from '../actions/vehicle-map-data.actions';

export const featureKey = 'vehicleMapData';

export interface VehicleMapDataState extends EntityState<VehicleMapDataDto> {
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
  actionStatus: VehicleMapDataActions.VehicleMapDataTypes | null;
  selectedVehicleMapDataId: GuidString;
  selectedVehicleIdsByMapData: GuidString[];
}

export const vehicleMapDataAdapter: EntityAdapter<VehicleMapDataDto> =
  createEntityAdapter<VehicleMapDataDto>();

export const initialState: VehicleMapDataState = vehicleMapDataAdapter.getInitialState({
  loaded: false,
  loading: false,
  errorMessage: '',
  actionStatus: null,
  selectedVehicleMapDataId: '',
  selectedVehicleIdsByMapData: [],
});

const vehicleMapDataReducer = createReducer(
  initialState,

  on(VehicleMapDataActions.loadVehicleMapData, state => ({
    ...state,
    loading: true,
    loaded: false,
    errorMessage: '',
  })),

  on(VehicleMapDataActions.getVehicleIdsByMapData, (state, { vehicleMapDataId }) => ({
    ...state,
    loading: true,
    loaded: false,
    selectedVehicleMapDataId: vehicleMapDataId,
    errorMessage: '',
  })),

  on(VehicleMapDataActions.getVehicleIdsByMapDataSuccess, (state, { vehicleIds }) => ({
    ...state,
    loading: true,
    loaded: false,
    selectedVehicleIdsByMapData: vehicleIds,
    errorMessage: '',
  })),

  on(VehicleMapDataActions.loadVehicleMapDataSuccess, (state, { vehicleMapData }) => {
    return vehicleMapDataAdapter.addMany(
      vehicleMapData.map(vehicleMapData => ({ ...vehicleMapData, id: vehicleMapData.id })),
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),

  on(VehicleMapDataActions.loadVehicleMapDataFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
    actionStatus: VehicleMapDataActions.VehicleMapDataTypes.LoadVehicleMapDataFailure,
  })),

  on(VehicleMapDataActions.uploadVehicleMapDataSuccess, (state, { vehicleMapData }) =>
    vehicleMapDataAdapter.addOne(vehicleMapData, {
      ...state,
      actionStatus: VehicleMapDataActions.VehicleMapDataTypes.UploadVehicleMapDataSuccess,
    })
  ),

  on(VehicleMapDataActions.uploadVehicleMapDataFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    actionStatus: VehicleMapDataActions.VehicleMapDataTypes.UploadVehicleMapDataFailure,
  })),

  on(VehicleMapDataActions.deleteVehicleMapData, state => ({
    ...state,
    loading: true,
    errorMessage: '',
  })),

  on(VehicleMapDataActions.deleteVehicleMapDataSuccess, (state, { vehicleMapData }) =>
    vehicleMapDataAdapter.removeOne(vehicleMapData.id.toString(), {
      ...state,
      loading: false,
      actionStatus: VehicleMapDataActions.VehicleMapDataTypes.DeleteVehicleMapDataSuccess,
    })
  ),

  on(VehicleMapDataActions.deleteVehicleMapDataFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    actionStatus: VehicleMapDataActions.VehicleMapDataTypes.DeleteVehicleMapDataFailure,
  })),

  on(VehicleMapDataActions.deleteDisabledMaps, state => ({
    ...state,
    loading: true,
    errorMessage: '',
  })),

  on(VehicleMapDataActions.deleteDisabledMapsSuccess, (state, { vehicleId }) =>
    vehicleMapDataAdapter.removeOne(vehicleId.toString(), {
      ...state,
      loading: false,
      actionStatus: VehicleMapDataActions.VehicleMapDataTypes.DeleteVehicleMapDataSuccess,
    })
  ),

  on(VehicleMapDataActions.deleteDisabledMapsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    actionStatus: VehicleMapDataActions.VehicleMapDataTypes.DeleteDisabledMapsFailure,
  })),

  on(VehicleMapDataActions.resetVehicleMapDataActionStatus, state => ({
    ...state,
    actionStatus: null,
  })),

  on(VehicleMapDataActions.resetVehicleIdsByMapData, state => ({
    ...state,
    selectedVehicleMapDataId: '',
    selectedVehicleIdsByMapData: [],
  }))
);

export function reducer(
  state: VehicleMapDataState | undefined,
  action: Action
): VehicleMapDataState {
  return vehicleMapDataReducer(state, action);
}

export const { selectEntities, selectAll } = vehicleMapDataAdapter.getSelectors();

export const getLoading = (state: VehicleMapDataState): boolean => state.loading;
export const getLoaded = (state: VehicleMapDataState): boolean => state.loaded;
export const getErrorMessage = (state: VehicleMapDataState): string => state.errorMessage;
export const getSelectedVehicleMapDataId = (state: VehicleMapDataState): GuidString =>
  state.selectedVehicleMapDataId;
export const getVehicleIdsByMapData = (state: VehicleMapDataState): GuidString[] =>
  state.selectedVehicleIdsByMapData;
export const getVehicleMapDataEntities = selectEntities;
export const getAllVehicleMapData = selectAll;
export const getVehicleMapDataActionStatus = (
  state: VehicleMapDataState
): VehicleMapDataActions.VehicleMapDataTypes | null => state.actionStatus;
