/* eslint-disable max-lines */
import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  FeatureToggle,
  FleetManagerFeatures,
  getDefaultFleetManagerFeatures,
  InfobarMessageResponse,
  ShutdownRequestDto,
  WorkingAreaDto,
} from 'core/dtos';
import { GuidString } from 'core/models';
import { AtsTranslationService, TenantRouterService } from 'core/services';
import { SettingsSignalRService } from 'core/signalR/modules/settings-signalr.service';
import { DateFormatEnum } from 'library/helpers/standard-date-formatter.helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ensureStoreLoaded } from 'shared/helpers';
import * as fromSettings from 'store-modules/settings-store';
import * as fromVehicles from 'store-modules/vehicles-store';
import * as fromRoot from 'store/index';
import { InfobarMessageViewModel } from './infobar-message.viewmodel';
import { InformationBarViewModel, ShutdownConfig } from './information-bar.viewmodel';

@Component({
  selector: 'app-information-bar',
  templateUrl: './information-bar.component.html',
  styleUrls: ['./information-bar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ 'margin-top': '-30px' }),
        animate(
          '800ms ease-in-out',
          style({
            'margin-top': '0px',
          })
        ),
      ]),
      transition(':leave', [
        style({ 'margin-top': '0px' }),
        animate(
          '800ms ease-in-out',
          style({
            'margin-top': '-30px',
          })
        ),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationBarComponent implements OnInit, OnDestroy {
  viewModel: InformationBarViewModel = {
    showInformation: false,
    infoBarStyle: 'none',
    infoBarFont: 'regular-information',
    translationKey: '',
    shutdownConfig: [],
  };

  @Input() hideInfoStatus = false;
  isMessageExpired = false;
  dateFormat = DateFormatEnum;

  secondaryInfobar: InfobarMessageViewModel = {
    showInformation: false,
    infobarStyle: 'none',
  };

  softwareUpdateMessageBar: InfobarMessageViewModel = {
    showInformation: false,
    infobarStyle: 'informationStyle',
  };

  endOfShiftModeActive = false;
  shutdownRequests: ShutdownRequestDto[] = [];
  infobarMessage: InfobarMessageResponse | null = {
    id: '',
    infoType: 'information',
    message: '',
  };

  fleetManagerFeatures: FleetManagerFeatures = getDefaultFleetManagerFeatures();
  beginShiftModeActive = false;
  onWelcomeScreen = false;
  displayEvacuationModeBanner = false;
  name!: string;
  allWorkingAreas: WorkingAreaDto[] = [];

  fireFighterModeActive = false;

  private readonly ngUnsubscribe = new Subject<void>();
  readonly settingsSignalrSubscriber;

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly settingsStore: Store<fromSettings.SettingsFeatureState>,
    private readonly routerService: TenantRouterService,
    private readonly settingsSignalRService: SettingsSignalRService,
    private readonly translationService: AtsTranslationService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.settingsSignalrSubscriber = this.settingsSignalRService.signalrSubscriberFactory(
      InformationBarComponent.name
    );
  }

  async ngOnInit(): Promise<void> {
    await this.settingsSignalrSubscriber.joinInfobarMessage();
    this.getRouterInfo();
    this.dispatchActionsAndQuerySelectors();
  }

  getRouterInfo(): void {
    this.rootStore
      .select(fromRoot.getRouterInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(routerInfo => {
        this.onWelcomeScreen = routerInfo.url.startsWith('/welcome');
        if (!this.fireFighterModeActive && this.onWelcomeScreen) {
          this.hideInfoStatus = true;
        }
        this.cdRef.markForCheck();
      });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private dispatchActionsAndQuerySelectors(): void {
    this.rootStore
      .select(fromSettings.selectInfobarMessage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(infobarMessage => {
        void ensureStoreLoaded(
          this.settingsStore,
          fromSettings.selectInfobarMessageLoaded,
          fromSettings.loadInfobarMessage()
        );
        this.infobarMessage = infobarMessage;
        if (infobarMessage !== null) {
          this.setInfobarMessage(infobarMessage);
        }
      });

    this.rootStore
      .select(fromVehicles.selectSoftwareUpdateInstallingAndUpdatingCount)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(count => {
        this.softwareUpdateMessageBar.showInformation = count > 0;
        this.softwareUpdateMessageBar.message = this.translationService.get(
          'vehicles.softwareManagement.installingVehicles',
          { count }
        );
        this.cdRef.markForCheck();
      });

    this.rootStore
      .pipe(select(fromSettings.selectBeginShiftModeActive), takeUntil(this.ngUnsubscribe))
      .subscribe(mode => {
        void ensureStoreLoaded(
          this.settingsStore,
          fromSettings.selectGraphManagerFeatureSettingLoaded,
          fromSettings.loadGraphManagerSettings()
        );
        this.beginShiftModeActive = mode || false;
        this.setShowInformation();
      });

    this.rootStore
      .pipe(select(fromSettings.isEndOfShiftModeActive), takeUntil(this.ngUnsubscribe))
      .subscribe(mode => {
        void ensureStoreLoaded(
          this.settingsStore,
          fromSettings.selectJobSettingsLoaded,
          fromSettings.loadJobSettings()
        );
        this.endOfShiftModeActive = mode || false;
        this.setShowInformation();
      });

    this.rootStore
      .select(fromSettings.selectShutdownRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(mode => {
        void ensureStoreLoaded(
          this.settingsStore,
          fromSettings.selectShutdownRequestLoaded,
          fromSettings.loadShutdownRequest()
        );
        this.shutdownRequests = mode;
        this.setShowInformation();
      });

    this.rootStore
      .select(fromSettings.selectFleetSettings)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(fleetSettings => {
        void ensureStoreLoaded(
          this.settingsStore,
          fromSettings.selectFleetSettingsLoaded,
          fromSettings.loadFleetSettings()
        );
        this.fleetManagerFeatures = fleetSettings;
        this.setShowInformation();
      });

    this.rootStore
      .select(fromRoot.selectAllWorkingAreas)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(was => {
        this.allWorkingAreas = Object.values(was);
      });

    this.rootStore
      .select(fromSettings.selectEvacuationModeFireFighter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(was => {
        this.updateEvacuationModeBanner(was);
        this.setShowInformation();
        this.fireFighterModeActive = was.some(wa => wa.isToggledOn);
        if (this.fireFighterModeActive) {
          this.hideInfoStatus = false;
        }
      });
  }

  updateEvacuationModeBanner(
    was: {
      workAreaId: GuidString;
      isToggledOn: boolean;
    }[]
  ): void {
    void ensureStoreLoaded(
      this.settingsStore,
      fromSettings.selectEvacuationModeFireFighterTogglesLoaded,
      fromSettings.retrieveAllFireFighterSettings()
    );
    const waNames = this.getWorkingAreaNames(was);
    this.name = this.translationService.get('infoBar.evacuationModeWelcomePage', { name: waNames });
    this.displayEvacuationModeBanner = waNames !== '';
  }

  getWorkingAreaNames(
    was: {
      workAreaId: GuidString;
      isToggledOn: boolean;
    }[]
  ): string {
    const waNames = was
      .filter(item => item.isToggledOn)
      .map(item => this.allWorkingAreas.find(wa => wa.id === item.workAreaId)?.name)
      .filter(name => name != null)
      .sort();
    return waNames.join(', ');
  }

  private setShowInformation(): void {
    this.viewModel.infoBarStyle = 'none';
    this.viewModel.translationKey = '';
    this.viewModel.infoBarFont = 'regular-information';
    const activeShutdownRequests = this.shutdownRequests.filter(s => s.isActive);
    this.viewModel.shutdownConfig = [];
    if (this.fleetManagerFeatures.evacuationModeToggle.isToggledOn && !this.onWelcomeScreen) {
      this.viewModel.infoBarStyle = 'evacuationStyle';
      this.viewModel.translationKey = 'infoBar.evacuationMode';
    } else if (activeShutdownRequests.length) {
      this.viewModel.infoBarStyle = 'shutdownStyle';
      this.viewModel.translationKey = 'infoBar.shutdownMode';
      this.viewModel.infoBarFont = 'labelText';
      this.viewModel.shutdownConfig = activeShutdownRequests.map(this.mapShutdownConfig.bind(this));
    } else if (
      Object.values(this.fleetManagerFeatures.collectivePauseToggle).some(
        (vehicleType: FeatureToggle) => vehicleType.isToggledOn === true
      )
    ) {
      this.viewModel.infoBarStyle = 'collectivePause';
      this.viewModel.translationKey = 'infoBar.collectivePause';
      this.viewModel.infoBarFont = 'labelText';
    } else if (this.endOfShiftModeActive) {
      this.viewModel.infoBarStyle = 'endOfShiftStyle';
      this.viewModel.translationKey = 'infoBar.endOfShiftMode';
    } else if (this.displayEvacuationModeBanner && this.onWelcomeScreen) {
      this.viewModel.infoBarStyle = 'evacuationStyle';
      this.viewModel.translationKey = this.name;
    } else if (this.beginShiftModeActive) {
      this.viewModel.infoBarStyle = 'beginShiftStyle';
      this.viewModel.translationKey = 'infoBar.beginShiftMode';
    }

    this.viewModel.showInformation = this.viewModel.infoBarStyle !== 'none';
    this.viewModel = { ...this.viewModel };
    this.cdRef.markForCheck();
  }

  private mapShutdownConfig(req: ShutdownRequestDto): ShutdownConfig {
    return {
      endShutdownAndWakeUpVehiclesDateTime: this.fleetManagerFeatures.shutdownModeToggle.find(
        x => x.vehicleShutdownType === req.vehicleShutdownType
      )?.endShutdownAndWakeUpVehiclesDateTime,
      startTimeUtc: req.startTimeUtc,
      vehicleShutdownType: req.vehicleShutdownType,
    };
  }
  private setInfobarMessage(infobarMessage: InfobarMessageResponse): void {
    if (infobarMessage.message === '') {
      this.isMessageExpired = true;
      this.cdRef.markForCheck();
      return;
    }

    this.isMessageExpired = false;
    this.secondaryInfobar.infobarStyle = 'none';
    this.secondaryInfobar.message = infobarMessage.message;

    switch (infobarMessage.infoType) {
      case 'information': {
        this.secondaryInfobar.infobarStyle = 'informationStyle';
        break;
      }
      case 'warning': {
        this.secondaryInfobar.infobarStyle = 'warningStyle';
        break;
      }
      case 'fatal': {
        this.secondaryInfobar.infobarStyle = 'fatalStyle';
        break;
      }
      default: {
        this.secondaryInfobar.infobarStyle = 'none';
        break;
      }
    }

    this.secondaryInfobar.showInformation =
      this.secondaryInfobar.infobarStyle !== 'none' && this.infobarMessage?.message.trim() !== '';
    this.secondaryInfobar = { ...this.secondaryInfobar };
    this.cdRef.markForCheck();
  }

  async onClick(): Promise<void> {
    await this.routerService.navigate(['settings/functions']);
  }
}
