import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UserSettingsService } from 'core/api-services';
import { UserSettingsDto } from 'core/dtos';
import { StartPageOptions, UserSettingsListOptions } from 'core/models';
import { ToastService } from 'core/services';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-user-settings-modal',
  templateUrl: './user-settings-modal-container.component.html',
  styleUrls: ['./user-settings-modal-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsModalComponent implements OnInit {
  @Input() isModalOpen = false;
  @Output() readonly closeEvent = new EventEmitter<void>();

  selectedListItem?: UserSettingsListOptions;

  settings: UserSettingsDto = { defaultStartPage: null, defaultWorkingArea: null };
  constructor(
    private readonly userSettingsService: UserSettingsService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.userSettingsService
      .getUserSettings()
      .pipe(take(1))
      .subscribe(settings => {
        {
          this.settings = settings;
        }
      });
  }

  setSelectedListItem(selectedListItem: UserSettingsListOptions): void {
    this.selectedListItem = selectedListItem;
  }

  setStartPageSettings(selectedStartPageOption: StartPageOptions): void {
    this.settings.defaultStartPage = selectedStartPageOption;
  }

  onSave(): void {
    this.userSettingsService
      .updateUserSettings(this.settings)
      .pipe(
        take(1),
        finalize(() => this.onClose())
      )
      .subscribe({
        next: () => {
          this.toastService.createSuccessToast('userProfile.savedSettings');
        },
        error: () => {
          this.toastService.createErrorToast('userProfile.errorMessage');
        },
      });
  }

  onClose(): void {
    this.closeEvent.emit();
  }
}
