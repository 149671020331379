import { Point } from 'pixi.js';

export enum PointerButton {
  Left,
  Middle,
  Right,
}

export interface PointerUpArgs {
  position: Point;
  dragged?: boolean;
}
