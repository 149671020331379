<h5>
  <span>{{ 'settings.ipstAlertNowSettings.generalHeader' | translate | uppercase }}</span>
  <span style="color: var(--ds-color-typography-neutral)">{{
    'settings.ipstAlertNowSettings.generalHeaderRelevant' | translate | uppercase
  }}</span>
</h5>
<div class="three-columns">
  <div class="labeled-static">
    <label ds-label>{{ 'settings.ipst.url' | translate }}</label>
    <div data-cy="ipstUrl">{{ generalInformation?.url | notApplicable }}</div>
  </div>
  <div class="labeled-static">
    <label ds-label>{{ 'settings.ipst.user' | translate }}</label>
    <div data-cy="ipstUser">{{ generalInformation?.user | notApplicable }}</div>
  </div>
  <div class="labeled-static">
    <label ds-label>{{ 'settings.ipst.password' | translate }}</label>
    <div
      data-cy="ipstPassword"
      class="form-plaintext"
      [style.-webkit-text-security]="generalInformation?.password ? 'disc' : 'none'">
      {{ generalInformation?.password | notApplicable }}
    </div>
  </div>
</div>
<ds-hint>{{ 'settings.ipstAlertNowSettings.generalInfoHint' | translate }}</ds-hint>
<hr />
