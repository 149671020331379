import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as MissionMonitoringEffects from './effects';
import * as fromMissionMonitoring from './reducers';

@NgModule({
  imports: [
    EffectsModule.forFeature(MissionMonitoringEffects.effects),
    StoreModule.forFeature(fromMissionMonitoring.featureKey, fromMissionMonitoring.reducers),
  ],
})
export class MissionMonitoringStoreModule {}
