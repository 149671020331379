<div class="toolbarContainer">
  <app-base-tool-bar styleClass="pb-0x" data-cy="tableToolbar">
    <div class="p-toolbar-group-left"></div>
    <div class="p-toolbar-group-right toolbarGroupRight">
      <ng-container *ngIf="isTableConfigurationModeActive; else editModeNotActive">
        <button
          *ngIf="isResetButtonVisible"
          ds-button
          [variant]="'ghost'"
          data-cy="toolbarColumnReset"
          [icon]="'arrow_go_back'"
          class="ms-4x"
          ds-tooltip="{{ 'shared.tableColumnActions.reset' | translate }}"
          (click)="resetReorderedColumns($event)"></button>
        <button
          data-cy="toolbarColumnSave"
          ds-button
          [variant]="'ghost'"
          [icon]="'save'"
          ds-tooltip="{{ 'shared.tableColumnActions.save' | translate }}"
          (click)="saveReorderedColumns($event)"></button>
        <button
          data-cy="toolbarColumnCancel"
          ds-button
          [variant]="'ghost'"
          [icon]="'close'"
          ds-tooltip="{{ 'shared.tableColumnActions.cancel' | translate }}"
          (click)="editReorderedColumns($event)"></button>
      </ng-container>
      <ng-container *ngFor="let item of actionItems; let first = first">
        <ng-container *ngIf="item.visible !== false">
          <div [class.ps-2x]="!first" data-cy="toolbar-secondary-button">
            <button
              ds-button
              [variant]="'outline'"
              (click)="onToolbarSecondaryButtonClick(item.command)"
              [disabled]="item.disabled">
              {{ item.key | translate }}
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #editModeNotActive>
        <button
          data-cy="toolbarColumnEdit"
          ds-button
          [variant]="'ghost'"
          [icon]="'settings'"
          ds-tooltip="{{ 'shared.tableColumnActions.edit' | translate }}"
          (click)="editReorderedColumns($event)"></button>
      </ng-template>
    </div>
  </app-base-tool-bar>
</div>
<app-tree-table
  [translationContext]="translationContext"
  [header]="header"
  [records]="records"
  [columns]="columns"
  [isLoading]="isLoading"
  [searchTerm]="searchTerm"
  [menuItems]="menuItems"
  [selectedRowId]="selectedRowId"
  [itemTemplate]="itemTemplate"
  [isInitialExpandAll]="isInitialExpandAll"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  [isTableConfigurationModeActive]="isTableConfigurationModeActive"
  [reorderableColumns]="isTableConfigurationModeActive"
  (nodeClick)="onNodeClick($event)"
  (nodeNavigate)="onNodeNavigate($event)"
  (changePage)="onChangePage($event)"
  (saveTableSort)="onSaveTableSort($event)"
  (colReordered)="onColReordered()">
</app-tree-table>
