import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { TourChainConfigurationDto } from 'core/dtos';
import { GuidString } from 'core/models';

export enum TourChainConfigurationActionTypes {
  LoadTourChainConfigurations = '[GraphManager] Load Tour Chain Configurations',
  LoadTourChainConfigurationsSuccess = '[GraphManager] Load Tour Chain Configurations Success',
  LoadTourChainConfigurationsFailure = '[GraphManager] Load Tour Chain Configurations Failure',
  SelectTourChainConfig = '[GraphManager] Select Tour Chain Configuration',
  CreateTourChainConfiguration = '[GraphManager] Create Tour Chain Configuration',
  CreateTourChainConfigurationSuccess = '[GraphManager] Create Tour Chain Configuration Success',
  CreateTourChainConfigurationFailure = '[GraphManager] Create Tour Chain Configuration Failure',
  UpdateTourChainConfiguration = '[GraphManager] Update Tour Chain Configuration',
  UpdateTourChainConfigurationSuccess = '[GraphManager] Update Tour Chain Configuration Success',
  UpdateTourChainConfigurationFailure = '[GraphManager] Update Tour Chain Configuration Failure',
  DeleteTourChainConfiguration = '[GraphManager] Delete Tour Chain Configuration',
  DeleteTourChainConfigurationSuccess = '[GraphManager] Delete Tour Chain Configuration Success',
  DeleteTourChainConfigurationFailure = '[GraphManager] Delete Tour Chain Configuration Failure',
}
export const loadTourChainConfigurations = createAction(
  TourChainConfigurationActionTypes.LoadTourChainConfigurations
);

export const loadTourChainConfigurationsSuccess = createAction(
  TourChainConfigurationActionTypes.LoadTourChainConfigurationsSuccess,
  props<{ tourChainConfigurations: TourChainConfigurationDto[] }>()
);

export const loadTourChainConfigurationsFailure = createAction(
  TourChainConfigurationActionTypes.LoadTourChainConfigurationsFailure,
  props<{ errorMessage: string }>()
);

export const selectTourChainConfig = createAction(
  TourChainConfigurationActionTypes.SelectTourChainConfig,
  props<{ tourChainConfigId: GuidString }>()
);

export const createTourChainConfiguration = createAction(
  TourChainConfigurationActionTypes.CreateTourChainConfiguration,
  props<{ tourChainConfig: TourChainConfigurationDto }>()
);

export const createTourChainConfigurationSuccess = createAction(
  TourChainConfigurationActionTypes.CreateTourChainConfigurationSuccess,
  props<{ tourChainConfig: TourChainConfigurationDto }>()
);

export const createTourChainConfigurationFailure = createAction(
  TourChainConfigurationActionTypes.CreateTourChainConfigurationFailure,
  props<{ errorMessage: string }>()
);

export const updateTourChainConfiguration = createAction(
  TourChainConfigurationActionTypes.UpdateTourChainConfiguration,
  props<{ tourChainConfiguration: TourChainConfigurationDto }>()
);

export const updateTourChainConfigurationSuccess = createAction(
  TourChainConfigurationActionTypes.UpdateTourChainConfigurationSuccess,
  props<{ tourChainConfiguration: Update<TourChainConfigurationDto> }>()
);

export const updateTourChainConfigurationFailure = createAction(
  TourChainConfigurationActionTypes.UpdateTourChainConfigurationFailure,
  props<{ errorMessage: string; tourChainConfiguration: TourChainConfigurationDto }>()
);

export const deleteTourChainConfiguration = createAction(
  TourChainConfigurationActionTypes.DeleteTourChainConfiguration,
  props<{ tourChainConfiguration: TourChainConfigurationDto }>()
);

export const deleteTourChainConfigurationSuccess = createAction(
  TourChainConfigurationActionTypes.DeleteTourChainConfigurationSuccess,
  props<{ tourChainConfiguration: TourChainConfigurationDto }>()
);

export const deleteTourChainConfigurationFailure = createAction(
  TourChainConfigurationActionTypes.DeleteTourChainConfigurationFailure,
  props<{ errorMessage: string }>()
);
