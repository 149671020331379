export enum OpcUaNewButton {
  StreamingService = 1,
  Device = 2,
}

export enum OpcuaStreamingServiceType {
  Opcua = 0,
  Ipsi = 1,
  LoRa = 2,
}
