<form [formGroup]="form">
  <ds-box elevation="base">
    <ds-box-header>
      <div>
        <span title="{{ value.workingArea }}"> {{ value.workingArea }}</span>
        <ds-switch
          class="ipst-switch ps-3x"
          formControlName="enabled"
          data-cy="IpstWorkAreaSettingEnabledInput">
          {{ 'settings.ipst.workingAreaSettings.ipst' | translate }}
        </ds-switch>
      </div>
    </ds-box-header>
    <hr />
    <ds-box-content>
      <ds-form-field class="mb-3x">
        <label ds-label for="akz">{{ 'settings.ipst.workingAreaSettings.akz' | translate }}</label>
        <input ds-input ds-form-validation id="akz" formControlName="akz" data-cy="AkzInput" />
      </ds-form-field>

      <div formArrayName="recipientKeys">
        <label ds-label class="mb-1x">
          ({{ 'settings.ipst.workingAreaSettings.default' | translate }})
          {{ 'settings.ipst.workingAreaSettings.recipientKeys' | translate }}</label
        >
        <div *ngFor="let key of recipientKeysFormArray.controls; let i = index">
          <div class="recipientkeyentry" [formGroupName]="i">
            <input
              ds-input
              type="radio"
              class="ms-1x me-2x"
              [value]="true"
              (click)="setDefaultRecipientKey(i)"
              formControlName="enabled" />
            <ds-form-field>
              <input ds-input formControlName="value" data-cy="RecipientKeyInput" />
            </ds-form-field>
            <button
              ds-button
              variant="ghost"
              size="sm"
              class="ms-2x"
              *ngIf="canDeleteRecipientKey"
              (click)="deleteRecipientKey(i)"
              icon="bin"></button>
          </div>
        </div>
      </div>
      <button
        ds-button
        icon="add"
        class="addRecipientButton"
        variant="outline"
        data-cy="AddRecipientKeyButton"
        (click)="addRecipientKey()">
        {{ 'settings.ipst.workingAreaSettings.addRecipientKey' | translate }}
      </button>
    </ds-box-content>
  </ds-box>
</form>
