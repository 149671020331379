import { GuidString } from 'core/models';

export const EMPTY_GUID: GuidString = '00000000-0000-0000-0000-000000000000';

export const DEFAULT_REPLENISHMENT_TIME = 20;

export const HALF_A_SECOND = 500;
export const ONE_SECOND = 1000;
export const FIVE_SECONDS = 5000;
export const TEN_SECONDS = 10000;
export const ONE_MINUTE = 60000;
export const METERS_PER_PIXEL_FACTOR = 0.01;
export const MONTH_IN_MS = 2592000000;
export const DISTANT_FUTURE = 8640000000000000;
export const DEBOUNCE_TIME = 300;
export const MAX_ORIENTATION = 360;

export const MM_PER_METER = 1000;
export const CM_PER_METER = 100;
export const MM_PER_CM = 10;

export const NO_VALUE_DEFAULT = '-';
export const DEGREE_SIGN = 'º';

export const MAX_DATE_VALUE = '9999-12-31T23:59:59.9999999Z';
