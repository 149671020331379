<ds-modal
  [dimensionsConfig]="{ width: '945px' }"
  data-cy="evacuationDevicesModal"
  #evacuationDevicesModal
  [ds-modal-trigger-for]="evacuationDevicesModal"
  [isDismissable]="false"
  [(isModalOpen)]="isModalOpen"
  (modalDismiss)="onClose()"
  (isModalOpenChange)="isModalOpenChange()">
  <ds-box-header [actionsTemplate]="arrowDiv" data-cy="dialogHeader"
    >{{ 'settings.functions.wagoConfigDialog.title' | translate }}
  </ds-box-header>
  <ng-template #arrowDiv>
    <div *ngIf="viewModel.content.length > 2">
      <button ds-button class="has-icon deleteIcon" (click)="scrollLeft()">
        {{ '' }}
        <ds-icon class="float-end" icon="arrow_left"> </ds-icon>
      </button>
      <button ds-button class="has-icon deleteIcon" (click)="scrollRight()">
        {{ '' }}
        <ds-icon class="float-end" icon="arrow_right"> </ds-icon>
      </button>
    </div>
  </ng-template>
  <ds-box-content divider="full" data-cy="dialog-content">
    <div class="paddingScroll" #scrollFrame>
      <div class="wrapper">
        <ng-container *ngFor="let device of viewModel.content">
          <ds-box
            style="height: 170px"
            [accentBarTone]="
              device.device === selectedEvacuationDeviceItem?.device ? 'info' : 'default'
            "
            (click)="selectDevice(device)"
            [ngClass]="device.isValid ? 'itemValid' : 'itemNotValid'">
            <ds-box-content [isClickable]="true" [spacing]="'none'" [divider]="'none'">
              <div class="d-flex justify-content-between w-100 h-100">
                <ds-icon class="robotIcon float-start" icon="robot"></ds-icon>
                <button
                  *ngIf="hasEvacuationDeviceUpdatePermission"
                  data-cy="evacuationDeviceDeleteButton"
                  ds-button
                  class="has-icon deleteIcon"
                  (click)="removeItem(device)">
                  {{ '' }}
                  <ds-icon class="float-end" icon="bin"> </ds-icon>
                </button>
              </div>
            </ds-box-content>
            <ds-box-footer data-cy="evacuationDeviceName" class="itemLabel" [spacing]="'none'">
              {{ device.device }}
            </ds-box-footer>
          </ds-box>
        </ng-container>
        <div
          *ngIf="hasEvacuationDeviceUpdatePermission"
          data-cy="addEvacuationDeviceButton"
          class="item">
          <ds-select
            data-cy="availableDevicesDropdown"
            [isMultiselect]="false"
            [showClearAll]="false"
            [isFilterable]="false"
            (optionSelected)="onChangeDevice($any($event).id)"
            [options]="availableDevicesList | dsDropdown : 'name' : 'name'">
          </ds-select>
          <ds-icon class="plusIcon" icon="add_circle"></ds-icon>
        </div>
      </div>
    </div>
    <div class="tab-container">
      <div *ngIf="selectedEvacuationDeviceItem; then showSelectedItem; else emptyListMessage"></div>
      <ng-template #emptyListMessage>
        <h3>
          {{ 'settings.functions.wagoConfigDialog.emptyListMessage' | translate }}
        </h3>
      </ng-template>
      <ng-template #showSelectedItem>
        <h3>
          {{ 'settings.functions.wagoConfigDialog.evacuationMode' | translate }} -
          {{ selectedEvacuationDeviceItem?.device }}
          {{ 'settings.functions.wagoConfigDialog.configuration' | translate }}
        </h3>
        <ds-tabs [(activeTabId)]="activeTabId" [contentSpacing]="'none'">
          <ds-tabs-label
            data-cy="evacuationTriggersTab"
            for="wagoTriggers"
            ds-tooltip="{{
              'settings.functions.wagoConfigDialog.modeSwitchNodesTabTooltip' | translate
            }}">
            {{ 'settings.functions.wagoConfigDialog.modeSwitchNodesTab' | translate }}
          </ds-tabs-label>
          <ds-tabs-content id="wagoTriggers">
            <app-wago-evacuation-devices-config-triggers-table
              class="w-100"
              [device]="device"
              [hasEvacuationDeviceUpdatePermission]="hasEvacuationDeviceUpdatePermission"
              [tableContent]="selectedEvacuationDeviceItem?.triggers">
            </app-wago-evacuation-devices-config-triggers-table>
          </ds-tabs-content>
          <ds-tabs-label
            data-cy="evacuationFeedbackTab"
            for="wagoFeedbacks"
            ds-tooltip="{{
              'settings.functions.wagoConfigDialog.writeNodesTabTooltip' | translate
            }}">
            {{ 'settings.functions.wagoConfigDialog.writeNodesTab' | translate }}
          </ds-tabs-label>
          <ds-tabs-content id="wagoFeedbacks">
            <app-wago-evacuation-devices-config-feedbacks-table
              class="w-100"
              [device]="device"
              [hasEvacuationDeviceUpdatePermission]="hasEvacuationDeviceUpdatePermission"
              [tableContent]="selectedEvacuationDeviceItem?.feedbacks">
            </app-wago-evacuation-devices-config-feedbacks-table>
          </ds-tabs-content>
        </ds-tabs>
      </ng-template>
    </div>
  </ds-box-content>
  <ds-box-footer data-cy="dialog-footer">
    <button ds-button [variant]="'outline'" (click)="onClose()" data-cy="cancelButton">
      {{ 'shared.modalButtons.CancelButton' | translate }}
    </button>
    <button
      ds-button
      class="ms-2x"
      (click)="onConfirm()"
      [disabled]="!viewModel.isValid || !hasEvacuationDeviceUpdatePermission"
      data-cy="saveButton">
      {{ 'shared.editBar.save' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
