import { createAction, props } from '@ngrx/store';
import {
  LIFUploadModel,
  LayersCreateModel,
  LifUpdatePreview,
  NavigationLayerCreateModel,
  NavigationLayerResponseModel,
} from 'core/dtos';
import { GuidString } from 'core/models';

export enum NavigationLayerTypes {
  AddNavigationLayerSuccess = '[Navigation Layers] Create Navigation Layer Success',

  UpdateNavigationLayerOffset = '[Navigation Layers] Update Navigation Layer Offset',
  UpdateNavigationLayerOffsetSuccess = '[Navigation Layers] Update Navigation Layer Offset Success',
  UpdateNavigationLayerOffsetFailure = '[Navigation Layers] Update Navigation Layer Offset Failure',

  LoadNavigationLayers = '[Navigation Layers] Load Navigation Layers',
  LoadNavigationLayersSuccess = '[Navigation Layers] Load Navigation Layers Success',
  LoadNavigationLayersFailure = '[Navigation Layers] Load Navigation Layers Fail',

  CreateLayer = '[Navigation Layers] Create Layer',
  CreateLayerSuccess = '[Navigation Layers] Save Navigation Layer Success',
  CreateLayerFailure = '[Navigation Layers] Save Navigation Layer Failure',

  ImportLIFFile = '[Navigation Layers] Import LIF file',
  ImportLIFFileSuccess = '[Navigation Layers] Import LIF file Success',
  ImportLIFFileFailure = '[Navigation Layers] Import LIF file Failure',

  PreviewUpdateLif = '[Navigation Layers] Preview Update Lif',
  PreviewUpdateLifSuccess = '[Navigation Layers] Preview Update Lif Success',
  PreviewUpdateLifFailure = '[Navigation Layers] Preview Update Lif Failure',

  UpdateLif = '[Navigation Layers] Update Lif',
  UpdateLifSuccess = '[Navigation Layers] Update Lif Success',
  UpdateLifFailure = '[Navigation Layers] Update Lif Failure',

  GetNavigationLayerIdsWithLif = '[Navigation Layers] Get NavigationLayer Ids WithLif',
  GetNavigationLayerIdsWithLifSuccess = '[Navigation Layers] Get NavigationLayer Ids WithLif Success',
  GetNavigationLayerIdsWithLifFailure = '[Navigation Layers] Get NavigationLayer Ids WithLif Failure',

  UpdateNavigationLayer = '[Navigation Layers] Update Navigation Layer',
  UpdateNavigationLayerSuccess = '[Navigation Layers] Update Navigation Layer Success',
  UpdateNavigationLayerFailure = '[Navigation Layers] Update Navigation Layer Failure',

  UpdateNavigationLayerOrder = '[Navigation Layers] Update Navigation Order Layer',
  UpdateNavigationLayerOrderSuccess = '[Navigation Layers] Update Navigation Layer Order Success',

  UpdateNavigationLayerBatchOffset = '[Navigation Layers] Update Navigation Layer Batch Offset',
  UpdateNavigationLayerBatchOffsetSuccess = '[Navigation Layers] Update Navigation Layer Batch Offset Success',
  UpdateNavigationLayerBatchOffsetFailure = '[Navigation Layers] Update Navigation Layer Batch Offset Failure',

  UpdateNavigationLayerImage = '[Navigation Layers] Update Navigation Layer Image',
  UpdateNavigationLayerImageSuccess = '[Navigation Layers] Update Navigation Layer Image Success',
  UpdateNavigationLayerImageFailure = '[Navigation Layers] Update Navigation Layer Image Failure',

  DeleteNavigationLayer = '[Navigation Layers] Delete Navigation Layer',
  DeleteNavigationLayerSuccess = '[Navigation Layers] Delete Navigation Layer Success',
  DeleteNavigationLayerFailure = '[Navigation Layers] Delete Navigation Layer Failure',

  SelectNavigationLayer = '[Navigation Layers] Select Navigation Layer',
  SelectNavigationLayerByMapId = '[Navigation Layers] Select Navigation Layer by mapId',
}

export enum SignalRNavigationLayerTypes {
  SignalRCreateNavigationLayer = '[SignalR] Create Navigation Layer',
  SignalRUpdateNavigationLayer = '[SignalR] Update Navigation Layer',
  SignalRUpdateNavigationLayerSuccess = '[SignalR] Update Navigation Layer Success',
  SignalRDeleteNavigationLayer = '[SignalR] Delete Navigation Layer',
}

export const loadNavigationLayers = createAction(NavigationLayerTypes.LoadNavigationLayers);

export const loadNavigationLayersSuccess = createAction(
  NavigationLayerTypes.LoadNavigationLayersSuccess,
  props<{ navigationLayers: NavigationLayerResponseModel[] }>()
);

export const loadNavigationLayersFailure = createAction(
  NavigationLayerTypes.LoadNavigationLayersFailure,
  props<{ errorMessage: string }>()
);

export const addNavigationLayerSuccess = createAction(
  NavigationLayerTypes.AddNavigationLayerSuccess,
  props<{ newNavigationLayer: NavigationLayerResponseModel }>()
);

export const createLayer = createAction(
  NavigationLayerTypes.CreateLayer,
  props<{ newLayer: LayersCreateModel }>()
);

export const createLayerSuccess = createAction(NavigationLayerTypes.CreateLayerSuccess);

export const createLayerFailure = createAction(
  NavigationLayerTypes.CreateLayerFailure,
  props<{ errorMessage: string }>()
);

export const importLIFFile = createAction(
  NavigationLayerTypes.ImportLIFFile,
  props<{ lifFile: LIFUploadModel }>()
);

export const importLIFFileSuccess = createAction(
  NavigationLayerTypes.ImportLIFFileSuccess,
  props<{ id: GuidString }>()
);

export const importLIFFileFailure = createAction(
  NavigationLayerTypes.ImportLIFFileFailure,
  props<{ errorMessage: string }>()
);

export const previewUpdateLif = createAction(
  NavigationLayerTypes.PreviewUpdateLif,
  props<{ lifFile: LIFUploadModel }>()
);

export const previewUpdateLifSuccess = createAction(
  NavigationLayerTypes.PreviewUpdateLifSuccess,
  props<{ response: LifUpdatePreview }>()
);

export const previewUpdateLifFailure = createAction(
  NavigationLayerTypes.PreviewUpdateLifFailure,
  props<{ errorMessage: string }>()
);

export const updateLif = createAction(
  NavigationLayerTypes.UpdateLif,
  props<{ lifUpdatePreview: LifUpdatePreview }>()
);

export const updateLifSuccess = createAction(
  NavigationLayerTypes.UpdateLifSuccess,
  props<{ response: LifUpdatePreview }>()
);

export const updateLifFailure = createAction(
  NavigationLayerTypes.UpdateLifFailure,
  props<{ errorMessage: string }>()
);

export const getNavigationLayerIdsWithLif = createAction(
  NavigationLayerTypes.GetNavigationLayerIdsWithLif,
  props<{ mapId: GuidString }>()
);

export const getNavigationLayerIdsWithLifSuccess = createAction(
  NavigationLayerTypes.GetNavigationLayerIdsWithLifSuccess,
  props<{ ids: GuidString[] }>()
);

export const getNavigationLayerIdsWithLifFailure = createAction(
  NavigationLayerTypes.GetNavigationLayerIdsWithLifFailure,
  props<{ errorMessage: string }>()
);

export const updateNavigationLayer = createAction(
  NavigationLayerTypes.UpdateNavigationLayer,
  props<{ navigationLayer: NavigationLayerResponseModel }>()
);

export const updateNavigationLayerSuccess = createAction(
  NavigationLayerTypes.UpdateNavigationLayerSuccess,
  props<{ navigationLayer: NavigationLayerResponseModel }>()
);

export const updateNavigationLayerFailure = createAction(
  NavigationLayerTypes.UpdateNavigationLayerFailure,
  props<{ errorMessage: string }>()
);

export const updateNavigationLayerOrder = createAction(
  NavigationLayerTypes.UpdateNavigationLayerOrder,
  props<{ navigationLayers: NavigationLayerResponseModel[] }>()
);

export const updateNavigationLayerOrderSuccess = createAction(
  NavigationLayerTypes.UpdateNavigationLayerOrderSuccess,
  props<{ navigationLayers: NavigationLayerResponseModel[] }>()
);

export const updateNavigationLayerBatchOffsetFailure = createAction(
  NavigationLayerTypes.UpdateNavigationLayerBatchOffsetFailure,
  props<{ errorMessage: string }>()
);

export const updateNavigationLayerBatchOffset = createAction(
  NavigationLayerTypes.UpdateNavigationLayerBatchOffset,
  props<{ navigationLayers: NavigationLayerResponseModel[] }>()
);

export const updateNavigationLayerBatchOffsetSuccess = createAction(
  NavigationLayerTypes.UpdateNavigationLayerBatchOffsetSuccess,
  props<{ navigationLayers: NavigationLayerResponseModel[] }>()
);

export const signalRDeleteNavigationLayer = createAction(
  SignalRNavigationLayerTypes.SignalRDeleteNavigationLayer,
  props<{ navigationLayer: NavigationLayerResponseModel }>()
);

export const deleteNavigationLayer = createAction(
  NavigationLayerTypes.DeleteNavigationLayer,
  props<{ navigationLayer: NavigationLayerResponseModel }>()
);

export const deleteNavigationLayerSuccess = createAction(
  NavigationLayerTypes.DeleteNavigationLayerSuccess,
  props<{ navigationLayer: NavigationLayerResponseModel }>()
);

export const deleteNavigationLayerFailure = createAction(
  NavigationLayerTypes.UpdateNavigationLayerFailure,
  props<{ errorMessage: string }>()
);

export const selectNavigationLayer = createAction(
  NavigationLayerTypes.SelectNavigationLayer,
  props<{ navigationLayerId: GuidString }>()
);

export const selectNavigationLayerByMapId = createAction(
  NavigationLayerTypes.SelectNavigationLayerByMapId,
  props<{ mapId: GuidString }>()
);

export const signalRCreateNavigationLayer = createAction(
  SignalRNavigationLayerTypes.SignalRCreateNavigationLayer,
  props<{ navigationLayer: NavigationLayerResponseModel }>()
);

export const signalRUpdateNavigationLayer = createAction(
  SignalRNavigationLayerTypes.SignalRUpdateNavigationLayer,
  props<{ navigationLayer: NavigationLayerResponseModel }>()
);

export const signalRUpdateNavigationLayerSuccess = createAction(
  SignalRNavigationLayerTypes.SignalRUpdateNavigationLayerSuccess,
  props<{ navigationLayer: NavigationLayerResponseModel }>()
);

export const updateNavigationLayerImage = createAction(
  NavigationLayerTypes.UpdateNavigationLayerImage,
  props<{ navigationLayerId: GuidString; navigationLayerImageFile: File }>()
);

export const updateNavigationLayerImageSuccess = createAction(
  NavigationLayerTypes.UpdateNavigationLayerImageSuccess
);

export const updateNavigationLayerImageFailure = createAction(
  NavigationLayerTypes.UpdateNavigationLayerImageFailure,
  props<{ errorMessage: string }>()
);

export const updateNavigationLayerOffset = createAction(
  NavigationLayerTypes.UpdateNavigationLayerOffset,
  props<{ navigationLayer: NavigationLayerCreateModel }>()
);

export const updateNavigationLayerOffsetSuccess = createAction(
  NavigationLayerTypes.UpdateNavigationLayerOffsetSuccess,
  props<{ navigationLayer: NavigationLayerCreateModel }>()
);

export const addNavigationLayerOffsetFailure = createAction(
  NavigationLayerTypes.UpdateNavigationLayerOffsetFailure,
  props<{ errorMessage: string }>()
);
