/* eslint-disable max-lines */
/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  OpcuaDeviceService,
  PoiService,
  ProcessChainsService,
  ZoneSetService,
} from 'core/api-services';
import { filterUndefined } from 'core/helpers';
import { TenantRouterService } from 'core/services';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions/ui.actions';

import { OpcUaDeviceReferenceDto } from 'core/models';
import { ToastService } from 'core/services/toast.service';
import { ModalDialogService } from 'library/components';
import * as OpcuaDeviceActions from '../actions/opcua-devices.actions';
import * as OpcuaStreamingServiceActions from '../actions/opcua-streamingservices.actions';

export type IsDeviceLinkedApiCallback = () => Observable<OpcUaDeviceReferenceDto[]>;
export type ShowWarningDialogCallback = (dependentResources: string) => Observable<boolean>;
@Injectable()
export class OpcuaDevicesEffects {
  loadDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaDeviceActions.setSelectedDevice),
      filter(a => a.streamingService !== ''),
      concatMap(action =>
        this.opcuaDeviceService.getDevice(action.streamingService, action.name).pipe(
          filterUndefined(),
          map(device =>
            OpcuaDeviceActions.loadOpcuaDeviceSuccess({
              device,
            })
          ),
          catchError(errorMessage =>
            of(OpcuaDeviceActions.loadOpcuaDevicesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadDevicesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaStreamingServiceActions.loadOpcuaStreamingServicesSuccess),
      concatMap(({ streamingServices }) => [
        OpcuaDeviceActions.loadOpcuaDevicesSuccess({
          devices: streamingServices
            .map(streamingService => streamingService.devices)
            .reduce((acc, devices) => acc.concat(devices), []),
        }),
      ])
    )
  );

  loadDevicesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpcuaStreamingServiceActions.loadOpcuaStreamingServicesFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  createDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaDeviceActions.createOpcuaDevice),
      concatMap(({ device }) =>
        this.opcuaDeviceService.createDevice(device).pipe(
          map(() =>
            OpcuaDeviceActions.createOpcuaDeviceSuccess({
              device: {
                ...device,
                lastPingReceivedDate: null,
                nodes: device.nodes.map(n => ({
                  ...n,
                  state: { name: n.name, measurementDate: null, value: null, rawValue: null },
                })),
              },
            })
          ),
          catchError(errorMessage =>
            of(OpcuaDeviceActions.createOpcuaDeviceFailure({ errorMessage }))
          )
        )
      )
    )
  );

  createDeviceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaDeviceActions.createOpcuaDeviceSuccess),
      tap(actionParam => {
        this.toastService.createSuccessToast('shared.actions.created', {
          name: actionParam.device.name,
        });
        this.router.navigate(['/devices']).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  createDeviceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpcuaDeviceActions.createOpcuaDeviceFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  updateDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaDeviceActions.updateOpcuaDevice),
      concatMap(({ device }) =>
        this.opcuaDeviceService.updateDevice(device).pipe(
          map(() =>
            OpcuaDeviceActions.updateOpcuaDeviceSuccess({
              device: {
                ...device,
                lastPingReceivedDate: null,
                nodes: device.nodes.map(n => ({
                  ...n,
                  state: { name: n.name, measurementDate: null, value: null, rawValue: null },
                })),
              },
            })
          ),
          catchError(errorMessage =>
            of(OpcuaDeviceActions.updateOpcuaDeviceFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateDeviceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaDeviceActions.updateOpcuaDeviceSuccess),
      tap(actionParam => {
        this.toastService.createSuccessToast('shared.actions.updated', {
          name: actionParam.device.name,
        });
        this.router.navigate(['/devices']).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateDeviceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpcuaDeviceActions.updateOpcuaDeviceFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  updateDeviceNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaDeviceActions.updateOpcuaDeviceNodes),
      concatMap(({ device }) =>
        this.opcuaDeviceService.updateDeviceNodes(device).pipe(
          map(() =>
            OpcuaDeviceActions.updateOpcuaDeviceNodesSuccess({
              device: {
                ...device,
              },
            })
          ),
          catchError(errorMessage =>
            of(OpcuaDeviceActions.updateOpcuaDeviceNodesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateDeviceNodesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaDeviceActions.updateOpcuaDeviceNodesSuccess),
      tap(actionParam => {
        this.toastService.createSuccessToast('shared.actions.updated', {
          name: actionParam.device.name,
        });
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateDeviceNodesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpcuaDeviceActions.updateOpcuaDeviceNodesFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  invokeDeviceLinkedLogic(
    apiCallback: IsDeviceLinkedApiCallback,
    showWarningDialogCallback: ShowWarningDialogCallback
  ): Observable<boolean> {
    return apiCallback().pipe(
      concatMap((dependentResourceNames: OpcUaDeviceReferenceDto[]) => {
        if (dependentResourceNames.length) {
          const mappedResources = dependentResourceNames.map(x => {
            return `${x.workAreaName}: ${x.resources.join(', ')}`;
          });
          return showWarningDialogCallback(mappedResources.join('<br>')).pipe(filter(Boolean));
        } else {
          return of(false);
        }
      }),
      filter(isDeviceLinked => !isDeviceLinked)
    );
  }

  isDeviceLinked(device: string): Observable<boolean> {
    return this.invokeDeviceLinkedLogic(
      () => this.processChainService.getProcessChainsWithDevice(device),
      (processChainNames: string) =>
        this.modalService.createWarningModal(
          'opcua-devices.details.device.modalDeviceLinkedToProcessChain',
          { dependentResourceNames: processChainNames }
        )
    ).pipe(
      concatMap(() =>
        this.invokeDeviceLinkedLogic(
          () => this.zoneSetService.getZonesWithDevice(device),
          (zoneSetNames: string) =>
            this.modalService.createWarningModal(
              'opcua-devices.details.device.modalDeviceLinkedToZone',
              { dependentResourceNames: zoneSetNames }
            )
        )
      ),
      concatMap(() =>
        this.invokeDeviceLinkedLogic(
          () => this.poiService.getPoiWithDevice(device),
          (poiNames: string) =>
            this.modalService.createWarningModal(
              'opcua-devices.details.device.modalDeviceLinkedToPoi',
              { dependentResourceNames: poiNames }
            )
        )
      )
    );
  }

  deleteDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaDeviceActions.deleteOpcuaDevice),
      concatMap(({ name, streamingService }) => {
        return this.isDeviceLinked(name).pipe(
          concatMap(() => {
            return this.opcuaDeviceService
              .deleteDevice(name, streamingService)
              .pipe(concatMap(() => [OpcuaDeviceActions.deleteOpcuaDeviceSuccess({ name })]));
          }),
          catchError(errorMessage =>
            of(OpcuaDeviceActions.deleteOpcuaDeviceFailure({ errorMessage }))
          )
        );
      })
    )
  );

  deleteDeviceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaDeviceActions.deleteOpcuaDeviceSuccess),
      tap(name => {
        this.toastService.createSuccessToast('shared.actions.deleted', name);
        this.router.navigate(['/devices']).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  deleteDeviceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpcuaDeviceActions.deleteOpcuaDeviceFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly opcuaDeviceService: OpcuaDeviceService,
    private readonly toastService: ToastService,
    private readonly router: TenantRouterService,
    private readonly modalService: ModalDialogService,
    private readonly processChainService: ProcessChainsService,
    private readonly zoneSetService: ZoneSetService,
    private readonly poiService: PoiService
  ) {}
}
