import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { Observable } from 'rxjs';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Store } from '@ngrx/store';
import { RootState } from 'store/reducers';
import { Factsheet } from 'core/dtos';

@Injectable({
  providedIn: 'root',
})
export class FactsheetService extends TenantHttpClient {
  protected apiUrl = environment.Services.FleetManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getFactsheets(): Observable<Factsheet[]> {
    return this.get<Factsheet[]>(`${API_SERVICES.Vehicles}/Factsheets`);
  }
}
