<app-labeled-dropdown-action
  data-cy="sendZoneUpdateDropdown"
  icon="arrow_right"
  disabledText="{{ 'shared.vehicles.actions.updateZonesDisabledText' | translate }}"
  [placeholder]="placeholder"
  [label]="label | translate"
  [disabled]="disabled"
  [options]="zoneSets"
  [sortDropdown]="false"
  (isOpenChange)="setZoneSets()"
  (sendSelectedItem)="onUpdateZoneSet($event)"></app-labeled-dropdown-action>
