import { Action, MemoizedSelector, select, Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export async function ensureStoreLoaded<T>(
  store: Store<T>,
  selector: MemoizedSelector<T, boolean>,
  action: Action
): Promise<void> {
  return firstValueFrom(loadStore(store, selector, action));
}

export function ensureStoreLoadedWithRefresh<T>(store: Store<T>, action: Action): void {
  return store.dispatch(action);
}

export function loadStore<T>(
  store: Store<T>,
  selector: MemoizedSelector<T, boolean>,
  action: Action
): Observable<void> {
  return store.pipe(
    select(selector),
    map(isLoaded => {
      if (!isLoaded) {
        store.dispatch(action);
      }
    }),
    take(1)
  );
}

export function selectFromStore<T, S>(
  store: Store<T>,
  selector: MemoizedSelector<T, S>
): Observable<S> {
  return store.pipe(
    select(selector),
    map(data => data)
  );
}
