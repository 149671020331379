/* eslint-disable @typescript-eslint/no-magic-numbers */
import { getMinMaxValue } from 'modules/maps/helpers';
import { Container, Graphics } from 'pixi.js';
import { NodeArrowStyle } from './graph-layer.constant';

export class EdgeContainerBase extends Container {
  arrows: Graphics[] = [];
  higherResScale = 2;
  lowerResScale = 1.25;

  constructor() {
    super();
  }

  setScale(scale: number, resolution: number): void {
    const maxScale =
      resolution < NodeArrowStyle.MinResolution
        ? NodeArrowStyle.Scale / this.lowerResScale
        : NodeArrowStyle.Scale / this.higherResScale;

    const minScale = maxScale * NodeArrowStyle.MinScaleFactor;
    const scaleFactor = getMinMaxValue(scale, maxScale, minScale);

    this.arrows?.forEach(arrow => arrow.scale.set(1 / (scaleFactor / NodeArrowStyle.Scale)));
  }
}
