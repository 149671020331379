import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-copy-text-cell',
  templateUrl: './copy-text-cell.component.html',
  styleUrls: ['./copy-text-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyTextCellComponent implements ICellRendererAngularComp {
  label = '';
  value = '';

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  agInit(params: ICellRendererParams<string>): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<string>): boolean {
    this.label = params.valueFormatted ?? params.value;
    this.value = params.value;
    this.cdRef.markForCheck();
    return true;
  }
}
