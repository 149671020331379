import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { WorkingArea } from 'core/models';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { RootState } from 'store/reducers';
import { selectReducedSelectedWorkingArea } from 'store/selectors';

@Injectable({
  providedIn: 'root',
})
export class TenantRouterService implements OnDestroy {
  tenant$: Observable<WorkingArea>;
  ngUnsubscribe = new Subject<void>();

  constructor(readonly router: Router, private readonly store: Store<RootState>) {
    this.tenant$ = this.store.pipe(
      select(selectReducedSelectedWorkingArea),
      filter(x => x !== undefined),
      takeUntil(this.ngUnsubscribe)
    ) as unknown as Observable<WorkingArea>;
  }

  async navigate(commands: string[], extras?: NavigationExtras): Promise<boolean> {
    const tenant = await firstValueFrom(this.tenant$.pipe(take(1)));
    const routingCommands = this.addTenantToRoutingCommand(commands, tenant);
    return this.router.navigate(routingCommands, extras);
  }

  async navigateFrom(command: string[], route: ActivatedRoute): Promise<boolean> {
    return this.router.navigate(command, { relativeTo: route });
  }

  parseUrl(url: string): UrlTree {
    return this.router.parseUrl(url);
  }

  isRoute(path: string): boolean {
    return this.router.url.includes(path);
  }

  addTenantToRoutingCommand(commands: string[], tenant: WorkingArea): string[] {
    const commandsWithoutSlashes = commands
      .map(m => m.split('/').filter(o => o !== ''))
      .reduce((p, c) => p.concat(c), []);
    const orgName = tenant.organizationName;
    const workingArea = tenant.name;
    return ['/', orgName, workingArea].concat(commandsWithoutSlashes);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
