import { DATE_FORMAT } from 'core/constants';
import { Locale, format, isValid, parse } from 'date-fns';

export function parseDateTime(value: string): Date {
  const formats = [
    DATE_FORMAT.TIME,
    DATE_FORMAT.TIME_HOURS_MINUTES,
    DATE_FORMAT.DATE,
    DATE_FORMAT.DATE_DAY_MONTH,
    DATE_FORMAT.DATE_DAY_MONTH_YEAR,
    DATE_FORMAT.DATE_TIME,
    DATE_FORMAT.DATE_TIME_MINUTES,
    DATE_FORMAT.FULL_DATE_TIME_SECONDS,
  ];
  for (const format of formats) {
    const parsedDate = parse(value, format, new Date());
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }
  return new Date(value);
}

export function formatDate(
  date: Date | string | number,
  dateFormat: string,
  locale: Locale
): string {
  const parsedDate = typeof date === 'string' ? parseDateTime(date) : date;

  if (!isValid(parsedDate)) {
    return 'Invalid date : ' + date;
  }
  return format(parsedDate, dateFormat, { locale });
}

export enum DateFormatEnum {
  // DateTime formats
  ShortDateTime = 'dateTime.short',
  MediumDateTime = 'dateTime.medium',
  LongDateTime = 'dateTime.long',
  ShortDateTimeMillisec = 'dateTime.shortMillisec',
  MediumDateTimeMillisec = 'dateTime.mediumMillisec',
  LongDateTimeMillisec = 'dateTime.longMillisec',

  // FullDate formats
  ShortFullDate = 'fullDate.short',
  MediumFullDate = 'fullDate.medium',
  LongFullDate = 'fullDate.long',
  ShortTextFullDate = 'fullDate.shortText',
  MediumTextFullDate = 'fullDate.mediumText',
  LongTextFullDate = 'fullDate.longText',

  // MonthAndYear formats
  ShortMonthAndYear = 'monthAndYear.short',
  MediumMonthAndYear = 'monthAndYear.medium',
  LongMonthAndYear = 'monthAndYear.long',
  ShortTextMonthAndYear = 'monthAndYear.shortText',
  FullTextMonthAndYear = 'monthAndYear.fullText',

  // YearOnly formats
  ShortYearOnly = 'yearOnly.short',
  FullYearOnly = 'yearOnly.full',

  // TimeFormats
  DefaultTimeFormat = 'timeFormats.default',
  CompareTimeFormat = 'timeFormats.compareTime',
}
