import { createSelector } from '@ngrx/store';
import { EdgeDto, LayoutDto, NodeDto } from 'core/dtos';

import { EMPTY_GUID } from 'core/constants';
import { selectMapId } from './maps.selectors';

import * as reducers from '../reducers';
import * as fromGraphLayers from '../reducers/graph-layer.reducer';

export function mapLayoutValues(layout?: LayoutDto): LayoutDto {
  if (layout && layout.nodes) {
    const nodeIds = layout.nodes.map(n => n.nodeId);

    const edges = layout.edges.filter(
      e => nodeIds.some(n => n === e.startNodeId) || nodeIds.some(n => n === e.endNodeId)
    );

    const result: LayoutDto = {
      ...layout,
      edges,
    };

    return result;
  }

  return {
    id: EMPTY_GUID,
    mapId: EMPTY_GUID,
    navigationLayerId: EMPTY_GUID,
    nodes: [],
    edges: [],
  };
}

export function mapNodeValues(layout?: LayoutDto): NodeDto[] {
  return mapLayoutValues(layout).nodes;
}

export function mapEdgeValues(layout?: LayoutDto): EdgeDto[] {
  return mapLayoutValues(layout).edges;
}

const selectGraphLayerState = createSelector(
  reducers.getMapsFeatureState,
  reducers.getGraphLayersState
);

export const selectAllGraphLayers = createSelector(
  selectGraphLayerState,
  fromGraphLayers.getAllGraphLayers
);

export const selectGraphLayersActionStatus = createSelector(
  selectGraphLayerState,
  fromGraphLayers.getActionStatus
);

export const selectGraphLayer = createSelector(selectAllGraphLayers, layouts => layouts[0]);

export const selectGraphLayersBySelectedMapId = createSelector(
  selectMapId,
  selectAllGraphLayers,
  (selectedMapId, layout) => {
    return mapLayoutValues(layout.filter(l => l.mapId === selectedMapId)[0]);
  }
);

export const selectGraphNodesBySelectedMapId = createSelector(
  selectMapId,
  selectAllGraphLayers,
  (selectedMapId, layout) => {
    return mapLayoutValues(layout.filter(l => l.mapId === selectedMapId)[0]).nodes;
  }
);

export const selectGraphEdgesBySelectedMapId = createSelector(
  selectMapId,
  selectAllGraphLayers,
  (selectedMapId, layout) => {
    return mapLayoutValues(layout.filter(l => l.mapId === selectedMapId)[0]).edges;
  }
);
