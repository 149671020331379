import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DsSelectLabellingConfiguration } from '@bmw-ds/components';
import { AtsTranslationService } from 'core/services';
import { isKeyDefined } from 'library/helpers/key-defined.helper';
import { SortOrder } from 'library/models';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-labeled-dropdown-action',
  templateUrl: './labeled-dropdown-action.component.html',
  styleUrls: ['./labeled-dropdown-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledDropdownActionComponent<T extends object>
  implements OnInit, OnChanges, OnDestroy, AfterViewChecked
{
  @Input() options: T[] = [];
  @Input() label = '';
  @Input() icon = '';
  @Input() placeholder = '';
  @Input() disabledText: string | undefined = undefined;
  @Input() sortBy = 'name';
  @Input() sortDropdown = true;
  @Input() disabled = false;
  @Input() sortOrder = SortOrder.Asc;
  @Input() disabledTooltip: string | null = null;

  @Output() readonly sendSelectedItem = new EventEmitter<T>();
  @Output() readonly isOpenChange = new EventEmitter();

  actionFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  isFormValid = false;
  actionDisabled = true;
  selectedOption?: T;
  ngUnsubscribe = new Subject<void>();

  labellingConfig: DsSelectLabellingConfiguration = {
    noResultsMessage: this.atsTranslationService.get('placeholders.selectEmpty'),
    placeholder: this.atsTranslationService.get('placeholders.none'),
    selectAll: this.atsTranslationService.get('placeholders.selectAll'),
  };

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly atsTranslationService: AtsTranslationService
  ) {}

  ngOnInit(): void {
    this.actionFormGroup = this.createNewFormGroup();
    this.atsTranslationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.setPlaceholder();
    });
  }

  ngOnChanges(): void {
    this.updateActionDisabled();
    this.setPlaceholder();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setPlaceholder(): void {
    this.labellingConfig.placeholder = this.placeholder
      ? this.atsTranslationService.get(this.placeholder)
      : this.atsTranslationService.get('placeholders.select');
  }

  createNewFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      selectedOption: [''],
    });
  }

  ngAfterViewChecked(): void {
    const selectedOption = this.actionFormGroup.get('selectedOption');
    if (selectedOption && this.disabled) {
      selectedOption.disable();
      this.resetSelection();
    } else if (selectedOption) {
      selectedOption.enable();
    }
    this.changeDetector.detectChanges();
  }

  resetSelection(): void {
    const selectedOption = this.actionFormGroup.get('selectedOption');
    if (selectedOption) {
      selectedOption.reset();
      this.selectedOption = undefined;
    }
  }

  updateActionDisabled(): void {
    this.disabled || !this.actionFormGroup.get('selectedOption')?.value
      ? (this.actionDisabled = true)
      : (this.actionDisabled = false);
  }

  onChangeSelectedItem(): void {
    const idKey = 'id';
    this.selectedOption = this.options.find(o => {
      if (isKeyDefined(o, idKey)) {
        return o[idKey] === this.actionFormGroup.value.selectedOption;
      } else {
        return;
      }
    });
  }

  onAction(): void {
    this.sendSelectedItem.emit(this.selectedOption);
    this.resetSelection();
    this.actionDisabled = true;
  }

  onOpenDropdown(): void {
    this.isOpenChange.emit();
  }
}
