<form [formGroup]="form">
  <div class="container-fluid g-0 select-container">
    <div class="row mb-4x">
      <ds-form-field class="col me-2x">
        <label ds-label for="errorMessage">
          {{ 'settings.ipstAlertNowSettings.helpTool.errorMessage' | translate }}
        </label>
        <ds-select
          id="errorMessage"
          appendTo="body"
          data-cy="helpToolErrorMessage"
          [options]="
            errors | uniqueBy : 'id' | sortDropdown : 'label' | dsDropdown : 'label' : 'id'
          "
          [labellingConfig]="{ placeholder: 'placeholders.select' | translate }"
          required
          [itemLabelTemplate]="dropdownTemplate"
          formControlName="selectedErrorMessage"
          (optionSelected)="handleSelection($event)"></ds-select>
      </ds-form-field>

      <ds-form-field class="col me-2x">
        <label ds-label for="vehicle">
          {{ 'settings.ipstAlertNowSettings.helpTool.vehicle' | translate }}
        </label>
        <ds-select
          id="vehicle"
          appendTo="body"
          data-cy="helpToolVehicle"
          [options]="vehicleSelectList | dsSortGroupedDropdown : 'label'"
          [labellingConfig]="{ placeholder: 'placeholders.select' | translate }"
          required
          formControlName="selectedVehicle"
          (optionSelected)="handleSelection($event)"></ds-select>
      </ds-form-field>

      <ds-form-field class="col me-2x">
        <label ds-label for="alertNowZone">
          {{ 'settings.ipstAlertNowSettings.helpTool.alertNowZone' | translate }}
        </label>
        <ds-select
          id="alertNowZone"
          appendTo="body"
          data-cy="helpToolAlertNowZone"
          [options]="zones | sortDropdown : 'zoneName' | dsDropdown : 'zoneName' : 'id'"
          [labellingConfig]="{ placeholder: 'placeholders.select' | translate }"
          required
          formControlName="selectedZone"
          (optionSelected)="handleSelection($event)"></ds-select>
      </ds-form-field>
    </div>
  </div>
  <ng-template #dropdownTemplate let-option>
    <div>
      <span class="seperate-content" [ds-tooltip]="option.disabled ? disabledTooltip : null">
        {{ option.data.label }}
      </span>
    </div>
  </ng-template>
</form>
