import { TreeViewSelectedItem } from 'library/models';
import { TreeNode } from 'primeng/api';

export const FAKE_PARENT_ID = 'fakeParent';
export const FAKE_PARENT_ID2 = 'fakeParent2';

export function isFakeNodeParent(node: TreeNode, fakeParent: string = FAKE_PARENT_ID): boolean {
  return node.parent?.data?.id === fakeParent;
}

export function isFakeNode(node: TreeNode, fakeParent: string = FAKE_PARENT_ID): boolean {
  return node.data?.id === fakeParent;
}

export function isFakeParent(item: TreeViewSelectedItem): boolean {
  return item.id === FAKE_PARENT_ID;
}

export function hasChildren(node: TreeNode): boolean {
  return !!node.children?.length;
}

export function isTreeNode(item: TreeNode): item is TreeNode {
  return 'id' in item && 'data' in item;
}
