import { OpcuaDeviceResponseModel } from 'core/dtos';
import { OpcuaDeviceTrafficLightStatus } from 'core/models';
import { differenceInSeconds, parseISO } from 'date-fns';
import { HEART_BEAT_THRESHOLD_SECONDS } from '../constants/opcua-devices-store.constant';

export function getConnectivityTrafficLightStatus(
  opcuaDevice: OpcuaDeviceResponseModel
): OpcuaDeviceTrafficLightStatus {
  if (!opcuaDevice.lastPingReceivedDate || !opcuaDevice.pingNodeName) {
    return OpcuaDeviceTrafficLightStatus.NotInitialized;
  }

  if (
    differenceInSeconds(new Date(), parseISO(opcuaDevice.lastPingReceivedDate.toString())) <
    HEART_BEAT_THRESHOLD_SECONDS
  ) {
    return OpcuaDeviceTrafficLightStatus.Connected;
  }

  if (
    differenceInSeconds(new Date(), parseISO(opcuaDevice.lastPingReceivedDate.toString())) >
    HEART_BEAT_THRESHOLD_SECONDS
  ) {
    return OpcuaDeviceTrafficLightStatus.LostConnection;
  }

  return OpcuaDeviceTrafficLightStatus.NotInitialized;
}
