import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as MapsEffects from './effects';
import * as fromMaps from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(fromMaps.featureKey, fromMaps.reducers),
    EffectsModule.forFeature(MapsEffects.effects),
  ],
})
export class MapsStoreModule {}
