import { createAction, props } from '@ngrx/store';
import { Filters } from 'core/models';

export enum FiltersTypes {
  LoadFilters = '[Maps] Load Filters',
  LoadFiltersSuccess = '[Maps] Load Filters Success',
  UpdateFilters = '[Maps] Update Filters',
  ResetFilters = '[Maps] Reset Filters',
}

export const loadFilters = createAction(FiltersTypes.LoadFilters);

export const loadFiltersSuccess = createAction(
  FiltersTypes.LoadFiltersSuccess,
  props<{ filters: Filters }>()
);

export const updateFilters = createAction(
  FiltersTypes.UpdateFilters,
  props<{ filters: Filters }>()
);

export const resetFilters = createAction(FiltersTypes.ResetFilters);
