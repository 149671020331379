import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { EvacuationDeviceDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class EvacuationDeviceSettingsService extends TenantHttpClient {
  protected apiUrl = environment.Services.FleetManager;
  private readonly servicePath = API_SERVICES.Devices;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getAllDevices(): Observable<EvacuationDeviceDto[]> {
    return this.get<EvacuationDeviceDto[]>(`${this.servicePath}`);
  }

  getByDeviceName(deviceName: string): Observable<EvacuationDeviceDto> {
    return this.get<EvacuationDeviceDto>(`${this.servicePath}/${deviceName}`);
  }

  updateDeviceSetting(device: EvacuationDeviceDto): Observable<void> {
    return this.put(`${this.servicePath}`, device);
  }

  deleteByDeviceName(deviceName: string): Observable<void> {
    return this.delete(`${this.servicePath}/${deviceName}`);
  }
}
