import { GuidString } from 'core/models';
import { MapItemBase, MapItemContainer } from './map-item-container';
import { MapLayerDrawing } from './map-layer-drawing';

export interface IMapLayer {
  drawing: MapLayerDrawing;

  setVisibility(isVisible: boolean): void;
  setOpacity(opacity: number): void;
  setIndex(index: number): void;
}

export interface IMapItemLayer extends IMapLayer {
  getLayerItem(id: GuidString): MapItemBase | undefined;
}

export class MapLayerBase {
  drawing!: MapLayerDrawing;

  setVisibility(isVisible: boolean): void {
    this.drawing.setVisibility(isVisible);
  }

  setOpacity(opacity: number): void {
    this.drawing.setOpacity(opacity);
  }

  setIndex(index: number): void {
    this.drawing.setIndex(index);
  }

  setScale(scale: number): void {
    this.drawing.scale.set(scale);
  }

  setChildOnTop(itemContainer: MapItemContainer): void {
    this.drawing.setChildOnTop(itemContainer);
  }
}
