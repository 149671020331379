import { SoftwareUpdateVersionStatus, VehicleStatus, VehicleTrafficLightStatus } from 'core/models';

export const vehicleStatus = {
  [VehicleStatus.SwitchedOff]: '#CACACA',
  [VehicleStatus.Error]: '#EB555A',
  [VehicleStatus.Busy]: '#5188E2',
  [VehicleStatus.Idle]: '#61D13E',
  [VehicleStatus.Charging]: '#F2CF46',
  [VehicleStatus.Paused]: '#F1A43A',
  [VehicleStatus.NotInitialized]: '#000000',
};

export const vehicleTrafficLightStatus = {
  [VehicleTrafficLightStatus.Connected]: '#61D13E',
  [VehicleTrafficLightStatus.ConnectedWithError]: '#EB555A',
  [VehicleTrafficLightStatus.SwitchedOff]: '#8f8f8f',
  [VehicleTrafficLightStatus.NotInitialized]: '#8f8f8f',
  [VehicleTrafficLightStatus.LostConnection]: '#EB555A',
};

export const maintenanceModeColor = '#B825DF';

export const softwareUpdateVersionStatus = {
  [SoftwareUpdateVersionStatus.Unknown]: 'critical',
  [SoftwareUpdateVersionStatus.Outdated]: 'caution',
  [SoftwareUpdateVersionStatus.Approved]: 'positive',
  [SoftwareUpdateVersionStatus.Test]: 'info',
};
