<app-toggle
  data-cy="includeTuggerTrainErrorsToggle"
  translationKey="settings.functions.includeTuggerTrainErrors"
  description="settings.featureToggles.fleetServiceSection.includeTuggerTrainErrorsDescription"
  tooltipText="{{
    ('settings.roles.permissions.Tooltip_Pretext' | translate) +
      ('settings.roles.permissions.ToggleErrorMessagesTuggerTrain' | translate) +
      ('settings.roles.permissions.Tooltip_Posttext' | translate)
  }}"
  [toggle]="viewModel.includeTuggerTrainErrors"
  [isLoaded]="ipstServiceFeaturesLoaded"
  [disabled]="true"
  [requiredPermission]="'ToggleErrorMessagesTuggerTrain'"></app-toggle>
