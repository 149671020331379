import { AppSettingsModel } from 'core/models';

export const environment: AppSettingsModel = {
  production: true,
  fetchConfig: true,
  skipAuth: false,
  useSignalR: true,
  useAnimations: true,
  useMsw: false,
  welcomePageUrls: {
    training:
      'https://bmwag.plateau.com/learning/user/catalogsearch/catalogSearchDispatchAction.do?searchType=filteredSearch&fromDeepLink=true',
    devRoadMap:
      'https://atc.bmwgroup.net/confluence/display/SERVATS/ATS+Services+Release+Planning+V2',
    operations:
      'https://atc.bmwgroup.net/confluence/download/attachments/859879316/20211001_ATSOperationsProcess.pptx',
    community:
      'https://teams.microsoft.com/l/team/19%3ab9778287743c4f0e949bd35fa8e32a8a%40thread.tacv2/conversations?groupId=88033cdd-183a-4fae-a324-cf324a347e49&tenantId=ce849bab-cc1c-465b-b62e-18f07c9ac198',
    userManual: 'https://handbook.ats.azure.cloud.bmw',
    tableauReports: 'https://tab02.bmwgroup.net/#/site/LOGAN/projects/994',
  },
  Services: {
    AppSettings: '/AppSettings',
    FleetManager: '/fleetapi',
    JobManager: '/jobmanagerapi',
    MapManager: '/mapapi',
    EmulatorManager: '/emulatorhostapi',
    Signalr: '/signalrapi/messagehub',
    Auth: '/auth',
    TrafficManager: '/trafficapi',
    Ipst: '/ipstapi',
    OpcuaDeviceManager: '/opcuaapi',
    Infobar: '/infobarapi',
    DataPublisher: '/datapublisherapi',
    OrderGateway: '/jobapi',
    PathPlanner: '/pathplannerapi',
    GraphManager: '/graphmanagerapi',
    MissionArchive: '/missionarchiveapi',
    Bff: '/signalrapi',
  },
  config: {
    environment: '',
    clientId: '',
    onboardUrl: '',
    showDeveloperOptions: 'false',
  },
};
