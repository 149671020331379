import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromNodeGroups from './node-group.reducer';
import * as fromRouteConfigurations from './route-configuration.reducer';
import * as fromRouteCustomizationRules from './route-customization-rule.reducer';
import * as fromTourChainConfigurations from './tour-chain-configuration.reducer';

export const featureKey = 'graphManager';

export interface GraphManagerFeatureState {
  [fromRouteConfigurations.featureKey]: fromRouteConfigurations.RouteConfigurationState;
  [fromTourChainConfigurations.featureKey]: fromTourChainConfigurations.TourChainConfigurationState;
  [fromNodeGroups.featureKey]: fromNodeGroups.NodeGroupState;
  [fromRouteCustomizationRules.featureKey]: fromRouteCustomizationRules.RouteCustomizationRuleState;
}

export function reducers(
  state: GraphManagerFeatureState | undefined,
  action: Action
): GraphManagerFeatureState {
  return combineReducers({
    [fromRouteConfigurations.featureKey]: fromRouteConfigurations.reducer,
    [fromTourChainConfigurations.featureKey]: fromTourChainConfigurations.reducer,
    [fromNodeGroups.featureKey]: fromNodeGroups.reducer,
    [fromRouteCustomizationRules.featureKey]: fromRouteCustomizationRules.reducer,
  })(state, action);
}

export const getRouteConfigurationsState = (
  state: GraphManagerFeatureState
): fromRouteConfigurations.RouteConfigurationState => state.routeConfigurations;

export const getTourChainConfigurationsState = (
  state: GraphManagerFeatureState
): fromTourChainConfigurations.TourChainConfigurationState => state.tourChainConfigurations;

export const getNodeGroupsState = (
  state: GraphManagerFeatureState
): fromNodeGroups.NodeGroupState => state.nodeGroups;

export const getRouteCustomizationRuleState = (
  state: GraphManagerFeatureState
): fromRouteCustomizationRules.RouteCustomizationRuleState => state.routeCustomizationRules;

export const getGraphManagerFeatureState =
  createFeatureSelector<GraphManagerFeatureState>(featureKey);
