import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as SettingsEffects from './effects';
import * as fromSettings from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(fromSettings.featureKey, fromSettings.reducers),
    EffectsModule.forFeature(SettingsEffects.effects),
  ],
})
export class SettingsStoreModule {}
