<app-base-page-layout pageHeading="versionInformation.versionInformation" [hasToolbar]="false">
  <div base-page-content class="content-area">
    <label class="label-text" data-cy="versionHeader">{{
      'versionInformation.currentVersion' | translate
    }}</label>
    <h2 style="color: #617899" data-cy="versionNumber">
      {{ versionInformation?.version | notApplicable }}
    </h2>

    <iframe
      *ngIf="versionInformation"
      style="border: none; height: 100%"
      title="ReleaseNotes"
      [src]="releaseNotesUrl | safe : 'resourceUrl'"></iframe>
  </div>
</app-base-page-layout>
