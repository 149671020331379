import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { UserSettingsDto } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService extends BaseHttpClient {
  private readonly servicePath = API_SERVICES.UserSettings;

  protected apiUrl = environment.Services.Auth;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getUserSettings(): Observable<UserSettingsDto> {
    return this.get<UserSettingsDto>(this.servicePath).pipe(
      take(1),
      catchError(() => of({ defaultWorkingArea: null, defaultStartPage: null }))
    );
  }

  updateUserSettings(settings: UserSettingsDto): Observable<UserSettingsDto> {
    return this.patch<UserSettingsDto>(this.servicePath, settings);
  }
}
