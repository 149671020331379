import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Role } from 'core/models';

import * as RoleActions from '../actions/roles.actions';

export const featureKey = 'role';

export interface RoleState extends EntityState<Role> {
  loading: boolean;
  loaded: boolean;
  errorMessage: string;
}

export const roleAdapter: EntityAdapter<Role> = createEntityAdapter<Role>();

export const initialState: RoleState = roleAdapter.getInitialState({
  loading: false,
  loaded: false,
  errorMessage: '',
});

export const roleReducer = createReducer(
  initialState,

  on(RoleActions.saveRoles, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(RoleActions.saveRolesSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
  })),

  on(RoleActions.saveRolesFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(RoleActions.loadRolesAndPermissions, state => ({
    ...state,
    errorMessage: '',
    loading: true,
    loaded: false,
  })),

  on(RoleActions.loadRolesAndPermissionsSuccess, (state, { roles }) => ({
    ...state,
    roles: roles,
    loaded: true,
  })),

  on(RoleActions.loadRolesAndPermissionsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loaded: false,
  }))
);

export function reducer(state: RoleState | undefined, action: Action): RoleState {
  return roleReducer(state, action);
}

export const getLoaded = (state: RoleState): boolean => state.loaded;
export const getLoading = (state: RoleState): boolean => state.loading;
export const getErrorMessage = (state: RoleState): string => state.errorMessage;
