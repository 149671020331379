/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from 'core/services/toast.service';

import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import {
  FactsheetActions,
  loadFactsheetsFailure,
  loadFactsheetsSuccess,
} from 'store-modules/vehicles-store';
import { FactsheetService } from 'core/api-services';

@Injectable()
export class FactsheetEffects {
  loadFactsheets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FactsheetActions.LoadAllFactsheets),
      concatMap(() =>
        this.factsheetService.getFactsheets().pipe(
          map(factsheets => loadFactsheetsSuccess({ factsheets })),
          catchError(errorMessage => of(loadFactsheetsFailure({ errorMessage })))
        )
      )
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadFactsheetsFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly factsheetService: FactsheetService
  ) {}
}
