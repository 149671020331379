export enum ProcessChainToolbarButton {
  NewProcessChain = 0,
  FromCopy = 1,
  FromTemplate = 2,
  NewWaitingQueue = 4,
  ActivateAll = 5,
  DeactivateAll = 6,
  NewMissionChain = 7,
  NewProcessChainGroup = 8,
}

export enum ProcessChainType {
  Basic,
  WaitingQueue,
  TourConfig,
}
