/* eslint-disable @typescript-eslint/no-magic-numbers */
import { IconOptions } from 'modules/maps/helpers';
import { Graphics, Sprite, Texture } from 'pixi.js';
import { MapIconAnchor, MapScale } from '../../map-layer.constant';
import { ForkliftDimensions } from '../vehicle-dimensions.model';
import { VehicleTextures } from '../vehicle-layer-texture.constant';
import { ForkliftMapItem } from '../vehicle-types/forklift.graphic';

/*
  Total Length incl Forks: 1511mm
  Body Length: 287mm
  Body Width: 810mm
  Fork Length: 1224mm
  Fork Width: 577mm 
  Pivot: 680mm from Fork end (1511mm-287mm-680mm)
*/

export const dimensions: ForkliftDimensions = {
  length: 29,
  width: 81,
  origin: -54.4,
  fork: {
    width: 57.7,
    length: 122.4,
    loadOffset: 10,
  },
  load: {
    width: 100,
    length: 200,
  },
};

export const ArrowIconStyle: IconOptions = {
  resourceOptions: { scale: 1 },
  scale: 7,
};

export class ForkliftAgiloxOneMapItem extends ForkliftMapItem {
  protected getDimensions(): ForkliftDimensions {
    return dimensions;
  }

  protected getArrowPosition(): number | undefined {
    return 0;
  }

  protected getArrowSize(): IconOptions {
    return ArrowIconStyle;
  }

  protected getBodyTexture(): Texture {
    return VehicleTextures.forkliftAgiloxOne;
  }

  protected createVehicleBase(): Graphics | Sprite {
    const body = new Sprite(this.getBodyTexture());
    body.scale.set(MapScale.MeterToCm, MapScale.MeterToCm);

    body.width = this.dimensions.length;
    body.height = this.dimensions.width;

    body.anchor.set(this.dimensions.origin / this.dimensions.length, MapIconAnchor.Middle);

    return body;
  }

  protected createForks(): Graphics | Sprite {
    const forks = new Sprite(VehicleTextures.forkliftAgiloxForks);
    forks.scale.set(MapScale.MeterToCm, MapScale.MeterToCm);

    forks.width = this.dimensions.fork.length;
    forks.height = this.dimensions.fork.width;

    const relativeXAnchor = this.dimensions.origin / this.dimensions.fork.length; // Adjust the x by percentage of origin on the fork length
    forks.anchor.set(MapIconAnchor.RightOrBottom + relativeXAnchor, MapIconAnchor.Middle);

    return forks;
  }
}
