import { createSelector } from '@ngrx/store';
import { selectSelectedMap } from './maps.selectors';

import * as fromPillarsGridFeatureState from '../reducers';
import * as fromPillarsGrid from '../reducers/pillars-grid.reducer';

const selectPillarsGridState = createSelector(
  fromPillarsGridFeatureState.getMapsFeatureState,
  fromPillarsGridFeatureState.getPillarsGridState
);

export const selectPillarsGridActionStatus = createSelector(
  selectPillarsGridState,
  fromPillarsGrid.getGridActionStatus
);

export const selectAllPillarsGrids = createSelector(
  selectPillarsGridState,
  fromPillarsGrid.getAllPillarsGrids
);

export const selectPillarsGridBySelectedMapId = createSelector(
  selectSelectedMap,
  selectAllPillarsGrids,
  (map, pillarsGrid) => {
    return pillarsGrid.find(i => i.mapId === map?.id);
  }
);
