import { ForkliftDimensions, VehicleDimensions } from '../vehicle-dimensions.model';
import { ForkliftMapItem } from '../vehicle-types/forklift.graphic';

const dimensions: ForkliftDimensions = {
  length: 217.2,
  width: 98,
  origin: 125.7,
  fork: {
    width: 50,
    length: 115,
    loadOffset: 10,
  },
  load: {
    width: 100,
    length: 200,
  },
};

export class Forklift4Am15tMapItem extends ForkliftMapItem {
  protected getDimensions(): VehicleDimensions {
    return this.getForkDimensions({ ...dimensions });
  }
}
