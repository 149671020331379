import { Pipe, PipeTransform } from '@angular/core';
import { GuidString } from 'core/models';
@Pipe({
  name: 'notApplicable',
})
export class NotApplicablePipe implements PipeTransform {
  transform(value: inputType): returnType {
    if (
      value === undefined ||
      value === '' ||
      value === null ||
      (typeof value === 'number' && Number.isNaN(value))
    ) {
      return '-';
    } else {
      return value;
    }
  }
}

export type inputType = GuidString | string | number | boolean | undefined | null;
export type returnType = GuidString | string | number | boolean;
