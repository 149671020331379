import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent {
  @Input() placeHolder = '';
  @Output() readonly search = new EventEmitter<string>();

  onKeyup(term: string): void {
    this.search.emit(term);
  }
}
