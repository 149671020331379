<ds-tabs [(activeTabId)]="activeTabId" (activeTabIdChange)="onSelectionChange($event)">
  <ds-tabs-label data-cy="usersTab" for="usersTab">
    {{ 'userRolesAndPermissions.usersTabHeading' | translate }}
  </ds-tabs-label>

  <ds-tabs-content id="usersTab">
    <div class="responsive-wrapper">
      <app-sidepanel-list-layout [hasToolbar]="false" [explicitlyHideExpandCollapseButtons]="true">
        <app-environment-organization-working-area-overview-container
          sidepanel></app-environment-organization-working-area-overview-container>
        <div list class="ag-grid-full-content">
          <router-outlet content></router-outlet>
        </div>
      </app-sidepanel-list-layout>
    </div>
  </ds-tabs-content>

  <ds-tabs-label data-cy="environmentRolesTab" for="environmentRolesTab">
    {{ 'userRolesAndPermissions.permissionsTabHeading' | translate }}
  </ds-tabs-label>
  <ds-tabs-content id="environmentRolesTab">
    <app-environment-roles-container
      class="pb-10x"
      style="width: 100%"></app-environment-roles-container>
  </ds-tabs-content>
</ds-tabs>
