import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-setting-box',
  templateUrl: './setting-box.component.html',
  styleUrls: ['./setting-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingBoxComponent {
  @Input() header?: TemplateRef<HTMLElement>;
  @Input() translationKey = '';
}
