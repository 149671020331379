import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { GuidString, IpstWorkingAreaSetting } from 'core/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ipst-groups-edit',
  templateUrl: './ipst-groups-edit.component.html',
  styleUrls: ['./ipst-groups-edit.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IpstGroupsEditComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IpstGroupsEditComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstGroupsEditComponent implements ControlValueAccessor, Validator, OnDestroy {
  viewModel: IpstWorkingAreaSetting[] = [];
  ngUnsubscribe = new Subject<void>();
  form: UntypedFormGroup;

  get workAreaSettingsArray(): UntypedFormArray {
    return this.form.get('workingAreaSettings') as UntypedFormArray;
  }

  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.form = this.createForm();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onTouched = (_value: IpstWorkingAreaSetting[]): void => {};

  registerOnChange(fn: (changes: IpstWorkingAreaSetting[]) => {}): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(formValues => {
      fn(formValues.workingAreaSettings);
    });
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};

    Object.keys(this.workAreaSettingsArray.controls).forEach(key => {
      Object.assign(errors, this.workAreaSettingsArray.get(key)?.errors);
    });

    return this.form.valid ? null : errors;
  }

  writeValue(value: IpstWorkingAreaSetting[]): void {
    if (!value) return;

    this.viewModel = value;
    this.workAreaSettingsArray.clear();
    value.forEach(v => this.workAreaSettingsArray.push(this.formBuilder.control(v)));
    this.form.patchValue({ workingAreaSettings: this.viewModel });
  }

  trackByWorkingAreaId(_index: number, item: IpstWorkingAreaSetting): GuidString {
    return item.workingAreaId;
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      workingAreaSettings: this.formBuilder.array([]),
    });
  }
}
