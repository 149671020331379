/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { ODataDto, RoleDto } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { Role, RoleScope } from 'core/models';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RolesService extends BaseHttpClient {
  protected apiUrl = environment.Services.Auth;
  private readonly oDataUrl = '/odata/Roles';
  private readonly servicePath = API_SERVICES.Role;
  private readonly defaultRoles = '/getAllDefaultRoles';

  getRoles(): Observable<RoleDto[]> {
    return this.get<ODataDto<RoleDto>>(this.oDataUrl).pipe(map(response => response.value));
  }

  getRolesByScope(roleScope: RoleScope): Observable<RoleDto[]> {
    return this.get<ODataDto<RoleDto>>(this.oDataUrl, {
      $filter: `scope eq ${roleScope}`,
    }).pipe(map(response => response.value));
  }

  getRolesByScopeWithoutPermissions(roleScope: RoleScope): Observable<RoleDto[]> {
    return this.get<ODataDto<RoleDto>>(this.oDataUrl, {
      $select: 'id,name',
      $filter: `scope eq ${roleScope}`,
    }).pipe(map(response => response.value));
  }

  saveRoles(roles: Role[]): Observable<void> {
    return this.put<void>(this.servicePath, { roles: roles });
  }

  getDefaultAtsRoles(): Observable<RoleDto[]> {
    return this.get<RoleDto[]>(`${this.oDataUrl}${this.defaultRoles}`);
  }
}
