import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { InfobarMessageResponse } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { InfobarMessageRequest } from 'core/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfobarService extends BaseHttpClient {
  protected apiUrl = environment.Services.Infobar;
  private readonly servicePath = API_SERVICES.InfoBar;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getLatestActiveInfobarMessage(): Observable<InfobarMessageResponse | null> {
    return this.get<InfobarMessageResponse>(`${this.servicePath}/GetLatestActiveMessage`);
  }

  createInfobarMessage(
    newInfobarMessage: InfobarMessageRequest
  ): Observable<InfobarMessageResponse> {
    return this.post<InfobarMessageResponse>(this.servicePath, newInfobarMessage);
  }
}
