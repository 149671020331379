import { createSelector, MemoizedSelector } from '@ngrx/store';
import { ErrorAggregate } from 'core/dtos';
import { GuidString } from 'core/models';
import * as fromErrorsFeatureState from '../reducers';
import * as fromErrorsReducer from '../reducers/errors.reducer';

const selectErrorsState = createSelector(
  fromErrorsFeatureState.getErrorsFeatureState,
  fromErrorsFeatureState.getErrorsState
);

export const selectErrorsEntities = createSelector(
  selectErrorsState,
  fromErrorsReducer.getErrorsEntities
);

export const selectAllErrors = createSelector(selectErrorsState, fromErrorsReducer.getErrors);
export const oDataCount = createSelector(selectErrorsState, state => state.oDataCount);

export const selectErrorsLoading = createSelector(
  selectErrorsState,
  fromErrorsReducer.getErrorsLoading
);

export const selectErrorsLoaded = createSelector(
  selectErrorsState,
  fromErrorsReducer.getErrorsLoaded
);

export const selectErrorsByVehicleId = (
  vehicleId: GuidString
): MemoizedSelector<object, ErrorAggregate[]> =>
  createSelector(selectAllErrors, (errors: ErrorAggregate[]) =>
    errors?.filter(error => error.vehicleId === vehicleId)
  );
