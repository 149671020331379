import { SignalREffects } from 'core/signalR/store/effects/signalr.effects';
import { ErrorEffects } from 'store-modules/errors-store/effects/errors.effects';
import { CollisionPointsEffects } from 'store-modules/maps-store/effects/collision-points.effects';
import { FiltersEffects } from 'store-modules/maps-store/effects/filters.effects';
import { MapsEffects } from 'store-modules/maps-store/effects/maps.effects';
import { NavigationLayersEffects } from 'store-modules/maps-store/effects/navigation-layers.effects';
import { ZonesEffects } from 'store-modules/maps-store/effects/zones.effects';
import { MissionTraceEffects } from 'store-modules/mission-monitoring-store/effects/mission-trace.effects';
import { TourEffects } from 'store-modules/mission-monitoring-store/effects/tour.effects';
import { PoiGroupsEffects } from 'store-modules/pois-store/effects/poi-groups.effects';
import { PoiEffects } from 'store-modules/pois-store/effects/pois.effects';
import { ProcessChainEffects } from 'store-modules/process-configurator-store/effects/process-chain.effects';
import { SettingsEffects } from 'store-modules/settings-store/effects/settings.effects';
import { FleetsEffects } from 'store-modules/vehicles-store/effects/fleets.effects';
import { VehiclesEffects } from 'store-modules/vehicles-store/effects/vehicles.effects';

import { TourChainConfigurationEffects } from 'store-modules/graph-manager/effects/tour-chain-configuration.effects';
import { TourConfigurationEffects } from 'store-modules/graph-manager/effects/tour-configuration.effects';
import { OrganizationsEffects } from './organization.effects';
import { RouterEffects } from './router.effects';
import { WorkingAreasEffects } from './working-areas.effects';

export const effects = [
  ErrorEffects,
  FiltersEffects,
  FleetsEffects,
  MapsEffects,
  NavigationLayersEffects,
  MissionTraceEffects,
  TourEffects,
  OrganizationsEffects,
  PoiGroupsEffects,
  PoiEffects,
  ProcessChainEffects,
  RouterEffects,
  TourConfigurationEffects,
  TourChainConfigurationEffects,
  SettingsEffects,
  SignalREffects,
  VehiclesEffects,
  WorkingAreasEffects,
  ZonesEffects,
  CollisionPointsEffects,
];
