import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { BaseHttpClient } from 'core/http/base-http-client';

import { GuidString, isHttpResponse } from 'core/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PathPlannerLayerService extends BaseHttpClient {
  protected apiUrl = environment.Services.PathPlanner;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getPathPlannerImage(navLayerId: GuidString, zoneSetId: GuidString): Observable<Blob | null> {
    const params: Params = {
      zoneSetId,
      navLayerId,
    };

    return this.getBlob(API_SERVICES.GppMapLayer, params).pipe(
      map(res => {
        if (isHttpResponse(res) && res.status !== HttpStatusCode.Ok) {
          return null;
        }
        return res.body;
      })
    );
  }
}
