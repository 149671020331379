import { createSelector } from '@ngrx/store';
import { NO_VALUE_DEFAULT } from 'core/constants';
import { OrderDto } from 'core/dtos';
import { OrderFilter, OrderStatus } from 'core/models';
import * as fromMonitoringFeatureState from '../reducers/index';
import * as fromOrder from '../reducers/order.reducer';

const selectOrderState = createSelector(
  fromMonitoringFeatureState.getMonitoringFeatureState,
  fromMonitoringFeatureState.getOrderState
);

export const selectOrderLoading = createSelector(selectOrderState, fromOrder.getLoading);

export const selectOrderLoaded = createSelector(selectOrderState, fromOrder.getLoaded);

export const selectAllOrders = createSelector(selectOrderState, fromOrder.getAllOrders);

export const selectAllOrderFilterOptions = createSelector(
  selectAllOrders,
  (orders): OrderFilter => {
    const orderFilterSets = {
      orderStatus: new Set(),
      processChainName: new Set(),
      materialNumber: new Set(),
      source: new Set(),
      destination: new Set(),
      externalSystemName: new Set(),
      externalSystemOrderId: new Set(),
    };
    orders.forEach(o => {
      orderFilterSets.orderStatus.add(o.orderStatus);
      orderFilterSets.processChainName.add(o.processChainName);
      orderFilterSets.materialNumber.add(o.materialNumber);
      orderFilterSets.source.add(o.source);
      orderFilterSets.destination.add(o.destination);
      orderFilterSets.externalSystemName.add(o.externalSystemName);
      orderFilterSets.externalSystemOrderId.add(o.externalSystemOrderId);
    });

    const orderFilter = {};

    Object.keys(orderFilterSets).forEach(key => {
      orderFilter[key] = Array.from(orderFilterSets[key]);
    });

    return orderFilter;
  }
);

export const selectActiveOrderFilter = createSelector(
  selectOrderState,
  fromOrder.getActiveOrderFilter
);

export const selectHistoryOrderFilter = createSelector(
  selectOrderState,
  fromOrder.getHistoryOrderFilter
);
export const selectHistoryOrderFilterOptions = createSelector(
  selectOrderState,
  fromOrder.getHistoryOrderFilterOptions
);

function mapFilterValues(filter: OrderFilter, key: string): string[] {
  return filter[key]?.map((val: string) => (val === NO_VALUE_DEFAULT ? 'null' : `'${val}'`)) ?? [];
}

export const selectHistoryOrderFilterODataQuery = createSelector(
  selectHistoryOrderFilter,
  filter => {
    let query = '';
    for (const key in filter) {
      if (key && filter[key]?.length > 0) {
        if (query.length > 0) {
          query += ' and ';
        }
        if (key === 'createdDateTimeInterval' && filter.createdDateTimeInterval) {
          query += `createdDateTime ge ${new Date(
            filter.createdDateTimeInterval[0]
          ).toISOString()} and createdDateTime le ${new Date(
            filter.createdDateTimeInterval[1]
          ).toISOString()}`;
        } else if (key === 'externalSystemOrderId') {
          const values = mapFilterValues(filter, key);
          query += `contains(${key}, ${values})`;
        } else {
          const values = mapFilterValues(filter, key);
          query += `${key} in (${values})`;
        }
      }
    }

    return query;
  }
);

export const selectFilteredActiveOrders = createSelector(
  selectAllOrders,
  selectActiveOrderFilter,
  (allOrders, activeFilter) => {
    let filteredOrders: OrderDto[] = allOrders;

    for (const key in activeFilter) {
      if (key && activeFilter[key]?.length > 0) {
        if (key === 'createdDateTimeInterval') {
          filteredOrders = filteredOrders.filter(
            order =>
              order.createdDateTime &&
              activeFilter.createdDateTimeInterval &&
              Date.parse(order.createdDateTime.toString()) >=
                Date.parse(activeFilter.createdDateTimeInterval[0]) &&
              Date.parse(order.createdDateTime.toString()) <=
                Date.parse(activeFilter.createdDateTimeInterval[1])
          );
        } else {
          filteredOrders = filteredOrders.filter(order => {
            const value = order[key];
            return (
              (value !== undefined && activeFilter[key]?.includes(value)) ||
              (value === null && activeFilter[key]?.includes(NO_VALUE_DEFAULT))
            );
          });
        }
      }
    }
    return filteredOrders;
  }
);

export const selectFailedOrdersCount = createSelector(
  selectFilteredActiveOrders,
  filteredActiveOrders => {
    return filteredActiveOrders.filter(o => o.orderStatus === OrderStatus.Failed).length;
  }
);
