export function removeObsoleteDecimalPlaces(value: number): number {
  const similarityThresh = 0.00001;

  const threeDecimalPlaces = 1000;
  const roundedValue =
    Math.round((value + Number.EPSILON) * threeDecimalPlaces) / threeDecimalPlaces;
  if (Math.abs(roundedValue - value) < similarityThresh) {
    return roundedValue;
  } else return value;
}

export function round(num: number, precision = 2): number {
  return Number(Number(num).toFixed(precision));
}
