<div class="container button-container" style="width: 100%">
  <div class="row ms-2x bold-labels">
    {{ 'userRolesAndPermissions.pageHeading' | translate }}
  </div>

  <app-edit-toggle *ngIf="editEnabled" class="editToggle" style="padding: 10px"></app-edit-toggle>
</div>
<div style="height: 100%; display: flex; gap: 10px">
  <div class="tree-view-items-container">
    <ds-tree data-cy="tree-leaf" class="org-tree">
      <ds-tree-item
        data-cy="nodeEnvironment"
        *ngFor="let environment of menuItems"
        id="{{ environment.id + '1' }}"
        label="{{ environment.label }}"
        [isOpen]="environment.isOpen"
        [icon]="environment.icon"
        (click)="handleItemSelect(environment); $event.stopPropagation()">
        <ds-tree-item
          aria-label="{{ org.label }}"
          *ngFor="let org of environment.children"
          label="{{ org.label }}"
          [isOpen]="org.isOpen"
          id="{{ org.id + '1' }}"
          [icon]="org.icon"
          (click)="handleItemSelect(org); $event.stopPropagation()">
          <ds-tree-item
            *ngFor="let wa of org.children"
            aria-label="{{ wa.label }}"
            label="{{ wa.label }}"
            id="{{ wa.id + '1' }}"
            [icon]="wa.icon"
            (click)="handleItemSelect(wa); $event.stopPropagation()">
          </ds-tree-item>
        </ds-tree-item>
      </ds-tree-item>
    </ds-tree>
  </div>
  <app-organization-chart-table
    style="width: 70%; height: 300px"
    [userDetailsData]="userDetailsData"
    [organizationList]="organizationList"
    [currentUserRoles]="currentUserRoles"
    [allRoles]="allRoles"
    [envUsers]="envUsers"
    [selectedNode]="selectedNode"
    [isVisible]="menuItems.length > 0"
    (selectedRole)="getSelectedRole($event)"
    (refreshOrganogram)="refreshOrganogram()"></app-organization-chart-table>
</div>
