/* eslint-disable @typescript-eslint/no-magic-numbers */
import { VehicleColors } from 'library/styles';
import { UnderrideDimensions } from '../vehicle-dimensions.model';
import { UnderrideMapItem } from '../vehicle-types/underride.graphic';

const dimensions: UnderrideDimensions = {
  length: 140,
  width: 60,
  origin: 70,
  load: {
    width: 70,
    length: 110,
  },
  loadPosition: 50,
};

export class GenericMapItem extends UnderrideMapItem {
  protected getDimensions(): UnderrideDimensions {
    return dimensions;
  }

  protected getDefaultVehicleColor(): number {
    return VehicleColors.GenericType;
  }
}
