/* eslint-disable max-lines */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { EvacuationDeviceDto, OpcuaDeviceResponseModel, WorkingAreaDto } from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { AtsActions, EvacuationDeviceItem, EvacuationDevices } from 'core/models';
import { PermissionService } from 'core/services';

@Component({
  selector: 'app-wago-evacuation-devices-config-modal',
  templateUrl: './wago-evacuation-devices-config-modal.component.html',
  styleUrls: ['wago-evacuation-devices-config-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WagoEvacuationDevicesConfigModalComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() evacuationDevices: EvacuationDeviceDto[] = [];
  @Input() allOpcuaDevices: OpcuaDeviceResponseModel[] = [];
  @Input() currentWorkingArea: WorkingAreaDto | undefined;
  @Input() isModalOpen = false;

  @Output() readonly confirm = new EventEmitter<EvacuationDevices>();
  @Output() readonly dismissModal = new EventEmitter<void>();

  @Output() readonly selectOpcuaDevice = new EventEmitter<OpcuaDeviceResponseModel>();

  @ViewChild('scrollFrame', { static: false })
  scrollFrame!: ElementRef;
  readonly SCROLL_STEP_UNIT = 300;

  activeTabId: string | undefined;
  isLoading = true;
  viewModel: EvacuationDevices = new EvacuationDevices([]);
  baseViewModel: EvacuationDeviceItem[] = [];

  selectedEvacuationDeviceItem: EvacuationDeviceItem | undefined;

  defaultDevicesList: OpcuaDeviceResponseModel[] = [];
  availableDevicesList: OpcuaDeviceResponseModel[] = [];

  device: OpcuaDeviceResponseModel | undefined;

  scrollContainer!: HTMLElement;

  hasEvacuationDeviceUpdatePermission = false;

  constructor(private readonly permissionService: PermissionService) {
    this.hasEvacuationDeviceUpdatePermission = this.permissionService.actionAllowed(
      AtsActions.Evacuation_Device_Update
    );
  }

  ngOnChanges({ allOpcuaDevices }: TypedChanges<WagoEvacuationDevicesConfigModalComponent>): void {
    if (allOpcuaDevices && allOpcuaDevices.currentValue) {
      this.defaultDevicesList = allOpcuaDevices.currentValue;
      this.createDeviceList(allOpcuaDevices.currentValue);
    }
  }

  ngOnInit(): void {
    this.isModalOpenChange();
  }

  ngAfterViewInit(): void {
    this.scrollContainer = this.scrollFrame.nativeElement;
  }

  scrollLeft(): void {
    const scrollLeft = this.scrollContainer.scrollLeft;
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: scrollLeft - this.SCROLL_STEP_UNIT,
      behavior: 'smooth',
    });
  }

  scrollRight(): void {
    const scrollLeft = this.scrollContainer.scrollLeft;
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: scrollLeft + this.SCROLL_STEP_UNIT,
      behavior: 'smooth',
    });
  }

  createDeviceList(list: OpcuaDeviceResponseModel[]): void {
    if (this.defaultDevicesList.length === 0) {
      this.defaultDevicesList = objectHelper.cloneDeep(list) || this.defaultDevicesList;
    }

    this.device = objectHelper.cloneDeep(
      list.filter(item => item.name === this.selectedEvacuationDeviceItem?.device)[0]
    );
  }

  onChangeDevice(selectedDevice: string): void {
    if (
      selectedDevice &&
      this.viewModel.devices.filter(i => i.device === selectedDevice).length === 0
    ) {
      const newItem = new EvacuationDeviceItem(
        selectedDevice,
        this.currentWorkingArea?.id,
        [],
        [],
        false,
        ''
      );
      this.viewModel.addDevice(newItem);
      this.deleteFromDeviceList(selectedDevice);
      this.selectDevice(newItem);
    }
  }

  deleteFromDeviceList(deviceName: string): void {
    const index = this.availableDevicesList.findIndex(i => i.name === deviceName);
    if (index !== -1) {
      this.availableDevicesList.splice(index, 1);
      this.availableDevicesList = objectHelper.cloneDeep(this.availableDevicesList);
    }
  }

  addToDeviceList(deviceName: string): void {
    const opcuaDevice = this.defaultDevicesList.filter(i => i.name === deviceName)[0];
    this.availableDevicesList.push(opcuaDevice);
    this.availableDevicesList = objectHelper.cloneDeep(this.availableDevicesList);
  }

  selectDevice(selectedItem: EvacuationDeviceItem): void {
    this.selectedEvacuationDeviceItem = selectedItem;
    this.device = objectHelper.cloneDeep(
      this.allOpcuaDevices.find(d => d.name === selectedItem.device)
    );
    if (this.device) {
      this.selectOpcuaDevice.emit(this.device);
    }
  }

  removeItem(deleteItem: EvacuationDeviceItem): void {
    this.viewModel.removeDevice(deleteItem);
    this.addToDeviceList(deleteItem.device);
    if (deleteItem.device === this.selectedEvacuationDeviceItem?.device) {
      this.selectFirstItem();
    }
    if (this.viewModel.devices.length <= 2) {
      this.scrollContainer.scroll({
        top: this.scrollContainer.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  onClose(): void {
    this.dismissModal.emit();
  }

  isModalOpenChange(): void {
    this.activeTabId = 'wagoTriggers';
    if (this.isModalOpen) {
      this.baseViewModel = [];
      this.availableDevicesList = objectHelper.cloneDeep(this.defaultDevicesList);
      this.createDeviceList(this.defaultDevicesList);
      this.createViewModel(this.evacuationDevices);
    }
  }

  onConfirm(): void {
    this.confirm.emit(this.viewModel);
  }

  createViewModel(devices: EvacuationDeviceDto[]): void {
    devices.forEach(i => this.baseViewModel.push(new EvacuationDeviceItem().convertDtoItem(i)));

    devices.forEach(i => {
      this.deleteFromDeviceList(i.device);
    });
    this.viewModel = new EvacuationDevices(this.baseViewModel);
    this.selectFirstItem();
  }

  selectFirstItem(): void {
    if (this.viewModel.devices.length > 0) {
      this.selectDevice(this.viewModel.devices[0]);
    } else {
      this.selectedEvacuationDeviceItem = undefined;
    }
  }
}
