import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { EMPTY_GUID } from 'core/constants/constant';
import { NodeDto, RouteConfigurationDto } from 'core/dtos';
import { GuidString, HardwareVersion, VehicleType } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { TuggerTrainSendStepInputModel } from '../vehicle-actions/vehicle-actions.models';
import { BaseSendStepDirective } from '../vehicle-send-steps/base-send-step.directive';
import { VehicleSendStepModel } from '../vehicle-send-steps/vehicle-send-steps.viewmodel';

export type StepsDialogResult =
  | { confirm: false }
  | { confirm: true; steps: VehicleSendStepModel[] };

@Component({
  selector: 'app-steps-dialog',
  templateUrl: './steps-dialog.component.html',
  styleUrls: ['./steps-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsDialogComponent extends BaseSendStepDirective implements OnInit, OnChanges {
  @Input() mapId?: GuidString = EMPTY_GUID;
  @Input() vehicleName = '';
  @Input() isModalOpen = false;
  @Input() vehicleType: VehicleType = VehicleType.UnitLoad;
  @Input() hardwareVersion?: HardwareVersion;
  @Input() nodes: NodeDto[] = [];
  @Input() allRouteConfigurations: RouteConfigurationDto[] = [];

  sendStepText = '';
  VehicleType = VehicleType;

  @Output() readonly sendSteps = new EventEmitter<VehicleSendStepModel[]>();
  @Output() readonly sendNodeSteps = new EventEmitter<TuggerTrainSendStepInputModel>();
  @Output() readonly dismissModal = new EventEmitter<void>();

  constructor(
    protected readonly formBuilder: UntypedFormBuilder,
    protected readonly translate: AtsTranslationService,
    protected readonly cdRef: ChangeDetectorRef
  ) {
    super(formBuilder, translate, cdRef);
  }
  ngOnChanges(): void {
    this.assignValues();
  }

  ngOnInit(): void {
    this.stepItems = this.createStepMenuItems(this.vehicleType);
    this.waypointItems = this.createWaypointMenuItems(this.vehicleType);
  }

  assignValues(): void {
    this.pois = this.pois.filter(x => x.mapId === this.mapId);
    this.prepareDropDownOptions();
    this.sendStepText = this.translate.get('shared.vehicles.sendStep.sendStepText1', {
      name: this.vehicleName,
    });
  }

  onSendSteps(): void {
    if (this.vehicleType === VehicleType.UnitLoad)
      this.sendSteps.emit(this.createVehicleSendStepArray());
    else if (
      (this.vehicleType === VehicleType.TuggerTrain &&
        this.hardwareVersion === HardwareVersion.TuggerTrainDsV1) ||
      this.vehicleType === VehicleType.U_AGV
    ) {
      this.sendNodeSteps.emit(this.createTuggerTrainSendStepArray());
    }
  }

  onCancelButton(): void {
    this.dismissModal.emit();
  }
}
