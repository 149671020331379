import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { PoiGroupDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, PoiGroupStrategy } from 'core/models';
import { Observable, firstValueFrom } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class PoiGroupsService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.POIGroups;

  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getPoiGroupsByMapIds(mapIds: GuidString[]): Observable<PoiGroupDto[]> {
    return this.post<PoiGroupDto[]>(`${this.servicePath}/maps`, mapIds);
  }

  createPoiGroup(poiGroup: PoiGroupDto): Observable<PoiGroupDto> {
    if (poiGroup.strategy === PoiGroupStrategy.PoiMonitoring) {
      return this.post<PoiGroupDto>(`${this.servicePath}/PoiGroupPoiMonitoring`, poiGroup);
    } else {
      return this.post<PoiGroupDto>(`${this.servicePath}/PoiGroupAlternating`, poiGroup);
    }
  }

  updatePoiGroup(poiGroup: PoiGroupDto): Observable<PoiGroupDto> {
    if (poiGroup.strategy === PoiGroupStrategy.PoiMonitoring) {
      return this.put<PoiGroupDto>(`${this.servicePath}/PoiGroupPoiMonitoring`, poiGroup);
    } else {
      return this.put<PoiGroupDto>(`${this.servicePath}/PoiGroupAlternating`, poiGroup);
    }
  }

  deletePoiGroup(poiGroup: PoiGroupDto): Observable<void> {
    return this.delete<void>(`${this.servicePath}/${poiGroup.id}`);
  }

  async deletePoiGroupsByMapId(mapId: GuidString): Promise<PoiGroupDto> {
    return firstValueFrom(this.put<PoiGroupDto>(`${this.servicePath}/ungroup/map/${mapId}`));
  }

  ungroupPoiGroup(poiGroup: PoiGroupDto): Observable<void> {
    return this.put<void>(`${this.servicePath}/ungroup/${poiGroup.id}`);
  }
}
