/* These types exist to augment the code completion with info about the string content. The custom
 * strings can be used as string in many places. Use .toString() elsewhere.
 */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GuidString extends String {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DateString extends String {}

export const MaxDateString = '2100-01-01';
