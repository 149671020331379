import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { ProcessChainDto } from 'core/dtos';
import {
  convertProcessChainDtoToModel,
  convertProcessChainModelToDto,
  mapProcessChainDtosToModels,
} from 'core/helpers';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, OpcUaDeviceReferenceDto, ProcessChain } from 'core/models';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ProcessChainsService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.ProcessChains;

  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getProcessChains(): Observable<ProcessChain[]> {
    return this.get<ProcessChainDto[]>(this.servicePath).pipe(
      map(processChainDtos => mapProcessChainDtosToModels(processChainDtos))
    );
  }

  getProcessChainsByFleetId(fleetId: GuidString): Observable<ProcessChain[]> {
    return this.get<ProcessChainDto[]>(`${this.servicePath}/fleets/${fleetId}`).pipe(
      map(processChainDtos => mapProcessChainDtosToModels(processChainDtos))
    );
  }

  createProcessChain(newProcessChain: ProcessChain): Observable<ProcessChain> {
    const newProcessChainDto = convertProcessChainModelToDto(newProcessChain);
    return this.post<ProcessChainDto>(this.servicePath, newProcessChainDto).pipe(
      map(processChain => convertProcessChainDtoToModel(processChain))
    );
  }

  updateProcessChain(updatedProcessChain: ProcessChain): Observable<ProcessChain> {
    const updatedProcessChainDto = convertProcessChainModelToDto(updatedProcessChain);

    return this.put<ProcessChainDto>(
      `${this.servicePath}/${updatedProcessChainDto.id}`,
      updatedProcessChainDto
    ).pipe(map(processChain => convertProcessChainDtoToModel(processChain)));
  }

  deleteProcessChain(processChainId: GuidString): Observable<GuidString> {
    return this.delete<GuidString>(`${this.servicePath}/${processChainId}`);
  }

  async deleteProcessChainsByMapId(mapId: GuidString): Promise<void> {
    return firstValueFrom(this.delete<void>(`${this.servicePath}/maps/${mapId}`));
  }

  activateProcessChains(processChainIds: GuidString[]): Observable<ProcessChain[]> {
    return this.put<ProcessChainDto[]>(`${this.servicePath}/activate`, processChainIds).pipe(
      map(processChain => mapProcessChainDtosToModels(processChain))
    );
  }

  deactivateProcessChains(processChainIds: GuidString[]): Observable<ProcessChain[]> {
    return this.put<ProcessChainDto[]>(`${this.servicePath}/deactivate`, processChainIds).pipe(
      map(processChain => mapProcessChainDtosToModels(processChain))
    );
  }

  getProcessChainsWithDevice(device: string): Observable<OpcUaDeviceReferenceDto[]> {
    return this.get<OpcUaDeviceReferenceDto[]>(`${this.servicePath}/devices/${device}`);
  }
}
