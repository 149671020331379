import { MapItemBase } from '../../map-item-container';

export enum RouteConfigurationMode {
  View,
  New,
  Edit,
}

export interface RouteConfigMapItem extends MapItemBase {
  setMode(mode: RouteConfigurationMode): void;
}
