import { createSelector, MemoizedSelector } from '@ngrx/store';
import { HardwareVersion, SoftwareUpdateDto } from 'core/models';
import * as fromVehicleFeatureState from '../reducers/index';
import * as fromSoftwareUpdatesReducer from '../reducers/software-update.reducer';

const selectSoftwareUpdateState = createSelector(
  fromVehicleFeatureState.getVehiclesFeatureState,
  fromVehicleFeatureState.getSoftwareUpdatesState
);

export const selectAllSoftwareUpdates = createSelector(
  selectSoftwareUpdateState,
  fromSoftwareUpdatesReducer.getAllSoftwareUpdates
);

export const selectSoftwareUpdatesLoading = createSelector(
  selectSoftwareUpdateState,
  fromSoftwareUpdatesReducer.getLoading
);

export const selectSoftwareUpdatesByHwVersion = (
  hwVersion: HardwareVersion
): MemoizedSelector<fromVehicleFeatureState.VehiclesFeatureState, SoftwareUpdateDto[]> =>
  createSelector(selectAllSoftwareUpdates, softwareUpdates => {
    return softwareUpdates.filter(s => s.hardwareVersion === hwVersion);
  });
