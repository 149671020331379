import { MapVehicle, VehicleDto } from 'core/dtos';
import {
  ConfiguredDsUagvHardwareVersions,
  ConfiguredDsUagvKeys,
  HardwareVersion,
  VehicleConnectionHealth,
  VehicleGroup,
  VehicleGroupByHardwareVersion,
  VehicleGroupsByVehicleType,
  VehicleType,
} from 'core/models';

export class MapVehicleHelper {
  static getVehicleGroupByHardwareVersion(hardwareVersion: HardwareVersion): VehicleGroup {
    return VehicleGroupByHardwareVersion[hardwareVersion] ?? VehicleGroup.Unknown;
  }

  static getVehicleTypeByVehicleGroup(VehicleType: VehicleType): VehicleGroup[] {
    return VehicleGroupsByVehicleType[VehicleType] ?? [VehicleGroup.Unknown];
  }

  static compareHardwareVersionAndVehicleGroup(
    hardwareVersion: HardwareVersion,
    vehicleGroup: VehicleGroup
  ): boolean {
    return vehicleGroup === MapVehicleHelper.getVehicleGroupByHardwareVersion(hardwareVersion);
  }

  static isDSUagvHardwareVersion(
    version: HardwareVersion
  ): version is ConfiguredDsUagvHardwareVersions {
    return ConfiguredDsUagvKeys.includes(version as ConfiguredDsUagvHardwareVersions);
  }

  static hasVehicleFactsheetDimensions(vehicle: MapVehicle | VehicleDto): vehicle is MapVehicle {
    return 'dimensions' in vehicle && vehicle.dimensions !== undefined;
  }

  static determineVehicleConnectionHealth(percentage: number): VehicleConnectionHealth {
    const CRITICAL_THRESHOLD = 80;
    const CAUTION_THRESHOLD = 90;

    if (percentage >= CRITICAL_THRESHOLD && percentage < CAUTION_THRESHOLD) {
      return VehicleConnectionHealth.Cautious;
    }
    if (percentage < CRITICAL_THRESHOLD) {
      return VehicleConnectionHealth.Critical;
    }

    return VehicleConnectionHealth.Default;
  }
}
