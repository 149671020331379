<form [formGroup]="emulatorSettingsForm">
  <ds-box class="mb-4x">
    <ds-box-header>
      <div class="toggle-box-header">
        {{ 'settings.featureToggles.emulatorServiceSection.emulatorService' | translate }}

        <ds-switch data-cy="enableAddingEmulators" formControlName="enableAddingEmulators">{{
          emulatorSettingsForm.controls['enableAddingEmulators'].value
            ? ('settings.featureToggles.on' | translate)
            : ('settings.featureToggles.off' | translate)
        }}</ds-switch>
      </div>
    </ds-box-header>
    <ds-box-content>
      <span>{{
        'settings.featureToggles.emulatorServiceSection.emulatorServiceDescription' | translate
      }}</span>
    </ds-box-content>
  </ds-box>

  <ds-box class="mb-4x">
    <ds-box-header>
      <div class="toggle-box-header">
        {{ 'settings.featureToggles.emulatorServiceSection.enableCompletePath' | translate }}

        <ds-switch data-cy="enableCompletePath" formControlName="enableCompletePath">{{
          emulatorSettingsForm.controls['enableCompletePath'].value
            ? ('settings.featureToggles.on' | translate)
            : ('settings.featureToggles.off' | translate)
        }}</ds-switch>
      </div>
    </ds-box-header>
    <ds-box-content>
      <span>{{
        'settings.featureToggles.emulatorServiceSection.enableCompletePathDescription' | translate
      }}</span>
    </ds-box-content>
  </ds-box>

  <ds-box class="mb-4x">
    <ds-box-header>
      <div class="toggle-box-header">
        {{ 'settings.featureToggles.emulatorServiceSection.enableAwarenessField' | translate }}

        <ds-switch data-cy="emulatorAwareness" formControlName="enableAwarenessField">{{
          emulatorSettingsForm.controls['enableAwarenessField'].value
            ? ('settings.featureToggles.on' | translate)
            : ('settings.featureToggles.off' | translate)
        }}</ds-switch>
      </div>
    </ds-box-header>
    <ds-box-content>
      <table ds-table [(config)]="config" class="emulatorServiceSettingsTable">
        <thead ds-table-head>
          <tr ds-table-row>
            <td>
              {{ 'settings.featureToggles.trafficManagementSection.property' | translate }}
            </td>
            <td>
              {{ 'settings.featureToggles.trafficManagementSection.value' | translate }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr ds-table-row>
            <td>
              <span class="align-sub" data-cy="emulatorServiceMinimumAwarenessDistanceProperty">{{
                'settings.featureToggles.emulatorServiceSection.minimumAwarenessDistance'
                  | translate
              }}</span>
            </td>
            <td>
              <span class="align-sub" data-cy="emulatorServiceMinimumAwarenessDistanceValue">{{
                viewModel.minimumAwarenessDistance
              }}</span>
            </td>
          </tr>
          <tr ds-table-row>
            <td>
              <span
                class="align-sub"
                data-cy="emulatorServiceAwarenessVehicleFrontOffsetProperty"
                >{{
                  'settings.featureToggles.emulatorServiceSection.vehicleFrontOffset' | translate
                }}</span
              >
            </td>
            <td>
              <span class="align-sub" data-cy="emulatorServiceAwarenessVehicleFrontOffsetValue">{{
                viewModel.vehicleFrontOffset
              }}</span>
            </td>
          </tr>
          <tr ds-table-row>
            <td>
              <span class="align-sub" data-cy="emulatorServiceAwarenessVehicleWidthProperty">{{
                'settings.featureToggles.emulatorServiceSection.vehicleWidth' | translate
              }}</span>
            </td>
            <td>
              <span class="align-sub" data-cy="emulatorServiceAwarenessVehicleWidthValue">{{
                viewModel.vehicleWidth
              }}</span>
            </td>
          </tr>
          <tr ds-table-row>
            <td>
              <span
                class="align-sub"
                data-cy="emulatorServiceAwarenessVehicleLoadedExtraWidthProperty"
                >{{
                  'settings.featureToggles.emulatorServiceSection.vehicleLoadedExtraWidth'
                    | translate
                }}</span
              >
            </td>
            <td>
              <span
                class="align-sub"
                data-cy="emulatorServiceAwarenessVehicleLoadedExtraWidthValue"
                >{{ viewModel.vehicleLoadedExtraWidth }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </ds-box-content>
  </ds-box>

  <ds-box class="mb-4x">
    <ds-box-header>
      <div class="toggle-box-header">
        {{ 'settings.featureToggles.emulatorServiceSection.emulatorSpeedFactor' | translate }}
      </div>
    </ds-box-header>
    <ds-box-content style="width: 20%">
      <ds-form-field>
        <input
          ds-input
          type="number"
          data-cy="emulatorSpeedFactor"
          min="1"
          ds-form-validation
          ds-inputnumber
          formControlName="emulatorSpeedFactor" />
        <ds-error *ngIf="emulatorSettingsForm.controls['emulatorSpeedFactor'].hasError('min')">{{
          'settings.featureToggles.emulatorServiceSection.validEmulatorSpeedFactorRange' | translate
        }}</ds-error>
      </ds-form-field>
    </ds-box-content>
  </ds-box>

  <ds-box class="mb-4x">
    <ds-box-header>
      <div class="toggle-box-header">
        {{ 'settings.featureToggles.emulatorServiceSection.activation' | translate }}
      </div>
    </ds-box-header>
    <ds-box-content>
      <span>{{
        'settings.featureToggles.emulatorServiceSection.enableActivatingEmulatorsDescription'
          | translate
      }}</span>
      <div class="mt-2x">
        <div *appAllowAction="'General_View'">
          <button
            [disabled]="!viewModel.enableAddingEmulators"
            data-cy="startEmulatorsButton"
            ds-button
            (click)="onActivateEmulators()">
            {{ 'settings.developerSettings.activateEmulators' | translate }}
          </button>

          <button
            [disabled]="!viewModel.enableAddingEmulators"
            ds-button
            data-cy="stopEmulatorsButton"
            [variant]="'outline'"
            class="ms-2x"
            (click)="onDeactivateEmulators()">
            {{ 'settings.developerSettings.deactivateEmulators' | translate }}
          </button>
        </div>
      </div>
    </ds-box-content>
  </ds-box>
</form>
