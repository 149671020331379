import { createSelector } from '@ngrx/store';
import { TourTriggerType } from 'core/dtos';
import * as fromGraphManagerFeatureState from '../reducers';
import * as fromTourChainConfigurationsReducer from '../reducers/tour-chain-configuration.reducer';

const selectGraphManagerState = createSelector(
  fromGraphManagerFeatureState.getGraphManagerFeatureState,
  fromGraphManagerFeatureState.getTourChainConfigurationsState
);

export const selectTourChainConfigurationsLoading = createSelector(
  selectGraphManagerState,
  fromTourChainConfigurationsReducer.getLoading
);

export const selectTourChainConfigurationsLoaded = createSelector(
  selectGraphManagerState,
  fromTourChainConfigurationsReducer.getLoaded
);
export const selectTourChainId = createSelector(
  selectGraphManagerState,
  fromTourChainConfigurationsReducer.getSelectedTourChainConfigId
);

export const selectAllTourChainConfigurations = createSelector(
  selectGraphManagerState,
  fromTourChainConfigurationsReducer.getTourChainConfigurations
);

export const selectCallOffTourConfigurations = createSelector(
  selectAllTourChainConfigurations,
  tourConfigs => tourConfigs.filter(t => t.triggerType === TourTriggerType.CallOff)
);

export const selectSelectedTourChainConfigDetails = createSelector(
  selectTourChainId,
  selectAllTourChainConfigurations,
  (tourChainConfigId, tourChains) => {
    return tourChains.find(tourChain => tourChain.id === tourChainConfigId);
  }
);
