<app-table
  actionMenu="all"
  data-cy="evacuationTriggersTable"
  [displayedColumns]="displayedColumns"
  [records]="tableContent"
  [menuItems]="menuItems"
  [menuItemsStyleClass]="'small-menu-items'"
  translationContext="settings.functions.wagoConfigDialog.list"
  [emptyTableTemplate]="newRowItemTemplate"
  [newRowItemTemplate]="newRowItemTemplate"
  [itemTemplate]="customTemplate">
  <ng-template #customTemplate let-rowData let-field="field">
    <div *ngIf="field === 'value'">
      <ds-select
        data-cy="nodeDropdownListDropdown"
        [isMultiselect]="false"
        [showClearAll]="false"
        [isFilterable]="false"
        [value]="rowData[field]"
        [disabled]="!hasEvacuationDeviceUpdatePermission"
        [options]="nodeDropdownList[rowData.node + '_' + field] | dsDropdown : 'name' : 'value'"
        (optionSelected)="onChangeTriggerValue($any($event).id)">
      </ds-select>
    </div>
  </ng-template>
  <ng-template #newRowItemTemplate let-columns let-columnLength>
    <td class="newItemHeight" [attr.colspan]="displayedColumns.length">
      <ds-select
        data-cy="toggleOnDeactivation"
        [isMultiselect]="true"
        [showClearAll]="true"
        [isFilterable]="false"
        [variant]="'inline'"
        [disabled]="!hasEvacuationDeviceUpdatePermission"
        [labellingConfig]="labellingConfig"
        [options]="unselectedTelemetryTypeNodes | dsDropdown : 'name' : 'value'"
        (isOpenChange)="onNewRowItemIsOpen($event)"
        (optionSelected)="onNewRowItemValue($event)">
      </ds-select>
    </td>
  </ng-template>
</app-table>
