import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AtsInternalTourDto } from 'core/dtos';
import * as TourActions from '../actions/tour.actions';

export const featureKey = 'tour';

export interface TourState extends EntityState<AtsInternalTourDto> {
  loading: boolean;
  loaded: boolean;
  selectedTourId: string;
  ids: string[];
}

export const tourAdapter: EntityAdapter<AtsInternalTourDto> =
  createEntityAdapter<AtsInternalTourDto>({ selectId: (tour: AtsInternalTourDto) => tour.id });

export const initialState: TourState = tourAdapter.getInitialState({
  loading: false,
  loaded: false,
  ids: [],
  selectedTourId: '',
});

export const orderReducer = createReducer(
  initialState,

  on(TourActions.loadTours, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(TourActions.loadToursSuccess, (state, { tours }) =>
    tourAdapter.setAll(tours, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(TourActions.loadToursFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(TourActions.selectTour, (state, { tourId }) => ({
    ...state,
    selectedTourId: tourId,
  })),
  on(TourActions.signalRAddTour, (state, { tour }) => tourAdapter.addOne(tour, state)),

  on(TourActions.signalRUpdateTour, (state, { tour }) => tourAdapter.upsertOne(tour, state)),
  on(TourActions.removeTour, (state, { tour }) => tourAdapter.removeOne(tour.id, state))
);

export function reducer(state: TourState | undefined, action: Action): TourState {
  return orderReducer(state, action);
}

export const { selectEntities, selectAll } = tourAdapter.getSelectors();

export const getLoaded = (state: TourState): boolean => state.loaded;
export const getLoading = (state: TourState): boolean => state.loading;
export const getEntities = selectEntities;
export const getAllTours = selectAll;

export const getSelectedTourId = (state: TourState): string =>
  (state || initialState).selectedTourId;
