import { TuggerDimensions, VehicleDimensions } from '../vehicle-dimensions.model';
import { TuggerTrainMapItem } from '../vehicle-types/tuggertrain.graphic';

const dimensions: TuggerDimensions = {
  length: 186.7,
  width: 103.8,
  origin: 52,
  trailer: {
    length: 140,
    width: 101,
    barLength: 48,
    origin: 70,
  },
};

export class TuggerTrain4AmMapItem extends TuggerTrainMapItem {
  protected getDimensions(): VehicleDimensions {
    return dimensions;
  }
}
