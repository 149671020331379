/* eslint-disable @typescript-eslint/no-magic-numbers, max-lines */
import { Injectable } from '@angular/core';
import { MapMode } from 'core/models';
import { Viewport } from 'pixi-viewport';
import {
  Application,
  BitmapFont,
  Container,
  InteractionData,
  InteractionEvent,
  IPointData,
  Point,
  settings,
  UPDATE_PRIORITY,
  utils,
} from 'pixi.js';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { normalizeToPositiveValueRadians, removeObsoleteDecimalPlaces } from 'shared/helpers';

import fontCharacterSet from '../../../../assets/global/fontCharacterSet.json';
import { MapFonts } from '../layers';
import { MapContainerDetails, MapLayerView, PointerButton, PointerUpArgs } from '../models';

export const MIN_ZOOM = 0;
export const MAX_ZOOM = 100;
export const MIN_SCALE = 35;

export const MAP_OFFSET_SCALE = 1; // Viewport Resize Factor (Pan off-screen)
export const MIN_SCALE_FACTOR = 1; // Zoom out
const MAX_SCALE_FACTOR = 400; // Zoom In
const DRAG_TIME = 120;

settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

export enum PointerState {
  Up,
  Down,
  Moved,
  Dragged, // Pointer down for more that minimum time
}

export enum TickerCallback {
  Centre,
  DrawNewZone,
}

@Injectable({
  providedIn: 'root',
})
export class StageService {
  private readonly app!: Application;
  private viewport!: Viewport;
  private stage!: Container;
  private resolution = 1;
  private rotationSubscription!: Subscription;
  private lastCursorMoved = new Date();
  private mapContainer: MapContainerDetails | undefined;
  private pointerState = PointerState.Up;
  private pointerDownTime = new Date();
  private mapSizedOnLoad = false;

  private readonly tickerCount = new Map<TickerCallback, boolean>();

  private readonly cursor = new BehaviorSubject<Point>(new Point(0, 0));
  readonly cursor$ = this.cursor.asObservable();

  private readonly rotation = new BehaviorSubject<number>(0);
  readonly rotation$ = this.rotation.asObservable();

  private readonly resolutionSubject = new Subject<number>();
  readonly resolution$ = this.resolutionSubject.asObservable();

  private readonly scale = new BehaviorSubject<number>(0);
  readonly scale$ = this.scale.asObservable();

  private readonly grid = new Subject<string>();
  readonly grid$ = this.grid.asObservable();

  private readonly manageLayerSelection = new Subject<[InteractionEvent, PointerUpArgs]>();
  readonly manageLayerSelection$ = this.manageLayerSelection.asObservable();

  private readonly pointerUp = new Subject<PointerUpArgs>();
  readonly pointerUp$ = this.pointerUp.asObservable(); // Avoid use, prefer using MapCommunicationService.SelectMapPoint

  private readonly pointerDown = new Subject<Point>();
  readonly pointerDown$ = this.pointerDown.asObservable();

  private readonly rightClick = new Subject<Point>();
  readonly rightClick$ = this.rightClick.asObservable();

  get currentRelativeOrientation(): number {
    return normalizeToPositiveValueRadians(-this.stage.rotation);
  }

  get viewScale(): number {
    return 1 / this.viewport.scale.x;
  }

  get view(): HTMLCanvasElement {
    return this.app.view;
  }

  get ready(): boolean {
    return this.created && this.mapContainer !== undefined;
  }

  get created(): boolean {
    return this.app && this.app.stage !== undefined && !this.app.stage.destroyed;
  }

  // #region Create
  constructor() {
    utils.skipHello();

    this.app = new Application({
      antialias: true,
      backgroundAlpha: 0,
      resolution: window.devicePixelRatio,
      autoDensity: true,
    });
  }

  createStage(): void {
    this.app.stage = new Container();

    this.createViewport();
    this.addListeners();
    this.loadFonts();
    this.subscribeToRotation();

    this.app.ticker.start();
  }

  destroyStage(): void {
    this.removeListeners();

    if (this.rotationSubscription) {
      this.rotationSubscription.unsubscribe();
    }

    this.app.ticker.stop();
    this.app.stage.destroy(true);
  }

  private loadFonts(): void {
    const fontFactor = 2;

    Object.values(MapFonts).forEach(f => {
      if (f.fontName && f.fontSize)
        BitmapFont.from(
          f.fontName,
          { ...f, fontSize: window.devicePixelRatio * f.fontSize * fontFactor },
          {
            chars: fontCharacterSet,
          }
        );
    });
  }

  private createViewport(): void {
    this.viewport = new Viewport({
      interaction: this.app.renderer.plugins.interaction,
      divWheel: this.app.view,
    })
      .wheel({ smooth: 5 })
      .drag()
      .pinch()
      .decelerate();

    this.app.stage.addChild(this.viewport);

    this.stage = this.viewport.addChild(new Container());
    this.stage.scale.y = -1; // reverse y-scale
    this.stage.sortableChildren = true;
  }

  setViewport(map: MapContainerDetails): void {
    if (map.isEmpty) {
      throw new Error('Unable to set view-port with empty map-details');
    }

    this.mapContainer = map;
    const maxBounds = Math.max(map.totalWidth, map.totalHeight) * MAP_OFFSET_SCALE;

    this.resolution = map.resolution;
    this.resolutionSubject.next(this.resolution);
    this.stage.position.set(maxBounds / 2, maxBounds / 2);
    this.stage.pivot.set(map.totalWidth / 2, map.totalHeight / 2);

    this.resizeViewport(maxBounds);
  }

  resizeViewport(bounds: number = this.viewport.worldWidth, windowResize = false): void {
    if (!this.mapContainer || this.mapContainer.isEmpty || bounds === 0) {
      return;
    }

    this.viewport.resize(this.viewport.screenWidth, this.viewport.screenHeight, bounds, bounds);
    this.viewport.clampZoom({
      minScale: this.getMinScale(),
      maxScale: this.getMaxScale(),
    });

    if (!windowResize || !this.mapSizedOnLoad) {
      this.viewport
        .fitWorld(true)
        .clampZoom({
          minScale: this.getMinScale(),
          maxScale: this.getMaxScale(),
        })
        .fitWorld(true)
        .fit(true, this.mapContainer.content.width, this.mapContainer.content.height);

      this.setCenter(
        new Point(this.mapContainer.content.centre.x, this.mapContainer.content.centre.y),
        false,
        true
      );
      if (windowResize) this.mapSizedOnLoad = true;
    }
  }

  createLayer(index = 0, view: MapLayerView = new MapLayerView()): Container {
    view.zIndex = index;

    if (view.isOnStage) return view;

    return this.stage.addChild(view);
  }

  addTicker(callbackType: TickerCallback, callback: () => void, context: object): void {
    if (!this.tickerCount.get(callbackType)) {
      this.app.ticker.add(callback, context, UPDATE_PRIORITY.UTILITY);
      this.tickerCount.set(callbackType, true);
    }
  }

  removeTicker(callbackType: TickerCallback, callback: () => void, context: object): void {
    this.app.ticker.remove(callback, context);
    this.tickerCount.set(callbackType, false);
  }
  // #endregion

  // #region Center & Rotation
  setCenter(point: IPointData, zoom = false, isPixels = false): void {
    const coordinateInPixels = isPixels ? point : this.toPixelCoordinate(point.x, point.y);
    this.viewport.toLocal(coordinateInPixels, this.stage, coordinateInPixels);

    if (zoom) {
      this.viewport.animate({
        position: coordinateInPixels,
        time: 300,
        scale: this.toScale(MAX_ZOOM / 8),
        removeOnInterrupt: true,
      });
    } else {
      this.viewport.moveCenter(coordinateInPixels);
    }
  }

  rotate(orientation: number): void {
    const next = normalizeToPositiveValueRadians(orientation);
    this.rotation.next(removeObsoleteDecimalPlaces(next));
  }

  subscribeToRotation(): void {
    this.rotationSubscription = this.rotation$.subscribe(orientation => {
      const { x, y } = this.viewport.center;
      const { x: posX, y: posY } = this.stage.position;
      const { rotation } = this.stage;

      this.stage.rotation = normalizeToPositiveValueRadians(-orientation);
      const rotationDiff = this.stage.rotation - rotation;

      const centerX =
        (x - posX) * Math.cos(rotationDiff) - (y - posY) * Math.sin(rotationDiff) + posX;
      const centerY =
        (x - posX) * Math.sin(rotationDiff) + (y - posY) * Math.cos(rotationDiff) + posY;

      this.viewport.moveCenter(centerX, centerY);
    });
  }

  rotateRight(): void {
    this.rotate(
      normalizeToPositiveValueRadians(-this.stage.rotation - Math.PI / 2) % (Math.PI * 2)
    );
  }
  // #endregion

  setGrid(grid: string): void {
    this.grid.next(grid);
  }

  // #region Distance Calc
  getGlobalPoint(data: InteractionData): Point {
    const point = data.getLocalPosition(this.stage);
    return this.toDistanceCoordinate(point);
  }

  private toDistanceCoordinate(point: Point): Point {
    return point.set(this.calculateDistance(point.x), this.calculateDistance(point.y));
  }

  private toPixelCoordinate(x: number, y: number): Point {
    return new Point(this.calculatePixels(x), this.calculatePixels(y));
  }

  calculatePixels(meters: number): number {
    return meters / this.resolution;
  }

  private calculateDistance(pixels: number): number {
    return pixels * this.resolution;
  }
  // #endregion

  // #region Pointer Events
  pauseMapDrag(): void {
    this.viewport.plugins.pause('drag');
  }

  resumeMapDrag(): void {
    this.viewport.plugins.resume('drag');
  }

  private addListeners(): void {
    this.viewport
      .on('pointerup', this.onPointerUp, this)
      .on('pointerdown', this.onPointerDown, this)
      .on('pointermove', this.onPointerMove, this)
      .on('rightclick', this.onRightClick, this)
      .on('zoomed-end', this.onZoomChange, this)
      .on('drag-start', this.onDragStart, this)
      .on('drag-end', this.onDragEnd, this);
  }

  private removeListeners(): void {
    this.viewport
      .off('pointerup', this.onPointerUp, this)
      .off('pointerdown', this.onPointerDown, this)
      .off('pointermove', this.onPointerMove, this)
      .off('rightclick', this.onRightClick, this)
      .off('zoomed-end', this.onZoomChange, this)
      .off('drag-start', this.onDragStart, this)
      .off('drag-end', this.onDragEnd, this);
  }

  private onPointerMove({ data }: InteractionEvent): void {
    this.setPointer(PointerState.Moved);

    const now = new Date();
    const diff = Math.abs(now.getTime() - this.lastCursorMoved.getTime());
    if (diff < 50) {
      return;
    }
    this.lastCursorMoved = now;

    const point = data.getLocalPosition(this.stage);
    this.cursor.next(this.toDistanceCoordinate(point));
  }

  private onPointerDown(event: InteractionEvent): void {
    if (event.data.button !== PointerButton.Left) {
      return;
    }
    this.setPointer(PointerState.Down);

    const point = event.data.getLocalPosition(this.stage);
    this.pointerDown.next(this.toDistanceCoordinate(point));
  }

  private onPointerUp(event: InteractionEvent): void {
    if (event.data.button !== PointerButton.Left) {
      return;
    }

    if (![PointerState.Down, PointerState.Dragged].includes(this.pointerState)) {
      return; // Pixi ViewPort fires the Up event even if the Down event was not on the viewport
    }
    const dragged = this.setPointer(PointerState.Up);

    const args: PointerUpArgs = { position: this.getGlobalPoint(event.data), dragged };
    this.manageLayerSelection.next([event, args]);
    this.pointerUp.next(args);
  }

  allowMapDrag(allow: boolean): void {
    if (allow) this.resumeMapDrag();
    else this.pauseMapDrag();
  }

  private onDragStart(): void {
    this.app.view.style.cursor = 'move';
  }

  private onDragEnd(): void {
    this.app.view.style.cursor = 'auto';
  }
  // #endregion

  // #region Size & Scale
  onWindowResize(width: number, height: number): void {
    const oldCenter = { ...this.viewport.center };

    this.app.renderer.resize(width, height);
    this.viewport.resize(width, height);
    this.viewport.moveCenter(oldCenter.x, oldCenter.y);

    this.resizeViewport(this.viewport.worldWidth, true);
  }

  private getMinScale(): number {
    return (
      (Math.min(this.viewport.screenWidth, this.viewport.screenHeight) / this.viewport.worldWidth) *
      MIN_SCALE_FACTOR
    );
  }

  private getMaxScale(): number {
    return this.resolution * MAX_SCALE_FACTOR;
  }

  setScale(): void {
    this.scale.next(this.viewport.scale.x);
  }

  setZoom(value: number): void {
    this.viewport.setZoom(this.toScale(value), true);
  }

  onZoomChange(): void {
    this.setScale();
  }

  toZoom(scale: number): number {
    return Math.round(
      ((scale - this.getMinScale()) * (MAX_ZOOM - MIN_ZOOM)) /
        (this.getMaxScale() - this.getMinScale()) +
        MIN_ZOOM
    );
  }

  toScale(zoom: number): number {
    return (
      ((zoom - MIN_ZOOM) * (this.getMaxScale() - this.getMinScale())) / (MAX_ZOOM - MIN_ZOOM) +
      this.getMinScale()
    );
  }
  // #endregion

  // #region Cursor
  setCursor(mode: MapMode | undefined): void {
    if (this.viewport) {
      this.viewport.cursor = (mode ?? MapMode.None) > MapMode.None ? 'crosshair' : 'default';
    }
  }

  private onRightClick(event: InteractionEvent): void {
    this.setPointer(PointerState.Down);
    const point = event.data.getLocalPosition(this.stage);
    this.rightClick.next(this.toDistanceCoordinate(point));
  }

  private setPointer(pointer: PointerState): boolean {
    if (pointer === PointerState.Down) {
      this.pointerDownTime = new Date();
    }

    if (pointer === PointerState.Moved) {
      if (
        this.pointerState === PointerState.Down &&
        Math.abs(new Date().getTime() - this.pointerDownTime.getTime()) > DRAG_TIME
      ) {
        this.pointerState = PointerState.Dragged;
        return true;
      }

      return false;
    }

    if (pointer === PointerState.Up) {
      const dragged = this.pointerState === PointerState.Dragged;
      this.pointerState = PointerState.Up;
      this.pointerDownTime = new Date();
      return dragged;
    }

    this.pointerState = pointer;
    return false;
  }
  // #endregion
}
