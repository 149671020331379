<router-outlet #outlet></router-outlet>
<app-ats-copilot *ngIf="isCopilotVisible"></app-ats-copilot>
<ng-container *ngIf="loadingRouteConfig">
  <div class="landingPageContent">
    <div class="outer-spacer"></div>
    <div class="mid-spacer"></div>
    <div class="inner-spacer"></div>
    <div class="logo"></div>
    <div class="inner-spacer"></div>
    <div class="loader-container">
      <div class="loading-spinner"></div>
    </div>
    <div class="inner-spacer"></div>
    <div class="mid-spacer"></div>
    <img class="group-logo" src="./assets/global/bmw_group_logo.png" alt="Bmw Group logo" />
    <div class="outer-spacer"></div>
  </div>
</ng-container>
