import { EMPTY_GUID } from 'core/constants/constant';
import { ZoneVertices } from '../zone-vertices.graphic';
import { ZoneEditorStyle } from '../zone.constant';
import { DrawGraphicBase } from './draw-graphics-base';
import { DrawPointerGraphic } from './draw-pointer.graphic';

export class DrawZoneGraphic extends DrawGraphicBase {
  protected vertices: ZoneVertices = new ZoneVertices();

  constructor() {
    super();

    this.addChild(this.vertices);
  }

  drawGraphic(pointer: DrawPointerGraphic): void {
    this.clear()
      .lineStyle(ZoneEditorStyle.borderSize, ZoneEditorStyle.borderColor)
      .beginFill(ZoneEditorStyle.fillColor, ZoneEditorStyle.alpha)
      .drawPolygon([...this.points, pointer.position])
      .endFill();

    this.vertices.createVertices(EMPTY_GUID, this.points);
  }

  reset(): void {
    this.clear();
    this.visible = true;
  }
}
