import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromMissionListItem from './mission-list-item.reducer';
import * as fromMissionTrace from './mission-trace.reducer';
import * as fromOrder from './order.reducer';
import * as fromProcessChainGroupTrace from './process-chain-group-trace.reducer';
import * as fromTour from './tour.reducer';

export const featureKey = 'missionMonitoring';

export interface MonitoringFeatureState {
  [fromMissionTrace.featureKey]: fromMissionTrace.MissionTraceState;
  [fromMissionListItem.featureKey]: fromMissionListItem.MissionListItemState;
  [fromOrder.featureKey]: fromOrder.OrderState;
  [fromTour.featureKey]: fromTour.TourState;
  [fromProcessChainGroupTrace.featureKey]: fromProcessChainGroupTrace.ProcessChainGroupTraceState;
}

export function reducers(state: MonitoringFeatureState, action: Action): unknown {
  return combineReducers({
    [fromMissionTrace.featureKey]: fromMissionTrace.reducer,
    [fromMissionListItem.featureKey]: fromMissionListItem.reducer,
    [fromOrder.featureKey]: fromOrder.reducer,
    [fromTour.featureKey]: fromTour.reducer,
    [fromProcessChainGroupTrace.featureKey]: fromProcessChainGroupTrace.reducer,
  })(state, action);
}

export const getMissionTraceState = (
  state: MonitoringFeatureState
): fromMissionTrace.MissionTraceState => {
  return state?.missionTrace ?? fromMissionTrace.initialState;
};
export const getMissionListItemState = (
  state: MonitoringFeatureState
): fromMissionListItem.MissionListItemState => {
  return state?.missionListItem ?? fromMissionListItem.initialState;
};
export const getOrderState = (state: MonitoringFeatureState): fromOrder.OrderState =>
  state?.order ?? fromOrder.initialState;
export const getTourState = (state: MonitoringFeatureState): fromTour.TourState =>
  state?.tour ?? fromTour.initialState;
export const getProcessChainGroupTraceState = (
  state: MonitoringFeatureState
): fromProcessChainGroupTrace.ProcessChainGroupTraceState => {
  return state?.processChainGroupTrace ?? fromProcessChainGroupTrace.initialState;
};
export const getMonitoringFeatureState = createFeatureSelector<MonitoringFeatureState>(featureKey);
