import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TreeModule,
    TreeTableModule,
    MenuModule,
    TableModule,
    CalendarModule,
    ChartModule,
    OrganizationChartModule,
  ],
  exports: [
    TreeModule,
    TreeTableModule,
    MenuModule,
    TableModule,
    CalendarModule,
    ChartModule,
    OrganizationChartModule,
  ],
})
export class PrimeNgModule {}
