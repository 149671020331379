import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CellClickedEvent, ColDef, GetRowIdFunc, GetRowIdParams } from 'ag-grid-community';
import { ExtendedDsMenuItem, UserWithRole } from 'core/models';
import { AtsTranslationService, ToolbarService } from 'core/services';
import { Icons } from 'library/constants';
import { Subject, takeUntil } from 'rxjs';
import { AgGridActionDirective } from 'shared/components';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListComponent
  extends AgGridActionDirective<UserWithRole>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() users: UserWithRole[] = [];
  @Input() searchTerm = '';
  @Input() menuItems: ExtendedDsMenuItem[] = [];

  @Output() readonly selectedItem = new EventEmitter<UserWithRole>();

  readonly columns: ColDef[] = [
    {
      field: 'name',
      headerName: 'settings.users.name',
      flex: 2,
    },
    { field: 'department', headerName: 'settings.users.department' },
    { field: 'email', headerName: 'settings.users.email', flex: 2 },
    { field: 'roleTranslation', headerName: 'settings.users.roleTranslation', flex: 2 },
  ];

  ngUnsubscribe = new Subject<void>();
  translationContext = 'settings.users';
  resetIcon = Icons.Reset;

  constructor(
    readonly atsTranslationService: AtsTranslationService,
    protected readonly toolbarService: ToolbarService,
    protected readonly cdRef: ChangeDetectorRef
  ) {
    super(atsTranslationService, toolbarService, cdRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.atsTranslationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateRole(this.users);
    });
  }

  ngOnChanges({ users }: TypedChanges<UserListComponent>): void {
    if (users?.currentValue) {
      this.translateRole(users.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  translateRole(users: UserWithRole[]): void {
    for (const user of users) {
      user.roleTranslation = this.atsTranslationService.get('settings.roles.list.' + user.role);
    }

    this.users = [...this.users];
    this.cdRef.markForCheck();
  }

  onCellSelected(cell: CellClickedEvent<UserWithRole>): void {
    this.selectedItem.emit(cell.data);
  }

  setGridMenuItems(): void {}

  updateGridMenuItems(_item: UserWithRole): void {}

  getRowIdForChangeDetection: GetRowIdFunc = (params: GetRowIdParams) => params.data.id.toString();

  addActionMenuColumn(): void {
    this.setGridMenuItems();
    this.actionMenuColumn.cellRendererParams = {
      value: this.menuItems,
    };

    this.columns.push(this.actionMenuColumn);

    this.translateHeader();

    this.gridOptions = {
      ...this.gridOptions,
      onCellClicked: row => {
        this.onSelectedAction(row);
      },
    };
  }

  onSelectedAction(event: CellClickedEvent): void {
    if (event.colDef.colId === this.actionMenuColumn.colId) {
      this.updateGridMenuItems(event.data);
      this.gridApi.refreshCells({ force: true });
      this.onCellSelected(event);
    }

    this.cdRef.markForCheck();
  }
}
