import { DateString, GuidString, MissionLogSeverity } from 'core/models';

export interface TourLogDto {
  tourId: GuidString;
  message: string;
  severity: MissionLogSeverity;
  source: string;
  loggedDateTime: DateString;
  tourLogId: GuidString;
}

export enum ExecutionType {
  Mission = 0,
  Tour = 1,
}
