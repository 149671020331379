import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SoftwareUpdateVersionStatus } from 'core/models';

export interface SoftwareVersionStatusCellParameters {
  value: SoftwareUpdateVersionStatus;
}

@Component({
  selector: 'app-software-version-status-cell',
  templateUrl: './software-version-status-cell.component.html',
  styleUrls: ['./software-version-status-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftwareVersionStatusCellComponent implements ICellRendererAngularComp {
  status: SoftwareUpdateVersionStatus | undefined;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  agInit(params: SoftwareVersionStatusCellParameters): void {
    this.status = params.value;
    this.cdRef.detectChanges();
  }

  refresh(params: SoftwareVersionStatusCellParameters): boolean {
    this.status = params.value;
    this.cdRef.detectChanges();
    return true;
  }
}
