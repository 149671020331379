import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { AtsInternalTourDto, ODataDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class SapInternalTourService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.InternalTours;

  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getInternalTours(): Observable<AtsInternalTourDto[]> {
    return this.get<AtsInternalTourDto[]>(`${this.servicePath}`);
  }

  getInternalTourByTourId(tourId: string): Observable<AtsInternalTourDto> {
    return this.get<AtsInternalTourDto>(`${this.servicePath}/${tourId}`);
  }

  getInternalToursHistory(params: Params): Observable<ODataDto<AtsInternalTourDto>> {
    return this.get<ODataDto<AtsInternalTourDto>>(`/odata/SapInternalTourHistory`, params);
  }

  cancelTour(tourId: GuidString): Observable<void> {
    return this.post<void>(`${this.servicePath}/cancel/${tourId}`);
  }

  retryTour(tourId: GuidString): Observable<void> {
    return this.post<void>(`${this.servicePath}/retry/${tourId}`);
  }
}
