import { Injectable } from '@angular/core';
import { DsToastModel, DsToastService } from '@bmw-ds/components';
import { ToastSeverityEnum } from 'core/models';
import { AtsTranslationService } from 'core/services/ats-translation.service';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  closedMessages$ = new ReplaySubject<DsToastModel>(1);

  constructor(
    private readonly dsToastService: DsToastService,
    private readonly atsTranslateService: AtsTranslationService
  ) {}

  createSuccessToast(input: string, translationParams: object = {}): void {
    const message = this.atsTranslateService.get(input, translationParams);
    this.sendToastContent({
      id: message,
      toastText: message,
      tone: ToastSeverityEnum.Success,
    });
  }

  createCustomToast(
    translationKey: string,
    translationParams: object = {},
    toastParams: DsToastModel
  ): void {
    const message = this.atsTranslateService.get(translationKey, translationParams);
    this.sendToastContent({
      ...toastParams,
      toastText: message,
    });
  }

  createErrorToast(translationKey: string, params: object = {}): void {
    const message = this.atsTranslateService.get(translationKey, params);
    this.sendToastContent({
      id: message,
      toastText: message,
      tone: ToastSeverityEnum.Error,
    });
  }

  createInfoToast(translationKey: string, translationParams: object = {}): void {
    const message = this.atsTranslateService.get(translationKey, translationParams);
    this.sendToastContent({
      id: message,
      toastText: message,
      tone: ToastSeverityEnum.Information,
    });
  }

  createWarningToast(translationKey: string, translationParams: object = {}): void {
    const message = this.atsTranslateService.get(translationKey, translationParams);
    this.sendToastContent({
      id: message,
      toastText: message,
      tone: ToastSeverityEnum.Warning,
    });
  }

  private sendToastContent(content: DsToastModel): void {
    this.dsToastService.pushToast(content);
  }

  onClose(message: DsToastModel): void {
    this.closedMessages$.next(message);
  }

  clear(toastId: string): void {
    this.dsToastService.popToast(toastId);
  }
}
