import { createAction, props } from '@ngrx/store';
import { WorkingAreaDto } from 'core/dtos';
import { GuidString, WorkingAreaInputModel } from 'core/models';

export const loadWorkingAreasSuccess = createAction(
  '[Home] Load Working Areas Success',
  props<{ workingAreas: WorkingAreaDto[] }>()
);

export const loadWorkingAreasFailure = createAction(
  '[Home] Load Working Areas Failure',
  props<{ errorMessage: string }>()
);

export const addWorkingArea = createAction(
  '[Home] Add Working Area',
  props<{ input: WorkingAreaInputModel }>()
);

export const addWorkingAreaSuccess = createAction(
  '[Home] Add Working Area Success',
  props<{ workingArea: WorkingAreaDto }>()
);

export const addWorkingAreaFailure = createAction(
  '[Home] Add Working Area Failure',
  props<{ errorMessage: string }>()
);

export const updateWorkingArea = createAction(
  '[Home] Update Working Area',
  props<{ oldWorkingAreaName: string; input: WorkingAreaInputModel }>()
);

export const updateWorkingAreaSuccess = createAction(
  '[Home] Update Working Area Success',
  props<{ workingArea: WorkingAreaDto }>()
);

export const updateWorkingAreaFailure = createAction(
  '[Home] Update Working Area Failure',
  props<{ errorMessage: string }>()
);

export const selectWorkingArea = createAction(
  '[Home] Select Working Area',
  props<{ workingAreaId: GuidString }>()
);

export const deleteWorkingArea = createAction(
  '[Home] Delete Working Area',
  props<{ input: WorkingAreaInputModel }>()
);

export const deleteWorkingAreaFailure = createAction(
  '[Home] Delete Working Area Failure',
  props<{ errorMessage: string }>()
);

export const deleteWorkingAreaSuccess = createAction(
  '[Home] Delete Working Area Success',
  props<{ workingArea: WorkingAreaDto }>()
);

export const changeWorkingArea = createAction('[Home] Change Working Area');
