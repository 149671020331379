import { Injectable } from '@angular/core';
import { SignalRNextMessage } from 'core/models';
import { SessionService } from 'core/services/session.service';
import { Subject } from 'rxjs';

import { SignalRNextService } from '../signalr-next.service';
import { SignalrRoutes } from '../signalr-routes';

export interface RolesSignalrSubscriber {
  joinUserRoles(): Promise<void>;
}
@Injectable({
  providedIn: 'root',
})
export class RolesSignalRService {
  rolesMessageReceived = new Subject<SignalRNextMessage<number>>();

  constructor(
    private readonly signalrNextService: SignalRNextService,
    private readonly sessionService: SessionService
  ) {
    this.registerConnections();
  }

  signalrSubscriberFactory(componentName: string): RolesSignalrSubscriber {
    const joinUserRoles = (): Promise<void> => {
      return this.signalrNextService.joinGroup(SignalrRoutes.Roles, componentName);
    };

    return { joinUserRoles };
  }

  protected registerConnections(): void {
    this.signalrNextService.registerConnectionNext(SignalrRoutes.Roles, this.rolesMessageReceived);

    this.rolesMessageReceived.subscribe(() => {
      const forceRefresh = true;
      void this.sessionService.getUserInfo(forceRefresh);
    });
  }
}
