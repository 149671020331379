import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { ODataDto, OrganizationDto, WorkingAreaDto } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { WorkingAreaInputModel } from 'core/models';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkingAreasService extends BaseHttpClient {
  protected apiUrl = environment.Services.Auth;
  private readonly oDataUrl = '/odata/Organizations?$expand=workAreas';
  private readonly servicePath = API_SERVICES.Organization;

  getWorkingAreas(orgName: string): Observable<WorkingAreaDto[]> {
    return this.get<ODataDto<OrganizationDto>>(this.oDataUrl, {
      $select: 'workAreas',
      $filter: `name eq '${orgName}'`,
    }).pipe(switchMap(response => response.value.map(org => org.workAreas)));
  }

  getCreatedWorkingArea({
    orgName,
    workingArea,
  }: WorkingAreaInputModel): Observable<WorkingAreaDto> {
    const workAreaUrl = `($filter=name eq '${workingArea.name}')`;
    return this.get<ODataDto<OrganizationDto>>(`${this.oDataUrl}${workAreaUrl}`, {
      $select: 'workAreas',
      $filter: `name eq '${orgName}'`,
    }).pipe(switchMap(response => response.value.map(org => org.workAreas)[0]));
  }

  createWorkingArea({ workingArea, orgName }: WorkingAreaInputModel): Observable<WorkingAreaDto> {
    return this.post<WorkingAreaDto>(`${this.servicePath}/${orgName}/workArea`, workingArea);
  }

  updateWorkingArea(
    oldWorkingAreaName: string,
    { workingArea, orgName }: WorkingAreaInputModel
  ): Observable<WorkingAreaDto> {
    return this.put<WorkingAreaDto>(
      `${this.servicePath}/${orgName}/workArea/${oldWorkingAreaName}`,
      workingArea
    );
  }

  deleteWorkingArea({ orgName, workingArea }: WorkingAreaInputModel): Observable<void> {
    return this.delete<void>(`${this.servicePath}/${orgName}/workArea/${workingArea.name}`);
  }
}
