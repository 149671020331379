/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MapsService } from 'core/api-services';
import { EMPTY_GUID } from 'core/constants';
import { MapMode, VehicleGroup } from 'core/models';
import { ToastService } from 'core/services/toast.service';
import { combineLatest, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { setHasChanges } from 'store/actions/ui.actions';

import { LayersCreateModel } from 'core/dtos';
import * as PoiGroupActions from 'store-modules/pois-store/actions/poi-groups.actions';
import * as GraphRouteActions from '../../graph-manager/actions/route-customization-rule.actions';
import * as GraphLayerActions from '../actions/graph-layer.actions';
import * as MapsActions from '../actions/maps.actions';
import * as NavigationLayerActions from '../actions/navigation-layers.actions';
import * as PillarsGridActions from '../actions/pillars-grid.actions';

@Injectable()
export class MapsEffects {
  loadMaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.loadMaps),
      concatMap(() =>
        this.mapsService.getAllMaps().pipe(
          map(maps => MapsActions.loadMapsSuccess({ maps })),
          catchError(errorMessage => of(MapsActions.loadMapsFailure({ errorMessage })))
        )
      )
    )
  );

  loadMapsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MapsActions.loadMapsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.loadMapsFailure');
        })
      ),
    { dispatch: false }
  );

  loadMapView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.loadMapView),
      concatMap(({ mapId }) => {
        return [
          MapsActions.showMapView(),
          MapsActions.selectMap({ mapId }),
          PoiGroupActions.loadPoiGroupsForMap({ mapId }),
          PillarsGridActions.loadPillarsGridForMap({ mapId }),
          GraphLayerActions.loadGraphLayers({ mapId }),
          GraphRouteActions.loadRouteCustomizationRules(),
        ];
      })
    )
  );

  addMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.signalRCreateMap),
      concatMap(state =>
        this.mapsService
          .getBackgroundImage(state.map.id)
          .pipe(
            map(mapBackgroundImageUrl =>
              MapsActions.addMapSuccess({ map: { ...state.map, mapBackgroundImageUrl } })
            )
          )
      )
    )
  );

  updateSignalRMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.signalRUpdateMap),
      concatMap(state =>
        combineLatest([this.mapsService.getBackgroundImage(state.map.id)]).pipe(
          map(([mapBackgroundImageUrl]) =>
            MapsActions.updateMapSuccess({
              map: { ...state.map, mapBackgroundImageUrl },
            })
          )
        )
      )
    )
  );

  updateMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.updateMap),
      concatMap(state =>
        this.mapsService.updateMapName(state.map).pipe(
          map(() => MapsActions.updateMapSuccess({ map: state.map })),
          catchError(errorMessage => of(MapsActions.updateMapFailure({ errorMessage })))
        )
      )
    )
  );

  createMapContainer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.createMapContainer),
      concatMap(state =>
        this.mapsService.createMap(state.newMap.name).pipe(
          map(map => MapsActions.createMapContainerSuccess({ map: map })),
          catchError(errorMessage => of(MapsActions.createMapContainerFailure({ errorMessage })))
        )
      )
    )
  );

  createMapContainerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.createMapContainerSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.mapContainerActions.created');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  createMapFromImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.createMapFromImage),
      concatMap(state =>
        this.mapsService.createMap(state.newMap.name).pipe(
          concatMap(m => {
            const newLayer: LayersCreateModel = {
              id: EMPTY_GUID,
              mapId: m.id,
              name: state.newMap.navigationLayerName ?? '',
              imgFile: state.newMap.imgFile,
              imageOffset: { x: 0, y: 0, rotation: 0 },
              coordinateSystemOffset: undefined,
              resolution: state.newMap.resolution,
              vehicleGroup: VehicleGroup.Str,
            };
            return [
              MapsActions.createMapSuccess(),
              NavigationLayerActions.createLayer({
                newLayer,
              }),
            ];
          }),
          catchError(errorMessage => of(MapsActions.createMapFailure({ errorMessage })))
        )
      )
    )
  );

  createMapSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.createMapSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.mapsActions.created');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  updateMapSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.updateMapSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.mapsActions.updated');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  deleteMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.deleteMap),
      concatMap(state =>
        this.mapsService.deleteMap(state.map.id).pipe(
          map(() => MapsActions.deleteMapSuccess({ map: state.map })),
          catchError(errorMessage => of(MapsActions.deleteMapFailure({ errorMessage })))
        )
      )
    )
  );

  previewDeleteMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.previewDeleteMap),
      concatMap(state =>
        this.mapsService.previewDeleteMap(state.mapId).pipe(
          map(previewDeleteMapDTO =>
            MapsActions.previewDeleteMapSuccess({
              previewDeleteMapDTO,
            })
          ),
          catchError(errorMessage => of(MapsActions.previewDeleteMapFailure({ errorMessage })))
        )
      )
    )
  );

  deleteMapSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.deleteMapSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.mapsActions.deleted');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MapsActions.updateMapFailure,
          MapsActions.deleteMapFailure,
          MapsActions.createMapFailure,
          MapsActions.createMapContainerFailure,
          MapsActions.deleteMapFailure,
          MapsActions.addBackgroundImageFailure,
          MapsActions.updateBackgroundImageFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  addBackgroundImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.addBackgroundImage),
      concatMap(state =>
        this.mapsService.addBackgroundImage(state.mapId, state.backgroundImage).pipe(
          map(() =>
            MapsActions.addBackgroundImageSuccess({
              mapId: state.mapId,
              backgroundTransparency: state.backgroundImage.transparency,
            })
          ),
          catchError(errorMessage => of(MapsActions.addBackgroundImageFailure({ errorMessage })))
        )
      )
    )
  );

  addBackgroundImageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.addBackgroundImageSuccess),
      concatMap(state =>
        combineLatest([this.mapsService.getBackgroundImage(state.mapId)]).pipe(
          map(([mapBackgroundImageUrl]) =>
            MapsActions.updateBackgroundImageUrlSuccess({
              mapId: state.mapId,
              backgroundUrl: mapBackgroundImageUrl,
            })
          )
        )
      )
    )
  );

  updateBackgroundImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.updateBackgroundImage),
      concatMap(state =>
        this.mapsService.updateBackgroundImage(state.mapId, state.backgroundImage).pipe(
          map(() =>
            MapsActions.updateBackgroundImageSuccess({
              mapId: state.mapId,
              backgroundTransparency: state.backgroundImage.transparency,
            })
          ),
          catchError(errorMessage => of(MapsActions.updateBackgroundImageFailure({ errorMessage })))
        )
      )
    )
  );

  updateBackgroundImageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.updateBackgroundImageSuccess),
      concatMap(state =>
        combineLatest([this.mapsService.getBackgroundImage(state.mapId)]).pipe(
          map(([mapBackgroundImageUrl]) =>
            MapsActions.updateBackgroundImageUrlSuccess({
              mapId: state.mapId,
              backgroundUrl: mapBackgroundImageUrl,
            })
          )
        )
      )
    )
  );

  backgroundImageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapsActions.addBackgroundImageSuccess, MapsActions.updateBackgroundImageSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.backgroundImageActions.updated');
      }),
      concatMap(() => [
        setHasChanges({ hasChanges: false }),
        MapsActions.setMapMode({ mode: MapMode.None }),
      ])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly mapsService: MapsService,
    private readonly toastService: ToastService
  ) {}
}
