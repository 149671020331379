import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { HuLookupMapping } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class HuLookupMappingService extends TenantHttpClient {
  protected apiUrl = environment.Services.OrderGateway;
  private readonly servicePath = API_SERVICES.HuLookupMappings;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getHuLookupMappingForDevice(deviceName: string): Observable<HuLookupMapping[]> {
    return this.get<HuLookupMapping[]>(`${this.servicePath}/${deviceName}`);
  }

  updateHuLookupMappings(deviceName: string, mappings: HuLookupMapping[]): Observable<void> {
    return this.post<void>(`${this.servicePath}/${deviceName}`, mappings);
  }
}
