import { removeZoneEndPoint } from 'core/helpers';
import { Line, arePositionsEqual } from 'core/models';
import { MINIMUM_ZONE_POINTS } from 'modules/maps/constants/map.constant';
import { Graphics, Point } from 'pixi.js';
import {
  absAngleOfLine,
  calculateDistance,
  closestPointOnEdge,
  distancePointToLine,
  getEdgesFromPoints,
  isBetweenTwoPointsOfTheEdge,
  removeConsecutiveDuplicates,
} from 'shared/helpers';
import { ZoneAllowedAngleVariance } from '../zone.constant';
import { DrawPointerGraphic } from './draw-pointer.graphic';

export class DrawGraphicBase extends Graphics {
  private _points: Point[] = [];
  protected edges: Line[] = [];

  get points(): Point[] {
    return this._points;
  }

  protected set points(val: Point[]) {
    this._points = val;
  }

  get firstPoint(): Point {
    return this.points[0];
  }

  get isPolygon(): boolean {
    return this.points.length > 2;
  }

  constructor() {
    super();
  }

  setPoints(points: Point[]): void {
    this.points = removeZoneEndPoint(removeConsecutiveDuplicates(points, (p, c) => p.equals(c)));
    this.setEdges();
  }

  addPoint(point: Point): void {
    this.points.push(point);
  }

  movePoint(index: number, point: Point): void {
    this.points[index] = point;
  }

  removePoint(index: number): void {
    if (this.points.length === MINIMUM_ZONE_POINTS) {
      return;
    }

    this.points.splice(index, 1);
    this.setEdges();
  }

  closePolygon(): void {
    this.addPoint(this.firstPoint);
    this.setEdges();
  }

  addPointOnEdge(point: Point, pointer: DrawPointerGraphic): number | null {
    const closestEdge = this.getClosestEdgeToCursor(point, pointer);

    if (closestEdge) {
      for (let i = 0; i < this.edges.length; i++) {
        if (
          arePositionsEqual(this.edges[i].first, closestEdge.first) &&
          arePositionsEqual(this.edges[i].last, closestEdge.last)
        ) {
          this.points.splice(i, 0, point);
          this.setEdges();
          return i;
        }
      }
    }

    this.setEdges();
    return null;
  }

  getPointIndex(point: Point): number {
    return this.points.findIndex(p => arePositionsEqual(p, point));
  }

  setEdges(): void {
    this.edges = getEdgesFromPoints(this.points);
  }

  getClosestEdgeToCursor(point: Point, pointer: DrawPointerGraphic): Line | undefined {
    let closestEdge;
    let smallest = pointer.distance;

    for (const edge of this.edges) {
      const dist = distancePointToLine(point, edge);

      if (dist < smallest) {
        smallest = dist;
        closestEdge = edge;
      }
    }

    return closestEdge;
  }

  getVertexSnapPoint(point: Point, pointer: DrawPointerGraphic): Point | null {
    let closestPoint = null;
    let smallest = pointer.distance;

    for (const p of this.points) {
      const dist = calculateDistance(point, p);

      if (dist < smallest) {
        smallest = dist;
        closestPoint = p;
      }
    }

    return closestPoint;
  }

  getEdgeSnapPoint(point: Point, pointer: DrawPointerGraphic): Point | null {
    const edge = this.getClosestEdgeToCursor(point, pointer);

    if (edge) {
      const pointOnEdge = closestPointOnEdge(point, edge);

      if (isBetweenTwoPointsOfTheEdge(edge, pointOnEdge)) {
        return new Point(pointOnEdge.x, pointOnEdge.y);
      }
    }
    return null;
  }

  hasHitClosingPoint(point: Point, pointer: DrawPointerGraphic): boolean {
    return this.points?.length > 2 && pointer.hitClosingPoint(point);
  }

  hasValidPolygonArea(): boolean {
    this.setEdges();

    const lineAngles = this.edges.map(absAngleOfLine);
    return Math.max(...lineAngles) - Math.min(...lineAngles) > ZoneAllowedAngleVariance;
  }

  clearEdges(): void {
    this.edges = [];
  }

  clearPoints(): void {
    this.points = [];
  }
}
