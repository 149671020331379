/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AtsCopilotService } from 'core/api-services';
import { pollInferenceStatus } from 'core/dtos';
import { ToastService } from 'core/services';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as AtsCopilotActions from '../actions/ats-copilot.actions';

@Injectable()
export class AtsCopilotEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly atsCopilotService: AtsCopilotService
  ) {}

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtsCopilotActions.sendMessage),
      mergeMap(action =>
        this.atsCopilotService.sendMessage(action.botId, action.message).pipe(
          switchMap(response => [
            AtsCopilotActions.messageSentSuccess({
              botId: action.botId,
              asyncId: response.asyncId,
            }),
            AtsCopilotActions.pollInference({
              botId: action.botId,
              asyncId: response.asyncId,
              sub: action.message.sub,
            }),
          ]),
          catchError(() =>
            of(AtsCopilotActions.messageSentFailure({ errorMessage: 'Error sending message' }))
          )
        )
      )
    )
  );

  pollInference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtsCopilotActions.pollInference),
      switchMap(action =>
        this.atsCopilotService.pollInference(action.botId, action.asyncId, action.sub).pipe(
          map(response => {
            if (response === pollInferenceStatus.ResponseStatus) {
              return AtsCopilotActions.pollInference({
                botId: action.botId,
                asyncId: action.asyncId,
                sub: action.sub,
              });
            } else if (response == null) {
              return AtsCopilotActions.pollInferenceFailure({
                errorMessage: 'No inference result returned',
              });
            } else {
              return AtsCopilotActions.pollInferenceSuccess({ response });
            }
          }),
          catchError(() =>
            of(
              AtsCopilotActions.pollInferenceFailure({ errorMessage: 'Error generating response' })
            )
          )
        )
      )
    )
  );

  handleError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AtsCopilotActions.messageSentFailure, AtsCopilotActions.pollInferenceFailure),
        tap(action => {
          this.toastService.createErrorToast(action.errorMessage);
        })
      ),
    { dispatch: false }
  );
}
