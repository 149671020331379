import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { ZoneSetDto, ZoneSetVehicleAction } from 'core/dtos';
import { sortZoneSetsForDropdown } from 'core/helpers';
import { GuidString, ReducedVehicle, VehicleStatus } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { DateFormatEnum } from 'library/helpers/standard-date-formatter.helper';
import { EnumTranslationPipe } from 'library/pipes/enum-translation.pipe';
import { StandardDateTimePipe } from 'library/pipes/standard-date-time.pipe';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { UpdateZoneSetModel } from '../vehicle-actions/vehicle-actions.models';

@Component({
  selector: 'app-vehicle-send-zone-set',
  templateUrl: './vehicle-send-zone-set.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleSendZoneSetComponent implements OnChanges, OnDestroy {
  @Input() allZoneSets: ZoneSetDto[] = [];
  @Input() label = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() selectedVehicle: ReducedVehicle = {} as ReducedVehicle;

  @Output() readonly updateZoneSet = new EventEmitter<UpdateZoneSetModel>();

  langChangeSubscription: Subscription;
  ngUnsubscribe: Subject<void> = new Subject<void>();

  zoneSets: ZoneSetVehicleAction[] = [];

  isVehicleOnline = false;
  canUpdateZoneSet = true;
  hasValidMap = false;

  constructor(private readonly atsTranslationService: AtsTranslationService) {
    this.langChangeSubscription = this.atsTranslationService.onLangChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.setZoneSets();
      });
  }

  ngOnChanges({ selectedVehicle }: TypedChanges<VehicleSendZoneSetComponent>): void {
    if (selectedVehicle?.currentValue !== selectedVehicle?.previousValue) {
      this.isVehicleOnline = this.checkIsVehicleOnline();
    }
  }

  setZoneSets(): void {
    this.zoneSets = this.getOrderedZoneSets(this.selectedVehicle.mapId);
  }

  checkIsVehicleOnline(): boolean {
    return ![VehicleStatus.NotConnected, VehicleStatus.SwitchedOff].includes(
      this.selectedVehicle.status
    );
  }

  getOrderedZoneSets(mapId: GuidString | undefined): ZoneSetVehicleAction[] {
    const items = (this.allZoneSets || []).filter(zoneSet => zoneSet.mapId === mapId);

    return sortZoneSetsForDropdown(items).map(zoneSet => {
      const { name, status } = this.getTranslatedZoneSet(zoneSet);
      return { id: zoneSet.id, name: `${name} - ${status}` };
    });
  }

  getTranslatedZoneSet(zoneSet: ZoneSetDto): { name: string; status: string } {
    const translatedName =
      new StandardDateTimePipe(this.atsTranslationService)
        .transform(zoneSet.name, DateFormatEnum.MediumDateTimeMillisec)
        ?.toUpperCase() ?? '';

    const translatedStatus =
      new EnumTranslationPipe(this.atsTranslationService)
        .transform(zoneSet.status, 'zoneSetStatus')
        ?.toUpperCase() ?? '';

    return { name: translatedName, status: translatedStatus };
  }

  onUpdateZoneSet(zoneSet: ZoneSetVehicleAction): void {
    this.updateZoneSet.emit({ vehicleId: this.selectedVehicle.id, zoneSetId: zoneSet.id });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
