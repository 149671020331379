<div class="last-changed-date">
  <span> <i class="pi pi-clock"></i>{{ 'settings.functions.lastChange' | translate }}</span>
  <span data-cy="date">
    {{
      dateUpdated
        ? (dateUpdated.toString() | formatDateNow)
        : ('settings.functions.neverUpdated' | translate)
    }}
  </span>
</div>
