import { createSelector } from '@ngrx/store';

import * as fromMapsFeatureState from '../reducers';
import * as fromCollisions from '../reducers/collision-points.reducer';

import { selectMapId } from './maps.selectors';

const selectCollisionsState = createSelector(
  fromMapsFeatureState.getMapsFeatureState,
  fromMapsFeatureState.getCollisionsState
);

export const selectCollisionsLoaded = createSelector(
  selectCollisionsState,
  fromCollisions.getCollisionsLoaded
);

export const selectCollisionsErrorMessage = createSelector(
  selectCollisionsState,
  fromCollisions.getCollisionErrorMessage
);

export const selectCollisionsEntities = createSelector(
  selectCollisionsState,
  fromCollisions.getCollisionEntities
);

export const selectAllCollisions = createSelector(
  selectCollisionsState,
  fromCollisions.getAllCollisions
);

export const selectCollisionsByMapId = createSelector(
  selectAllCollisions,
  selectMapId,
  (collisions, mapId) => collisions.filter(collision => collision.mapId === mapId)
);
