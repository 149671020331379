/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OpcuaDeviceService } from 'core/api-services';
import { TenantRouterService } from 'core/services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions/ui.actions';

import * as OpcuaStreamingServiceActions from '../actions/opcua-streamingservices.actions';

@Injectable()
export class OpcuaStreamingServicesEffects {
  loadStreamingServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaStreamingServiceActions.loadOpcuaStreamingServices),
      concatMap(() =>
        this.opcuaDeviceService.getStreamingServicesWithDevices().pipe(
          map(streamingServices =>
            OpcuaStreamingServiceActions.loadOpcuaStreamingServicesSuccess({
              streamingServices: streamingServices,
            })
          ),
          catchError(errorMessage =>
            of(OpcuaStreamingServiceActions.loadOpcuaStreamingServicesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadStreamingServicesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpcuaStreamingServiceActions.loadOpcuaStreamingServicesFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  createStreamingService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaStreamingServiceActions.createOpcuaStreamingService),
      concatMap(({ streamingService }) =>
        this.opcuaDeviceService.createStreamingService(streamingService).pipe(
          switchMap(() => [
            OpcuaStreamingServiceActions.createOpcuaStreamingServiceSuccess({
              streamingService: streamingService,
            }),
          ]),
          catchError(errorMessage =>
            of(OpcuaStreamingServiceActions.createOpcuaStreamingServiceFailure({ errorMessage }))
          )
        )
      )
    )
  );

  createStreamingServiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaStreamingServiceActions.createOpcuaStreamingServiceSuccess),
      tap(response => {
        this.toastService.createSuccessToast('shared.actions.created', {
          name: response.streamingService.name,
        });
        this.router.navigate(['/devices']).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  createStreamingServiceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpcuaStreamingServiceActions.createOpcuaStreamingServiceFailure),
        tap(({ errorMessage }) => {
          if (errorMessage === '400 - OK') {
            this.toastService.createErrorToast('shared.actions.kafkaConnectionFailed');
          } else {
            this.toastService.createErrorToast(errorMessage);
          }
        })
      ),
    { dispatch: false }
  );

  updateStreamingService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaStreamingServiceActions.updateOpcuaStreamingService),
      concatMap(({ streamingService }) =>
        this.opcuaDeviceService.updateStreamingService(streamingService).pipe(
          map(() =>
            OpcuaStreamingServiceActions.updateOpcuaStreamingServiceSuccess({
              streamingService: streamingService,
            })
          ),
          catchError(errorMessage =>
            of(OpcuaStreamingServiceActions.updateOpcuaStreamingServiceFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateStreamingServiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaStreamingServiceActions.updateOpcuaStreamingServiceSuccess),
      tap(({ streamingService }) => {
        this.toastService.createSuccessToast('shared.actions.updated', streamingService);
        this.router.navigate(['/devices']).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateStreamingServiceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpcuaStreamingServiceActions.updateOpcuaStreamingServiceFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  deleteStreamingService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaStreamingServiceActions.deleteOpcuaStreamingService),
      concatMap(({ name }) =>
        this.opcuaDeviceService.deleteStreamingService(name).pipe(
          switchMap(() => [
            OpcuaStreamingServiceActions.deleteOpcuaStreamingServiceSuccess({ name }),
          ]),
          catchError(errorMessage =>
            of(OpcuaStreamingServiceActions.deleteOpcuaStreamingServiceFailure({ errorMessage }))
          )
        )
      )
    )
  );

  deleteStreamingServiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcuaStreamingServiceActions.deleteOpcuaStreamingServiceSuccess),
      tap(name => {
        this.toastService.createSuccessToast('shared.actions.deleted', name);
        this.router.navigate(['/devices']).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  deleteStreamingServiceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpcuaStreamingServiceActions.deleteOpcuaStreamingServiceFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly opcuaDeviceService: OpcuaDeviceService,
    private readonly toastService: ToastService,
    private readonly router: TenantRouterService
  ) {}
}
