import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateString } from 'core/models';

@Component({
  selector: 'app-last-changed-date',
  templateUrl: './last-changed-date.component.html',
  styleUrls: ['./last-changed-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastChangedDateComponent {
  @Input() dateUpdated?: DateString | null;
}
