import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-labeled-input',
  templateUrl: './labeled-input.component.html',
  styleUrls: ['./labeled-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledInputComponent implements OnChanges {
  @Input() label = '';
  @Input() required = false;
  @Input() icon = '';
  @Input() toolTip = '';

  labelText = '';

  ngOnChanges(): void {
    this.labelText = this.label;

    if (this.required) {
      this.labelText += '*';
    }
  }
}
