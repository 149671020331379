import { Pipe, PipeTransform } from '@angular/core';
import { Colors } from 'library/styles';

@Pipe({
  name: 'formatColor',
  pure: true,
})
export class FormatColorPipe implements PipeTransform {
  transform(value: string | number | undefined, key: string): string {
    return (Colors[key] && Colors[key][value]) || '';
  }
}
