/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ConfiguredDsUagvHardwareVersions, HardwareVersion } from 'core/models';
import { MapVehicleHelper } from 'modules/maps/helpers';
import { BitmapText, Graphics } from 'pixi.js';
import { TagOptions, VehicleGraphicHelper } from '../helpers/vehicle-graphic.helper';
import { UnderrideDimensions } from '../vehicle-dimensions.model';
import { TagScale, UAgvTagStyle } from '../vehicle-layer.constant';
import { UnderrideMapItem } from '../vehicle-types/underride.graphic';

// #region Emulator Dimensions
const dimensions: UnderrideDimensions = {
  length: 235,
  width: 80,
  origin: 100,
  load: {
    width: 50,
    length: 100,
  },
  loadPosition: 50,
};

export const hardwareVersionDimensions: Record<
  ConfiguredDsUagvHardwareVersions,
  UnderrideDimensions
> = {
  [HardwareVersion.DSType1]: {
    ...dimensions,
    length: 235,
    width: 80,
  },
  [HardwareVersion.DSType2]: {
    ...dimensions,
    length: 170,
    width: 90,
  },
  [HardwareVersion.DSType3]: {
    ...dimensions,
    length: 170,
    width: 90,
  },
  [HardwareVersion.DSType4]: {
    ...dimensions,
    length: 162,
    width: 56,
  },
  [HardwareVersion.DSType5]: {
    ...dimensions,
    length: 170,
    width: 132,
  },
};

// #endregion
export class UnderrideDSMapItem extends UnderrideMapItem {
  protected tag: BitmapText | undefined;
  private tagOptions: TagOptions | undefined;

  get configuredHardwareVersion(): ConfiguredDsUagvHardwareVersions {
    return MapVehicleHelper.isDSUagvHardwareVersion(this.vehicle.hardwareVersion)
      ? this.vehicle.hardwareVersion
      : HardwareVersion.DSType1;
  }

  protected getDimensions(): UnderrideDimensions {
    if (MapVehicleHelper.hasVehicleFactsheetDimensions(this.vehicle))
      return { ...dimensions, ...this.vehicle.dimensions };

    return hardwareVersionDimensions[this.configuredHardwareVersion];
  }

  protected createVehicleBase(): Graphics {
    if (!this.tagOptions) {
      const scale = this.getTagScale(this.dimensions);
      this.tagOptions = {
        ...UAgvTagStyle,
        scale,
      };
    }

    this.tag = VehicleGraphicHelper.createTag(this.tagOptions);

    return VehicleGraphicHelper.createVehicleBaseWithTag(
      this.dimensions,
      this.tag,
      this.tagOptions
    );
  }

  protected getTagScale(dimensions: UnderrideDimensions): TagScale {
    if (dimensions.width <= 60) return TagScale.TagScaleExtraSmall;
    if (dimensions.width <= 80) return TagScale.TagScaleSmall;
    if (dimensions.width <= 90) return TagScale.TagScaleMedium;
    if (dimensions.width <= 140) return TagScale.TagScaleLarge;

    return TagScale.TagScaleExtraLarge;
  }

  protected setOtherOrientation(relativeOrientation: number): void {
    if (this.tag) {
      const rad = VehicleGraphicHelper.calculateTagRotation(relativeOrientation);

      if (this.tag.rotation !== rad) this.tag.rotation = rad;
    }
  }
}
