export enum OrderStatus {
  Queued = 'Queued',
  InProgress = 'InProgress',
  Completed = 'Completed',
  CompletedAndReported = 'CompletedAndReported',
  Canceled = 'Canceled',
  CompletedAndReportSkipped = 'CompletedAndReportSkipped',
  Failed = 'Failed',
  AbortedWithoutConfirmation = 'AbortedWithoutConfirmation',
}

export enum OrderDeliveryStatus {
  Overdue = -1,
  DueShortly = 0,
  Due = 1,
  NotUrgent = 2,
  Normal = 3,
}

export enum ODataOrderDeliveryStatus {
  Overdue = 'Overdue',
  DueShortly = 'DueShortly',
  Due = 'Due',
  NotUrgent = 'NotUrgent',
  Normal = 'Normal',
}
