import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import * as fromRoot from '../store';

@Injectable()
export class WorkingAreaResolver implements Resolve<void> {
  constructor(private readonly store: Store<fromRoot.RootState>, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const orgName = decodeURI(route.params.organization);
    const waName = decodeURI(route.params.workingArea);
    this.store
      .pipe(
        select(fromRoot.selectAllOrganizations),
        filter(x => x != null && x.length > 0),
        take(1)
      )
      .subscribe(organizations => {
        const requestedOrganization = organizations.find(org => org.name === orgName);
        const requestedWorkingArea = requestedOrganization?.workAreas?.find(
          wa => wa.name === waName
        );
        if (!requestedOrganization || !requestedWorkingArea) {
          void this.router.navigateByUrl('/wa-not-found');
        } else {
          this.store.dispatch(
            fromRoot.selectOrganization({ organizationId: requestedOrganization.id })
          );
          this.store.dispatch(
            fromRoot.selectWorkingArea({ workingAreaId: requestedWorkingArea.id })
          );
        }
      });
  }
}
