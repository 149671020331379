import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AtsActions, ToolBarControlKeys, ToolBarControls, ToolBarItem } from 'core/models';
import { PermissionService, ToolbarService } from 'core/services';
import { TabViewPanels } from 'library/models';
import { Subject } from 'rxjs';
import * as fromRoot from 'store/index';
@Component({
  selector: 'app-user-roles-and-permissions-container',
  templateUrl: './user-roles-and-permissions-container.component.html',
  styleUrls: ['./user-roles-and-permissions-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRolesAndPermissionsContainerComponent implements OnInit {
  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly permissionService: PermissionService,
    private readonly toolbarService: ToolbarService
  ) {}
  activeTabId = 'usersTab';
  canEdit = false;
  ngUnsubscribe = new Subject<void>();

  TOOLBAR_ITEMS: ToolBarItem[] = [];

  tabs: TabViewPanels[] = [
    {
      id: '0',
      header: 'Users',
    },
    {
      id: '1',
      header: 'Permissions',
    },
  ];

  ngOnInit(): void {
    this.getUserPermissions();
  }

  onSelectionChange(id: string): void {
    this.activeTabId = id;
    if (this.activeTabId === 'usersTab') {
      this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
      this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
    } else {
      this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: this.canEdit }));
      this.buildToolBarItems();
    }
  }

  getUserPermissions(): void {
    this.canEdit = this.permissionService.actionAllowed(AtsActions.Roles_Edit);
  }

  buildToolBarItems(): void {
    this.TOOLBAR_ITEMS = [];
    this.TOOLBAR_ITEMS.push({
      key: ToolBarControlKeys.Search,
      type: ToolBarControls.Search,
    });
    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
  }
}
