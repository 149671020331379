/* eslint-disable sonarjs/no-identical-functions */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ZoneSetDto } from 'core/dtos';
import { GuidString } from 'core/models';

import * as zoneSetActions from '../actions/zone-set.actions';

export const featureKey = 'zoneSets';

export interface ZoneSetsState extends EntityState<ZoneSetDto> {
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
  selectedZoneSetId: GuidString;
  actionStatus: zoneSetActions.ZoneSetTypes | null;
}

// eslint-disable-next-line max-len
export const zoneSetsAdapter: EntityAdapter<ZoneSetDto> = createEntityAdapter<ZoneSetDto>();

export const initialState: ZoneSetsState = zoneSetsAdapter.getInitialState({
  loaded: false,
  loading: false,
  errorMessage: '',
  selectedZoneSetId: '',
  actionStatus: null,
});

const zoneSetsReducer = createReducer(
  initialState,

  on(zoneSetActions.loadZoneSets, zoneSetActions.addZoneSet, state => ({
    ...state,
    loading: true,
    loaded: false,
    errorMessage: '',
  })),

  on(zoneSetActions.loadZoneSetsSuccess, (state, { zoneSets }) =>
    zoneSetsAdapter.setAll(
      zoneSets.map(zoneSet => ({ ...zoneSet })),
      {
        ...state,
        loaded: true,
        loading: false,
      }
    )
  ),

  on(zoneSetActions.addZoneSetSuccess, (state, { zoneSet }) =>
    zoneSetsAdapter.addOne(zoneSet, {
      ...state,
      selectedZoneSetId: zoneSet.id,
      actionStatus: zoneSetActions.ZoneSetTypes.AddZoneSetSuccess,
    })
  ),

  on(zoneSetActions.addZoneSetSuccessAndNavigate, (state, { zoneSet }) =>
    zoneSetsAdapter.addOne(zoneSet, {
      ...state,
      actionStatus: zoneSetActions.ZoneSetTypes.AddZoneSetSuccessAndNavigate,
    })
  ),

  on(zoneSetActions.addZoneSetFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    actionStatus: zoneSetActions.ZoneSetTypes.AddZoneSetFailure,
  })),

  on(zoneSetActions.updateZoneSet, state => ({
    ...state,
    errorMessage: '',
  })),

  on(zoneSetActions.updateZoneSetSuccess, (state, { zoneSet }) =>
    zoneSetsAdapter.updateOne(zoneSet, state)
  ),

  on(zoneSetActions.deleteZoneSet, state => ({
    ...state,
    errorMessage: '',
  })),

  on(zoneSetActions.deleteZoneSetSuccess, (state, { zoneSetId }) =>
    zoneSetsAdapter.removeOne(zoneSetId.toString(), state)
  ),

  on(zoneSetActions.setActiveZoneSet, state => ({
    ...state,
    loading: true,
    loaded: false,
    errorMessage: '',
  })),

  on(zoneSetActions.setActiveZoneSetSuccess, (state, { zoneSets }) =>
    zoneSetsAdapter.upsertMany(
      zoneSets.map(zoneSet => ({ ...zoneSet })),
      {
        ...state,
        loaded: true,
        loading: false,
      }
    )
  ),

  on(
    zoneSetActions.loadZoneSetsFailure,
    zoneSetActions.updateZoneSetFailure,
    zoneSetActions.deleteZoneSetFailure,
    zoneSetActions.setActiveZoneSetFailure,
    (state, { errorMessage }) => ({
      ...state,
      loaded: false,
      loading: false,
      errorMessage,
    })
  ),
  on(zoneSetActions.selectZoneSet, (state, { zoneSetId }) => ({
    ...state,
    selectedZoneSetId: zoneSetId,
  })),

  on(zoneSetActions.signalRCreateZoneSet, (state, { zoneSet }) =>
    zoneSetsAdapter.addOne(zoneSet, state)
  ),

  on(zoneSetActions.signalRUpdateZoneSet, (state, { zoneSet }) =>
    zoneSetsAdapter.updateOne({ id: zoneSet.id.toString(), changes: zoneSet }, state)
  ),

  on(zoneSetActions.signalRDeleteZoneSet, (state, { zoneSet }) =>
    zoneSetsAdapter.removeOne(zoneSet.id.toString(), state)
  )
);

export function reducer(state: ZoneSetsState | undefined, action: Action): ZoneSetsState {
  return zoneSetsReducer(state, action);
}

export const { selectEntities, selectAll } = zoneSetsAdapter.getSelectors();

export const getLoaded = (state: ZoneSetsState): boolean => state.loaded;
export const getLoading = (state: ZoneSetsState): boolean => state.loading;
export const getErrorMessage = (state: ZoneSetsState): string => state.errorMessage;
export const getSelectedZoneSetId = (state: ZoneSetsState): GuidString => state.selectedZoneSetId;

export const getZoneSetEntities = selectEntities;
export const getAllZoneSets = selectAll;
