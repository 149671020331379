import { createAction, props } from '@ngrx/store';
import { OrganizationDto } from 'core/dtos';
import { GuidString } from 'core/models';

export const loadOrganizations = createAction('[Home] Load Organizations');

export const loadOrganizationsSuccess = createAction(
  '[Home] Load Organizations Success',
  props<{ organizations: OrganizationDto[] }>()
);

export const loadOrganizationsFailure = createAction(
  '[Home] Load Organizations Failure',
  props<{ errorMessage: string }>()
);

export const selectOrganization = createAction(
  '[Home] Select Organization',
  props<{ organizationId: GuidString }>()
);

export const addOrganization = createAction(
  '[Home] Add Organization',
  props<{ organization: OrganizationDto }>()
);

export const addOrganizationSuccess = createAction(
  '[Home] Add Organization Success',
  props<{ organization: OrganizationDto }>()
);

export const addOrganizationFailure = createAction(
  '[Home] Add Organization Failure',
  props<{ errorMessage: string }>()
);

export const updateOrganization = createAction(
  '[Home] Update Organization',
  props<{ orgName: string; organization: OrganizationDto }>()
);

export const updateOrganizationSuccess = createAction(
  '[Home] Update Organization Success',
  props<{ orgName: string; organization: OrganizationDto }>()
);

export const updateOrganizationFailure = createAction(
  '[Home] Update Organization Failure',
  props<{ errorMessage: string }>()
);

export const deleteOrganization = createAction(
  '[Home] Delete Organization',
  props<{ organization: OrganizationDto }>()
);

export const deleteOrganizationFailure = createAction(
  '[Home] Delete Organization Failure',
  props<{ errorMessage: string }>()
);

export const deleteOrganizationSuccess = createAction(
  '[Home] Delete Organization Success',
  props<{ organization: OrganizationDto }>()
);
