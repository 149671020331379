import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { LoadTypeConfigurationDto } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class LoadTypeService extends TenantHttpClient {
  protected apiUrl = environment.Services.JobManager;
  private readonly servicePath = API_SERVICES.LoadTypes;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getLoadTypes(): Observable<LoadTypeConfigurationDto[]> {
    return this.get<LoadTypeConfigurationDto[]>(`${this.servicePath}`);
  }

  updateLoadType(loadType: LoadTypeConfigurationDto): Observable<LoadTypeConfigurationDto> {
    return this.put<LoadTypeConfigurationDto>(`${this.servicePath}/`, loadType);
  }
}
