/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-community';
import { AlertNowGroup, WorkAreaSettingRecipientKeyDto } from 'core/dtos';
import { AtsActions, ErrorForwardingModel, IpstWorkingAreaSetting, VehicleType } from 'core/models';
import {
  AtsTranslationService,
  EditBarService,
  PermissionService,
  ToolbarService,
} from 'core/services';
import { Icons } from 'library/constants';
import { ZONE_ALERT_NOW_GUID } from 'modules/settings/constants/settings.constant';
import {
  BaseAgGridTableDirective,
  GridValueFormatters,
  GridValueGetter,
  SwitchNotificationCellComponent,
} from 'shared/components';
import { RecipientKeySelectionCellComponent } from 'shared/components/ag-grid/recipient-key-selection-cell/recipient-key-selection-cell.component';
import * as fromRoot from 'store/index';

export interface DefaultRecipientKeyLabel {
  id: string;
  name: string;
  isDefault: boolean;
}

@Component({
  selector: 'app-error-forwarding-list',
  templateUrl: './error-forwarding-list.component.html',
  styleUrls: ['./error-forwarding-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorForwardingListComponent
  extends BaseAgGridTableDirective
  implements OnInit, OnChanges, OnDestroy
{
  @Input() tableErrorForwarding: ErrorForwardingModel[] = [];
  @Input() searchTerm?: string;
  @Input() workingAreaSetting: IpstWorkingAreaSetting | undefined;

  @Output() readonly saveErrorForwarding = new EventEmitter<ErrorForwardingModel[]>();
  @Output() readonly vehicleTypesUsedInErrorForwarding = new EventEmitter<number[]>();
  @Output() readonly filterVehicleTypesFlag = new EventEmitter<boolean>();

  initialErrorForwardingList: ErrorForwardingModel[] | undefined = [];
  updatedErrorForwardingList: ErrorForwardingModel[] | undefined = [];
  resetIcon = Icons.Reset;
  showVehicleFilter = false;
  filterVehicleTypes = true;
  vehicleTypeFilter: string[] = [];
  vehicleTypeInErrorNotificationList: number[] = [];

  strTranslation = 'settings.errorForwarding.ipstErrorSources.STR';
  forkliftTranslation = 'settings.errorForwarding.ipstErrorSources.Forklift';
  recipientList: WorkAreaSettingRecipientKeyDto[] = [];
  alertNowGroupsList: AlertNowGroup[] = [];
  ipstIsEnabledInWa = false;
  readonly columns: ColDef[] = [
    {
      field: 'type',
      tooltipField: 'type',
      headerName: 'settings.errorForwarding.list.type',
    },
    {
      field: 'level',
      tooltipField: 'level',
      headerName: 'settings.errorForwarding.list.level',
      valueFormatter: GridValueFormatters.capitalizeFormatter,
    },
    {
      field: 'errorSource',
      tooltipField: 'error source',
      headerName: 'settings.errorForwarding.list.source',
      width: 60,
      wrapText: true,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      valueGetter: GridValueGetter.getTranslatedValueGetterFn(
        this.translationService,
        'settings.errorForwarding.ipstErrorSources.'
      ),
    },
    {
      field: 'recipientKeyIds',
      tooltipField: 'recipient Key',
      wrapText: true,
      autoHeight: true,
      flex: 2,
      headerName: 'settings.errorForwarding.list.recipientKey',
      cellEditor: 'agSelectCellEditor',
      cellRenderer: RecipientKeySelectionCellComponent,
      cellEditorParams: {
        formatValue: (value: string) => value.toUpperCase(),
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: (params: ICellRendererParams) => {
          const recipient = params.context.componentParent.recipientList.find(
            (o: WorkAreaSettingRecipientKeyDto) => o.id === params.value
          );
          const zoneAlertNowGroup = params.value === ZONE_ALERT_NOW_GUID;

          if (recipient) {
            return recipient.recipientKey;
          } else if (zoneAlertNowGroup) {
            return this.translationService.get(
              'settings.ipstAlertNowSettings.errorForwardingRecipientKey.zoneAlertNowGroup'
            );
          } else {
            return params.value;
          }
        },
      },
    },
    {
      field: 'description',
      tooltipField: 'description',
      headerName: 'settings.errorForwarding.list.description',
      wrapText: true,
      autoHeight: true,
      flex: 4,
    },
    {
      field: 'sendNotification',
      headerName: 'settings.errorForwarding.list.sendNotification',
      filter: 'agSetColumnFilter',
      filterParams: {
        valueGetter: GridValueFormatters.booleanFormatter(this.translationService, 'switchBoolean'),
      },
      cellRenderer: SwitchNotificationCellComponent,
    },
  ];

  canEdit?: boolean;
  vehicleTypeMapping: Record<VehicleType, string> = {
    [VehicleType.UnitLoad]: '',
    [VehicleType.TuggerTrain]: '',
    [VehicleType.Forklift]: '',
    [VehicleType.U_AGV]: '',
  };
  saveErrorForwardingModels: ErrorForwardingModel[] = [];

  constructor(
    protected readonly translationService: AtsTranslationService,
    protected readonly toolbarService: ToolbarService,
    protected readonly cdRef: ChangeDetectorRef,
    private readonly permissionService: PermissionService,
    private readonly editBarService: EditBarService,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {
    super(translationService, toolbarService, cdRef);
    this.canEdit = this.permissionService.actionAllowed(AtsActions.ToggleSendingErrors);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.editBarService.onSave = this.onSave.bind(this);
  }

  ngOnChanges({
    searchTerm,
    tableErrorForwarding,
    workingAreaSetting,
  }: TypedChanges<ErrorForwardingListComponent>): void {
    if (tableErrorForwarding?.currentValue) {
      this.initialErrorForwardingList = tableErrorForwarding?.currentValue;
      this.updatedErrorForwardingList = JSON.parse(JSON.stringify(this.initialErrorForwardingList));
      this.filterQuickFilterByVehicleType(tableErrorForwarding?.currentValue);
    }

    if (searchTerm?.currentValue && searchTerm?.currentValue !== searchTerm?.previousValue) {
      this.onSearchTermChanged(this.searchTerm);
    }

    if (
      workingAreaSetting &&
      workingAreaSetting.currentValue !== workingAreaSetting.previousValue
    ) {
      const newRecipientList = workingAreaSetting.currentValue?.recipientList;

      if (newRecipientList) {
        this.recipientList = newRecipientList;
      }
      if (workingAreaSetting.currentValue?.enabled) {
        this.ipstIsEnabledInWa = workingAreaSetting.currentValue?.enabled;
      }
    }

    this.gridApi?.redrawRows();
  }

  updateData(data: ErrorForwardingModel) {
    const updatedData = this.updatedErrorForwardingList?.find(f => f.id === data.id);
    if (updatedData) {
      updatedData.recipientKeyIds = data.recipientKeyIds;
      updatedData.sendNotification = data.sendNotification;
      this.handleSaveValidation();
      this.checkForChanges();
    }
  }

  checkForChanges(): void {
    const hasChanges =
      this.updatedErrorForwardingList?.some((updatedData, index) => {
        const initialData = this.initialErrorForwardingList?.[index];
        return (
          initialData?.recipientKeyIds.join() !== updatedData?.recipientKeyIds.join() ||
          initialData?.sendNotification !== updatedData?.sendNotification
        );
      }) ?? false;

    this.editBarService.setHasChanges(hasChanges);
  }

  updateDataToggle(data: ErrorForwardingModel) {
    const updatedData = this.updatedErrorForwardingList?.find(f => f.id === data.id);
    if (updatedData) updatedData.sendNotification = data.sendNotification;
    this.checkForChanges();
  }

  handleSaveValidation() {
    const emptyRecipientKeyListExists =
      this.updatedErrorForwardingList?.some(f => f.recipientKeyIds.length === 0) ?? false;

    this.setSaveButtonValidation(
      !emptyRecipientKeyListExists,
      emptyRecipientKeyListExists
        ? 'settings.ipstAlertNowSettings.errorForwardingRecipientKey.disabledSaveButton'
        : ''
    );
  }

  setSaveButtonValidation(isValid: boolean, message: string): void {
    this.editBarService.setIsValid({
      isValid: isValid,
      message: message,
    });
    this.editBarService.setIsFormValid(isValid);
  }

  filterQuickFilterByVehicleType(errorForwardingModel: ErrorForwardingModel[]): void {
    const errorSources = errorForwardingModel.map(x => x.errorSource);

    const vehicleTypes = new Set<number>();
    for (const error of errorSources) {
      switch (error) {
        case 'STR':
          vehicleTypes.add(VehicleType.UnitLoad);
          break;
        case 'Forklift': // Legacy data
        case 'Forklift4am': // 2023.5 onwards
        case 'Forklift15': // Was only in demo shortly
        case 'Forklift20': // Was only in demo shortly
          vehicleTypes.add(VehicleType.Forklift);
          break;
        case 'TuggerTrain':
        case 'TuggerDS':
        case 'TuggerSchiller':
          vehicleTypes.add(VehicleType.TuggerTrain);
          break;
        default:
          break;
      }
    }

    for (const vehicleType of vehicleTypes) {
      this.vehicleTypeInErrorNotificationList.push(vehicleType);
    }

    this.showVehicleFilter = this.vehicleTypeInErrorNotificationList.length > 1 ? true : false;
    this.cdRef.markForCheck();
    this.vehicleTypesUsedInErrorForwarding.emit(this.vehicleTypeInErrorNotificationList);
    this.filterVehicleTypesFlag.emit(this.filterVehicleTypes);
  }

  onGridReady(params: GridReadyEvent): void {
    super.onGridReady(params);
    this.gridApi.setIsExternalFilterPresent(this.isExternalFilterPresent.bind(this));
    this.gridApi.setDoesExternalFilterPass(this.doesExternalFilterPass.bind(this));
  }

  getRowIdForChangeDetection: GetRowIdFunc<ErrorForwardingModel> = (
    params: GetRowIdParams<ErrorForwardingModel>
  ) => params.data.id.toString();

  isExternalFilterPresent(): boolean {
    return this.vehicleTypeFilter?.length > 0;
  }

  doesExternalFilterPass(node: RowNode<ErrorForwardingModel>): boolean {
    if (node.data) {
      return this.vehicleTypeFilter?.some(filter => node.data?.errorSource.startsWith(filter));
    } else {
      return true;
    }
  }

  selectedVehicleTypesChanged(vehicleTypeFilters: VehicleType[]): void {
    const vehicleTypeMappingEN = {
      [VehicleType.UnitLoad]: this.translationService.get(this.strTranslation),
      [VehicleType.TuggerTrain]: 'Tugger',
      [VehicleType.Forklift]: this.translationService.get(this.forkliftTranslation),
      [VehicleType.U_AGV]: '',
    };

    const vehicleTypeMappingDE = {
      [VehicleType.UnitLoad]: this.translationService.get(this.strTranslation),
      [VehicleType.TuggerTrain]: 'Tugger',
      [VehicleType.Forklift]: 'Forklift',
      [VehicleType.U_AGV]: '',
    };

    this.translationService.currentLang === 'de'
      ? (this.vehicleTypeMapping = vehicleTypeMappingDE)
      : (this.vehicleTypeMapping = vehicleTypeMappingEN);

    this.vehicleTypeFilter = vehicleTypeFilters.map(
      vehicleType => this.vehicleTypeMapping[vehicleType]
    );
    if (this.gridApi) this.gridApi.onFilterChanged();

    if (vehicleTypeFilters.length === 0) {
      this.resetPersistedFilterAndColumnState();
    }
  }

  ngOnDestroy(): void {
    this.editBarService.setIsValid();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getDropdownOptions(rowData: ErrorForwardingModel): WorkAreaSettingRecipientKeyDto[] {
    let options: WorkAreaSettingRecipientKeyDto[] = [];
    const errorSource = rowData.errorSource;
    const forkliftPrefix = this.translationService.get(this.forkliftTranslation);

    if (errorSource.startsWith(forkliftPrefix) || errorSource === 'TuggerDS') {
      options = [...this.recipientList];
    } else {
      switch (errorSource) {
        case this.translationService.get(this.strTranslation):
          options = [...this.recipientList];
          break;
        case this.translationService.get('settings.errorForwarding.ipstErrorSources.ATS'):
          options = [...this.recipientList];
          break;
        case this.translationService.get('settings.errorForwarding.ipstErrorSources.Device'):
          options = [...options];
          break;
      }
    }

    return options;
  }

  mergeChanges(errorForwardingModel: ErrorForwardingModel): void {
    const index = this.saveErrorForwardingModels.findIndex(x => x.id === errorForwardingModel.id);

    if (index > -1) {
      this.saveErrorForwardingModels[index] = {
        ...this.saveErrorForwardingModels[index],
        ...errorForwardingModel,
      };
    } else {
      this.saveErrorForwardingModels.push(errorForwardingModel);
    }

    this.cdRef.markForCheck();
  }

  updateRecipientKey(data: ErrorForwardingModel): void {
    const rowNode = this.gridApi.getRowNode(data.id.toString());
    if (rowNode) {
      this.mergeChanges(data);
    }
  }

  onChangeErrorNotification(changedErrorForwarding: ErrorForwardingModel): void {
    const rowNode = this.gridApi.getRowNode(changedErrorForwarding.id.toString());
    if (rowNode) {
      this.mergeChanges(changedErrorForwarding);
    }
  }

  onSave(): void {
    const updatedRows: ErrorForwardingModel[] = [];
    this.updatedErrorForwardingList?.forEach((data: ErrorForwardingModel) => {
      const initial = this.initialErrorForwardingList?.find(f => f.id === data?.id);
      if (data && initial?.recipientKeyIds.join() !== data?.recipientKeyIds?.join()) {
        updatedRows.push(data);
      }

      if (data && initial?.sendNotification !== data?.sendNotification) updatedRows.push(data);
    });

    updatedRows
      .filter(f => f.errorSource !== 'ATS')
      .forEach(element => {
        const includesZoneAlertGroup = element.recipientKeyIds.includes(ZONE_ALERT_NOW_GUID);
        if (includesZoneAlertGroup) element.zoneAlertNowGroupEnabled = true;
        else element.zoneAlertNowGroupEnabled = false;
      });

    if (updatedRows.length) {
      this.saveErrorForwarding.emit(updatedRows);
      this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
    }
  }
}
