import { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const Go = createAction(
  '[Router] Go',
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);
export const Forward = createAction('[Router] Forward');
export const Back = createAction('[Router] Back');
