import { createAction, props } from '@ngrx/store';
import { HuLookupMapping } from 'core/dtos';

export enum HuLookupMappingTypes {
  LoadHuLookupMappings = '[Devices] Load All Hu Lookup Mappings',
  LoadHuLookupMappingsSuccess = '[Devices] Load All Hu Lookup Mappings Success',
  LoadHuLookupMappingsFailure = '[Devices] Load Hu Lookup Mappings Failure',

  UpdateHuLookupMappings = '[Devices] Update Hu Lookup Mappings',
  UpdateHuLookupMappingsSuccess = '[Devices] Update Hu Lookup Mappings Success',
  UpdateHuLookupMappingsFailure = '[Devices] Update Hu Lookup Mappings Failure',
}

export const loadHuLookupMappings = createAction(
  HuLookupMappingTypes.LoadHuLookupMappings,
  props<{ deviceName: string }>()
);

export const loadHuLookupMappingsSuccess = createAction(
  HuLookupMappingTypes.LoadHuLookupMappingsSuccess,
  props<{ mappings: HuLookupMapping[] }>()
);

export const loadHuLookupMappingsFailure = createAction(
  HuLookupMappingTypes.LoadHuLookupMappingsFailure,
  props<{ errorMessage: string }>()
);

export const updateHuLookupMappings = createAction(
  HuLookupMappingTypes.UpdateHuLookupMappings,
  props<{ deviceName: string; mappings: HuLookupMapping[] }>()
);

export const updateHuLookupMappingsSuccess = createAction(
  HuLookupMappingTypes.UpdateHuLookupMappingsSuccess,
  props<{ deviceName: string }>()
);

export const updateHuLookupMappingsFailure = createAction(
  HuLookupMappingTypes.UpdateHuLookupMappingsFailure,
  props<{ errorMessage: string }>()
);
