import { FeatureToggle } from 'core/dtos';

export type IncludeTuggerTrainErrorsDto = FeatureToggle;
export type NewIpstToggleDto = FeatureToggle;

export interface IpstServiceFeatures {
  includeTuggerTrainErrorsToggle: IncludeTuggerTrainErrorsDto;
  newIpstToggle: NewIpstToggleDto;
}

export function getDefaultIpstServiceFeatures(): IpstServiceFeatures {
  return {
    includeTuggerTrainErrorsToggle: {
      isToggledOn: false,
      dateUpdated: null,
    },
    newIpstToggle: {
      isToggledOn: false,
      dateUpdated: null,
    },
  };
}
