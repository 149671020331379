/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PillarsGridService } from 'core/api-services';
import { filterUndefined } from 'core/helpers';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { setHasChanges } from 'store/actions/ui.actions';

import * as PillarsGridActions from '../actions/pillars-grid.actions';

@Injectable()
export class PillarsGridEffects {
  loadPillarsGridForMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PillarsGridActions.loadPillarsGridForMap),
      concatMap(({ mapId }) => {
        return this.pillarsGridService.getPillarsGridByMapId(mapId).pipe(
          filterUndefined(),
          map(pillarsGrid =>
            PillarsGridActions.loadPillarsGridSuccess({ pillarsGrid: pillarsGrid })
          ),
          catchError(errorMessage =>
            of(PillarsGridActions.loadPillarsGridFailure({ errorMessage }))
          )
        );
      })
    )
  );

  createPillarsGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PillarsGridActions.createPillarsGrid),
      concatMap(state =>
        this.pillarsGridService.createPillarsGrid(state.pillarsGrid, state.mapId).pipe(
          map(pillarsGrid => PillarsGridActions.createPillarsGridSuccess({ pillarsGrid })),
          catchError(errorMessage =>
            of(PillarsGridActions.createPillarsGridFailure({ errorMessage }))
          )
        )
      )
    )
  );

  createPillarsGridSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PillarsGridActions.createPillarsGridSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.pillarsGridActions.updated');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  updatePillarsGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PillarsGridActions.updatePillarsGrid),
      concatMap(({ pillarsGrid }) =>
        this.pillarsGridService.updatePillarsGrid(pillarsGrid).pipe(
          map(() => PillarsGridActions.updatePillarsGridSuccess({ pillarsGrid })),
          catchError(errorMessage =>
            of(PillarsGridActions.updatePillarsGridFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updatePillarsGridSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PillarsGridActions.updatePillarsGridSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.pillarsGridActions.updated');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  deletePillarsGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PillarsGridActions.deletePillarsGrid),
      concatMap(({ pillarsGrid }) =>
        this.pillarsGridService.deletePillarsGrid(pillarsGrid).pipe(
          map(() => PillarsGridActions.deletePillarsGridSuccess({ pillarsGridId: pillarsGrid.id })),
          catchError(errorMessage =>
            of(PillarsGridActions.deletePillarsGridFailure({ errorMessage }))
          )
        )
      )
    )
  );

  deletePillarsGridSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PillarsGridActions.deletePillarsGridSuccess),
      tap(() => {
        this.toastService.createSuccessToast('maps.pillarsGridActions.deleted');
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PillarsGridActions.createPillarsGridFailure,
          PillarsGridActions.deletePillarsGridFailure,
          PillarsGridActions.updatePillarsGridFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly pillarsGridService: PillarsGridService
  ) {}
}
