import '@pixi/graphics-extras';
import { CollisionPoint, VehicleDto } from 'core/dtos';
import { MapItemType, ReleaseType, TrafficManagementFilter, Vector2D } from 'core/models';
import { CollisionPointColors } from 'library/styles';
import { Container, Graphics } from 'pixi.js';
import { calculateMidpoint, calculateOrientationFromPoints } from 'shared/helpers';
import { MapItemBase, MapItemContainer } from '../map-item-container';
import { VehiclePathStyle } from '../vehicles/vehicle-layer.constant';

const CollisionPointStyle = {
  color: CollisionPointColors.Point,
  colorReleased: CollisionPointColors.Released,
  sizeSmall: 10,
  alpha: 0.4,
  scale: 0.025,
  lineSize: 0.05,
};

export class CollisionMapItem implements MapItemBase {
  readonly container: MapItemContainer;
  private readonly graphic: Graphics;
  private readonly arrowGraphic: Graphics;

  constructor(
    view: Container,
    collisionPoint: CollisionPoint,
    pausedVehicle: VehicleDto,
    prioritizedVehicle: VehicleDto,
    private filters?: TrafficManagementFilter
  ) {
    this.container = view.addChild(
      new MapItemContainer(collisionPoint.id.toString(), MapItemType.Collision)
    );

    this.container.scale.set(CollisionPointStyle.scale, -CollisionPointStyle.scale);
    this.graphic = this.createCollisionPointGraphic(collisionPoint.releaseType);
    this.container.addChild(this.graphic);

    this.arrowGraphic = new Graphics();
    view.addChild(this.arrowGraphic);

    this.container.interactive = true;
    this.container.buttonMode = true;

    this.update(collisionPoint, pausedVehicle, prioritizedVehicle);
  }

  private createCollisionPointGraphic(releaseType = ReleaseType.NotReleased): Graphics {
    const color =
      releaseType === ReleaseType.NotReleased
        ? CollisionPointStyle.color
        : CollisionPointStyle.colorReleased;
    return new Graphics()
      .beginFill(color, CollisionPointStyle.alpha)
      .beginFill(color)
      .drawCircle(0, 0, CollisionPointStyle.sizeSmall)
      .endFill();
  }

  private createArrow(
    pausedVehiclePose: Vector2D,
    prioritizedVehiclePose: Vector2D,
    releaseType = ReleaseType.NotReleased
  ): void {
    if (!this.arrowGraphic.visible) {
      return;
    }

    const color =
      releaseType === ReleaseType.NotReleased
        ? CollisionPointStyle.color
        : CollisionPointStyle.colorReleased;

    this.arrowGraphic
      .clear()
      .moveTo(prioritizedVehiclePose.x, prioritizedVehiclePose.y)
      .lineTo(pausedVehiclePose.x, pausedVehiclePose.y)
      .lineStyle(CollisionPointStyle.lineSize, color)
      .lineTo(prioritizedVehiclePose.x, prioritizedVehiclePose.y);

    this.addPathArrow(
      this.arrowGraphic,
      calculateMidpoint(prioritizedVehiclePose, pausedVehiclePose),
      calculateOrientationFromPoints(prioritizedVehiclePose, pausedVehiclePose),
      color
    );
  }

  private addPathArrow(
    graphic: Graphics,
    { x, y }: Vector2D,
    rotation: number,
    color: number
  ): Graphics {
    if (graphic.drawStar) {
      graphic.lineStyle(0);
      graphic.beginFill(color);
      graphic.drawStar(
        x,
        y,
        VehiclePathStyle.ArrowPoints,
        VehiclePathStyle.ArrowSize,
        0,
        rotation + Math.PI / 2
      );
      graphic.endFill();
    }

    return graphic;
  }

  update(
    collisionPoint: CollisionPoint,
    pausedVehicle: VehicleDto,
    prioritizedVehicle: VehicleDto
  ): void {
    this.container.position.set(collisionPoint.pose2D.x, collisionPoint.pose2D.y);

    this.createArrow(pausedVehicle.pose2D, prioritizedVehicle.pose2D, collisionPoint.releaseType);

    this.setVisibility(this.filters);
  }

  remove(): void {
    this.container.removeChildren();
    this.container.parent.removeChild(this.container);
  }

  setVisibility(filter?: TrafficManagementFilter): void {
    if (!filter) {
      return;
    }
    this.filters = filter;
    this.graphic.visible = filter.collisionPoint;
    this.arrowGraphic.visible = filter.dependencies;
  }

  toggleSelection(_isSelected: boolean): void {}
}
