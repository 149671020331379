import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-ipst-alert-now-help-tool-results',
  templateUrl: './ipst-alert-now-help-tool-results.component.html',
  styleUrl: './ipst-alert-now-help-tool-results.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowHelpToolResultsComponent {
  @Input() recipientKeys: string[] = [];
}
