<div
  *ngIf="secondaryInfobar.showInformation && !isMessageExpired"
  data-cy="infobarMessage"
  class="info-bar-container top"
  [ngClass]="secondaryInfobar.infobarStyle"
  [@slideInOut]>
  <p [innerHTML]="secondaryInfobar.message"></p>
</div>
<div
  *ngIf="!hideInfoStatus && viewModel.showInformation"
  data-cy="infoBar"
  role="alert"
  class="info-bar-container pointer bottom"
  [ngClass]="viewModel.infoBarStyle"
  [@slideInOut]
  (click)="onClick()">
  <p row [ngClass]="viewModel.infoBarFont">{{ viewModel.translationKey | translate }}</p>
  <div row *ngFor="let item of viewModel.shutdownConfig">
    {{ 'infoBar.shutdownModeTypes.' + item.vehicleShutdownType | translate }}
    <span *ngIf="item.startTimeUtc" class="shutDownTime">
      {{ item.startTimeUtc | standardDateTime : dateFormat.LongDateTimeMillisec }}&nbsp;
    </span>
    <span
      *ngIf="item.startTimeUtc && item.endShutdownAndWakeUpVehiclesDateTime"
      [innerHTML]="
        'infoBar.wakeUp'
          | translate
            : {
                endShutdownAndWakeUpVehicles:
                  (item.endShutdownAndWakeUpVehiclesDateTime.toString()
                  | standardDateTime : dateFormat.LongDateTimeMillisec)
              }
      "></span>
  </div>
</div>
<div
  *ngIf="!hideInfoStatus && softwareUpdateMessageBar.showInformation"
  data-cy="infobarMessage"
  class="info-bar-container software-update-container"
  [ngClass]="softwareUpdateMessageBar.infobarStyle"
  [@slideInOut]>
  <p [innerHTML]="softwareUpdateMessageBar.message"></p>
</div>
