import { createAction, props } from '@ngrx/store';
import { BaseZoneSetDto } from 'core/dtos';
import { GuidString, Zone } from 'core/models';

export enum ZonesTypes {
  LoadZonesByZoneSetId = '[Zone] Load Zones By ZoneSetId',
  LoadZonesByZoneSetIdSuccess = '[Zone] Load Zones By ZoneSetId Success',
  LoadZonesByZoneSetIdFailure = '[Zone] Load Zones By ZoneSetId Fail',
  AddZone = '[Zone] Add Zone',
  AddZones = '[Zone] Add Zones',
  AddZoneSuccess = '[Zone] Add Zone Success',
  AddZonesSuccess = '[Zone] Add Zones Success',
  AddZoneFailure = '[Zone] Add Zone Failure',
  AddZonesFailure = '[Zone] Add Zones Failure',
  UpdateZone = '[Zone] Update Zone',
  UpdateZoneSuccess = '[Zone] Update Zone Success',
  UpdateZoneFailure = '[Zone] Update Zone Failure',
  ReplaceZoneSuccess = '[Zone] Update Zone Success',
  DeleteZone = '[Zone] Delete Zone',
  DeleteZoneSuccess = '[Zone] Delete Zone Success',
  DeleteZoneFailure = '[Zone] Delete Zone Failure',
  SelectZone = '[Zone] Select Zone',
  ResetActionStatus = '[Zone] Reset Action Status',
}

export enum SignalRZonesTypes {
  SignalrCreateZone = '[SignalR] Create Zone',
  SignalrUpdateZone = '[SignalR] Update Zone',
  SignalrDeleteZone = '[SignalR] Delete Zone',
}

//Load By ZoneSetId
export const loadZonesByZoneSetId = createAction(
  ZonesTypes.LoadZonesByZoneSetId,
  props<{ zoneSetId: GuidString }>()
);

export const loadZonesByZoneSetIdSuccess = createAction(
  ZonesTypes.LoadZonesByZoneSetIdSuccess,
  props<{ zones: Zone[] }>()
);

export const loadZonesByZoneSetIdFailure = createAction(
  ZonesTypes.LoadZonesByZoneSetIdFailure,
  props<{ errorMessage: string }>()
);

// CREATE
export const addZone = createAction(
  ZonesTypes.AddZone,
  props<{ zone: Zone; zoneSet?: BaseZoneSetDto }>()
);

export const addZoneSuccess = createAction(ZonesTypes.AddZoneSuccess, props<{ zone: Zone }>());
export const addZonesSuccess = createAction(ZonesTypes.AddZonesSuccess, props<{ zones: Zone[] }>());

export const addZoneFailure = createAction(
  ZonesTypes.AddZoneFailure,
  props<{ errorMessage: string }>()
);

export const addZonesFailure = createAction(
  ZonesTypes.AddZonesFailure,
  props<{ errorMessage: string }>()
);

// UPDATE
export const updateZone = createAction(
  ZonesTypes.UpdateZone,
  props<{ zone: Zone; zoneSet?: BaseZoneSetDto }>()
);

export const updateZoneSuccess = createAction(
  ZonesTypes.UpdateZoneSuccess,
  props<{ zoneId: GuidString; zone: Zone }>()
);

export const updateZoneFailure = createAction(
  ZonesTypes.UpdateZoneFailure,
  props<{ errorMessage: string }>()
);

// DELETE
export const deleteZone = createAction(
  ZonesTypes.DeleteZone,
  props<{ zone: Zone; zoneSet?: BaseZoneSetDto }>()
);

export const deleteZoneSuccess = createAction(
  ZonesTypes.DeleteZoneSuccess,
  props<{ zone: Zone }>()
);

export const deleteZoneFailure = createAction(
  ZonesTypes.DeleteZoneFailure,
  props<{ errorMessage: string }>()
);

// SELECT
export const selectZone = createAction(ZonesTypes.SelectZone, props<{ zoneId: GuidString }>());

export const resetActionStatus = createAction(ZonesTypes.ResetActionStatus);

export const signalRCreateZone = createAction(
  SignalRZonesTypes.SignalrCreateZone,
  props<{ zone: Zone }>()
);

export const signalRUpdateZone = createAction(
  SignalRZonesTypes.SignalrUpdateZone,
  props<{ zone: Zone }>()
);

export const signalRDeleteZone = createAction(
  SignalRZonesTypes.SignalrDeleteZone,
  props<{ zone: Zone }>()
);
