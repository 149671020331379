<div class="flex-stretch-vertically">
  <div *ngIf="pageHeading || hasToolbar">
    <app-page-header [pageHeading]="pageHeading" [secondaryPageHeading]="secondaryPageHeading">
      <app-tool-bar *ngIf="hasToolbar">
        <ng-content select="[tool-bar-content]"></ng-content>
      </app-tool-bar>
    </app-page-header>
  </div>
  <div
    [class.content-area]="useContentStyling"
    class="pt-1x flex-stretch-vertically"
    base-page-content>
    <div role="Vehicle type filters" class="flex-top">
      <ng-content class="content" select="[layout-head]"></ng-content>
    </div>
    <div role="tabs" class="flex-stretch flex-container">
      <ng-container *ngTemplateOutlet="tabContent"></ng-container>
    </div>
    <ng-content class="content" select="[layout-content]"></ng-content>
  </div>
</div>

<ng-template #routeTabTemplate>
  <ds-tabs
    [navigateOnSelect]="navigateOnSelect"
    contentSpacing="none"
    (activeTabChange)="onActiveTabChanged()">
    <ds-tabs-label
      *ngIf="routeTabs.length > 0"
      [for]="routeTabs[0].displayKey"
      [path]="routeTabs[0].path"
      [attr.data-cy]="routeTabs[0].dataKey">
      <label ds-label class="tab-label">{{ routeTabs[0].displayKey | translate }}</label>
      <label
        ds-label
        class="tab-label text-critical ms-1x"
        *ngIf="routeTabs[0].badgeValue !== undefined"
        [attr.data-cy]="routeTabs[0].dataKey + '-badge'"
        >{{ routeTabs[0].badgeValue }}</label
      >
    </ds-tabs-label>

    <ds-tabs-label
      *ngIf="routeTabs.length > 1"
      [for]="routeTabs[1].displayKey"
      [path]="routeTabs[1].path"
      [attr.data-cy]="routeTabs[1].dataKey">
      <label ds-label class="tab-label">{{ routeTabs[1].displayKey | translate }}</label>
      <label
        ds-label
        class="tab-label text-critical ms-1x"
        *ngIf="routeTabs[1].badgeValue !== undefined"
        [attr.data-cy]="routeTabs[1].dataKey + '-badge'"
        >{{ routeTabs[1].badgeValue }}</label
      >
    </ds-tabs-label>

    <ds-tabs-label
      *ngIf="routeTabs.length > 2"
      [for]="routeTabs[2].displayKey"
      [path]="routeTabs[2].path"
      [attr.data-cy]="routeTabs[2].dataKey">
      <label ds-label class="tab-label">{{ routeTabs[2].displayKey | translate }}</label>
      <label
        ds-label
        class="tab-label text-critical ms-1x"
        *ngIf="routeTabs[2].badgeValue !== undefined"
        [attr.data-cy]="routeTabs[2].dataKey + '-badge'"
        >{{ routeTabs[2].badgeValue }}</label
      >
    </ds-tabs-label>

    <ds-tabs-label
      *ngIf="routeTabs.length > 3"
      [for]="routeTabs[3].displayKey"
      [path]="routeTabs[3].path"
      [attr.data-cy]="routeTabs[3].dataKey">
      <label ds-label class="tab-label">{{ routeTabs[3].displayKey | translate }}</label>
      <label
        ds-label
        class="tab-label text-critical ms-1x"
        *ngIf="routeTabs[3].badgeValue !== undefined"
        [attr.data-cy]="routeTabs[3].dataKey + '-badge'"
        >{{ routeTabs[3].badgeValue }}</label
      >
    </ds-tabs-label>
  </ds-tabs>
</ng-template>

<ng-template #listTabTemplate>
  <app-tab-list
    class="flex-stretch tabs"
    *ngIf="showContent; else noContentTemplate"
    [contentTemplate]="contentTemplate"
    [tabTemplate]="tabTemplate"
    [tabs]="basicTabs"
    [selectedKey]="selectedKey"
    (selectedTabIdChanged)="onSelectedTabIdChanged($event)">
  </app-tab-list>

  <ng-template #noContentTemplate>
    <ng-content select="[no-content]"></ng-content>
  </ng-template>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loader-container">
    <div class="loading-spinner"></div>
    <p class="regular-bold">{{ 'shared.general.loading' | translate }}</p>
  </div>
</ng-template>
