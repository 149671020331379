import { createAction, props } from '@ngrx/store';
import { CollisionPoint } from 'core/dtos';
import { GuidString } from 'core/models';

export enum CollisionPointsTypes {
  LoadCollisionPoints = '[Collision] Load Collision Points',
  LoadCollisionPointsSuccess = '[Collision] Load Collision Points Success',
  LoadCollisionPointsFailure = '[Collision] Load Collision Points Fail',
}

export enum SignalRCollisionPointsTypes {
  SignalrCreateCollisionPoint = '[SignalR] Create Collision Point',
  SignalrUpdateCollisionPoint = '[SignalR] Update Collision Point',
  SignalrDeleteCollisionPoint = '[SignalR] Delete Collision Point',
  SignalRUpdateCollisionPointList = '[SignalR] Update Collision Point List',
}

export const loadCollisionPoints = createAction(
  CollisionPointsTypes.LoadCollisionPoints,
  props<{ mapId: GuidString }>()
);

export const loadCollisionPointsSuccess = createAction(
  CollisionPointsTypes.LoadCollisionPointsSuccess,
  props<{ collisionPoints: CollisionPoint[] }>()
);

export const loadCollisionPointsFailure = createAction(
  CollisionPointsTypes.LoadCollisionPointsFailure,
  props<{ errorMessage: string }>()
);

export const signalRCreateCollisionPoint = createAction(
  SignalRCollisionPointsTypes.SignalrCreateCollisionPoint,
  props<{ collisionPoint: CollisionPoint }>()
);

export const signalRUpdateCollisionPoint = createAction(
  SignalRCollisionPointsTypes.SignalrUpdateCollisionPoint,
  props<{ collisionPoint: CollisionPoint }>()
);

export const signalRUpdateCollisionPointList = createAction(
  SignalRCollisionPointsTypes.SignalRUpdateCollisionPointList,
  props<{ collisionPointList: CollisionPoint[] }>()
);

export const signalRDeleteCollisionPoint = createAction(
  SignalRCollisionPointsTypes.SignalrDeleteCollisionPoint,
  props<{ collisionPoint: CollisionPoint }>()
);
