import { ErrorForwardingEffects } from './error-forwarding.effects';
import { LoadTypeSettingsEffects } from './load-type-settings.effects';
import { RolesEffects } from './roles.effects';
import { SettingsEffects } from './settings.effects';

export const effects = [
  SettingsEffects,
  ErrorForwardingEffects,
  RolesEffects,
  LoadTypeSettingsEffects,
];
