import { ForkliftDimensions } from '../vehicle-dimensions.model';
import { ForkliftMapItem } from '../vehicle-types/forklift.graphic';

const dimensions: ForkliftDimensions = {
  length: 216.7,
  width: 135,
  origin: 127.4,
  fork: {
    width: 50,
    length: 100,
    loadOffset: 10,
  },
  load: {
    width: 100,
    length: 200,
  },
};

export class Forklift4Am20tMapItem extends ForkliftMapItem {
  protected getDimensions(): ForkliftDimensions {
    return this.getForkDimensions({ ...dimensions });
  }
}
