<div class="main ag-custom-component-popup" [style.width.px]="cellWidth">
  <ds-form-field class="dropdown-select ag-custom-component-popup">
    <ds-select
      data-cy="gridDropDownList"
      [class]="'ag-custom-component-popup'"
      (click)="onPopupClick($event)"
      [labellingConfig]="{ placeholder: 'Sensor node name...' }"
      [options]="options"
      (valueChange)="optionSelected()"
      [(ngModel)]="selectedValue"></ds-select>
  </ds-form-field>
</div>
