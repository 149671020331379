/* eslint-disable max-lines */
import { createAction, props } from '@ngrx/store';
import { GuidString, HardwareVersion, SoftwareUpdateDto } from 'core/models';

export enum SoftwareUpdateTypes {
  LoadAllSoftwareUpdates = '[SoftwareUpdates] Load All',
  LoadAllSoftwareUpdatesSuccess = '[SoftwareUpdates] Load All Success',
  LoadAllSoftwareUpdatesFailure = '[SoftwareUpdates] Load All Failure',

  LoadSoftwareUpdates = '[SoftwareUpdates] Load',
  LoadSoftwareUpdatesSuccess = '[SoftwareUpdates] Load Success',
  LoadSoftwareUpdatesFailure = '[SoftwareUpdates] Load Failure',

  SelectSoftwareUpdate = '[SoftwareUpdates] Select',
  RetrySoftwareUpdate = '[SoftwareUpdates] Retry',
  RetrySoftwareUpdateFailure = '[SoftwareUpdates] Retry Failure',
}

export const loadSoftwareUpdates = createAction(
  SoftwareUpdateTypes.LoadSoftwareUpdates,
  props<{ hardwareVersion: HardwareVersion }>()
);

export const loadAllSoftwareUpdates = createAction(SoftwareUpdateTypes.LoadAllSoftwareUpdates);

export const loadAllSoftwareUpdatesSuccess = createAction(
  SoftwareUpdateTypes.LoadAllSoftwareUpdatesSuccess,
  props<{ softwareUpdates: SoftwareUpdateDto[] }>()
);

export const loadAllSoftwareUpdatesFailure = createAction(
  SoftwareUpdateTypes.LoadAllSoftwareUpdatesFailure,
  props<{ errorMessage: string }>()
);

export const loadSoftwareUpdatesSuccess = createAction(
  SoftwareUpdateTypes.LoadSoftwareUpdatesSuccess,
  props<{ softwareUpdates: SoftwareUpdateDto[] }>()
);

export const loadSoftwareUpdatesFailure = createAction(
  SoftwareUpdateTypes.LoadSoftwareUpdatesFailure,
  props<{ errorMessage: string }>()
);

export const selectSoftwareUpdate = createAction(
  SoftwareUpdateTypes.SelectSoftwareUpdate,
  props<{ softwareUpdateId: GuidString }>()
);

export const retrySoftwareUpdate = createAction(
  SoftwareUpdateTypes.RetrySoftwareUpdate,
  props<{ vehicleId: GuidString }>()
);

export const retrySoftwareUpdateFailure = createAction(
  SoftwareUpdateTypes.RetrySoftwareUpdateFailure,
  props<{ errorMessage: string }>()
);
