import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { EditBarService } from 'core/services';
import { ModalDialogService } from 'library/components';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-edit-bar',
  templateUrl: './edit-bar.component.html',
  styleUrls: ['./edit-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditBarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() openPanel = false;

  hasChanges = false;
  isNewObject = false;
  isValidForm = false;
  ngUnsubscribe = new Subject<void>();
  canDelete = true;

  @HostBinding('class.active') isEditMode = false;
  @HostBinding('class.footer-content-open') openNotificationPanelFlag = false;

  constructor(
    private readonly modalService: ModalDialogService,
    private readonly rootStore: Store<fromRoot.RootState>,
    readonly editBarService: EditBarService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.rootStore
      .select(fromRoot.selectIsEditMode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isEditMode: boolean) => {
        this.isEditMode = isEditMode;
        this.cdRef.markForCheck();
      });

    this.rootStore
      .select(fromRoot.selectHasChanges)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((hasChanges: boolean) => {
        this.editBarService.setHasChanges(hasChanges);
      });

    this.subscribeToEditBarService();
  }

  ngOnChanges({ openPanel }: TypedChanges<EditBarComponent>): void {
    if (openPanel?.currentValue && openPanel?.currentValue !== openPanel.previousValue) {
      this.openNotificationPanelFlag = true;

      this.cdRef.markForCheck();
    } else {
      this.openNotificationPanelFlag = false;
    }
  }

  save(): void {
    this.editBarService.onSave();
  }

  cancel(): void {
    if (this.isEditMode && this.hasChanges) {
      this.openModalExitEditMode();
    } else {
      this.exitEditMode();
    }
  }

  delete(): void {
    this.editBarService.onDelete();
  }

  openModalExitEditMode(): void {
    this.modalService
      .createModal('shared.modalExitEditMode')
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.exitEditMode();
      });
  }

  exitEditMode(): void {
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
    this.editBarService.setHasChanges(false);
    this.editBarService.cancel();
  }

  subscribeToEditBarService(): void {
    this.editBarService.isFormValid$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(valid => {
      this.isValidForm = valid;
      this.cdRef.markForCheck();
    });
    this.editBarService.hasChanges$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(hasChanges => {
      this.hasChanges = hasChanges;
      this.cdRef.markForCheck();
    });
    this.editBarService.isNewObject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isNewObject => {
      this.isNewObject = isNewObject;
      this.cdRef.markForCheck();
    });
    this.editBarService.canDelete$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(canDelete => {
      this.canDelete = canDelete;
      this.cdRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
