import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GuidString, Zone } from 'core/models';

import * as ZoneActions from '../actions/zones.actions';

export const featureKey = 'zones';

export interface ZonesState extends EntityState<Zone> {
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
  selectedZoneId: GuidString;
  actionStatus: ZoneActions.ZonesTypes | null;
}

export const zoneAdapter: EntityAdapter<Zone> = createEntityAdapter<Zone>();

export const initialState: ZonesState = zoneAdapter.getInitialState({
  loaded: false,
  loading: false,
  errorMessage: '',
  selectedZoneId: '',
  actionStatus: null,
});

const zonesReducer = createReducer(
  initialState,

  on(ZoneActions.loadZonesByZoneSetId, state => ({
    ...state,
    loading: true,
    loaded: false,
    errorMessage: '',
  })),

  on(ZoneActions.loadZonesByZoneSetIdSuccess, (state, { zones }) =>
    zoneAdapter.setAll(zones, {
      ...state,
      loaded: true,
      loading: false,
      actionStatus: ZoneActions.ZonesTypes.LoadZonesByZoneSetIdSuccess,
    })
  ),

  on(ZoneActions.addZoneSuccess, (state, { zone }) =>
    zoneAdapter.addOne(zone, {
      ...state,
      selectedZoneId: zone.id,
      actionStatus: ZoneActions.ZonesTypes.AddZoneSuccess,
    })
  ),

  on(ZoneActions.addZonesSuccess, (state, { zones }) => {
    return zoneAdapter.addMany(
      zones.map(zone => ({ ...zone, id: zone.id })),
      {
        ...state,
        selectedZoneId: zones[0].id,
        actionStatus: ZoneActions.ZonesTypes.AddZonesSuccess,
      }
    );
  }),

  on(ZoneActions.signalRCreateZone, (state, { zone }) =>
    zoneAdapter.addOne(zone, {
      ...state,
    })
  ),

  on(ZoneActions.loadZonesByZoneSetIdFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
    actionStatus: ZoneActions.ZonesTypes.LoadZonesByZoneSetIdFailure,
  })),

  on(ZoneActions.updateZoneSuccess, (state, { zone, zoneId }) =>
    zoneAdapter.addOne(zone, {
      ...zoneAdapter.removeOne(zoneId.toString(), state),
      actionStatus: ZoneActions.ZonesTypes.UpdateZoneSuccess,
    })
  ),

  on(ZoneActions.signalRUpdateZone, (state, { zone }) =>
    zoneAdapter.updateOne({ id: zone.id.toString(), changes: zone }, state)
  ),

  on(ZoneActions.deleteZoneSuccess, (state, { zone }) =>
    zoneAdapter.removeOne(zone.id.toString(), {
      ...state,
      actionStatus: ZoneActions.ZonesTypes.DeleteZoneSuccess,
    })
  ),
  on(ZoneActions.signalRDeleteZone, (state, { zone }) =>
    zoneAdapter.removeOne(zone.id.toString(), {
      ...state,
    })
  ),

  on(ZoneActions.addZone, ZoneActions.updateZone, ZoneActions.deleteZone, state => ({
    ...state,
    errorMessage: '',
  })),

  on(ZoneActions.addZoneFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    actionStatus: ZoneActions.ZonesTypes.AddZoneFailure,
  })),

  on(ZoneActions.addZonesFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    actionStatus: ZoneActions.ZonesTypes.AddZonesFailure,
  })),

  on(ZoneActions.updateZoneFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    actionStatus: ZoneActions.ZonesTypes.UpdateZoneFailure,
  })),

  on(ZoneActions.deleteZoneFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    actionStatus: ZoneActions.ZonesTypes.DeleteZoneFailure,
  })),

  on(ZoneActions.selectZone, (state, { zoneId }) => ({
    ...state,
    selectedZoneId: zoneId,
  })),

  on(ZoneActions.resetActionStatus, state => ({
    ...state,
    actionStatus: null,
  }))
);

export function reducer(state: ZonesState | undefined, action: Action): ZonesState {
  return zonesReducer(state, action);
}

export const { selectEntities, selectAll } = zoneAdapter.getSelectors();

export const getZonesLoading = (state: ZonesState): boolean => state.loading;
export const getZonesLoaded = (state: ZonesState): boolean => state.loaded;
export const getZoneErrorMessage = (state: ZonesState): string => state.errorMessage;
export const getSelectedZoneId = (state: ZonesState): GuidString => state.selectedZoneId;
export const getZonesActionStatus = (state: ZonesState): ZoneActions.ZonesTypes | null =>
  state.actionStatus;
export const getZoneEntities = selectEntities;
export const getAllZones = selectAll;
