import { createAction, props } from '@ngrx/store';
import { TourChainConfigurationDto } from 'core/dtos';

export enum TourConfigurationActionTypes {
  TriggerTourConfiguration = '[GraphManager] Trigger Tour Configuration',
  TriggerTourConfigurationSuccess = '[GraphManager] Trigger Tour Configuration Success',
  TriggerTourConfigurationFailure = '[GraphManager] Trigger Tour Configuration Failure',
}

export const triggerTourConfiguration = createAction(
  TourConfigurationActionTypes.TriggerTourConfiguration,
  props<{ tourConfiguration: TourChainConfigurationDto }>()
);

export const triggerTourConfigurationSuccess = createAction(
  TourConfigurationActionTypes.TriggerTourConfigurationSuccess,
  props<{ tourConfiguration: TourChainConfigurationDto }>()
);

export const triggerTourConfigurationFailure = createAction(
  TourConfigurationActionTypes.TriggerTourConfigurationFailure,
  props<{ errorMessage: string; tourConfiguration: TourChainConfigurationDto }>()
);
