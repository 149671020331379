import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OrganizationDto, RoleDto } from 'core/dtos';
import { DateString, User } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { addMonths } from 'date-fns';
import { Observable, Subject, of } from 'rxjs';
import * as fromRoot from 'store/index';
import { OrganizationChartComponent } from '../organization-chart/organization-chart.component';

export interface AssignUserRoleDialogInput {
  currentUser?: User;
  translateKey: string;
  roles: RoleDto[];
  currentRole?: RoleDto;
  areaText: string;
  users: User[];
}

@Component({
  selector: 'app-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsDialogComponent implements OnChanges, OnDestroy {
  @Input() isModalOpen = false;
  @Input() userDetailsData?: AssignUserRoleDialogInput;
  @Input() editEnabled = false;
  @Output() readonly dismissModal = new EventEmitter<void>();
  @ViewChild(forwardRef(() => OrganizationChartComponent))
  orgChart!: OrganizationChartComponent;

  organizationList$: Observable<OrganizationDto[]>;

  currentUser?: User;
  translateKey!: string;
  currentLang = '';
  roles!: RoleDto[];
  currentRole?: RoleDto;
  areaText!: string;
  users!: User[];
  isEditMode$ = of(false);
  userId = '0';
  accessExpiry: DateString = '';
  readonly editTranslateKey = 'shared.editUserRoleModal';
  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    protected readonly translate: AtsTranslationService
  ) {
    this.organizationList$ = this.rootStore.pipe(select(fromRoot.selectAllOrganizations));
  }

  ngOnChanges(): void {
    try {
      Object.assign(this, this.userDetailsData);
      this.userId = this.userDetailsData?.currentUser?.id ?? '0';
      this.accessExpiry = this.calculateLoginExpiry(
        this.userDetailsData?.currentUser?.lastLoginDate ?? ''
      );
      this.currentLang = this.translate.currentLang;
      this.initializeSubscriptions();
    } catch (error) {
      console.error('Error loading users ' + error);
    }
  }

  private calculateLoginExpiry(lastLoginDateString: DateString): DateString {
    let loginExpiryDate = '';
    const monthsUntilLoginExpires = 3;
    if (lastLoginDateString) {
      const lastLoginDate = new Date(lastLoginDateString);
      loginExpiryDate = addMonths(lastLoginDate, monthsUntilLoginExpires).toString();
    }
    return loginExpiryDate;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
  }

  initializeSubscriptions(): void {
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
  }

  onCancel(): void {
    if (this.orgChart) {
      this.orgChart.cancel();
    }
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
    this.dismissModal.emit();
  }

  async onSave(): Promise<void> {
    if (this.orgChart) {
      await this.orgChart.save();
    }
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
    this.initializeSubscriptions();
  }
}
