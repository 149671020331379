import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { SessionService } from 'core/services/session.service';
import { SignalRNextService } from 'core/signalR/signalr-next.service';

@Injectable({ providedIn: 'root' })
export class TabChangedGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly sessionService: SessionService,
    protected signalRNextService: SignalRNextService
  ) {}

  canActivate(state: ActivatedRouteSnapshot): boolean {
    if (
      state.data.selectedSideMenuItem &&
      this.sessionService.currentTab !== state.data.selectedSideMenuItem
    ) {
      this.sessionService.currentTab = state.data.selectedSideMenuItem;
      this.signalRNextService.checkOnlineStatus();
    } else {
      this.sessionService.currentTab = state.data.selectedSideMenuItem;
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.canActivate(route);
  }
}
