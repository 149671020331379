import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { RuleDto } from 'core/dtos';
import { GuidString } from 'core/models';

export enum RouteCustomizationRuleActionTypes {
  LoadRouteCustomizationRules = '[GraphManager] Load Route Customization Rules',
  LoadRouteCustomizationRulesSuccess = '[GraphManager] Load Route Customization Rules Success',
  LoadRouteCustomizationRulesFailure = '[GraphManager] Load Route Customization Rules Failure',
  CreateRouteCustomizationRule = '[GraphManager] Create Route Customization Rule',
  CreateRouteCustomizationRuleSuccess = '[GraphManager] Create Route Customization Rule Success',
  CreateRouteCustomizationRuleFailure = '[GraphManager] Create Route Customization Rule Failure',
  UpdateRouteCustomizationRules = '[GraphManager] Update Route Customization Rule',
  UpdateRouteCustomizationRuleSuccess = '[GraphManager] Update Route Customization Rule Success',
  UpdateRouteCustomizationRuleFailure = '[GraphManager] Update Route Customization Rule Failure',
  DeleteRouteCustomizationRule = '[GraphManager] Delete Route Customization Rule',
  DeleteRouteCustomizationRuleSuccess = '[GraphManager] Delete Route Customization Rule Success',
  DeleteRouteCustomizationRuleFailure = '[GraphManager] Delete Route Customization Rule Failure',
}

export const loadRouteCustomizationRules = createAction(
  RouteCustomizationRuleActionTypes.LoadRouteCustomizationRules
);

export const loadRouteCustomizationRulesSuccess = createAction(
  RouteCustomizationRuleActionTypes.LoadRouteCustomizationRulesSuccess,
  props<{ rules: RuleDto[] }>()
);

export const loadRouteCustomizationRulesFailure = createAction(
  RouteCustomizationRuleActionTypes.LoadRouteCustomizationRulesFailure,
  props<{ errorMessage: string }>()
);

export const createRouteCustomizationRule = createAction(
  RouteCustomizationRuleActionTypes.CreateRouteCustomizationRule,
  props<{ rule: RuleDto }>()
);

export const createRouteCustomizationRuleSuccess = createAction(
  RouteCustomizationRuleActionTypes.CreateRouteCustomizationRuleSuccess,
  props<{ rule: RuleDto }>()
);

export const createRouteCustomizationRuleFailure = createAction(
  RouteCustomizationRuleActionTypes.CreateRouteCustomizationRuleFailure,
  props<{ errorMessage: string }>()
);

export const updateRouteCustomizationRule = createAction(
  RouteCustomizationRuleActionTypes.UpdateRouteCustomizationRules,
  props<{ rule: RuleDto }>()
);

export const updateRouteCustomizationRuleSuccess = createAction(
  RouteCustomizationRuleActionTypes.UpdateRouteCustomizationRuleSuccess,
  props<{ rule: Update<RuleDto> }>()
);

export const updateRouteCustomizationRuleFailure = createAction(
  RouteCustomizationRuleActionTypes.UpdateRouteCustomizationRuleFailure,
  props<{ errorMessage: string }>()
);

export const deleteRouteCustomizationRule = createAction(
  RouteCustomizationRuleActionTypes.DeleteRouteCustomizationRule,
  props<{ id: GuidString }>()
);

export const deleteRouteCustomizationRuleSuccess = createAction(
  RouteCustomizationRuleActionTypes.DeleteRouteCustomizationRuleSuccess,
  props<{ id: GuidString }>()
);

export const deleteRouteCustomizationRuleFailure = createAction(
  RouteCustomizationRuleActionTypes.DeleteRouteCustomizationRuleFailure,
  props<{ errorMessage: string }>()
);
