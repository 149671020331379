<ds-form-field *ngIf="isVisible">
  <ds-select
    ds-form-validation
    data-cy="stepAttributeNodeDropdown"
    [labellingConfig]="labellingConfig"
    [options]="options | dsSortGroupedDropdown : 'label'"
    [(ngModel)]="selectedId"
    required
    (optionSelected)="onSelectionChange()"></ds-select>
</ds-form-field>
