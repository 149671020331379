import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { TourLogDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/index';

@Injectable({
  providedIn: 'root',
})
export class TourLogService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.TourLog;
  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getLogsByTourId(tourId: GuidString): Observable<TourLogDto[]> {
    return this.get<TourLogDto[]>(`${this.servicePath}/${tourId}`);
  }
}
