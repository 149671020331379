import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'latestDate',
})
export class LatestDatePipe implements PipeTransform {
  transform(object: object | undefined, key: string): Date | null {
    if (!object) return null;
    let latestDate: Date | null = null;

    Object.values(object).forEach(value => {
      if (!value[key]) return;
      const date = new Date(value[key]);
      if (!latestDate || date > latestDate) {
        latestDate = date;
      }
    });

    return latestDate;
  }
}
