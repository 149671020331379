/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  RowGroupingDisplayType,
} from 'ag-grid-community';
import { TreeTableComponent } from 'components/tree-table/tree-table.component';
import { RoleDto } from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { AtsActions, AtsActionsGrouping, Role } from 'core/models';
import { AtsTranslationService, EditBarService, ToolbarService } from 'core/services';
import { StandardTableColumns, TableColumns } from 'library/models';
import { isEqual, pull } from 'lodash';
import { Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseAgGridTableDirective, GridValueGetter } from 'shared/components';
import * as fromRoot from 'store/index';
import { PermissionsCheckBoxComponent } from '../permissions-checkbox/permissions-checkbox.component';

@Component({
  selector: 'app-roles-and-permissions',
  templateUrl: './roles-and-permissions.component.html',
  styleUrls: ['./roles-and-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesAndPermissionsComponent
  extends BaseAgGridTableDirective
  implements OnChanges, OnInit, OnDestroy
{
  @ViewChild(TreeTableComponent) child!: TreeTableComponent;
  @Input() roles: Role[] = [];
  @Input() searchTerm = '';
  @Input() atsDefaultRoles: Role[] = [];
  @Output() readonly saveRoles: EventEmitter<Role[]> = new EventEmitter<Role[]>();

  private readonly permissionsColumn: TableColumns = {
    field: 'groupName',
    type: 'text',
    size: 300,
  };
  displayedColumns: TableColumns[] = [this.permissionsColumn];
  FireFighter = 'Fire Fighter';

  ngUnsubscribe = new Subject<void>();
  translationContext = 'settings.roles';
  rows: Record<string, unknown>[] = [];
  modifiedRows: Record<string, unknown>[] = [];

  isEditMode$ = of(false);
  copyOfRoles: Role[] = [];
  defaultRoles: Role[] = [];
  cancelOfRoles: Role[] = [];
  isEditMode = false;
  hasBeenEdited = false;
  reset = false;

  readonly columns: ColDef[] = [
    {
      field: 'parentGroupName',
      tooltipField: 'permissions',
      headerName: 'settings.roles.list.parentGroupName',
      hide: true,
      wrapText: true,
      autoHeight: true,
      resizable: true,
      rowGroup: true,
      suppressFiltersToolPanel: true,
      suppressColumnsToolPanel: true,
      valueGetter: GridValueGetter.getTranslatedValueGetterFn(this.translationService),
    },
    {
      field: 'groupName',
      tooltipField: 'permissions',
      headerName: 'settings.roles.list.groupName',
      wrapText: true,
      autoHeight: true,
      resizable: true,
      minWidth: 400,
      width: 400,
    },
    {
      field: 'Viewer',
      tooltipField: 'viewer',
      headerName: 'settings.roles.list.Viewer',
      cellRenderer: PermissionsCheckBoxComponent,
      resizable: true,
    },
    {
      field: 'Environment Viewer',
      tooltipField: 'environmentViewer',
      headerName: 'settings.roles.list.Environment Viewer',
      cellRenderer: PermissionsCheckBoxComponent,
      resizable: true,
    },
    {
      field: 'Fire Fighter',
      tooltipField: 'FireFighter',
      headerName: 'settings.roles.list.Fire Fighter',
      cellRenderer: PermissionsCheckBoxComponent,
      resizable: true,
    },
    {
      field: 'ATS Support',
      tooltipField: 'atsSupport',
      headerName: 'settings.roles.list.ATS Support',
      resizable: true,
      cellRenderer: PermissionsCheckBoxComponent,
    },
    {
      field: 'Operator',
      tooltipField: 'operator',
      headerName: 'settings.roles.list.Operator',
      cellRenderer: PermissionsCheckBoxComponent,
      resizable: true,
    },
    {
      field: 'Supervisor',
      tooltipField: 'supervisor',
      headerName: 'settings.roles.list.Supervisor',
      cellRenderer: PermissionsCheckBoxComponent,
      resizable: true,
    },
    {
      field: 'WorkingArea Admin',
      tooltipField: 'workingAreaAdmin',
      headerName: 'settings.roles.list.WorkingArea Admin',
      cellRenderer: PermissionsCheckBoxComponent,
      resizable: true,
    },
    {
      field: 'Organization Admin',
      tooltipField: 'organizationAdmin',
      headerName: 'settings.roles.list.Organization Admin',
      cellRenderer: PermissionsCheckBoxComponent,
      resizable: true,
    },
    {
      field: 'Environment Admin',
      tooltipField: 'environmentAdmin',
      headerName: 'settings.roles.list.Environment Admin',
      cellRenderer: PermissionsCheckBoxComponent,
      resizable: true,
    },
  ];
  groupDisplayType: RowGroupingDisplayType = 'groupRows';

  constructor(
    protected readonly atsTranslationService: AtsTranslationService,
    protected readonly toolbarService: ToolbarService,
    protected readonly cdRef: ChangeDetectorRef,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly editBarService: EditBarService
  ) {
    super(atsTranslationService, toolbarService, cdRef);
    this.gridOptions.rowSelection = undefined;
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.translateHeader();
    this.atsTranslationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.buildRows(this.copyOfRoles);
    });
    this.editBarService.onCancel$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onCancel.bind(this));
    this.editBarService.onSave = this.onSave.bind(this);
  }

  ngOnChanges({ roles, atsDefaultRoles }: TypedChanges<RolesAndPermissionsComponent>): void {
    if (roles?.currentValue && atsDefaultRoles?.currentValue) {
      this.copyOfRoles = [...roles.currentValue];
      this.defaultRoles = [...atsDefaultRoles.currentValue];
      this.cancelOfRoles = [...roles.currentValue];
      this.buildModel(roles.currentValue);
      this.buildRows(roles.currentValue);
      this.rowsHaveBeenEdited();
    }
    this.editBarService.setCanDelete(false);
  }

  initializeSubscriptions(): void {
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
    this.rootStore
      .pipe(select(fromRoot.selectIsEditMode), takeUntil(this.ngUnsubscribe))
      .subscribe(isEditMode => {
        this.isEditMode = isEditMode;
      });
  }

  onChange(row: Record<string, string | boolean>, roleName: string): void {
    const childGroup = this.rows?.find(c => c.groupName === row.groupName);
    if (childGroup) {
      const copy = [...this.copyOfRoles];
      const role = copy.find(r => r.name === roleName);
      const newPermissionValue = !childGroup[roleName];
      childGroup[roleName] = newPermissionValue;
      if (role) {
        this.addOrRemoveRole(newPermissionValue, role, childGroup.permission as string);
        row[role.name + 'edited'] = newPermissionValue;
      }
    }
    this.reset = false;
    this.editBarService.setIsFormValid(true);
    this.buildRows(this.copyOfRoles);
    this.editBarService.setHasChanges(!isEqual(this.rows, this.modifiedRows));
    this.modifiedRows = objectHelper.cloneDeep(this.rows);
  }

  rowsHaveBeenEdited(): boolean {
    const copy = [...this.copyOfRoles];
    copy.sort((a, b) => a.permissions.length - b.permissions.length);
    this.defaultRoles.sort((a, b) => a.permissions.length - b.permissions.length);

    for (let i = 0; i < this.defaultRoles.length; i++) {
      if (
        isEqual(
          this.defaultRoles[i].permissions.sort((a, b) => (a > b ? 1 : -1)),
          copy[i].permissions.sort((a, b) => (a > b ? 1 : -1))
        )
      ) {
        this.hasBeenEdited = false;
      } else {
        this.hasBeenEdited = true;
        break;
      }
    }
    return this.hasBeenEdited;
  }

  addOrRemoveRole(addPermission: boolean, role: Role, permission: string): void {
    addPermission
      ? this.addPermissionToRole(role, permission)
      : pull(role?.permissions, permission);
  }

  addPermissionToRole(role: Role, newPermission: string): void {
    const roleCopy = Object.assign({}, role);
    roleCopy.permissions = Object.assign([], role.permissions);
    roleCopy.permissions.push(newPermission);
    this.addUpdatedRoleToRoles(roleCopy);
  }

  addUpdatedRoleToRoles(roleCopy: Role): void {
    const updateRole = this.copyOfRoles.find(r => r.name === roleCopy.name);
    if (updateRole !== undefined) {
      const index = this.copyOfRoles.indexOf(updateRole);
      this.copyOfRoles[index] = roleCopy;
    }
  }

  buildModel(roles: RoleDto[]): void {
    roles.sort((a, b) => a.permissions.length - b.permissions.length);

    const columns = roles.map(
      (o): StandardTableColumns => ({ field: o.name, type: 'checkBox', size: 75 })
    );
    this.displayedColumns = [this.permissionsColumn, ...columns];
  }

  buildRows(roles: RoleDto[]): void {
    const allPermissions = Object.keys(AtsActions);
    const groups: Record<string, unknown>[] = [];

    for (const permission of allPermissions) {
      const row: Record<string, string | boolean> = { groupName: '', permission: permission };
      row.groupName = this.atsTranslationService.get(
        this.translationContext + '.permissions.' + permission
      );

      for (const role of roles) {
        row[role.name] = role.permissions.includes(permission);
        row[role.name + 'edited'] = false;

        const defaultRoleStatus = this.defaultRoles
          ?.find(x => x.name === role.name)
          ?.permissions?.includes(permission);

        if (row[role.name] !== defaultRoleStatus) {
          row[role.name + 'edited'] = true;
        }
      }
      const grouping = this.atsTranslationService.get(
        this.translationContext + '.groups.' + AtsActionsGrouping[permission]
      );
      row.parentGroupName = grouping;
      groups?.push(row);
    }

    this.rows = groups;
  }

  onSave(): void {
    if (this.reset) {
      this.buildRows(this.defaultRoles);
      this.saveRoles.emit(this.defaultRoles);
      this.reset = false;
      this.hasBeenEdited = false;
    } else {
      this.modifiedRows = objectHelper.cloneDeep(this.rows);
      const savedRoles = objectHelper.cloneDeep(this.copyOfRoles);
      this.cancelOfRoles = objectHelper.cloneDeep(this.copyOfRoles);
      this.saveRoles.emit(savedRoles);
      this.hasBeenEdited = true;
      this.reset = false;
    }
  }

  onCancel(): void {
    // this.roles = objectHelper.cloneDeep(this.roles);
    this.copyOfRoles = objectHelper.cloneDeep(this.cancelOfRoles);
    this.buildRows(this.copyOfRoles);
    this.rowsHaveBeenEdited();
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
    this.editBarService.setIsFormValid(true);
    this.editBarService.setHasChanges(true);
  }

  resetToDefaults(): void {
    this.reset = true;
    this.hasBeenEdited = false;
    this.roles = objectHelper.cloneDeep(this.defaultRoles);
    this.copyOfRoles = objectHelper.cloneDeep(this.defaultRoles);
    this.cancelOfRoles = objectHelper.cloneDeep(this.defaultRoles);
    this.buildRows(this.copyOfRoles);
    this.editBarService.setIsFormValid(true);
    this.editBarService.setHasChanges(true);
  }

  ngOnDestroy(): void {
    this.editBarService.setHasChanges(false);
    this.editBarService.setIsFormValid(false);
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onGridReady(params: GridReadyEvent): void {
    super.onGridReady(params);
  }

  getRowIdForChangeDetection: GetRowIdFunc = (params: GetRowIdParams) =>
    params.data.groupName.toString();

  onRowSelected(): void {}
}
