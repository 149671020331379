<app-table
  actionMenu="all"
  data-cy="evacuationFeedbackTable"
  [displayedColumns]="displayedColumns"
  [records]="tableContent"
  [menuItems]="menuItems"
  [menuItemsStyleClass]="'small-menu-items'"
  translationContext="settings.functions.wagoConfigDialog.list"
  [emptyTableTemplate]="newRowItemTemplate"
  [newRowItemTemplate]="newRowItemTemplate"
  [itemTemplate]="customTemplate">
  <ng-template #customTemplate let-rowData let-field="field" let-type="type">
    <div *ngIf="field === 'node'">
      {{ rowData[field] }}
      <ds-icon
        *ngIf="!rowData.isValid"
        tone="critical"
        icon="error"
        class="errorIcon"
        data-cy="feedbackRowErrorIcon"
        ds-tooltip="{{ rowData.errorMessage }}">
      </ds-icon>
    </div>
    <div *ngIf="field === 'activationValue'">
      <ds-select
        data-cy="onActivationFeedbackInput"
        [isMultiselect]="false"
        [showClearAll]="false"
        [isFilterable]="false"
        [value]="rowData[field]"
        [disabled]="!hasEvacuationDeviceUpdatePermission"
        [options]="nodeDropdownList[rowData.node + '_' + field] | dsDropdown : 'name' : 'value'"
        (optionSelected)="onChangeActivationValue($any($event).id)">
      </ds-select>
    </div>
    <div *ngIf="field === 'deactivationValue'">
      <ds-select
        data-cy="onDeactivationFeedbackInput"
        [isMultiselect]="false"
        [showClearAll]="false"
        [isFilterable]="false"
        [value]="rowData[field]"
        [disabled]="!hasEvacuationDeviceUpdatePermission"
        [options]="nodeDropdownList[rowData.node + '_' + field] | dsDropdown : 'name' : 'value'"
        (optionSelected)="onChangeDeactivationValue($any($event).id)">
      </ds-select>
    </div>
  </ng-template>
  <ng-template #newRowItemTemplate let-columns let-columnLength>
    <td class="newItemHeight" [attr.colspan]="displayedColumns.length">
      <ds-select
        data-cy="feedbackNodeDropdownListDropdown"
        [isMultiselect]="true"
        [showClearAll]="true"
        [isFilterable]="false"
        [variant]="'inline'"
        [labellingConfig]="labellingConfig"
        [disabled]="!hasEvacuationDeviceUpdatePermission"
        [options]="unselectedWriteTypeNodes | dsDropdown : 'name' : 'value'"
        (isOpenChange)="onNewRowItemIsOpen($event)"
        (optionSelected)="onNewRowItemValue($event)">
      </ds-select>
    </td>
  </ng-template>
</app-table>
