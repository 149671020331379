import { createSelector } from '@ngrx/store';
import { InternalTourStatus } from 'core/models';
import * as fromMonitoringFeatureState from '../reducers/index';
import * as fromTour from '../reducers/tour.reducer';

const selectTourState = createSelector(
  fromMonitoringFeatureState.getMonitoringFeatureState,
  fromMonitoringFeatureState.getTourState
);

export const selectTourLoading = createSelector(selectTourState, fromTour.getLoading);

export const selectTourLoaded = createSelector(selectTourState, fromTour.getLoaded);

export const selectAllTours = createSelector(selectTourState, fromTour.getAllTours);

export const selectSelectedTourId = createSelector(selectTourState, fromTour.getSelectedTourId);

export const selectSelectedTour = createSelector(
  selectSelectedTourId,
  selectAllTours,
  (tourId, tours) => tours.filter(m => m.id === tourId)[0]
);

export const selectFailedToursCount = createSelector(selectAllTours, tours => {
  return tours.filter(o => o.status === InternalTourStatus.Failed).length;
});
