import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { MapDto, MapImage, PreviewDeleteMapDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class MapsService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.Maps;

  protected apiUrl = environment.Services.MapManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getAllMaps(): Observable<MapDto[]> {
    return this.get<MapDto[]>(this.servicePath);
  }

  createMap(name: string): Observable<MapDto> {
    return this.post<MapDto>(this.servicePath, {
      name: name,
      creationDateTime: new Date().toLocaleString('en-US'),
    });
  }

  updateMap(vehicleId: GuidString, mapId: GuidString): Observable<void> {
    return this.post<void>(`${API_SERVICES.Dispatcher}/vehicle/${vehicleId}/updateMap`, {
      mapId: mapId,
    });
  }

  updateMapName(map: MapDto): Observable<MapDto> {
    return this.put<MapDto>(`${this.servicePath}`, map);
  }

  updateZoneSet(vehicleId: GuidString, zoneSetId: GuidString): Observable<void> {
    return this.post<void>(
      `${API_SERVICES.Dispatcher}/vehicle/${vehicleId}/updateZones/${zoneSetId}`
    );
  }

  deleteMap(mapId: GuidString): Observable<GuidString> {
    return this.delete<GuidString>(`${this.servicePath}/${mapId}`);
  }

  getHasZoneOverlapsForZoneSet(zoneSetId: GuidString, mapId: GuidString): Observable<boolean> {
    return this.get<boolean>(
      `${this.servicePath}/${zoneSetId.toString()}/hasOverlappingZones/${mapId.toString()}`
    );
  }

  previewDeleteMap(id: GuidString): Observable<PreviewDeleteMapDto> {
    return this.get<PreviewDeleteMapDto>(`${this.servicePath}/previewDelete/${id}`);
  }

  // BACKGROUND IMAGE
  getBackgroundImage(mapId: GuidString): Observable<string> {
    return this.get<string>(`${this.servicePath}/${mapId}/downloadbackgroundurl`);
  }

  addBackgroundImage(mapId: GuidString, backgroundImage: MapImage): Observable<void> {
    backgroundImage = {
      ...backgroundImage,
      imageStreamBase64: backgroundImage.imageStreamBase64.split(',')[1],
    };
    return this.post<void>(`${this.servicePath}/${mapId}/addBackground`, backgroundImage);
  }

  updateBackgroundImage(mapId: GuidString, backgroundImage: MapImage): Observable<void> {
    backgroundImage = {
      ...backgroundImage,
      imageStreamBase64: backgroundImage.imageStreamBase64.split(',')[1],
    };
    return this.post<void>(`${this.servicePath}/${mapId}/updateBackground`, backgroundImage);
  }

  getImagePath(url: string): string {
    if (url === '') return url;

    return environment.Services.MapManager + url;
  }
}
