import {
  EvacuationDeviceDto,
  EvacuationDeviceFeedbackDto,
  EvacuationDeviceTriggerDto,
} from 'core/dtos/settings/evacuation-device.dto';
import { cloneDeep } from 'lodash';
import { GuidString } from '../core/string-typings';

export interface EvacuationModeType {
  name: string;
  value: string;
}

export class EvacuationDeviceTriggerItem implements EvacuationDeviceTriggerDto {
  constructor(
    public node: string = '',
    public value: string = '',
    public errorMessage: string = ''
  ) {}

  convertDtoItem(item: EvacuationDeviceTriggerDto): EvacuationDeviceTriggerItem {
    this.errorMessage = '';
    this.node = item.node;
    this.value = item.value;
    return this;
  }

  get isValid(): boolean {
    return this.value.length > 0;
  }
}

export class EvacuationDeviceFeedbackItem implements EvacuationDeviceFeedbackDto {
  constructor(
    public node: string = '',
    public activationValue: string = '',
    public deactivationValue: string = '',
    public errorMessage: string = ''
  ) {}

  convertDtoItem(item: EvacuationDeviceFeedbackDto): EvacuationDeviceFeedbackItem {
    this.errorMessage = '';
    this.node = item.node;
    this.activationValue = item.activationValue;
    this.deactivationValue = item.deactivationValue;
    return this;
  }

  get isValid(): boolean {
    return this.activationValue !== this.deactivationValue;
  }
}

export class EvacuationDevices {
  content: EvacuationDeviceItem[];
  constructor(public baseContent: EvacuationDeviceItem[]) {
    this.content = cloneDeep(this.baseContent);
  }

  get devices(): EvacuationDeviceItem[] {
    return this.content;
  }

  addDevice(item: EvacuationDeviceItem): void {
    this.content.push(item);
  }

  removeDevice(item: EvacuationDeviceItem): void {
    const index = this.content.findIndex(i => i.device === item.device);
    this.content.splice(index, 1);
  }

  get isValid(): boolean {
    const isModelChanged = JSON.stringify(this.content) !== JSON.stringify(this.baseContent);
    return isModelChanged && this.content.filter(i => i.isValid).length === this.content.length;
  }
}

export class EvacuationDeviceItem implements EvacuationDeviceDto {
  constructor(
    public device: string = '',
    public workAreaId: GuidString = '',
    public triggers: EvacuationDeviceTriggerItem[] = [],
    public feedbacks: EvacuationDeviceFeedbackItem[] = [],
    public isTriggered: boolean = false,
    public errorMessage: string = ''
  ) {}

  get isValid(): boolean {
    const checkFeedbacks =
      this.feedbacks.filter(i => i.isValid).length === this.feedbacks.length &&
      this.feedbacks.length > 0;
    const checkTriggers =
      this.triggers.filter(i => i.isValid).length === this.triggers.length ||
      this.triggers.length === 0;
    return checkFeedbacks && checkTriggers;
  }

  convertDtoItem(item: EvacuationDeviceDto): EvacuationDeviceItem {
    this.errorMessage = '';
    this.device = item.device;
    this.isTriggered = item.isTriggered;
    this.workAreaId = item.workAreaId;
    item.feedbacks.forEach(i =>
      this.feedbacks.push(new EvacuationDeviceFeedbackItem().convertDtoItem(i))
    );
    item.triggers.forEach(i =>
      this.triggers.push(new EvacuationDeviceTriggerItem().convertDtoItem(i))
    );
    return this;
  }
}
