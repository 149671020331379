import { ImageOffsetResponseModel } from 'core/dtos';
import { Point } from 'pixi.js';

export type MapLayer = IMapLayerDrawing | IOffsetMapLayerDrawing;

export enum MapLayerType {
  NavigationLayer = 'navigationLayer',
  GraphLayer = 'graphLayer',
  GppLayer = 'gppLayer',
  BackgroundImageLayer = 'backgroundImageLayer',
  PoiPathLayer = 'poiPathLayer',
}

export interface IMapLayerDrawing {
  id: string;
  type: MapLayerType;
}

export interface IOffsetMapLayerDrawing extends IMapLayerDrawing {
  updateOffset(offset: ImageOffsetResponseModel): void;
  calculateLayerPosition(layerOffset: ImageOffsetResponseModel): Point;
}
