import { DirectedZoneDto } from 'core/dtos';
import { Matrix, Point, Texture } from 'pixi.js';

import { ZoneLayerImages } from '../map-layer-images.constant';
import { ZoneBorder } from './zone.constant';
import { ZoneMapItem } from './zone.graphic';

const PivotY = 2.2;

export class DirectedZoneMapItem extends ZoneMapItem {
  tempZone?: DirectedZoneDto;
  texture?: Texture;

  protected createZoneGraphic(zone: DirectedZoneDto): void {
    const points = zone.polygon.map(p => new Point(p.x, p.y));
    this.tempZone = zone;

    if (!this.texture) {
      this.texture = Texture.from(ZoneLayerImages.Zone_One_Way, {
        resolution: 40,
        resourceOptions: { scale: 5 },
      });
    }

    const centroid = this.getCentroid(points);
    const matrix = Matrix.IDENTITY;
    matrix.setTransform(centroid.x, centroid.y, 0, PivotY, 1, 1, zone.orientation, 0, 0);

    const borderColor = this.getBorderColor();

    this.graphic.clear().beginTextureFill({ texture: this.texture, matrix });

    if (borderColor) {
      this.graphic.lineStyle(ZoneBorder.size, borderColor, ZoneBorder.alpha, ZoneBorder.alignment);
    }

    this.graphic.drawPolygon(points).endFill();
    this.createZoneVertices(points);
  }

  getBorderColor(): number {
    if (this.hasCollision) {
      return ZoneBorder.error;
    }
    return this.isSelected ? ZoneBorder.selected : ZoneBorder[this.zone.zoneType];
  }

  setOrientation(orientation = 0): void {
    if (this.tempZone) {
      const zone = { ...this.tempZone };
      zone.orientation = orientation;
      this.createZoneGraphic(zone);
    }
  }

  private getCentroid(allPoints: Point[]): Point {
    const points = [...allPoints];
    points.pop();
    const length = points.length;
    return points.reduce((last, current) => {
      last.x += current.x / length;
      last.y += current.y / length;
      return last;
    }, new Point(0, 0));
  }
}
