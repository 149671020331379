export enum ProcessChainGroupTraceStatus {
  Waiting = 0,
  Scheduled = 1,
  Active = 2,
  Completed = 3,
  Failed = 4,
}

export enum ProcessChainGroupExecutionMode {
  Parallel = 0,
  Sequential = 1,
}
