import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'core/services/session.service';
import { ToastService } from 'core/services';
import { ModalDialogService } from 'library/components/modal-dialog';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterUserComponent implements OnInit {
  constructor(
    private readonly modalService: ModalDialogService,
    private readonly sessionService: SessionService,
    private readonly router: Router,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    if (this.sessionService.isUserAuthenticated() && this.sessionService.hasUser()) {
      void this.router.navigate(['/welcome']);
    } else {
      this.confirmUserDetailsStore();
    }
  }

  confirmUserDetailsStore(): void {
    const userInfo = this.sessionService.getIdentityClaims();

    const data = { name: userInfo?.name || 'Unknown User' };

    this.modalService
      .createInfoModal('shared.userNotFoundModal', data)
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        void this.createUser();
      });
  }

  async createUser(): Promise<void> {
    try {
      await this.sessionService.registerUser();
      console.info('User accepted and was created.');
      await this.router.navigate(['/welcome']);
    } catch (error) {
      if (error instanceof Error) {
        this.toastService.createErrorToast(error.message);
      }
    }
  }
}
