export enum pollInferenceStatus {
  ResponseStatus = 'InProgress',
}

export interface HistoryItem {
  text: string;
}

export interface InferenceRequestHistory {
  inputs: HistoryItem;
  outputs: HistoryItem;
}

export interface InferenceRequestDto {
  text: string;
  streaming: boolean;
  sub: string;
  history: InferenceRequestHistory[];
  files: string[];
}

export interface InferenceRequestResponseDto {
  asyncId: string;
}

export interface InferenceResponseDto {
  answer: string;
  references: string;
}
