import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-step-attribute-timespan',
  templateUrl: './step-attribute-timespan.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StepAttributeTimeSpanComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepAttributeTimeSpanComponent implements ControlValueAccessor {
  timeSpan?: number;

  writeValue(timeSpan: number | undefined): void {
    this.timeSpan = timeSpan;
  }

  registerOnChange(fn: () => {}): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  onChanged = (_value: number | undefined): void => {};
  onTouched = (_value: number | undefined): void => {};

  onInputNumberChange(): void {
    this.onTouched(this.timeSpan);
    this.onChanged(this.timeSpan);
  }
}
