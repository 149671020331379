import { createSelector } from '@ngrx/store';
import { ZoneType } from 'core/dtos';
import {
  OccupancyStatus,
  PoisFilter,
  PoiType,
  VehicleFilter,
  VehicleLoadStatus,
  VehicleMaintenanceStatus,
  VehicleMode,
  VehicleStatus,
  ZoneFilter,
} from 'core/models';

import * as fromMapsFeatureState from '../reducers';
import * as fromFilters from '../reducers/filters.reducer';
import { getBackwardsCompatibleValue, getValuesFromEnum } from './filters.selectors.helpers';

const selectFiltersState = createSelector(
  fromMapsFeatureState.getMapsFeatureState,
  fromMapsFeatureState.getFiltersState
);

export const selectFiltersLoaded = createSelector(selectFiltersState, fromFilters.getLoaded);

export const selectFilters = createSelector(
  selectFiltersState,
  ({ vehicles, pois, zones, maps, trafficManagement }) => ({
    vehicles: {
      ...vehicles,
      mode: getBackwardsCompatibleValue(vehicles.mode, getValuesFromEnum(VehicleMode)),
      status: getBackwardsCompatibleValue(vehicles.status, getValuesFromEnum(VehicleStatus)),
      load: getBackwardsCompatibleValue(vehicles.load, getValuesFromEnum(VehicleLoadStatus)),
      maintenance: getBackwardsCompatibleValue(
        vehicles.maintenance,
        getValuesFromEnum(VehicleMaintenanceStatus)
      ),
    } as VehicleFilter,
    pois: {
      ...pois,
      occupancy: getBackwardsCompatibleValue(pois.occupancy, getValuesFromEnum(OccupancyStatus)),
      type: getBackwardsCompatibleValue(pois.type, getValuesFromEnum(PoiType)),
    } as PoisFilter,
    zones: {
      ...zones,
      type: getBackwardsCompatibleValue(zones.type, getValuesFromEnum(ZoneType)),
    } as ZoneFilter,
    maps,
    trafficManagement,
  })
);

export const selectVehicleFilters = createSelector(
  selectFilters,
  ({ vehicles }): VehicleFilter => vehicles
);
export const selectPoisFilters = createSelector(selectFilters, ({ pois }): PoisFilter => pois);
export const selectZoneFilters = createSelector(selectFilters, ({ zones }): ZoneFilter => zones);

export const selectMapsFilters = createSelector(selectFiltersState, fromFilters.getMapsFilters);
export const selectTrafficManagementFilters = createSelector(
  selectFiltersState,
  fromFilters.getTrafficManagementFilters
);
