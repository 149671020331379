import { Injectable, OnDestroy } from '@angular/core';
import { DsNavigationBarComponent } from '@bmw-ds/components';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderNavbarIntegrationService implements OnDestroy {
  destroy$ = new Subject();
  navBar$: BehaviorSubject<DsNavigationBarComponent | undefined> = new BehaviorSubject<
    DsNavigationBarComponent | undefined
  >(undefined);

  setNavBar(navBar: DsNavigationBarComponent | undefined): void {
    this.navBar$.next(navBar);
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
