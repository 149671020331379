import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EMPTY_GUID } from 'core/constants';
import { EmulatorHostFeaturesDto } from 'core/dtos';
import { GuidString } from 'core/models';
import { ToolbarService } from 'core/services';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import * as fromSettings from 'store-modules/settings-store';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-working-area-developer-settings-container',
  templateUrl: './working-area-developer-settings-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkingAreaDeveloperSettingsComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  isEditMode$ = of(false);
  emulatorSettings$: Observable<EmulatorHostFeaturesDto>;
  selectedWorkingAreaId: GuidString = '';
  updateEmulatorSettings: EmulatorHostFeaturesDto = {
    enableAddingEmulators: false,
    speedFactor: 5,
    enableCompletePath: false,
    enableAwarenessField: false,
  };

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly toolbarService: ToolbarService
  ) {
    this.emulatorSettings$ = this.rootStore.pipe(select(fromSettings.selectEmulatorSettings));
  }

  async ngOnInit(): Promise<void> {
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));

    this.rootStore.dispatch(
      fromRoot.showHideEditToggle({
        isVisible: true,
      })
    );
    this.toolbarService.configureItems([]);

    this.selectedWorkingAreaId = await firstValueFrom(
      this.rootStore.pipe(select(fromRoot.selectSelectedWorkingAreaId))
    );

    this.rootStore.dispatch(
      fromSettings.loadEmulatorSettings({ workAreaId: this.selectedWorkingAreaId ?? EMPTY_GUID })
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onActivateEmulators(): void {
    this.rootStore.dispatch(
      fromSettings.activateEmulators({ workingAreaId: this.selectedWorkingAreaId })
    );
  }

  onDeactivateEmulators(): void {
    this.rootStore.dispatch(
      fromSettings.deactivateEmulators({ workingAreaId: this.selectedWorkingAreaId })
    );
  }

  onSetEmulatorSettings(emulatorSettings: EmulatorHostFeaturesDto): void {
    this.updateEmulatorSettings = emulatorSettings;
    this.rootStore.dispatch(
      fromSettings.updateEmulatorSettings({
        emulatorFeature: this.updateEmulatorSettings,
        workAreaId: this.selectedWorkingAreaId,
      })
    );
  }
}
