/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-magic-numbers */

import { Coordinate } from 'core/dtos';
import { Pose2D, Trailer } from 'core/models';
import { DevColors, VehicleColors } from 'library/styles';
import { Container, Graphics, LINE_CAP, Sprite, Texture } from 'pixi.js';
import { MapIconAnchor, MapScale } from '../../map-layer.constant';
import { TrailerDimensions } from '../vehicle-dimensions.model';
import { TuggerTrailerStyle } from '../vehicle-layer.constant';

export class TrailerGraphic extends Container {
  private readonly showZeroPoint = false;
  private readonly useSprite = true;

  constructor(
    trailer: Trailer,
    private readonly texture: Texture,
    readonly dimensions: TrailerDimensions | undefined
  ) {
    super();

    this.scale.set(MapScale.MeterToCm, -MapScale.MeterToCm);

    if (dimensions) this.createTrailer(trailer, dimensions);
  }

  protected createTrailer(trailer: Trailer, dimensions: TrailerDimensions): void {
    const length = dimensions.length;
    const width = dimensions.width;

    let body: Graphics | Sprite;
    const center = length / 2;
    const pivotX = dimensions.origin ? dimensions.origin : center;

    if (this.useSprite) {
      const body = new Sprite(this.texture);

      body.width = length;
      body.height = width;

      body.anchor.set(pivotX / length, MapIconAnchor.Middle); // Divide by length to get % of total length
      this.addChild(body);
    } else {
      body = new Graphics()
        .beginFill(VehicleColors.Trailer)
        .drawRoundedRect(-pivotX, -width / 2, length, width, TuggerTrailerStyle.Corner)
        .endFill();
      this.addChild(body);
    }

    this.addChild(this.createDrawBar(dimensions, pivotX));

    if (this.showZeroPoint) {
      this.addChild(
        new Graphics().beginFill(DevColors.PivotPointGreen).drawCircle(0, 0, 5).endFill()
      );
    }

    this.position.set(trailer.trailerPosition.x, trailer.trailerPosition.y);
    this.rotation = trailer.trailerPosition.orientation ?? trailer.trailerPosition.theta ?? 0;
  }

  private createDrawBar(dimensions: TrailerDimensions, trailerOrigin: number): Graphics {
    const from: Coordinate = { x: dimensions.length - trailerOrigin, y: 0 };
    const to: Coordinate = { x: from.x + dimensions.barLength, y: 0 };

    return new Graphics()
      .lineTextureStyle({
        cap: LINE_CAP.ROUND,
        color: TuggerTrailerStyle.DrawBarColor,
        width: TuggerTrailerStyle.DrawBarWidth,
      })
      .moveTo(from.x, from.y)
      .lineTo(to.x, to.y);
  }

  updateTrailer(position: Pose2D): void {
    this.position.copyFrom(position);
    this.rotation = position.orientation;
  }
}
