import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { FleetDto } from 'core/dtos';
import { GuidString, NewFleetInput } from 'core/models';

export enum FleetTypes {
  LoadFleets = '[Vehicles] Load Fleets',
  LoadFleetsSuccess = '[Vehicles] Load Fleets Success',
  LoadFleetsFailure = '[Vehicles] Load Fleets Failure',

  AddFleet = '[Vehicles] Add Fleet',
  AddFleetSuccess = '[Vehicles] Add Fleet Success',
  AddFleetFailure = '[Vehicles] Add Fleet Failure',

  UpdateFleet = '[Vehicles] Update Fleet',
  UpdateFleetSuccess = '[Vehicles] Update Fleet Success',
  UpdateFleetFailure = '[Vehicles] Update Fleet Failure',

  UngroupFleet = '[Vehicles] Ungroup Fleet',
  UngroupFleetSuccess = '[Vehicles] Ungroup Fleet Success',
  UngroupFleetFailure = '[Vehicles] Ungroup Fleet Failure',

  SelectFleet = '[Vehicles] Select Fleet',
}

export const loadFleets = createAction(FleetTypes.LoadFleets);

export const loadFleetsSuccess = createAction(
  FleetTypes.LoadFleetsSuccess,
  props<{ fleets: FleetDto[] }>()
);
export const loadFleetsFailure = createAction(
  FleetTypes.LoadFleetsFailure,
  props<{ errorMessage: string }>()
);

export const selectFleet = createAction(FleetTypes.SelectFleet, props<{ fleetId: GuidString }>());

export const addFleet = createAction(FleetTypes.AddFleet, props<{ newFleet: NewFleetInput }>());

export const addFleetSuccess = createAction(
  FleetTypes.AddFleetSuccess,
  props<{ newFleet: FleetDto }>()
);

export const addFleetFailure = createAction(
  FleetTypes.AddFleetFailure,
  props<{ errorMessage: string }>()
);

export const updateFleet = createAction(FleetTypes.UpdateFleet, props<{ fleet: FleetDto }>());

export const updateFleetSuccess = createAction(
  FleetTypes.UpdateFleetSuccess,
  props<{ fleet: Update<FleetDto> }>()
);

export const updateFleetFailure = createAction(
  FleetTypes.UpdateFleetFailure,
  props<{ errorMessage: string }>()
);

export const ungroupFleet = createAction(
  FleetTypes.UngroupFleet,
  props<{ fleetWithVehicles: FleetDto }>()
);

export const ungroupFleetSuccess = createAction(
  FleetTypes.UngroupFleetSuccess,
  props<{ fleetWithVehicles: FleetDto }>()
);

export const ungroupFleetFailure = createAction(
  FleetTypes.UngroupFleetFailure,
  props<{ errorMessage: string }>()
);
