import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  getMissionErrorHandlingDefaultsDto,
  MissionErrorHandlingDefaultsDto,
  OrderConfirmationOption,
} from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { ErrorHandlingOptions } from 'core/models';

@Component({
  selector: 'app-mission-error-handling-settings-modal',
  templateUrl: './mission-error-handling-settings-modal.component.html',
  styleUrls: ['mission-error-handling-settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionErrorHandlingSettingsModalComponent implements OnChanges {
  @Input() isModalOpen = false;
  @Input() missionErrorHandlingDefaultsData?: MissionErrorHandlingDefaultsDto;
  @Output() readonly confirm = new EventEmitter<MissionErrorHandlingDefaultsDto>();
  @Output() readonly dismissModal = new EventEmitter<void>();

  viewModel: MissionErrorHandlingDefaultsDto = getMissionErrorHandlingDefaultsDto();
  sapConfirmationAfterAbort = OrderConfirmationOption;
  errorHandlingOptions = ErrorHandlingOptions;

  ngOnChanges(): void {
    this.createViewModel();
  }

  createViewModel(): void {
    this.viewModel =
      objectHelper.cloneDeep(this.missionErrorHandlingDefaultsData) || this.viewModel;
  }

  onClose(): void {
    this.dismissModal.emit();
  }

  onConfirm(): void {
    this.confirm.emit(this.viewModel);
  }
}
