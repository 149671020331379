<app-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></app-menu>
<p-treeTable
  dataKey="id"
  data-cy="treeTable"
  filterMode="strict"
  selectionMode="single"
  [value]="viewModel"
  [(selection)]="selectedNodes"
  [columns]="columns"
  [reorderableColumns]="reorderableColumns"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  [customSort]="true"
  [rowTrackBy]="rowTrackBy"
  [rowHover]="true"
  [scrollable]="true"
  scrollDirection="both"
  [loading]="isLoading"
  [showLoader]="false"
  [scrollHeight]="'flex'"
  [paginator]="hasPaginator"
  [rows]="10"
  [totalRecords]="totalNumberOfRecordsPaginator"
  [lazy]="lazyLoad"
  (onPage)="paginate($event)"
  (sortFunction)="customSort($event)"
  (onColReorder)="onColReorder()"
  (onNodeExpand)="onNodeExpand($event)"
  (onNodeCollapse)="onNodeCollapse()"
  (onFilter)="onFilter($event)"
  (onSort)="onDefaultSort()">
  <ng-template pTemplate="header">
    <tr>
      <th
        *ngFor="let col of columns"
        [ttSortableColumn]="col.field"
        [style.display]="isTableConfigurationModeActive || !col.isHidden ? '' : 'none'"
        [style.width.px]="col.size"
        [ds-tooltip]="translationContext + '.list.' + col.field | translate"
        [dsTooltipConfig]="{ disabled: tooltipDisabled }"
        ttReorderableColumn>
        <div>
          <span *ngIf="col.field">
            {{ translationContext + '.list.' + col.field | translate }}
          </span>

          <div class="alignRight">
            <div *ngIf="isTableConfigurationModeActive">
              <span
                [ngClass]="col.isHidden ? 'iwp-icon-gen_hide' : 'iwp-icon-gen_show_all'"
                class="icon-toolbar-button-eye"
                (click)="hideColumn($event, col)"></span>
            </div>
            <p-treeTableSortIcon *ngIf="showSortIcons" [field]="col.field"> </p-treeTableSortIcon>
          </div>
        </div>
      </th>

      <th class="actionMenuHeader" *ngIf="showActionMenuColumn">
        <span>{{ 'shared.treeTable.actionMenu.actionMenuHeader' | translate }}</span>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr
      [ttSelectableRow]="rowNode"
      (click)="onNodeSelect(rowNode.node)"
      [ngClass]="{
        childRow: rowNode.level > 0,
        fakeParentRow:
          rowNode.node.data.id === FAKE_PARENT_ID || rowNode.node.data.id === FAKE_PARENT_ID2
      }">
      <td
        *ngFor="let col of columns; first as isFirst; last as isLast"
        [style.width.px]="col.size"
        [style.display]="isTableConfigurationModeActive || !col.isHidden ? '' : 'none'">
        <div
          [ngClass]="{
            'container-last': isLast,
            'child-row-first-column': rowNode.node.leaf && isFirst && showTreeTableToggler,
            parent: !rowNode.node.leaf
          }">
          <p-treeTableToggler
            *ngIf="isFirst && showTreeTableToggler && !rowNode.node.leaf"
            [rowNode]="rowNode"></p-treeTableToggler>

          <ng-container
            *ngTemplateOutlet="
              columnItemTemplate;
              context: {
                $implicit: rowData,
                isFirst: isFirst,
                isLeaf: rowNode.node.leaf,
                isLast: isLast,
                field: col.field,
                type: col.type,
                icon: col.icon,
                searchTerm: searchTerm
              }
            "></ng-container>
        </div>
      </td>

      <td class="actionMenuCell" *ngIf="showActionMenuColumn">
        <div class="center" *ngIf="showActionMenu && isLeaf(rowNode.node)">
          <i
            data-cy="actionMenu"
            class="pi pi-ellipsis-h"
            (click)="onActionMenuClick($event, rowNode.node)"></i>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <ng-container *ngIf="isLoading; else noContent">
      <tr *ngFor="let fake of [].constructor(20)">
        <td *ngFor="let col of columns" [style.width.px]="col.size">
          <span class="skeleton"></span>
        </td>
      </tr>
    </ng-container>

    <ng-template #noContent>
      <tr [ngStyle]="{ 'height.px': screenHeight }">
        <td class="tableNoContent" [attr.colspan]="columns.length">
          <div class="content-message-header tableNoContentHeading">
            {{ noContentHeadingKey | translate }}
          </div>
          <div class="content-message">
            {{ noContentMessageKey | translate }}
          </div>
        </td>
      </tr>
    </ng-template>
  </ng-template>

  <ng-template #defaultItemTemplate let-rowData let-field="field">
    <span [innerHTML]="rowData[field] | highlight : searchTerm"></span>
  </ng-template>
</p-treeTable>
