import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PoiGroupDto } from 'core/dtos';
import { EntityList, GuidString } from 'core/models';

import * as fromPoisFeatureState from '../reducers';
import * as fromPoiGroups from '../reducers/poi-groups.reducer';

const selectPoiGroupsState = createSelector(
  fromPoisFeatureState.getPoisFeatureState,
  fromPoisFeatureState.getPoiGroupsState
);

export const selectPoiGroupsLoading = createSelector(
  selectPoiGroupsState,
  fromPoiGroups.getPoiGroupsLoading
);
export const selectPoiGroupsLoaded = createSelector(
  selectPoiGroupsState,
  fromPoiGroups.getPoiGroupsLoaded
);
export const selectPoiGroupsErrorMessage = createSelector(
  selectPoiGroupsState,
  fromPoiGroups.getPoiGroupsErrorMessage
);
export const selectPoiGroupId = createSelector(
  selectPoiGroupsState,
  fromPoiGroups.getSelectedPoiGroupId
);
export const selectPoiGroupsEntities = createSelector(
  selectPoiGroupsState,
  fromPoiGroups.getPoiGroupsEntities
);
export const selectAllPoiGroups = createSelector(
  selectPoiGroupsState,
  fromPoiGroups.getAllPoiGroups
);

export const selectAllPoiGroupsByMapId = (
  mapId?: GuidString
): MemoizedSelector<object, PoiGroupDto[]> =>
  createSelector(selectAllPoiGroups, (poiGroups: PoiGroupDto[]) =>
    poiGroups.filter(poiGroup => poiGroup.mapId === mapId)
  );

export const selectPoiGroupsList = createSelector(selectAllPoiGroups, (poiGroups): EntityList[] =>
  poiGroups.map(({ id, name }) => ({ id, name }))
);
