import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngrx/store';
import { ensureStoreLoadedWithRefresh } from 'shared/helpers';
import * as fromVehicles from 'store-modules/vehicles-store';

@Injectable({
  providedIn: 'root',
})
export class VehiclesGuard implements CanActivate, CanActivateChild {
  constructor(private readonly vehiclesStore: Store<fromVehicles.VehiclesFeatureState>) {}

  canActivate(): boolean {
    ensureStoreLoadedWithRefresh(this.vehiclesStore, fromVehicles.loadVehicles());
    ensureStoreLoadedWithRefresh(this.vehiclesStore, fromVehicles.loadFactsheets());
    return true;
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
