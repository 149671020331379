import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES, EMPTY_GUID } from 'core/constants';
import { BaseZoneSetDto, ZoneSetDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import {
  GuidString,
  OpcUaDeviceReferenceDto,
  ZoneSetEditDescriptionInput,
  ZoneSetSetActiveInput,
} from 'core/models';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ZoneSetService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.ZoneSet;
  protected apiUrl = environment.Services.MapManager;

  descriptionUpdateSubject = new Subject<ZoneSetEditDescriptionInput>();
  descriptionUpdate$ = this.descriptionUpdateSubject.asObservable();

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getZoneSets(): Observable<ZoneSetDto[]> {
    return this.get<ZoneSetDto[]>(`${this.servicePath}/ZoneSetList`);
  }

  getAllZoneSetsAndZones(): Observable<ZoneSetDto[]> {
    return this.get<ZoneSetDto[]>(`${this.servicePath}/AllZoneSets`);
  }

  getZoneSetByZoneSetId(zoneSetId: GuidString): Observable<ZoneSetDto> {
    return this.get<ZoneSetDto>(`${this.servicePath}/${zoneSetId.toString()}`);
  }

  createEmptyZoneSet(zoneSet: BaseZoneSetDto): Observable<ZoneSetDto> {
    return this.post<ZoneSetDto>(this.servicePath, zoneSet);
  }

  createFullZoneSet(zoneSet: ZoneSetDto): Observable<ZoneSetDto> {
    return this.post<ZoneSetDto>(API_SERVICES.ZoneSet + '/createWithZones', zoneSet);
  }

  updateZoneSetDescription(input: ZoneSetEditDescriptionInput): void {
    this.descriptionUpdateSubject.next(input);
  }

  createZoneSetFromTemplate(
    fromZoneSetId: GuidString,
    zoneSet: BaseZoneSetDto
  ): Observable<ZoneSetDto> {
    return this.post<ZoneSetDto>(`${this.servicePath}/createFrom/${fromZoneSetId}`, zoneSet);
  }

  updateZoneSet(zoneSet: ZoneSetDto): Observable<ZoneSetDto> {
    return this.put<ZoneSetDto>(`${this.servicePath}`, zoneSet);
  }

  async deleteZoneSetsByMapId(mapId: GuidString): Promise<void> {
    return firstValueFrom(
      this.delete<void>({
        template: '{path}/maps/{mapId}',
        path: this.servicePath,
        mapId,
      })
    );
  }

  deleteZoneSet(zoneSetId: GuidString): Observable<GuidString> {
    return this.delete<GuidString>(`${this.servicePath}/${zoneSetId}`);
  }

  setActiveZoneSet(setToActiveInput: ZoneSetSetActiveInput): Observable<ZoneSetDto[]> {
    return this.put<ZoneSetDto[]>(`${this.servicePath}/setActiveZoneSet`, setToActiveInput);
  }

  getHasZoneOverlapsForZoneSet(
    zoneSetId: GuidString,
    mapId: GuidString = EMPTY_GUID
  ): Observable<boolean> {
    return this.get<boolean>(
      `${this.servicePath}/${zoneSetId.toString()}/hasOverlappingZones/${mapId.toString()}`
    );
  }

  getZonesWithDevice(device: string): Observable<OpcUaDeviceReferenceDto[]> {
    return this.get<OpcUaDeviceReferenceDto[]>(`${this.servicePath}/devices/${device}`);
  }
}
