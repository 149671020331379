<app-base-page-layout
  [pageHeading]="pageHeading"
  [secondaryPageHeading]="secondaryPageHeading"
  [hasToolbar]="true">
  <ng-container base-page-content>
    <div class="content-area">
      <ng-content select="[content]"></ng-content>
    </div>
  </ng-container>
</app-base-page-layout>
