<app-base-tool-bar data-cy="toolbar">
  <ng-container *ngFor="let item of actionItems$ | async; let first = first; trackBy: trackByKey">
    <ng-container [ngSwitch]="item.type" *ngIf="item.visible !== false && showItems !== false">
      <div
        *ngSwitchCase="ActionType.Button"
        [class.ps-2x]="!first"
        [ngClass]="{ 'action-item': item.hasSeparatorBorder }"
        [ds-tooltip]="item.disabledText || '' | translate"
        [dsTooltipConfig]="{ disabled: !item.disabled }">
        <button
          data-cy="actionButton"
          ds-button
          (click)="onButtonClick(item.command)"
          [icon]="item.icon"
          [disabled]="item.disabled"
          [attr.data-cy]="item.dataCyAttribute">
          {{ item.key | translate }}
        </button>
      </div>

      <div
        *ngSwitchCase="ActionType.ButtonSecondary"
        [ngClass]="{ 'action-item': item.hasSeparatorBorder }"
        [class.ps-2x]="!first"
        [ds-tooltip]="item.disabledText || '' | translate"
        [dsTooltipConfig]="{ disabled: !item.disabled }"
        data-cy="toolbar-secondary-button">
        <button
          ds-button
          [variant]="'outline'"
          [icon]="item.icon"
          (click)="onButtonClick(item.command)"
          [disabled]="item.disabled"
          [attr.data-cy]="item.dataCyAttribute">
          {{ item.key | translate }}
        </button>
      </div>

      <div
        *ngSwitchCase="ActionType.MenuButton"
        [ngClass]="{ 'action-item': item.hasSeparatorBorder }"
        [class.ps-2x]="!first">
        <app-menu-button
          [key]="item.key"
          label="{{ item.key | translate }}"
          [variant]="item.variant"
          [icon]="item.icon"
          [attr.data-cy]="item.dataCyAttribute">
          ></app-menu-button
        >
      </div>

      <div *ngSwitchCase="ActionType.Search" [class.ps-2x]="!first">
        <app-search-input
          *ngIf="showSearchBar$ | async"
          placeHolder="{{ item.key | translate }}"
          (search)="onSearch($event)">
        </app-search-input>
      </div>

      <div *ngSwitchCase="ActionType.Custom" [class.ps-2x]="!first">
        <ng-content></ng-content>
      </div>
    </ng-container>
  </ng-container>

  <app-edit-toggle
    *ngIf="isEditToggleVisible$ | async"
    [class.edit-toggle-separator]="showSeparator$ | async"></app-edit-toggle>
</app-base-tool-bar>
