import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ErrorAggregate, VehicleDto } from 'core/dtos';
import { MapItem, MapItemType, MissionTrace, Severity } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { ErrorAggregateSignalrService } from 'core/signalR/modules/error-aggregate-signalr.service';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationPanelViewModel } from 'shared/components/notification-panel/notification-panel.viewmodel';
import { ODataQueryBuilder, ensureStoreLoadedWithRefresh } from 'shared/helpers';
import { MapNavigationHelperService } from 'shared/services';
import * as fromErrors from 'store-modules/errors-store';
import * as fromMissions from 'store-modules/mission-monitoring-store';
import * as fromOpcuaDevices from 'store-modules/opcua-devices-store';
import * as fromVehicles from 'store-modules/vehicles-store';

@Component({
  selector: 'app-notification-panel-container',
  templateUrl: './notification-panel-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPanelContainerComponent implements OnInit, OnDestroy {
  @Input() openPanel = false;
  @Output() readonly sendHasNotifications = new EventEmitter<boolean>();
  @Output() readonly sendClosePanel = new EventEmitter<boolean>();

  allActiveMissions: MissionTrace[] = [];
  allVehicles$: Observable<VehicleDto[]> = of([]);
  initialNotification: NotificationPanelViewModel[] = [];
  notificationList: NotificationPanelViewModel[] = [];
  sendNotification = false;

  private readonly ngUnsubscribe = new Subject<void>();
  readonly errorAggregateSignalrSubscriber;

  constructor(
    private readonly actions$: Actions,
    private readonly errorStore: Store<fromErrors.ErrorsFeatureState>,
    private readonly vehiclesStore: Store<fromVehicles.VehiclesFeatureState>,
    private readonly missionStore: Store<fromMissions.MonitoringFeatureState>,
    private readonly opcuaDevicesStore: Store<fromOpcuaDevices.OpcuaDevicesFeatureState>,
    private readonly errorAggregateSignalRService: ErrorAggregateSignalrService,
    private readonly atsTranslationService: AtsTranslationService,
    private readonly mapNavigationHelperService: MapNavigationHelperService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.errorAggregateSignalrSubscriber =
      this.errorAggregateSignalRService.signalrSubscriberFactory(
        NotificationPanelContainerComponent.name
      );
  }

  ngOnInit(): void {
    this.dispatchActionsAndQuerySelectors();
    this.loadErrorAggregates();
    void this.errorAggregateSignalrSubscriber.joinErrorAggregateGroup();
  }

  ngOnDestroy(): void {
    void this.errorAggregateSignalrSubscriber.leaveErrorAggregateGroup();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadErrorAggregates(): void {
    const queryBuilder = new ODataQueryBuilder('occurredDateTimeUtc', 'desc');
    const httpParams = queryBuilder.getHttpParams();
    ensureStoreLoadedWithRefresh(this.errorStore, fromErrors.loadErrors({ httpParams }));
  }

  dispatchActionsAndQuerySelectors(): void {
    this.missionStore
      // TODO SERVATS-38029 Remove old endpoints
      .pipe(select(fromMissions.selectActiveMissions2), takeUntil(this.ngUnsubscribe))
      .subscribe(missions => {
        this.allActiveMissions = missions;
        this.notificationList = this.notificationList.map(notification => {
          return {
            ...notification,
            missionName: this.allActiveMissions.find(
              mission => mission.vehicleId === notification.id
            )?.missionName,
          };
        });
        this.cdRef.markForCheck();
      });

    this.errorStore
      .pipe(select(fromErrors.selectAllErrorsWithErrorForwarding), takeUntil(this.ngUnsubscribe))
      .subscribe(errors => {
        if (errors.length > 0) {
          this.buildNotificationList(errors);
        } else {
          this.notificationList = this.notificationList.filter(n => n.originArea !== 'Vehicles');
          this.cdRef.markForCheck();
        }

        const hasNewNotifications = this.notificationList.some(n => n.isNew);
        this.sendHasNotifications.emit(hasNewNotifications);
        this.sendNotification = false;
      });

    this.allVehicles$ = this.vehiclesStore.pipe(select(fromVehicles.selectAllVehicles));

    this.opcuaDevicesStore
      .pipe(select(fromOpcuaDevices.selectAllErrors), takeUntil(this.ngUnsubscribe))
      .subscribe(errors => {
        if (errors.length > 0) {
          for (const error of errors) {
            const existingError = this.notificationList.find(n => n.id === error.id);
            if (existingError) {
              error.isNew = existingError.isNew;
            }
          }

          this.notificationList = this.notificationList.filter(
            n => n.originArea !== 'OpcuaDevices'
          );
          this.notificationList.push(...errors);
          this.translateNotificationItems();

          const hasNewNotifications = this.notificationList.some(n => n.isNew);
          this.sendHasNotifications.emit(hasNewNotifications);
        } else {
          this.notificationList = this.notificationList.filter(
            n => n.originArea !== 'OpcuaDevices'
          );
        }
        this.cdRef.markForCheck();
      });

    this.actions$
      .pipe(
        ofType(fromOpcuaDevices.signalROpcuaDeviceNotificationTriggered),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(o => {
        this.sendHasNotifications.emit(true);
        this.notificationList = [
          ...this.notificationList,
          {
            name: o.notification.device,
            description: `${o.notification.node} = ${o.notification.value}`,
            descriptionTranslationKey: undefined,
            location: '',
            isNew: true,
            level: 'error',
            errors: [],
            occurredDateTimeUtc: new Date().toUTCString(),
            id: o.notification.device + o.notification.node,
            originArea: 'OpcuaNotifications',
          },
        ];
        this.cdRef.markForCheck();
      });

    this.actions$
      .pipe(
        ofType(fromOpcuaDevices.signalROpcuaDeviceNotificationResolved),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(n => {
        this.notificationList = this.notificationList.filter(
          o => o.id !== n.notification.device + n.notification.node
        );
        this.cdRef.markForCheck();

        const hasNewNotifications = this.notificationList.some(n => n.isNew);
        this.sendHasNotifications.emit(hasNewNotifications);
      });

    this.atsTranslationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateNotificationItems();
    });
  }

  private translateNotificationItems(): void {
    this.notificationList.forEach(n => {
      if (n.descriptionTranslationKey !== undefined) {
        n.description = this.atsTranslationService.get(n.descriptionTranslationKey, n);
      }
      this.notificationList = [...this.notificationList];
      this.cdRef.markForCheck();
    });
  }

  buildNotificationList(errors: ErrorAggregate[]): void {
    const initialValue: NotificationPanelViewModel[] = [];
    const notificationList = errors.reduce((notifications, error) => {
      const index = notifications.findIndex(not => not.id === error.vehicleId);

      if (index > -1) {
        notifications[index].errors.push({
          type: error.type,
          description: error.description,
        });

        if (
          Severity[error.level] === Severity.fatal ||
          (Severity[notifications[index].level] !== Severity.fatal &&
            Severity[error.level] !== Severity.info)
        ) {
          notifications[index].description = error.description;
        }

        notifications[index] = { ...notifications[index], isNew: true };
        notifications[index] = { ...this.resetNotificationStatus(notifications[index], error) };
        return notifications;
      } else {
        const notificationItem = this.generateViewModel(error);
        return [...notifications, { ...this.resetNotificationStatus(notificationItem, error) }];
      }
    }, initialValue);

    this.notificationList = this.notificationList.filter(n => n.originArea !== 'Vehicles');
    this.notificationList.push(...notificationList);
    this.notificationList = [...this.notificationList];
    this.cdRef.markForCheck();
  }

  generateViewModel(error: ErrorAggregate): NotificationPanelViewModel {
    const mission = this.allActiveMissions.find(m => m.vehicleId === error.vehicleId);
    return {
      name: error.vehicleName,
      location: error.location,
      missionName: mission?.missionName || '',
      description: error.description,
      id: error.vehicleId,
      isNew: true,
      occurredDateTimeUtc: error.occurredDateTimeUtc,
      dueTime: mission?.provisioningTime?.toString() ?? undefined,
      errors: [...[], { type: error.type, description: error.description }],
      level: error.level,
      originArea: 'Vehicles',
    };
  }

  resetNotificationStatus(
    newErrorNotification: NotificationPanelViewModel,
    error: ErrorAggregate
  ): NotificationPanelViewModel {
    const vehicleExistingNotificationItem = this.notificationList.find(
      not => not.id === newErrorNotification.id
    );
    this.sendNotification = true;
    if (
      vehicleExistingNotificationItem &&
      vehicleExistingNotificationItem.errors.find(err => err.type === error.type)
    ) {
      newErrorNotification = {
        ...newErrorNotification,
        isNew: vehicleExistingNotificationItem.isNew,
      };
      this.sendNotification = false;
    }
    return newErrorNotification;
  }

  closePanel(notification?: NotificationPanelViewModel): void {
    this.openPanel = false;
    this.sendClosePanel.emit(!this.openPanel);
    this.cdRef.markForCheck();
    if (notification) {
      const index = this.notificationList.findIndex(n => n.id === notification.id);
      this.notificationList[index].isNew = false;
      this.notificationList = [...this.notificationList];
      this.cdRef.markForCheck();
      const hasNewNotifications = this.notificationList.some(n => n.isNew);
      this.sendHasNotifications.emit(hasNewNotifications);
    }
  }

  onSelectItem(vehicle: VehicleDto): void {
    const mapId = vehicle?.map?.id ?? '';
    const item: MapItem = {
      id: vehicle.id.toString(),
      position: {
        x: vehicle.pose2D.x,
        y: vehicle.pose2D.y,
      },
      type: MapItemType.Vehicle,
    };
    this.mapNavigationHelperService.navigateToMapWithSelection(item, mapId);
  }
}
