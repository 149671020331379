import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  OpcuaDeviceNodeTelemetrySignalR,
  OpcuaDeviceNotification,
  OpcuaDeviceOnlineStatusChangedDto,
} from 'core/dtos';
import { SignalRNextMessage } from 'core/models';
import { SignalRNextService } from 'core/signalR/signalr-next.service';
import { Subject } from 'rxjs';
import { OpcuaDevicesFeatureState } from 'store-modules/opcua-devices-store';

import { SignalrRoutes } from '../signalr-routes';
import {
  opcuaDeviceNotificationMessageReceived,
  opcuaDeviceOnlineStatusMessageReceived,
  opcuaNodeTelemetryMessageReceived,
} from '../store/actions/signalr.actions';

export interface OpcuaDeviceSignalrSubscriber {
  joinTelemetryGroup(): Promise<void>;
  joinNotificationsGroup(): Promise<void>;
  joinOnlineStatusGroup(): Promise<void>;
}
@Injectable({
  providedIn: 'root',
})
export class OpcuaDeviceSignalrService {
  deviceNodeTelemetryMessageReceived = new Subject<
    SignalRNextMessage<OpcuaDeviceNodeTelemetrySignalR>
  >();
  deviceOnlineStatusMessageReceived = new Subject<
    SignalRNextMessage<OpcuaDeviceOnlineStatusChangedDto>
  >();
  deviceNotificationMessageReceived = new Subject<SignalRNextMessage<OpcuaDeviceNotification>>();

  constructor(
    private readonly signalRService: SignalRNextService,
    private readonly store: Store<OpcuaDevicesFeatureState>
  ) {
    this.registerConnections();
  }
  signalrSubscriberFactory(componentName: string): OpcuaDeviceSignalrSubscriber {
    const joinTelemetryGroup = (): Promise<void> => {
      return this.signalRService.joinGroup(SignalrRoutes.OpcuaDevicesNodeTelemetry, componentName);
    };

    const joinNotificationsGroup = (): Promise<void> => {
      return this.signalRService.joinGroup(SignalrRoutes.OpcuaDevicesNotifications, componentName);
    };

    const joinOnlineStatusGroup = (): Promise<void> => {
      return this.signalRService.joinGroup(SignalrRoutes.OpcuaDevicesOnlineStatus, componentName);
    };

    return { joinTelemetryGroup, joinNotificationsGroup, joinOnlineStatusGroup };
  }

  protected registerConnections(): void {
    this.signalRService.registerConnectionNext(
      SignalrRoutes.OpcuaDevicesNodeTelemetry,
      this.deviceNodeTelemetryMessageReceived
    );

    this.deviceNodeTelemetryMessageReceived.subscribe(telemetryMessage => {
      this.store.dispatch(opcuaNodeTelemetryMessageReceived({ telemetryMessage }));
    });

    this.signalRService.registerConnectionNext(
      SignalrRoutes.OpcuaDevicesOnlineStatus,
      this.deviceOnlineStatusMessageReceived
    );

    this.deviceOnlineStatusMessageReceived.subscribe(statusMessage => {
      this.store.dispatch(opcuaDeviceOnlineStatusMessageReceived({ statusMessage }));
    });

    this.signalRService.registerConnectionNext(
      SignalrRoutes.OpcuaDevicesNotifications,
      this.deviceNotificationMessageReceived
    );

    this.deviceNotificationMessageReceived.subscribe(notification => {
      this.store.dispatch(opcuaDeviceNotificationMessageReceived({ notification }));
    });
  }
}
