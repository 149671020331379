import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as VehiclesEffects from './effects';
import * as fromVehicles from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(fromVehicles.featureKey, fromVehicles.reducers),
    EffectsModule.forFeature(VehiclesEffects.effects),
  ],
})
export class VehiclesStoreModule {}
