/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouteConfigurationService } from 'core/api-services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions';
import * as RouteConfigurationActions from '../actions/route-configuration.actions';

@Injectable()
export class RouteConfigurationEffects {
  loadRouteConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteConfigurationActions.loadRouteConfigurations),
      concatMap(() =>
        this.routeConfigurationService.getAllRouteConfigurations().pipe(
          map(routeConfigurations => {
            return RouteConfigurationActions.loadRouteConfigurationsSuccess({
              routeConfigurations: routeConfigurations,
            });
          }),
          catchError(errorMessage =>
            of(RouteConfigurationActions.loadRouteConfigurationsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadRouteConfigurationsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouteConfigurationActions.loadRouteConfigurationsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.loadRoutesFailure');
        })
      ),
    { dispatch: false }
  );

  createRouteConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteConfigurationActions.createRouteConfiguration),
      concatMap(({ routeConfig }) =>
        this.routeConfigurationService.createRouteConfiguration(routeConfig).pipe(
          map(routeConfig =>
            RouteConfigurationActions.createRouteConfigurationSuccess({ routeConfig })
          ),
          catchError(errorMessage =>
            of(RouteConfigurationActions.createRouteConfigurationFailure({ errorMessage }))
          )
        )
      )
    )
  );

  createRouteConfigurationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteConfigurationActions.createRouteConfigurationSuccess),
      tap(({ routeConfig }) => {
        this.toastService.createSuccessToast('shared.actions.created', {
          name: routeConfig.name,
        });
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: true })])
    )
  );

  createRouteConfigurationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouteConfigurationActions.createRouteConfigurationFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  updateRouteConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteConfigurationActions.updateRouteConfiguration),
      concatMap(({ routeConfig }) =>
        this.routeConfigurationService.updateRouteConfiguration(routeConfig).pipe(
          map(routeConfig =>
            RouteConfigurationActions.updateRouteConfigurationSuccess({ routeConfig })
          ),
          catchError(errorMessage =>
            of(RouteConfigurationActions.updateRouteConfigurationFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateRouteConfigurationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteConfigurationActions.updateRouteConfigurationSuccess),
      tap(({ routeConfig }) => {
        this.toastService.createSuccessToast('shared.actions.updated', {
          name: routeConfig.name,
        });
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: true })])
    )
  );

  updateRouteConfigurationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouteConfigurationActions.updateRouteConfigurationFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  updateBatchRouteConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteConfigurationActions.updateBatchRouteConfiguration),
      concatMap(({ routeConfigList }) =>
        this.routeConfigurationService.updateBatchRouteConfiguration(routeConfigList).pipe(
          map(routeConfigList =>
            RouteConfigurationActions.updateBatchRouteConfigurationSuccess({ routeConfigList })
          ),
          catchError(errorMessage =>
            of(RouteConfigurationActions.updateBatchRouteConfigurationFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateBatchRouteConfigurationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteConfigurationActions.updateBatchRouteConfigurationSuccess),
      tap(() => {
        this.toastService.createSuccessToast('shared.actions.baseRouteUpdated', {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateBatchRouteConfigurationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouteConfigurationActions.updateBatchRouteConfigurationFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  deleteRouteConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteConfigurationActions.deleteRouteConfiguration),
      concatMap(state =>
        this.routeConfigurationService.deleteRouteConfiguration(state.routeConfig).pipe(
          map(() =>
            RouteConfigurationActions.deleteRouteConfigurationSuccess({
              routeConfig: state.routeConfig,
            })
          ),
          catchError(errorMessage =>
            of(RouteConfigurationActions.deleteRouteConfigurationFailure({ errorMessage }))
          )
        )
      )
    )
  );

  deleteRouteConfigurationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteConfigurationActions.deleteRouteConfigurationSuccess),
      tap(({ routeConfig }) => {
        this.toastService.createSuccessToast('shared.actions.deleted', {
          name: routeConfig.name,
        });
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: true })])
    )
  );

  deleteRouteConfigurationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouteConfigurationActions.deleteRouteConfigurationFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly routeConfigurationService: RouteConfigurationService,
    private readonly toastService: ToastService
  ) {}
}
