/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SoftwareManagementService } from 'core/api-services';
import { HardwareVersion } from 'core/models';
import { ToastService } from 'core/services/toast.service';

import { forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import * as SoftwareUpdateActions from '../actions/software-update.actions';

@Injectable()
export class SoftwareUpdateEffects {
  loadSoftwareUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SoftwareUpdateActions.loadSoftwareUpdates),
      concatMap(({ hardwareVersion }) =>
        this.softwareManagementService.getSoftwareUpdates(hardwareVersion).pipe(
          map(softwareUpdates =>
            SoftwareUpdateActions.loadSoftwareUpdatesSuccess({ softwareUpdates })
          ),
          catchError(errorMessage =>
            of(SoftwareUpdateActions.loadSoftwareUpdatesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  retrySoftwareUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SoftwareUpdateActions.retrySoftwareUpdate),
        concatMap(({ vehicleId }) =>
          this.softwareManagementService.retryUpdate(vehicleId).pipe(
            tap(({ success }) => {
              if (success) {
                this.toastService.createSuccessToast(
                  'vehicles.modalSendSoftwareUpdate.retrySuccess'
                );
              } else {
                this.toastService.createErrorToast('vehicles.modalSendSoftwareUpdate.retryFailure');
              }
            }),
            catchError(errorMessage =>
              of(SoftwareUpdateActions.retrySoftwareUpdateFailure({ errorMessage }))
            )
          )
        )
      ),
    { dispatch: false }
  );

  loadAllSoftwareUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SoftwareUpdateActions.loadAllSoftwareUpdates),
      concatMap(() => {
        const supportedHwVersions = [HardwareVersion.StrVersion4];

        const apiCalls = supportedHwVersions.map(hardwareVersion =>
          this.softwareManagementService.getSoftwareUpdates(hardwareVersion)
        );
        return forkJoin(apiCalls).pipe(
          map(allSwUpdates =>
            SoftwareUpdateActions.loadAllSoftwareUpdatesSuccess({
              softwareUpdates: allSwUpdates.flat(),
            })
          ),
          catchError(errorMessage =>
            of(SoftwareUpdateActions.loadAllSoftwareUpdatesFailure({ errorMessage }))
          )
        );
      })
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SoftwareUpdateActions.loadAllSoftwareUpdatesFailure),
        ofType(SoftwareUpdateActions.loadSoftwareUpdatesFailure),
        ofType(SoftwareUpdateActions.retrySoftwareUpdateFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly softwareManagementService: SoftwareManagementService
  ) {}
}
