import { Action, createReducer, on } from '@ngrx/store';

import * as UIActions from '../actions';

export const uiFeatureKey = 'ui';

export interface UIState {
  isEditToggleVisible: boolean;
  isEditMode: boolean;
  hasChanges: boolean;
  showSearchBar: boolean;
  isMassAbortMode: boolean;
  isMassAbortOrderMode: boolean;
  isMassUpdateMaintenanceMode: boolean;
  isMassCommentMode: boolean;
}

export const initialState: UIState = {
  isEditToggleVisible: false,
  isEditMode: false,
  hasChanges: false,
  showSearchBar: true,
  isMassAbortMode: false,
  isMassAbortOrderMode: false,
  isMassUpdateMaintenanceMode: false,
  isMassCommentMode: false,
};

const uiReducer = createReducer(
  initialState,

  on(UIActions.showHideEditToggle, (state, { isVisible }) => ({
    ...state,
    isEditToggleVisible: isVisible,
  })),

  on(UIActions.setIsEditMode, (state, { isEditMode }) => ({
    ...state,
    isEditMode: isEditMode,
  })),
  on(UIActions.setHasChanges, (state, { hasChanges }) => ({
    ...state,
    hasChanges: hasChanges,
  })),
  on(UIActions.showSearchBar, (state, { isVisible }) => ({
    ...state,
    showSearchBar: isVisible,
  })),
  on(UIActions.setIsMassAbortMode, (state, { isMassAbortMode }) => ({
    ...state,
    isMassAbortMode: isMassAbortMode,
  })),
  on(UIActions.setIsMassAbortOrderMode, (state, { isMassAbortOrderMode }) => ({
    ...state,
    isMassAbortOrderMode: isMassAbortOrderMode,
  })),
  on(UIActions.setIsMassUpdateMaintenanceMode, (state, { isMassUpdateMaintenanceMode }) => ({
    ...state,
    isMassUpdateMaintenanceMode: isMassUpdateMaintenanceMode,
  })),
  on(UIActions.setIsMassCommentMode, (state, { isMassCommentMode }) => ({
    ...state,
    isMassCommentMode: isMassCommentMode,
  }))
);

export function reducer(state: UIState | undefined, action: Action): UIState {
  return uiReducer(state, action);
}

export const getIsEditMode = (state: UIState): boolean => state.isEditMode;
export const getHasChanges = (state: UIState): boolean => state.hasChanges;
export const getIsMassAbortMode = (state: UIState): boolean => state.isMassAbortMode;
export const getIsMassAbortOrderMode = (state: UIState): boolean => state.isMassAbortOrderMode;
export const getIsMassUpdateMaintenanceMode = (state: UIState): boolean =>
  state.isMassUpdateMaintenanceMode;
export const getIsMassCommentMode = (state: UIState): boolean => state.isMassCommentMode;
