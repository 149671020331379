import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/index';

@Injectable({ providedIn: 'root' })
export class TourRetryService extends TenantHttpClient {
  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  retryTour(id: GuidString): Observable<void> {
    return this.post<void>(`${API_SERVICES.TourRetry}/retry/${id}`);
  }
}
