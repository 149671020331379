import { createSelector, MemoizedSelector } from '@ngrx/store';
import { OrganizationDto } from 'core/dtos';
import { GuidString } from 'core/models';
import * as fromOrganizations from 'store/reducers/organizations.reducer';
import * as fromRoot from '../reducers/index';

export const selectOrganizationsLoading = createSelector(
  fromRoot.getOrganizationsState,
  fromOrganizations.getOrganizationsLoading
);

export const selectOrganizationsLoaded = createSelector(
  fromRoot.getOrganizationsState,
  fromOrganizations.getOrganizationsLoaded
);

export const selectOrganizationsErrorMessage = createSelector(
  fromRoot.getOrganizationsState,
  fromOrganizations.getOrganizationsErrorMessage
);

export const selectOrganizationsEntities = createSelector(
  fromRoot.getOrganizationsState,
  fromOrganizations.getOrganizationsEntities
);

export const selectAllOrganizations = createSelector(
  fromRoot.getOrganizationsState,
  fromOrganizations.getAllOrganizations
);

export const selectSelectedOrganizationId = createSelector(
  fromRoot.getOrganizationsState,
  fromOrganizations.getSelectedOrganizationId
);

export const selectSelectedOrganization = createSelector(
  selectSelectedOrganizationId,
  selectOrganizationsEntities,
  (organizationId, organizations) => organizations[String(organizationId)]
);

export const selectOrganizationByName = (
  organizationName: string
): MemoizedSelector<object, OrganizationDto | undefined> =>
  createSelector(selectAllOrganizations, (organizations: OrganizationDto[]) =>
    organizations.find(o => o.name === organizationName)
  );

export const selectOrganizationById = (
  organizationId: GuidString
): MemoizedSelector<object, OrganizationDto | undefined> =>
  createSelector(selectAllOrganizations, (organizations: OrganizationDto[]) =>
    organizations.find(o => o.id === organizationId)
  );
