import { NgModule } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { environment } from '@environment';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

const NO_CACHE = {
  query: {
    fetchPolicy: 'no-cache',
  },
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
} as const;

export function createApolloDefault(httpLink: HttpLink): ApolloClientOptions<unknown> {
  return {
    cache: new InMemoryCache(),
    link: httpLink.create({
      uri: `${environment.Services.Bff}/graphql`,
    }),
  };
}

const orgWaMiddleware = new ApolloLink((operation, forward) => {
  const ctx = operation.getContext();
  const slug: [string, string] = ctx.workingArea;
  // Tenant specific URI
  ctx.uri = `${environment.Services.MissionArchive}/${slug[0]}/${slug[1]}/graphql`;
  operation.setContext(ctx);
  return forward(operation);
});

export function createApollo(httpLink: HttpLink): Record<string, ApolloClientOptions<unknown>> {
  return {
    authClient: {
      cache: new InMemoryCache(),
      link: httpLink.create({
        uri: `${environment.Services.Auth}/graphql`,
      }),
    },
    missionArchiveClient: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        orgWaMiddleware,
        httpLink.create({
          uri: `${environment.Services.MissionArchive}/graphql`,
        }),
      ]),
      defaultOptions: NO_CACHE,
    },
    jobManagerClient: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        new ApolloLink((operation, forward) => {
          const ctx = operation.getContext();
          const slug: [string, string] = ctx.workingArea;
          // Tenant specific URI
          ctx.uri = `${environment.Services.JobManager}/${slug[0]}/${slug[1]}/graphql`;
          operation.setContext(ctx);
          return forward(operation);
        }),
        httpLink.create({
          uri: `${environment.Services.JobManager}/graphql`,
        }),
      ]),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApolloDefault,
      deps: [HttpLink],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
