import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import * as routerActions from '../actions';

@Injectable()
export class RouterEffects {
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.Go),
        map(action => action.payload),
        tap(async ({ path, query: queryParams, extras }) => {
          await this.router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  navigateBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.Back),
      tap(() => {
        this.location.back();
      })
    )
  );

  navigateForward$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.Forward),
      tap(() => {
        this.location.forward();
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly location: Location
  ) {}
}
