import { createSelector } from '@ngrx/store';
import { PoiGroup } from 'core/models';
import { selectAllMaps, selectMapId, selectMapsEntities } from 'store-modules/maps-store/selectors';

import {
  selectAllPoiGroups,
  selectPoiGroupId,
  selectPoiGroupsEntities,
} from './poi-groups.selectors';
import {
  createMapsList,
  createPoiGroupForMappingTable,
  createPoiGroupForNotificationMappingTable,
  createPoiGroupForTreeTable,
  createPoiGroupList,
} from './poi-groups.selectors.helper';
import { selectPoisBySelectedMapId, selectPoisWithMap } from './pois-extended.selectors';

export const selectPoiGroupsByMapId = createSelector(
  selectMapId,
  selectAllPoiGroups,
  (selectedMapId, poiGroups) =>
    selectedMapId ? poiGroups.filter(poiGroup => poiGroup.mapId === selectedMapId) : poiGroups
);

export const selectPoiGroupsForList = createSelector(
  selectAllPoiGroups,
  selectPoisWithMap,
  (poiGroups, pois) => createPoiGroupList(poiGroups, pois)
);

export const selectPoiGroupsForTreeTable = createSelector(
  selectMapId,
  selectPoiGroupsForList,
  (selectedMapId, poiGroups) => createPoiGroupForTreeTable(selectedMapId, poiGroups)
);

export const selectPoiGroupsForMappingTable = createSelector(
  selectPoiGroupsByMapId,
  selectPoisBySelectedMapId,
  (poiGroups, pois) => createPoiGroupForMappingTable(poiGroups, pois)
);

export const selectPoisForNotificationMappingTable = createSelector(
  selectPoiGroupsByMapId,
  selectPoisBySelectedMapId,
  (poiGroups, pois) => createPoiGroupForNotificationMappingTable(poiGroups, pois)
);

export const selectMapWithPoiGroups = createSelector(
  selectAllMaps,
  selectPoiGroupsForList,
  (maps, poiGroups) => createMapsList(maps, poiGroups)
);

export const selectSelectedPoiGroup = createSelector(
  selectPoiGroupId,
  selectPoiGroupsEntities,
  selectMapsEntities,
  (selectedPoiGroupId, poiGroups, maps): PoiGroup | undefined => {
    if (!poiGroups || Object.keys(poiGroups).length === 0 || !selectedPoiGroupId) {
      return undefined;
    }

    const poiGroup = poiGroups[selectedPoiGroupId.toString()];
    return poiGroup && { ...poiGroup, map: maps[poiGroup.mapId.toString()] };
  }
);
