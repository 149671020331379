import { Dictionary } from '@ngrx/entity';
import { MapDto, ProcessChainGroupDto } from 'core/dtos';
import { TableProcessChainGroup } from 'core/models';

export function convertToTableProcessChainGroup(
  processChainGroup: ProcessChainGroupDto,
  mapEntities: Dictionary<MapDto>
): TableProcessChainGroup {
  const tableProcessChainGroup: TableProcessChainGroup = {
    id: processChainGroup.id,
    name: processChainGroup.name,
    mapId: '',
    mapName: '',
    executionMode: processChainGroup.executionMode,
  };

  setMapInformation(tableProcessChainGroup, processChainGroup, mapEntities);
  return tableProcessChainGroup;
}

function setMapInformation(
  tableProcessChainGroup: TableProcessChainGroup,
  processChainGroup: ProcessChainGroupDto,
  mapEntities: Dictionary<MapDto>
): void {
  if (processChainGroup.mapId) {
    const map = mapEntities[String(processChainGroup.mapId)];
    tableProcessChainGroup.mapName = map ? map.name : '';
    tableProcessChainGroup.mapId = map ? map.id : '';
  }
}
