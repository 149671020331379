import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatVehicleDueDate',
  pure: false,
})
export class FormatVehicleDueDatePipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (value === undefined || value === '' || value === null) {
      return '';
    }
    const date = new Date(value);
    const msec = date.getTime() - new Date().getTime();
    const divisor = 60000;
    return Math.floor(msec / divisor).toString() + ' min';
  }
}
