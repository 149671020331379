import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { VersionInformationDto } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class VersionService extends BaseHttpClient {
  protected apiUrl = environment.Services.FleetManager;

  getVersion(): Observable<VersionInformationDto> {
    return this.get<VersionInformationDto>('/version');
  }
}
