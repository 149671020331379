import { Pipe, PipeTransform } from '@angular/core';
import { DateString } from 'core/models';
import { formatDate } from 'shared/helpers';

@Pipe({
  name: 'formatDateToday',
  pure: false,
})
export class FormatDateTodayPipe implements PipeTransform {
  transform(value: Date | DateString | undefined | null): string {
    if (!value) {
      return '';
    }

    return formatDate(value);
  }
}
