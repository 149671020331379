import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import {
  BaseVehicleDto,
  ErrorAggregate,
  ODataDto,
  VehicleDto,
  WakeOtherVehiclesDto,
} from 'core/dtos';
import { roundBatteryLevelOfVehicles } from 'core/helpers/vehicles.helper';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, Pose2D, ReducedVehicle } from 'core/models';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootState } from 'store/reducers';

import { API_SERVICES } from 'core/constants';
import { Params } from './../http/base-http-client';

@Injectable({
  providedIn: 'root',
})
export class VehicleService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.Vehicles;
  private readonly servicePathVehicleCommands = API_SERVICES.VehicleCommands;
  private readonly servicePathVehicleMaintenanceMode = API_SERVICES.VehicleMaintenanceMode;
  private readonly oDataErrorAggregatePath = '/odata/ErrorAggregate';

  protected apiUrl = environment.Services.FleetManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getVehicles(): Observable<VehicleDto[]> {
    return this.get<VehicleDto[]>(this.servicePath).pipe(
      map(vehicles => roundBatteryLevelOfVehicles(vehicles))
    );
  }

  getVehiclesByMapId(mapId: GuidString): Observable<VehicleDto[]> {
    return this.get<VehicleDto[]>(this.servicePath, { mapId: mapId.toString() });
  }

  getVehicleById(vehicleId: GuidString): Observable<VehicleDto> {
    return this.get<VehicleDto>(`${this.servicePath}/${vehicleId}`);
  }

  createVehicle(newVehicle: BaseVehicleDto): Observable<VehicleDto> {
    return this.post<VehicleDto>(this.servicePath, newVehicle);
  }

  updateVehicle(vehicle: ReducedVehicle): Observable<VehicleDto> {
    return this.put<VehicleDto>(`${this.servicePath}/${vehicle.id}`, vehicle);
  }

  deleteVehicle(vehicleId: GuidString): Observable<GuidString> {
    return this.delete<GuidString>(`${this.servicePath}/${vehicleId}`);
  }

  async deleteVehiclesByMapId(mapId: GuidString): Promise<GuidString> {
    return firstValueFrom(this.delete<GuidString>(`${this.servicePath}/maps/${mapId}`));
  }

  changeWorkingArea(vehicleId: GuidString, workingAreaId: GuidString): Observable<VehicleDto> {
    return this.put<VehicleDto>(`${this.servicePath}/move/${vehicleId}/${workingAreaId}`);
  }

  localizeVehicle(vehicleId: GuidString, pose: Pose2D): Observable<GuidString> {
    return this.post<GuidString>(
      `${this.servicePathVehicleCommands}/initialpose/${vehicleId}`,
      pose
    );
  }

  updateMaintenanceMode({
    id,
    maintenanceModeEnabled,
  }: {
    id: GuidString;
    maintenanceModeEnabled: boolean;
  }): Observable<GuidString> {
    const mode = maintenanceModeEnabled ? 'enable' : 'disable';
    return this.post<GuidString>(`${this.servicePathVehicleMaintenanceMode}/${mode}/${id}`);
  }

  massMaintenanceModeUpdate(vehicleIds: GuidString[]): Observable<void> {
    return this.post(
      `${this.servicePathVehicleMaintenanceMode}/massmaintenancemodeupdate`,
      vehicleIds
    );
  }

  updateErrorForwarding(vehicle: ReducedVehicle): Observable<GuidString> {
    return this.post<GuidString>(
      `${this.servicePath}/${vehicle.id}/updateerrorforwarding/${vehicle.isErrorForwardingEnabled}`
    );
  }

  resetVehicleKey(vehicleId: GuidString): Observable<string> {
    return this.post<string>(`${this.servicePath}/ResetVehicleKey/${vehicleId}`);
  }

  async getVehicleConnectionString(vehicleId: GuidString): Promise<string> {
    return firstValueFrom(this.get<string>(`${this.servicePath}/ConnectionString/${vehicleId}`));
  }

  getErrorAggregatesViaOData(params: Params): Observable<ODataDto<ErrorAggregate>> {
    return this.get<ODataDto<ErrorAggregate>>(this.oDataErrorAggregatePath, params);
  }

  getErrorsByVehicleId(vehicleId: GuidString): Observable<ODataDto<ErrorAggregate>> {
    return this.getErrorAggregatesViaOData({
      $filter: `vehicleid eq ${vehicleId}`,
      $select: 'vehicleId,description,level,type,occurredDateTimeUtc',
      $orderby: `occurredDateTimeUtc desc`,
      $top: 5,
    });
  }

  pauseVehicle(vehicleId: GuidString): Observable<void> {
    const options = { connectionTimeoutInSeconds: 5, responseTimeoutInSeconds: 8 };
    return this.post<void>(`${this.servicePathVehicleCommands}/pause/${vehicleId}`, options);
  }

  resumeVehicle(vehicleId: GuidString): Observable<void> {
    const options = { connectionTimeoutInSeconds: 5, responseTimeoutInSeconds: 8 };
    return this.post<void>(`${this.servicePathVehicleCommands}/resume/${vehicleId}`, options);
  }

  wakeOtherVehicles(): Observable<WakeOtherVehiclesDto> {
    return this.put<WakeOtherVehiclesDto>(`${this.servicePath}/wakeothervehicles`);
  }

  checkDeleteVehicle(vehicleId: GuidString): Observable<string[]> {
    return this.get<string[]>(`${this.servicePath}/checkDelete/${vehicleId}`);
  }

  requestFactsheet(vehicleId: GuidString): Observable<void> {
    const options = { connectionTimeoutInSeconds: 5, responseTimeoutInSeconds: 8 };
    return this.post<void>(
      `${this.servicePathVehicleCommands}/requestFactsheet/${vehicleId}`,
      options
    );
  }
}
