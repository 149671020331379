export interface TreeViewNodeConfiguration {
  labelProperty: string;
  dataProperty: string;
  selectable?: boolean;
  childProperty?: string;
  childConfiguration?: TreeViewNodeConfiguration;
  sortType: TreeViewSortType;
  expanded?: boolean;
  customSort?: (firstValue: object, secondValue: object) => number;
  invertSelectableOnParent?: string;
  type?: TreeViewType;
}

export enum TreeViewSortType {
  none,
  ascending,
  descending,
  custom,
}

export enum TreeViewType {
  Filter = 'Filter',
}
