export enum OrderConfirmationOption {
  DoNotSend = 0,
  SendAsConfirmation = 1,
  SendAsCancel = 2,
}

export interface MissionTraceAbortOptionsDto {
  releaseInterlock: boolean;
  sapConfirmationOption: OrderConfirmationOption;
  reason?: MissionTraceAbortReason;
}

export enum MissionTraceAbortReason {
  Test = 0,
  DuplicateMission = 1,
  DoubleRetrievalAma = 2,
  WrongMission = 3,
  NotRelevant = 4,
  FollowUpOfAborted = 5,
  AtsError = 6,
}
