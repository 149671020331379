import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as FactsheetActions from '../actions/factsheet.actions';
import { Factsheet } from 'core/dtos';

export const featureKey = 'factsheets';

export interface FactsheetState extends EntityState<Factsheet> {
  loading: boolean;
  loaded: boolean;
  errorMessage: string;
}

export const entityIdMapper = (model: Factsheet): string => `${model.vehicleId}`;

export const factsheetAdapter: EntityAdapter<Factsheet> = createEntityAdapter<Factsheet>({
  selectId: entityIdMapper,
});

export const initialState: FactsheetState = factsheetAdapter.getInitialState({
  loading: false,
  loaded: false,
  errorMessage: '',
});

export const factsheetsReducer = createReducer(
  initialState,
  on(FactsheetActions.loadFactsheets, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(FactsheetActions.loadFactsheetsSuccess, (state, { factsheets }) =>
    factsheetAdapter.setAll(factsheets, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(FactsheetActions.signalRUpdateVehicleFactsheet, (state, { factsheet }) => {
    return factsheetAdapter.setOne(factsheet, {
      ...state,
    });
  }),

  on(FactsheetActions.loadFactsheetsFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  }))
);

export function reducer(state: FactsheetState | undefined, action: Action): FactsheetState {
  return factsheetsReducer(state, action);
}

export const { selectEntities, selectAll } = factsheetAdapter.getSelectors();

export const getLoading = (state: FactsheetState): boolean => state.loading;
export const getLoaded = (state: FactsheetState): boolean => state.loaded;

export const getEntities = selectEntities;
export const getAllFactsheets = selectAll;
