import { Pipe, PipeTransform } from '@angular/core';
import { DateString } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { formatDateIncludingIn } from 'shared/helpers';

@Pipe({
  name: 'formatDateIncludeIn',
  pure: false,
})
export class FormatDateIncludeInPipe implements PipeTransform {
  constructor(private readonly translateService: AtsTranslationService) {}

  transform(value: Date | DateString | null | undefined): string {
    if (!value) {
      return '';
    }

    const minutesTranslation = this.translateService.get('jobs.missionDetails.minutes');
    const nowTranslation = this.translateService.get('jobs.missionDetails.now');

    return formatDateIncludingIn(minutesTranslation, nowTranslation, value);
  }
}
