/* eslint-disable max-lines */
import { DateString, GuidString } from 'core/models';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: DateString;
  UUID: GuidString;
  /** The UnsignedInt scalar type represents a unsigned 32-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedInt: number;
};

export type ArchiveTourDeviceSubStepFrontendData = {
  readonly __typename?: 'ArchiveTourDeviceSubStepFrontendData';
  readonly nodeName: Scalars['String'];
  readonly nodeValue: Scalars['String'];
  readonly sequenceNumber?: Maybe<Scalars['UnsignedInt']>;
  readonly state: WaitForDeviceSubStepState;
  readonly type: WaitForDeviceSubStepType;
  readonly updatedDateTime: Scalars['DateTime'];
};

export type ArchiveTourDeviceSubStepFrontendDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ArchiveTourDeviceSubStepFrontendDataFilterInput>>;
  readonly nodeName?: InputMaybe<StringOperationFilterInput>;
  readonly nodeValue?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ArchiveTourDeviceSubStepFrontendDataFilterInput>>;
  readonly sequenceNumber?: InputMaybe<UnsignedIntOperationFilterInputType>;
  readonly state?: InputMaybe<WaitForDeviceSubStepStateOperationFilterInput>;
  readonly type?: InputMaybe<WaitForDeviceSubStepTypeOperationFilterInput>;
  readonly updatedDateTime?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ArchivedTourStepFrontendData = {
  readonly __typename?: 'ArchivedTourStepFrontendData';
  readonly alreadyExecuted: Scalars['Boolean'];
  readonly id: Scalars['UUID'];
  readonly interrupted: Scalars['Boolean'];
  readonly isManual: Scalars['Boolean'];
  readonly sequenceId: Scalars['Int'];
  readonly skipped: Scalars['Boolean'];
  readonly startedDateUtc?: Maybe<Scalars['DateTime']>;
  readonly stepAttributeText?: Maybe<Scalars['String']>;
  readonly stoppedDateUtc?: Maybe<Scalars['DateTime']>;
  readonly subSteps: ReadonlyArray<ArchiveTourDeviceSubStepFrontendData>;
  readonly tour: TourArchive;
  readonly tourId: Scalars['UUID'];
  readonly type: StepType;
};

export type ArchivedTourStepFrontendDataFilterInput = {
  readonly alreadyExecuted?: InputMaybe<BooleanOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<ArchivedTourStepFrontendDataFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly interrupted?: InputMaybe<BooleanOperationFilterInput>;
  readonly isManual?: InputMaybe<BooleanOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ArchivedTourStepFrontendDataFilterInput>>;
  readonly sequenceId?: InputMaybe<IntOperationFilterInput>;
  readonly skipped?: InputMaybe<BooleanOperationFilterInput>;
  readonly startedDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly stepAttributeText?: InputMaybe<StringOperationFilterInput>;
  readonly stoppedDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly subSteps?: InputMaybe<ListFilterInputTypeOfArchiveTourDeviceSubStepFrontendDataFilterInput>;
  readonly tour?: InputMaybe<TourArchiveFilterInput>;
  readonly tourId?: InputMaybe<UuidOperationFilterInput>;
  readonly type?: InputMaybe<StepTypeOperationFilterInput>;
};

export type BooleanOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Boolean']>;
  readonly neq?: InputMaybe<Scalars['Boolean']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  readonly __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  readonly hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  readonly hasPreviousPage: Scalars['Boolean'];
};

export type CommentLocation = {
  readonly __typename?: 'CommentLocation';
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly workAreaId: Scalars['UUID'];
};

export type CommentLocationFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<CommentLocationFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CommentLocationFilterInput>>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export type DateTimeOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['DateTime']>;
  readonly gt?: InputMaybe<Scalars['DateTime']>;
  readonly gte?: InputMaybe<Scalars['DateTime']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']>>>;
  readonly lt?: InputMaybe<Scalars['DateTime']>;
  readonly lte?: InputMaybe<Scalars['DateTime']>;
  readonly neq?: InputMaybe<Scalars['DateTime']>;
  readonly ngt?: InputMaybe<Scalars['DateTime']>;
  readonly ngte?: InputMaybe<Scalars['DateTime']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']>>>;
  readonly nlt?: InputMaybe<Scalars['DateTime']>;
  readonly nlte?: InputMaybe<Scalars['DateTime']>;
};

export type FailureComment = {
  readonly __typename?: 'FailureComment';
  readonly activeTraceId?: Maybe<Scalars['UUID']>;
  readonly archivedTour?: Maybe<TourArchive>;
  readonly archivedTourId?: Maybe<Scalars['UUID']>;
  readonly durationOfMissionInFailed?: Maybe<Scalars['Int']>;
  readonly errorClass: Scalars['Int'];
  readonly failureVehicleType: FailureVehicleType;
  readonly failureVehicles?: Maybe<ReadonlyArray<FailureVehicle>>;
  readonly id: Scalars['UUID'];
  readonly missionCommentState: MissionCommentState;
  readonly missionFailureEndDateTime?: Maybe<Scalars['DateTime']>;
  readonly missionFailureLocation?: Maybe<CommentLocation>;
  readonly missionFailureLocationId?: Maybe<Scalars['UUID']>;
  readonly missionFailureLocationText?: Maybe<Scalars['String']>;
  readonly missionFailureMinutesForEmergencyProcess?: Maybe<Scalars['Int']>;
  readonly missionFailureMinutesToSolve?: Maybe<Scalars['Int']>;
  readonly missionFailureReason?: Maybe<MissionFailureReason>;
  readonly missionFailureReasonComments?: Maybe<Scalars['String']>;
  readonly missionFailureReasonId?: Maybe<Scalars['UUID']>;
  readonly missionFailureShiftGroup?: Maybe<ShiftGroup>;
  readonly missionFailureShiftGroupId?: Maybe<Scalars['UUID']>;
  readonly missionFailureStartDateTime?: Maybe<Scalars['DateTime']>;
  readonly missionId?: Maybe<Scalars['UUID']>;
  readonly missionTraceArchive?: Maybe<MissionTraceArchive>;
  readonly missionTraceArchiveId?: Maybe<Scalars['UUID']>;
  readonly tuggerMissions?: Maybe<ReadonlyArray<VehicleTuggerMission>>;
  readonly workAreaId: Scalars['UUID'];
};

export const enum FailureCommentCategory {
  Alright = 'ALRIGHT',
  CallOffSystem = 'CALL_OFF_SYSTEM',
  FacilityMaterialsHandlingTechnology = 'FACILITY_MATERIALS_HANDLING_TECHNOLOGY',
  NotRelevant = 'NOT_RELEVANT',
  NoReasonAssigned = 'NO_REASON_ASSIGNED',
  Organizational = 'ORGANIZATIONAL',
  Services = 'SERVICES',
  SystemOptimization = 'SYSTEM_OPTIMIZATION',
  Vehicle = 'VEHICLE',
}

export type FailureCommentCategoryOperationFilterInput = {
  readonly eq?: InputMaybe<FailureCommentCategory>;
  readonly in?: InputMaybe<ReadonlyArray<FailureCommentCategory>>;
  readonly neq?: InputMaybe<FailureCommentCategory>;
  readonly nin?: InputMaybe<ReadonlyArray<FailureCommentCategory>>;
};

export type FailureCommentFilterInput = {
  readonly activeTraceId?: InputMaybe<UuidOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<FailureCommentFilterInput>>;
  readonly archivedTour?: InputMaybe<TourArchiveFilterInput>;
  readonly archivedTourId?: InputMaybe<UuidOperationFilterInput>;
  readonly durationOfMissionInFailed?: InputMaybe<IntOperationFilterInput>;
  readonly errorClass?: InputMaybe<IntOperationFilterInput>;
  readonly failureVehicleType?: InputMaybe<FailureVehicleTypeOperationFilterInput>;
  readonly failureVehicles?: InputMaybe<ListFilterInputTypeOfFailureVehicleFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly missionCommentState?: InputMaybe<MissionCommentStateOperationFilterInput>;
  readonly missionFailureEndDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  readonly missionFailureLocation?: InputMaybe<CommentLocationFilterInput>;
  readonly missionFailureLocationId?: InputMaybe<UuidOperationFilterInput>;
  readonly missionFailureLocationText?: InputMaybe<StringOperationFilterInput>;
  readonly missionFailureMinutesForEmergencyProcess?: InputMaybe<IntOperationFilterInput>;
  readonly missionFailureMinutesToSolve?: InputMaybe<IntOperationFilterInput>;
  readonly missionFailureReason?: InputMaybe<MissionFailureReasonFilterInput>;
  readonly missionFailureReasonComments?: InputMaybe<StringOperationFilterInput>;
  readonly missionFailureReasonId?: InputMaybe<UuidOperationFilterInput>;
  readonly missionFailureShiftGroup?: InputMaybe<ShiftGroupFilterInput>;
  readonly missionFailureShiftGroupId?: InputMaybe<UuidOperationFilterInput>;
  readonly missionFailureStartDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  readonly missionId?: InputMaybe<UuidOperationFilterInput>;
  readonly missionTraceArchive?: InputMaybe<MissionTraceArchiveFilterInput>;
  readonly missionTraceArchiveId?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<FailureCommentFilterInput>>;
  readonly tuggerMissions?: InputMaybe<ListFilterInputTypeOfVehicleTuggerMissionFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export type FailureVehicle = {
  readonly __typename?: 'FailureVehicle';
  readonly failureComment?: Maybe<FailureComment>;
  readonly failureCommentId: Scalars['UUID'];
  readonly id: Scalars['UUID'];
  readonly vehicleId: Scalars['UUID'];
};

export type FailureVehicleFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<FailureVehicleFilterInput>>;
  readonly failureComment?: InputMaybe<FailureCommentFilterInput>;
  readonly failureCommentId?: InputMaybe<UuidOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<FailureVehicleFilterInput>>;
  readonly vehicleId?: InputMaybe<UuidOperationFilterInput>;
};

export const enum FailureVehicleType {
  Default = 'DEFAULT',
  DsTugger = 'DS_TUGGER',
  Forklift = 'FORKLIFT',
  Other = 'OTHER',
  Tugger = 'TUGGER',
  Uagv = 'UAGV',
}

export type FailureVehicleTypeOperationFilterInput = {
  readonly eq?: InputMaybe<FailureVehicleType>;
  readonly in?: InputMaybe<ReadonlyArray<FailureVehicleType>>;
  readonly neq?: InputMaybe<FailureVehicleType>;
  readonly nin?: InputMaybe<ReadonlyArray<FailureVehicleType>>;
};

export type FloatOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Float']>;
  readonly gt?: InputMaybe<Scalars['Float']>;
  readonly gte?: InputMaybe<Scalars['Float']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Float']>>>;
  readonly lt?: InputMaybe<Scalars['Float']>;
  readonly lte?: InputMaybe<Scalars['Float']>;
  readonly neq?: InputMaybe<Scalars['Float']>;
  readonly ngt?: InputMaybe<Scalars['Float']>;
  readonly ngte?: InputMaybe<Scalars['Float']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Float']>>>;
  readonly nlt?: InputMaybe<Scalars['Float']>;
  readonly nlte?: InputMaybe<Scalars['Float']>;
};

export const enum HardwareVersion {
  AgiloxOcf = 'AGILOX_OCF',
  AgiloxOne = 'AGILOX_ONE',
  DsType1 = 'DS_TYPE1',
  DsType2 = 'DS_TYPE2',
  DsType3 = 'DS_TYPE3',
  DsType4 = 'DS_TYPE4',
  DsType5 = 'DS_TYPE5',
  FourAm_15T = 'FOUR_AM_15T',
  FourAm_20T = 'FOUR_AM_20T',
  FourAmManual = 'FOUR_AM_MANUAL',
  StrVersion2 = 'STR_VERSION2',
  StrVersion3 = 'STR_VERSION3',
  StrVersion4 = 'STR_VERSION4',
  TuggerTrainDsV1 = 'TUGGER_TRAIN_DS_V1',
  TuggerTrainFourAmV2 = 'TUGGER_TRAIN_FOUR_AM_V2',
  TuggerTrainManual = 'TUGGER_TRAIN_MANUAL',
  Unknown = 'UNKNOWN',
}

export type IntOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Int']>;
  readonly gt?: InputMaybe<Scalars['Int']>;
  readonly gte?: InputMaybe<Scalars['Int']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly lt?: InputMaybe<Scalars['Int']>;
  readonly lte?: InputMaybe<Scalars['Int']>;
  readonly neq?: InputMaybe<Scalars['Int']>;
  readonly ngt?: InputMaybe<Scalars['Int']>;
  readonly ngte?: InputMaybe<Scalars['Int']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly nlt?: InputMaybe<Scalars['Int']>;
  readonly nlte?: InputMaybe<Scalars['Int']>;
};

export type ListFilterInputTypeOfArchiveTourDeviceSubStepFrontendDataFilterInput = {
  readonly all?: InputMaybe<ArchiveTourDeviceSubStepFrontendDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<ArchiveTourDeviceSubStepFrontendDataFilterInput>;
  readonly some?: InputMaybe<ArchiveTourDeviceSubStepFrontendDataFilterInput>;
};

export type ListFilterInputTypeOfArchivedTourStepFrontendDataFilterInput = {
  readonly all?: InputMaybe<ArchivedTourStepFrontendDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<ArchivedTourStepFrontendDataFilterInput>;
  readonly some?: InputMaybe<ArchivedTourStepFrontendDataFilterInput>;
};

export type ListFilterInputTypeOfFailureCommentFilterInput = {
  readonly all?: InputMaybe<FailureCommentFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<FailureCommentFilterInput>;
  readonly some?: InputMaybe<FailureCommentFilterInput>;
};

export type ListFilterInputTypeOfFailureVehicleFilterInput = {
  readonly all?: InputMaybe<FailureVehicleFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<FailureVehicleFilterInput>;
  readonly some?: InputMaybe<FailureVehicleFilterInput>;
};

export type ListFilterInputTypeOfVehicleTuggerMissionFilterInput = {
  readonly all?: InputMaybe<VehicleTuggerMissionFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<VehicleTuggerMissionFilterInput>;
  readonly some?: InputMaybe<VehicleTuggerMissionFilterInput>;
};

export const enum LoadType {
  GltPalletCrosswise = 'GLT_PALLET_CROSSWISE',
  GltPalletLengthwise = 'GLT_PALLET_LENGTHWISE',
  GltStrStandard = 'GLT_STR_STANDARD',
  SeatDeb = 'SEAT_DEB',
  SpaMedium = 'SPA_MEDIUM',
  Uglt = 'UGLT',
  Unknown = 'UNKNOWN',
}

export type LoadTypeOperationFilterInput = {
  readonly eq?: InputMaybe<LoadType>;
  readonly in?: InputMaybe<ReadonlyArray<LoadType>>;
  readonly neq?: InputMaybe<LoadType>;
  readonly nin?: InputMaybe<ReadonlyArray<LoadType>>;
};

export type MissionArchiveGraphQlQuery = {
  readonly __typename?: 'MissionArchiveGraphQlQuery';
  readonly missionArchiveFilters: MissionTraceArchiveFilterValues;
  readonly missionArchives?: Maybe<MissionArchivesCollectionSegment>;
  readonly tourArchiveFilters: MissionTraceArchiveFilterValues;
};

export type MissionArchiveGraphQlQueryMissionArchivesArgs = {
  order?: InputMaybe<ReadonlyArray<MissionTraceArchiveSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MissionTraceArchiveFilterInput>;
};

/** A segment of a collection. */
export type MissionArchivesCollectionSegment = {
  readonly __typename?: 'MissionArchivesCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<MissionTraceArchive>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly totalCount: Scalars['Int'];
};

export const enum MissionCommentState {
  Active = 'ACTIVE',
  History = 'HISTORY',
}

export type MissionCommentStateOperationFilterInput = {
  readonly eq?: InputMaybe<MissionCommentState>;
  readonly in?: InputMaybe<ReadonlyArray<MissionCommentState>>;
  readonly neq?: InputMaybe<MissionCommentState>;
  readonly nin?: InputMaybe<ReadonlyArray<MissionCommentState>>;
};

export type MissionFailureReason = {
  readonly __typename?: 'MissionFailureReason';
  readonly descriptionDe: Scalars['String'];
  readonly descriptionEn: Scalars['String'];
  readonly failureCommentCategory: FailureCommentCategory;
  readonly id: Scalars['UUID'];
  readonly isActive: MissionFailureReasonStatus;
  readonly isCustom: Scalars['Boolean'];
  readonly vehicleType: VehicleType;
};

export type MissionFailureReasonFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<MissionFailureReasonFilterInput>>;
  readonly descriptionDe?: InputMaybe<StringOperationFilterInput>;
  readonly descriptionEn?: InputMaybe<StringOperationFilterInput>;
  readonly failureCommentCategory?: InputMaybe<FailureCommentCategoryOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly isActive?: InputMaybe<MissionFailureReasonStatusOperationFilterInput>;
  readonly isCustom?: InputMaybe<BooleanOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<MissionFailureReasonFilterInput>>;
  readonly vehicleType?: InputMaybe<VehicleTypeOperationFilterInput>;
};

export const enum MissionFailureReasonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type MissionFailureReasonStatusOperationFilterInput = {
  readonly eq?: InputMaybe<MissionFailureReasonStatus>;
  readonly in?: InputMaybe<ReadonlyArray<MissionFailureReasonStatus>>;
  readonly neq?: InputMaybe<MissionFailureReasonStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<MissionFailureReasonStatus>>;
};

export const enum MissionStatus {
  Aborted = 'ABORTED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Interrupted = 'INTERRUPTED',
  InterruptionFailed = 'INTERRUPTION_FAILED',
  InProgress = 'IN_PROGRESS',
  PendingDispatch = 'PENDING_DISPATCH',
  QueuedOnJobProcessor = 'QUEUED_ON_JOB_PROCESSOR',
  QueuedOnRobot = 'QUEUED_ON_ROBOT',
  QueuedOnSystem = 'QUEUED_ON_SYSTEM',
  SentToRobot = 'SENT_TO_ROBOT',
  Sleeping = 'SLEEPING',
  WaitingForAssignment = 'WAITING_FOR_ASSIGNMENT',
  WaitingForZoneSetUpdate = 'WAITING_FOR_ZONE_SET_UPDATE',
}

export type MissionStatusOperationFilterInput = {
  readonly eq?: InputMaybe<MissionStatus>;
  readonly in?: InputMaybe<ReadonlyArray<MissionStatus>>;
  readonly neq?: InputMaybe<MissionStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<MissionStatus>>;
};

export type MissionTraceArchive = {
  readonly __typename?: 'MissionTraceArchive';
  readonly completedUtc: Scalars['DateTime'];
  readonly createdUtc: Scalars['DateTime'];
  readonly failureComment?: Maybe<ReadonlyArray<FailureComment>>;
  readonly fifoOrderTimeUtc?: Maybe<Scalars['DateTime']>;
  readonly fleetId?: Maybe<Scalars['UUID']>;
  readonly fleetName?: Maybe<Scalars['String']>;
  readonly hasManualSupport: Scalars['Boolean'];
  readonly id: Scalars['UUID'];
  readonly lastStepDetail: Scalars['String'];
  readonly lastStepType: Scalars['String'];
  readonly loadType: LoadType;
  readonly mapId: Scalars['UUID'];
  readonly materialNumber?: Maybe<Scalars['String']>;
  readonly missionId?: Maybe<Scalars['UUID']>;
  readonly missionName: Scalars['String'];
  readonly missionTraceReportFields?: Maybe<MissionTraceReportFields>;
  readonly processChainName?: Maybe<Scalars['String']>;
  readonly processChainTraceId?: Maybe<Scalars['UUID']>;
  readonly provisioningTimeUtc?: Maybe<Scalars['DateTime']>;
  readonly status: MissionStatus;
  readonly successfulSteps: Scalars['Int'];
  readonly totalSteps: Scalars['Int'];
  readonly triggerSource: MissionTraceTrigger;
  readonly vehicleId: Scalars['UUID'];
  readonly vehicleName: Scalars['String'];
  readonly vehicleType: VehicleType;
  readonly workAreaId: Scalars['UUID'];
};

export type MissionTraceArchiveFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<MissionTraceArchiveFilterInput>>;
  readonly completedUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly createdUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly failureComment?: InputMaybe<ListFilterInputTypeOfFailureCommentFilterInput>;
  readonly fifoOrderTimeUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly fleetId?: InputMaybe<UuidOperationFilterInput>;
  readonly fleetName?: InputMaybe<StringOperationFilterInput>;
  readonly hasManualSupport?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly lastStepDetail?: InputMaybe<StringOperationFilterInput>;
  readonly lastStepType?: InputMaybe<StringOperationFilterInput>;
  readonly loadType?: InputMaybe<LoadTypeOperationFilterInput>;
  readonly mapId?: InputMaybe<UuidOperationFilterInput>;
  readonly materialNumber?: InputMaybe<StringOperationFilterInput>;
  readonly missionId?: InputMaybe<UuidOperationFilterInput>;
  readonly missionName?: InputMaybe<StringOperationFilterInput>;
  readonly missionTraceReportFields?: InputMaybe<MissionTraceReportFieldsFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<MissionTraceArchiveFilterInput>>;
  readonly processChainName?: InputMaybe<StringOperationFilterInput>;
  readonly processChainTraceId?: InputMaybe<UuidOperationFilterInput>;
  readonly provisioningTimeUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly status?: InputMaybe<MissionStatusOperationFilterInput>;
  readonly successfulSteps?: InputMaybe<IntOperationFilterInput>;
  readonly totalSteps?: InputMaybe<IntOperationFilterInput>;
  readonly triggerSource?: InputMaybe<MissionTraceTriggerOperationFilterInput>;
  readonly vehicleId?: InputMaybe<UuidOperationFilterInput>;
  readonly vehicleName?: InputMaybe<StringOperationFilterInput>;
  readonly vehicleType?: InputMaybe<VehicleTypeOperationFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export type MissionTraceArchiveFilterValues = {
  readonly __typename?: 'MissionTraceArchiveFilterValues';
  readonly mapIds: ReadonlyArray<Scalars['String']>;
  readonly matNumbers: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly missionNames: ReadonlyArray<Scalars['String']>;
  readonly processChainNames: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly vehicleNames: ReadonlyArray<Scalars['String']>;
};

export type MissionTraceArchiveSortInput = {
  readonly completedUtc?: InputMaybe<SortEnumType>;
  readonly createdUtc?: InputMaybe<SortEnumType>;
  readonly fifoOrderTimeUtc?: InputMaybe<SortEnumType>;
  readonly fleetId?: InputMaybe<SortEnumType>;
  readonly fleetName?: InputMaybe<SortEnumType>;
  readonly hasManualSupport?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly lastStepDetail?: InputMaybe<SortEnumType>;
  readonly lastStepType?: InputMaybe<SortEnumType>;
  readonly loadType?: InputMaybe<SortEnumType>;
  readonly mapId?: InputMaybe<SortEnumType>;
  readonly materialNumber?: InputMaybe<SortEnumType>;
  readonly missionId?: InputMaybe<SortEnumType>;
  readonly missionName?: InputMaybe<SortEnumType>;
  readonly missionTraceReportFields?: InputMaybe<MissionTraceReportFieldsSortInput>;
  readonly processChainName?: InputMaybe<SortEnumType>;
  readonly processChainTraceId?: InputMaybe<SortEnumType>;
  readonly provisioningTimeUtc?: InputMaybe<SortEnumType>;
  readonly status?: InputMaybe<SortEnumType>;
  readonly successfulSteps?: InputMaybe<SortEnumType>;
  readonly totalSteps?: InputMaybe<SortEnumType>;
  readonly triggerSource?: InputMaybe<SortEnumType>;
  readonly vehicleId?: InputMaybe<SortEnumType>;
  readonly vehicleName?: InputMaybe<SortEnumType>;
  readonly vehicleType?: InputMaybe<SortEnumType>;
  readonly workAreaId?: InputMaybe<SortEnumType>;
};

export type MissionTraceReportFields = {
  readonly __typename?: 'MissionTraceReportFields';
  readonly id: Scalars['UUID'];
  readonly missionAssignmentTime?: Maybe<Scalars['DateTime']>;
  readonly missionDistance?: Maybe<Scalars['Float']>;
  readonly missionStartTime?: Maybe<Scalars['DateTime']>;
  readonly missionTraceArchive?: Maybe<MissionTraceArchive>;
  readonly missionTraceId: Scalars['UUID'];
  readonly softwareVersion: Scalars['String'];
  readonly vehicleVersion: Scalars['String'];
};

export type MissionTraceReportFieldsFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<MissionTraceReportFieldsFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly missionAssignmentTime?: InputMaybe<DateTimeOperationFilterInput>;
  readonly missionDistance?: InputMaybe<FloatOperationFilterInput>;
  readonly missionStartTime?: InputMaybe<DateTimeOperationFilterInput>;
  readonly missionTraceArchive?: InputMaybe<MissionTraceArchiveFilterInput>;
  readonly missionTraceId?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<MissionTraceReportFieldsFilterInput>>;
  readonly softwareVersion?: InputMaybe<StringOperationFilterInput>;
  readonly vehicleVersion?: InputMaybe<StringOperationFilterInput>;
};

export type MissionTraceReportFieldsSortInput = {
  readonly id?: InputMaybe<SortEnumType>;
  readonly missionAssignmentTime?: InputMaybe<SortEnumType>;
  readonly missionDistance?: InputMaybe<SortEnumType>;
  readonly missionStartTime?: InputMaybe<SortEnumType>;
  readonly missionTraceArchive?: InputMaybe<MissionTraceArchiveSortInput>;
  readonly missionTraceId?: InputMaybe<SortEnumType>;
  readonly softwareVersion?: InputMaybe<SortEnumType>;
  readonly vehicleVersion?: InputMaybe<SortEnumType>;
};

export const enum MissionTraceTrigger {
  AdhocInstructionFromMission = 'ADHOC_INSTRUCTION_FROM_MISSION',
  AdhocInstructionFromProcessChain = 'ADHOC_INSTRUCTION_FROM_PROCESS_CHAIN',
  AdhocInstructionFromStep = 'ADHOC_INSTRUCTION_FROM_STEP',
  AssignmentProduction = 'ASSIGNMENT_PRODUCTION',
  JobInstruction = 'JOB_INSTRUCTION',
  /** @deprecated Field no longer supported */
  None = 'NONE',
  SystemBrakeTestInstruction = 'SYSTEM_BRAKE_TEST_INSTRUCTION',
  SystemChargingInstruction = 'SYSTEM_CHARGING_INSTRUCTION',
  SystemParkingInstruction = 'SYSTEM_PARKING_INSTRUCTION',
  SystemWaitingQueueInstruction = 'SYSTEM_WAITING_QUEUE_INSTRUCTION',
  Tour = 'TOUR',
  TourBeginShift = 'TOUR_BEGIN_SHIFT',
  TourLongRunning = 'TOUR_LONG_RUNNING',
}

export type MissionTraceTriggerOperationFilterInput = {
  readonly eq?: InputMaybe<MissionTraceTrigger>;
  readonly in?: InputMaybe<ReadonlyArray<MissionTraceTrigger>>;
  readonly neq?: InputMaybe<MissionTraceTrigger>;
  readonly nin?: InputMaybe<ReadonlyArray<MissionTraceTrigger>>;
};

export type NullableOfHardwareVersionOperationFilterInput = {
  readonly eq?: InputMaybe<HardwareVersion>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<HardwareVersion>>>;
  readonly neq?: InputMaybe<HardwareVersion>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<HardwareVersion>>>;
};

export type ShiftGroup = {
  readonly __typename?: 'ShiftGroup';
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly vehicleType: VehicleType;
  readonly workAreaId: Scalars['UUID'];
};

export type ShiftGroupFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ShiftGroupFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ShiftGroupFilterInput>>;
  readonly vehicleType?: InputMaybe<VehicleTypeOperationFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export const enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export const enum StepType {
  Dock = 'DOCK',
  DockToCharge = 'DOCK_TO_CHARGE',
  DockToMapping = 'DOCK_TO_MAPPING',
  DockToTripleTurnTable = 'DOCK_TO_TRIPLE_TURN_TABLE',
  Drop = 'DROP',
  DynamicWaitStep = 'DYNAMIC_WAIT_STEP',
  Goto = 'GOTO',
  GotoAndPushSideButton = 'GOTO_AND_PUSH_SIDE_BUTTON',
  GotoMapping = 'GOTO_MAPPING',
  GotoPose = 'GOTO_POSE',
  Lift = 'LIFT',
  NoOperation = 'NO_OPERATION',
  RaiseEvent = 'RAISE_EVENT',
  StartToCharge = 'START_TO_CHARGE',
  StopToCharge = 'STOP_TO_CHARGE',
  TurnDolly = 'TURN_DOLLY',
  Wait = 'WAIT',
  WaitForDevice = 'WAIT_FOR_DEVICE',
  WaitForEvent = 'WAIT_FOR_EVENT',
  WaitForOrderAcknowledgement = 'WAIT_FOR_ORDER_ACKNOWLEDGEMENT',
  WaitForScanDestination = 'WAIT_FOR_SCAN_DESTINATION',
  WaitForSideButton = 'WAIT_FOR_SIDE_BUTTON',
}

export type StepTypeOperationFilterInput = {
  readonly eq?: InputMaybe<StepType>;
  readonly in?: InputMaybe<ReadonlyArray<StepType>>;
  readonly neq?: InputMaybe<StepType>;
  readonly nin?: InputMaybe<ReadonlyArray<StepType>>;
};

export type StringOperationFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly contains?: InputMaybe<Scalars['String']>;
  readonly endsWith?: InputMaybe<Scalars['String']>;
  readonly eq?: InputMaybe<Scalars['String']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly ncontains?: InputMaybe<Scalars['String']>;
  readonly nendsWith?: InputMaybe<Scalars['String']>;
  readonly neq?: InputMaybe<Scalars['String']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly nstartsWith?: InputMaybe<Scalars['String']>;
  readonly or?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly startsWith?: InputMaybe<Scalars['String']>;
};

export type TourArchive = {
  readonly __typename?: 'TourArchive';
  readonly assignableWaypointName?: Maybe<Scalars['String']>;
  readonly completedDateUtc?: Maybe<Scalars['DateTime']>;
  readonly createdDateUtc: Scalars['DateTime'];
  readonly failureComment?: Maybe<ReadonlyArray<FailureComment>>;
  readonly hardwareVersion?: Maybe<HardwareVersion>;
  readonly id: Scalars['UUID'];
  readonly lastStepDetail?: Maybe<Scalars['String']>;
  readonly lastStepType: StepType;
  readonly name: Scalars['String'];
  readonly provisioningTime?: Maybe<Scalars['DateTime']>;
  readonly retryRunId?: Maybe<Scalars['UUID']>;
  readonly routeConfigurationId?: Maybe<Scalars['UUID']>;
  readonly runIndex: Scalars['Int'];
  readonly sapInternalTourId?: Maybe<Scalars['UUID']>;
  readonly softwareVersion?: Maybe<Scalars['String']>;
  readonly startSequenceOffset: Scalars['Int'];
  readonly startStepSequenceNumber: Scalars['Int'];
  readonly status: TourStatus;
  readonly steps: ReadonlyArray<ArchivedTourStepFrontendData>;
  readonly successfulSteps: Scalars['Int'];
  readonly totalSteps: Scalars['Int'];
  readonly tourChainId?: Maybe<Scalars['UUID']>;
  readonly tourChainName?: Maybe<Scalars['String']>;
  readonly tourConfigurationId?: Maybe<Scalars['UUID']>;
  readonly tourConfigurationName: Scalars['String'];
  readonly tourSapInternalRoute?: Maybe<Scalars['String']>;
  readonly trigger: TourTrigger;
  readonly vehicleId: Scalars['UUID'];
  readonly vehicleName: Scalars['String'];
  readonly vehicleType: VehicleType;
  readonly workAreaId: Scalars['UUID'];
};

export type TourArchiveFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<TourArchiveFilterInput>>;
  readonly assignableWaypointName?: InputMaybe<StringOperationFilterInput>;
  readonly completedDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly createdDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly failureComment?: InputMaybe<ListFilterInputTypeOfFailureCommentFilterInput>;
  readonly hardwareVersion?: InputMaybe<NullableOfHardwareVersionOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly lastStepDetail?: InputMaybe<StringOperationFilterInput>;
  readonly lastStepType?: InputMaybe<StepTypeOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<TourArchiveFilterInput>>;
  readonly provisioningTime?: InputMaybe<DateTimeOperationFilterInput>;
  readonly retryRunId?: InputMaybe<UuidOperationFilterInput>;
  readonly routeConfigurationId?: InputMaybe<UuidOperationFilterInput>;
  readonly runIndex?: InputMaybe<IntOperationFilterInput>;
  readonly sapInternalTourId?: InputMaybe<UuidOperationFilterInput>;
  readonly softwareVersion?: InputMaybe<StringOperationFilterInput>;
  readonly startSequenceOffset?: InputMaybe<IntOperationFilterInput>;
  readonly startStepSequenceNumber?: InputMaybe<IntOperationFilterInput>;
  readonly status?: InputMaybe<TourStatusOperationFilterInput>;
  readonly steps?: InputMaybe<ListFilterInputTypeOfArchivedTourStepFrontendDataFilterInput>;
  readonly successfulSteps?: InputMaybe<IntOperationFilterInput>;
  readonly totalSteps?: InputMaybe<IntOperationFilterInput>;
  readonly tourChainId?: InputMaybe<UuidOperationFilterInput>;
  readonly tourChainName?: InputMaybe<StringOperationFilterInput>;
  readonly tourConfigurationId?: InputMaybe<UuidOperationFilterInput>;
  readonly tourConfigurationName?: InputMaybe<StringOperationFilterInput>;
  readonly tourSapInternalRoute?: InputMaybe<StringOperationFilterInput>;
  readonly trigger?: InputMaybe<TourTriggerOperationFilterInput>;
  readonly vehicleId?: InputMaybe<UuidOperationFilterInput>;
  readonly vehicleName?: InputMaybe<StringOperationFilterInput>;
  readonly vehicleType?: InputMaybe<VehicleTypeOperationFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export const enum TourStatus {
  Aborted = 'ABORTED',
  Completed = 'COMPLETED',
  Corrupted = 'CORRUPTED',
  Failed = 'FAILED',
  Interrupted = 'INTERRUPTED',
  InterruptionFailed = 'INTERRUPTION_FAILED',
  InProgress = 'IN_PROGRESS',
  PendingCancellation = 'PENDING_CANCELLATION',
  PendingDispatch = 'PENDING_DISPATCH',
  PendingReDispatch = 'PENDING_RE_DISPATCH',
  QueuedForAssignment = 'QUEUED_FOR_ASSIGNMENT',
  SentToRobot = 'SENT_TO_ROBOT',
  Sleeping = 'SLEEPING',
  WaitingForZoneSetUpdate = 'WAITING_FOR_ZONE_SET_UPDATE',
}

export type TourStatusOperationFilterInput = {
  readonly eq?: InputMaybe<TourStatus>;
  readonly in?: InputMaybe<ReadonlyArray<TourStatus>>;
  readonly neq?: InputMaybe<TourStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<TourStatus>>;
};

export const enum TourTrigger {
  AdhocInstructionFromStep = 'ADHOC_INSTRUCTION_FROM_STEP',
  AdhocPose = 'ADHOC_POSE',
  AdHocFromTourConfiguration = 'AD_HOC_FROM_TOUR_CONFIGURATION',
  BeginShiftTour = 'BEGIN_SHIFT_TOUR',
  CallOff = 'CALL_OFF',
  Charging = 'CHARGING',
  Device = 'DEVICE',
  Parking = 'PARKING',
  SapInternalTour = 'SAP_INTERNAL_TOUR',
}

export type TourTriggerOperationFilterInput = {
  readonly eq?: InputMaybe<TourTrigger>;
  readonly in?: InputMaybe<ReadonlyArray<TourTrigger>>;
  readonly neq?: InputMaybe<TourTrigger>;
  readonly nin?: InputMaybe<ReadonlyArray<TourTrigger>>;
};

export type TuggerMission = {
  readonly __typename?: 'TuggerMission';
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly workAreaId: Scalars['UUID'];
};

export type TuggerMissionFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<TuggerMissionFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<TuggerMissionFilterInput>>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export type UnsignedIntOperationFilterInputType = {
  readonly eq?: InputMaybe<Scalars['UnsignedInt']>;
  readonly gt?: InputMaybe<Scalars['UnsignedInt']>;
  readonly gte?: InputMaybe<Scalars['UnsignedInt']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UnsignedInt']>>>;
  readonly lt?: InputMaybe<Scalars['UnsignedInt']>;
  readonly lte?: InputMaybe<Scalars['UnsignedInt']>;
  readonly neq?: InputMaybe<Scalars['UnsignedInt']>;
  readonly ngt?: InputMaybe<Scalars['UnsignedInt']>;
  readonly ngte?: InputMaybe<Scalars['UnsignedInt']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UnsignedInt']>>>;
  readonly nlt?: InputMaybe<Scalars['UnsignedInt']>;
  readonly nlte?: InputMaybe<Scalars['UnsignedInt']>;
};

export type UuidOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['UUID']>;
  readonly gt?: InputMaybe<Scalars['UUID']>;
  readonly gte?: InputMaybe<Scalars['UUID']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  readonly lt?: InputMaybe<Scalars['UUID']>;
  readonly lte?: InputMaybe<Scalars['UUID']>;
  readonly neq?: InputMaybe<Scalars['UUID']>;
  readonly ngt?: InputMaybe<Scalars['UUID']>;
  readonly ngte?: InputMaybe<Scalars['UUID']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  readonly nlt?: InputMaybe<Scalars['UUID']>;
  readonly nlte?: InputMaybe<Scalars['UUID']>;
};

export type VehicleTuggerMission = {
  readonly __typename?: 'VehicleTuggerMission';
  readonly failureComment?: Maybe<FailureComment>;
  readonly failureCommentId: Scalars['UUID'];
  readonly id: Scalars['UUID'];
  readonly tuggerMission?: Maybe<TuggerMission>;
  readonly tuggerMissionId: Scalars['UUID'];
};

export type VehicleTuggerMissionFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<VehicleTuggerMissionFilterInput>>;
  readonly failureComment?: InputMaybe<FailureCommentFilterInput>;
  readonly failureCommentId?: InputMaybe<UuidOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<VehicleTuggerMissionFilterInput>>;
  readonly tuggerMission?: InputMaybe<TuggerMissionFilterInput>;
  readonly tuggerMissionId?: InputMaybe<UuidOperationFilterInput>;
};

export const enum VehicleType {
  Forklift = 'FORKLIFT',
  TuggerTrain = 'TUGGER_TRAIN',
  Uagv = 'UAGV',
  UnitLoad = 'UNIT_LOAD',
}

export type VehicleTypeOperationFilterInput = {
  readonly eq?: InputMaybe<VehicleType>;
  readonly in?: InputMaybe<ReadonlyArray<VehicleType>>;
  readonly neq?: InputMaybe<VehicleType>;
  readonly nin?: InputMaybe<ReadonlyArray<VehicleType>>;
};

export const enum WaitForDeviceSubStepState {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Running = 'RUNNING',
  TimedOut = 'TIMED_OUT',
}

export type WaitForDeviceSubStepStateOperationFilterInput = {
  readonly eq?: InputMaybe<WaitForDeviceSubStepState>;
  readonly in?: InputMaybe<ReadonlyArray<WaitForDeviceSubStepState>>;
  readonly neq?: InputMaybe<WaitForDeviceSubStepState>;
  readonly nin?: InputMaybe<ReadonlyArray<WaitForDeviceSubStepState>>;
};

export const enum WaitForDeviceSubStepType {
  Read = 'READ',
  Write = 'WRITE',
}

export type WaitForDeviceSubStepTypeOperationFilterInput = {
  readonly eq?: InputMaybe<WaitForDeviceSubStepType>;
  readonly in?: InputMaybe<ReadonlyArray<WaitForDeviceSubStepType>>;
  readonly neq?: InputMaybe<WaitForDeviceSubStepType>;
  readonly nin?: InputMaybe<ReadonlyArray<WaitForDeviceSubStepType>>;
};

export type FilterValuesQueryVariables = Exact<{ [key: string]: never }>;

export type FilterValuesQuery = {
  readonly __typename?: 'MissionArchiveGraphQlQuery';
  readonly missionArchiveFilters: {
    readonly __typename?: 'MissionTraceArchiveFilterValues';
    readonly mapIds: ReadonlyArray<string>;
    readonly processChainNames: ReadonlyArray<string | null>;
    readonly missionNames: ReadonlyArray<string>;
    readonly matNumbers: ReadonlyArray<string | null>;
    readonly vehicleNames: ReadonlyArray<string>;
  };
};

export type MissionArchiveLogPageQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<MissionTraceArchiveFilterInput>;
  order?: InputMaybe<ReadonlyArray<MissionTraceArchiveSortInput> | MissionTraceArchiveSortInput>;
}>;

export type MissionArchiveLogPageQuery = {
  readonly __typename?: 'MissionArchiveGraphQlQuery';
  readonly missionArchives?: {
    readonly __typename?: 'MissionArchivesCollectionSegment';
    readonly totalCount: number;
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'MissionTraceArchive';
      readonly id: GuidString;
      readonly mapId: GuidString;
      readonly workAreaId: GuidString;
      readonly missionName: string;
      readonly processChainName?: string | null;
      readonly materialNumber?: string | null;
      readonly successfulSteps: number;
      readonly totalSteps: number;
      readonly lastStepType: string;
      readonly lastStepDetail: string;
      readonly createdUtc: DateString;
      readonly completedUtc: DateString;
      readonly fifoOrderTimeUtc?: DateString | null;
      readonly provisioningTimeUtc?: DateString | null;
      readonly vehicleId: GuidString;
      readonly vehicleName: string;
      readonly fleetId?: GuidString | null;
      readonly fleetName?: string | null;
      readonly status: MissionStatus;
      readonly triggerSource: MissionTraceTrigger;
      readonly processChainTraceId?: GuidString | null;
      readonly hasManualSupport: boolean;
      readonly vehicleType: VehicleType;
      readonly loadType: LoadType;
      readonly failureComment?: ReadonlyArray<{
        readonly __typename?: 'FailureComment';
        readonly id: GuidString;
        readonly missionFailureMinutesToSolve?: number | null;
        readonly missionFailureMinutesForEmergencyProcess?: number | null;
        readonly missionFailureReasonComments?: string | null;
        readonly missionFailureReasonId?: GuidString | null;
        readonly missionId?: GuidString | null;
        readonly missionFailureLocationId?: GuidString | null;
        readonly missionFailureStartDateTime?: DateString | null;
        readonly missionFailureEndDateTime?: DateString | null;
        readonly missionFailureShiftGroupId?: GuidString | null;
        readonly durationOfMissionInFailed?: number | null;
        readonly missionFailureReason?: {
          readonly __typename?: 'MissionFailureReason';
          readonly id: GuidString;
        } | null;
        readonly missionFailureLocation?: {
          readonly __typename?: 'CommentLocation';
          readonly id: GuidString;
        } | null;
        readonly missionFailureShiftGroup?: {
          readonly __typename?: 'ShiftGroup';
          readonly id: GuidString;
        } | null;
      }> | null;
      readonly missionTraceReportFields?: {
        readonly __typename?: 'MissionTraceReportFields';
        readonly id: GuidString;
        readonly missionTraceArchive?: {
          readonly __typename?: 'MissionTraceArchive';
          readonly missionId?: GuidString | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type MissionArchiveEntryFragment = {
  readonly __typename?: 'MissionTraceArchive';
  readonly id: GuidString;
  readonly mapId: GuidString;
  readonly workAreaId: GuidString;
  readonly missionName: string;
  readonly processChainName?: string | null;
  readonly materialNumber?: string | null;
  readonly successfulSteps: number;
  readonly totalSteps: number;
  readonly lastStepType: string;
  readonly lastStepDetail: string;
  readonly createdUtc: DateString;
  readonly completedUtc: DateString;
  readonly fifoOrderTimeUtc?: DateString | null;
  readonly provisioningTimeUtc?: DateString | null;
  readonly vehicleId: GuidString;
  readonly vehicleName: string;
  readonly fleetId?: GuidString | null;
  readonly fleetName?: string | null;
  readonly status: MissionStatus;
  readonly triggerSource: MissionTraceTrigger;
  readonly processChainTraceId?: GuidString | null;
  readonly hasManualSupport: boolean;
  readonly vehicleType: VehicleType;
  readonly loadType: LoadType;
  readonly failureComment?: ReadonlyArray<{
    readonly __typename?: 'FailureComment';
    readonly id: GuidString;
    readonly missionFailureMinutesToSolve?: number | null;
    readonly missionFailureMinutesForEmergencyProcess?: number | null;
    readonly missionFailureReasonComments?: string | null;
    readonly missionFailureReasonId?: GuidString | null;
    readonly missionId?: GuidString | null;
    readonly missionFailureLocationId?: GuidString | null;
    readonly missionFailureStartDateTime?: DateString | null;
    readonly missionFailureEndDateTime?: DateString | null;
    readonly missionFailureShiftGroupId?: GuidString | null;
    readonly durationOfMissionInFailed?: number | null;
    readonly missionFailureReason?: {
      readonly __typename?: 'MissionFailureReason';
      readonly id: GuidString;
    } | null;
    readonly missionFailureLocation?: {
      readonly __typename?: 'CommentLocation';
      readonly id: GuidString;
    } | null;
    readonly missionFailureShiftGroup?: {
      readonly __typename?: 'ShiftGroup';
      readonly id: GuidString;
    } | null;
  }> | null;
  readonly missionTraceReportFields?: {
    readonly __typename?: 'MissionTraceReportFields';
    readonly id: GuidString;
    readonly missionTraceArchive?: {
      readonly __typename?: 'MissionTraceArchive';
      readonly missionId?: GuidString | null;
    } | null;
  } | null;
};

export type MissionCommentEntryFragment = {
  readonly __typename?: 'FailureComment';
  readonly id: GuidString;
  readonly missionFailureMinutesToSolve?: number | null;
  readonly missionFailureMinutesForEmergencyProcess?: number | null;
  readonly missionFailureReasonComments?: string | null;
  readonly missionFailureReasonId?: GuidString | null;
  readonly missionId?: GuidString | null;
  readonly missionFailureLocationId?: GuidString | null;
  readonly missionFailureStartDateTime?: DateString | null;
  readonly missionFailureEndDateTime?: DateString | null;
  readonly missionFailureShiftGroupId?: GuidString | null;
  readonly durationOfMissionInFailed?: number | null;
  readonly missionFailureReason?: {
    readonly __typename?: 'MissionFailureReason';
    readonly id: GuidString;
  } | null;
  readonly missionFailureLocation?: {
    readonly __typename?: 'CommentLocation';
    readonly id: GuidString;
  } | null;
  readonly missionFailureShiftGroup?: {
    readonly __typename?: 'ShiftGroup';
    readonly id: GuidString;
  } | null;
};

export type TourFilterValuesQueryVariables = Exact<{ [key: string]: never }>;

export type TourFilterValuesQuery = {
  readonly __typename?: 'MissionArchiveGraphQlQuery';
  readonly tourArchiveFilters: {
    readonly __typename?: 'MissionTraceArchiveFilterValues';
    readonly mapIds: ReadonlyArray<string>;
    readonly processChainNames: ReadonlyArray<string | null>;
    readonly missionNames: ReadonlyArray<string>;
    readonly vehicleNames: ReadonlyArray<string>;
  };
};

export const MissionCommentEntryFragmentDoc = gql`
  fragment missionCommentEntry on FailureComment {
    id
    missionFailureMinutesToSolve
    missionFailureMinutesForEmergencyProcess
    missionFailureReasonComments
    missionFailureReasonId
    missionFailureReason {
      id
    }
    missionId
    missionFailureLocation {
      id
    }
    missionFailureLocationId
    missionFailureStartDateTime
    missionFailureEndDateTime
    missionFailureShiftGroup {
      id
    }
    missionFailureShiftGroupId
    durationOfMissionInFailed
  }
`;
export const MissionArchiveEntryFragmentDoc = gql`
  fragment missionArchiveEntry on MissionTraceArchive {
    id
    mapId
    workAreaId
    missionName
    processChainName
    materialNumber
    successfulSteps
    totalSteps
    lastStepType
    lastStepDetail
    createdUtc
    completedUtc
    fifoOrderTimeUtc
    provisioningTimeUtc
    vehicleId
    vehicleName
    fleetId
    fleetName
    status
    triggerSource
    processChainTraceId
    hasManualSupport
    failureComment {
      ...missionCommentEntry
    }
    vehicleType
    missionTraceReportFields {
      missionTraceArchive {
        missionId
      }
      id
    }
    loadType
  }
  ${MissionCommentEntryFragmentDoc}
`;
export const FilterValuesDocument = gql`
  query filterValues {
    missionArchiveFilters {
      mapIds
      processChainNames
      missionNames
      matNumbers
      vehicleNames
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FilterValuesGQL extends Apollo.Query<FilterValuesQuery, FilterValuesQueryVariables> {
  document = FilterValuesDocument;
  client = 'missionArchiveClient';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MissionArchiveLogPageDocument = gql`
  query missionArchiveLogPage(
    $offset: Int
    $pageSize: Int
    $filter: MissionTraceArchiveFilterInput
    $order: [MissionTraceArchiveSortInput!]
  ) {
    missionArchives(skip: $offset, take: $pageSize, where: $filter, order: $order) {
      totalCount
      items {
        ...missionArchiveEntry
      }
    }
  }
  ${MissionArchiveEntryFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MissionArchiveLogPageGQL extends Apollo.Query<
  MissionArchiveLogPageQuery,
  MissionArchiveLogPageQueryVariables
> {
  document = MissionArchiveLogPageDocument;
  client = 'missionArchiveClient';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TourFilterValuesDocument = gql`
  query tourFilterValues {
    tourArchiveFilters {
      mapIds
      processChainNames
      missionNames
      vehicleNames
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TourFilterValuesGQL extends Apollo.Query<
  TourFilterValuesQuery,
  TourFilterValuesQueryVariables
> {
  document = TourFilterValuesDocument;
  client = 'missionArchiveClient';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
