import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  IncludeTuggerTrainErrorsDto,
  IpstServiceFeatures,
  NewIpstToggleDto,
} from 'core/dtos/settings/ipst-service-features.dto';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class IpstFeatureService extends TenantHttpClient {
  protected apiUrl = environment.Services.Ipst;
  private readonly servicePath = API_SERVICES.Features;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getIpstServiceFeatures(): Observable<IpstServiceFeatures> {
    return this.get<IpstServiceFeatures>(`${this.servicePath}`);
  }

  updateIncludeTuggerTrainErrors(value: boolean): Observable<IncludeTuggerTrainErrorsDto> {
    return this.put<IncludeTuggerTrainErrorsDto>(`${this.servicePath}/IncludeTuggerTrainErrors`, {
      isToggledOn: value,
    });
  }

  updateNewIpstToggle(value: boolean): Observable<NewIpstToggleDto> {
    return this.put<NewIpstToggleDto>(`${this.servicePath}/NewIpst`, {
      isToggledOn: value,
    });
  }
}
