/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TourChainService } from 'core/api-services';
import { TourGenerationFailureReason } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import * as TourConfigurationActions from '../actions/tour-configuration.actions';

@Injectable()
export class TourConfigurationEffects {
  triggerTourConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TourConfigurationActions.triggerTourConfiguration),
      map(action => action.tourConfiguration),
      concatMap(payload => {
        return this.tourChainService.triggerTourConfiguration(payload.id).pipe(
          map(() =>
            TourConfigurationActions.triggerTourConfigurationSuccess({
              tourConfiguration: payload,
            })
          ),
          catchError(errorMessage =>
            of(
              TourConfigurationActions.triggerTourConfigurationFailure({
                errorMessage: errorMessage,
                tourConfiguration: payload,
              })
            )
          )
        );
      })
    )
  );

  triggerTourConfigurationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TourConfigurationActions.triggerTourConfigurationSuccess),
        tap(({ tourConfiguration }) => {
          this.toastService.createSuccessToast(
            'jobs.tourChainConfig.triggerTourConfig.triggerTourConfigSuccess',
            { name: tourConfiguration.name }
          );
        })
      ),
    { dispatch: false }
  );

  triggerTourConfigurationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TourConfigurationActions.triggerTourConfigurationFailure),
        tap(({ errorMessage, tourConfiguration }) => {
          const failureReason = TourGenerationFailureReason[errorMessage];
          this.toastService.createErrorToast(
            'jobs.tourChainConfig.triggerTourConfig.triggerTourConfigFailure',
            {
              name: tourConfiguration.name,
              failureReason: this.translate.get(
                `enums.TourGenerationFailureReason.${failureReason}`
              ),
            }
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly tourChainService: TourChainService,
    private readonly toastService: ToastService,
    private readonly translate: AtsTranslationService
  ) {}
}
