import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IntersectionCollisionPoint } from 'core/dtos';
import { GuidString } from 'core/models';
import * as IntersectionCollisionsActions from '../actions/intersection-collision-points.actions';

export const featureKey = 'intersectionCollisions';

export interface IntersectionCollisionsState extends EntityState<IntersectionCollisionPoint> {
  loaded: boolean;
  errorMessage: string;
  selectedCollisionId: GuidString;
}

// eslint-disable-next-line max-len
export const intersectionCollisionAdapter: EntityAdapter<IntersectionCollisionPoint> =
  createEntityAdapter<IntersectionCollisionPoint>();

export const initialState: IntersectionCollisionsState =
  intersectionCollisionAdapter.getInitialState({
    loaded: false,
    errorMessage: '',
    selectedCollisionId: '',
  });

const intersectionCollisionsReducer = createReducer(
  initialState,

  on(IntersectionCollisionsActions.loadIntersectionCollisionPoints, state => ({
    ...state,
    loaded: false,
    errorMessage: '',
  })),

  on(
    IntersectionCollisionsActions.loadIntersectionCollisionPointsSuccess,
    (state, { intersectionCollisionPoints }) =>
      intersectionCollisionAdapter.setAll(intersectionCollisionPoints, {
        ...state,
        loaded: true,
      })
  ),

  on(
    IntersectionCollisionsActions.loadIntersectionCollisionPointsFailure,
    (state, { errorMessage }) => ({
      ...state,
      loaded: false,
      errorMessage,
    })
  ),

  on(
    IntersectionCollisionsActions.signalRCreateIntersectionCollisionPoint,
    (state, { intersectionCollisionPoints }) =>
      intersectionCollisionAdapter.addOne(intersectionCollisionPoints, state)
  ),

  on(
    IntersectionCollisionsActions.signalRUpdateIntersectionCollisionPoint,
    (state, { intersectionCollisionPoints }) => {
      return intersectionCollisionAdapter.upsertOne(intersectionCollisionPoints, state);
    }
  ),

  on(
    IntersectionCollisionsActions.signalRDeleteIntersectionCollisionPoint,
    (state, { intersectionCollisionPoints }) =>
      intersectionCollisionAdapter.removeOne(intersectionCollisionPoints.id.toString(), state)
  ),

  on(
    IntersectionCollisionsActions.signalRUpdateIntersectionCollisionPointList,
    (state, { intersectionCollisionPointList }) => {
      const intersectionCollState = intersectionCollisionAdapter.removeMany(
        getAllIntersectionCollisions(state)
          .filter(
            c =>
              !intersectionCollisionPointList.includes(c) &&
              c.mapId === intersectionCollisionPointList[0].mapId
          )
          .map(col => col.id.toString()),
        state
      );
      return intersectionCollisionAdapter.addMany(
        intersectionCollisionPointList.filter(
          c => !getAllIntersectionCollisions(intersectionCollState).includes(c)
        ),
        intersectionCollState
      );
    }
  )
);

export function reducer(
  state: IntersectionCollisionsState | undefined,
  action: Action
): IntersectionCollisionsState {
  return intersectionCollisionsReducer(state, action);
}

export const { selectEntities, selectAll } = intersectionCollisionAdapter.getSelectors();

export const getIntersectionCollisionsLoaded = (state: IntersectionCollisionsState): boolean =>
  state.loaded;
export const getIntersectionCollisionErrorMessage = (state: IntersectionCollisionsState): string =>
  state.errorMessage;
export const getIntersectionCollisionEntities = selectEntities;
export const getAllIntersectionCollisions = selectAll;
