import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GuidString, WorkingArea } from 'core/models';
import { SessionService } from 'core/services/session.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filterUndefined } from 'core/helpers';
import { RootState } from 'store/reducers';
import { selectReducedSelectedWorkingArea } from 'store/selectors';

@Injectable()
export class PermissionService implements OnDestroy {
  tenantLoaded?: Promise<WorkingArea>;
  tenant?: WorkingArea;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly sessionService: SessionService,
    private readonly store: Store<RootState>
  ) {
    this.store
      .pipe(
        select(selectReducedSelectedWorkingArea),
        filterUndefined(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(wa => {
        this.tenant = wa;
        this.tenantLoaded = Promise.resolve(wa);
      });
  }

  actionAllowed(action: string): boolean {
    const user = this.sessionService.getCurrentUser();

    if (!user) {
      return false;
    }

    if (user.allowedActionsForEnvironment.includes(action)) {
      return true;
    }

    if (
      this.tenant &&
      user.allowedActionsByOrgId[this.tenant.organizationId.toString()]?.includes(action)
    ) {
      return true;
    }

    if (
      this.tenant &&
      user.allowedActionsByWorkingAreaId[this.tenant.id.toString()]?.includes(action)
    ) {
      return true;
    }

    return false;
  }

  actionAllowedInOrg(action: string, orgId: GuidString): boolean {
    const user = this.sessionService.getCurrentUser();

    if (!user) {
      return false;
    }

    if (user.allowedActionsForEnvironment.includes(action)) {
      return true;
    }

    if (orgId && user.allowedActionsByOrgId[orgId.toString()]?.includes(action)) {
      return true;
    }

    return false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  actionAllowedInWa(action: string, workingAreaId: GuidString, orgId: GuidString): boolean {
    const user = this.sessionService.getCurrentUser();
    if (!user) {
      return false;
    }

    if (user.allowedActionsForEnvironment.includes(action)) {
      return true;
    }

    if (orgId && user.allowedActionsByOrgId[orgId.toString()]?.includes(action)) {
      return true;
    }

    if (
      workingAreaId &&
      user.allowedActionsByWorkingAreaId[workingAreaId.toString()]?.includes(action)
    ) {
      return true;
    }

    return false;
  }
}
