import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DsMenuItem } from '@bmw-ds/components/ds-menu/ds-menu.interface';
import { Store, select } from '@ngrx/store';
import { UserSettingsService } from 'core/api-services';
import { ROUTER_DATA_WORKING_AREA_AGNOSTIC } from 'core/constants';
import { Breadcrumb, EntityList, GuidString, WorkingArea } from 'core/models';
import { AtsTranslationService, BreadcrumbService, TenantRouterService } from 'core/services';
import { last } from 'lodash';
import { Subject, firstValueFrom } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BreadcrumbItem } from 'shared/components/breadcrumb-bar/breadcrumb-item';
import * as fromRoot from 'store/index';

import { BreadcrumbDropdownService } from './service/breadcrumb-dropdown.service';

@Component({
  selector: 'app-breadcrumb-bar',
  templateUrl: './breadcrumb-bar.component.html',
  styleUrls: ['./breadcrumb-bar.component.scss'],
})
export class BreadcrumbBarComponent implements OnInit, OnDestroy {
  breadcrumbItems: BreadcrumbItem[] = [];
  allDropdownItems: EntityList[] = [];
  selectedItem!: EntityList;
  selectedWorkingArea?: WorkingArea;
  shouldHideSideMenuOnOrgSettings = false;
  shouldHideBreadCrumb = false;
  ngUnsubscribe = new Subject<void>();
  selectedOrganization?: string;

  workingAreasList: DsMenuItem[] = [];
  mapDropDownList: DsMenuItem[] = [];

  private readonly welcomeUrlPrefix = '/welcome/plant/view/';
  private readonly organizationSettingsKey = 'breadcrumb.settings.organizationSettings';
  private readonly pathsToIgnore = 3;

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly atsTranslationService: AtsTranslationService,
    private readonly router: Router,
    private readonly tenantRouter: TenantRouterService,
    private readonly breadcrumbDropdownService: BreadcrumbDropdownService,
    private readonly userSettingsService: UserSettingsService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.getRouterInfoSelectedOrganization();
    this.breadcrumbService.breadcrumbChanged
      .pipe(
        filter(crumbs => crumbs.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(crumbs => {
        this.breadcrumbItems = crumbs.map(this.buildBreadcrumbs.bind(this));
        this.cdRef.markForCheck();
        this.getRouterInfoSelectedOrganization();
        this.updateCrumbForOrgSettings();
      });
  }

  ngOnInit(): void {
    this.atsTranslationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      const items = this.breadcrumbItems
        .filter(item => item.key)
        .map(item => {
          item.label = this.atsTranslationService.get(item.key);
          return item;
        });

      this.breadcrumbItems = items;
      this.updateCrumbForOrgSettings();
      this.cdRef.markForCheck();
    });

    this.breadcrumbDropdownService
      .getSelectedItem()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectedItem: EntityList | undefined) => {
        if (selectedItem) {
          this.selectedItem = selectedItem;
          this.cdRef.markForCheck();
        }
      });

    this.breadcrumbDropdownService
      .getDropdownList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(dropdownList => {
        this.allDropdownItems = dropdownList;

        this.mapDropDownList = dropdownList.map(item => ({
          id: item.id.toString(),
          label: item.name,
        }));
        this.cdRef.markForCheck();
      });

    this.rootStore
      .pipe(
        select(fromRoot.selectSelectedOrganizationReducedWorkingAreas),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(workingAreas => {
        this.workingAreasList = workingAreas.map(wa => ({ id: wa.id.toString(), label: wa.name }));
        this.cdRef.markForCheck();
      });

    this.rootStore
      .pipe(select(fromRoot.selectReducedSelectedWorkingArea), takeUntil(this.ngUnsubscribe))
      .subscribe(workingArea => {
        if (workingArea) {
          this.selectedWorkingArea = workingArea;
          this.cdRef.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getRouterInfoSelectedOrganization(): void {
    this.rootStore
      .select(fromRoot.getRouterInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(routerInfo => {
        this.selectedOrganization =
          routerInfo && routerInfo.params ? routerInfo.params.organizationName : undefined;
        this.shouldHideBreadCrumb =
          routerInfo && routerInfo.url ? routerInfo.url.includes('auditlog') : false;
        this.shouldHideSideMenuOnOrgSettings =
          routerInfo && routerInfo.url
            ? routerInfo.url.includes('organization-settings') ||
              routerInfo.url.includes('auditlog')
            : false;
        this.cdRef.markForCheck();
      });
  }

  async setSelectedWorkingArea(selectedOption: DsMenuItem): Promise<void> {
    if (this.selectedWorkingArea?.id !== selectedOption.id) {
      const workingAreaId: GuidString = selectedOption.id;
      const settings = await firstValueFrom(this.userSettingsService.getUserSettings());

      this.rootStore.dispatch(fromRoot.selectWorkingArea({ workingAreaId: workingAreaId }));
      this.userSettingsService
        .updateUserSettings({
          defaultWorkingArea: workingAreaId.toString(),
          defaultStartPage: settings.defaultStartPage,
        })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          const featureBreadcrumbUrl =
            last(this.breadcrumbItems.filter(o => o.workingAreaAgnostic))?.routerLink || '';
          if (typeof featureBreadcrumbUrl === 'string') {
            const url = featureBreadcrumbUrl.split('/').splice(this.pathsToIgnore);
            const fallbackUrl = this.router.url.split('/').splice(this.pathsToIgnore);
            void this.tenantRouter.navigate(url.length > 0 ? url : fallbackUrl);
          }
        });
    }
  }

  buildBreadcrumbs(crumb: Breadcrumb): BreadcrumbItem {
    this.updateCrumbForOrgSettings();
    return {
      label: this.atsTranslationService.get(crumb.displayName),
      routerLink: decodeURI(crumb.url),
      key: crumb.displayName,
      workingAreaAgnostic: crumb?.data[ROUTER_DATA_WORKING_AREA_AGNOSTIC] || false,
    };
  }

  changeItemSelected(itemSelected: DsMenuItem): void {
    const itemId: GuidString = itemSelected.id;
    const item = this.allDropdownItems.find(item => item.id === itemId);
    if (item) {
      this.breadcrumbDropdownService.setSelectedItem(item);
    }
  }

  updateCrumbForOrgSettings(): void {
    if (this.shouldHideSideMenuOnOrgSettings) {
      this.breadcrumbItems
        .filter(
          item =>
            item.label === this.selectedOrganization || item.key === this.organizationSettingsKey
        )
        .forEach(item => {
          if (item.key === this.organizationSettingsKey) {
            item.label = this.selectedOrganization ?? item.label;
          }
          item.routerLink = this.welcomeUrlPrefix + this.selectedOrganization;
        });
    }
  }
}
