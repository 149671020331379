/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProcessChainGroupTraceService } from 'core/api-services';
import { TEN_SECONDS } from 'core/constants';
import { ProcessChainGroupTraceStatus } from 'core/models';
import { ToastService } from 'core/services';
import { of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, tap } from 'rxjs/operators';
import * as ProcessChainGroupTraceActions from '../actions/process-chain-group-trace.actions';

@Injectable()
export class ProcessChainGroupTraceEffects {
  loadProcessChainGroupTraces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainGroupTraceActions.loadProcessChainGroupTraces),
      concatMap(() =>
        this.processChainGroupTrace.getAllProcessChainGroupTraces().pipe(
          map(processChainGroupTraces =>
            ProcessChainGroupTraceActions.loadProcessChainGroupTracesSuccess({
              processChainGroupTraces,
            })
          ),
          catchError(errorMessage =>
            of(ProcessChainGroupTraceActions.loadProcessChainGroupTracesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadProcessChainGroupTracesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainGroupTraceActions.loadProcessChainGroupTracesFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'jobs.processChainGroupTraceActive.loadProcessChainGroupTracesFailure'
          );
        })
      ),
    { dispatch: false }
  );

  signalRUpdateProcessChainGroupTrace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainGroupTraceActions.signalRUpdateProcessChainGroupTrace),
      filter(
        ({ processChainGroupTrace }) =>
          processChainGroupTrace.status === ProcessChainGroupTraceStatus.Completed
      ),
      delay(TEN_SECONDS),
      map(({ processChainGroupTrace }) =>
        ProcessChainGroupTraceActions.deleteCompletedProcessChainGroupTrace({
          processChainGroupTrace,
        })
      )
    )
  );

  abortProcessChainGroupTraces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainGroupTraceActions.abortProcessChainGroupTrace),
      concatMap(({ processChainGroupTraceId, abortOptions }) =>
        this.processChainGroupTrace
          .abortProcessChainGroupTrace(processChainGroupTraceId, abortOptions)
          .pipe(
            map(() => ProcessChainGroupTraceActions.abortProcessChainGroupTraceSuccess()),
            catchError(errorMessage =>
              of(ProcessChainGroupTraceActions.abortProcessChainGroupTraceFailure({ errorMessage }))
            )
          )
      )
    )
  );

  abortProcessChainGroupTraceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainGroupTraceActions.abortProcessChainGroupTraceSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.processChainGroupTraceAborted');
        })
      ),
    { dispatch: false }
  );

  abortProcessChainGroupTracesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainGroupTraceActions.abortProcessChainGroupTraceFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.errorAbortingProcessChainGroupTrace');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly processChainGroupTrace: ProcessChainGroupTraceService
  ) {}
}
