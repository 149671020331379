/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrderService } from 'core/api-services';
import { TEN_SECONDS } from 'core/constants';
import { OrderStatus } from 'core/models';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, tap } from 'rxjs/operators';
import * as OrderActions from '../actions/order.actions';

@Injectable()
export class OrderEffects {
  loadOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.loadOrders),
      concatMap(() =>
        this.orderService.getAllActiveOrders().pipe(
          map(orders => OrderActions.loadOrdersSuccess({ orders })),
          catchError(errorMessage => of(OrderActions.loadOrdersFailure({ errorMessage })))
        )
      )
    )
  );

  loadOrdersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderActions.loadOrdersFailure),
        tap(() => {
          this.toastService.createErrorToast('jobs.order.loadOrdersFailure');
        })
      ),
    { dispatch: false }
  );

  loadHistoryOrderFilterOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.loadHistoryOrderFilterOptions),
      concatMap(() =>
        this.orderService.getOrderHistoryFilterOptions().pipe(
          map(
            historyOrderFilterOptions =>
              OrderActions.historyOrderFilterOptionsLoaded({ historyOrderFilterOptions }),
            catchError(errorMessage => of(OrderActions.loadOrdersFailure({ errorMessage })))
          )
        )
      )
    )
  );

  signalRUpdateOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.signalRUpdateOrder),
      filter(
        ({ order }) =>
          order.orderStatus === OrderStatus.Completed ||
          order.orderStatus === OrderStatus.CompletedAndReportSkipped ||
          order.orderStatus === OrderStatus.CompletedAndReported ||
          order.orderStatus === OrderStatus.Canceled ||
          order.orderStatus === OrderStatus.AbortedWithoutConfirmation
      ),
      delay(TEN_SECONDS),
      map(({ order }) => OrderActions.removeOrder({ order }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly orderService: OrderService,
    private readonly toastService: ToastService
  ) {}
}
