/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PoiService } from 'core/api-services';
import { PoiDto } from 'core/dtos';
import { GuidString, PoiGroupTreeTable } from 'core/models';
import { ModalDialogService } from 'library/components/modal-dialog';
import { flatten } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { setHasChanges } from 'store/actions/ui.actions';

import { ToastService } from 'core/services/toast.service';
import * as PoiGroupsActions from '../actions/poi-groups.actions';
import * as PoisActions from '../actions/pois.actions';

@Injectable()
export class PoiEffects {
  loadPois$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.loadPoiGroupsSuccess),
      map(({ poiGroups }) =>
        PoisActions.loadPoisSuccess({
          pois: flatten(
            poiGroups.filter(Boolean).map(poiGroup =>
              poiGroup.pointsOfInterest.map(poi => ({
                ...poi,
                poiGroupId: poiGroup.id !== poi.id ? poiGroup.id : undefined,
              }))
            )
          ),
        })
      )
    )
  );

  addPoi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.addPoi),
      concatMap(state =>
        this.poiService.createPoi(state.poi).pipe(
          map(poi => PoisActions.addPoiSuccess({ poi })),
          catchError(errorMessage => of(PoisActions.addPoiFailure({ errorMessage })))
        )
      )
    )
  );

  addPoiSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.addPoiSuccess),
      tap(state => {
        this.toastService.createSuccessToast('shared.actions.created', {
          name: state.poi.name,
        });
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  updatePoi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.updatePoi),
      concatMap(({ poi }) =>
        this.poiService.updatePoi(poi).pipe(
          map(() => PoisActions.updatePoiSuccess({ poi })),
          catchError(errorMessage => of(PoisActions.updatePoiFailure({ errorMessage })))
        )
      )
    )
  );

  updatePoiSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.updatePoiSuccess),
      tap(state => {
        this.toastService.createSuccessToast('shared.actions.saved', {
          name: state.poi.name,
        });
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  deletePoi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.deletePoi),
      concatMap(({ poi }) =>
        this.poiService.deletePoi(poi).pipe(
          map(() => PoisActions.deletePoiSuccess({ poi })),
          catchError(errorMessage => of(PoisActions.deletePoiFailure({ errorMessage })))
        )
      )
    )
  );

  deletePoiSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.deletePoiSuccess),
      tap(response => {
        this.toastService.createSuccessToast('shared.actions.deleted', {
          name: response.poi.name,
        });
      }),
      map(() => setHasChanges({ hasChanges: false }))
    )
  );

  deletePoiFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoisActions.deletePoiFailure),
        tap(state => {
          this.modalService.createModal('shared.modalDeleteError', state, 'critical');
        })
      ),
    { dispatch: false }
  );

  updatePoiFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoisActions.updatePoiFailure),
        tap(state => {
          this.modalService.createModal('shared.modalUpdateError', state, 'critical');
        })
      ),
    { dispatch: false }
  );

  ungroupPoi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.ungroupPoi),
      concatMap(({ poi }) =>
        this.poiService.ungroupPoi(poi).pipe(
          map(() => this.ungroupPoiAction(poi.id, poi.name)),
          catchError(errorMessage => of(PoisActions.ungroupPoiFailure({ errorMessage })))
        )
      )
    )
  );

  ungroupPoiSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoisActions.ungroupPoiSuccess),
        tap(state => {
          this.toastService.createSuccessToast('shared.actions.ungrouped', {
            name: state.poi.name,
          });
        })
      ),
    { dispatch: false }
  );

  ungroupPoiGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoiGroupsActions.ungroupPoiGroupSuccess),
      concatMap(({ poiGroup }) =>
        ((poiGroup as PoiGroupTreeTable).children || []).map(poi =>
          this.ungroupPoiAction(poi.id, poi.name)
        )
      )
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PoisActions.loadPoisFailure,
          PoisActions.addPoiFailure,
          PoisActions.ungroupPoiFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  resetActionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PoisActions.loadPoisSuccess,
        PoisActions.addPoiSuccess,
        PoisActions.updatePoiSuccess,
        PoisActions.deletePoiSuccess,
        PoisActions.loadPoisFailure,
        PoisActions.addPoiFailure,
        PoisActions.updatePoiFailure,
        PoisActions.deletePoiFailure
      ),
      map(() => PoisActions.resetActionStatus())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly poiService: PoiService,
    private readonly toastService: ToastService,
    private readonly modalService: ModalDialogService
  ) {}

  private ungroupPoiAction(id?: GuidString, name?: string): Action<'[Pois] Ungroup Poi Success'> {
    return PoisActions.ungroupPoiSuccess({
      poi: {
        id,
        name,
        poiGroupId: undefined,
      } as PoiDto,
    });
  }
}
