<app-base-page-layout
  [pageHeading]="pageHeading"
  [hasToolbar]="hasToolbar"
  [isNewLayout]="isNewLayout">
  <div
    base-page-content
    class="content-area-list-layout"
    [class.edit]="isEditMode && !isNewLayout"
    [class.view]="!isEditMode && !isNewLayout"
    [class.editView]="isNewLayout">
    <div
      *ngIf="showSidePanel"
      [ngClass]="{
        closeSidepanel: !isSidePanelOpen
      }"
      class="sidepanel-area pt-5x">
      <div>
        <h3
          data-cy="sidepanelHeading"
          [attr.data-translatekey]="sidepanelHeading"
          *ngIf="sidepanelHeading">
          {{ sidepanelHeading | translate }}
        </h3>
        <div class="sidepanel-area-content">
          <ng-content select="[sidepanel]"></ng-content>
        </div>
      </div>
    </div>

    <div class="verticalLineSep" *ngIf="showSidePanel">
      <span class="iwp-icon-ci_pause_active" (click)="openClosePanel()"></span>
    </div>

    <div class="list-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col pb-0x">
            <ng-content select="[headingSuffix]"></ng-content>
            <div class="expand-collapse-buttons" *ngIf="showCollapseAndExpandButtons">
              <app-expand-collapse-buttons></app-expand-collapse-buttons>
            </div>
          </div>
        </div>
      </div>
      <ng-content select="[list]"></ng-content>
    </div>
  </div>
</app-base-page-layout>
