import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { BaseZoneSetDto, ZoneSetDto } from 'core/dtos';
import { GuidString, ZoneSetSetActiveInput } from 'core/models';

export enum ZoneSetTypes {
  LoadZoneSets = '[Zone Sets] Load Zone Sets',
  LoadZoneSetsSuccess = '[Zone Sets] Load Zone Sets Success',
  LoadZoneSetsFailure = '[Zone Sets] Load Zone Sets Failure',

  UpdateArchiveZoneSetSuccess = '[Zone Sets] Update Archive Zone Set Success',
  UpdateArchiveZoneSetFailed = '[Zone Sets] Update Archive Zone Set Failure',

  LoadArchiveZoneSet = '[Zone Sets] Load Archive Zone Set',
  LoadArchiveZoneSets = '[Zone Sets] Load Archive Zone Sets',

  AddZoneSet = '[Zone Sets] Add Zone Set',
  AddZoneSetFromTemplate = '[Zone Sets] Add Zone Set From Template',
  AddZoneSetSuccess = '[Zone Sets] Add Zone Set Success',
  AddZoneSetSuccessAndNavigate = '[Zone Sets] Add Zone Set Success And Navigate',
  AddZoneSetFailure = '[Zone Sets] Add Zone Set Fail',

  UpdateZoneSet = '[Zone Sets] Update Zone Set',
  UpdateZoneSetSuccess = '[Zone Sets] Update Zone Set Success',
  UpdateZoneSetFailure = '[Zone Sets] Update Zone Set Failure',

  DeleteZoneSet = '[Zone Sets] Delete Zone Set',
  DeleteZoneSetSuccess = '[Zone Sets] Delete Zone Set Success',
  DeleteZoneSetFailure = '[Zone Sets] Delete Zone Set Failure',

  SetActiveZoneSet = '[Zone Sets] Set Active Zone Set',
  SetActiveZoneSetSuccess = '[Zone Sets] Set Active Zone Set Success',
  SetActiveZoneSetFailure = '[Zone Sets] Set Active Zone Set Failure',

  SelectZoneSet = '[Zone Sets] Select Zone Set',

  GetHasOverlapsZoneSet = '[Zone Sets] Get Has Overlaps For Zone Set',
  GetHasOverlapsSuccess = '[Zone Sets] Get Has Overlaps For Zone Set Success',
  GetHasOverlapsFailure = '[Zone Sets] Get Has Overlaps For Zone Set Failure',
}

export enum SignalRZoneSetTypes {
  SignalrCreateZoneSet = '[SignalR] Create Zone Set',
  SignalrUpdateZoneSet = '[SignalR] Update Zone Set',
  SignalrDeleteZoneSet = '[SignalR] Delete Zone Set',
}

export const loadZoneSets = createAction(ZoneSetTypes.LoadZoneSets);

export const loadArchiveZoneSets = createAction(
  ZoneSetTypes.LoadArchiveZoneSets,
  props<{ zoneSets: ZoneSetDto[] }>()
);

export const loadZoneSetsSuccess = createAction(
  ZoneSetTypes.LoadZoneSetsSuccess,
  props<{ zoneSets: ZoneSetDto[] }>()
);

export const loadZoneSetsFailure = createAction(
  ZoneSetTypes.LoadZoneSetsFailure,
  props<{ errorMessage: string }>()
);

export const loadZoneSetFailure = createAction(
  ZoneSetTypes.LoadZoneSetsFailure,
  props<{ errorMessage: string }>()
);

export const updateZoneSet = createAction(
  ZoneSetTypes.UpdateZoneSet,
  props<{ zoneSet: ZoneSetDto }>()
);

export const updateZoneSetSuccess = createAction(
  ZoneSetTypes.UpdateZoneSetSuccess,
  props<{ zoneSet: Update<ZoneSetDto> }>()
);

export const updateZoneSetFailure = createAction(
  ZoneSetTypes.UpdateZoneSetFailure,
  props<{ errorMessage: string }>()
);

export const addZoneSet = createAction(
  ZoneSetTypes.AddZoneSet,
  props<{ zoneSet: BaseZoneSetDto }>()
);

export const addZoneSetFromTemplate = createAction(
  ZoneSetTypes.AddZoneSetFromTemplate,
  props<{ fromZoneSetId: GuidString; zoneSet: BaseZoneSetDto }>()
);

export const addZoneSetSuccess = createAction(
  ZoneSetTypes.AddZoneSetSuccess,
  props<{ zoneSet: ZoneSetDto }>()
);

export const addZoneSetSuccessAndNavigate = createAction(
  ZoneSetTypes.AddZoneSetSuccessAndNavigate,
  props<{ zoneSet: ZoneSetDto }>()
);

export const addZoneSetFailure = createAction(
  ZoneSetTypes.AddZoneSetFailure,
  props<{ errorMessage: string }>()
);

export const deleteZoneSet = createAction(
  ZoneSetTypes.DeleteZoneSet,
  props<{ zoneSetId: GuidString }>()
);

export const deleteZoneSetSuccess = createAction(
  ZoneSetTypes.DeleteZoneSetSuccess,
  props<{ zoneSetId: GuidString }>()
);

export const deleteZoneSetFailure = createAction(
  ZoneSetTypes.DeleteZoneSetFailure,
  props<{ errorMessage: string }>()
);

export const setActiveZoneSet = createAction(
  ZoneSetTypes.SetActiveZoneSet,
  props<{ setToActiveInput: ZoneSetSetActiveInput }>()
);

export const setActiveZoneSetSuccess = createAction(
  ZoneSetTypes.SetActiveZoneSetSuccess,
  props<{ zoneSets: ZoneSetDto[] }>()
);

export const setActiveZoneSetFailure = createAction(
  ZoneSetTypes.SetActiveZoneSetFailure,
  props<{ errorMessage: string }>()
);

export const selectZoneSet = createAction(
  ZoneSetTypes.SelectZoneSet,
  props<{ zoneSetId: GuidString }>()
);

export const getHasZoneOverlapsForZoneSet = createAction(
  ZoneSetTypes.GetHasOverlapsZoneSet,
  props<{ zoneSetId: GuidString }>()
);

export const getHasZoneOverlapsForZoneSetSuccess = createAction(
  ZoneSetTypes.GetHasOverlapsSuccess,
  props<{ hasOverlaps: boolean }>()
);

export const getHasZoneOverlapsForZoneSetFailure = createAction(
  ZoneSetTypes.GetHasOverlapsFailure,
  props<{ errorMessage: string }>()
);

export const signalRCreateZoneSet = createAction(
  SignalRZoneSetTypes.SignalrCreateZoneSet,
  props<{ zoneSet: ZoneSetDto }>()
);

export const signalRUpdateZoneSet = createAction(
  SignalRZoneSetTypes.SignalrUpdateZoneSet,
  props<{ zoneSet: ZoneSetDto }>()
);

export const signalRDeleteZoneSet = createAction(
  SignalRZoneSetTypes.SignalrDeleteZoneSet,
  props<{ zoneSet: ZoneSetDto }>()
);
