import { DateString } from 'core/models';

export interface ChatMessage {
  text: string;
  time: DateString;
  type: 'bot' | 'user';
}

export enum AvailableBots {
  Handbook = 'o9fptjpshr7vksegldqt88mz',
  VDA5050Spec = 'fyzudlehk3jl7wcj24p7bned',
}
