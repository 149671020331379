import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';
import { PermissionService } from 'core/services';
import { SessionService } from 'core/services/session.service';
import { RolesSignalRService, UserPermissionsSignalRService } from 'core/signalR/modules';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate, CanActivateChild {
  readonly userPermissionsSignalRSubscriber;
  readonly rolesSignalRSubscriber;
  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService,
    private readonly permissionService: PermissionService,
    private readonly userPermissionsSignalRService: UserPermissionsSignalRService,
    private readonly rolesSignalRService: RolesSignalRService
  ) {
    this.userPermissionsSignalRSubscriber =
      this.userPermissionsSignalRService.signalrSubscriberFactory(AuthorizationGuard.name);
    this.rolesSignalRSubscriber = this.rolesSignalRService.signalrSubscriberFactory(
      AuthorizationGuard.name
    );
  }

  async canActivate({ data }: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    await Promise.all([this.sessionService.loginCompleted, this.permissionService.tenantLoaded]);

    const currentUser = this.sessionService.getCurrentUser();

    // check if route is restricted by required AtsActions
    if (currentUser && data?.permissions) {
      const hasPermission = data.permissions.every(
        this.permissionService.actionAllowed.bind(this.permissionService)
      );

      return hasPermission || this.router.createUrlTree(['/no-permission']);
    }

    if (currentUser?.id) {
      await Promise.all([
        this.userPermissionsSignalRSubscriber.joinUserPermissions(currentUser.id),
        this.rolesSignalRSubscriber.joinUserRoles(),
      ]);
    }

    return true;
  }

  async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(route);
  }
}
