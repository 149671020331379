import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { IconColorType } from '@bmw-ds/components';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { POIMatrixCalculationStatus, ZoneSetsListItem } from 'core/models';
import { Icons } from 'library/constants';
import { first, isEqual } from 'lodash';
@Component({
  selector: 'app-poi-matrix-calculation-cell',
  templateUrl: './poi-matrix-calculation-cell.component.html',
  styleUrls: ['./poi-matrix-calculation-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoiMatrixCalculationCellComponent implements ICellRendererAngularComp {
  activeZoneSets: ZoneSetsListItem[] = [];

  poiCalculationStatusIcon = Icons.Loading;

  poiCalculationStatusIconTooltipColor: IconColorType = 'positive';

  activeZoneSetLabel = '';
  poiCalculationStatusIconTooltip = 'zoneSets.info.successIcon';

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  agInit(params: ICellRendererParams<ZoneSetsListItem>): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<ZoneSetsListItem>): boolean {
    if (params.data) {
      this.setActiveZoneSets([params.data]);
    }

    return true;
  }

  setActiveZoneSets(activeZoneSets: ZoneSetsListItem[]): void {
    if (
      !this.activeZoneSets ||
      this.activeZoneSets.length === 0 ||
      !isEqual(activeZoneSets, this.activeZoneSets)
    ) {
      this.activeZoneSets = activeZoneSets;
      const activeZoneSet = first(activeZoneSets);
      this.activeZoneSetLabel = activeZoneSet?.name ?? '';
      activeZoneSet?.poiMatrixCalculationStatus === POIMatrixCalculationStatus.Latest
        ? this.setCalculatingIcon()
        : this.setLoadingIcon();
    }
    this.cdRef.markForCheck();
  }

  setCalculatingIcon(): void {
    this.poiCalculationStatusIcon = Icons.Check;
    this.poiCalculationStatusIconTooltip = 'zoneSets.info.successIcon';
    this.poiCalculationStatusIconTooltipColor = 'positive';
  }

  setLoadingIcon(): void {
    this.poiCalculationStatusIcon = Icons.Loading;
    this.poiCalculationStatusIconTooltip = 'zoneSets.info.loaderIcon';
    this.poiCalculationStatusIconTooltipColor = 'info';
  }
}
