import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AtsCopilotEffects } from './effects/ats-copilot.effects';
import { AtsCopilotReducer, featureKey } from './reducers/ats-copilot.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(featureKey, AtsCopilotReducer),
    EffectsModule.forFeature(AtsCopilotEffects),
  ],
})
export class AtsCopilotStoreModule {}
