import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import {
  InferenceRequestDto,
  InferenceRequestResponseDto,
  InferenceResponseDto,
  pollInferenceStatus,
} from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AtsCopilotService extends BaseHttpClient {
  private readonly servicePath = API_SERVICES.Copilot;
  protected apiUrl = environment.Services.Infobar;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  sendMessage(
    botId: string,
    inferenceRequest: InferenceRequestDto
  ): Observable<InferenceRequestResponseDto> {
    return this.post<InferenceRequestResponseDto>(
      `${this.servicePath}/invoke/${botId}`,
      inferenceRequest
    );
  }

  pollInference(
    botId: string,
    asyncId: string,
    sub: string
  ): Observable<InferenceResponseDto | pollInferenceStatus.ResponseStatus | null> {
    return this.requestWithStatus<InferenceResponseDto>(
      'get',
      `${this.servicePath}/invoke/${botId}/async/${asyncId}/${sub}`
    ).pipe(
      map(response => {
        if (response.status === HttpStatusCode.NoContent) {
          return pollInferenceStatus.ResponseStatus;
        } else {
          return response.body;
        }
      })
    );
  }
}
