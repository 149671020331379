import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RolesService } from 'core/api-services';
import { RoleDto } from 'core/dtos';
import { AtsActions, Role } from 'core/models';
import { EditBarService, PermissionService, ToolbarService } from 'core/services';
import { ModalDialogService } from 'library/components/modal-dialog';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import * as fromRoles from 'store-modules/settings-store';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-environment-roles-container',
  templateUrl: './environment-roles-container.component.html',
  styles: [':host { height: 100%; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentRolesContainerComponent implements OnInit, OnDestroy {
  roles$: Observable<RoleDto[]> = of([]);
  defaultAtsRoles$: Observable<RoleDto[]> = of([]);
  ngUnsubscribe = new Subject<void>();
  canEdit = false;
  hasChanges = false;
  searchTerm$: Observable<string> = of('');
  readonly DEFAULT_DEBOUNCE_TIME = 400;

  constructor(
    private readonly rolesService: RolesService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly permissionService: PermissionService,
    private readonly editBarService: EditBarService,
    private readonly modalService: ModalDialogService,
    private readonly toolbarService: ToolbarService
  ) {}

  ngOnInit(): void {
    this.getUserPermissions();
    this.dispatchActionsAndQuerySelectors();
    this.initializeSubscriptions();
  }

  dispatchActionsAndQuerySelectors(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: this.canEdit }));
    this.searchTerm$ = this.toolbarService.searchTerm$.pipe(
      debounceTime(this.DEFAULT_DEBOUNCE_TIME),
      distinctUntilChanged()
    );

    forkJoin({
      requestOne: this.rolesService.getDefaultAtsRoles(),
      requestTwo: this.rolesService.getRoles(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ requestOne, requestTwo }) => {
        this.defaultAtsRoles$ = of(requestOne);
        this.roles$ = of(requestTwo);
      });
  }

  getUserPermissions(): void {
    this.canEdit = this.permissionService.actionAllowed(AtsActions.Roles_Edit);
  }

  initializeSubscriptions(): void {
    this.editBarService.hasChanges$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(hasChanges => (this.hasChanges = hasChanges));
  }

  onSave(newRoles: Role[]): void {
    this.rootStore.dispatch(fromRoles.saveRoles({ roles: newRoles }));
    this.hasChanges = false;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.hasChanges) {
      return this.modalService.createModal('shared.modalExitEditMode').pipe(
        tap(res => {
          this.hasChanges = !res;
          this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: true }));
          this.rootStore.dispatch(fromRoot.setHasChanges({ hasChanges: !res }));
        })
      );
    }
    return true;
  }

  ngOnDestroy(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
