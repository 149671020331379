/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FleetService } from 'core/api-services';
import { FleetDto } from 'core/dtos';
import { NewFleetInput } from 'core/models';
import { TenantRouterService } from 'core/services/tenant-router.service';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions/ui.actions';

import * as FleetsActions from '../actions/fleets.actions';
import * as VehiclesActions from '../actions/vehicles.actions';

@Injectable()
export class FleetsEffects {
  loadFleets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetsActions.loadFleets),
      concatMap(() =>
        this.fleetService.getFleets().pipe(
          map(fleets => FleetsActions.loadFleetsSuccess({ fleets })),
          catchError(errorMessage => of(FleetsActions.loadFleetsFailure({ errorMessage })))
        )
      )
    )
  );

  loadFleetsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FleetsActions.loadFleetsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.loadFleetsFailure');
        })
      ),
    { dispatch: false }
  );

  addFleet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetsActions.addFleet),
      map(action => action.newFleet),
      concatMap((payload: NewFleetInput) => {
        return this.fleetService.createFleet(payload).pipe(
          concatMap(newFleet => [
            FleetsActions.addFleetSuccess({ newFleet: newFleet }),
            VehiclesActions.updateFleetIds({
              fleetId: newFleet.id,
              vehicles: payload.vehicles,
            }),
          ]),
          catchError(errorMessage => of(FleetsActions.addFleetFailure({ errorMessage })))
        );
      })
    )
  );

  addFleetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetsActions.addFleetSuccess),
      tap(({ newFleet }) => {
        this.toastService.createSuccessToast('shared.actions.saved', newFleet);
        this.router.navigate(['/vehicles/fleet/view/' + newFleet.id]).catch(() => {});
      }),
      switchMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateFleet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetsActions.updateFleet),
      map(action => action.fleet),
      concatMap(payload => {
        return this.fleetService.updateFleet(payload).pipe(
          switchMap(() => {
            return [
              FleetsActions.updateFleetSuccess({
                fleet: {
                  id: payload.id.toString(),
                  changes: payload,
                },
              }),
              VehiclesActions.updateFleetIds({
                fleetId: payload.id,
                vehicles: payload.vehicles,
              }),
            ];
          }),
          catchError(errorMessage => of(FleetsActions.updateFleetFailure({ errorMessage })))
        );
      })
    )
  );

  updateFleetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetsActions.updateFleetSuccess),
      tap(({ fleet }) => {
        this.toastService.createSuccessToast('shared.actions.saved', fleet.changes);
      }),
      switchMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  ungroupFleet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetsActions.ungroupFleet),
      map(action => action.fleetWithVehicles),
      concatMap((fleetWithVehicles: FleetDto) => {
        return this.fleetService.ungroupFleet(fleetWithVehicles.id).pipe(
          switchMap(() => [
            FleetsActions.ungroupFleetSuccess({ fleetWithVehicles: fleetWithVehicles }),
            VehiclesActions.ungroupVehiclesByFleetId({ fleetId: fleetWithVehicles.id }),
          ]),
          catchError(errorMessage => of(FleetsActions.ungroupFleetFailure({ errorMessage })))
        );
      })
    )
  );

  ungroupVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.ungroupVehicle),
      concatMap(payload => {
        return this.fleetService.updateFleet(payload.fleet).pipe(
          switchMap(updatedFleet => [
            FleetsActions.updateFleetSuccess({
              fleet: {
                id: updatedFleet.id.toString(),
                changes: updatedFleet,
              },
            }),
            VehiclesActions.ungroupVehicleSuccess({
              vehicle: {
                id: payload.vehicle.id.toString(),
                changes: { ...payload.vehicle, fleetId: undefined, fleetName: undefined },
              },
            }),
          ]),
          catchError(errorMessage => of(VehiclesActions.ungroupVehicleFailure({ errorMessage })))
        );
      })
    )
  );

  ungroupFleetSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FleetsActions.ungroupFleetSuccess),
        tap(action => {
          this.toastService.createSuccessToast('shared.actions.ungrouped', {
            name: action.fleetWithVehicles.name,
          });
          void this.router.navigate(['/vehicles']);
        })
      ),
    { dispatch: false }
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FleetsActions.addFleetFailure,
          FleetsActions.updateFleetFailure,
          FleetsActions.ungroupFleetFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly fleetService: FleetService,
    private readonly toastService: ToastService,
    private readonly router: TenantRouterService
  ) {}
}
