import { createSelector } from '@ngrx/store';
import { OpcuaDeviceTrafficLightStatus } from 'core/models';
import { NotificationPanelViewModel } from 'shared/components';
import { getConnectivityTrafficLightStatus } from 'store-modules/opcua-devices-store/selectors/opcua-devices.selectors.helpers';
import { selectSelectedWorkingAreaId, selectWorkingAreasEntities } from 'store/selectors';

import objectHelper from 'core/helpers/object.helper';
import * as fromOpcuaDevices from '../reducers/index';
import * as fromOpcuaDevicesReducer from '../reducers/opcua-devices.reducer';

const selectOpcuaDevicesState = createSelector(
  fromOpcuaDevices.getOpcuaDevicesFeatureState,
  fromOpcuaDevices.getOpcuaDevicesState
);

export const selectOpcuaDevicesLoaded = createSelector(
  selectOpcuaDevicesState,
  fromOpcuaDevicesReducer.getOpcuaDeviceLoaded
);

export const selectAllOpcuaDevices = createSelector(
  selectOpcuaDevicesState,
  fromOpcuaDevicesReducer.getAllOpcuaDevices
);

export const selectOpcuaDevicesEntities = createSelector(
  selectOpcuaDevicesState,
  fromOpcuaDevicesReducer.getOpcuaDeviceEntities
);

export const selectSelectedDevice = createSelector(
  selectOpcuaDevicesState,
  fromOpcuaDevicesReducer.getSelectedDevice
);

export const selectSelectedDeviceProperties = createSelector(
  selectSelectedDevice,
  selectAllOpcuaDevices,
  selectWorkingAreasEntities,
  (name, devices, workingAreas) => {
    let device = objectHelper.cloneDeep(devices.find(d => d.name === name));

    if (device === undefined) {
      device = {
        name: '',
        description: '',
        akz: '',
        streamingServiceName: '',
        nodes: [],
        server: '',
        pingNodeName: '',
        lastPingReceivedDate: null,
        notifications: [],
        workAreas: [],
        readonly: false,
        recipientKey: '',
        maxLatencyMs: null,
      };
    }

    device.workAreas = device.workAreas.map(wa => {
      return {
        ...wa,
        workAreaName: workingAreas[wa.workAreaId.toString()]?.name ?? '',
      };
    });

    return device;
  }
);

export const selectSelectedDeviceNodesState = createSelector(
  selectSelectedDevice,
  selectAllOpcuaDevices,
  (name, devices) => {
    const device = devices.find(d => d.name === name);

    return (
      device?.nodes
        .flatMap(o => ({ ...o.state, comment: o.comment }))
        .filter(o => o !== undefined) ?? []
    );
  }
);

export const selectAllErrors = createSelector(
  selectAllOpcuaDevices,
  selectSelectedWorkingAreaId,
  (devices, waId) => {
    return devices
      .filter(
        d =>
          d.pingNodeName &&
          getConnectivityTrafficLightStatus(d) === OpcuaDeviceTrafficLightStatus.LostConnection &&
          d.workAreas.find(o => o.workAreaId === waId) !== undefined
      )
      .map((d): NotificationPanelViewModel => {
        return {
          name: d.name,
          description: '',
          descriptionTranslationKey: 'notificationsPanel.opcuaDevicePingMissing',
          location: '',
          isNew: true,
          level: 'error',
          errors: [],
          occurredDateTimeUtc: new Date().toUTCString(),
          id: d.name,
          originArea: 'OpcuaDevices',
        };
      });
  }
);
