export const Icons = {
  Plus: 'iwp-icon-gen_plus',
  Minus: 'iwp-icon-gen_minus',
  Error: 'error',
  Warning: 'warning',
  Delete: 'bin',
  Reset: 'arrow_go_back',
  Upload: 'cloud_upload',
  FileUpload: 'file_upload',
  Download: 'cloud_download',
  UploadPending: 'cloud_off',
  Loading: 'hourglass',
  Check: 'checkbox_circle',
  Question: 'question_mark',
  Exchange: 'exchange',
  Rotation: 'anticlockwise',
  InsertRow: 'insert_row_bottom',
  Quality: 'quality',
  Offline: 'wifi_off',
  Alert: 'alert',
  Information: 'information',
  CheckDouble: 'check_double',
};
