import { Filters } from 'core/models';
import * as z from 'zod';

const checkboxSchema = z.object({
  id: z.number(),
  selected: z.boolean(),
});

const vehicleTypeFilterSchema = z.object({
  name: z.boolean(),
  path: z.boolean(),
  pathSegments: z.boolean(),
});

const vehicleFilterSchema = z.object({
  type: z.array(vehicleTypeFilterSchema).nonempty(),
  mode: z.array(checkboxSchema).nonempty(),
  manual: z.array(checkboxSchema).nonempty(),
  status: z.array(checkboxSchema).nonempty(),
  load: z.array(checkboxSchema).nonempty(),
  maintenance: z.array(checkboxSchema).nonempty(),
  search: z.string(),
});

const trafficManagementSchema = z.object({
  collisionPoint: z.boolean(),
  intersectionCollisionPoint: z.boolean(),
  vehicleStoppedByTM: z.boolean(),
  stoppingArea: z.boolean(),
  collisionArea: z.boolean(),
  deadlockArea: z.boolean(),
  dependencies: z.boolean(),
});

const poiSchema = z.object({
  name: z.boolean(),
  entryExitVector: z.boolean(),
  occupancy: z.array(checkboxSchema).nonempty(),
  type: z.array(checkboxSchema).nonempty(),
});

const zoneSchema = z.object({
  type: z.array(checkboxSchema).nonempty(),
});

const mapSchema = z.object({
  backgroundMap: z.boolean(),
  baseMap: z.boolean(),
  pillarsGrid: z.boolean(),
  graphMap: z.boolean(),
  pathPlannerMap: z.boolean(),
});

const filterSchema = z.object({
  vehicles: vehicleFilterSchema,
  trafficManagement: trafficManagementSchema,
  pois: poiSchema,
  zones: zoneSchema,
  maps: mapSchema,
});

export function isValidFilter(object?: Filters): z.SafeParseReturnType<FilterSchema, FilterSchema> {
  return filterSchema.safeParse(object);
}

export type FilterSchema = z.infer<typeof filterSchema>;
