import { MapItemType } from './layers/map-item.models';

export enum MapMode {
  None = 0,
  Poi = 1,
  PoiGroup = 2,
  Zone = 3,
  ProcessChain = 4,
  Image = 5,
  Vehicle = 6,
  VehicleEmulator = 7,
  PillarsGrid = 8,
  VehicleLocalize = 9,
  AddBackgroundImage = -1,
  AddBaseMapImage = -2,
  ZoneSet = -3,
  NavigationLayers = -4,
  Mission = -5,
  VehicleConnectionHealth = -6,
  RouteConfiguration = -7,
  TrafficAnalysis = -8,
}

export const MapModeItemType: Partial<Record<MapMode, MapItemType>> = {
  [MapMode.Poi]: MapItemType.Poi,
  [MapMode.Zone]: MapItemType.Zone,
  [MapMode.Vehicle]: MapItemType.Vehicle,
  [MapMode.PillarsGrid]: MapItemType.PillarGrid,
};

export enum MapSelectionMode {
  Single,
  Multiple,
  View,
}
