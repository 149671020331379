import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromHuLookupMappings from './hu-lookup-mapping.reducer';

import * as fromOpcuaDevices from './opcua-devices.reducer';
import * as fromOpcuaStreamingServices from './opcua-streaming-service.reducer';

export const featureKey = 'opcuaDevicesFeature';

export interface OpcuaDevicesFeatureState {
  [fromOpcuaStreamingServices.featureKey]: fromOpcuaStreamingServices.StreamingServicesState;
  [fromOpcuaDevices.featureKey]: fromOpcuaDevices.DevicesState;
  [fromHuLookupMappings.featureKey]: fromHuLookupMappings.HuLookupMappingState;
}

export function reducers(
  state: OpcuaDevicesFeatureState | undefined,
  action: Action
): OpcuaDevicesFeatureState {
  return combineReducers({
    [fromOpcuaStreamingServices.featureKey]: fromOpcuaStreamingServices.reducer,
    [fromOpcuaDevices.featureKey]: fromOpcuaDevices.reducer,
    [fromHuLookupMappings.featureKey]: fromHuLookupMappings.reducer,
  })(state, action);
}

export const getOpcuaStreamingServicesState = (
  state: OpcuaDevicesFeatureState
): fromOpcuaStreamingServices.StreamingServicesState => state.opcuaStreamingServices;
export const getOpcuaDevicesState = (
  state: OpcuaDevicesFeatureState
): fromOpcuaDevices.DevicesState => state.opcuaDevices;
export const getHuLookupMappingState = (
  state: OpcuaDevicesFeatureState
): fromHuLookupMappings.HuLookupMappingState => state.huLookupMappings;
export const getOpcuaDevicesFeatureState =
  createFeatureSelector<OpcuaDevicesFeatureState>(featureKey);
