import { Injectable } from '@angular/core';
import { EntityList } from 'core/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbDropdownService {
  dropdownList = new Subject<EntityList[]>();
  selectedItem = new Subject<EntityList | undefined>();

  navigatedItem: EntityList | undefined;

  setDropdownList(list: EntityList[]): void {
    this.dropdownList.next(list);
  }

  getDropdownList(): Observable<EntityList[]> {
    return this.dropdownList.asObservable();
  }

  setSelectedItem(item: EntityList): void {
    this.selectedItem.next(item);

    this.navigatedItem = item;
  }

  getSelectedItem(): Observable<EntityList | undefined> {
    return this.selectedItem.asObservable();
  }
}
