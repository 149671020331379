import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  RouteConfigurationDto,
  RouteConfigurationRequest,
  RouteValidationDto,
  TourValidationResultDto,
} from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class RouteConfigurationService extends TenantHttpClient {
  private readonly servicePathRouteConfigurations = API_SERVICES.RouteConfigurations;

  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  createRouteConfiguration(
    routeConfig: RouteConfigurationRequest
  ): Observable<RouteConfigurationDto> {
    return this.post<RouteConfigurationDto>(`${this.servicePathRouteConfigurations}`, routeConfig);
  }

  getAllRouteConfigurations(): Observable<RouteConfigurationDto[]> {
    return this.get<RouteConfigurationDto[]>(`${this.servicePathRouteConfigurations}`);
  }

  updateRouteConfiguration(
    routeConfig: RouteConfigurationRequest
  ): Observable<RouteConfigurationDto> {
    return this.put<RouteConfigurationDto>(`${this.servicePathRouteConfigurations}`, routeConfig);
  }

  updateBatchRouteConfiguration(
    routeConfigList: RouteConfigurationDto[]
  ): Observable<RouteConfigurationDto[]> {
    return this.put<RouteConfigurationDto[]>(
      `${this.servicePathRouteConfigurations}/batchUpdateRoute`,
      routeConfigList
    );
  }

  deleteRouteConfiguration(routeConfig: RouteConfigurationDto): Observable<GuidString> {
    return this.delete<GuidString>(`${this.servicePathRouteConfigurations}/${routeConfig.id}`);
  }

  ValidateRouteCompilation(
    routeName: string,
    nodes: RouteValidationDto
  ): Observable<TourValidationResultDto> {
    return this.post<TourValidationResultDto>(
      `${this.servicePathRouteConfigurations}/validate/${routeName}`,
      { nodes }
    );
  }
}
