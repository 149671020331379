import { Pipe, PipeTransform } from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { isKeyDefined } from 'library/helpers/key-defined.helper';

@Pipe({
  name: 'dsDropdown',
})
export class DsDropdownPipe<T extends object> implements PipeTransform {
  transform(
    objects: T[] | null | undefined,
    labelKey = 'name',
    idKey = 'id',
    disabledKey = 'disabled'
  ): SelectListOption[] | null {
    if (!objects) {
      return null;
    }

    return objects
      .filter(object => isKeyDefined(object, labelKey) && isKeyDefined(object, idKey))
      .map(object => ({
        id: object[idKey],
        label: object[labelKey],
        disabled: object[disabledKey] ?? false,
      }));
  }
}
@Pipe({
  name: 'dsDropdownPrimitive',
})
export class DsDropdownPrimitivePipe<T extends number | string> implements PipeTransform {
  transform(objects: T[] | null | undefined): SelectListOption[] | null {
    if (!objects) {
      return null;
    }

    return objects.map(object => ({
      id: object.toString(),
      label: object.toString(),
    }));
  }
}
