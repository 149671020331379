/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable rxjs/no-implicit-any-catch */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DetailErrorResponse,
  GuidString,
  HttpRequestMethod,
  ServerErrorResponse,
  ValidationListResponse,
  isDetailError,
  isMessageError,
  isSimpleMessageError,
  isValidationError,
  isValidationListError,
} from 'core/models';

import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export interface Params {
  [param: string]: string | number | string[];
}

export interface RequestTemplate {
  template: string;
  [param: string]: string | number | GuidString;
}

@Injectable()
export class BaseHttpClient {
  protected apiUrl = '';

  constructor(protected httpClient: HttpClient) {}

  protected request<T>(
    method: string,
    path: string | RequestTemplate,
    body?: object,
    options?: object
  ): Observable<T> {
    if (typeof path === 'string' && path.length > 0 && !path.startsWith('/')) {
      return throwError('Path should always start with a slash!');
    }

    return this.getUrlFromServicePath(path).pipe(
      switchMap(fullPath =>
        this.httpClient
          .request<T>(method, fullPath, {
            ...options,
            body,
          })
          .pipe(
            catchError((response: any) => {
              return throwError(this.getErrorResponse(response));
            })
          )
      )
    );
  }

  private getErrorResponse(
    response: ServerErrorResponse | ValidationListResponse | undefined
  ): string {
    const serverError = 'HTTP call failed';
    if (response === undefined) {
      return serverError;
    }

    if (isValidationListError(response)) {
      return response.errors.name.join('\n');
    }

    if (isMessageError(response) || isSimpleMessageError(response)) {
      return response.error;
    }

    let message = response?.status ? `${response.status} - ` : '';

    if (isValidationError(response)) {
      message += response.error.ErrorMessage;
    } else {
      message += response?.statusText || serverError;
    }

    if (isDetailError(response)) {
      const detail: DetailErrorResponse = response;
      message += detail.error.title ? ` - ${detail.error.title}` : '';
      message += detail.error.detail ? ` - ${detail.error.detail}` : '';
    }

    return message;
  }

  protected requestWithStatus<T>(
    method: HttpRequestMethod,
    request: string | RequestTemplate,
    body?: object
  ): Observable<HttpResponse<T>> {
    return this.request(method, request, body, { observe: 'response' });
  }

  protected get<T>(url: string, params?: Params): Observable<T> {
    return this.request('get', url, undefined, { params });
  }

  protected getBlob(url: string, params?: Params): Observable<HttpResponse<Blob>> {
    return this.request('get', url, undefined, {
      params,
      responseType: 'blob',
      observe: 'response',
    });
  }

  protected delete<T>(url: string | RequestTemplate): Observable<T> {
    return this.request('delete', url);
  }

  protected post<T>(
    request: string | RequestTemplate,
    body?: object,
    options?: object
  ): Observable<T> {
    return this.request('post', request, body, options);
  }

  protected put<T>(
    request: string | RequestTemplate,
    body?: object,
    options?: object
  ): Observable<T> {
    return this.request('put', request, body, options);
  }

  protected patch<T>(url: string, body?: object): Observable<T> {
    return this.request('patch', url, body);
  }

  protected getUrlFromServicePath(request: string | RequestTemplate): Observable<string> {
    if (typeof request === 'string') {
      return of(this.apiUrl + request);
    } else {
      return of(this.getUrlFromRequest(request));
    }
  }

  protected getUrlFromRequest(request: RequestTemplate): string {
    let url = request.template;

    Object.keys(request).forEach(k => {
      url = url.replace(`{${k}}`, request[k].toString());
    });

    if (url.includes('{') || url.includes('}')) {
      throw new Error('Path is missing a parameter');
    }

    return url;
  }
}
