import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GuidString } from 'core/models';

import * as ProcessChainGroupActions from '../actions/process-chain-group.actions';
import { ProcessChainGroupDto } from 'core/dtos';

export const featureKey = 'processChainGroups';

export interface ProcessChainGroupState extends EntityState<ProcessChainGroupDto> {
  loading: boolean;
  loaded: boolean;
  selectedProcessChainGroupId: GuidString;
  errorMessage: string;
}

export const processChainGroupAdapter: EntityAdapter<ProcessChainGroupDto> =
  createEntityAdapter<ProcessChainGroupDto>();

export const initialState: ProcessChainGroupState = processChainGroupAdapter.getInitialState({
  loading: false,
  loaded: false,
  selectedProcessChainGroupId: '',
  errorMessage: '',
});

export const processChainGroupReducer = createReducer(
  initialState,
  on(ProcessChainGroupActions.loadProcessChainGroups, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(ProcessChainGroupActions.loadProcessChainGroupsSuccess, (state, { processChainGroups }) =>
    processChainGroupAdapter.setAll(processChainGroups, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(ProcessChainGroupActions.loadProcessChainGroupsFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(ProcessChainGroupActions.addProcessChainGroupSuccess, (state, { processChainGroupCreated }) =>
    processChainGroupAdapter.addOne(processChainGroupCreated, state)
  ),

  on(ProcessChainGroupActions.updateProcessChainGroupSuccess, (state, { processChainGroup }) =>
    processChainGroupAdapter.updateOne(processChainGroup, state)
  ),

  on(ProcessChainGroupActions.deleteProcessChainGroupSuccess, (state, { processChainGroup }) =>
    processChainGroupAdapter.removeOne(processChainGroup.id.toString(), state)
  ),

  on(
    ProcessChainGroupActions.addProcessChainGroup,
    ProcessChainGroupActions.updateProcessChainGroup,
    ProcessChainGroupActions.deleteProcessChainGroup,
    state => ({
      ...state,
      errorMessage: '',
    })
  ),

  on(
    ProcessChainGroupActions.addProcessChainGroupFailure,
    ProcessChainGroupActions.updateProcessChainGroupFailure,
    ProcessChainGroupActions.deleteProcessChainGroupFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
    })
  ),

  on(ProcessChainGroupActions.selectProcessChainGroup, (state, { processChainGroupId }) => ({
    ...state,
    selectedProcessChainGroupId: processChainGroupId,
  }))
);

export function reducer(
  state: ProcessChainGroupState | undefined,
  action: Action
): ProcessChainGroupState {
  return processChainGroupReducer(state, action);
}

export const { selectEntities, selectAll } = processChainGroupAdapter.getSelectors();
export const getProcessChainGroups = selectAll;
export const getEntities = selectEntities;
export const getLoading = (state: ProcessChainGroupState): boolean =>
  (state || initialState).loading;
export const getLoaded = (state: ProcessChainGroupState): boolean => state.loaded;
export const getSelectedProcessChainGroupId = (state: ProcessChainGroupState): GuidString =>
  (state || initialState).selectedProcessChainGroupId;
