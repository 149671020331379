import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { EmulatorHostFeaturesDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class EmulatorSettingsService extends TenantHttpClient {
  protected apiUrl = environment.Services.EmulatorManager;
  private readonly servicePath = API_SERVICES.Features;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getEmulatorManagerFeatures(): Observable<EmulatorHostFeaturesDto> {
    return this.get<EmulatorHostFeaturesDto>(this.servicePath);
  }
}
