import { EventDto } from 'core/dtos';
import { EventType, GuidString } from 'core/models';

export interface EventTraceDto extends EventDto {
  eventId: GuidString;
  isSignaled: boolean;
}

export type PackedEventTrace = [
  GuidString,
  GuidString,
  string,
  EventType,
  GuidString | null,
  boolean,
  string
];

export function unpackEventTraceDto(data: PackedEventTrace[]): EventTraceDto[] {
  return data?.map(et => ({
    id: et[0],
    eventId: et[1],
    name: et[2],
    type: et[3],
    connectionId: et[4] || undefined,
    isSignaled: et[5],
    deviceId: et[6],
  }));
}
