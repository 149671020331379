import { createSelector } from '@ngrx/store';

import * as fromErrorForwardingReducer from '../reducers/error-forwarding.reducer';
import * as fromSettings from '../reducers/index';

const selectErrorForwardingState = createSelector(
  fromSettings.getSettingsFeatureState,
  fromSettings.getErrorForwardingState
);

export const selectErrorForwardingLoading = createSelector(
  selectErrorForwardingState,
  fromErrorForwardingReducer.getLoading
);

export const selectErrorForwardingLoaded = createSelector(
  selectErrorForwardingState,
  fromErrorForwardingReducer.getLoaded
);

export const selectErrorForwardingErrorMessage = createSelector(
  selectErrorForwardingState,
  fromErrorForwardingReducer.getErrorMessage
);

export const selectErrorForwardingEntities = createSelector(
  selectErrorForwardingState,
  fromErrorForwardingReducer.getEntities
);

export const selectAllErrorForwarding = createSelector(
  selectErrorForwardingState,
  fromErrorForwardingReducer.getAll
);
