import { createAction, props } from '@ngrx/store';
import {
  BaseVehicleMapDataDto,
  MassCommandMapDataResponseDto,
  MassVdaMapRequestDto,
  PreviewDeleteVehicleMapDataDto,
  VdaMapRequestModel,
  VdaMapUploadDataRequest,
  VehicleMapDataDownloadRequest,
  VehicleMapDataDto,
  VehicleMapDataListItemModel,
} from 'core/dtos';
import { GuidString } from 'core/models';

export enum VehicleMapDataTypes {
  LoadVehicleMapData = '[Vehicle Map Data] Load Vehicle Map Data',
  LoadVehicleMapDataSuccess = '[Vehicle Map Data] Load Vehicle Map Data Success',
  LoadVehicleMapDataFailure = '[Vehicle Map Data] Load Vehicle Map Data Failure',

  UploadRosVehicleMapData = '[Vehicle Map Data] UpLoad Vehicle Map Data',
  UploadVehicleMapDataSuccess = '[Vehicle Map Data] UpLoad Vehicle Map Data Success',
  UploadVehicleMapDataFailure = '[Vehicle Map Data] UpLoad Vehicle Map Data Failure',
  UploadVdaVehicleMapData = '[Vehicle Map Data] Upload DsMapData',

  DownloadVehicleMapData = '[Vehicle Map Data] Download Vehicle Map Data',
  DownloadVehicleMapDataSuccess = '[Vehicle Map Data] Download Vehicle Map Data Success',
  DownloadVehicleMapDataFailure = '[Vehicle Map Data] Download Vehicle Map Data Failure',

  MassEnableMap = '[Vehicle Map Data] Enable Map',
  MassEnableMapSuccess = '[Vehicle Map Data] Enable Map Success',
  MassEnableMapFailure = '[Vehicle Map Data] Enable Map Failure',

  DownloadMultiVehiclesMapData = '[Multi Vehicles Map Data] Download Multi Vehicles Map Data',
  DownloadMultiVehiclesMapDataSuccess = '[Multi Vehicles Map Data] Download Multi Vehicles Map Data Success',

  DeleteVehicleMapData = '[Vehicle Map Data] Delete Vehicle Map Data',
  DeleteVehicleMapDataSuccess = '[Vehicle Map Data] Delete Vehicle Map Data Success',
  DeleteVehicleMapDataFailure = '[Vehicle Map Data] Delete Vehicle Map Data Failure',

  PreviewDeleteVehicleMapData = '[Vehicle Map Data] Preview Delete Vehicle Map Data',
  PreviewDeleteVehicleMapDataSuccess = '[Vehicle Map Data] Preview Delete Vehicle Map Data Success',
  GetVehicleIdsByMapData = '[Vehicle Map Data] Get Vehicles By Map Data Id',
  GetVehicleIdsByMapDataSuccess = '[Vehicle Map Data] Get Vehicles By Map Data Id Success',

  EnableDisabledMaps = '[Vehicle Map Data] Enable Map From Vehicle',
  EnableDisabledMapsSuccess = '[Vehicle Map Data] Enable Map From Vehicle Success',
  EnableDisabledMapsFailure = '[Vehicle Map Data] Enable Map From Vehicle Failure',

  DeleteDisabledMaps = '[Vehicle Map Data] Delete Map From Vehicle',
  DeleteDisabledMapsSuccess = '[Vehicle Map Data] Delete Map From Vehicle Success',
  DeleteDisabledMapsFailure = '[Vehicle Map Data] Delete Map From Vehicle Failure',

  ResetVehicleMapDataActionStatus = '[Vehicle Map Data] Reset Action Status',
  ResetVehicleIdsByMapData = '[Vehicle Map Data] Reset VehicleIds By Map Data',
}

export const loadVehicleMapData = createAction(VehicleMapDataTypes.LoadVehicleMapData);

export const resetVehicleMapDataActionStatus = createAction(
  VehicleMapDataTypes.ResetVehicleMapDataActionStatus
);

export const loadVehicleMapDataSuccess = createAction(
  VehicleMapDataTypes.LoadVehicleMapDataSuccess,
  props<{ vehicleMapData: VehicleMapDataDto[] }>()
);

export const loadVehicleMapDataFailure = createAction(
  VehicleMapDataTypes.LoadVehicleMapDataFailure,
  props<{ errorMessage: string }>()
);

export const uploadRosVehicleMapData = createAction(
  VehicleMapDataTypes.UploadRosVehicleMapData,
  props<{ fromVehicleId: GuidString; vehicleMapData: BaseVehicleMapDataDto }>()
);

export const uploadVdaVehicleMapData = createAction(
  VehicleMapDataTypes.UploadVdaVehicleMapData,
  props<{ uploadVdaVehicleMapDataRequest: VdaMapUploadDataRequest }>()
);

export const uploadVehicleMapDataSuccess = createAction(
  VehicleMapDataTypes.UploadVehicleMapDataSuccess,
  props<{ vehicleMapData: VehicleMapDataDto }>()
);

export const uploadVehicleMapDataFailure = createAction(
  VehicleMapDataTypes.UploadVehicleMapDataFailure,
  props<{ errorMessage: string }>()
);

export const downloadVehicleMapData = createAction(
  VehicleMapDataTypes.DownloadVehicleMapData,
  props<{ downloadRequest: VehicleMapDataDownloadRequest }>()
);

export const downloadVehicleMapDataSuccess = createAction(
  VehicleMapDataTypes.DownloadVehicleMapDataSuccess,
  props<{ vehicleName: string }>()
);

export const downloadVehicleMapDataFailure = createAction(
  VehicleMapDataTypes.DownloadVehicleMapDataFailure,
  props<{ errorMessage: string }>()
);

export const massEnableMap = createAction(
  VehicleMapDataTypes.MassEnableMap,
  props<{ request: MassVdaMapRequestDto }>()
);

export const massEnableMapSuccess = createAction(
  VehicleMapDataTypes.MassEnableMapSuccess,
  props<{ response: MassCommandMapDataResponseDto }>()
);

export const downloadMultiVehiclesMapData = createAction(
  VehicleMapDataTypes.DownloadMultiVehiclesMapData,
  props<{ downloadRequest: VehicleMapDataDownloadRequest }>()
);

export const downloadMultiVehiclesMapDataSuccess = createAction(
  VehicleMapDataTypes.DownloadMultiVehiclesMapDataSuccess,
  props<{ response: MassCommandMapDataResponseDto }>()
);

export const previewDeleteVehicleMapData = createAction(
  VehicleMapDataTypes.PreviewDeleteVehicleMapData,
  props<{ vehicleMapDataId: GuidString }>()
);

export const previewDeleteVehicleMapDataSuccess = createAction(
  VehicleMapDataTypes.PreviewDeleteVehicleMapDataSuccess,
  props<{ response: PreviewDeleteVehicleMapDataDto }>()
);

export const deleteVehicleMapData = createAction(
  VehicleMapDataTypes.DeleteVehicleMapData,
  props<{ vehicleMapData: VehicleMapDataListItemModel }>()
);

export const deleteVehicleMapDataSuccess = createAction(
  VehicleMapDataTypes.DeleteVehicleMapDataSuccess,
  props<{ vehicleMapData: VehicleMapDataListItemModel }>()
);

export const deleteVehicleMapDataFailure = createAction(
  VehicleMapDataTypes.DeleteVehicleMapDataFailure,
  props<{ errorMessage: string }>()
);

export const getVehicleIdsByMapData = createAction(
  VehicleMapDataTypes.GetVehicleIdsByMapData,
  props<{ vehicleMapDataId: GuidString }>()
);

export const getVehicleIdsByMapDataSuccess = createAction(
  VehicleMapDataTypes.GetVehicleIdsByMapDataSuccess,
  props<{ vehicleIds: GuidString[] }>()
);

export const enableDisabledMaps = createAction(
  VehicleMapDataTypes.EnableDisabledMaps,
  props<{ vehicleId: GuidString; mapToEnable: VdaMapRequestModel }>()
);

export const enableDisabledMapsSuccess = createAction(
  VehicleMapDataTypes.EnableDisabledMapsSuccess,
  props<{ vehicleId: GuidString; mapToEnable: VdaMapRequestModel }>()
);

export const enableDisabledMapsFailure = createAction(
  VehicleMapDataTypes.EnableDisabledMapsFailure,
  props<{ errorMessage: string }>()
);

export const deleteDisabledMaps = createAction(
  VehicleMapDataTypes.DeleteDisabledMaps,
  props<{ vehicleId: GuidString; mapToDelete: VdaMapRequestModel }>()
);

export const deleteDisabledMapsSuccess = createAction(
  VehicleMapDataTypes.DeleteDisabledMapsSuccess,
  props<{ vehicleId: GuidString; mapToDelete: VdaMapRequestModel }>()
);

export const deleteDisabledMapsFailure = createAction(
  VehicleMapDataTypes.DeleteDisabledMapsFailure,
  props<{ errorMessage: string }>()
);

export const resetVehicleIdsByMapData = createAction(VehicleMapDataTypes.ResetVehicleIdsByMapData);
