import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { ProcessChainTraceDto } from 'core/dtos';
import { convertProcessChainTraceDtoToModel } from 'core/helpers';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, ProcessChainTraceModel } from 'core/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ProcessChainTraceService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.ProcessChainTrace;

  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  createProcessChainTraceWithoutJob(processChainId: GuidString): Observable<void> {
    const params = { processchainId: processChainId.toString() };
    return this.post<void>(`${this.servicePath}/AdHocProcessChain`, undefined, { params });
  }

  getProcessChainTraceById(processChainTraceId: GuidString): Observable<ProcessChainTraceModel> {
    return this.get<ProcessChainTraceDto>(`${this.servicePath}/${processChainTraceId}`).pipe(
      map(convertProcessChainTraceDtoToModel)
    );
  }
}
