import { createAction, props } from '@ngrx/store';

export const showHideEditToggle = createAction(
  '[UI] Show/Hide Edit Toggle',
  props<{ isVisible: boolean }>()
);

export const setIsEditMode = createAction(
  '[UI] Set Is Edit Mode',
  props<{ isEditMode: boolean }>()
);

export const setIsMassAbortMode = createAction(
  '[UI] Set Is Mass Abort Mode',
  props<{ isMassAbortMode: boolean }>()
);

export const setIsMassAbortOrderMode = createAction(
  '[UI] Set Is Mass Abort Order Mode',
  props<{ isMassAbortOrderMode: boolean }>()
);

export const setIsMassUpdateMaintenanceMode = createAction(
  '[UI] Set Is Mass Update Maintenance Mode',
  props<{ isMassUpdateMaintenanceMode: boolean }>()
);

export const setIsSaveMode = createAction(
  '[UI] Set Is Save Mode',
  props<{ isSaveMode: boolean }>()
);

export const setIsMassCommentMode = createAction(
  '[UI] Set Is Mass Comment Mode',
  props<{ isMassCommentMode: boolean }>()
);

export const setHasChanges = createAction('[UI] Set Has Changes', props<{ hasChanges: boolean }>());

export const showSearchBar = createAction('[UI] Show Search Bar', props<{ isVisible: boolean }>());
