import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { VersionService } from 'core/api-services';
import { VersionInformationDto } from 'core/dtos';
import { AtsTranslationService, ToastService } from 'core/services';
import { Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-version-information',
  templateUrl: './version-information.component.html',
  styleUrls: ['version-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionInformationComponent implements OnInit, OnDestroy {
  releaseNotesUrl = '';
  shortVersion = '';
  ngUnsubscribe = new Subject<void>();
  versionInformation?: VersionInformationDto;

  constructor(
    private readonly versionService: VersionService,
    private readonly atsTranslateService: AtsTranslationService,
    private readonly toastService: ToastService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.atsTranslateService.onLangChange
      .pipe(
        startWith(null),
        switchMap(() => this.versionService.getVersion()),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: versionInformation => {
          this.versionInformation = versionInformation;
          this.shortVersion = this.getShortVersion(versionInformation);
          this.releaseNotesUrl = this.getReleaseNotesUrl();
          this.cdRef.markForCheck();
        },
        error: () => {
          this.toastService.createErrorToast('versionInformation.loadingError');
        },
      });
  }

  getShortVersion(versionInformation: VersionInformationDto): string {
    const formattedVersion = versionInformation.version.split('-')[0];
    return formattedVersion.split('.').slice(0, 2).join('.');
  }

  getReleaseNotesUrl(): string {
    const currentLanguage = this.atsTranslateService.currentLang;
    return `https://docs.ats.azure.cloud.bmw/release-notes/${currentLanguage}/${this.shortVersion}/`;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
