<form [formGroup]="actionFormGroup" (ngSubmit)="onAction()">
  <div class="action-dropdown" data-cy="actionDropdown">
    <ds-form-field style="flex-grow: 1">
      <label ds-label for="action">{{ label }}</label>
      <ds-select
        data-cy="labeledDropdown"
        formControlName="selectedOption"
        variant="inline"
        id="action"
        [labellingConfig]="labellingConfig"
        [options]="
          sortDropdown === true
            ? (options | sortDropdown : sortBy : sortOrder | dsDropdown)
            : (options | dsDropdown)
        "
        [required]="false"
        [ds-tooltip]="disabledText"
        [dsTooltipConfig]="{ width: '200px', disabled: !actionDisabled }"
        [itemLabelTemplate]="dropdownTemplate"
        (optionSelected)="updateActionDisabled(); onChangeSelectedItem()"
        (isOpenChange)="onOpenDropdown()">
      </ds-select>
    </ds-form-field>
    <div style="display: flex; align-items: end">
      <button
        data-cy="dropdownActionButton"
        class="button-action-button"
        ds-button
        type="submit"
        [icon]="icon"
        [disabled]="actionDisabled"
        [ds-tooltip]="disabledText"
        [dsTooltipConfig]="{ width: '200px', disabled: !actionDisabled }"></button>
    </div>
  </div>
</form>

<ng-template #dropdownTemplate let-option>
  <div>
    <span class="seperate-content">
      {{ option.data.label }}
      <i *ngIf="option.disabled">
        <ds-icon
          data-cy="infoIcon"
          icon="information"
          class="info-icon ds-icon--sm"
          [ds-tooltip]="option.disabled ? disabledTooltip : null"></ds-icon
      ></i>
    </span>
  </div>
</ng-template>
