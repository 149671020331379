<div class="row">
  <div class="col-sm-12 col-lg-12">
    <ds-form-field>
      <ds-datepicker [config]="datePickerConfig" [viewDate]="singleDate">
        <input ds-date ds-form-validation required id="singleDate" [(ngModel)]="singleDate" />
      </ds-datepicker>
    </ds-form-field>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-lg-12">
    <ds-form-field>
      <ds-timepicker [config]="timePickerConfig">
        <input ds-time required id="singleTime" [(ngModel)]="singleTime" />
      </ds-timepicker>
    </ds-form-field>
  </div>
</div>
<br />
<div class="row mt-2x">
  <button
    #btn
    cy-data="apply-filter-button"
    class="col-sm-6 col-lg-6"
    ds-button
    [variant]="'outline'"
    (click)="updateFilter()">
    {{ 'shared.filters.applyButton' | translate }}
  </button>
  <button
    #btn
    cy-data="clear-filter-button"
    class="col-sm-6 col-lg-6"
    ds-button
    [variant]="'outline'"
    (click)="clearFilter()">
    {{ 'shared.filters.clearButton' | translate }}
  </button>
</div>
<br />
