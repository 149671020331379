import { ValueGetterFunc, ValueGetterParams } from 'ag-grid-community';
import { NO_VALUE_DEFAULT } from 'core/constants';
import { AtsTranslationService } from 'core/services';

export type PartialValueGetterParams = Pick<ValueGetterParams<object>, 'colDef' | 'data'>;

export interface Getter extends ValueGetterFunc<object> {
  (params: PartialValueGetterParams): unknown;
}

export class GridValueGetter {
  static getEnumValueGetterFn(translationService: AtsTranslationService, enumName: string): Getter {
    return (params: PartialValueGetterParams): string => {
      if (params.colDef?.field && params.data && translationService) {
        const value = params.data[params.colDef.field];
        return translationService.translateEnum(value, enumName);
      }
      return NO_VALUE_DEFAULT;
    };
  }

  static getEnumArrayValueGetterFn(
    translationService: AtsTranslationService,
    enumName: string
  ): Getter {
    return (params: PartialValueGetterParams): string => {
      const field = params.colDef?.field;
      if (!field || !params.data || !translationService) return NO_VALUE_DEFAULT;

      const value = params.data[field];
      if (!Array.isArray(value) || !value.length) return NO_VALUE_DEFAULT;
      const translatedItems = value.map(
        (item: string) => translationService.translateEnum(item, enumName) ?? NO_VALUE_DEFAULT
      );

      return translatedItems.join(', ');
    };
  }

  static getTranslatedValueGetterFn(
    translationService: AtsTranslationService,
    prefix?: string
  ): Getter {
    return (params: PartialValueGetterParams): string => {
      if (params.colDef?.field && params.data && translationService) {
        const translationKey = (prefix ?? '') + params.data[params.colDef.field];
        return translationKey ? translationService.get(translationKey) : '';
      }

      return NO_VALUE_DEFAULT;
    };
  }
}
