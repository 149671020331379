import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import { TextOptions } from 'modules/maps/helpers';
import { BitmapText, Graphics } from 'pixi.js';
import { FontName, MapFonts } from '../map-layer-fonts.constant';
import { LabelStyle } from '../map-layer.constant';

export class MapLabelGraphic extends Graphics {
  private readonly textGraphic: BitmapText;
  private readonly options: TextOptions;

  get text(): string {
    return this.textGraphic.text;
  }

  constructor(options: TextOptions) {
    super();

    this.options = options;
    this.textGraphic = this.createText(this.options);
    this.createBackground();

    this.addChild(this.textGraphic);
    this.updateTextPosition();
  }

  updateLabelText(label: string): void {
    this.textGraphic.text = this.formatLabel(label);

    this.clear();
    this.createBackground();
    this.updateTextPosition();
  }

  private createText({ label, scale }: TextOptions): BitmapText {
    const text = new BitmapText(this.formatLabel(label), MapFonts[FontName.Default]);
    text.interactive = false;
    text.buttonMode = true;
    text.align = 'center';
    text.scale.set(scale);

    return text;
  }

  private createBackground(): void {
    this.beginFill(LabelStyle.Color, this.options.alpha ?? LabelStyle.Alpha)
      .drawRoundedRect(
        -this.textGraphic.width / 2,
        -LabelStyle.Padding / 2,
        this.textGraphic.width,
        this.textGraphic.height + LabelStyle.Padding,
        this.options.radius ?? LabelStyle.Corner
      )
      .endFill();

    if (this.options.shadow) {
      this.filters = [new DropShadowFilter({ distance: 2, alpha: 0.3 })];
    }
  }

  private updateTextPosition() {
    this.textGraphic.position.x = -this.textGraphic.width / 2;
  }

  private formatLabel(label: string): string {
    return ` ${label} `;
  }
}
