<div class="list-options">
  <h3>
    {{ 'userProfile.personalPreferences' | translate }}
  </h3>

  <ng-container *ngFor="let item of listOptions" sidemenu>
    <ds-list-item
      class="navigation-list-item"
      (click)="onSelectListItem(item)"
      [isSelected]="item === selectedListItem"
      [isHoverable]="true"
      [attr.data-cy]="item"
      >{{ item | translate }}</ds-list-item
    >
  </ng-container>
</div>
