import { UserRoleDto } from 'core/dtos';
import { DateString, GuidString, RoleType } from 'core/models';

export interface User {
  id?: string;
  name: string;
  department: string;
  email: string;
  lastLoginDate: DateString;
  roleId?: RoleType;
  roleDescription?: string;
  allowedActionsByOrgId: Record<string, string[]>;
  allowedActionsByWorkingAreaId: Record<string, string[]>;
  allowedActionsForEnvironment: string[];
}

export interface UserWithUserRoles {
  id: number;
  name: string;
  email: string;
  department: string;
  roles: UserRoleDto[];
}

export interface UserWithRole {
  id: number;
  name: string;
  email: string;
  userRoleId: number;
  role?: string;
  roleTranslation?: string;
  roleId?: number;
  children?: [];
}

export interface AssignUserRoleCommand {
  id?: number;
  roleId?: number;
  workingAreaId?: GuidString;
  organizationId?: GuidString;
}

export interface ChangeUserRoleCommand {
  id?: number;
  userIdentifier: string;
  roleId?: number;
}

export interface RemoveUserRoleCommand {
  id?: number;
  roleId?: number;
  workingAreaId?: GuidString;
  organizationId?: GuidString;
}

export type UserRoleModel =
  | {
      id: 'env';
      level: string;
      roleName: string;
      roleAssigned: string;
    }
  | {
      id: GuidString;
      level: string;
      roleName: string;
      roleAssigned: DateString;
    };

export interface UserRoleModelWithChildren {
  id?: number;
  hierarchy: string[];
  level: string;
  roleName: string;
  roleId?: number;
  orgId?: GuidString;
  waId?: GuidString;
  envId?: number;
  reasonForAccess: string;
  roleAssigned?: DateString;
  isDirty: boolean;
  editable: boolean;
  deletable: boolean;
  creatable: boolean;
  levelType?: LevelType;
  roleAction?: RoleAction;
}

export enum RoleAction {
  CreateOrUpdate = 'CreateOrUpdate',
  Delete = 'Delete',
}

export interface RoleBaseObj {
  id: GuidString;
  name: string;
}

export enum LevelType {
  Environment = 'Environment',
  Organization = 'Organization',
  WorkingArea = 'WorkingArea',
}

export interface AtsTreeRoleItem {
  id: string;
  label: string;
  icon: string;
  group: false;
  type: string;
  isOpen: boolean;
  children: AtsTreeRoleItem[];
}
