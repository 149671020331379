import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { TourArchiveDto } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArchivedToursRetryService extends BaseHttpClient {
  private readonly servicePathArchiveTours = API_SERVICES.ArchiveToursRetry;

  protected apiUrl = environment.Services.MissionArchive;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getArchivedToursByRetryRunId(retryRunId: GuidString): Observable<TourArchiveDto[]> {
    return this.get<TourArchiveDto[]>(`${this.servicePathArchiveTours}/${retryRunId}`);
  }
}
