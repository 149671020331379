export enum BMWColors {
  Accent1 = '#FECB00',
  HintBlue = '#0071c5',
}

export const BMWColorsNumber = {
  HintBlue: 0x0071c5,
  SignalBlue: 0xcbdfff,
};

export const StandardColors = {
  CardBorder: 'var(--ds-color-border-action-strong)',
  CardBackground: 'var(--ds-color-surface-info',

  CardIndicator1: '#93A53B',
  CardIndicator2: '#4A90E2',
  CardIndicator3: '#BC3B3E',
};

// https://density.bmwgroup.net/v7/foundations/color/color-palette
export const DensityColors = {
  Turquoise: 0x037493,
  Red400: 0xff8485,
};

export const OtherColorsNumber = {
  OceanBlue200: 0xa8dfff,
  OceanBlue250: 0x7cd9fb,
  OceanBlue300: 0x3ccaff,
  OceanBlue400: 0x00aede,
  LightBlue: 0x4a90e2,
  GreyBlue: 0x98a4bb,
  Turquoise: 0x037493,
  Green: 0x93a53b,
  RedBrown: 0xa91b0d,
  Red: 0xff0000,
  Orange: 0xf1a43a,
};
