import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IpstSettingsDto } from 'core/dtos';

@Component({
  selector: 'app-ipst-settings-view',
  templateUrl: './ipst-settings-view.component.html',
  styleUrls: ['./ipst-settings-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstSettingsViewComponent {
  @Input() ipstSettings?: IpstSettingsDto;
}
