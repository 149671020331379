import { Injectable } from '@angular/core';
import { ValidMessage } from 'core/models';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditBarService {
  onSave!: Function;
  onDelete!: Function;

  private hasChanged = false;

  private readonly isFormValid = new Subject<boolean>();
  isFormValid$ = this.isFormValid.asObservable();

  private readonly isValid = new BehaviorSubject<ValidMessage>({ isValid: true });
  isValid$ = this.isValid.asObservable();

  private readonly changed = new Subject<boolean>();
  hasChanges$ = this.changed.asObservable();

  private readonly isNewObject = new BehaviorSubject<boolean>(false);
  isNewObject$ = this.isNewObject.asObservable();

  private readonly canDelete = new BehaviorSubject<boolean>(true);
  canDelete$ = this.canDelete.asObservable();

  private readonly cancelDialog = new Subject<void>();
  cancelDialog$ = this.cancelDialog.asObservable();

  private readonly onCancel = new Subject<void>();
  onCancel$ = this.onCancel.asObservable();

  get hasChanges(): boolean {
    return this.hasChanged;
  }

  setIsFormValid(valid: boolean): void {
    this.isFormValid.next(valid);
  }

  setIsValid(valid?: ValidMessage): void {
    if (valid === undefined) {
      this.isValid.next({ isValid: true, message: undefined });
    } else this.isValid.next(valid);
  }

  setHasChanges(hasChanges: boolean): void {
    this.hasChanged = hasChanges;
    this.changed.next(hasChanges);
  }

  setCancelDialog(): void {
    this.cancelDialog.next();
  }

  setIsNewObject(isNewObject: boolean): void {
    this.isNewObject.next(isNewObject);
  }

  setCanDelete(canDelete: boolean): void {
    this.canDelete.next(canDelete);
  }

  cancel(): void {
    this.onCancel.next();
  }
}
