import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { HttpError } from '@microsoft/signalr';
import { InfobarService } from 'core/api-services';
import { InfobarMessageResponse } from 'core/dtos';
import { InfobarMessageRequest } from 'core/models';
import { ToastService } from 'core/services';
import { InfobarSettingsViewModel } from 'modules/settings/components/infobar-settings/infobar-settings.viewmodel';
import { firstValueFrom, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-infobar-settings-page',
  templateUrl: './infobar-settings-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfobarSettingsPageComponent implements OnInit {
  latestMessageObs: Observable<InfobarMessageResponse | null> | null = null;
  isLoaded = false;

  viewModel: InfobarSettingsViewModel = {
    message: '',
    infoType: undefined,
    optionItems: [
      { label: 'Information', id: 'information' },
      { label: 'Warning', id: 'warning' },
      { label: 'Fatal', id: 'fatal' },
    ],
    minDate: new Date(),
    expiryTime: new Date(),
    currentDate: new Date(),
  };

  constructor(
    private readonly infobarService: InfobarService,
    private readonly toastService: ToastService
  ) {}
  ngOnInit(): void {
    this.latestMessageObs = this.infobarService.getLatestActiveInfobarMessage();

    this.latestMessageObs.pipe(take(1)).subscribe(latestMessage => {
      this.viewModel.message = latestMessage?.message ?? '';
      this.viewModel.expiryTime = new Date(
        (latestMessage?.expiryDateTime ?? this.viewModel.minDate).toString()
      );
      this.viewModel.infoType = latestMessage?.infoType;
      this.isLoaded = true;
    });
  }

  async onSave(infobarMessage: InfobarMessageRequest): Promise<void> {
    try {
      await firstValueFrom(
        this.infobarService.createInfobarMessage({
          infoType: infobarMessage.infoType,
          message: infobarMessage.message,
          expiryDateTime: infobarMessage.expiryDateTime,
        })
      );

      this.toastService.createSuccessToast('settings.infobarSettings.savingSuccess');
    } catch (_err) {
      if (_err instanceof HttpError && _err.message.includes('Forbidden')) {
        this.toastService.createErrorToast('settings.infobarSettings.savingNotAuthorized');
      } else this.toastService.createErrorToast('settings.infobarSettings.savingFailure');
    }
  }
}
