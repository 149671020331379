import { CheckboxModel } from 'core/models';
import { isEmpty } from 'lodash';

export function getBackwardsCompatibleValue(
  items: CheckboxModel[] | number[] | boolean[] | undefined,
  validValues: CheckboxModel[]
): CheckboxModel[] | undefined {
  let order = 0;
  const validIds = validValues.map(item => item.id);
  items = isEmpty(items) ? [...validValues] : items;

  return items
    ?.map(item => {
      if (typeof item === 'number') {
        return { id: item, selected: true };
      } else if (typeof item === 'boolean') {
        return { id: order++, selected: item };
      } else return item;
    })
    .filter(item => validIds.includes(item.id));
}

export function getValuesFromEnum(data: object): CheckboxModel[] {
  return Object.values(data)
    .filter(v => typeof v === 'number')
    .map(value => ({ id: value, selected: true }));
}
