import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromCollisions from './collision-points.reducer';
import * as fromFilters from './filters.reducer';
import * as fromGraphLayers from './graph-layer.reducer';
import * as fromIntersectionCollisions from './intersection-collision-points.reducer';
import * as fromMaps from './maps.reducer';
import * as fromNavigationLayers from './navigation-layers.reducer';
import * as fromPillarsGrid from './pillars-grid.reducer';
import * as fromVehicleMapData from './vehicle-map-data.reducer';
import * as fromZoneSets from './zone-sets.reducer';
import * as fromZones from './zones.reducer';

export const featureKey = 'maps';

export interface MapsFeatureState {
  [fromMaps.featureKey]: fromMaps.MapsState;
  [fromVehicleMapData.featureKey]: fromVehicleMapData.VehicleMapDataState;
  [fromZones.featureKey]: fromZones.ZonesState;
  [fromFilters.featureKey]: fromFilters.FiltersState;
  [fromCollisions.featureKey]: fromCollisions.CollisionsState;
  [fromIntersectionCollisions.featureKey]: fromIntersectionCollisions.IntersectionCollisionsState;
  [fromNavigationLayers.featureKey]: fromNavigationLayers.NavigationLayersState;
  [fromGraphLayers.featureKey]: fromGraphLayers.GraphLayersState;
  [fromZoneSets.featureKey]: fromZoneSets.ZoneSetsState;
  [fromPillarsGrid.featureKey]: fromPillarsGrid.PillarsGridState;
}

export function reducers(state: MapsFeatureState | undefined, action: Action): MapsFeatureState {
  return combineReducers({
    [fromMaps.featureKey]: fromMaps.reducer,
    [fromVehicleMapData.featureKey]: fromVehicleMapData.reducer,
    [fromZones.featureKey]: fromZones.reducer,
    [fromFilters.featureKey]: fromFilters.reducer,
    [fromCollisions.featureKey]: fromCollisions.reducer,
    [fromIntersectionCollisions.featureKey]: fromIntersectionCollisions.reducer,
    [fromNavigationLayers.featureKey]: fromNavigationLayers.reducer,
    [fromGraphLayers.featureKey]: fromGraphLayers.reducer,
    [fromZoneSets.featureKey]: fromZoneSets.reducer,
    [fromPillarsGrid.featureKey]: fromPillarsGrid.reducer,
  })(state, action);
}

export const getMapsState = (state: MapsFeatureState): fromMaps.MapsState => state.maps;
export const getVehicleMapDataState = (
  state: MapsFeatureState
): fromVehicleMapData.VehicleMapDataState => state.vehicleMapData;
export const getZonesState = (state: MapsFeatureState): fromZones.ZonesState => state.zones;
export const getFiltersState = (state: MapsFeatureState): fromFilters.FiltersState => state.filters;
export const getCollisionsState = (state: MapsFeatureState): fromCollisions.CollisionsState =>
  state.collisions;
export const getIntersectionCollisionsState = (
  state: MapsFeatureState
): fromIntersectionCollisions.IntersectionCollisionsState => state.intersectionCollisions;

export const getNavigationLayersState = (
  state: MapsFeatureState
): fromNavigationLayers.NavigationLayersState => state.navigationLayers;

export const getGraphLayersState = (state: MapsFeatureState): fromGraphLayers.GraphLayersState =>
  state.graphLayer;

export const getZoneSetState = (state: MapsFeatureState): fromZoneSets.ZoneSetsState =>
  state.zoneSets;
export const getPillarsGridState = (state: MapsFeatureState): fromPillarsGrid.PillarsGridState =>
  state.pillarsGrid;
export const getMapsFeatureState = createFeatureSelector<MapsFeatureState>(featureKey);
