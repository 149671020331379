import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ProcessChainGroupDto } from 'core/dtos';
import { GuidString } from 'core/models';

export enum ProcessChainGroupActionTypes {
  LoadProcessChainGroups = '[ProcessChainGroups] Load Process Chain Groups',
  LoadProcessChainGroupsSuccess = '[ProcessChainGroups] Load Process Chain Groups Success',
  LoadProcessChainGroupsFailure = '[ProcessChainGroups] Load Process Chain Groups Failure',
  SelectProcessChainGroup = '[ProcessChainGroups] Select Process Chain Group',
  AddProcessChainGroup = '[ProcessChainGroups] Add Process Chain Group',
  AddProcessChainGroupSuccess = '[ProcessChainGroups] Add Process Chain Group Success',
  AddProcessChainGroupFailure = '[ProcessChainGroups] Add Process Chain Group Failure',
  UpdateProcessChainGroup = '[ProcessChainGroups] Update Process Chain Group',
  UpdateProcessChainGroupSuccess = '[ProcessChainGroups] Update Process Chain Group Success',
  UpdateProcessChainGroupFailure = '[ProcessChainGroups] Update Process Chain Group Failure',
  DeleteProcessChainGroup = '[ProcessChainGroups] Delete Process Chain Group',
  DeleteProcessChainGroupSuccess = '[ProcessChainGroups] Delete Process Chain Group Success',
  DeleteProcessChainGroupFailure = '[ProcessChainGroups] Delete Process Chain Group Failure',
}
export const loadProcessChainGroups = createAction(
  ProcessChainGroupActionTypes.LoadProcessChainGroups
);

export const loadProcessChainGroupsSuccess = createAction(
  ProcessChainGroupActionTypes.LoadProcessChainGroupsSuccess,
  props<{ processChainGroups: ProcessChainGroupDto[] }>()
);

export const loadProcessChainGroupsFailure = createAction(
  ProcessChainGroupActionTypes.LoadProcessChainGroupsFailure,
  props<{ errorMessage: string }>()
);

export const selectProcessChainGroup = createAction(
  ProcessChainGroupActionTypes.SelectProcessChainGroup,
  props<{ processChainGroupId: GuidString }>()
);

export const addProcessChainGroup = createAction(
  ProcessChainGroupActionTypes.AddProcessChainGroup,
  props<{ newProcessChainGroup: ProcessChainGroupDto }>()
);

export const addProcessChainGroupSuccess = createAction(
  ProcessChainGroupActionTypes.AddProcessChainGroupSuccess,
  props<{ processChainGroupCreated: ProcessChainGroupDto }>()
);

export const addProcessChainGroupFailure = createAction(
  ProcessChainGroupActionTypes.AddProcessChainGroupFailure,
  props<{ errorMessage: string; processChainGroup: ProcessChainGroupDto }>()
);

export const updateProcessChainGroup = createAction(
  ProcessChainGroupActionTypes.UpdateProcessChainGroup,
  props<{ processChainGroup: ProcessChainGroupDto }>()
);

export const updateProcessChainGroupSuccess = createAction(
  ProcessChainGroupActionTypes.UpdateProcessChainGroupSuccess,
  props<{ processChainGroup: Update<ProcessChainGroupDto> }>()
);

export const updateProcessChainGroupFailure = createAction(
  ProcessChainGroupActionTypes.UpdateProcessChainGroupFailure,
  props<{ errorMessage: string; processChainGroup: ProcessChainGroupDto }>()
);

export const deleteProcessChainGroup = createAction(
  ProcessChainGroupActionTypes.DeleteProcessChainGroup,
  props<{ processChainGroup: ProcessChainGroupDto }>()
);

export const deleteProcessChainGroupSuccess = createAction(
  ProcessChainGroupActionTypes.DeleteProcessChainGroupSuccess,
  props<{ processChainGroup: ProcessChainGroupDto }>()
);

export const deleteProcessChainGroupFailure = createAction(
  ProcessChainGroupActionTypes.DeleteProcessChainGroupFailure,
  props<{ errorMessage: string }>()
);
