import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { TourChainConfigurationDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class TourChainConfigurationService extends TenantHttpClient {
  private readonly servicePathTourChainConfigurations = API_SERVICES.TourChainConfigurations;

  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getAllTourChainConfigurations(): Observable<TourChainConfigurationDto[]> {
    return this.get<TourChainConfigurationDto[]>(`${this.servicePathTourChainConfigurations}`);
  }

  createTourChainConfiguration(
    tourConfig: TourChainConfigurationDto
  ): Observable<TourChainConfigurationDto> {
    return this.post<TourChainConfigurationDto>(
      `${this.servicePathTourChainConfigurations}`,
      tourConfig
    );
  }

  updateTourChainConfiguration(
    tourConfig: TourChainConfigurationDto
  ): Observable<TourChainConfigurationDto> {
    return this.put<TourChainConfigurationDto>(
      `${this.servicePathTourChainConfigurations}`,
      tourConfig
    );
  }

  deleteTourChainConfiguration(id: GuidString): Observable<void> {
    return this.delete<void>(`${this.servicePathTourChainConfigurations}/${id}`);
  }
}
