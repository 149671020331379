export enum InternalTourStatus {
  Queued = 0,
  InProgress = 1,
  Completed = 2,
  Canceled = 3,
  Failed = 4,
  PendingDispatch = 5,
  GenerationFailed = 6,
  FailedRetry = 7,
}
