import { Injectable } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NO_VALUE_DEFAULT } from 'core/constants';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, firstValueFrom, map, startWith } from 'rxjs';

export interface Locale {
  lang: string;
  data: object;
}

@Injectable({
  providedIn: 'root',
})
export class AtsTranslationService {
  readonly supportedLanguages = ['en', 'de'];
  private readonly enumCache: Map<string, string> = new Map();

  constructor(
    private readonly translateService: TranslateService,
    private readonly primeNGConfig: PrimeNGConfig
  ) {
    this.translateService.addLangs(this.supportedLanguages);
    this.translateService.setDefaultLang('en');
    this.translateService.use(this.getInitialLanguage());
  }

  private getInitialLanguage(): string {
    const storedLanguage = localStorage.getItem('userDefaultLang');
    if (storedLanguage && this.supportedLanguages.includes(storedLanguage)) {
      return storedLanguage;
    }
    const browserLanguage = this.translateService.getBrowserLang() ?? 'en';
    return this.supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';
  }

  loadTranslations(...args: Locale[]): void {
    for (const locale of args) {
      this.translateService.setTranslation(locale.lang, locale.data, true);
    }
    this.setPrimeNgTranslations();
  }

  private setPrimeNgTranslations(): void {
    this.translateService.stream('primeng').subscribe(res => {
      this.primeNGConfig.setTranslation(res);
    });
  }

  get(key: string, params: object = {}): string {
    const value = this.translateService.instant(key, params);
    if (typeof value !== 'string') {
      throw new Error('Translation missing or key incomplete.');
    }
    return value;
  }

  getEnum(key: string): { [key: number]: string } {
    return this.getSubtree(key);
  }

  private getSubtree(key: string): { [key: string]: string } {
    const value = this.translateService.instant(key);
    if (typeof value !== 'object') {
      throw new Error(`Expected object for ${key} but received ${typeof value} instead.`);
    }
    return value;
  }

  async setLanguage(lang: string): Promise<void> {
    this.clearEnumCache();

    if (!this.supportedLanguages.includes(lang)) {
      console.error(`Language ${lang} is not supported. `);
    } else {
      await firstValueFrom(this.translateService.use(lang));
      localStorage.setItem('userDefaultLang', lang);
    }
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  get onLangChange(): Observable<LangChangeEvent> {
    return this.translateService.onLangChange;
  }

  get currentLanguage$(): Observable<string> {
    return this.translateService.onLangChange.pipe(
      map(x => x.lang),
      startWith(this.currentLang)
    );
  }

  private getCachedEnum(enumName: string, enumKey: number | string): string {
    const key = `enums.${enumName}.${enumKey}`;

    if (!this.enumCache.has(key)) {
      this.updateEnumCache(enumName);
    }

    const value = this.enumCache.get(key);
    return value ?? NO_VALUE_DEFAULT;
  }

  private updateEnumCache(enumName: string): void {
    const children = this.getEnum(`enums.${enumName}`);

    Object.entries(children).forEach(([id, value]) => {
      this.enumCache.set(`enums.${enumName}.${id}`, value);
    });
  }

  clearEnumCache(): void {
    this.enumCache.clear();
  }

  translateEnum(value: number | boolean | string | undefined, enumName?: string): string {
    if (value === undefined) {
      return NO_VALUE_DEFAULT;
    } else if (typeof value === 'boolean') {
      value = Number(value);
      enumName = enumName ? enumName : 'boolean';
    } else if (enumName !== undefined) {
      return this.getCachedEnum(enumName, value);
    }

    return NO_VALUE_DEFAULT;
  }
}
