/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { RouterEvent } from '@angular/router';
import objectHelper from 'core/helpers/object.helper';
import { BasicMenuItem } from 'core/models';
import { AtsTranslationService } from 'core/services/ats-translation.service';
import { TableComponent } from 'library/components/table/table.component';
import { TreeTableSortOptions } from 'library/components/tree-table/tree-table.model';
import { TableColumns } from 'library/models';
import { TableTemplateContext } from 'library/models/template-context.models';
import { FilterService } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { TreeTablePaginatorState } from 'primeng/treetable';
import * as localStorageHelper from '../../helpers/local-storage.helper';

@Component({
  selector: 'app-table-reorderable',
  templateUrl: './table-reorderable.component.html',
  styleUrls: [
    './table-reorderable.component.scss',
    '../../../library/components/table/table.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableReorderableComponent<T> extends TableComponent<T> implements OnInit {
  @Input() actionItems = new Array<BasicMenuItem>();
  @Output() readonly toggleFilter = new EventEmitter();
  @Input() isFilterVisible = false;
  @Input() filterTemplate!: TemplateRef<TableTemplateContext>;
  @Input() isFilterItemActive = false;

  @Output() readonly isFilterItemActiveChange = new EventEmitter<boolean>();

  defaultDisplayedColumns: TableColumns[] = [];
  tempDisplayedColumns: TableColumns[] = [];
  isResetButtonVisible = false;

  constructor(
    protected datePipe: DatePipe,
    protected filterService: FilterService,
    protected translate: AtsTranslationService
  ) {
    super(datePipe, filterService, translate);
  }

  ngOnInit(): void {
    this.isTableConfigurationModeActive = false;
    this.updateColumnConfigurableColumnSettings();
  }

  saveReorderedColumns(_event: Event | RouterEvent): void {
    this.isTableConfigurationModeActive = false;
    localStorageHelper.setTableColumnOrder(this.displayedColumns, this.translationContext);
    this.checkStoredColumnOrder(this.displayedColumns);
  }

  resetReorderedColumns(_event: Event | RouterEvent): void {
    this.displayedColumns = objectHelper.cloneDeep(this.defaultDisplayedColumns);
  }

  editReorderedColumns(_event: Event | RouterEvent): void {
    this.isTableConfigurationModeActive = !this.isTableConfigurationModeActive;

    if (this.isTableConfigurationModeActive) {
      this.tempDisplayedColumns = objectHelper.cloneDeep(this.displayedColumns);
    } else {
      this.displayedColumns = this.tempDisplayedColumns;
    }

    this.checkStoredColumnOrder(this.displayedColumns);
  }

  tableColumnsToString(columns: TableColumns[]): string {
    for (const col of columns) {
      col.isHidden = col.isHidden !== true ? false : true;
    }
    return JSON.stringify(columns);
  }

  checkStoredColumnOrder(savedColumnOrder: TableColumns[]): boolean {
    const savedColumns = this.tableColumnsToString(savedColumnOrder);
    const defaultColumns = this.tableColumnsToString(this.defaultDisplayedColumns);
    return (this.isResetButtonVisible =
      savedColumnOrder.length > 0 && savedColumns !== defaultColumns);
  }

  getSavedReorderedColumns(): void {
    const savedValue = localStorageHelper.getTableColumnOrder(this.translationContext);
    if (this.checkStoredColumnOrder(savedValue)) {
      this.displayedColumns = savedValue;
    }
  }

  updateColumnConfigurableColumnSettings(): void {
    if (this.defaultDisplayedColumns.length === 0) {
      this.defaultDisplayedColumns = objectHelper.cloneDeep(this.displayedColumns);
    }

    if (!this.isTableConfigurationModeActive) {
      this.getSavedReorderedColumns();
    }
  }

  onNavigateTo(value: T): void {
    this.navigateTo.emit(value);
  }

  onPaginate(event: TreeTablePaginatorState): void {
    this.paginate.emit(event);
  }

  onSaveTableSort(sortOptions: TreeTableSortOptions): void {
    this.saveTableSort.emit(sortOptions);
  }

  onSetSelectedRow(selectedRow: T): void {
    this.selectedRow = selectedRow;
    this.setSelectedRow.emit(selectedRow);
  }

  onRowClick(rowData: T): void {
    this.selectedRow = rowData;
    this.rowClick.emit(rowData);
  }

  onTextFieldChange(event: T): void {
    this.textFieldChange.emit(event);
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    this.lazyLoad.emit(event);
  }

  onColReordered(): void {
    this.checkStoredColumnOrder(this.displayedColumns);
  }

  onToolbarSecondaryButtonClick(command?: Function): void {
    command && command();
  }
}
