import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { AdxCollisionRecordDto, AdxVehicleMessageRecord, AdxZoneRecordDto } from 'core/dtos';
import { BaseHttpClient, Params } from 'core/http/base-http-client';
import { DateString, GuidString } from 'core/models';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataPublisherService extends BaseHttpClient {
  private readonly servicePath = API_SERVICES.AdxQueries;
  protected apiUrl = environment.Services.DataPublisher;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  async getAdxCollisionMessages(
    orgName: string,
    workingArea: string,
    dateTime: string,
    mapId: GuidString
  ): Promise<AdxCollisionRecordDto[]> {
    return firstValueFrom(
      this.get<AdxCollisionRecordDto[]>(
        `${this.servicePath}/${orgName}/${workingArea}/${mapId}/collision/${dateTime}`
      )
    );
  }

  async getAdxZoneMessages(
    orgName: string,
    workingArea: string,
    dateTime: string,
    mapId: GuidString
  ): Promise<AdxZoneRecordDto[]> {
    return firstValueFrom(
      this.get<AdxZoneRecordDto[]>(
        `${this.servicePath}/${orgName}/${workingArea}/${mapId}/zone-messages/${dateTime}`
      )
    );
  }

  async GetTrafficManagerVehicleMessages(
    orgName: string,
    workingArea: string,
    dateTime: DateString,
    mapId: GuidString
  ): Promise<AdxVehicleMessageRecord[]> {
    const params: Params = {
      vehicleMessageTime: dateTime.toString(),
    };
    return firstValueFrom(
      this.get<AdxVehicleMessageRecord[]>(
        `${this.servicePath}/${orgName}/${workingArea}/tm-vehicle-messages/${mapId}`,
        params
      )
    );
  }

  async GetVehicleMessages(
    orgName: string,
    workingArea: string,
    dateTime: DateString,
    mapId: GuidString
  ): Promise<AdxVehicleMessageRecord[]> {
    const params: Params = {
      vehicleMessageTime: dateTime.toString(),
    };
    return firstValueFrom(
      this.get<AdxVehicleMessageRecord[]>(
        `${this.servicePath}/${orgName}/${workingArea}/vehicle-messages/${mapId}`,
        params
      )
    );
  }
}
