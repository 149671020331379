import { TourStepDto } from 'core/dtos';
import { StepType, StepTypeBackend, TourStepUIModel } from 'core/models';

export function mapTourStepDtosToModels(tourSteps?: TourStepDto[]): TourStepUIModel[] {
  return tourSteps?.map(step => convertTourStepToTourStepModel(step)) ?? [];
}

export function convertTourStepToTourStepModel(step: TourStepDto): TourStepUIModel {
  const sequenceNum = step.sequenceId;
  const type = convertStepTypeBackendToStepType(step.type);
  const { sequenceId, ...uiStep } = step;
  return {
    ...uiStep,
    sequenceNum,
    type,
  };
}

export function convertStepTypeBackendToStepType(type: StepTypeBackend): StepType {
  switch (type) {
    case StepTypeBackend.Goto:
      return StepType.Goto;
    case StepTypeBackend.WaitForSideButton:
      return StepType.WaitForSideButton;
    case StepTypeBackend.GotoAndPushSideButton:
      return StepType.GotoAndPushSideButton;
    case StepTypeBackend.Lift:
      return StepType.Lift;
    case StepTypeBackend.Drop:
      return StepType.Drop;
    case StepTypeBackend.WaitForSapAcknowledgement:
      return StepType.WaitForSapAcknowledgement;
    case StepTypeBackend.StartToCharge:
      return StepType.StartToCharge;
    case StepTypeBackend.StopToCharge:
      return StepType.StopToCharge;
    case StepTypeBackend.WaitForDevice:
      return StepType.WaitForDevice;
    case StepTypeBackend.WaitForEvent:
      return StepType.WaitForEndOfStep;
    default:
      return StepType.SAP;
  }
}
