import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ProcessChainGroupTraceDto, ProcessChainTraceInGroupDto } from 'core/dtos';
import { ProcessChainTraceStatus } from 'core/models';
import * as ProcessChainGroupTraceActions from '../actions/process-chain-group-trace.actions';

export const featureKey = 'processChainGroupTrace';

export interface ProcessChainGroupTraceState extends EntityState<ProcessChainGroupTraceDto> {
  loading: boolean;
  loaded: boolean;
  selectedProcessChainGroupTraceId: string;
  ids: string[];
}

export const processChainGroupTraceAdapter: EntityAdapter<ProcessChainGroupTraceDto> =
  createEntityAdapter<ProcessChainGroupTraceDto>();

export const initialState: ProcessChainGroupTraceState =
  processChainGroupTraceAdapter.getInitialState({
    loading: false,
    loaded: false,
    ids: [],
    selectedProcessChainGroupTraceId: '',
  });

export const processChainGroupTraceReducer = createReducer(
  initialState,

  on(ProcessChainGroupTraceActions.loadProcessChainGroupTraces, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(
    ProcessChainGroupTraceActions.loadProcessChainGroupTracesSuccess,
    (state, { processChainGroupTraces }) =>
      processChainGroupTraceAdapter.setAll(processChainGroupTraces, {
        ...state,
        loading: false,
        loaded: true,
      })
  ),

  on(
    ProcessChainGroupTraceActions.loadProcessChainGroupTracesFailure,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      loaded: false,
      errorMessage,
    })
  ),

  on(
    ProcessChainGroupTraceActions.signalRUpdateProcessChainTrace,
    (state, { processChainTrace }) => {
      if (processChainTrace.processChainGroupTraceId !== null) {
        let processChainGroupTrace = getAllProcessChainGroupTraces(state)
          .filter(g => g.id === processChainTrace.processChainGroupTraceId)
          .at(0);

        if (processChainGroupTrace !== undefined) {
          const processChainTraces = processChainGroupTrace.processChainTraces.filter(
            p => p.id !== processChainTrace.id
          );

          const incomingProcessChainTrace = processChainGroupTrace.processChainTraces
            .filter(p => p.id === processChainTrace.id)
            .at(0);

          const processChainTraceInGroup: ProcessChainTraceInGroupDto = {
            id: processChainTrace.id,
            status: processChainTrace.status,
            processChainName: incomingProcessChainTrace?.processChainName ?? '',
            processChainGroupTraceId: processChainTrace.processChainGroupTraceId,
          };

          processChainTraces.push(processChainTraceInGroup);
          processChainGroupTrace = {
            ...processChainGroupTrace,
            processChainTraces: processChainTraces,
          };
          return processChainGroupTraceAdapter.upsertOne(processChainGroupTrace, state);
        }
      }
      return state;
    }
  ),

  on(
    ProcessChainGroupTraceActions.signalRDeleteProcessChainTrace,
    (state, { processChainTrace }) => {
      if (processChainTrace.processChainGroupTraceId !== null) {
        const processChainGroupTrace = getAllProcessChainGroupTraces(state)
          .filter(g => g.id === processChainTrace.processChainGroupTraceId)
          .at(0);

        if (processChainGroupTrace !== undefined) {
          const processChainTraces = processChainGroupTrace.processChainTraces;
          const isProcessChainGroupTraceCompleted =
            processChainTraces.filter(
              p =>
                p.status === ProcessChainTraceStatus.Completed ||
                p.status === ProcessChainTraceStatus.Cancelled
            ).length === 2;
          if (isProcessChainGroupTraceCompleted)
            return processChainGroupTraceAdapter.removeOne(
              processChainGroupTrace.id.toString(),
              state
            );
        }
      }
      return state;
    }
  ),

  on(
    ProcessChainGroupTraceActions.signalRAddProcessChainGroupTrace,
    (state, { processChainGroupTrace }) =>
      processChainGroupTraceAdapter.addOne(processChainGroupTrace, state)
  ),

  on(
    ProcessChainGroupTraceActions.signalRUpdateProcessChainGroupTrace,
    (state, { processChainGroupTrace }) =>
      processChainGroupTraceAdapter.upsertOne(processChainGroupTrace, state)
  ),
  on(
    ProcessChainGroupTraceActions.signalRDeleteProcessChainGroupTrace,
    ProcessChainGroupTraceActions.deleteCompletedProcessChainGroupTrace,
    (state, { processChainGroupTrace }) =>
      processChainGroupTraceAdapter.removeOne(processChainGroupTrace.id.toString(), state)
  )
);

export function reducer(
  state: ProcessChainGroupTraceState | undefined,
  action: Action
): ProcessChainGroupTraceState {
  return processChainGroupTraceReducer(state, action);
}

export const { selectEntities, selectAll } = processChainGroupTraceAdapter.getSelectors();

export const getLoaded = (state: ProcessChainGroupTraceState): boolean => state.loaded;
export const getLoading = (state: ProcessChainGroupTraceState): boolean => state.loading;
export const getEntities = selectEntities;
export const getAllProcessChainGroupTraces = selectAll;

export const getSelectedProcessChainGroupTraceId = (state: ProcessChainGroupTraceState): string =>
  (state || initialState).selectedProcessChainGroupTraceId;
