export enum MappingType {
  Source = 1,
  Destination = 2,
  MaterialNumber = 3,
  ScanResult = 4,
}

export enum MappingsNewButton {
  NewSourceMapping = 1,
  NewDestinationMapping = 2,
  NewMaterialNumberMapping = 3,
  NewScanResultMapping = 4,
  NewNotificationMapping = 5,
  NewPsaMapping = 6,
}
