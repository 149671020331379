import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { FleetDto } from 'core/dtos';
import { GuidString } from 'core/models';

import * as FleetActions from '../actions/fleets.actions';

export const featureKey = 'fleets';

export interface FleetsState extends EntityState<FleetDto> {
  loading: boolean;
  loaded: boolean;
  selectedFleetId: GuidString;
  errorMessage: string;
}

export const fleetsAdapter: EntityAdapter<FleetDto> = createEntityAdapter<FleetDto>();

export const initialState: FleetsState = fleetsAdapter.getInitialState({
  loading: false,
  loaded: false,
  selectedFleetId: '',
  errorMessage: '',
});

export const fleetsReducer = createReducer(
  initialState,

  on(FleetActions.loadFleets, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(FleetActions.loadFleetsSuccess, (state, { fleets }) =>
    fleetsAdapter.setAll(fleets, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(FleetActions.loadFleetsFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(FleetActions.addFleetSuccess, (state, { newFleet }) => fleetsAdapter.addOne(newFleet, state)),

  on(FleetActions.updateFleetSuccess, (state, { fleet }) => fleetsAdapter.updateOne(fleet, state)),

  on(FleetActions.ungroupFleetSuccess, (state, { fleetWithVehicles }) =>
    fleetsAdapter.removeOne(fleetWithVehicles.id.toString(), {
      ...state,
      selectedFleetId: '',
    })
  ),

  on(FleetActions.selectFleet, (state, { fleetId }) => ({
    ...state,
    selectedFleetId: fleetId,
  })),

  on(FleetActions.addFleet, FleetActions.updateFleet, FleetActions.ungroupFleet, state => ({
    ...state,
    errorMessage: '',
  })),

  on(
    FleetActions.addFleetFailure,
    FleetActions.updateFleetFailure,
    FleetActions.ungroupFleetFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
    })
  )
);

export function reducer(state: FleetsState | undefined, action: Action): FleetsState {
  return fleetsReducer(state, action);
}

export const { selectEntities, selectAll } = fleetsAdapter.getSelectors();

export const getLoading = (state: FleetsState): boolean => state.loading;
export const getLoaded = (state: FleetsState): boolean => state.loaded;
export const getSelectedFleetId = (state: FleetsState): GuidString => state.selectedFleetId;
export const getErrorMessage = (state: FleetsState): string => state.errorMessage;
export const getEntities = selectEntities;
export const getFleets = selectAll;
