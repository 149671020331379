<div class="chatbot-icon">
  <button
    class="bg-base border-neutral shadow-component"
    ds-button
    icon="chat"
    variant="outline"
    size="lg"
    aria-label="copilot-button"
    (click)="onOpenChat()"></button>
</div>
