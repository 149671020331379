import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { FeatureToggle } from 'core/dtos';
import { AtsActions } from 'core/models';
import { AtsTranslationService, PermissionService } from 'core/services';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import * as fromSettings from 'store-modules/settings-store';
import * as fromRoot from 'store/index';
import { FunctionsModalInput } from '../functions-modal/functions-modal.component';

@Component({
  selector: 'app-evacuation-mode-toggle',
  templateUrl: './evacuation-mode-toggle.component.html',
  styleUrls: ['./evacuation-mode-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvacuationModeToggleComponent implements OnChanges, OnInit, OnDestroy {
  @Input() translationKey = '';
  @Input() description = '';
  @Input() tooltipText = '';
  @Input() toggle: FeatureToggle = { isToggledOn: false, dateUpdated: null };
  @Input() requiredPermission: string = AtsActions.General_View;
  @Input() disabled = false;
  @Input() isLoaded = false;
  @Input() evacuationModeTriggerActive = false;

  @Output() readonly saveToggle = new EventEmitter<boolean>();

  modalInputData: FunctionsModalInput | undefined = undefined;
  doesNotHaveRequiredPermission = false;
  isFireFighter = false;
  isModalOpen = false;
  isToggledOn = false;
  selectedWorkingArea?: string;
  ngUnsubscribe = new Subject<void>();
  evacuationDisabled = false;

  constructor(
    private readonly translation: AtsTranslationService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly settingsStore: Store<fromSettings.SettingsFeatureState>,
    private readonly permissionService: PermissionService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.rootStore.pipe(select(fromRoot.selectSelectedWorkingAreaId)),
      this.settingsStore.pipe(select(fromSettings.selectEvacuationModeFireFighter)),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([selectedWorkAreaId, fireFighterSettings]) => {
        const fireFighterEvacuationModeIsEnabledInWa = fireFighterSettings.find(
          f => f.workAreaId === selectedWorkAreaId
        );
        this.evacuationDisabled = fireFighterEvacuationModeIsEnabledInWa
          ? fireFighterEvacuationModeIsEnabledInWa.isToggledOn
          : false;
        this.cdRef.markForCheck();
      });
  }
  ngOnChanges({ requiredPermission }: TypedChanges<EvacuationModeToggleComponent>): void {
    if (requiredPermission?.currentValue) {
      this.doesNotHaveRequiredPermission = !this.permissionService.actionAllowed(
        requiredPermission?.currentValue
      );
    }
    this.isToggledOn = this.toggle.isToggledOn;

    this.isFireFighter = this.permissionService.actionAllowed(
      AtsActions.ToggleEmergencyStrategyWelcomePage
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onToggle(checked: boolean): void {
    const action = checked ? 'enable' : 'disable';
    this.modalInputData = {
      title: this.translation.get(this.translationKey + '.title'),
      heading: this.translation.get(this.translationKey + '.' + action) + '?',
      description: checked
        ? this.translation.get(this.translationKey + '.enableDescription')
        : this.translation.get(this.translationKey + '.disableDescription'),
      toggleOn: checked,
    };
    this.isModalOpen = true;
  }

  onConfirm(isConfirmed: boolean): void {
    if (this.isModalOpen && isConfirmed) {
      this.saveToggle.emit(this.modalInputData?.toggleOn);
    }
    if (this.isModalOpen && !isConfirmed) {
      this.isToggledOn = !this.modalInputData?.toggleOn;
    }
    this.isModalOpen = false;
  }
}
