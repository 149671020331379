import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { ExpandCollapseButtonsService } from './service/expand-collapse-buttons.service';

@Component({
  selector: 'app-expand-collapse-buttons',
  templateUrl: './expand-collapse-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandCollapseButtonsComponent implements OnInit {
  disableCollapse$: Observable<boolean> = of(true);
  disableExpand$: Observable<boolean> = of(false);
  ngUnsubscribe = new Subject<void>();

  collapseDisabled = true;
  disableExpand = false;

  constructor(private readonly expandCollapseButtonsService: ExpandCollapseButtonsService) {}

  ngOnInit(): void {
    this.disableCollapse$ = this.expandCollapseButtonsService.getDisableCollapseAllButton();
    this.disableExpand$ = this.expandCollapseButtonsService.getDisableExpandAllButton();
  }

  collapseAllClicked(): void {
    this.expandCollapseButtonsService.setClickCollapseAll();

    this.expandCollapseButtonsService.setDisableCollapseAllButton(true);
    this.expandCollapseButtonsService.setDisableExpandAllButton(false);
  }

  expandAllClicked(): void {
    this.expandCollapseButtonsService.setClickExpandAll();

    this.expandCollapseButtonsService.setDisableExpandAllButton(true);
    this.expandCollapseButtonsService.setDisableCollapseAllButton(false);
  }
}
