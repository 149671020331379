import { createSelector } from '@ngrx/store';

import { selectAllActiveVehicles } from 'store-modules/vehicles-store/selectors/vehicles.selectors';
import * as fromMapsFeatureState from '../reducers';
import * as fromVehicleMapData from '../reducers/vehicle-map-data.reducer';

const selectVehicleMapDataState = createSelector(
  fromMapsFeatureState.getMapsFeatureState,
  fromMapsFeatureState.getVehicleMapDataState
);

export const selectVehicleIdsByMapData = createSelector(
  selectVehicleMapDataState,
  fromVehicleMapData.getVehicleIdsByMapData
);

export const selectVehicleMapDataLoading = createSelector(
  selectVehicleMapDataState,
  fromVehicleMapData.getLoading
);

export const selectVehicleMapDataLoaded = createSelector(
  selectVehicleMapDataState,
  fromVehicleMapData.getLoaded
);

export const selectVehicleMapDataErrorMessage = createSelector(
  selectVehicleMapDataState,
  fromVehicleMapData.getErrorMessage
);

export const selectVehicleMapDataEntities = createSelector(
  selectVehicleMapDataState,
  fromVehicleMapData.getVehicleMapDataEntities
);

export const selectAllVehicleMapData = createSelector(
  selectVehicleMapDataState,
  fromVehicleMapData.getAllVehicleMapData
);

export const selectVehicleMapDataActionStatus = createSelector(
  selectVehicleMapDataState,
  fromVehicleMapData.getVehicleMapDataActionStatus
);

export const selectActiveVehicleIdsByMapData = createSelector(
  selectVehicleIdsByMapData,
  selectAllActiveVehicles,
  (vehicleIds, vehicles) => {
    return vehicles.filter(vehicle => !vehicleIds.includes(vehicle.id));
  }
);
