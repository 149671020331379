import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { OrderDto } from 'core/dtos';
import { OrderFilter } from 'core/models';
import * as OrderActions from '../actions/order.actions';

export const featureKey = 'order';

export interface OrderState extends EntityState<OrderDto> {
  loading: boolean;
  loaded: boolean;
  activeOrderFilter: OrderFilter;
  historyOrderFilter: OrderFilter;
  historyOrderFilterOptions: OrderFilter;
}

export const orderAdapter: EntityAdapter<OrderDto> = createEntityAdapter<OrderDto>();

export const initialState: OrderState = orderAdapter.getInitialState({
  loading: false,
  loaded: false,
  activeOrderFilter: {},
  historyOrderFilter: {},
  historyOrderFilterOptions: {
    orderStatus: [],
    processChainName: [],
    materialNumber: [],
    source: [],
    destination: [],
    externalSystemName: [],
    deviceTrigger: [],
    internalHandlingUnitIdentificationNumber: [],
    alternativeHandlingUnitIdentification: [],
  },
});

export const orderReducer = createReducer(
  initialState,

  on(OrderActions.loadOrders, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(OrderActions.loadOrdersSuccess, (state, { orders }) =>
    orderAdapter.setAll(orders, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(OrderActions.loadOrdersFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(OrderActions.historyOrderFilterOptionsLoaded, (state, { historyOrderFilterOptions }) => ({
    ...state,
    historyOrderFilterOptions,
  })),

  on(OrderActions.signalRAddOrder, (state, { order }) => orderAdapter.addOne(order, state)),

  on(OrderActions.signalRUpdateOrder, (state, { order }) => orderAdapter.upsertOne(order, state)),

  on(OrderActions.removeOrder, (state, { order }) =>
    orderAdapter.removeOne(order.id.toString(), state)
  ),

  on(OrderActions.setActiveOrderFilter, (state, { activeOrderFilter }) => ({
    ...state,
    activeOrderFilter,
  })),

  on(OrderActions.setHistoryOrderFilter, (state, { historyOrderFilter }) => ({
    ...state,
    historyOrderFilter,
  }))
);

export function reducer(state: OrderState | undefined, action: Action): OrderState {
  return orderReducer(state, action);
}

export const { selectEntities, selectAll } = orderAdapter.getSelectors();

export const getLoaded = (state: OrderState): boolean => state.loaded;
export const getLoading = (state: OrderState): boolean => state.loading;
export const getEntities = selectEntities;
export const getAllOrders = selectAll;
export const getActiveOrderFilter = (state: OrderState): OrderFilter => state.activeOrderFilter;
export const getHistoryOrderFilterOptions = (state: OrderState): OrderFilter =>
  state.historyOrderFilterOptions;
export const getHistoryOrderFilter = (state: OrderState): OrderFilter => state.historyOrderFilter;
