<div>
  <input
    [disabled]="!isEditMode || field === FireFighter"
    type="checkbox"
    (ngModelChange)="onClick()"
    [(ngModel)]="displayValue" />
  <ds-tag *ngIf="edited" data-cy="edited">
    <i toggle class="iwp-icon-gen_edit"></i>
    {{ 'settings.users.edited' | translate }}
  </ds-tag>
</div>
