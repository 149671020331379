import { GuidString, HardwareVersion, MissionTrace, VehicleType } from 'core/models';
import { MissionTraceDto } from './mission-trace.dto';
import { TourTriggerType } from './tour-configuration.dto';

export interface TourChainDto {
  id: GuidString;
  name: string;
  sapInternalRoute: string | null;
  enableRepetitiveTour: boolean;
  tourTrigger: TourTriggerType;
  vehicleType: VehicleType;
  deviceName: string | null;
  deviceNodeName: string | null;
  deviceNodeValue: string | null;
  hardwareVersion: HardwareVersion | null;
  workAreaId: GuidString;
  tourChainStatus: TourChainStatus;
  tourChainConfigurationId: GuidString;
  tours: MissionTraceDto[];
}

export enum TourChainStatus {
  Created = 0,
  InProgress = 1,
  Completed = 2,
  Failed = 3,
}

export interface TourChain {
  id: GuidString;
  name: string;
  sapInternalRoute: string | null;
  enableRepetitiveTour: boolean;
  tourTrigger: TourTriggerType;
  vehicleType: VehicleType;
  deviceName: string | null;
  deviceNodeName: string | null;
  deviceNodeValue: string | null;
  hardwareVersion: HardwareVersion | null;
  workAreaId: GuidString;
  tourChainStatus: TourChainStatus;
  tourChainConfigurationId: GuidString;
  tours: MissionTrace[];
}
