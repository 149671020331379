import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  ODataDto,
  ODataOrderDto,
  OrderConfirmationOption,
  OrderDto,
  OrdersAbortRequestDto,
} from 'core/dtos';

import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, OrderFilter } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends TenantHttpClient {
  protected apiUrl = environment.Services.OrderGateway;
  private readonly servicePath = API_SERVICES.TransportOrders;
  private readonly filterPath = API_SERVICES.TransportOrderHistoryFilter;
  private readonly oDataOrderHistoryPath = '/odata/TransportOrderHistory';

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getAllActiveOrders(): Observable<OrderDto[]> {
    return this.get<OrderDto[]>(`${this.servicePath}/active`);
  }

  getOrderHistoryFilterOptions(): Observable<OrderFilter> {
    return this.get<OrderFilter>(this.filterPath);
  }

  cancelOrder(orderId: GuidString, cancelOption: OrderConfirmationOption): Observable<void> {
    return this.post<void>(`${this.servicePath}/cancelWithOptions/${orderId}`, {
      confirmationOption: cancelOption,
    });
  }

  retryOrder(orderId: GuidString): Observable<boolean> {
    return this.post<boolean>(`${this.servicePath}/retryOrder/${orderId}`, {});
  }

  massAbort(orders: OrdersAbortRequestDto): Observable<void> {
    return this.post<void>(`${this.servicePath}/cancelOrders`, {
      orderIds: orders.orderIds,
      abortOptions: {
        confirmationOption: orders.abortOptions,
      },
    });
  }

  getOrderHistoryViaOdata(params: Params): Observable<ODataDto<ODataOrderDto>> {
    return this.get<ODataDto<ODataOrderDto>>(`${this.oDataOrderHistoryPath}/`, params);
  }
}
