import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GuidString } from 'core/models';

import { TourChainConfigurationDto } from 'core/dtos';
import * as TourChainConfigurationActionTypes from '../actions/tour-chain-configuration.actions';

export const featureKey = 'tourChainConfigurations';

export interface TourChainConfigurationState extends EntityState<TourChainConfigurationDto> {
  loading: boolean;
  loaded: boolean;
  selectedTourChainConfigId: GuidString;
  errorMessage: string;
  ids: string[];
}

export const tourChainConfigurationAdapter: EntityAdapter<TourChainConfigurationDto> =
  createEntityAdapter<TourChainConfigurationDto>();

export const initialState: TourChainConfigurationState =
  tourChainConfigurationAdapter.getInitialState({
    loading: false,
    loaded: false,
    selectedTourChainConfigId: '',
    errorMessage: '',
    ids: [],
  });

export const tourChainConfigurationReducer = createReducer(
  initialState,
  on(TourChainConfigurationActionTypes.loadTourChainConfigurations, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(
    TourChainConfigurationActionTypes.loadTourChainConfigurationsSuccess,
    (state, { tourChainConfigurations }) =>
      tourChainConfigurationAdapter.setAll(tourChainConfigurations, {
        ...state,
        loading: false,
        loaded: true,
      })
  ),

  on(
    TourChainConfigurationActionTypes.createTourChainConfiguration,
    TourChainConfigurationActionTypes.updateTourChainConfiguration,
    TourChainConfigurationActionTypes.deleteTourChainConfiguration,
    state => ({
      ...state,
      errorMessage: '',
    })
  ),

  on(
    TourChainConfigurationActionTypes.createTourChainConfigurationSuccess,
    (state, { tourChainConfig }) => tourChainConfigurationAdapter.addOne(tourChainConfig, state)
  ),

  on(
    TourChainConfigurationActionTypes.updateTourChainConfigurationSuccess,
    (state, { tourChainConfiguration }) =>
      tourChainConfigurationAdapter.updateOne(tourChainConfiguration, state)
  ),

  on(
    TourChainConfigurationActionTypes.deleteTourChainConfigurationSuccess,
    (state, { tourChainConfiguration }) =>
      tourChainConfigurationAdapter.removeOne(tourChainConfiguration.id.toString(), state)
  ),

  on(TourChainConfigurationActionTypes.selectTourChainConfig, (state, { tourChainConfigId }) => ({
    ...state,
    selectedTourChainConfigId: tourChainConfigId,
  })),

  on(
    TourChainConfigurationActionTypes.loadTourChainConfigurationsFailure,
    TourChainConfigurationActionTypes.createTourChainConfigurationFailure,
    TourChainConfigurationActionTypes.updateTourChainConfigurationFailure,
    TourChainConfigurationActionTypes.deleteTourChainConfigurationFailure,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      loaded: false,
      errorMessage,
    })
  )
);

export function reducer(
  state: TourChainConfigurationState | undefined,
  action: Action
): TourChainConfigurationState {
  return tourChainConfigurationReducer(state, action);
}

export const { selectEntities, selectAll } = tourChainConfigurationAdapter.getSelectors();
export const getTourChainConfigurations = selectAll;
export const getEntities = selectEntities;
export const getLoading = (state: TourChainConfigurationState): boolean => state.loading;
export const getLoaded = (state: TourChainConfigurationState): boolean => state.loaded;
export const getSelectedTourChainConfigId = (state: TourChainConfigurationState): GuidString =>
  state.selectedTourChainConfigId;
