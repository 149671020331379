import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { ODataDto } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import {
  AssignUserRoleCommand,
  ChangeUserRoleCommand,
  GuidString,
  RemoveUserRoleCommand,
  RoleScope,
  User,
  UserWithUserRoles,
} from 'core/models';
import { EMPTY, Observable, firstValueFrom } from 'rxjs';
import { expand, map, reduce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseHttpClient {
  private readonly servicePath = API_SERVICES.User;
  private readonly oDataUrl = `/odata/users`;

  protected apiUrl = environment.Services.Auth;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  private ExpandNextLinks<T>(response: Observable<ODataDto<T>>): Observable<T[]> {
    return response.pipe(
      expand(res =>
        res['@odata.nextLink']
          ? this.get<ODataDto<T>>(this.extractAndAppendQueryString(res['@odata.nextLink']))
          : EMPTY
      ),
      map(value => value.value),
      reduce((acc: T[], user: T[]) => acc.concat(user), [])
    );
  }

  extractAndAppendQueryString(nextUrl: string): string {
    if (nextUrl) {
      const parsedNextUrl = new URL(nextUrl);
      return this.oDataUrl + parsedNextUrl.search;
    } else {
      return '';
    }
  }

  getUsers(): Observable<User[]> {
    return this.ExpandNextLinks(
      this.get<ODataDto<User>>(this.oDataUrl, {
        $orderby: 'name',
      })
    );
  }

  getUsersWithNoRolesAssigned(): Observable<User[]> {
    return this.ExpandNextLinks(
      this.get<ODataDto<User>>(this.oDataUrl, {
        $filter: 'not roles/any()',
      })
    );
  }

  getUserWithRoles(userId: string | undefined): Observable<UserWithUserRoles[]> {
    return this.ExpandNextLinks(
      this.get<ODataDto<UserWithUserRoles>>(this.oDataUrl, {
        $expand: `roles`,
        $filter: `id eq ${userId} and roles/any()`,
      })
    );
  }

  getEnvironmentUsers(): Observable<UserWithUserRoles[]> {
    return this.ExpandNextLinks(
      this.get<ODataDto<UserWithUserRoles>>(this.oDataUrl, {
        $expand: 'roles($filter=roleScope eq 0)',
        $filter: 'roles/any(r: r/roleScope eq 0)',
      })
    );
  }

  getOrganizationUsers(organizationId: GuidString): Observable<UserWithUserRoles[]> {
    return this.ExpandNextLinks(
      this.get<ODataDto<UserWithUserRoles>>(this.oDataUrl, {
        $expand: `roles($filter=roleScope eq ${RoleScope.Organization} and organizationId eq ${organizationId})`,
        $filter: `roles/any(r: r/roleScope eq ${RoleScope.Organization} and r/organizationId eq ${organizationId})`,
      })
    );
  }

  getWorkingAreaUsers(workingAreaId: GuidString): Observable<UserWithUserRoles[]> {
    return this.ExpandNextLinks(
      this.get<ODataDto<UserWithUserRoles>>(this.oDataUrl, {
        $expand: `roles($filter=roleScope eq ${RoleScope.WorkingArea} and workingAreaId eq ${workingAreaId})`,
        $filter: `roles/any(r: r/workingAreaId eq ${workingAreaId})`,
      })
    );
  }

  async updateUser(command: ChangeUserRoleCommand): Promise<void> {
    return firstValueFrom(
      this.put<void>(`${this.servicePath}/${command.id}/changeUserRole`, command)
    );
  }

  async getUserInfo(): Promise<User> {
    return firstValueFrom(this.get<User>(`${this.servicePath}/userinfo`));
  }

  async registerUser(): Promise<User> {
    return firstValueFrom(this.get<User>(`${this.servicePath}/registerUser`));
  }

  async logOut(token: string): Promise<void> {
    const headers_object = new HttpHeaders({ TokenForBlacklist: token });
    const options = { headers: headers_object };
    return firstValueFrom(this.post<void>(`${this.servicePath}/logout`, undefined, options));
  }

  async deleteUser(userId: number): Promise<void> {
    return firstValueFrom(this.delete<void>(`${this.servicePath}/${userId}`));
  }

  async removeUserRole(command: RemoveUserRoleCommand): Promise<void> {
    return firstValueFrom(
      this.delete<void>(
        `/${command.organizationId}/${command.workingAreaId}
        ${this.servicePath}/${command.id}/removeUserRole/${command.roleId}`
      )
    );
  }

  async assignUserRole(command: AssignUserRoleCommand): Promise<void> {
    return firstValueFrom(
      this.put<void>(
        `/${command.organizationId}/${command.workingAreaId}${this.servicePath}/${command.id}/assignUserRole`,
        command
      )
    );
  }
}
