import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserSettingsListOptions } from 'core/models';

@Component({
  selector: 'app-user-settings-list-options',
  templateUrl: './user-settings-list-options.component.html',
  styleUrls: ['./user-settings-list-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsListOptionsComponent implements OnInit {
  @Output() readonly selectListOption = new EventEmitter();

  listOptions = [UserSettingsListOptions.DefineStartPage];
  initiallySelectedListItem = UserSettingsListOptions.DefineStartPage;
  selectedListItem: UserSettingsListOptions;

  constructor() {
    this.selectedListItem = this.initiallySelectedListItem;
  }

  ngOnInit(): void {
    this.emitSelectedListOption();
  }

  onSelectListItem(selectedListItem: UserSettingsListOptions): void {
    this.selectedListItem = selectedListItem;
    this.emitSelectedListOption();
  }

  emitSelectedListOption(): void {
    this.selectListOption.emit(this.selectedListItem);
  }
}
