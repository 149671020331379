import { ImageOffsetResponseModel, NavigationLayerResponseModel } from 'core/dtos';
import { IOffsetMapLayerDrawing, LayerPivot, MapLayerType } from 'core/models';
import { Point } from 'pixi.js';
import { calculateInverseAngle } from 'shared/helpers';
import { MapLayerDrawing } from './map-layer-drawing';

export class OffsetMapLayerDrawing extends MapLayerDrawing implements IOffsetMapLayerDrawing {
  constructor(
    layer: NavigationLayerResponseModel,
    type: MapLayerType,
    pivot: LayerPivot = LayerPivot.Top
  ) {
    super(layer.id.toString(), type);

    this.interactive = false;

    if (pivot === LayerPivot.Top) this.pivot.set(0, layer.heightInPixels);

    this.updateOffset(layer.imageOffset);
  }

  updateOffset(offset: ImageOffsetResponseModel): void {
    this.angle = calculateInverseAngle(offset.rotation);

    const point = this.calculateLayerPosition(offset);
    this.position.copyFrom(point);
  }

  calculateLayerPosition(layerOffset: ImageOffsetResponseModel): Point {
    const x = layerOffset.x;
    const y = -layerOffset.y;

    return new Point(x, y);
  }
}
