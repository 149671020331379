import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject, of, takeUntil } from 'rxjs';
import * as fromRoot from 'store/index';
export interface EditedCheckBoxTag {
  value: boolean;
}

@Component({
  selector: 'app-permissions-checkbox',
  templateUrl: './permissions-checkbox.component.html',
  styleUrls: ['./permissions-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionsCheckBoxComponent implements ICellRendererAngularComp, OnDestroy {
  params!: ICellRendererParams<Record<string, boolean>>;
  displayValue!: string;
  field!: string;
  edited = false;
  isEditMode = false;
  isEditMode$ = of(false);
  ngUnsubscribe = new Subject<void>();
  FireFighter = 'Fire Fighter';

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initializeSubscriptions(): void {
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
    this.rootStore
      .pipe(select(fromRoot.selectIsEditMode), takeUntil(this.ngUnsubscribe))
      .subscribe(isEditMode => {
        this.isEditMode = isEditMode;
        this.cdRef.detectChanges();
      });
  }

  agInit(params: ICellRendererParams<Record<string, boolean>>): void {
    this.initializeSubscriptions();
    this.params = params;
    this.displayValue = params.value;
    this.field = params.colDef?.field ?? '';
    this.setEdited();
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<Record<string, boolean>>): boolean {
    this.params = Object.assign({}, params);
    this.displayValue = params.value;
    this.setEdited();
    this.cdRef.markForCheck();
    return true;
  }

  onClick(): void {
    this.params.context.componentParent.onChange(this.params.data, this.field);
  }

  setEdited(): void {
    if (!this.params || !this.params.data) return;
    this.edited = this.params.data[this.field + 'edited'];
  }
}
