import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as ErrorEffects from './effects';
import * as fromErrors from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(fromErrors.featureKey, fromErrors.reducers),
    EffectsModule.forFeature(ErrorEffects.effects),
  ],
})
export class ErrorsStoreModule {}
