import { Injectable, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { LOCALE } from 'core/constants/standard-date-time.constant';
import { DateFormatEnum, formatDate } from 'library/helpers/standard-date-formatter.helper';
import { AbstractTranslatePipe } from './abstract-translate.pipe';

@Pipe({
  name: 'standardDateTime',
  pure: false, // required, translation is unpure
})
@Injectable({
  providedIn: 'root',
})
export class StandardDateTimePipe
  extends AbstractTranslatePipe<string>
  implements PipeTransform, OnDestroy
{
  transform(
    date: Date | number | string | null | undefined,
    formatType: DateFormatEnum = DateFormatEnum.ShortFullDate
  ): string {
    if (!date) {
      return '';
    }
    this.ensureLangChangeReaction();
    const dateFormat = this.getTranslatedFormat(`shared.dateFormats.${formatType}`);
    return formatDate(date, dateFormat, LOCALE[this.atsTranslationService.currentLang]);
  }

  private translateKey(key: string): string {
    return this.atsTranslationService.get(key);
  }

  private getTranslatedFormat(key: string): string {
    return this.getCachedOrFresh(key, [], this.translateKey);
  }
}
