/* eslint-disable max-lines */
/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProcessChainGroupService } from 'core/api-services';
import { ProcessChainGroupDto } from 'core/dtos';
import { TenantRouterService } from 'core/services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions';
import * as ProcessChainGroupActions from '../actions/process-chain-group.actions';

@Injectable()
export class ProcessChainGroupEffects {
  loadProcessChainGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainGroupActions.loadProcessChainGroups),
      concatMap(() =>
        this.processChainGroupService.getProcessChainGroups().pipe(
          map(processChainGroups =>
            ProcessChainGroupActions.loadProcessChainGroupsSuccess({ processChainGroups })
          ),
          catchError(errorMessage =>
            of(ProcessChainGroupActions.loadProcessChainGroupsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadProcessChainGroupsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainGroupActions.loadProcessChainGroupsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.jobs.actions.loadProcessChainGroupsFailure');
        })
      ),
    { dispatch: false }
  );

  addProcessChainGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainGroupActions.addProcessChainGroup),
      map(action => action.newProcessChainGroup),
      concatMap(payload => {
        return this.processChainGroupService.createProcessChainGroup(payload).pipe(
          switchMap(processChainGroupCreated => [
            ProcessChainGroupActions.addProcessChainGroupSuccess({
              processChainGroupCreated: processChainGroupCreated,
            }),
          ]),
          catchError(errorMessage =>
            of(
              ProcessChainGroupActions.addProcessChainGroupFailure({
                errorMessage,
                processChainGroup: payload,
              })
            )
          )
        );
      })
    )
  );

  addProcessChainGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainGroupActions.addProcessChainGroupSuccess),
      tap(({ processChainGroupCreated }) => {
        this.toastService.createSuccessToast('shared.actions.saved', processChainGroupCreated);
        this.router
          .navigate([
            `/jobs/processConfigurator/processChainGroup/view/${processChainGroupCreated.id}`,
          ])
          .catch(() => {});
      }),
      switchMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  addProcessChainGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainGroupActions.addProcessChainGroupFailure),
        tap(() => {
          this.toastService.createErrorToast('jobs.processConfig.addProcessChainGroupFailure');
        })
      ),
    { dispatch: false }
  );

  updateProcessChainGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainGroupActions.updateProcessChainGroup),
      map(action => action.processChainGroup),
      concatMap(payload => {
        return this.processChainGroupService.updateProcessChainGroup(payload).pipe(
          map(() =>
            ProcessChainGroupActions.updateProcessChainGroupSuccess({
              processChainGroup: {
                id: payload.id.toString(),
                changes: payload,
              },
            })
          ),
          catchError(errorMessage =>
            of(
              ProcessChainGroupActions.updateProcessChainGroupFailure({
                errorMessage,
                processChainGroup: payload,
              })
            )
          )
        );
      })
    )
  );

  updateProcessChainGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainGroupActions.updateProcessChainGroupSuccess),
      tap(({ processChainGroup }) => {
        this.toastService.createSuccessToast('shared.actions.updated', processChainGroup.changes);
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateProcessChainGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainGroupActions.updateProcessChainGroupFailure),
        tap(() => {
          this.toastService.createErrorToast('jobs.processConfig.updateProcessChainGroupFailure');
        })
      ),
    { dispatch: false }
  );

  deleteProcessChainGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainGroupActions.deleteProcessChainGroup),
      map(action => action.processChainGroup),
      concatMap((processChainGroup: ProcessChainGroupDto) => {
        return this.processChainGroupService.deleteProcessChainGroup(processChainGroup.id).pipe(
          map(() =>
            ProcessChainGroupActions.deleteProcessChainGroupSuccess({
              processChainGroup: processChainGroup,
            })
          ),
          catchError(errorMessage =>
            of(ProcessChainGroupActions.deleteProcessChainGroupFailure({ errorMessage }))
          )
        );
      })
    )
  );

  deleteProcessChainGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainGroupActions.deleteProcessChainGroupSuccess),
        tap(({ processChainGroup }) => {
          this.toastService.createSuccessToast('shared.actions.deleted', {
            name: processChainGroup.name,
          });
          void this.router.navigate(['jobs/processConfigurator']);
        })
      ),
    { dispatch: false }
  );

  deleteProcessChainGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainGroupActions.deleteProcessChainGroupFailure),
        tap(() => {
          this.toastService.createErrorToast('jobs.processConfig.deleteProcessChainGroupFailure');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly processChainGroupService: ProcessChainGroupService,
    private readonly toastService: ToastService,
    private readonly router: TenantRouterService
  ) {}
}
