/* eslint-disable max-lines */
/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProcessChainsService, ProcessChainTraceService } from 'core/api-services';
import { ProcessChain } from 'core/models';
import { TenantRouterService } from 'core/services/tenant-router.service';
import { ModalDialogService } from 'library/components/modal-dialog';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions/ui.actions';

import { ToastService } from 'core/services/toast.service';
import * as ProcessChainActions from '../actions/process-chain.actions';

@Injectable()
export class ProcessChainEffects {
  loadProcessChains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainActions.loadProcessChains),
      concatMap(() =>
        this.processChainService.getProcessChains().pipe(
          map(processChains => ProcessChainActions.loadProcessChainsSuccess({ processChains })),
          catchError(errorMessage =>
            of(ProcessChainActions.loadProcessChainsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadProcessChainsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.loadProcessChainsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.jobs.actions.loadProcessChainsFailure');
        })
      ),
    { dispatch: false }
  );

  addProcessChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainActions.addProcessChain),
      map(action => action.newProcessChain),
      concatMap(payload => {
        return this.processChainService.createProcessChain(payload).pipe(
          map(processChainCreated =>
            ProcessChainActions.addProcessChainSuccess({ processChainCreated })
          ),
          catchError(errorMessage =>
            of(
              ProcessChainActions.addProcessChainFailure({
                errorMessage,
                processChain: payload,
              })
            )
          )
        );
      })
    )
  );

  addProcessChainSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainActions.addProcessChainSuccess),
      tap(({ processChainCreated }) => {
        this.toastService.createSuccessToast('shared.actions.saved', processChainCreated);
        this.router
          .navigate([`/jobs/processConfigurator/view/${processChainCreated.id}`])
          .catch(() => {});
      }),
      switchMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  private handleProcessChainSaveError(errorMessage: string, processChain: ProcessChain): void {
    if (errorMessage.includes('Name already exists in work area')) {
      this.modalService.createModal('jobs.processConfig.modalSaveProcessChainNameError', {
        name: processChain.name,
        errorMessage,
      });
    } else {
      this.modalService.createModal('jobs.processConfig.modalSaveProcessChainParametersError', {
        source: processChain.source,
        destination: processChain.destination,
        materialNumber: processChain.materialNumber,
        errorMessage,
      });
    }
  }

  addProcessChainFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.addProcessChainFailure),
        tap(({ errorMessage, processChain }) =>
          this.handleProcessChainSaveError(errorMessage, processChain)
        )
      ),
    { dispatch: false }
  );

  updateProcessChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainActions.updateProcessChain),
      map(action => action.processChain),
      concatMap(payload => {
        return this.processChainService.updateProcessChain(payload).pipe(
          map(() =>
            ProcessChainActions.updateProcessChainSuccess({
              processChain: {
                id: payload.id.toString(),
                changes: payload,
              },
            })
          ),
          catchError(errorMessage =>
            of(
              ProcessChainActions.updateProcessChainFailure({
                errorMessage,
                processChain: payload,
              })
            )
          )
        );
      })
    )
  );

  updateProcessChainSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainActions.updateProcessChainSuccess),
      tap(({ processChain }) => {
        this.toastService.createSuccessToast('shared.actions.updated', processChain.changes);
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateProcessChainFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.updateProcessChainFailure),
        tap(({ errorMessage, processChain }) =>
          this.handleProcessChainSaveError(errorMessage, processChain)
        )
      ),
    { dispatch: false }
  );

  deleteProcessChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainActions.deleteProcessChain),
      map(action => action.processChain),
      concatMap((processChain: ProcessChain) => {
        return this.processChainService.deleteProcessChain(processChain.id).pipe(
          map(() => ProcessChainActions.deleteProcessChainSuccess({ processChain: processChain })),
          catchError(errorMessage =>
            of(ProcessChainActions.deleteProcessChainFailure({ errorMessage }))
          )
        );
      })
    )
  );

  deleteProcessChainSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.deleteProcessChainSuccess),
        tap(({ processChain }) => {
          this.toastService.createSuccessToast('shared.actions.deleted', {
            name: processChain.name,
          });
          void this.router.navigate(['jobs/processConfigurator']);
        })
      ),
    { dispatch: false }
  );

  deleteProcessChainFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.deleteProcessChainFailure),
        tap(() => {
          this.toastService.createErrorToast('jobs.processConfig.deleteProcessChainFailure');
        })
      ),
    { dispatch: false }
  );

  triggerProcessChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainActions.triggerProcessChain),
      map(action => action.processChain),
      concatMap((payload: ProcessChain) => {
        return this.processChainTraceService.createProcessChainTraceWithoutJob(payload.id).pipe(
          switchMap(() => [
            ProcessChainActions.triggerProcessChainSuccess({ processChain: payload }),
          ]),
          catchError(errorMessage =>
            of(
              ProcessChainActions.triggerProcessChainFailure({
                errorMessage,
                processChain: payload,
              })
            )
          )
        );
      })
    )
  );

  triggerProcessChainSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.triggerProcessChainSuccess),
        tap(({ processChain }) => {
          this.toastService.createSuccessToast(
            'jobs.processConfig.triggerPc.triggerProcessChainSuccess',
            { name: processChain.name }
          );
        })
      ),
    { dispatch: false }
  );

  triggerProcessChainFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.triggerProcessChainFailure),
        tap(({ processChain }) => {
          this.toastService.createErrorToast(
            'jobs.processConfig.triggerPc.triggerProcessChainFailure',
            { name: processChain.name }
          );
        })
      ),
    { dispatch: false }
  );

  activateProcessChains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainActions.activateProcessChains),
      map(action => action.processChainIds),
      concatMap(payload => {
        return this.processChainService.activateProcessChains(payload).pipe(
          map(response =>
            ProcessChainActions.activateProcessChainsSuccess({ processChains: response })
          ),
          catchError(errorMessage =>
            of(ProcessChainActions.activateProcessChainsFailure({ errorMessage }))
          )
        );
      })
    )
  );

  activateProcessChainsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.activateProcessChainsSuccess),
        tap(() => {
          this.toastService.createSuccessToast('jobs.processConfig.activateSuccess');
        })
      ),
    { dispatch: false }
  );

  activateProcessChainsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.activateProcessChainsFailure),
        tap(() => {
          this.toastService.createErrorToast('jobs.processConfig.activateFailure');
        })
      ),
    { dispatch: false }
  );

  deactivateProcessChains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessChainActions.deactivateProcessChains),
      map(action => action.processChainIds),
      concatMap(payload => {
        return this.processChainService.deactivateProcessChains(payload).pipe(
          map(response =>
            ProcessChainActions.deactivateProcessChainsSuccess({ processChains: response })
          ),
          catchError(errorMessage =>
            of(ProcessChainActions.deactivateProcessChainsFailure({ errorMessage }))
          )
        );
      })
    )
  );

  deactivateProcessChainsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.deactivateProcessChainsSuccess),
        tap(() => {
          this.toastService.createSuccessToast('jobs.processConfig.deactivateSuccess');
        })
      ),
    { dispatch: false }
  );

  deactivateProcessChainsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessChainActions.deactivateProcessChainsFailure),
        tap(() => {
          this.toastService.createErrorToast('jobs.processConfig.deactivateFailure');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly processChainService: ProcessChainsService,
    private readonly processChainTraceService: ProcessChainTraceService,
    private readonly toastService: ToastService,
    private readonly router: TenantRouterService,
    private readonly modalService: ModalDialogService
  ) {}
}
