import { EdgeDto, NodeDto, RouteConfigurationDto } from 'core/dtos';
import { GuidString, Pose2D, RouteConfigState } from 'core/models';
import { Nurb } from '../nurb.model';

export type Node = GraphNode | RouteConfigNode;
export type Edge = GraphEdge | RouteConfigEdge;

export interface GraphNode extends NodeDto {
  isSwitchNode?: boolean;
  hasRule?: boolean;
}

export interface GraphEdge extends EdgeDto {
  startPosition: Pose2D;
  endPosition: Pose2D;
  isIncluded: boolean;
  isShared?: boolean;
  isDisconnected?: boolean;
  nurb?: Nurb;
}

export interface EdgeSegment {
  startEdgeId: GuidString;
  startNodeId: GuidString;
  endNodeId: GuidString;
  positions: Pose2D[];
  edges?: GuidString[];
}

export interface RouteConfigNode extends GraphNode {
  state: RouteConfigState;
  routes?: GuidString[];
  color?: number;
}

export interface RouteConfigEdge extends GraphEdge {
  state: RouteConfigState;
  isInRoute?: boolean;
  color?: number;
}

export function isRouteConfigNode(node: Node): node is RouteConfigNode {
  return 'state' in node;
}

export function isRouteConfigEdge(edge: Edge): edge is RouteConfigEdge {
  return 'state' in edge;
}

export interface BaseRoute extends RouteConfigurationDto {
  edges: RouteConfigEdge[] | GraphEdge[];
}
