import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-labeled-text',
  templateUrl: './labeled-text.component.html',
  styleUrls: ['./labeled-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledTextComponent {
  @Input() label = '';
  @Input() value = '';
  @Input() icon = '';
  @Input() toolTip = '';
  @Input() dsLabel = false;
}
