import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { DsSelectLabellingConfiguration } from '@bmw-ds/components/ds-interfaces/select/config.interface';
import { OpcuaDeviceResponseModel } from 'core/dtos';
import {
  ATSMenuItem,
  BasicMenuItem,
  EvacuationDeviceFeedbackItem,
  EvacuationModeType,
} from 'core/models';
import { AtsTranslationService, EditBarService } from 'core/services';
import { TableComponent } from 'library/components/table/table.component';
import { TableColumns } from 'library/models';

@Component({
  selector: 'app-wago-evacuation-devices-config-feedbacks-table',
  templateUrl: './wago-evacuation-devices-config-feedbacks-table.component.html',
  styleUrls: ['wago-evacuation-devices-config-feedbacks-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WagoEvacuationDevicesConfigFeedbacksTableComponent implements OnInit, OnChanges {
  @ViewChild(TableComponent) child!: TableComponent<EvacuationDeviceFeedbackItem>;
  @Input() tableContent: EvacuationDeviceFeedbackItem[] = [];
  @Input() device: OpcuaDeviceResponseModel | undefined;
  @Input() hasEvacuationDeviceUpdatePermission = false;

  @Output() readonly isChanged = new EventEmitter();

  displayedColumns: TableColumns[] = [
    { field: 'node', type: 'component', size: 150 },
    {
      field: 'activationValue',
      type: 'component',
      size: 140,
    },
    {
      field: 'deactivationValue',
      type: 'component',
      size: 140,
    },
    { field: 'empty', type: 'text', size: 20, isHidden: true },
  ];
  nodeDropdownList = {};

  writeTypeNodes: EvacuationModeType[] | undefined = [];
  unselectedWriteTypeNodes: EvacuationModeType[] | undefined = [];
  selectedNewRowNodes: SelectListOption | SelectListOption[] | null = [];
  menuItems: ATSMenuItem[] = [];
  labellingConfig: DsSelectLabellingConfiguration = {
    placeholder: this.translationService.get('settings.functions.placeHolders.addNodes'),
  };

  constructor(
    private readonly editBarService: EditBarService,
    private readonly translationService: AtsTranslationService
  ) {}

  ngOnInit(): void {
    this.menuItems = this.createMenuItems();
  }

  ngOnChanges({
    device,
    tableContent,
  }: TypedChanges<WagoEvacuationDevicesConfigFeedbacksTableComponent>): void {
    if (tableContent && tableContent.currentValue && device && device.currentValue) {
      this.updateDeviceChanges(tableContent.currentValue, device.currentValue);
    } else if (tableContent && tableContent.currentValue && this.device) {
      this.updateDeviceChanges(tableContent.currentValue, this.device);
    } else if (device && device.currentValue) {
      this.updateDeviceChanges(this.tableContent, device.currentValue);
    }
    this.menuItems = this.createMenuItems();
  }

  updateDeviceChanges(
    tableContent: EvacuationDeviceFeedbackItem[],
    device: OpcuaDeviceResponseModel
  ): void {
    this.editBarService.setIsNewObject(true);
    this.writeTypeNodes = device.nodes
      .filter(i => i.type === 'Write')
      .map(data => {
        return { name: data.name, value: data.name };
      });
    tableContent.forEach((item: EvacuationDeviceFeedbackItem) => {
      this.getNodeDefaultValue(item.node, 'activationValue');
      this.getNodeDefaultValue(item.node, 'deactivationValue');
      this.checkTableRowValidation(item);
    });
  }

  createMenuItems(): ATSMenuItem[] {
    return [
      this.createMenuItem(
        'shared.treeTable.actionMenu.delete',
        this.onDeleteSelectedItem.bind(this),
        true,
        !this.hasEvacuationDeviceUpdatePermission
      ),
    ];
  }

  onDeleteSelectedItem(): void {
    const index = this.tableContent.findIndex(i => i.node === this.child.selectedRow?.node);
    this.tableContent.splice(index, 1);
    this.isChanged.emit();
  }

  checkTableRowValidation(item: EvacuationDeviceFeedbackItem): EvacuationDeviceFeedbackItem {
    item.errorMessage = item.isValid
      ? ''
      : this.translationService.get(
          'settings.functions.wagoConfigDialog.messages.feedbackRowValidationMessage'
        );
    this.isChanged.emit();
    return item;
  }

  onChangeActivationValue(value: string): void {
    if (this.child.selectedRow) {
      this.child.selectedRow.activationValue = value;
      this.checkTableRowValidation(this.child.selectedRow);
    }
  }

  onChangeDeactivationValue(value: string): void {
    if (this.child.selectedRow) {
      this.child.selectedRow.deactivationValue = value;
      this.checkTableRowValidation(this.child.selectedRow);
    }
  }

  fillEvacuationModeTypes(values: string[]): EvacuationModeType[] {
    return values.map(data => {
      return { name: data, value: data };
    });
  }

  getNodeDefaultValue(nodeName: string, columnName: string): string {
    const selectedNode = this.device?.nodes.find(i => i.name === nodeName);

    if (selectedNode && selectedNode.values.length > 0) {
      this.nodeDropdownList[nodeName + '_' + columnName] = this.fillEvacuationModeTypes(
        selectedNode.values
      );

      return selectedNode.values[0];
    }

    return '';
  }

  onNewRowItemIsOpen(value: boolean): void {
    if (value) {
      const content = this.tableContent;
      this.unselectedWriteTypeNodes = this.writeTypeNodes?.filter(function (item) {
        return !content.some(i => i.node === item.value);
      });
    } else if (!value && this.selectedNewRowNodes !== null) {
      this.selectedNewRowNodes.forEach((selectedItem: SelectListOption) => {
        const newItem = new EvacuationDeviceFeedbackItem(
          selectedItem.label,
          this.getNodeDefaultValue(selectedItem.label, 'activationValue'),
          this.getNodeDefaultValue(selectedItem.label, 'deactivationValue')
        );
        this.tableContent.push(this.checkTableRowValidation(newItem));
        this.isChanged.emit();
      });

      this.selectedNewRowNodes = null;
    }
  }

  onNewRowItemValue(values: SelectListOption | SelectListOption[] | null): void {
    this.selectedNewRowNodes = values;
  }

  createMenuItem(key: string, command: Function, visible = true, disabled = false): BasicMenuItem {
    return {
      label: this.translationService.get(key),
      key,
      command,
      visible: visible,
      disabled,
    };
  }
}
