import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild(RouterOutlet)
  outlet!: RouterOutlet;
  loadingRouteConfig = true;
  isCopilotVisible = false;
  ngUnsubscribe = new Subject<void>();

  constructor(private readonly router: Router) {}

  ngAfterViewInit(): void {
    this.updateCopilotVisibility();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.updateCopilotVisibility();
      });

    this.outlet.activateEvents.pipe(take(1)).subscribe(() => {
      this.loadingRouteConfig = false;
    });
  }

  updateCopilotVisibility(): void {
    const currentUrl = this.router.url;
    this.isCopilotVisible = !currentUrl.includes('maps/') && !currentUrl.includes('welcome/');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
