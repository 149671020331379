<ds-modal
  [dimensionsConfig]="{ width: '535px' }"
  #functionModeModal
  [ds-modal-trigger-for]="functionModeModal"
  [accentBarTone]="'caution'"
  [(isModalOpen)]="isModalOpen"
  (modalDismiss)="onClose()">
  <ds-box-header data-cy="dialogHeader">{{ modalInputData?.title }}</ds-box-header>
  <ds-box-content divider="full" data-cy="dialog-content">
    <div class="modalMessageText1">
      <span data-cy="heading"> {{ modalInputData?.heading }}</span>
    </div>
    <div class="modalMessageText2">
      <span data-cy="description"> {{ modalInputData?.description }}</span>
    </div>
  </ds-box-content>
  <ds-box-content
    divider="full"
    data-cy="dialog-content"
    *ngIf="modalInputData && modalInputData.toggleOn === false">
    <form [formGroup]="form">
      <div>
        <ds-form-field class="col-sm-12 col-lg-12">
          <label ds-label for="confirm">
            {{ 'settings.functions.evacuationMode.confirm' | translate }}</label
          >
          <input
            ds-input
            required
            id="confirm"
            formControlName="confirm"
            data-cy="confirmInput"
            type="text"
            autocomplete="off"
            placeholder="{{ 'placeholders.define' | translate }}" />
        </ds-form-field>
      </div>
    </form>
  </ds-box-content>
  <ds-box-footer data-cy="dialog-footer">
    <button ds-button [variant]="'outline'" (click)="onClose()" data-cy="cancelButton">
      {{ 'shared.modalButtons.CancelButton' | translate }}
    </button>

    <button
      ds-button
      class="ms-2x"
      data-cy="saveButton"
      (click)="onConfirm()"
      [disabled]="!form.valid">
      {{
        modalInputData?.toggleOn
          ? ('settings.functions.enable' | translate)
          : ('settings.functions.disable' | translate)
      }}
    </button>
  </ds-box-footer>
</ds-modal>
