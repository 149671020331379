/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ErrorForwardingService, FleetService, IpstSettingsService } from 'core/api-services';
import { EMPTY_GUID } from 'core/constants';
import {
  AlertNowFleetDto,
  AlertNowGroupDto,
  AlertNowVehicleGroupDto,
  FeatureToggle,
  FleetDto,
  IpstAlertNowConnectionSettingsDto,
  IpstServiceFeatures,
  IpstSettingsDto,
  OrganizationDto,
  VehicleDto,
  WorkAreaSettingRecipientKeyDto,
  WorkingAreaDto,
} from 'core/dtos';
import { filterUndefined } from 'core/helpers';
import objectHelper from 'core/helpers/object.helper';
import {
  AlertNowTabDto,
  AtsActions,
  DateString,
  ErrorForwardingModel,
  GuidString,
  IpstAlertNowSettingsTab,
  IpstSettings,
  IpstWorkingAreaSetting,
  ToolBarControlKeys,
  ToolBarControls,
  ToolBarItem,
} from 'core/models';
import { EditBarService, PermissionService, ToastService, ToolbarService } from 'core/services';
import { Tab } from 'library/models';
import _, { isEqual } from 'lodash';
import { Observable, Subject, distinctUntilChanged, of, switchMap, take, takeUntil } from 'rxjs';

import * as fromSettings from 'store-modules/settings-store';
import * as fromVehicles from 'store-modules/vehicles-store';
import * as fromRoot from 'store/index';
@Component({
  selector: 'app-ipst-alertnow-settings-container',
  styleUrls: ['./ipst-alertnow-settings-container.component.scss'],
  templateUrl: './ipst-alertnow-settings-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowSettingsContainerComponent implements OnInit, OnDestroy {
  @ViewChild('IpstServiceTemplate', { static: false })
  templateIpstService!: TemplateRef<HTMLElement>;
  @ViewChild('AlertNowServiceTemplate', { static: false })
  templateAlertNowService!: TemplateRef<HTMLElement>;
  @ViewChild('ErrorForwardingServiceTemplate', { static: false })
  templateErrorForwardingService!: TemplateRef<HTMLElement>;
  @ViewChild('HelpToolTemplate', { static: false })
  templateHelpTool!: TemplateRef<HTMLElement>;

  tableErrorForwarding$: Observable<ErrorForwardingModel[]> = of([]);
  alertNowGroups$: Observable<AlertNowGroupDto[]> = of([]);

  ipstSettingsLoaded$: Observable<boolean> = of(false);
  isEditMode$: Observable<boolean> = of(false);

  ipstLoadLastMessageResolve$: Observable<DateString> = of();
  ipstSettings$: Observable<IpstServiceFeatures> = of();

  selectedWorkingAreaId$: Observable<GuidString> = of('');
  searchTerm$: Observable<string> = of('');

  alertNowGroups: AlertNowGroupDto[] = [];
  TOOLBAR_ITEMS: ToolBarItem[] = [];
  tabs: Tab[] = [];
  allFleets: FleetDto[] = [];
  vehicles: VehicleDto[] = [];

  ngUnsubscribe = new Subject<void>();

  activeTabId: string | undefined;
  selectedOrganization?: OrganizationDto;
  selectedWorkingArea?: WorkingAreaDto;

  pageHeading = 'settings.ipstAlertNowSettings.heading';
  selectedTabId = '';
  alertNowSelectedTabId = '';
  ipstWorkingAreaSettingV2Dto: IpstWorkingAreaSetting = {
    akz: '',
    enabled: false,
    errorLanguage: 1,
    workingAreaId: EMPTY_GUID,
    defaultRecipientKey: '',
    recipientKeys: [''],
    workingArea: '',
    recipientList: [],
  };

  originalIpstWorkingAreaSettingV2Dto: IpstWorkingAreaSetting = {
    akz: '',
    enabled: false,
    errorLanguage: 1,
    workingAreaId: EMPTY_GUID,
    defaultRecipientKey: '',
    recipientKeys: [''],
    workingArea: '',
    recipientList: [],
  };

  generalInformation: IpstSettings = {
    url: '',
    user: '',
    organizationId: EMPTY_GUID,
    password: '',
    workingAreaSettings: [],
    alertNowUrl: '',
  };

  originalGeneralInformation: IpstSettings = {
    url: '',
    user: '',
    organizationId: EMPTY_GUID,
    password: '',
    workingAreaSettings: [],
    alertNowUrl: '',
  };
  ipstEnabled = false;

  get settingsPageTemplate(): TemplateRef<HTMLElement> {
    switch (this.selectedTabId) {
      case IpstAlertNowSettingsTab.ErrorForwardingService:
        this.buildToolBarItems();
        return this.templateErrorForwardingService;
      case IpstAlertNowSettingsTab.IpstService:
        this.clearToolBarItems();
        return this.templateIpstService;
      case IpstAlertNowSettingsTab.AlertNowService:
        return this.templateAlertNowService;
      case IpstAlertNowSettingsTab.HelpTool:
        this.clearToolBarItems();
        return this.templateHelpTool;
      default:
        return this.templateErrorForwardingService;
    }
  }

  mainForm: UntypedFormGroup;
  hasChanges = false;
  originalTuggerTrainErrorsToggle: FeatureToggle = { isToggledOn: false, dateUpdated: null };
  editedTuggerTrainErrorsToggle: FeatureToggle = { isToggledOn: false, dateUpdated: null };
  lastIpstMessageResolved = false;

  constructor(
    private readonly toolbarService: ToolbarService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly permissionService: PermissionService,
    private readonly errorForwardingService: ErrorForwardingService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly ipstService: IpstSettingsService,
    private readonly fleetService: FleetService,
    private readonly editBarService: EditBarService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly toastService: ToastService,
    private readonly vehiclesStore: Store<fromVehicles.VehiclesFeatureState>,
    private readonly settingsStore: Store<fromSettings.SettingsFeatureState>
  ) {
    this.mainForm = this.createMainForm();
  }

  ngOnInit(): void {
    this.setTabs();
    this.buildToolBarItems();
    this.dispatchActionsAndQuerySelectors();
    this.initializeSubscriptions();
    this.onEditFormChanges();
    this.subscribeToIpstSettingsTuggerTrainErrors();
  }

  ngOnDestroy(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createMainForm(): UntypedFormGroup {
    return this.formBuilder.group({
      generalInformation: this.formBuilder.control({}),
      ipstSettings: this.formBuilder.control({}),
    });
  }

  setTabs(): void {
    this.tabs = [
      {
        key: IpstAlertNowSettingsTab.ErrorForwardingService,
        heading: 'settings.ipstAlertNowSettings.tabs.errorForwarding',
      },
      {
        key: IpstAlertNowSettingsTab.IpstService,
        heading: 'settings.ipstAlertNowSettings.tabs.ipstService',
      },
      {
        key: IpstAlertNowSettingsTab.AlertNowService,
        heading: 'settings.ipstAlertNowSettings.tabs.alertNowService',
      },
      {
        key: IpstAlertNowSettingsTab.HelpTool,
        heading: 'settings.ipstAlertNowSettings.tabs.helpTool',
      },
    ];

    this.selectedTabId = this.tabs[0].key;
    this.cdRef.markForCheck();
  }

  dispatchActionsAndQuerySelectors(): void {
    this.subscribeToVehicles();

    this.editBarService.setCanDelete(false);
    this.editBarService.onCancel$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onCancel.bind(this));
    this.editBarService.onSave = this.onSave.bind(this);

    this.searchTerm$ = this.toolbarService.searchTerm$.pipe(distinctUntilChanged());
    this.ipstSettings$ = this.rootStore.pipe(select(fromSettings.selectIpstFeatures));
    this.ipstSettingsLoaded$ = this.rootStore.pipe(select(fromSettings.selectIpstFeaturesLoaded));
    this.selectedWorkingAreaId$ = this.rootStore.pipe(select(fromRoot.selectSelectedWorkingAreaId));
    this.selectedWorkingAreaId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(workAreaId => {
      this.rootStore.dispatch(fromSettings.loadLastIpstMessageResolve({ workAreaId }));
    });
    this.ipstLoadLastMessageResolve$ = this.rootStore.pipe(
      select(fromSettings.selectIpstLastMessageResolved)
    );
    this.tableErrorForwarding$ = this.rootStore.pipe(select(fromSettings.selectAllErrorForwarding));
  }

  subscribeToVehicles(): void {
    this.vehiclesStore
      .pipe(select(fromVehicles.selectAllVehicles), takeUntil(this.ngUnsubscribe))
      .subscribe(val => {
        this.vehicles = val;
        this.cdRef.markForCheck();
      });
  }

  buildToolBarItems(): void {
    this.TOOLBAR_ITEMS = [
      {
        key: ToolBarControlKeys.Search,
        type: ToolBarControls.Search,
      },
    ];
    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
  }

  clearToolBarItems(): void {
    this.TOOLBAR_ITEMS = [];
    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
  }

  initializeSubscriptions(): void {
    this.rootStore
      .pipe(select(fromRoot.selectReducedSelectedWorkingArea), takeUntil(this.ngUnsubscribe))
      .subscribe(workingArea => {
        if (workingArea) {
          this.selectedWorkingArea = workingArea;
          if (this.selectedOrganization) this.getData(this.selectedOrganization);
          this.cdRef.markForCheck();
        }
      });

    this.rootStore
      .pipe(
        select(fromRoot.getRouterInfo),
        switchMap(({ params: { organization } }) =>
          this.rootStore.pipe(
            select(fromRoot.selectOrganizationByName(organization)),
            filterUndefined(),
            take(1)
          )
        )
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(org => {
        this.selectedOrganization = org;
        this.getData(org);
      });

    this.rootStore
      .pipe(select(fromRoot.selectReducedSelectedWorkingArea), takeUntil(this.ngUnsubscribe))
      .subscribe(workingArea => {
        if (workingArea) {
          this.selectedWorkingArea = workingArea;
          this.populateForm();
          this.cdRef.markForCheck();
        }
      });

    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));

    if (this.selectedWorkingArea?.id) {
      this.alertNowGroups$ = this.ipstService.getZoneAlertNowGroups(this.selectedWorkingArea?.id);
    }
  }

  checkTemplateAndSetVisibility(selectedTabId: string): void {
    let isVisible = true;

    if (selectedTabId === IpstAlertNowSettingsTab.HelpTool) {
      isVisible = false;
    } else {
      isVisible = this.permissionService.actionAllowedInOrg(
        AtsActions.IpstSettingsEdit,
        this.selectedOrganization?.id ?? ''
      );
    }

    this.rootStore.dispatch(
      fromRoot.showHideEditToggle({
        isVisible: isVisible,
      })
    );
  }

  setupTab(): void {
    switch (this.selectedTabId) {
      case IpstAlertNowSettingsTab.IpstService:
        this.editBarService.onSave = this.onSave.bind(this);
        break;
      case IpstAlertNowSettingsTab.ErrorForwardingService:
        this.tableErrorForwarding$ = this.errorForwardingService.getErrorForwardings();
    }
  }

  getData(selectedOrganization: OrganizationDto): void {
    this.ipstService
      .getWaIpstSettings(selectedOrganization, this.selectedWorkingArea?.id)
      .pipe(take(1))
      .subscribe(data => {
        this.generalInformation = this.convertToIpstSettings(data);
        this.populateForm();
      });

    if (selectedOrganization && this.selectedWorkingArea)
      this.ipstService
        .getAlertNowGroups(selectedOrganization, this.selectedWorkingArea.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
          this.alertNowGroups = data;
        });

    this.fleetService
      .getFleets()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.allFleets = data;
      });
  }

  ipstHasBeenToggled($event: boolean): void {
    this.ipstEnabled = $event;
  }

  convertToIpstSettings(data: IpstSettingsDto): IpstSettings {
    const ipstSettings: IpstSettings = {
      url: data.url,
      user: data.user,
      password: data.password,
      organizationId: data.organizationId,
      alertNowUrl: data.alertNowUrl,
      workingAreaSettings: data.workingAreaSettings.map(workingAreaDto => ({
        akz: workingAreaDto.akz,
        defaultRecipientKey: workingAreaDto.defaultRecipientKey,
        enabled: workingAreaDto.enabled,
        workingAreaId: this.selectedWorkingArea?.id ?? '',
        recipientKeys: workingAreaDto.recipientKeys,
        errorLanguage: workingAreaDto.errorLanguage,
        workingArea: workingAreaDto.akz,
        recipientList: workingAreaDto.recipientList,
      })),
    };

    return ipstSettings;
  }

  patchForm(): void {
    const generalInfo = {
      url: this.generalInformation.url,
      user: this.generalInformation.user,
      password: this.generalInformation.password,
      organizationId: this.generalInformation.organizationId,
    };

    const ipstSettings = {
      akz: this.ipstWorkingAreaSettingV2Dto.akz,
      enabled: this.ipstWorkingAreaSettingV2Dto.enabled,
      errorLanguage: this.ipstWorkingAreaSettingV2Dto.errorLanguage,
    };

    this.mainForm.patchValue({
      generalInformation: generalInfo,
      ipstSettings: ipstSettings,
    });

    this.cdRef.markForCheck();
  }

  populateForm(): void {
    const wa = this.generalInformation.workingAreaSettings.find(
      waID => waID.workingAreaId === this.selectedWorkingArea?.id
    );

    if (wa) {
      this.ipstWorkingAreaSettingV2Dto = {
        akz: wa.akz,
        enabled: wa.enabled,
        errorLanguage: wa.errorLanguage,
        defaultRecipientKey: wa.defaultRecipientKey,
        recipientKeys: wa.recipientKeys,
        workingArea: wa.workingArea,
        workingAreaId: wa.workingAreaId,
        recipientList: wa.recipientList,
      };
    }

    this.originalIpstWorkingAreaSettingV2Dto = objectHelper.cloneDeep(
      this.ipstWorkingAreaSettingV2Dto
    );

    this.originalGeneralInformation = objectHelper.cloneDeep(this.generalInformation);
    this.patchForm();
  }

  onCancel(): void {
    this.generalInformation = objectHelper.cloneDeep(this.originalGeneralInformation);
    this.ipstWorkingAreaSettingV2Dto = objectHelper.cloneDeep(
      this.originalIpstWorkingAreaSettingV2Dto
    );

    this.patchForm();

    const toggleValue = this.mainForm.get('ipstSettings')?.get('enabled')?.value;

    if (toggleValue && this.originalIpstWorkingAreaSettingV2Dto.enabled !== toggleValue) {
      this.onSaveIncludeTuggerTrainErrors(this.ipstWorkingAreaSettingV2Dto.enabled);
    }

    this.cdRef.markForCheck();
  }

  onDeleteAlertNowGroup(alertNowGroup: AlertNowGroupDto): void {
    if (alertNowGroup.workAreaId !== '') {
      const groups = this.alertNowGroups.filter(i => i.id !== alertNowGroup.id);

      this.updateAlertNowGroups(groups);
    }
  }

  getAlertNowFleets(alertNowVehicleGroup: AlertNowVehicleGroupDto): AlertNowFleetDto[] {
    const alertNowVehicleIds = alertNowVehicleGroup.alertNowVehicles.map(i => i.id);
    const fleets: AlertNowFleetDto[] = [];

    this.allFleets.forEach(f => {
      const fleetVehicleIds = f.vehicles.map(i => i.id);

      if (fleetVehicleIds.every(id => alertNowVehicleIds.includes(id))) {
        fleets.push({
          id: f.id,
          vehicleGroupId: alertNowVehicleGroup.id,
        });
      }
    });

    return fleets;
  }

  getAlertNowVehicleGroups(alertNowVehicleGroup: AlertNowVehicleGroupDto): AlertNowVehicleGroupDto {
    const alertNowFleets = this.getAlertNowFleets(alertNowVehicleGroup);
    const alertNowFleetIds = alertNowFleets.map(i => i.id);
    const fleets: FleetDto[] = this.allFleets.filter(fleet => alertNowFleetIds.includes(fleet.id));
    const vehicleIds = fleets.flatMap(fleet => fleet.vehicles.map(vehicle => vehicle.id));
    const alertNowVehicles = alertNowVehicleGroup.alertNowVehicles.filter(
      i => !vehicleIds.includes(i.id)
    );

    return {
      ...alertNowVehicleGroup,
      alertNowFleets: alertNowFleets,
      alertNowVehicles: alertNowVehicles,
    };
  }

  updateAlertNowGroups(alertNowGroups: AlertNowGroupDto[]): void {
    const validGroups = alertNowGroups.filter(i => i.workAreaId !== '');

    if (this.selectedOrganization && this.selectedWorkingArea)
      this.ipstService
        .updateIpstAlertNowGroup(
          validGroups,
          this.selectedOrganization,
          this.selectedWorkingArea.id
        )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.toastService.createSuccessToast(
              'settings.ipstAlertNowSettings.saveGroupSuccessMessage'
            );

            this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));

            this.hasChanges = false;
            this.alertNowGroups = validGroups;
          },
          error: () => {
            this.toastService.createErrorToast(
              'settings.ipstAlertNowSettings.saveGroupFailedMessage'
            );
          },
        });
  }

  onSave(): void {
    this.saveOrgLevelAlertNowSettings();

    const checkForChangesErrorMessagesTuggerTrain = this.checkForChangesErrorMessagesTuggerTrain();

    if (checkForChangesErrorMessagesTuggerTrain) {
      this.rootStore.dispatch(
        fromSettings.toggleIncludeTuggerTrainErrors({
          toggle: this.editedTuggerTrainErrorsToggle.isToggledOn,
        })
      );
    }
  }

  saveOrgLevelAlertNowSettings(): void {
    const ipstAlertNowConnectionSettingsDto: IpstAlertNowConnectionSettingsDto = {
      workAreaId: this.selectedWorkingArea?.id ?? '',
      url: this.generalInformation.url,
      user: this.generalInformation.user,
      password: this.generalInformation.password,
      alertNowUrl: this.generalInformation.alertNowUrl,
      akz: this.ipstWorkingAreaSettingV2Dto.akz,
      enabled: this.ipstWorkingAreaSettingV2Dto.enabled,
      errorLanguage: this.ipstWorkingAreaSettingV2Dto.errorLanguage,
    };

    this.originalIpstWorkingAreaSettingV2Dto.akz = ipstAlertNowConnectionSettingsDto.akz;
    this.originalIpstWorkingAreaSettingV2Dto.enabled = ipstAlertNowConnectionSettingsDto.enabled;
    this.originalIpstWorkingAreaSettingV2Dto.errorLanguage =
      ipstAlertNowConnectionSettingsDto.errorLanguage;
    this.originalIpstWorkingAreaSettingV2Dto = { ...this.originalIpstWorkingAreaSettingV2Dto };

    this.ipstService
      .updateIpstAlertNowSettings(ipstAlertNowConnectionSettingsDto, this.selectedOrganization)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.toastService.createSuccessToast(
            'settings.ipstAlertNowSettings.saveUrlSuccessMessage'
          );
          this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
          this.hasChanges = false;
        },
        error: () => {
          this.toastService.createErrorToast('settings.ipstAlertNowSettings.saveUrlFailedMessage');
        },
      });
  }

  onEditFormChanges(): void {
    this.initializeFormChangeSubscriptions();
  }

  initializeFormChangeSubscriptions(): void {
    this.subscribeToFormStatusChanges();
    this.subscribeToFormValueChanges();
  }

  subscribeToFormStatusChanges(): void {
    this.mainForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.editBarService.setIsFormValid(status === 'VALID');
    });
  }

  subscribeToFormValueChanges(): void {
    this.mainForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(formValue => {
      this.updateGeneralInformation(formValue.generalInformation);
      this.updateIpstWorkingAreaSetting(formValue.ipstSettings);
      this.checkForChangesInForms();
    });
  }

  updateGeneralInformation(generalInfo: IpstSettings): void {
    this.generalInformation = {
      ...this.generalInformation,
      url: generalInfo.url,
      user: generalInfo.user,
      password: generalInfo.password,
      organizationId:
        this.selectedOrganization?.id || this.originalGeneralInformation.organizationId,
    };
  }

  updateIpstWorkingAreaSetting(ipstSettings: IpstWorkingAreaSetting): void {
    this.ipstWorkingAreaSettingV2Dto = {
      ...this.ipstWorkingAreaSettingV2Dto,
      akz: ipstSettings.akz,
      enabled: ipstSettings.enabled,
      errorLanguage: ipstSettings.errorLanguage,
      workingAreaId:
        this.selectedWorkingArea?.id || this.originalIpstWorkingAreaSettingV2Dto.workingAreaId,
      workingArea:
        this.selectedWorkingArea?.name || this.originalIpstWorkingAreaSettingV2Dto.workingArea,
    };
  }

  checkForChangesInForms(): void {
    const generalInfoChanged = !isEqual(this.generalInformation, this.originalGeneralInformation);
    const workingAreaSettingChanged = !isEqual(
      this.ipstWorkingAreaSettingV2Dto,
      this.originalIpstWorkingAreaSettingV2Dto
    );

    this.hasChanges = generalInfoChanged || workingAreaSettingChanged;
    this.editBarService.setHasChanges(this.hasChanges);
  }

  checkForChangesInToggle(): void {
    const errorMessagesTuggerTrain = !isEqual(
      this.editedTuggerTrainErrorsToggle.isToggledOn,
      this.originalTuggerTrainErrorsToggle.isToggledOn
    );
    this.hasChanges = errorMessagesTuggerTrain;
    this.editBarService.setHasChanges(this.hasChanges);
  }

  onSaveIncludeTuggerTrainErrors(includeTuggerTrainErrorsToggle: boolean): void {
    this.editedTuggerTrainErrorsToggle.isToggledOn = includeTuggerTrainErrorsToggle;
    this.checkForChangesInToggle();
  }

  checkForChangesErrorMessagesTuggerTrain(): boolean {
    return (
      this.editedTuggerTrainErrorsToggle.isToggledOn !==
      this.originalTuggerTrainErrorsToggle.isToggledOn
    );
  }

  subscribeToIpstSettingsTuggerTrainErrors(): void {
    this.ipstSettings$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(settings => {
      this.originalTuggerTrainErrorsToggle = settings.includeTuggerTrainErrorsToggle;
      this.cdRef.markForCheck();
    });
  }

  resolveAllIpstMessages(workAreaId: GuidString): void {
    this.lastIpstMessageResolved = false;
    this.cdRef.markForCheck();

    if (this.selectedOrganization) {
      this.rootStore.dispatch(
        fromSettings.resolveAllIpstMessages({
          workAreaId: workAreaId,
          org: this.selectedOrganization,
        })
      );

      this.lastIpstMessageResolved = true;
      this.hasChanges = false;
    }
  }

  onSaveAlertNowGroups(alertNowGroups: AlertNowGroupDto[]): void {
    const updatedAlertNowGroups = alertNowGroups.flatMap(group => {
      return {
        ...group,
        alertNowVehicleGroups: group.alertNowVehicleGroups.map(vehicleGroup =>
          this.getAlertNowVehicleGroups(vehicleGroup)
        ),
      };
    });

    this.updateAlertNowGroups(updatedAlertNowGroups);
  }

  onSaveAlertNowSettings(alertNowTabDto: AlertNowTabDto): void {
    this.originalGeneralInformation = objectHelper.cloneDeep(this.generalInformation);
    if (this.originalGeneralInformation.alertNowUrl !== alertNowTabDto.alertNowUrl) {
      this.generalInformation.alertNowUrl = alertNowTabDto.alertNowUrl;
      this.originalGeneralInformation.alertNowUrl = alertNowTabDto.alertNowUrl;
      this.saveOrgLevelAlertNowSettings();
    }

    this.originalIpstWorkingAreaSettingV2Dto = { ...this.originalIpstWorkingAreaSettingV2Dto };

    for (const workArea of this.generalInformation.workingAreaSettings) {
      workArea.recipientKeys = _.uniq(workArea.recipientKeys);

      if (workArea.recipientKeys.length === 0) {
        workArea.recipientKeys.push('');
      }

      if (!workArea.recipientKeys.includes(workArea.defaultRecipientKey)) {
        workArea.defaultRecipientKey = workArea.recipientKeys[0];
      }
    }

    const existingWorkingArea = this.generalInformation.workingAreaSettings.findIndex(
      setting => setting.workingAreaId === alertNowTabDto.workingAreaId
    );

    if (existingWorkingArea !== -1) {
      this.generalInformation.workingAreaSettings[existingWorkingArea].recipientList =
        alertNowTabDto.recipientList;
    }

    this.ipstService
      .updateAlertNowSettingsForWorkArea(
        alertNowTabDto,
        this.selectedOrganization,
        this.selectedWorkingArea?.id
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result: WorkAreaSettingRecipientKeyDto[]) => {
          this.toastService.createSuccessToast(
            'settings.ipstAlertNowSettings.saveRecipientsSuccessMessage'
          );
          this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
          this.hasChanges = false;
          this.ipstWorkingAreaSettingV2Dto = {
            ...this.ipstWorkingAreaSettingV2Dto,
            recipientList: result,
          };
          this.originalIpstWorkingAreaSettingV2Dto = objectHelper.cloneDeep(
            this.ipstWorkingAreaSettingV2Dto
          );
          this.cdRef.markForCheck();
        },
        error: (error: unknown) => {
          this.toastService.createErrorToast(
            'settings.ipstAlertNowSettings.saveRecipientsFailedMessage',
            { error: error }
          );
        },
      });
  }

  onSelectedTabChanged(tabId: string): void {
    if (this.selectedTabId !== tabId) {
      this.selectedTabId = tabId;
      this.checkTemplateAndSetVisibility(this.selectedTabId);
      this.setupTab();
    }
  }

  saveErrorForwarding(errorForwardingChange: ErrorForwardingModel[]): void {
    errorForwardingChange.forEach(
      e => (e.recipientKeyIds = e.recipientKeyIds.filter(x => x !== 'zoneAlertNowGroup'))
    );
    this.settingsStore.dispatch(
      fromSettings.saveErrorForwarding({ errorForwardingModel: errorForwardingChange })
    );
  }
}
