import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-ats-copilot',
  templateUrl: './ats-copilot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AtsCopilotComponent {
  isChatOpen = false;

  openChat(): void {
    this.isChatOpen = true;
  }

  closeChat(): void {
    this.isChatOpen = false;
  }
}
