/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  NodeType,
  OpcuaDeviceNodeNotification,
  OpcuaDeviceNodeTelemetryPagedResponseModel,
  OpcuaDeviceNotificationEdit,
  OpcuaDeviceRequestModel,
  OpcuaDeviceResponseModel,
  OpcuaStreamingServiceDto,
} from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable, firstValueFrom } from 'rxjs';
import { RootState } from 'store/reducers';

import { API_SERVICES } from 'core/constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OpcuaDeviceService extends TenantHttpClient {
  protected apiUrl = environment.Services.OpcuaDeviceManager;
  private readonly servicePath = API_SERVICES.OPCUADevice;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getStreamingServicesWithDevices(): Observable<OpcuaStreamingServiceDto[]> {
    return this.get<OpcuaStreamingServiceDto[]>(`${this.servicePath}/streamingServices`);
  }

  getDevice(streamingService: string, name: string): Observable<OpcuaDeviceResponseModel> {
    return this.get<OpcuaDeviceResponseModel>(
      `${this.servicePath}/streamingServices/${streamingService}/devices/${name}`
    );
  }

  getDeviceStateHistory(
    streamingService: string,
    device: string,
    page: number,
    pageSize: number,
    startDate: string | null | undefined,
    endDate: string | null | undefined,
    nodes: string[] | null | undefined,
    value: string | null | undefined,
    types: NodeType[] | null
  ): Observable<OpcuaDeviceNodeTelemetryPagedResponseModel> {
    let url = `${this.servicePath}/streamingServices/${streamingService}/devices/${device}/nodes/history?page=${page}&pageSize=${pageSize}`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (nodes) {
      for (const node of nodes) {
        url += `&nodes=${node}`;
      }
    }
    if (value) {
      url += `&value=${value}`;
    }
    if (types) {
      for (const type of types) {
        url += `&type=${type}`;
      }
    }
    return this.get<OpcuaDeviceNodeTelemetryPagedResponseModel>(url);
  }

  async exportDeviceStateHistory(
    streamingService: string,
    device: string,
    startDate: string | null | undefined,
    endDate: string | null | undefined,
    nodes: string[] | null | undefined,
    value: string | null | undefined,
    types: NodeType[] | null | undefined
  ): Promise<void> {
    const prefix = await firstValueFrom(this.getUrlFromServicePath(this.servicePath));
    let url = `${prefix}/streamingServices/${streamingService}/devices/${device}/nodes/history.csv?page=1`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (nodes) {
      for (const node of nodes) {
        url += `&nodes=${node}`;
      }
    }
    if (value) {
      url += `&value=${value}`;
    }
    if (types) {
      for (const type of types) {
        url += `&type=${type}`;
      }
    }

    window.open(url);
  }

  createStreamingService(
    newStreamingService: OpcuaStreamingServiceDto
  ): Observable<OpcuaStreamingServiceDto> {
    return this.post<OpcuaStreamingServiceDto>(
      `${this.servicePath}/streamingServices`,
      newStreamingService
    );
  }

  updateStreamingService(
    streamingService: OpcuaStreamingServiceDto
  ): Observable<OpcuaStreamingServiceDto> {
    return this.patch<OpcuaStreamingServiceDto>(
      `${this.servicePath}/streamingServices/${streamingService.name}`,
      streamingService
    );
  }

  deleteStreamingService(streamingService: string): Observable<void> {
    return this.delete<void>(`${this.servicePath}/streamingServices/${streamingService}`);
  }

  createDevice(newDevice: OpcuaDeviceRequestModel): Observable<OpcuaDeviceResponseModel> {
    const toPost = objectHelper.omit(newDevice, ['lastPingReceivedDate']);

    return this.post<OpcuaDeviceResponseModel>(
      `${this.servicePath}/streamingServices/${newDevice.streamingServiceName}/devices`,
      toPost
    );
  }

  updateDevice(updatedDevice: OpcuaDeviceRequestModel): Observable<OpcuaDeviceResponseModel> {
    return this.patch<OpcuaDeviceResponseModel>(
      `${this.servicePath}/streamingServices/${updatedDevice.streamingServiceName}/devices/${updatedDevice.name}`,
      updatedDevice
    );
  }

  deleteDevice(name: string, streamingService: string): Observable<void> {
    return this.delete<void>(
      `${this.servicePath}/streamingServices/${streamingService}/devices/${name}`
    );
  }

  createNotification(
    streamingService: string,
    opcuaNotification: OpcuaDeviceNodeNotification
  ): Observable<OpcuaDeviceNodeNotification> {
    return this.post<OpcuaDeviceNodeNotification>(
      `${this.servicePath}/streamingServices/${streamingService}/devices/${opcuaNotification.device}/notifications`,
      opcuaNotification
    );
  }

  updateNotification(
    streamingService: string,
    opcuaNotification: OpcuaDeviceNodeNotification
  ): Observable<void> {
    const editRecipientKeyDto: OpcuaDeviceNotificationEdit = {
      recipientKey: opcuaNotification.recipientKey,
    };

    return this.patch<void>(
      `${this.servicePath}/streamingServices/${streamingService}/devices/${opcuaNotification.device}/notifications/${opcuaNotification.node}/${opcuaNotification.value}`,
      editRecipientKeyDto
    );
  }

  deleteNotification(
    streamingService: string,
    opcuaNotification: OpcuaDeviceNodeNotification
  ): Observable<void> {
    return this.delete<void>(
      `${this.servicePath}/streamingServices/${streamingService}/devices/${opcuaNotification.device}/notifications/${opcuaNotification.node}/${opcuaNotification.value}`
    );
  }

  disableNotification(
    streamingService: string,
    opcuaNotification: OpcuaDeviceNodeNotification
  ): Observable<OpcuaDeviceNodeNotification> {
    return this.post<OpcuaDeviceNodeNotification>(
      `${this.servicePath}/streamingServices/${streamingService}/devices/${opcuaNotification.device}/notifications/${opcuaNotification.node}/${opcuaNotification.value}/disable`
    );
  }

  enableNotification(
    streamingService: string,
    opcuaNotification: OpcuaDeviceNodeNotification
  ): Observable<OpcuaDeviceNodeNotification> {
    return this.post<OpcuaDeviceNodeNotification>(
      `${this.servicePath}/streamingServices/${streamingService}/devices/${opcuaNotification.device}/notifications/${opcuaNotification.node}/${opcuaNotification.value}/enable`
    );
  }

  updateDeviceNodes(device: OpcuaDeviceRequestModel): Observable<OpcuaDeviceResponseModel> {
    return this.patch<OpcuaDeviceResponseModel>(
      `${this.servicePath}/streamingServices/${device.streamingServiceName}/devices/${device.name}/nodes`,
      device
    );
  }
}
