import { DATE_FORMAT } from 'core/constants';
import { FleetDto, MapDto, PoiDto, VehicleDto, ZoneSetDto } from 'core/dtos';
import { MapManagerListViewItem, ProcessChain, ZoneSetStatus } from 'core/models';
import { findLast } from 'lodash';
import { formatDate } from 'shared/helpers';

export const createManageMapList = (
  maps: MapDto[],
  pois: PoiDto[],
  fleets: FleetDto[],
  vehicles: VehicleDto[],
  processChains: ProcessChain[],
  zoneSets: ZoneSetDto[]
): MapManagerListViewItem[] => {
  return maps.map(m => ({
    id: m.id,
    name: m.name,
    creationDateTime: formatDate(m.creationDateTime, DATE_FORMAT.FULL_DATE_TIME),
    mapData: '',
    activeZoneSetName:
      findLast(zoneSets, set => set.mapId === m.id && set.status === ZoneSetStatus.Active)?.name ||
      '',
    numVehicles: vehicles.filter(vehicle => vehicle?.map?.id === m.id).length,
    numPois: pois.filter(poi => poi.mapId === m.id).length,
    numProcessChains: processChains.filter(pc => pc.missions[0]?.mapId === m.id).length,
    numFleets: fleets.filter(fleet => fleet.mapId === m.id).length,
    numZoneSets: zoneSets.filter(zoneSet => zoneSet.mapId === m.id).length,
  }));
};
