import { createSelector } from '@ngrx/store';
import { Mission, ProcessChain, StepType } from 'core/models';

import * as ProcessConfiguratorFeatureState from '../reducers/index';
import * as fromProcessChainReducer from '../reducers/process-chain.reducer';

const selectProcessChainState = createSelector(
  ProcessConfiguratorFeatureState.getProcessConfiguratorFeatureState,
  ProcessConfiguratorFeatureState.getProcessChainState
);

export const selectProcessChainEntities = createSelector(
  selectProcessChainState,
  fromProcessChainReducer.getEntities
);

export const selectProcessChainLoaded = createSelector(
  selectProcessChainState,
  fromProcessChainReducer.getLoaded
);
export const selectAllProcessChains = createSelector(
  selectProcessChainState,
  fromProcessChainReducer.getProcessChains
);
export const selectProcessChainsLoading = createSelector(
  selectProcessChainState,
  fromProcessChainReducer.getLoading
);

export const selectSelectedProcessChainId = createSelector(
  selectProcessChainState,
  fromProcessChainReducer.getSelectedProcessChainId
);

export const selectSelectedProcessChain = createSelector(
  selectSelectedProcessChainId,
  selectProcessChainEntities,
  (processChainId, processChains): ProcessChain | undefined => processChains[String(processChainId)]
);

export const selectAllMissions = createSelector(
  selectAllProcessChains,
  (allProcessChains): Mission[] => {
    return allProcessChains.flatMap(pc => {
      return pc.missions;
    });
  }
);

export const selectAllMissionsForDirectDispatch = createSelector(
  selectAllMissions,
  (missions): Mission[] => {
    return missions.filter(
      mission =>
        !mission.steps.find(
          step =>
            step.type === StepType.GoToMapping ||
            step.type === StepType.DockToMapping ||
            step.type === StepType.WaitForEndOfStep
        )
    );
  }
);
