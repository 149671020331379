import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PillarsGridDto } from 'core/dtos';
import { GuidString } from 'core/models';

import * as PillarsGridActions from '../actions';

export const featureKey = 'pillarsGrid';

export interface PillarsGridState extends EntityState<PillarsGridDto> {
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
  pillarsGridId: GuidString;
  actionStatus: PillarsGridActions.PillarsGridTypes | null;
}

export const pillarsGridAdapter: EntityAdapter<PillarsGridDto> =
  createEntityAdapter<PillarsGridDto>();

export const initialState: PillarsGridState = pillarsGridAdapter.getInitialState({
  loaded: false,
  loading: false,
  errorMessage: '',
  pillarsGridId: '',
  actionStatus: null,
});

const pillarsGridReducer = createReducer(
  initialState,

  on(PillarsGridActions.loadPillarsGridForMap, state => ({
    ...state,
    loading: true,
    loaded: false,
    errorMessage: '',
  })),

  on(PillarsGridActions.loadPillarsGridSuccess, (state, { pillarsGrid }) =>
    pillarsGridAdapter.setOne(pillarsGrid, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(
    PillarsGridActions.createPillarsGrid,
    PillarsGridActions.deletePillarsGrid,
    PillarsGridActions.updatePillarsGrid,
    state => ({
      ...state,
      errorMessage: '',
    })
  ),

  on(PillarsGridActions.createPillarsGridSuccess, (state, { pillarsGrid }) =>
    pillarsGridAdapter.addOne(pillarsGrid, {
      ...state,
      actionStatus: PillarsGridActions.PillarsGridTypes.CreatePillarsGridSuccess,
    })
  ),

  on(PillarsGridActions.updatePillarsGridSuccess, (state, { pillarsGrid }) =>
    pillarsGridAdapter.updateOne({ id: pillarsGrid.id.toString(), changes: pillarsGrid }, state)
  ),

  on(PillarsGridActions.deletePillarsGridSuccess, (state, { pillarsGridId }) =>
    pillarsGridAdapter.removeOne(pillarsGridId.toString(), {
      ...state,
      actionStatus: PillarsGridActions.PillarsGridTypes.DeletePillarsGridSuccess,
    })
  ),

  on(
    PillarsGridActions.createPillarsGridFailure,
    PillarsGridActions.deletePillarsGridFailure,
    PillarsGridActions.updatePillarsGridFailure,
    PillarsGridActions.loadPillarsGridFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
    })
  )
);

export function reducer(state: PillarsGridState | undefined, action: Action): PillarsGridState {
  return pillarsGridReducer(state, action);
}

export const { selectEntities, selectAll } = pillarsGridAdapter.getSelectors();

export const getLoading = (state: PillarsGridState): boolean => state.loading;
export const getLoaded = (state: PillarsGridState): boolean => state.loaded;
export const getErrorMessage = (state: PillarsGridState): string => state.errorMessage;
export const getGridActionStatus = (
  state: PillarsGridState
): PillarsGridActions.PillarsGridTypes | null => state.actionStatus;
export const getPillarsGridEntities = selectEntities;
export const getAllPillarsGrids = selectAll;
