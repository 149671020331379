import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DsSelectLabellingConfiguration } from '@bmw-ds/components/ds-interfaces/select/config.interface';
import { BasicMenuItem, GuidString, Poi, PoiType, StepType, VehicleType } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { TuggerTrainSendStepInputModel } from '../vehicle-actions/vehicle-actions.models';
import { getRequiredFieldsByStepType, toggleAttributeDisable } from './step-type-helper';
import { VehicleSendNodeStepModel, VehicleSendStepModel } from './vehicle-send-steps.viewmodel';

@Directive()
export class BaseSendStepDirective {
  @Input() pois: Poi[] = [];

  stepForm: UntypedFormGroup;
  stepItems: BasicMenuItem[] = [];
  waypointItems: BasicMenuItem[] = [];
  waypoints: Poi[] = [];
  stepIndex?: number = undefined;
  waypointIndex?: number = undefined;
  hasChanges = false;
  isSendingStepsToVehicle = false;

  labellingConfig: DsSelectLabellingConfiguration = {
    noResultsMessage: this.translate.get('placeholders.selectEmpty'),
    placeholder: this.translate.get('placeholders.select'),
    selectAll: this.translate.get('placeholders.selectAll'),
  };

  constructor(
    protected readonly formBuilder: UntypedFormBuilder,
    protected readonly translate: AtsTranslationService,
    protected readonly cdRef: ChangeDetectorRef
  ) {
    this.stepForm = this.createFormGroup();
  }

  createFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      routeConfigurationId: this.formBuilder.control(''),
      steps: this.formBuilder.array([this.newStep()]),
    });
  }

  get stepsFormArray(): UntypedFormArray {
    return this.stepForm.get('steps') as UntypedFormArray;
  }

  get routeConfigurationId(): AbstractControl {
    return this.stepForm.get('routeConfigurationId') || new UntypedFormControl();
  }

  newStep(): UntypedFormGroup {
    return this.formBuilder.group({
      stepType: ['', [Validators.required]],
      pointOfInterestGroup: [''],
      waypoints: this.formBuilder.array([]),
      timeSpan: [],
    });
  }

  addStep(): void {
    this.stepsFormArray.push(this.newStep());
  }

  removeStep(): void {
    if (this.stepIndex !== undefined) {
      this.stepsFormArray.removeAt(this.stepIndex);
    }
  }

  stepWaypointArray(stepIndex: number): UntypedFormArray {
    return this.stepsFormArray.at(stepIndex).get('waypoints') as UntypedFormArray;
  }

  newWaypoint(): UntypedFormGroup {
    return this.formBuilder.group({
      waypoint: ['', [Validators.required]],
    });
  }

  onAddWayPoint(): void {
    if (this.stepIndex !== undefined) {
      this.stepWaypointArray(this.stepIndex).push(this.newWaypoint());
    }
  }

  onDeleteWayPoint(): void {
    if (this.stepIndex !== undefined && this.waypointIndex !== undefined) {
      this.stepWaypointArray(this.stepIndex).removeAt(this.waypointIndex);
    }
  }

  onStepTypeChanged(stepType: StepType, step: UntypedFormGroup): void {
    // TODO: reset attribute selection
    this.setStepTypeAttributeAndFromValidation(stepType, step);
  }

  showHideDeleteAction(): void {
    this.stepItems[1].visible = this.stepsFormArray.length > 1 ?? false;
    this.stepItems = [...this.stepItems];
    this.cdRef.markForCheck();
  }

  isWait(step: UntypedFormGroup): boolean {
    return step.get('stepType')?.value === StepType.Wait;
  }

  attributeDisabled(step: UntypedFormGroup): boolean {
    return toggleAttributeDisable(step.get('stepType')?.value);
  }

  setStepIndex(stepIndex: number): void {
    this.stepIndex = stepIndex;
  }

  setWaypointIndex(waypointIndex: number): void {
    this.waypointIndex = waypointIndex;
  }

  prepareDropDownOptions(): void {
    this.waypoints = this.pois.filter((poi: Poi) => poi.type === PoiType.WayPoint);
  }

  dropWaypoint(event: CdkDragDrop<AbstractControl[]>, stepIndex: number): void {
    moveItemInArray(
      this.stepWaypointArray(stepIndex).controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  createStepMenuItems(vehicleType: VehicleType = VehicleType.UnitLoad): BasicMenuItem[] {
    return this.createMenuItems(this.onAddWayPoint, this.removeStep, vehicleType);
  }

  createWaypointMenuItems(vehicleType: VehicleType = VehicleType.UnitLoad): BasicMenuItem[] {
    return this.createMenuItems(this.onAddWayPoint, this.onDeleteWayPoint, vehicleType);
  }

  createMenuItems(
    addMethod: Function,
    deleteMethod: Function,
    vehicleType: VehicleType
  ): BasicMenuItem[] {
    return [
      this.createMenuItem(
        'shared.vehicles.sendStep.addWaypoint',
        addMethod.bind(this),
        vehicleType === VehicleType.UnitLoad
      ),
      this.createMenuItem('shared.treeTable.actionMenu.delete', deleteMethod.bind(this), true),
    ];
  }

  createMenuItem(key: string, command: Function, visible: boolean): BasicMenuItem {
    return {
      label: this.translate.get(key),
      key,
      command,
      visible,
      disabled: false,
    };
  }

  setStepTypeAttributeAndFromValidation(stepType: StepType, step: UntypedFormGroup): void {
    const requiredField = getRequiredFieldsByStepType(stepType);
    this.setOrClearValidators(step, requiredField);
  }

  setOrClearValidators(step: UntypedFormGroup, required: string): void {
    for (const key of Object.keys(step.controls)) {
      if (required === key) {
        step.get(key)?.setValidators(Validators.required);
      } else {
        step.get(key)?.clearValidators();
      }
      step.get(key)?.updateValueAndValidity();
    }
  }

  createVehicleSendStepArray(): VehicleSendStepModel[] {
    const steps: VehicleSendStepModel[] = [];
    for (let index = 0; index < this.stepsFormArray.length; index++) {
      steps.push({
        ...this.stepsFormArray.value[index],
        pointOfInterestName:
          this.stepsFormArray.value[index].pointOfInterestGroup.pointOfInterestName,
        pointOfInterestGroupId:
          this.stepsFormArray.value[index].pointOfInterestGroup.pointOfInterestGroupId ??
          this.stepsFormArray.value[index].pointOfInterestGroup.pointOfInterestId,
        pointOfInterestId: this.stepsFormArray.value[index].pointOfInterestGroup.pointOfInterestId,
        waypoints: this.getFormWaypoints(this.stepsFormArray.value[index].waypoints),
        timeSpan: this.stepsFormArray.value[index].timeSpan,
      });
    }

    return steps;
  }

  createTuggerTrainSendStepArray(): TuggerTrainSendStepInputModel {
    const routeConfigurationId = this.routeConfigurationId.value;
    const steps: VehicleSendNodeStepModel[] = [];
    for (let index = 0; index < this.stepsFormArray.length; index++) {
      steps.push({
        ...this.stepsFormArray.value[index],
        nodeName: this.stepsFormArray.value[index].pointOfInterestGroup.nodeName,
        nodeGroupId: this.stepsFormArray.value[index].pointOfInterestGroup.nodeGroupId,
        nodeId: this.stepsFormArray.value[index].pointOfInterestGroup.nodeId,
      });
    }

    return {
      routeConfigurationId: routeConfigurationId,
      steps: steps,
    };
  }

  getFormWaypoints(waypoints: UntypedFormArray[]): GuidString[] {
    const list: GuidString[] = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < waypoints.length; index++) {
      const waypointGroup = waypoints[index];
      // eslint-disable-next-line @typescript-eslint/dot-notation
      list.push(waypointGroup['waypoint']);
    }

    return list;
  }
}
