import { isEmpty } from 'lodash';

export interface RouteTab {
  displayKey: string;
  path: string;
  dataKey: string;
  badgeValue?: string;
  tabPath?: string;
}

export interface Tab {
  key: string;
  heading: string;
  subHeading?: string;
}

export function isRouteTabs(tabs: RouteTab[] | Tab[]): tabs is RouteTab[] {
  return tabs && !isEmpty(tabs) && 'displayKey' in tabs[0] && 'dataKey' in tabs[0];
}

export function isBasicTabs(tabs: RouteTab[] | Tab[]): tabs is Tab[] {
  return tabs && !isEmpty(tabs) && 'key' in tabs[0] && 'heading' in tabs[0];
}
