<div class="boxcontainer">
  <ng-container *ngFor="let workArea of ipstView | async">
    <ds-box *ngIf="workArea.workingArea" elevation="base">
      <ds-box-header data-cy="IpstWorkAreaSettingName">
        <div>
          <span>{{ workArea.workingArea }}</span>
          <div
            *ngIf="workArea.enabled"
            class="text-regular text-positive ps-3x ipst-switch"
            data-cy="IpstWorkAreaSettingEnabled">
            <ds-icon icon="checkbox_circle" size="sm" class="me-1x"></ds-icon>
            {{ 'settings.ipst.workingAreaSettings.enabled' | translate }}
          </div>
          <div
            *ngIf="!workArea.enabled"
            class="text-regular text-neutral ps-3x ipst-switch"
            data-cy="IpstWorkAreaSettingEnabled">
            <ds-icon icon="close_circle" size="sm" class="me-1x"></ds-icon>
            {{ 'settings.ipst.workingAreaSettings.disabled' | translate }}
          </div>
        </div>
      </ds-box-header>
      <hr />
      <ds-box-content>
        <div class="mb-3x">
          <label for="akz">{{ 'settings.ipst.workingAreaSettings.akz' | translate }}</label>
          <div class="inputValue" id="akz" data-cy="IpstWorkAreaSettingAKZ">
            {{ workArea.akz | notApplicable }}
          </div>
        </div>

        <div>
          <label ds-label class="mb-1x">{{
            'settings.ipst.workingAreaSettings.recipientKeys' | translate
          }}</label>
          <hr />
          <div *ngFor="let key of workArea.recipientKeys">
            <div class="recipientkey" data-cy="IpstWorkAreaSettingRecipientKey">
              {{ key }}
              <ds-tag
                tone="info"
                *ngIf="key === workArea.defaultRecipientKey"
                data-cy="IpstWorkAreaSettingRecipientKeyDefault"
                >{{ 'settings.ipst.workingAreaSettings.default' | translate }}</ds-tag
              >
            </div>

            <hr />
          </div>
        </div>
      </ds-box-content>
      <ds-box-footer
        *ngIf="canResolveMessages && workArea.enabled"
        class="d-flex justify-content-end">
        <span data-cy="resolveMessages" class="center-text info-text">
          <i
            class="pi pi-info-circle"
            ds-tooltip="{{ workArea.lastMessageResolve }}"
            [dsTooltipConfig]="{ width: '140px' }"></i>
        </span>

        <button
          ds-button
          class="resolveIpstMessagesButton"
          variant="outline"
          data-cy="resolveIpstMessagesButton"
          ds-tooltip="{{ 'settings.ipst.workingAreaSettings.resolveAllWarning' | translate }}"
          [dsTooltipConfig]="{ width: '150px' }"
          (click)="onResolveAllMessages(workArea.workingAreaId)">
          {{ 'settings.ipst.workingAreaSettings.resolveIpstMessages' | translate }}
        </button>
      </ds-box-footer>
    </ds-box>
  </ng-container>
</div>
