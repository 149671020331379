import '@pixi/graphics-extras';
import { IntersectionCollisionPoint, VehicleDto } from 'core/dtos';
import { MapItemType, TrafficManagementFilter, Vector2D } from 'core/models';
import { CollisionPointColors } from 'library/styles';
import { Container, Graphics } from 'pixi.js';
import { calculateMidpoint, calculateOrientationFromPoints } from 'shared/helpers';
import { MapItemBase, MapItemContainer } from '../map-item-container';
import { VehiclePathStyle } from '../vehicles/vehicle-layer.constant';

const IntersectionCollisionPointStyle = {
  color: CollisionPointColors.Intersection,
  sizeSmall: 10,
  alpha: 0.4,
  scale: 0.025,
  lineSize: 0.05,
};

export class IntersectionCollisionMapItem implements MapItemBase {
  readonly container: MapItemContainer;
  private readonly graphic: Graphics;
  private readonly arrowGraphic: Graphics;

  constructor(
    view: Container,
    intersectionCollisionPoint: IntersectionCollisionPoint,
    blockedAccessVehicle: VehicleDto | undefined,
    prioritizedAccessVehicle: VehicleDto | undefined,
    private filters?: TrafficManagementFilter
  ) {
    this.container = view.addChild(
      new MapItemContainer(
        intersectionCollisionPoint.id.toString(),
        MapItemType.IntersectionCollision
      )
    );

    this.container.scale.set(
      IntersectionCollisionPointStyle.scale,
      -IntersectionCollisionPointStyle.scale
    );
    this.graphic = this.createIntersectionCollisionPointGraphic();
    this.container.addChild(this.graphic);

    this.arrowGraphic = new Graphics();
    view.addChild(this.arrowGraphic);

    this.container.interactive = true;
    this.container.buttonMode = true;

    this.update(intersectionCollisionPoint, blockedAccessVehicle, prioritizedAccessVehicle);
  }

  private createIntersectionCollisionPointGraphic(): Graphics {
    return new Graphics()
      .beginFill(IntersectionCollisionPointStyle.color, IntersectionCollisionPointStyle.alpha)
      .beginFill(IntersectionCollisionPointStyle.color)
      .drawCircle(0, 0, IntersectionCollisionPointStyle.sizeSmall)
      .endFill();
  }

  private createArrow(
    blockedAccessVehiclePose: Vector2D,
    prioritizedAccessVehiclePose: Vector2D
  ): void {
    if (!this.arrowGraphic.visible) {
      return;
    }

    this.arrowGraphic
      .clear()
      .moveTo(prioritizedAccessVehiclePose.x, prioritizedAccessVehiclePose.y)
      .lineTo(blockedAccessVehiclePose.x, blockedAccessVehiclePose.y)
      .lineStyle(IntersectionCollisionPointStyle.lineSize, IntersectionCollisionPointStyle.color)
      .lineTo(prioritizedAccessVehiclePose.x, prioritizedAccessVehiclePose.y);

    this.addPathArrow(
      this.arrowGraphic,
      calculateMidpoint(prioritizedAccessVehiclePose, blockedAccessVehiclePose),
      calculateOrientationFromPoints(prioritizedAccessVehiclePose, blockedAccessVehiclePose),
      IntersectionCollisionPointStyle.color
    );
  }

  private addPathArrow(
    graphic: Graphics,
    { x, y }: Vector2D,
    rotation: number,
    color: number
  ): Graphics {
    if (graphic.drawStar) {
      graphic.lineStyle(0);
      graphic.beginFill(color);
      graphic.drawStar(
        x,
        y,
        VehiclePathStyle.ArrowPoints,
        VehiclePathStyle.ArrowSize,
        0,
        rotation + Math.PI / 2
      );
      graphic.endFill();
    }

    return graphic;
  }

  update(
    intersectionCollisionPoint: IntersectionCollisionPoint,
    blockedAccessVehicle: VehicleDto | undefined,
    prioritizedAccessVehicle: VehicleDto | undefined
  ): void {
    this.container.position.set(
      intersectionCollisionPoint.pose2D.x,
      intersectionCollisionPoint.pose2D.y
    );

    if (blockedAccessVehicle && prioritizedAccessVehicle) {
      this.createArrow(blockedAccessVehicle.pose2D, prioritizedAccessVehicle.pose2D);
    }

    this.setVisibility(this.filters);
  }

  remove(): void {
    this.container.removeChildren();
    this.container.parent.removeChild(this.container);
  }

  setVisibility(filter?: TrafficManagementFilter): void {
    if (!filter) {
      return;
    }
    this.filters = filter;
    this.graphic.visible = filter.intersectionCollisionPoint;
    this.arrowGraphic.visible = filter.dependencies;
  }

  toggleSelection(_isSelected: boolean): void {}
}
