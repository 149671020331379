import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CollisionPoint } from 'core/dtos';
import { GuidString } from 'core/models';

import * as CollisionsActions from '../actions/collision-points.actions';

export const featureKey = 'collisions';

export interface CollisionsState extends EntityState<CollisionPoint> {
  loaded: boolean;
  errorMessage: string;
  selectedCollisionId: GuidString;
}

export const collisionAdapter: EntityAdapter<CollisionPoint> =
  createEntityAdapter<CollisionPoint>();

export const initialState: CollisionsState = collisionAdapter.getInitialState({
  loaded: false,
  errorMessage: '',
  selectedCollisionId: '',
});

const collisionsReducer = createReducer(
  initialState,

  on(CollisionsActions.loadCollisionPoints, state => ({
    ...state,
    loaded: false,
    errorMessage: '',
  })),

  on(CollisionsActions.loadCollisionPointsSuccess, (state, { collisionPoints }) =>
    collisionAdapter.setAll(collisionPoints, {
      ...state,
      loaded: true,
    })
  ),

  on(CollisionsActions.loadCollisionPointsFailure, (state, { errorMessage }) => ({
    ...state,
    loaded: false,
    errorMessage,
  })),

  on(CollisionsActions.signalRCreateCollisionPoint, (state, { collisionPoint }) =>
    collisionAdapter.addOne(collisionPoint, state)
  ),

  on(CollisionsActions.signalRUpdateCollisionPoint, (state, { collisionPoint }) =>
    collisionAdapter.updateOne({ id: collisionPoint.id.toString(), changes: collisionPoint }, state)
  ),

  on(CollisionsActions.signalRDeleteCollisionPoint, (state, { collisionPoint }) =>
    collisionAdapter.removeOne(collisionPoint.id.toString(), state)
  ),

  on(CollisionsActions.signalRUpdateCollisionPointList, (state, { collisionPointList }) => {
    const collState = collisionAdapter.removeMany(
      getAllCollisions(state)
        .filter(c => !collisionPointList.includes(c) && c.mapId === collisionPointList[0].mapId)
        .map(col => col.id.toString()),
      state
    );
    return collisionAdapter.addMany(
      collisionPointList.filter(c => !getAllCollisions(collState).includes(c)),
      collState
    );
  })
);

export function reducer(state: CollisionsState | undefined, action: Action): CollisionsState {
  return collisionsReducer(state, action);
}

export const { selectEntities, selectAll } = collisionAdapter.getSelectors();

export const getCollisionsLoaded = (state: CollisionsState): boolean => state.loaded;
export const getCollisionErrorMessage = (state: CollisionsState): string => state.errorMessage;
export const getCollisionEntities = selectEntities;
export const getAllCollisions = selectAll;
