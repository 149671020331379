import { Coordinate } from 'core/dtos';
import { Line, LineEquation, Pose2D, Vector2D } from 'core/models';
import { Point } from 'pixi.js';
import { GeometryConverter } from './geometry-converters.helper';

// NOTE: HELPERS FOR CALCULATING GEOMETRIC SHAPES OR GEOMETRIC CHECKS ONLY

export class GeometryHelper {
  static calculateOffsetFromOrigin(
    rad: number,
    height: number,
    length: number,
    origin: number
  ): number {
    return calculateOffsetFromOrigin(rad, height, length, origin);
  }

  static calculateOrientationFromPoints(a: Vector2D, b: Vector2D): number {
    return calculateOrientationFromPoints(a, b);
  }

  static calculateDistance(a: Vector2D, b: Vector2D): number {
    return calculateDistance(a, b);
  }

  static mapXYArrayToVector(shape: number[]): Vector2D[] {
    const list: Vector2D[] = [];

    for (let i = 0; i < shape.length - 1; i += 2) {
      const x = shape[i];
      const y = shape[i + 1];

      list.push({ x, y });
    }

    return list;
  }

  static pointInPolygon(polygon: Vector2D[], point: Vector2D): boolean {
    return pointInPolygon(polygon, point);
  }
}

// #region Distances
export function calculateDistance(a: Vector2D, b: Vector2D): number {
  return Math.hypot(a.x - b.x, a.y - b.y);
}

export function distancePointToLine(point: Vector2D, line: Line): number {
  return (
    Math.abs(
      (line.last.y - line.first.y) * point.x -
        (line.last.x - line.first.x) * point.y +
        line.last.x * line.first.y -
        line.last.y * line.first.x
    ) / Math.sqrt(Math.pow(line.last.y - line.first.y, 2) + Math.pow(line.last.x - line.first.x, 2))
  );
}

// #endregion

// #region Calculate a Point
export function getPointFromDistance(a: Vector2D, b: Vector2D, distance: number): Vector2D {
  const rotation = calculateOrientationFromPoints(a, b);

  return getPointFromDistanceByPose({ ...a, orientation: rotation }, distance);
}

export function getPointFromDistanceByPose(pose: Pose2D, distance: number): Vector2D {
  return {
    x: Math.cos(pose.orientation) * distance + pose.x,
    y: Math.sin(pose.orientation) * distance + pose.y,
  };
}

export function closestPointOnEdge(point: Vector2D, line: Line): Vector2D {
  const eq = getLineEquation(line);

  const quotient = Math.pow(eq.a, 2) + Math.pow(eq.b, 2);
  const x = (eq.b * (eq.b * point.x - eq.a * point.y) - eq.a * eq.c) / quotient;
  const y = (eq.a * (-eq.b * point.x + eq.a * point.y) - eq.b * eq.c) / quotient;

  return { x, y };
}

export function calculateMidpoint(a: Vector2D, b: Vector2D): Vector2D {
  const x = (a.x + b.x) / 2;
  const y = (a.y + b.y) / 2;
  return { x, y };
}

export function calculatePointOnLine(a: Vector2D, b: Vector2D, length: number): Vector2D {
  const distance = calculateDistance(a, b);
  const diff_X = b.x - a.x;
  const diff_Y = b.y - a.y;
  const percentage = length / distance;

  return {
    x: a.x + percentage * diff_X,
    y: a.y + percentage * diff_Y,
  };
}

export function difference(a: Vector2D, b: Vector2D): Vector2D {
  return { x: b.x - a.x, y: b.y - a.y };
}

export function calculateOffsetFromCenter(
  rad: number,
  height: number, // Y
  length: number // X
): number {
  return (Math.abs(Math.cos(rad) * height) + Math.abs(Math.sin(rad) * length)) / 2;
}

function calculateOffsetFromOrigin(
  rad: number,
  height: number, // Y
  length: number, // X
  origin: number
): number {
  const d = length - origin;

  const s = Math.sin(rad);
  const calcY = Math.abs(Math.cos(rad) * height) / 2;
  const calcOx = s * origin;
  const calcDx = Math.abs(s) * d;

  const calcX = s > 0 ? calcOx : calcDx;
  return Math.abs(calcX + calcY);
}

// #endregion

// #region Calculate Lines
export function getEdgesFromPoints(points: Point[]): Line[] {
  const list = points.map(p => {
    const vector: Vector2D = {
      x: p.x,
      y: p.y,
    };

    return vector;
  });

  return list.reduce<Line[]>(
    (accumulator, last, index) => [
      ...accumulator,
      { first: list[(index === 0 ? list.length : index) - 1], last },
    ],
    []
  );
}

export function absAngleOfLine(edge: Line): number {
  const rad = calculateOrientationFromPoints(edge.first, edge.last);
  return (rad + Math.PI) % Math.PI;
}
//#endregion

// #region Angle & Orientation

// Calculates the radian value from base line between two points
export function calculateOrientationFromPoints(a: Vector2D, b: Vector2D): number {
  return Math.atan2(b.y - a.y, b.x - a.x);
}

// Calculates the degree value between two vectors given by three points where point B is shared. So the angle is between vector AB and BC
export function calculateAngleBetweenTwoVectorsWithSharedPoint(
  pointA: Coordinate,
  pointB: Pose2D,
  pointC: Coordinate
): number {
  const vectorAB = { x: pointB.x - pointA.x, y: pointB.y - pointA.y };
  const vectorBC = { x: pointC.x - pointB.x, y: pointC.y - pointB.y };
  const magnitudeAB = Math.sqrt(vectorAB.x ** 2 + vectorAB.y ** 2);
  const magnitudeBC = Math.sqrt(vectorBC.x ** 2 + vectorBC.y ** 2);
  const radians = Math.acos(dotProduct(vectorAB, vectorBC) / (magnitudeAB * magnitudeBC));
  return GeometryConverter.radiansToDegrees(radians);
}

// #endregion

// #region Geometric validation
export function pointBelongsToLine(point: Vector2D, line: Line): boolean {
  const eq = getLineEquation(line);
  return eq.a * point.x + eq.b * point.y + eq.c === 0;
}

export function isBetweenTwoPointsOfTheEdge(edge: Line, point: Vector2D): boolean {
  const dx = edge.last.x - edge.first.x;
  const dy = edge.last.y - edge.first.y;

  if (Math.abs(dx) >= Math.abs(dy)) {
    return dx > 0
      ? edge.first.x <= point.x && point.x <= edge.last.x
      : edge.last.x <= point.x && point.x <= edge.first.x;
  }
  return dy > 0
    ? edge.first.y <= point.y && point.y <= edge.last.y
    : edge.last.y <= point.y && point.y <= edge.first.y;
}

export function checkEdgeCollision(edgesA: Line[], edgesB: Line[]): boolean {
  for (const edge1 of edgesA) {
    for (const edge2 of edgesB) {
      if (getIntersectionPoint(edge1, edge2)) {
        return true;
      }
    }
  }

  return false;
}

export function getIntersectionPoint(edge: Line, compareEdge: Line): Point | null {
  const a = edge.first;
  const b = edge.last;
  const e = compareEdge.first;
  const f = compareEdge.last;

  const a1 = b.y - a.y;
  const a2 = f.y - e.y;
  const b1 = a.x - b.x;
  const b2 = e.x - f.x;
  const c1 = b.x * a.y - a.x * b.y;
  const c2 = f.x * e.y - e.x * f.y;
  const denom = a1 * b2 - a2 * b1;

  if (denom === 0) {
    return null;
  }

  const point = new Point((b1 * c2 - b2 * c1) / denom, (a2 * c1 - a1 * c2) / denom);

  const uc = (f.y - e.y) * (b.x - a.x) - (f.x - e.x) * (b.y - a.y);
  const ua = ((f.x - e.x) * (a.y - e.y) - (f.y - e.y) * (a.x - e.x)) / uc;
  const ub = ((b.x - a.x) * (a.y - e.y) - (b.y - a.y) * (a.x - e.x)) / uc;

  if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
    return point;
  } else {
    return null;
  }
}

/* https://github.com/HarryStevens/geometric */
export function pointInPolygon(polygon: Vector2D[], point: Vector2D): boolean {
  const x = point.x;
  const y = point.y;
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].x,
      yi = polygon[i].y,
      xj = polygon[j].x,
      yj = polygon[j].y;

    if (yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi) {
      inside = !inside;
    }
  }

  return inside;
}
// #endregion

// #region Helper Methods
function getLineEquation(line: Line): LineEquation {
  const a = line.last.y - line.first.y;
  const b = line.first.x - line.last.x;

  return {
    a: a,
    b: b,
    c: -(a * line.first.x + b * line.first.y),
  };
}

export function dotProduct(a: Vector2D, b: Vector2D): number {
  return a.x * b.x + a.y * b.y;
}

// #endregion
