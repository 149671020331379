import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IpstSettingsService } from 'core/api-services';
import {
  AtsActions,
  GuidString,
  IpstWorkingAreaSetting,
  IpstWorkingAreaSettingViewModel,
} from 'core/models';
import { AtsTranslationService, PermissionService } from 'core/services';
import { Observable, Subject, firstValueFrom, map, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ipst-groups-view',
  templateUrl: './ipst-groups-view.component.html',
  styleUrls: ['./ipst-groups-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstGroupsViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() viewModel: IpstWorkingAreaSetting[] = [];
  @Output() readonly resolveAllIpstMessages = new EventEmitter<GuidString>();
  @Input() lastIpstMessageResolved?: boolean;
  lastIpstMessageResolvedText$: Observable<string> = of();
  ngUnsubscribe = new Subject<void>();
  canResolveMessages: boolean;
  minDate: Date = new Date('2000-01-01T00:00:00.000Z');
  ipstView: Observable<IpstWorkingAreaSettingViewModel[]> = of(this.viewModel);

  constructor(
    private readonly permissionService: PermissionService,
    private readonly ipstService: IpstSettingsService,
    private readonly atsTranslateService: AtsTranslationService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.canResolveMessages = this.permissionService.actionAllowed(
      AtsActions.ResolveAllIpstMessages
    );
  }
  ngOnChanges({ viewModel, lastIpstMessageResolved }: TypedChanges<IpstGroupsViewComponent>): void {
    if (
      viewModel?.currentValue !== viewModel?.previousValue ||
      lastIpstMessageResolved?.currentValue !== lastIpstMessageResolved?.previousValue
    ) {
      this.ipstView = of(this.viewModel);
      this.setLastIpstMessageResolveTooltip();
      this.cdRef.markForCheck();
    }
  }
  ngOnInit(): void {
    this.ipstView = of(this.viewModel);
    this.setLastIpstMessageResolveTooltip();
    this.cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setLastIpstMessageResolveTooltip(): void {
    this.ipstView.pipe(takeUntil(this.ngUnsubscribe)).subscribe(ipst => {
      ipst.forEach(async x => {
        const response = await firstValueFrom(
          this.ipstService.getLastResolveIpstMessage(x.workingAreaId)
        );
        const lastResolvedTime = new Date(response);
        x.lastMessageResolve =
          lastResolvedTime > this.minDate
            ? await firstValueFrom(
                this.atsTranslateService.currentLanguage$.pipe(
                  map(() =>
                    this.atsTranslateService.get('settings.ipst.workingAreaSettings.lastResolved', {
                      time: lastResolvedTime.toLocaleTimeString('de', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      }),
                    })
                  )
                )
              )
            : await firstValueFrom(
                this.atsTranslateService.currentLanguage$.pipe(
                  map(() =>
                    this.atsTranslateService.get(
                      'settings.ipst.workingAreaSettings.lastResolvedUnknown'
                    )
                  )
                )
              );
        this.cdRef.markForCheck();
      });
    });
  }

  onResolveAllMessages(workAreaId: GuidString): void {
    this.resolveAllIpstMessages.emit(workAreaId);
  }
}
