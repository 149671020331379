import { createSelector } from '@ngrx/store';
import { ProcessChainTriggerType, ProcessChainType } from 'core/models';
import {
  selectAllMaps,
  selectMapId,
  selectMapsEntities,
} from 'store-modules/maps-store/selectors/maps.selectors';

import * as fromVehiclesStore from 'store-modules/vehicles-store/selectors/fleets.selectors';
import * as fromPoiStore from '../../pois-store/selectors/pois.selectors';
import * as fromProcessChainGroupsStore from './process-chain-group.selectors';

import {
  selectAllProcessChains,
  selectProcessChainEntities,
  selectSelectedProcessChain,
} from './process-chain.selectors';
import {
  convertToProcessChainDetails,
  convertToTableProcessChain,
  convertToTreeOverviewProcessChain,
} from './process-chain.selectors.helpers';

export const selectAllTableProcessChains = createSelector(
  selectAllProcessChains,
  selectMapsEntities,
  fromVehiclesStore.selectFleetEntities,
  (processChains, mapEntities, fleetEntities) => {
    return processChains.map(processChain =>
      convertToTableProcessChain(processChain, fleetEntities, mapEntities)
    );
  }
);

export const selectTableProcessChainsByMapId = createSelector(
  selectAllTableProcessChains,
  selectMapId,
  (processChains, mapId) => {
    return processChains.filter(
      pc => pc.type === ProcessChainType.Basic && (mapId ? pc.mapId === mapId : true)
    );
  }
);

export const selectTableTransportOrderProcessChainsByMapId = createSelector(
  selectAllTableProcessChains,
  selectMapId,
  (processChains, mapId) => {
    return processChains.filter(
      pc =>
        pc.type === ProcessChainType.Basic &&
        (pc.triggerType === ProcessChainTriggerType.TransportOrder ||
          pc.triggerType === ProcessChainTriggerType.Device) &&
        (mapId ? pc.mapId === mapId : true)
    );
  }
);

export const selectTableWaitingQueuesByMapId = createSelector(
  selectAllTableProcessChains,
  selectMapId,
  (processChains, mapId) => {
    return processChains.filter(
      pc => pc.type === ProcessChainType.WaitingQueue && (mapId ? pc.mapId === mapId : true)
    );
  }
);

export const selectProcessChainsForTreeOverview = createSelector(
  selectAllTableProcessChains,
  selectAllMaps,
  (tableProcessChains, maps) => {
    return convertToTreeOverviewProcessChain(tableProcessChains, maps).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }
);

export const selectSelectedProcessChainDetails = createSelector(
  selectSelectedProcessChain,
  selectMapsEntities,
  fromVehiclesStore.selectFleetEntities,
  fromPoiStore.selectPoiEntities,
  fromProcessChainGroupsStore.selectProcessChainGroupEntities,
  selectProcessChainEntities,
  (processChain, mapEntities, fleetEntities, poiEntities, processChainGroupEntities) => {
    return processChain
      ? convertToProcessChainDetails(
          processChain,
          mapEntities,
          fleetEntities,
          poiEntities,
          processChainGroupEntities
        )
      : undefined;
  }
);
