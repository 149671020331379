import { de, enGB } from 'date-fns/locale';

export const ONE_MINUTE = 1;
export const SIXTY_MINUTES = 60;
export const THIRTY_DAYS = 30;
export const TWELVE_MONTHS = 12;
export const DEFAULT_DATE_FORMAT = 'fullDate.short';

export const LOCALE = {
  de: de,
  en: enGB,
};
