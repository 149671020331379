<div class="toolbarTopMargin">
  <app-base-tool-bar styleClass="pb-0x" data-cy="tableToolbar">
    <div class="p-toolbar-group-left"></div>

    <div class="p-toolbar-group-right toolbarGroupRight">
      <ng-container *ngFor="let item of actionItems; let first = first">
        <ng-container *ngIf="item.visible !== false">
          <div [class.ps-2x]="!first" data-cy="toolbar-secondary-button">
            <button
              ds-button
              [variant]="'outline'"
              (click)="onToolbarSecondaryButtonClick(item.command)"
              [disabled]="item.disabled">
              {{ item.key | translate }}
            </button>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isTableConfigurationModeActive; else editModeNotActive">
        <button
          *ngIf="isResetButtonVisible"
          ds-button
          [variant]="'ghost'"
          data-cy="toolbarColumnReset"
          [icon]="'arrow_go_back'"
          class="ms-4x"
          ds-tooltip="{{ 'shared.tableColumnActions.reset' | translate }}"
          (click)="resetReorderedColumns($event)"></button>

        <button
          data-cy="toolbarColumnSave"
          ds-button
          [variant]="'ghost'"
          [icon]="'save'"
          ds-tooltip="{{ 'shared.tableColumnActions.save' | translate }}"
          (click)="saveReorderedColumns($event)"></button>

        <button
          data-cy="toolbarColumnCancel"
          ds-button
          [variant]="'ghost'"
          [icon]="'close'"
          ds-tooltip="{{ 'shared.tableColumnActions.cancel' | translate }}"
          (click)="editReorderedColumns($event)"></button>
      </ng-container>

      <ng-template #editModeNotActive>
        <button
          *ngIf="isFilterVisible"
          data-cy="toolbarTableFilter"
          ds-button
          class="filter-button"
          [variant]="isFilterItemActive ? 'primary' : 'ghost'"
          icon="filter"
          [disclosureIcon]="'more_vertical'"
          [ds-menu-trigger-for]="dsMenu"></button>
        <ds-menu #dsMenu>
          <ng-container *ngTemplateOutlet="filterTemplate; context: {}"></ng-container>
        </ds-menu>
        <button
          data-cy="toolbarColumnEdit"
          ds-button
          [variant]="'ghost'"
          [icon]="'settings'"
          ds-tooltip="{{ 'shared.tableColumnActions.edit' | translate }}"
          (click)="editReorderedColumns($event)"></button>
      </ng-template>
    </div>
  </app-base-tool-bar>
</div>

<app-table
  [displayedColumns]="displayedColumns"
  [searchTerm]="searchTerm"
  [records]="records"
  [totalNumberOfRecords]="totalNumberOfRecords"
  [hasPaginator]="hasPaginator"
  [first]="first"
  [rows]="rows"
  [isLazy]="isLazy"
  [loading]="loading"
  [translationContext]="translationContext"
  [menuItems]="menuItems"
  [itemTemplate]="itemTemplate"
  [rowHighlight]="rowHighlight"
  [isSortable]="isSortable"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  [isTableConfigurationModeActive]="isTableConfigurationModeActive"
  [reorderableColumns]="isTableConfigurationModeActive"
  (paginate)="onPaginate($event)"
  (navigateTo)="onNavigateTo($event)"
  (setSelectedRow)="onSetSelectedRow($event)"
  (rowClick)="onRowClick($event)"
  (textFieldChange)="onTextFieldChange($event)"
  (saveTableSort)="onSaveTableSort($event)"
  (lazyLoad)="onLazyLoad($event)"
  (colReordered)="onColReordered()">
</app-table>
