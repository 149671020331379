<ds-form-field>
  <input
    ds-input
    type="number"
    data-cy="timeInSeconds"
    placeholder="{{ 'shared.vehicles.sendStep.timeInSeconds' | translate }}"
    [(ngModel)]="timeSpan"
    [min]="0"
    required
    (ngModelChange)="onInputNumberChange()" />
</ds-form-field>
