import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { Store, select } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LevelType, RoleAction, UserRoleModelWithChildren } from 'core/models';
import { Subject, takeUntil } from 'rxjs';
import * as fromRoot from 'store/index';
@Component({
  selector: 'app-organization-chart-table-cell',
  templateUrl: './organization-chart-table-cell.component.html',
  styleUrls: ['./organization-chart-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationChartTableCellComponent
  implements ICellRendererAngularComp, OnDestroy, OnChanges, OnInit
{
  @Output() readonly selectedRole = new EventEmitter<string | string[] | null>();
  params?: ICellRendererParams<UserRoleModelWithChildren>;
  roleDto?: UserRoleModelWithChildren;
  editEnabled?: boolean = false;
  list: UserRoleModelWithChildren[] = [];
  optionList: SelectListOption[] = [];

  data: UserRoleModelWithChildren | undefined = {
    level: '',
    roleName: '',
    roleId: -1,
    reasonForAccess: '',
    hierarchy: [],
    editable: false,
    creatable: false,
    deletable: false,
    isDirty: false,
  };

  isEditMode = false;
  calculatedEditMode = false;
  canEdit?: boolean;
  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {}

  get roleId(): number | undefined {
    if (this.data) {
      return this.data.roleId;
    }

    return undefined;
  }

  set roleId(value: number | undefined) {
    if (!this.data) {
      this.data = {
        level: '',
        roleName: '',
        roleId: -1,
        reasonForAccess: '',
        hierarchy: [],
        editable: false,
        creatable: false,
        deletable: false,
        isDirty: false,
      };
    }
    this.data.roleId = value;
  }

  get roleName(): string {
    if (this.data) {
      return this.data.roleName;
    }

    return '';
  }

  set roleName(value: string) {
    if (!this.data) {
      this.data = {
        level: '',
        roleName: '',
        roleId: -1,
        reasonForAccess: '',
        hierarchy: [],
        editable: false,
        creatable: false,
        deletable: false,
        isDirty: false,
      };
    }
    this.data.roleName = value;
  }

  ngOnInit(): void {
    this.rootStore
      .pipe(select(fromRoot.selectIsEditMode), takeUntil(this.ngUnsubscribe))
      .subscribe(isEditMode => {
        this.isEditMode = isEditMode;
        this.setEditMode();
      });
  }

  agInit(params: ICellRendererParams<UserRoleModelWithChildren>): void {
    this.params = params;
    this.canEdit = this.params.context.componentParent.canEdit;
    this.optionList = this.params.context.componentParent.getDropdownOptions(params.data);
    this.list = this.params.context.componentParent.userAccessList;
    this.data = this.params.data;
    this.refresh();
  }

  refresh(): boolean {
    this.editEnabled = this.canEdit;
    this.cdRef.markForCheck();
    return true;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.setEditMode();
  }

  onChange(selectedRole: string | string[] | null): void {
    let obj = undefined;
    if (this.data?.levelType === LevelType.Organization) {
      obj = this.list?.find(o => o.orgId === this.data?.orgId);
    } else if (this.data?.levelType === LevelType.WorkingArea && this.list?.length) {
      obj = this.list.find((w: UserRoleModelWithChildren) => w.waId === this.data?.waId);
    } else if (this.data?.levelType === LevelType.Environment) {
      obj = this.list?.find(o => o.envId === this.data?.envId);
    }
    this.setDirty(obj, this.data!);
    if (obj) {
      if (typeof selectedRole === 'number') {
        obj.roleAction = RoleAction.CreateOrUpdate;
      } else {
        obj.roleAction = RoleAction.Delete;
      }
    }
    this.selectedRole.emit(selectedRole);
    this.cdRef.markForCheck();
  }

  setDirty(obj: UserRoleModelWithChildren | undefined, data: UserRoleModelWithChildren): void {
    if (obj) {
      obj.roleId = data.roleId;
      obj.isDirty = true;
    }
  }

  setEditMode(): void {
    if (this.data?.roleId && this.data.editable !== undefined) {
      this.calculatedEditMode = this.isEditMode && this.data.editable;
    } else if (!this.data?.roleId && this.data?.creatable !== undefined) {
      this.calculatedEditMode = this.isEditMode && this.data.creatable;
    } else {
      this.calculatedEditMode = this.isEditMode;
    }
    this.cdRef.markForCheck();
  }

  getRevokeMode(): boolean {
    if (this.data?.deletable !== undefined) return this.isEditMode && this.data.deletable;
    else return this.isEditMode;
  }
}
