import { AddOptions, Ease } from 'pixi-ease';
import { Circle } from 'pixi.js';
import { GraphicsEx } from '../pixi';

export interface PulseStyle {
  color: number;
  alpha: number;
  size: number;
  animation?: PulseAnimation;
}

interface PulseAnimation extends AddOptions {
  reverse: boolean;
  repeat: boolean;
  duration: number;
  scale: number;
}

export class MapPulseGraphic {
  private errorPulseBackground: GraphicsEx | undefined;
  private ease: Ease | undefined;
  private readonly defaultAnimation: PulseAnimation = {
    reverse: true,
    repeat: true,
    duration: 500,
    scale: 2,
  };

  constructor(private readonly options: PulseStyle) {}

  get isRunning(): boolean {
    return this.errorPulseBackground !== undefined;
  }

  get animation(): PulseAnimation {
    return this.options.animation ?? this.defaultAnimation;
  }

  start(): GraphicsEx {
    if (this.errorPulseBackground) return this.errorPulseBackground;

    const graphic = new GraphicsEx()
      .beginFill(this.options.color, this.options.alpha)
      .drawCircle(0, 0, this.options.size);

    graphic.visible = false;
    graphic.hitArea = new Circle(0, 0, 0);
    graphic.visible = true;

    this.ease = new Ease({});
    this.ease.add(graphic, { scale: this.animation.scale }, this.animation);

    this.errorPulseBackground = graphic;
    return graphic;
  }

  stop(): void {
    if (!this.isRunning) return;

    if (this.ease) {
      this.ease?.destroy();
      this.ease = undefined;
    }

    if (this.errorPulseBackground) {
      this.errorPulseBackground.remove();
      this.errorPulseBackground = undefined;
    }
  }
}
