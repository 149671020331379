<div class="divInline">
  <button
    data-cy="editBarDeleteButton"
    *ngIf="!isNewObject && canDelete"
    ds-button
    [variant]="'outline'"
    class="ms-2x"
    (click)="delete()">
    {{ 'shared.editBar.delete' | translate }}
  </button>
  <button
    data-cy="editBarCancelButton"
    ds-button
    [variant]="'outline'"
    type="button"
    class="ms-2x"
    (click)="cancel()">
    {{ 'shared.editBar.cancel' | translate }}
  </button>
  <button
    data-cy="editBarSaveButton"
    [disabled]="!isValidForm || !hasChanges"
    ds-button
    class="ms-2x"
    (click)="save()">
    {{ 'shared.editBar.save' | translate }}
  </button>
</div>
