import { EntityList } from '../../core/entity.models';
import { Pose2D } from '../positions.models';

export type MapEntity = MainPoseMapEntity | PoseMapEntity;

export interface MainPoseMapEntity extends EntityList {
  mainPose: Pose2D;
}

export interface PoseMapEntity extends EntityList {
  pose2D: Pose2D;
}

export function isMainPoseEntity(entity: MapEntity): entity is MainPoseMapEntity {
  return 'mainPose' in entity;
}
