export const DATE_FORMAT = {
  TIME: 'HH:mm:ss',
  TIME_HOURS_MINUTES: 'HH:mm',
  DATE: 'dd.MM.yy',
  DATE_DAY_MONTH: 'dd.MM.',
  DATE_DAY_MONTH_YEAR: 'ddMMyy',
  DATE_TIME: 'dd.MM.yy HH:mm:ss',
  DATE_TIME_MINUTES: 'dd.MM.yy HH:mm',
  FULL_DATE_TIME: 'dd.MM.yyyy HH:mm',
  FULL_DATE_TIME_SECONDS: 'dd.MM.yyyy HH:mm:ss',
};
