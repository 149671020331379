import { createAction, props } from '@ngrx/store';
import { MapDto, PoiGroupDto } from 'core/dtos';
import { GuidString } from 'core/models';

export enum PoiGroupsTypes {
  LoadPoiGroups = '[PoiGroups] Load PoiGroups',
  LoadPoiGroupsForMap = '[PoiGroups] Load PoiGroups For Map',
  LoadPoiGroupsSuccess = '[PoiGroups] Load PoiGroups Success',
  LoadPoiGroupsFailure = '[PoiGroups] Load PoiGroups Failure',
  LoadMapsForPoiGroups = '[PoiGroups] Load Maps for PoiGroups',

  AddPoiGroup = '[PoiGroups] Add PoiGroup',
  AddPoiGroupSuccess = '[PoiGroups] Add PoiGroup Success',
  AddPoiGroupFailure = '[PoiGroups] Add PoiGroup Failure',

  UpdatePoiGroup = '[PoiGroups] Update PoiGroup',
  UpdatePoiGroupSuccess = '[PoiGroups] Update PoiGroup Success',
  UpdatePoiGroupFailure = '[PoiGroups] Update PoiGroup Failure',

  DeletePoiGroup = '[PoiGroups] Delete PoiGroup',
  DeletePoiGroupSuccess = '[PoiGroups] Delete PoiGroup Success',
  DeletePoiGroupFailure = '[PoiGroups] Delete PoiGroup Failure',

  UngroupPoiGroup = '[PoiGroups] Ungroup PoiGroup',
  UngroupPoiGroupSuccess = '[PoiGroups] Ungroup PoiGroup Success',
  UngroupPoiGroupFailure = '[PoiGroups] Ungroup PoiGroup Failure',

  SelectPoiGroup = '[PoiGroups] Select PoiGroup',
}

export enum SignalRPoiGroupTypes {
  SignalRCreatePoiGroup = '[SignalR] Create PoiGroup',
  SignalRUpdatePoiGroup = '[SignalR] Update PoiGroup',
  SignalRDeletePoiGroup = '[SignalR] Delete PoiGroup',
}

export const loadPoiGroups = createAction(PoiGroupsTypes.LoadPoiGroups);
export const loadPoiGroupsForMap = createAction(
  PoiGroupsTypes.LoadPoiGroupsForMap,
  props<{ mapId: GuidString }>()
);
export const loadMapsForPoiGroupsSuccess = createAction(
  PoiGroupsTypes.LoadMapsForPoiGroups,
  props<{ maps: MapDto[] }>()
);

export const loadPoiGroupsSuccess = createAction(
  PoiGroupsTypes.LoadPoiGroupsSuccess,
  props<{ poiGroups: PoiGroupDto[] }>()
);

export const loadPoiGroupsFailure = createAction(
  PoiGroupsTypes.LoadPoiGroupsFailure,
  props<{ errorMessage: string }>()
);

export const addPoiGroup = createAction(
  PoiGroupsTypes.AddPoiGroup,
  props<{ poiGroup: PoiGroupDto }>()
);

export const addPoiGroupSuccess = createAction(
  PoiGroupsTypes.AddPoiGroupSuccess,
  props<{ poiGroup: PoiGroupDto }>()
);

export const addPoiGroupFailure = createAction(
  PoiGroupsTypes.AddPoiGroupFailure,
  props<{ errorMessage: string }>()
);

export const updatePoiGroup = createAction(
  PoiGroupsTypes.UpdatePoiGroup,
  props<{ poiGroup: PoiGroupDto }>()
);

export const updatePoiGroupSuccess = createAction(
  PoiGroupsTypes.UpdatePoiGroupSuccess,
  props<{ poiGroup: PoiGroupDto }>()
);

export const updatePoiGroupFailure = createAction(
  PoiGroupsTypes.UpdatePoiGroupFailure,
  props<{ errorMessage: string }>()
);

export const deletePoiGroup = createAction(
  PoiGroupsTypes.DeletePoiGroup,
  props<{ poiGroup: PoiGroupDto }>()
);

export const deletePoiGroupSuccess = createAction(
  PoiGroupsTypes.DeletePoiGroupSuccess,
  props<{ poiGroup: PoiGroupDto }>()
);

export const deletePoiGroupFailure = createAction(
  PoiGroupsTypes.DeletePoiGroupFailure,
  props<{ errorMessage: string }>()
);

export const ungroupPoiGroup = createAction(
  PoiGroupsTypes.UngroupPoiGroup,
  props<{ poiGroup: PoiGroupDto }>()
);

export const ungroupPoiGroupSuccess = createAction(
  PoiGroupsTypes.UngroupPoiGroupSuccess,
  props<{ poiGroup: PoiGroupDto }>()
);

export const ungroupPoiGroupFailure = createAction(
  PoiGroupsTypes.UngroupPoiGroupFailure,
  props<{ errorMessage: string }>()
);

export const selectPoiGroup = createAction(
  PoiGroupsTypes.SelectPoiGroup,
  props<{ poiGroupId: GuidString }>()
);

export const signalRCreatePoiGroup = createAction(
  SignalRPoiGroupTypes.SignalRCreatePoiGroup,
  props<{ poiGroup: PoiGroupDto }>()
);

export const signalRUpdatePoiGroup = createAction(
  SignalRPoiGroupTypes.SignalRUpdatePoiGroup,
  props<{ poiGroup: PoiGroupDto }>()
);

export const signalRDeletePoiGroup = createAction(
  SignalRPoiGroupTypes.SignalRDeletePoiGroup,
  props<{ poiGroup: PoiGroupDto }>()
);
