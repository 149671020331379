import { VehicleOptionStatus } from 'core/models';

export const ManageMapDataTones = {
  [VehicleOptionStatus.CurrentActiveMap]: 'positive',
  [VehicleOptionStatus.EnabledMap]: 'caution',
  [VehicleOptionStatus.Available]: 'positive',
  [VehicleOptionStatus.Busy]: 'caution',
  [VehicleOptionStatus.Offline]: 'caution',
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: 'info',
  [VehicleOptionStatus.AlreadySentMap]: 'positive',
};

const dsColorYellow100 = '--ds-color-yellow-100';

export const ManageMapDataColors = {
  [VehicleOptionStatus.CurrentActiveMap]: '--ds-color-green-100',
  [VehicleOptionStatus.EnabledMap]: dsColorYellow100,
  [VehicleOptionStatus.Available]: '',
  [VehicleOptionStatus.Busy]: dsColorYellow100,
  [VehicleOptionStatus.Offline]: dsColorYellow100,
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: '--ds-color-blue-100',
  [VehicleOptionStatus.AlreadySentMap]: '--ds-color-green-100',
};
