import { environment } from '@environment';

export const AppSettingsLoader = async (): Promise<void> => {
  if (!environment.fetchConfig) {
    return;
  }

  try {
    const response = await fetch(environment.Services.AppSettings);

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    environment.config = await response.json();

    console.info('App config loaded.');
  } catch (err) {
    if (err instanceof Error) {
      console.error('AppSettingsLoader', err.message);
    }
  }
};
