/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DsSelectLabellingConfiguration } from '@bmw-ds/components/ds-interfaces/select/config.interface';
import { EMPTY_GUID } from 'core/constants';
import { NodeDto, NodeGroupDto } from 'core/dtos';
import { GuidString, StepType } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { flatten } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KeySelectItemGroup } from '../step-attribute/key-select-item-group.model';
import { NodeControl } from '../step-attribute/node-control.model';

@Component({
  selector: 'app-step-attribute-node',
  templateUrl: './step-attribute-node.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StepAttributeNodeComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepAttributeNodeComponent
  implements ControlValueAccessor, OnChanges, OnInit, OnDestroy
{
  @Input() isVisible = false;
  @Input() stepType: StepType = StepType.Unknown;
  @Input() nodeGroups: NodeGroupDto[] = [];
  @Input() allNodes: NodeDto[] = [];

  singleNodes: NodeDto[] = [];
  options: KeySelectItemGroup[] = [];
  selectedId?: GuidString;
  controlValue?: NodeControl;

  singleNodeTranslationKey = 'shared.tourConfig.singleNodes';
  nodeGroupTranslationKey = 'shared.tourConfig.nodeGroups';

  labellingConfig: DsSelectLabellingConfiguration = {
    noResultsMessage: this.translationService.get('placeholders.selectEmpty'),
    placeholder: this.translationService.get('placeholders.select'),
    selectAll: this.translationService.get('placeholders.selectAll'),
  };

  protected readonly ngUnsubscribe = new Subject<void>();

  constructor(private readonly translationService: AtsTranslationService) {}

  ngOnInit(): void {
    this.translationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.prepareSingleNodes();
      this.createOptions();
    });
  }

  ngOnChanges(): void {
    this.prepareSingleNodes();
    this.setOptions();
    this.selectedId = this.getSelectedId();
  }

  prepareSingleNodes(): void {
    let nodeIdsInGroup: GuidString[] = [];
    this.singleNodes = this.allNodes;

    this.nodeGroups.forEach(nodeGroup => {
      nodeIdsInGroup = nodeIdsInGroup.concat(nodeGroup.nodes);
    });

    this.singleNodes = this.singleNodes?.filter(function (node) {
      return !nodeIdsInGroup.includes(node.nodeId);
    });
  }

  setOptions(): void {
    this.createOptions();
  }

  writeValue(controlValue: NodeControl): void {
    this.controlValue = controlValue;
    this.createOptions();
    this.selectedId = this.getSelectedId();
  }

  createOptions(): void {
    this.options = [];

    const singleNodeItems: KeySelectItemGroup = {
      label: this.translationService.get(this.singleNodeTranslationKey),
      key: this.singleNodeTranslationKey,
      id: 'nodeSingle',
      group: true,
      children: this.singleNodes?.map((node: NodeDto) => {
        return {
          label: node.nodeName,
          id: `single-${node.nodeId}`,
          value: {
            nodeGroupId: null,
            nodeName: node.nodeName,
            nodeId: node.nodeId,
          },
        };
      }),
    };

    const nodeGroupItems: KeySelectItemGroup = {
      label: this.translationService.get(this.nodeGroupTranslationKey),
      key: this.nodeGroupTranslationKey,
      id: 'nodeGroup',
      group: true,
      children: this.nodeGroups?.map((nodeGroup: NodeGroupDto) => {
        return {
          label: nodeGroup.name,
          id: `group-${nodeGroup.id}`,
          value: {
            nodeGroupId: nodeGroup.id,
            nodeName: nodeGroup.name,
            nodeId: EMPTY_GUID,
          },
        };
      }),
    };

    if (singleNodeItems.children && singleNodeItems.children.length > 0) {
      this.options.push(singleNodeItems);
    }

    if (nodeGroupItems.children && nodeGroupItems.children.length > 0) {
      this.options.push(nodeGroupItems);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getSelectedId(): GuidString | undefined {
    const singleNodeItems =
      this.options.find(option => option.key === this.singleNodeTranslationKey)?.children ?? [];
    const nodeGroupItems =
      this.options.find(option => option.key === this.nodeGroupTranslationKey)?.children ?? [];

    return singleNodeItems.concat(nodeGroupItems).find(item => {
      if (!this.controlValue?.nodeGroupId) {
        return item.value.nodeId === this.controlValue?.nodeId;
      } else {
        return item.value.nodeGroupId === this.controlValue?.nodeGroupId;
      }
    })?.id;
  }

  onSelectionChange(): void {
    this.controlValue = this.getControlValueById();
    if (this.controlValue) {
      this.onTouched(this.controlValue);
      this.onChanged(this.controlValue);
    }
  }

  getControlValueById(): NodeControl | undefined {
    const selectedItem = flatten(this.options.map(c => c.children)).find(
      d => d?.id === this.selectedId
    );
    if (selectedItem) {
      return selectedItem.value;
    } else {
      return undefined;
    }
  }

  registerOnChange(fn: () => {}): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  onChanged = (_value: NodeControl): void => {};
  onTouched = (_value: NodeControl): void => {};
}
