import { createSelector } from '@ngrx/store';
import * as fromGraphManagerFeatureState from '../reducers';
import * as fromNodeGroupsReducer from '../reducers/node-group.reducer';

const selectGraphManagerState = createSelector(
  fromGraphManagerFeatureState.getGraphManagerFeatureState,
  fromGraphManagerFeatureState.getNodeGroupsState
);

export const selectNodeGroupsLoading = createSelector(
  selectGraphManagerState,
  fromNodeGroupsReducer.getLoading
);

export const selectNodeGroupsLoaded = createSelector(
  selectGraphManagerState,
  fromNodeGroupsReducer.getLoaded
);
export const selectNodeGroupId = createSelector(
  selectGraphManagerState,
  fromNodeGroupsReducer.getSelectedNodeGroupId
);

export const selectAllNodeGroups = createSelector(
  selectGraphManagerState,
  fromNodeGroupsReducer.getNodeGroups
);

export const selectSelectedNodeGroup = createSelector(
  selectNodeGroupId,
  selectAllNodeGroups,
  (nodeGroupId, nodeGroups) => {
    return nodeGroups.find(nodeGroup => nodeGroup.id === nodeGroupId);
  }
);
