import { Pipe, PipeTransform } from '@angular/core';
import { NO_VALUE_DEFAULT } from 'core/constants';
import { AbstractTranslatePipe } from 'library/pipes/abstract-translate.pipe';

@Pipe({
  name: 'enumArrayTranslation',
  pure: false, // required,since the translation is unpure
})
export class EnumArrayTranslationPipe<T extends number | boolean | string>
  extends AbstractTranslatePipe<string>
  implements PipeTransform
{
  transform(value: T[] | undefined, enumName: string): string {
    if (value === undefined || value.length === 0) {
      return NO_VALUE_DEFAULT;
    }

    const enumNames = this.atsTranslationService.getEnum(`enums.${enumName}`);
    return value.map(v => this.translate(v, enumNames)).join(', ');
  }

  private translate(value: T | undefined, enumMap: Record<string | number, string>): string {
    if (value === undefined) {
      return NO_VALUE_DEFAULT;
    }

    return typeof value === 'boolean' ? enumMap[Number(value)] : enumMap[value as number];
  }
}
