import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { HuLookupMapping } from 'core/dtos';

import * as HuLookupMappingActions from '../actions/hu-lookup-mapping.actions';

export const featureKey = 'huLookupMappings';

export interface HuLookupMappingState extends EntityState<HuLookupMapping> {
  loaded: boolean;
  errorMessage: string;
}

export const huLookupMappingAdapter: EntityAdapter<HuLookupMapping> =
  createEntityAdapter<HuLookupMapping>({
    selectId: (model: HuLookupMapping) =>
      `${model.device}_${model.actionType}_${model.sourceBinType}_${model.destinationBinType}_${model.workAreaId}`,
  });

export const initialState: HuLookupMappingState = huLookupMappingAdapter.getInitialState({
  loaded: false,
  errorMessage: '',
});

const huLookupMappingReducer = createReducer(
  initialState,

  on(HuLookupMappingActions.loadHuLookupMappings, state => ({
    ...state,
    errorMessage: '',
    loaded: false,
  })),

  on(HuLookupMappingActions.loadHuLookupMappingsSuccess, (state, { mappings }) =>
    huLookupMappingAdapter.setAll(mappings, {
      ...state,
      loaded: true,
    })
  ),

  on(HuLookupMappingActions.loadHuLookupMappingsFailure, (state, { errorMessage }) => ({
    ...state,
    loaded: false,
    errorMessage,
  }))
);

export function reducer(
  state: HuLookupMappingState | undefined,
  action: Action
): HuLookupMappingState {
  return huLookupMappingReducer(state, action);
}

export const { selectEntities, selectAll } = huLookupMappingAdapter.getSelectors();

export const getHuLookupMappingsLoaded = (state: HuLookupMappingState): boolean => state.loaded;
export const getHuLookupMappingEntities = selectEntities;
export const getAllHuLookupMappings = selectAll;
