import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { NotificationMappingDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class NotificationNodeMappingService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.NotificationMappings;
  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getMappings(): Observable<NotificationMappingDto[]> {
    return this.get<NotificationMappingDto[]>(this.servicePath);
  }

  getMappingsByMap(mapId: GuidString): Observable<NotificationMappingDto[]> {
    return this.get<NotificationMappingDto[]>(`${this.servicePath}/${mapId}`);
  }

  addMapping(mapping: NotificationMappingDto): Observable<NotificationMappingDto> {
    return this.post<NotificationMappingDto>(this.servicePath, mapping);
  }

  updateMapping(mapping: NotificationMappingDto): Observable<NotificationMappingDto> {
    return this.put<NotificationMappingDto>(this.servicePath, mapping);
  }

  deleteMapping(mapping: NotificationMappingDto): Observable<boolean> {
    return this.delete<boolean>(`${this.servicePath}/${mapping.id}`);
  }
}
