import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as OpcuaDevicesEffects from './effects';
import * as fromOpcuaDevices from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(fromOpcuaDevices.featureKey, fromOpcuaDevices.reducers),
    EffectsModule.forFeature(OpcuaDevicesEffects.effects),
  ],
})
export class OpcuaDevicesStoreModule {}
