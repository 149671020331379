import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AtsActions } from 'core/models';
import { OpcuaDevicesGuard } from 'modules/opcua-devices/guards/opcua-devices.guard';
import { DeveloperSettingsGuard } from 'modules/settings/Guards/developer-settings.guard';
import { WorkingAreaDeveloperSettingsComponent } from 'modules/settings/containers/working-area-developer-settings-container/working-area-developer-settings-container.component';
import { WorkingAreaSettingsComponent } from 'modules/settings/containers/working-area-settings/working-area-settings.component';
import { NotFoundDetailsComponent } from 'shared/components';
import { SettingsGuard } from './Guards/settings.guard';
import { AllEnvironmentUsersContainerComponent } from './containers/all-environment-users-container/all-environment-users-container.component';
import { AllOrganizationUsersContainerComponent } from './containers/all-organization-users-container/all-organization-users-container.component';
import { AllWorkingAreaUsersContainerComponent } from './containers/all-working-area-users-container/all-working-area-users-container.component';
import { EnvironmentRolesContainerComponent } from './containers/environment-roles-container/environment-roles-container.component';
import { FunctionsContainerComponent } from './containers/functions-container/functions-container.component';
import { IpstAlertNowSettingsContainerComponent } from './containers/ipst-alertnow-settings-container/ipst-alertnow-settings-container.component';
import { UserRolesAndPermissionsContainerComponent } from './containers/user-roles-and-permissions-container/user-roles-and-permissions-container.component';
import { UserRolesAndPermissionPageComponent } from './page/user-roles-and-permission-page.component';

const functionsPath = 'functions';
const selectedSideMenuItemSettings = 'sideMenu.settings.settings';
const selectedSideMenuItemRoles = 'sideMenu.settings.roles';
const selectedSideMenuItemFunctions = 'sideMenu.settings.functions';

const selectedSideMenuItemSettingsDeveloperSettings = 'sideMenu.settings.developerSettings';
const selectedSideMenuItemSettingsIpstAlertNowSettings = 'sideMenu.settings.ipstAlertNowSettings';

const routes: Routes = [
  {
    path: '',
    component: WorkingAreaSettingsComponent,
    data: {
      breadcrumb: 'breadcrumb.settings.workingAreaSettings',
      selectedSideMenuItem: selectedSideMenuItemSettings,
    },
    children: [
      {
        path: '',
        redirectTo: functionsPath,
        pathMatch: 'full',
        data: { selectedSideMenuItem: selectedSideMenuItemSettings },
      },
      {
        path: 'functions',
        component: FunctionsContainerComponent,
        data: {
          breadcrumb: 'breadcrumb.settings.functions',
          selectedSideMenuItem: selectedSideMenuItemFunctions,
        },
        canActivate: [SettingsGuard, OpcuaDevicesGuard],
      },
      {
        path: 'ipstAlertNowSettings',
        component: IpstAlertNowSettingsContainerComponent,
        data: {
          workingAreaAgnostic: true,
          breadcrumb: 'breadcrumb.settings.ipstAlertNowSettings',
          permissions: [AtsActions.General_View],
          selectedSideMenuItem: selectedSideMenuItemSettingsIpstAlertNowSettings,
        },
        canActivate: [SettingsGuard],
      },
      {
        path: 'developer-settings',
        component: WorkingAreaDeveloperSettingsComponent,
        data: {
          breadcrumb: 'breadcrumb.settings.developerSettings',
          selectedSideMenuItem: selectedSideMenuItemSettingsDeveloperSettings,
        },
        canActivate: [DeveloperSettingsGuard],
      },
    ],
  },
  {
    path: '',
    component: UserRolesAndPermissionPageComponent,
    data: {
      breadcrumb: 'breadcrumb.settings.userRolesAndPermissions',
      selectedSideMenuItem: selectedSideMenuItemRoles,
    },
    children: [
      {
        path: 'user-roles-permission',
        component: UserRolesAndPermissionsContainerComponent,
        data: { selectedSideMenuItem: selectedSideMenuItemRoles },
        children: [
          {
            path: 'environment-users',
            component: AllEnvironmentUsersContainerComponent,
            data: {
              selectedSideMenuItem: selectedSideMenuItemRoles,
            },
          },
          {
            path: 'organization-users/:organizationId',
            component: AllOrganizationUsersContainerComponent,
            data: {
              selectedSideMenuItem: selectedSideMenuItemRoles,
            },
          },
          {
            path: 'working-area-users/:organizationId/:workAreaId',
            component: AllWorkingAreaUsersContainerComponent,
            data: {
              selectedSideMenuItem: selectedSideMenuItemRoles,
            },
          },
          {
            path: 'environment-roles',
            component: EnvironmentRolesContainerComponent,
            data: {
              selectedSideMenuItem: selectedSideMenuItemRoles,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'notFound',
    component: NotFoundDetailsComponent,
    data: { breadcrumb: 'breadcrumb.notFound', selectedSideMenuItem: selectedSideMenuItemRoles },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
