import { DateString } from 'core/models';
import { SelectItem } from 'primeng/api';

export interface OpcuaDeviceNodeRangeDto {
  from: number;
  to: number;
  value: string;
}

export interface OpcuaDeviceNodeRequestModel {
  device: string;
  name: string;
  nodeId: string;
  type: NodeType;
  subscriptionStatus: string;
  values: string[];
  range: OpcuaDeviceNodeRangeDto[];
  comment?: string | null;
}

export interface OpcuaDeviceNodeResponseModel extends OpcuaDeviceNodeRequestModel {
  state: OpcuaNodeStateDto;
}

export interface Values {
  value: string;
}

export enum NodeType {
  None = 'None',
  Telemetry = 'Telemetry',
  Write = 'Write',
  Procedure = 'Procedure',
}

export interface OpcuaNodeStateDto {
  name: string;
  measurementDate: DateString | null;
  value: string | null;
  rawValue: string | null;
}

export interface SelectedOpcuaReadNodes {
  nodeName?: string;
  values: SelectItem[];
}
