import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { IpstAlertSettingsV2Dto } from 'core/dtos';
import { AtsActions, DateString, GuidString } from 'core/models';
import { AtsTranslationService, PermissionService } from 'core/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ipst-alertnow-settings-view',
  templateUrl: './ipst-alertnow-settings-view.component.html',
  styleUrls: ['./ipst-alertnow-settings-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertnowSettingsViewComponent implements OnChanges, OnDestroy {
  @Input() selectedWorkingAreaId: GuidString | null = null;
  @Input() ipstWorkingAreaSettingV2Dto?: IpstAlertSettingsV2Dto;
  @Input() lastIpstMessageResolved: DateString = '';
  @Output() readonly resolveAllIpstMessages = new EventEmitter<GuidString>();

  canResolveMessages = false;
  ngUnsubscribe = new Subject<void>();
  minDate: Date = new Date('2000-01-01T00:00:00.000Z');
  response: DateString = '';
  constructor(
    private readonly permissionService: PermissionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly atsTranslateService: AtsTranslationService
  ) {
    this.canResolveMessages = this.permissionService.actionAllowed(
      AtsActions.ResolveAllIpstMessages
    );
  }

  ngOnChanges({ lastIpstMessageResolved }: TypedChanges<IpstAlertnowSettingsViewComponent>): void {
    if (lastIpstMessageResolved?.currentValue || lastIpstMessageResolved?.isFirstChange()) {
      this.response = lastIpstMessageResolved.currentValue;
      this.setLastIpstMessageResoledDsHint(lastIpstMessageResolved.currentValue);
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setLastIpstMessageResoledDsHint(response: DateString): void {
    const lastResolvedTime = new Date(response);

    if (this.ipstWorkingAreaSettingV2Dto) {
      if (lastResolvedTime > this.minDate) {
        this.ipstWorkingAreaSettingV2Dto.lastMessageResolve = this.atsTranslateService.get(
          'settings.ipstAlertNowSettings.workingAreaSettings.lastResolved',
          {
            time: lastResolvedTime.toLocaleTimeString('de', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            }),
          }
        );
        this.cdr.markForCheck();
      } else {
        this.ipstWorkingAreaSettingV2Dto.lastMessageResolve = this.atsTranslateService.get(
          'settings.ipstAlertNowSettings.workingAreaSettings.lastResolvedUnknown'
        );
        this.cdr.markForCheck();
      }
    }
  }

  onResolveAllMessages(): void {
    if (this.selectedWorkingAreaId) this.resolveAllIpstMessages.emit(this.selectedWorkingAreaId);
  }
}
