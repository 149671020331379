import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { InfobarMessageResponse } from 'core/dtos';
import { InfobarMessageRequest } from 'core/models';
import { AtsTranslationService } from 'core/services/ats-translation.service';
import { LocaleSettings } from 'primeng/calendar';
import { Observable } from 'rxjs';
import { getLocale } from 'shared/helpers';

@Component({
  selector: 'app-infobar-settings',
  templateUrl: './infobar-settings.component.html',
  styleUrls: ['./infobar-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfobarSettingsComponent implements OnInit {
  constructor(private readonly atsTranslateService: AtsTranslationService) {}

  @Input() infoType: 'information' | 'warning' | 'fatal' | null = null;
  @Input() minDate: Date = new Date();
  @Input() expiryTime: Date = new Date();
  @Input() message = '';
  @Input() dateFormat = '';
  @Input() optionItems: SelectListOption[] = [];
  @Input() isLoaded = false;
  @Input() latestMessageObs: Observable<InfobarMessageResponse | null> | null = null;

  @Output() readonly save = new EventEmitter<InfobarMessageRequest>();
  calendarLocale?: LocaleSettings;

  ngOnInit(): void {
    this.getCalendarAccordingLanguage(this.atsTranslateService.currentLang);
  }

  getCalendarAccordingLanguage(lang: string): void {
    this.calendarLocale = getLocale(lang);
  }

  onSave(): void {
    const infobarMessage: InfobarMessageRequest = {
      infoType: this.infoType ?? 'information',
      message: this.message,
      expiryDateTime: this.expiryTime,
    };

    this.save.emit(infobarMessage);
  }
}
