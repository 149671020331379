import { Injectable } from '@angular/core';
import { GuidString, MapItem, MapItemType } from 'core/models';

import { select, Store } from '@ngrx/store';
import { TenantRouterService, ToastService } from 'core/services';
import { MapCommunicationService } from 'modules/maps/services';
import { take } from 'rxjs/operators';
import * as fromVehicle from 'store-modules/vehicles-store';

import * as fromRoot from 'store/index';

@Injectable({
  providedIn: 'root',
})
export class MapNavigationHelperService {
  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly vehicleStore: Store<fromVehicle.VehiclesFeatureState>,
    private readonly mapCommunicationService: MapCommunicationService,
    private readonly router: TenantRouterService,
    private readonly toastService: ToastService
  ) {}

  navigateToMapWithSelectionByVehicleId(vehicleId: GuidString): void {
    this.vehicleStore
      .pipe(select(fromVehicle.selectVehicleById(vehicleId)), take(1))
      .subscribe(vehicle => {
        if (!vehicle) {
          this.toastService.createErrorToast('shared.selectedVehicleNotFound');
        } else if (!vehicle?.map?.id) {
          this.toastService.createErrorToast('shared.selectedVehicleNoMap');
        } else {
          this.navigateToMapWithSelection(
            {
              id: vehicle.id.toString(),
              position: {
                x: vehicle.pose2D.x,
                y: vehicle.pose2D.y,
              },
              type: MapItemType.Vehicle,
            },
            vehicle.map.id
          );
        }
      });
  }

  navigateToMapWithSelection(item: MapItem, mapId: GuidString): void {
    this.rootStore.pipe(select(fromRoot.getRouterInfo), take(1)).subscribe(routerInfo => {
      const route = `/maps/${mapId}`;

      this.mapCommunicationService.setSelectedItem({
        item: {
          id: item.id.toString(),
          position: {
            x: item.position.x,
            y: item.position.y,
          },
          type: item.type,
        },
        isFocused: true,
      });

      if (!routerInfo.url.includes(route)) {
        void this.router.navigate([route]);
      }
    });
  }
}
