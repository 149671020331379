import { createSelector } from '@ngrx/store';

import * as fromRoot from '../reducers';

export const getRouterInfo = createSelector(fromRoot.getRouterState, state =>
  state
    ? state.state
    : {
        url: '',
        queryParams: {},
        params: {},
        data: {},
        breadcrumbs: [],
      }
);
