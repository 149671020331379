import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { SettingsSignalRService } from 'core/signalR/modules/settings-signalr.service';
import { ensureStoreLoadedWithRefresh } from 'shared/helpers';

import * as fromSettings from 'store-modules/settings-store';

@Injectable({
  providedIn: 'root',
})
export class MapSettingsGuard implements CanActivate {
  readonly settingsSignalrSubscriber;
  constructor(
    private readonly store: Store<fromSettings.SettingsFeatureState>,
    private readonly settingsSignalRService: SettingsSignalRService
  ) {
    this.settingsSignalrSubscriber = this.settingsSignalRService.signalrSubscriberFactory(
      MapSettingsGuard.name
    );
  }

  async canActivate(): Promise<boolean> {
    ensureStoreLoadedWithRefresh(this.store, fromSettings.loadMapSettings());

    await this.settingsSignalrSubscriber.joinMapManagerFeatures();

    return true;
  }
}
