import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromRoot from 'store/reducers';
import { selectReducedSelectedWorkingArea } from 'store/selectors';

import { BaseHttpClient, RequestTemplate } from './base-http-client';

@Injectable()
export class TenantHttpClient extends BaseHttpClient {
  constructor(protected httpClient: HttpClient, protected store: Store<fromRoot.RootState>) {
    super(httpClient);
    if (store === null || store === undefined) {
      throw new Error('Store cannot be null');
    }
  }

  protected getUrlFromServicePath(request: string | RequestTemplate): Observable<string> {
    const service = typeof request === 'string' ? request : this.getUrlFromRequest(request);

    return this.store.pipe(
      select(selectReducedSelectedWorkingArea),
      filter(x => x != null),
      map(wa => `${this.apiUrl}/${wa?.organizationName}/${wa?.name}${service}`),
      take(1)
    );
  }
}
