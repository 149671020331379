import { ZoneType } from 'core/dtos';
import { GuidString, MapItemType, Vector2D } from 'core/models';
import { DisplayObject } from 'pixi.js';
import { MapItemContainer } from '../map-item-container';

export class ZoneMapItemContainer extends MapItemContainer {
  constructor(
    dtoId: string,
    readonly masterZoneId: GuidString,
    readonly zoneType: ZoneType,
    readonly polygon: Vector2D[]
  ) {
    super(dtoId, MapItemType.Zone);
  }

  getZoneShape(): Vector2D[] {
    return this.polygon;
  }
}

export function isZoneMapItemContainer(
  item: DisplayObject | MapItemContainer
): item is ZoneMapItemContainer {
  return 'getZoneShape' in item;
}
