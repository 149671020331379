/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HuLookupMappingService } from 'core/api-services';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { ToastService } from 'core/services/toast.service';
import { setHasChanges, setIsEditMode } from 'store/actions';
import * as HuLookupMappingActions from '../actions/hu-lookup-mapping.actions';

@Injectable()
export class HuLookupMappingEffects {
  loadHuLookupMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HuLookupMappingActions.loadHuLookupMappings),
      map(action => action.deviceName),
      concatMap(deviceName =>
        this.huLookupMappingService.getHuLookupMappingForDevice(deviceName).pipe(
          map(mappings =>
            HuLookupMappingActions.loadHuLookupMappingsSuccess({
              mappings,
            })
          ),
          catchError(errorMessage =>
            of(HuLookupMappingActions.loadHuLookupMappingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadHuLookupMappingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HuLookupMappingActions.loadHuLookupMappingsFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  updateHuLookupMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HuLookupMappingActions.updateHuLookupMappings),
      concatMap(({ deviceName, mappings }) =>
        this.huLookupMappingService.updateHuLookupMappings(deviceName, mappings).pipe(
          concatMap(() => {
            return [
              HuLookupMappingActions.updateHuLookupMappingsSuccess({ deviceName }),
              HuLookupMappingActions.loadHuLookupMappings({ deviceName }),
            ];
          }),
          catchError(errorMessage =>
            of(HuLookupMappingActions.updateHuLookupMappingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateHuLookupMappingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HuLookupMappingActions.updateHuLookupMappingsSuccess),
      tap(deviceName => {
        this.toastService.createSuccessToast('shared.actions.updated', {
          name: deviceName.deviceName,
        });
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateHuLookupMappingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HuLookupMappingActions.updateHuLookupMappingsFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly huLookupMappingService: HuLookupMappingService,
    private readonly toastService: ToastService
  ) {}
}
