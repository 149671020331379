export enum VehicleAvailability {
  NotAvailable = 0,
  Available = 1,
}

export enum VehicleMode {
  Autonomous = 0,
  Manual = 1,
}

export enum VehicleStatus {
  NotInitialized = 0,
  Busy = 1,
  Idle = 2,
  Charging = 3,
  Paused = 4,
  Error = 5,
  NotConnected = 6,
  SwitchedOff = 7,
}

export const InactiveVehicleStates = [
  VehicleStatus.NotConnected,
  VehicleStatus.NotInitialized,
  VehicleStatus.SwitchedOff,
];

export enum VehicleLoadStatus {
  NotLoaded = 0,
  Loaded = 1,
}

export enum VehicleMaintenanceStatus {
  NotInMaintenance = 0,
  Maintenance = 1,
}

export enum VehiclesMenuButtons {
  NewFleet = 0,
  NewVehicle = 1,
  ActionWakeVehicles = 2,
  ActionSendZone = 3,
  ActionSetMaintenanceMode = 4,
  ActionSendSoftwareUpdate = 5,
}

export enum VehicleInterfaceType {
  Ros = 0,
  Vda5050 = 1,
}

export enum VehicleTrafficLightStatus {
  Connected = 0,
  SwitchedOff = 1,
  NotInitialized = 2,
  ConnectedWithError = 3,
  LostConnection = 4,
}

export enum VehicleInformation {
  Paused = 0,
  Maintenance = 1,
  Charging = 2,
  Loaded = 3,
  BrakeTestRequired = 4,
}

export enum BatteryLevelStatus {
  Green = 0,
  Orange = 1,
  Red = 2,
}

export enum HardwareVersion {
  Unknown = 0,
  StrVersion2 = 2,
  StrVersion3 = 3,
  StrVersion4 = 4,
  TuggerTrainFourAmV2 = 5,
  TuggerTrainDsV1 = 6,
  ForkliftFourAm_15t = 7,
  ForkliftFourAm_20t = 8,
  DSType1 = 9,
  DSType2 = 10,
  DSType3 = 11,
  DSType4 = 12,
  DSType5 = 13,
  TuggerTrainManual = 14,
  ForkliftManual = 15,
  AgiloxONE = 16,
  AgiloxOCF = 17,
}

export enum VehicleType {
  UnitLoad = 0,
  TuggerTrain = 1,
  Forklift = 2,
  U_AGV = 4,
}

export enum LoadType {
  Unknown = 0,
  GltStrStandard = 1,
  Uglt = 2,
  SpaMedium = 3,
  SeatDeb = 4,
  GltPalletCrosswise = 5,
  GltPalletLengthwise = 6,
}
export interface LoadDimensions {
  length: number;
  width: number;
}

export enum BrokerType {
  IoTHub = 0,
  MqttBroker = 1,
}

export enum SoftwareUpdateStatus {
  NoUpdate = 0,
  Downloading = 1,
  Installing = 2,
  Error = 3,
}

export enum SoftwareUpdateVersionStatus {
  Unknown = 0,
  Approved = 1,
  Outdated = 2,
  Test = 3,
}

export enum VehicleGroup {
  Unknown = 0,
  Str = 1,
  TuggerTrainFourAmV2 = 2,
  TuggerTrainDs = 3,
  USTR = 4,
  Forklift = 5,
  Manual = 6,
  U_AGV = 7,
}

type ConfiguredMapHardwareVersions = Exclude<HardwareVersion, HardwareVersion.Unknown>;

export const HardwareVersionVehicleType: Record<ConfiguredMapHardwareVersions, VehicleType> = {
  [HardwareVersion.StrVersion2]: VehicleType.UnitLoad,
  [HardwareVersion.StrVersion3]: VehicleType.UnitLoad,
  [HardwareVersion.StrVersion4]: VehicleType.UnitLoad,
  [HardwareVersion.TuggerTrainFourAmV2]: VehicleType.TuggerTrain,
  [HardwareVersion.TuggerTrainDsV1]: VehicleType.TuggerTrain,
  [HardwareVersion.TuggerTrainManual]: VehicleType.TuggerTrain,
  [HardwareVersion.ForkliftFourAm_15t]: VehicleType.Forklift,
  [HardwareVersion.ForkliftFourAm_20t]: VehicleType.Forklift,
  [HardwareVersion.ForkliftManual]: VehicleType.Forklift,
  [HardwareVersion.AgiloxONE]: VehicleType.Forklift,
  [HardwareVersion.AgiloxOCF]: VehicleType.Forklift,
  [HardwareVersion.DSType1]: VehicleType.U_AGV,
  [HardwareVersion.DSType2]: VehicleType.U_AGV,
  [HardwareVersion.DSType3]: VehicleType.U_AGV,
  [HardwareVersion.DSType4]: VehicleType.U_AGV,
  [HardwareVersion.DSType5]: VehicleType.U_AGV,
};

export const DefaultHardwareVersion: Record<VehicleType, HardwareVersion> = {
  [VehicleType.UnitLoad]: HardwareVersion.StrVersion3,
  [VehicleType.TuggerTrain]: HardwareVersion.TuggerTrainFourAmV2,
  [VehicleType.Forklift]: HardwareVersion.ForkliftFourAm_15t,
  [VehicleType.U_AGV]: HardwareVersion.DSType1,
};

export enum VehiclePowerSavingType {
  Str = 0,
  Vda5050 = 1,
  Uagv = 2,
}

export enum VehicleErrorType {
  AtsJob10 = 'ATS_JOB_10',
}

export enum VehicleConnectionHealth {
  Default = 'default',
  Cautious = 'caution',
  Critical = 'critical',
}

export const VehicleGroupByHardwareVersion: Record<ConfiguredMapHardwareVersions, VehicleGroup> = {
  [HardwareVersion.StrVersion2]: VehicleGroup.Str,
  [HardwareVersion.StrVersion3]: VehicleGroup.Str,
  [HardwareVersion.StrVersion4]: VehicleGroup.Str,
  [HardwareVersion.TuggerTrainFourAmV2]: VehicleGroup.TuggerTrainFourAmV2,
  [HardwareVersion.TuggerTrainDsV1]: VehicleGroup.TuggerTrainDs,
  [HardwareVersion.ForkliftFourAm_15t]: VehicleGroup.Forklift,
  [HardwareVersion.ForkliftFourAm_20t]: VehicleGroup.Forklift,
  [HardwareVersion.AgiloxOCF]: VehicleGroup.Forklift,
  [HardwareVersion.AgiloxONE]: VehicleGroup.Forklift,
  [HardwareVersion.TuggerTrainManual]: VehicleGroup.Manual,
  [HardwareVersion.ForkliftManual]: VehicleGroup.Manual,
  [HardwareVersion.DSType1]: VehicleGroup.U_AGV,
  [HardwareVersion.DSType2]: VehicleGroup.U_AGV,
  [HardwareVersion.DSType3]: VehicleGroup.U_AGV,
  [HardwareVersion.DSType4]: VehicleGroup.U_AGV,
  [HardwareVersion.DSType5]: VehicleGroup.U_AGV,
};

export const HardwareVersionByVehicleGroup: Record<
  Exclude<VehicleGroup, VehicleGroup.Unknown | VehicleGroup.USTR>,
  ConfiguredMapHardwareVersions[]
> = {
  [VehicleGroup.Str]: [
    HardwareVersion.StrVersion2,
    HardwareVersion.StrVersion3,
    HardwareVersion.StrVersion4,
  ],
  [VehicleGroup.TuggerTrainFourAmV2]: [HardwareVersion.TuggerTrainFourAmV2],
  [VehicleGroup.TuggerTrainDs]: [HardwareVersion.TuggerTrainDsV1],
  [VehicleGroup.Forklift]: [HardwareVersion.ForkliftFourAm_15t, HardwareVersion.ForkliftFourAm_20t],
  [VehicleGroup.Manual]: [HardwareVersion.TuggerTrainManual, HardwareVersion.ForkliftManual],
  [VehicleGroup.U_AGV]: [
    HardwareVersion.DSType1,
    HardwareVersion.DSType2,
    HardwareVersion.DSType3,
    HardwareVersion.DSType4,
    HardwareVersion.DSType5,
  ],
};

export type ConfiguredVehicleGroups = Exclude<VehicleGroup, VehicleGroup.Unknown>;

export const DefaultHardwareVersionByVehicleGroup: Record<
  ConfiguredVehicleGroups,
  HardwareVersion
> = {
  [VehicleGroup.Str]: HardwareVersion.StrVersion3,
  [VehicleGroup.TuggerTrainFourAmV2]: HardwareVersion.TuggerTrainFourAmV2,
  [VehicleGroup.Forklift]: HardwareVersion.ForkliftFourAm_15t,
  [VehicleGroup.U_AGV]: HardwareVersion.DSType1,
  [VehicleGroup.TuggerTrainDs]: HardwareVersion.TuggerTrainDsV1,
  [VehicleGroup.USTR]: HardwareVersion.Unknown,
  [VehicleGroup.Manual]: HardwareVersion.ForkliftManual,
};

export const VehicleGroupsByVehicleType: Record<VehicleType, VehicleGroup[]> = {
  [VehicleType.UnitLoad]: [VehicleGroup.USTR, VehicleGroup.Str],
  [VehicleType.Forklift]: [VehicleGroup.Forklift],
  [VehicleType.TuggerTrain]: [VehicleGroup.TuggerTrainDs, VehicleGroup.TuggerTrainFourAmV2],
  [VehicleType.U_AGV]: [VehicleGroup.U_AGV],
};

export const ConfiguredDsUagvKeys = [
  HardwareVersion.DSType1,
  HardwareVersion.DSType2,
  HardwareVersion.DSType3,
  HardwareVersion.DSType4,
  HardwareVersion.DSType5,
] as const;
export type ConfiguredDsUagvHardwareVersions = (typeof ConfiguredDsUagvKeys)[number];
