import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationsService, UserSettingsService } from 'core/api-services';
import { UserSettingsDto } from 'core/dtos';
import { GuidString } from 'core/models';
import { SessionService } from 'core/services/session.service';
import { firstValueFrom } from 'rxjs';

interface ReducedWorkingArea {
  id: GuidString;
  name: string;
  organizationName: string;
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageComponent implements OnInit {
  private readonly plantUrlPrefix = '/welcome/plant/view/';
  constructor(
    private readonly sessionService: SessionService,
    private readonly organizationsService: OrganizationsService,
    private readonly userSettingsService: UserSettingsService,
    private readonly router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    // Login must be complete to have the JWT
    await this.sessionService.loginCompleted;

    const [organizations, settings] = await Promise.all([
      firstValueFrom(this.organizationsService.getOrganizations()),
      firstValueFrom(this.userSettingsService.getUserSettings()),
    ]);

    const workingAreas = organizations.flatMap(org =>
      org.workAreas.map(wa => ({
        id: wa.id,
        name: wa.name,
        organizationName: org.name,
      }))
    );
    const route = this.findWorkingArea(workingAreas, settings);
    void this.router.navigate(route);
  }

  findWorkingArea(allWorkingAreas: ReducedWorkingArea[], settings: UserSettingsDto): string[] {
    if (allWorkingAreas.length === 0) {
      return ['/welcome'];
    }
    const defaultStartPage =
      settings.defaultStartPage === null || settings.defaultStartPage === undefined
        ? 'maps'
        : settings.defaultStartPage?.toString().split('/');
    const wa = allWorkingAreas.find(workingArea => workingArea.id === settings.defaultWorkingArea);
    if (wa !== undefined && settings.defaultStartPage !== null) {
      return [wa.organizationName, wa.name].concat(defaultStartPage);
    }

    const org = allWorkingAreas[0].organizationName;
    return [this.plantUrlPrefix, org].filter(Boolean);
  }
}
