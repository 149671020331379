import { GuidString } from '../../models/core/string-typings';

export interface IntersectionZoneVehiclePriorityDto {
  vehicleId: GuidString;
  masterZoneId: GuidString;
  mapId: GuidString;
  zoneAccessPriorityReason: ZoneAccessPriorityReasonEnum;
}

export enum ZoneAccessPriorityReasonEnum {
  UserTriggered = 1,
  BlockingIntersection = 2,
  WaitTimeExceeded = 3,
  MainFlowDirection = 4,
  Normal = 1000,
}
