import { ApplicationInitStatus, Injectable } from '@angular/core';
import { environment } from '@environment';
import { AuthConfig, OAuthService, OAuthStorage, UserInfo } from 'angular-oauth2-oidc';
import { User } from 'core/models';

import { HttpErrorResponse } from '@angular/common/http';
import { API_SERVICES } from 'core/constants';
import { UserService } from '../api-services/user.service';

@Injectable()
export class SessionService {
  loginCompleted: Promise<void>;
  redirectUrl = '';
  url = '';
  baseUrl = '';
  currentUser?: User;
  token = '';
  currentTab = '';
  readonly ERROR_WHEN_EXPIRED = 401;

  constructor(
    private readonly oAuthService: OAuthService,
    private readonly authStorage: OAuthStorage,
    private readonly userService: UserService,
    private readonly appInit: ApplicationInitStatus
  ) {
    this.loginCompleted = this.tryLogin();
  }

  async tryLogin(): Promise<void> {
    await this.appInit.donePromise;

    let showAuthDebug = false;
    this.baseUrl = 'https://' + window.location.hostname;
    this.url = this.baseUrl;

    if (window.location.hostname.startsWith('localhost')) {
      this.baseUrl = '';
      this.url = 'http://localhost:4200';
      showAuthDebug = true;
    }
    const oauthConfig: AuthConfig = {
      redirectUri: this.url,
      clientId: environment.config.clientId,
      scope: 'openid profile email',
      postLogoutRedirectUri: this.url,
      responseType: 'id_token',
      requestAccessToken: false,
      oidc: true,
      requireHttps: 'remoteOnly',
      showDebugInformation: showAuthDebug,
      clearHashAfterLogin: false,
      skipIssuerCheck: true,
      strictDiscoveryDocumentValidation: false,
    };

    this.oAuthService.configure(oauthConfig);

    if (environment.skipAuth) {
      return;
    }
    const discoveryDocumentUrl = `${this.baseUrl}${environment.Services.Auth}${API_SERVICES.DiscoveryEndpoint}`;
    try {
      await this.oAuthService.loadDiscoveryDocument(discoveryDocumentUrl);
      await this.oAuthService.tryLogin();
      this.setRedirectUrl(this.oAuthService.state || '');

      if (this.oAuthService.hasValidIdToken()) {
        await this.getUserInfo();
        this.token = this.oAuthService.getIdToken();
      }
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === this.ERROR_WHEN_EXPIRED) {
        this.authStorage.removeItem('id_token');
        location.reload();
      } else {
        console.error(error);
      }
    }
  }

  async getUserInfo(forceRefresh = false): Promise<User> {
    if (this.currentUser === undefined || forceRefresh) {
      this.currentUser = await this.userService.getUserInfo();
      this.persistUserData();
    }
    return this.currentUser;
  }

  private persistUserData(): void {
    if (this.currentUser) {
      this.authStorage.setItem('user', JSON.stringify(this.currentUser));
      this.authStorage.setItem('user_name', this.currentUser.name);
    } else {
      this.authStorage.removeItem('user');
      this.authStorage.removeItem('user_name');
    }
  }

  login(): void {
    this.oAuthService.initImplicitFlow(window.location.pathname);
  }

  logout(): void {
    this.authStorage.removeItem('user_name');
    this.userService.logOut(this.token).catch(console.error);
    this.oAuthService.logOut();
  }

  isUserAuthenticated(): boolean {
    return (
      environment.skipAuth ||
      this.oAuthService.hasValidIdToken() ||
      this.oAuthService.hasValidAccessToken()
    );
  }

  hasUser(): boolean {
    return this.getDisplayName() !== 'Guest';
  }

  getDisplayName(): string {
    const userName = this.authStorage.getItem('user_name');
    return (userName?.match(/[^\s]*\s*[^\s|,]+/) || [])[0] || 'Guest';
  }

  getCurrentUser(): User | undefined {
    if (this.currentUser) {
      return this.currentUser;
    }

    const userString = this.authStorage.getItem('user');
    if (userString) {
      this.currentUser = JSON.parse(userString);
    }
    return this.currentUser;
  }

  getToken(): string {
    return (this.isUserAuthenticated() && this.authStorage.getItem('id_token')) || '';
  }

  private setRedirectUrl(url: string): void {
    const cleaned = url.replace(/\/$/, '');
    this.redirectUrl = cleaned;
  }

  getAndResetRedirect(): string {
    const result = this.redirectUrl;
    this.redirectUrl = '';
    return result;
  }

  getIdentityClaims(): UserInfo | undefined {
    return this.oAuthService.getIdentityClaims() as UserInfo;
  }

  async registerUser(): Promise<void> {
    this.currentUser = await this.userService.registerUser();
    this.persistUserData();
  }
}
