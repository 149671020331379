import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-enterprise';
import { ExtendedDsMenuItem } from 'core/models';

@Component({
  selector: 'app-action-menu-cell',
  templateUrl: './action-menu-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMenuCellComponent implements ICellRendererAngularComp {
  menuItems: ExtendedDsMenuItem[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowNode!: RowNode<any>;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  agInit(params: ICellRendererParams): void {
    this.menuItems = params.data.isMenuItemsHidden ? [] : params.value;
    this.rowNode = params.node;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh(params: ICellRendererParams<any, ExtendedDsMenuItem[]>): boolean {
    this.menuItems = params.data.isMenuItemsHidden ? [] : params.value;

    this.cdRef.detectChanges();
    return true;
  }
}
