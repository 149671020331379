import { GuidString } from 'core/models';

export interface ErrorAggregate {
  vehicleId: GuidString;
  type: string;
  vehicleName: string;
  isVehicleMaintenanceModeEnabled: boolean;
  level: string;
  description: string;
  occurredDateTimeUtc: string;
  workAreaId: GuidString;
  isDeleted: boolean;
  sendNotification: boolean;
  mapId: GuidString;
  mapName: string;
  fleetId: GuidString | null;
  fleetName: string;
  missionId: GuidString | null;
  missionName: string;
  missionStepDetails: string;
  missionProvisioningDateTimeUtc: string | null;
  location: string;
}

export enum ErrorStatus {
  NoError = 'no-error',
  FatalError = 'fatal-error',
  WarningError = 'warning-error',
  InfoError = 'info-error',
}
