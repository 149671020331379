import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';

import { AbstractTranslatePipe } from './abstract-translate.pipe';

@Pipe({
  name: 'dsEnumDropdown',
  pure: false,
})
export class DsEnumDropdownPipe
  extends AbstractTranslatePipe<SelectListOption[]>
  implements PipeTransform, OnDestroy
{
  transform(
    value: string | null,
    sort = true,
    remove: number[] | string[] = [],
    type: 'string' | 'number' = 'number'
  ): SelectListOption[] | null {
    this.ensureLangChangeReaction();

    if (value == null) {
      return null;
    }

    const translationKey = `enums.${value}`;
    return this.getCachedOrFresh(
      translationKey,
      remove,
      this.translateEnum.bind(this, translationKey, sort, remove, type)
    );
  }

  private translateEnum(
    translationKey: string,
    sort: boolean,
    remove: number[] | string[],
    type: 'string' | 'number'
  ): SelectListOption[] {
    const translations = this.atsTranslationService.getEnum(translationKey);

    let data = Object.entries(translations).map(([value, label]) => ({
      value: type === 'string' ? String(value) : Number(value),
      label,
      id: String(value),
    }));

    if (remove.length > 0) {
      for (const removeItem of remove) {
        data = data.filter(item => item.id !== String(removeItem));
      }
    }

    return sort ? data.sort((x, y) => x.label.localeCompare(y.label)) : data;
  }
}
