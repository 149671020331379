import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { ProcessChainGroupDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ProcessChainGroupService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.ProcessChainGroups;

  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getProcessChainGroups(): Observable<ProcessChainGroupDto[]> {
    return this.get<ProcessChainGroupDto[]>(this.servicePath);
  }

  createProcessChainGroup(
    newProcessChainGroup: ProcessChainGroupDto
  ): Observable<ProcessChainGroupDto> {
    return this.post<ProcessChainGroupDto>(this.servicePath, newProcessChainGroup);
  }

  updateProcessChainGroup(
    updatedProcessChainGroup: ProcessChainGroupDto
  ): Observable<ProcessChainGroupDto> {
    return this.put<ProcessChainGroupDto>(
      `${this.servicePath}/${updatedProcessChainGroup.id}`,
      updatedProcessChainGroup
    );
  }

  deleteProcessChainGroup(processChainGroupId: GuidString): Observable<GuidString> {
    return this.delete<GuidString>(`${this.servicePath}/${processChainGroupId}`);
  }
}
