/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable rxjs/no-implicit-any-catch */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OAuthModuleConfig,
  OAuthResourceServerErrorHandler,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { SessionService } from 'core/services';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly authStorage: OAuthStorage,
    private readonly errorHandler: OAuthResourceServerErrorHandler,
    private readonly moduleConfig: OAuthModuleConfig,
    private readonly sessionService: SessionService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = req.url.toLowerCase();

    if (!this.allowedUrl(url)) {
      return next.handle(req);
    }
    if (!this.moduleConfig) {
      return next.handle(req);
    }
    if (!this.moduleConfig.resourceServer) {
      return next.handle(req);
    }
    if (!this.moduleConfig.resourceServer.allowedUrls) {
      return next.handle(req);
    }

    const shouldSendToken = this.moduleConfig.resourceServer.sendAccessToken;
    if (shouldSendToken) {
      const token = this.authStorage.getItem('id_token');
      if (token != null) {
        const headers = req.headers.set('Authorization', `Bearer ${token}`);
        req = req.clone({ headers });
      }
    }

    return next.handle(req).pipe(
      catchError((err: HttpResponse<any>) => {
        if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.Unauthorized) {
          this.sessionService.login();
        }
        return this.errorHandler.handleError(err);
      })
    );
  }

  private allowedUrl(url: string): boolean {
    const allowedUrls = this.moduleConfig.resourceServer.allowedUrls ?? [];
    return allowedUrls.some(x => url.startsWith(x));
  }
}
