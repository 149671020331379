import { createSelector } from '@ngrx/store';
import {
  selectAllNavigationLayers,
  selectSelectedNavigationLayer,
} from 'store-modules/maps-store/selectors/navigation-layer.selectors';
import {
  selectZonesByMapId,
  selectZonesByZoneSetId,
} from 'store-modules/maps-store/selectors/zones.selectors';
import { selectAllPois } from 'store-modules/pois-store/selectors/pois.selectors';
import { selectAllProcessChains } from 'store-modules/process-configurator-store';
import { selectAllFleets } from 'store-modules/vehicles-store/selectors/fleets.selectors';
import {
  selectAllActiveUnitLoadVehiclesWithoutFleet,
  selectAllVehicles,
} from 'store-modules/vehicles-store/selectors/vehicles.selectors';

import { selectAllMaps } from './maps.selectors';
import { createManageMapList } from './maps.selectors.helper';
import { selectAllZoneSets } from './zone-sets.selectors';

export const selectMapsWithUnitLoadVehiclesWithoutFleet = createSelector(
  selectAllMaps,
  selectAllActiveUnitLoadVehiclesWithoutFleet,
  (maps, vehicles) => {
    const vehiclesMapIds = vehicles.map(v => v.map?.id);
    return maps.filter(map => vehiclesMapIds.includes(map.id));
  }
);

export const selectMapDataForList = createSelector(
  selectAllMaps,
  selectAllPois,
  selectAllFleets,
  selectAllVehicles,
  selectAllProcessChains,
  selectAllZoneSets,
  (maps, pois, fleets, vehicles, processChains, zoneSets) => {
    return createManageMapList(maps, pois, fleets, vehicles, processChains, zoneSets);
  }
);

export const selectZonesAndNavigationLayer = createSelector(
  selectZonesByMapId,
  selectSelectedNavigationLayer,
  (allZones, navigationLayer) => {
    return {
      zones: allZones,
      layer: navigationLayer,
    };
  }
);

export const selectZonesByZoneSetAndNavigationLayer = createSelector(
  selectZonesByZoneSetId,
  selectSelectedNavigationLayer,
  (allZoneSetZones, navigationLayer) => {
    return {
      zones: allZoneSetZones,
      layer: navigationLayer,
    };
  }
);

export const selectAllMapsAndNavigationLayers = createSelector(
  selectAllMaps,
  selectAllNavigationLayers,
  (allMaps, allNavigationLayers) => {
    return { maps: allMaps, layers: allNavigationLayers };
  }
);
