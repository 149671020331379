import { DateString, GuidString, VehicleGroup } from 'core/models';

export interface NavigationLayerResponseModel {
  id: GuidString;
  createdOn: DateString;
  freeThresh: number;
  heightInPixels: number;
  imageOffset: ImageOffsetResponseModel;
  coordinateSystemOffset?: CoordinateSystemOffset;
  applyCoordinateOffset?: boolean;
  imageUrl: string;
  mapId: GuidString;
  name: string;
  occupiedThresh: number;
  resolution: number;
  widthInPixels: number;
  displayOrder: number;
  opacity?: number;
  isSelected?: boolean;
  vehicleGroup: VehicleGroup;
  hasLif?: boolean;
}

export interface Coordinate {
  x: number;
  y: number;
}

export interface ImageOffsetResponseModel {
  x: number;
  y: number;
  rotation: number;
}

export interface CoordinateSystemOffset {
  translation: Coordinate;
  scale: Coordinate;
  rotation: number;
}

export interface NavigationLayerCardModel
  extends NavigationLayerStorageModel,
    NavigationLayerResponseModel {
  borderColor?: string;
  isInvalid?: boolean;
  hasChanged?: boolean;
}

export interface NavigationLayerStorageModel {
  id: GuidString;
  mapId: GuidString;
  isSelected?: boolean;
  opacity?: number;
  showOffsetPanel?: boolean;
}

export interface LayersCreateModel extends NavigationLayerCreateModel {
  lifFile?: File | undefined;
}

export interface NavigationLayerCreateModel {
  id: GuidString;
  mapId: GuidString;
  name: string;
  imgFile: File | undefined;
  imageOffset: ImageOffsetResponseModel;
  coordinateSystemOffset?: CoordinateSystemOffset;
  resolution: number | undefined;
  applyCoordinateOffset?: boolean;
  vehicleGroup: VehicleGroup;
  isSelected?: boolean;
}

export enum LayerCreateStatus {
  NotDefined = 1,
  Success = 2,
  Failed = 3,
}
export interface LayersCreateResponse {
  layer: NavigationLayerResponseModel;
  lifUploadStatus: LayerCreateStatus;
}
