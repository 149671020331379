import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DsDatepickerConfiguration, DsTimepickerComponentConfiguration } from '@bmw-ds/components';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { LocaleSettings } from 'primeng/calendar';
import { setTimeString } from 'shared/helpers';

export interface IAgGridSingleDateTimeFilterModel {
  singleDate: string | undefined;
}

@Component({
  selector: 'app-ag-grid-single-date-time-filter',
  templateUrl: './ag-grid-single-date-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridSingleDateTimeFilterComponent implements IFilterAngularComp {
  params: IFilterParams | undefined;

  readonly datePickerConfig: Partial<DsDatepickerConfiguration> = {
    selectionMode: 'single',
    allowDirectInput: true,
    closeOnDateSelect: true,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  readonly timePickerConfig: Partial<DsTimepickerComponentConfiguration> = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    hours: 1,
    minutes: 1,
    seconds: 1,
    format: 'HH:mm:ss',
    allowDirectInput: true,
  };
  calendarLocale?: LocaleSettings;
  singleDate: Date | null = null;
  singleTime = '';

  singleDatetimeIsInValid = false;

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  getSingleDateTime(): Date | undefined {
    if (this.singleDate === null) {
      return undefined;
    }
    return setTimeString(this.singleDate, this.singleTime);
  }

  isFilterActive(): boolean {
    return this.getSingleDateTime() !== undefined;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const field = this.params?.colDef.field;
    if (field === undefined) return false;

    const singleDateTime = this.getSingleDateTime();
    if (singleDateTime === undefined) return false;

    let cellDate: Date | undefined;
    try {
      cellDate = new Date(params.data[field]);
    } catch (e) {
      console.error(
        'AgGridDateTimeFilterComponent could not parse timestamp into date:',
        params.data[field],
        e
      );
      return false;
    }

    return cellDate >= singleDateTime && cellDate <= singleDateTime;
  }

  getModel(): IAgGridSingleDateTimeFilterModel {
    const singleDateTime = this.getSingleDateTime();

    if (singleDateTime === undefined) {
      return { singleDate: undefined };
    }

    return {
      singleDate: singleDateTime.toISOString(),
    };
  }

  setModel(_model: {}): void {}

  isFormValid(): boolean {
    const singleDateValid =
      this.singleDate !== null && setTimeString(this.singleDate, this.singleTime) !== undefined;

    this.singleDatetimeIsInValid = !singleDateValid;

    return singleDateValid;
  }

  updateFilter(): void {
    if (this.isFormValid()) this.params?.filterChangedCallback();
  }

  clearFilter(): void {
    this.singleDate = null;
    this.singleTime = '';

    this.singleDatetimeIsInValid = false;

    if (this.params?.filterChangedCallback) {
      this.params.filterChangedCallback();
    }
  }
}
