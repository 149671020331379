import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  get<T>(key: string): T | undefined {
    try {
      const data = localStorage.getItem(key);

      return data && JSON.parse(data);
    } catch (err) {
      console.error(err);
      return;
    }
  }

  getArray<T>(key: string): T[] {
    return this.get(key) ?? [];
  }

  set(key: string, data: object = {}): void {
    try {
      window.localStorage.setItem(key, JSON.stringify(data));
    } catch (err) {
      console.error(err);
    }
  }

  load<T>(keys: string[]): T {
    return keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: this.get(key),
      }),
      {} as T
    );
  }

  upsertArrayItem<T, K>(key: string, item: T, fnKey: (value: T) => K): void {
    const items: T[] = this.get(key) ?? [];
    const index = items.findIndex(i => fnKey(i) === fnKey(item));

    if (index >= 0) {
      items[index] = item;
    } else {
      items.push(item);
    }

    this.set(key, items);
  }
}
