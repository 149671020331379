import { FeatureToggle } from 'core/dtos';
import { DateString } from 'core/models';

export type EnableNavigationLayerDto = FeatureToggle;
export type EnableVehicleSmoothingSettingsDto = FeatureToggle;
export type AllowedNumberOfZoneSetsDto = {
  allowedNumberOfZoneSets: number;
  dateUpdated: DateString | null;
};

export interface MapManagerFeatures {
  enableVehicleSmoothingToggle: EnableVehicleSmoothingSettingsDto;
  allowedNumberOfZoneSets: AllowedNumberOfZoneSetsDto;
}

export function getDefaultMapManagerFeatures(): MapManagerFeatures {
  return {
    enableVehicleSmoothingToggle: {
      isToggledOn: true,
      dateUpdated: null,
    },
    allowedNumberOfZoneSets: {
      allowedNumberOfZoneSets: 10,
      dateUpdated: null,
    },
  };
}
