import { Dictionary } from '@ngrx/entity';
import { EMPTY_GUID } from 'core/constants';
import { MapDto, PoiDto, PoiGroupAlternatingDto, PoiGroupDto } from 'core/dtos';
import {
  GuidString,
  MapWithPoiGroups,
  Poi,
  PoiDeviceOccupancy,
  PoiGroup,
  PoiGroupMappingTable,
  PoiGroupStrategy,
  PoiGroupTreeTable,
  PoiTreeTable,
} from 'core/models';
import { FAKE_PARENT_ID } from 'library/helpers';
import { groupBy } from 'lodash';

export const createPoiGroupList = (poiGroups: PoiGroup[], pois: Poi[]): PoiGroup[] => {
  const groupedPois = groupBy(pois, 'poiGroupId');

  poiGroups = poiGroups.map(poiGroup => ({
    ...poiGroup,
    pointsOfInterest:
      groupedPois[poiGroup.id.toString()]?.filter(
        o => !poiGroup?.bufferPlaces?.find(b => b.id === o.id)
      ) || [],
  }));

  if (groupedPois.undefined?.length) {
    poiGroups.push({
      id: FAKE_PARENT_ID,
      mapId: EMPTY_GUID,
      name: 'shared.treeNav.noPoiGroupAssigned',
      pointsOfInterest: groupedPois.undefined,
      strategy: PoiGroupStrategy.Single,
    });
  }

  return poiGroups;
};

export const createMapsList = (maps: MapDto[], poiGroups: PoiGroup[]): MapWithPoiGroups[] => {
  const fakePoiGroup = poiGroups.find(pg => pg.id === FAKE_PARENT_ID) as PoiGroup;

  return maps.map(map => ({
    ...map,
    poiGroups: [
      ...poiGroups.filter(pg => pg.mapId === map.id),
      ...getFakePoiGroup(fakePoiGroup, map.id),
    ],
  }));
};

export const createPoiGroupForTreeTable = (
  mapId: GuidString,
  poiGroups: PoiGroup[]
): PoiGroupTreeTable[] => {
  if (mapId) {
    poiGroups = poiGroups
      .filter(poiGroup => poiGroup.mapId === mapId || poiGroup.mapId === EMPTY_GUID)
      .map(poiGroup => ({
        ...poiGroup,
        pointsOfInterest: poiGroup.pointsOfInterest
          .filter(poi => poi.mapId === mapId)
          .filter(o => !poiGroup?.bufferPlaces?.find(b => b.id === o.id)),
      }));
  }

  return poiGroups.map(createPoiGroupTreeTable);
};

export const createPoiGroupForMappingTable = (
  poiGroups: PoiGroupDto[],
  pois: PoiDto[]
): PoiGroupMappingTable[] => {
  const groupedPois = groupBy(pois, 'poiGroupId');

  const poiList = pois.filter(poi => !poi.poiGroupId).map(createPoiMappingTable);

  const poiGroupList = poiGroups.map(createPoiGroupMappingTable.bind(undefined, groupedPois));

  return [...poiList, ...poiGroupList];
};

export const createPoiGroupForNotificationMappingTable = (
  _poiGroups: PoiGroupDto[],
  pois: PoiDto[]
): PoiGroupMappingTable[] => {
  const poiList = pois.map(createPoiMappingTable);
  return [...poiList];
};

const getFakePoiGroup = (poiGroup: PoiGroup, mapId: GuidString) => {
  const pointsOfInterest = poiGroup
    ? poiGroup.pointsOfInterest.filter(poi => poi.mapId === mapId)
    : [];

  return pointsOfInterest.length ? [{ ...poiGroup, pointsOfInterest }] : [];
};

export const createPoiGroupTreeTable = (poiGroup: PoiGroup): PoiGroupTreeTable => ({
  id: poiGroup.id,
  name: poiGroup.name,
  mapId: poiGroup.mapId,
  children: poiGroup.pointsOfInterest.map(createPoiTreeTable.bind(null, poiGroup)),
});

export const createPoiTreeTable = (poiGroup: PoiGroup, poi: Poi): PoiTreeTable => ({
  id: poi.id,
  name: poi.name,
  mapId: poi.mapId,
  mapName: poi.map?.name ?? '',
  type: poi.type,
  loadType: poi.loadType,
  occupancy: poi.occupancy?.occupancyStatus,
  booked: poi.booked ? 2 : 1,
  poiGroupId: poiGroup.id,
  deviceOccupancy:
    poi.deviceOccupancy === PoiDeviceOccupancy.Unknown ? undefined : poi.deviceOccupancy,
  strategy: poiGroup.strategy,
  isNextPoi:
    poiGroup.strategy === PoiGroupStrategy.Alternating
      ? (poiGroup as PoiGroupAlternatingDto).nextPointOfInterestIdToUse === poi.id
      : false,
  isSensorMonitored: poi.occupancyDevice !== null,
});

export const createPoiMappingTable = (poi: PoiDto): PoiGroupMappingTable => ({
  id: poi.id,
  mapId: poi.mapId,
  name: poi.name,
  type: poi.type,
  isMapped: false,
  isSingle: true,
});

export const createPoiGroupMappingTable = (
  pois: Dictionary<PoiDto[]>,
  poiGroup: PoiGroup
): PoiGroupMappingTable => ({
  id: poiGroup.id,
  mapId: poiGroup.mapId,
  name: poiGroup.name,
  isMapped: false,
  isSingle: false,
  type: (pois[poiGroup.id.toString()] || [])[0]?.type,
});
