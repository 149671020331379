import { PoiType } from 'core/models';
import { POILayerImages } from '../map-layer-images.constant';

export const PoiIcon: Partial<Record<PoiType, string>> = {
  [PoiType.AutomaticConveyorLoading]: POILayerImages.POI_AutomaticConveyor,
  [PoiType.ChargingStation]: POILayerImages.POI_ChargingStation,
  [PoiType.DollyPlace]: POILayerImages.POI_DollyPlace,
  [PoiType.ParkingSpace]: POILayerImages.POI_ParkingSpace,
  [PoiType.ParkingSpaceLarge]: POILayerImages.POI_ParkingSpaceLarge,
  [PoiType.TurnTableLarge]: POILayerImages.POI_TurnTableLarge,
  [PoiType.TurnTableSmall]: POILayerImages.POI_TurnTableSmall,
  [PoiType.Handover]: POILayerImages.POI_Handover,
  [PoiType.AssignableWaypoint]: POILayerImages.POI_AssignableWaypoint,
  [PoiType.TripleTurnTable]: POILayerImages.POI_TripleTurnTable,
  [PoiType.PalletStationCrosswise]: POILayerImages.POI_PalletStationCrosswise,
  [PoiType.PalletStationLengthwise]: POILayerImages.POI_PalletStationLengthwise,
  [PoiType.RackChangerDropoff]: POILayerImages.POI_RackChangerDropoff,
  [PoiType.RackChangerPickup]: POILayerImages.POI_RackChangerPickup,
  [PoiType.PrePositionPoi]: POILayerImages.POI_PrePositioning,
};

export const PoiBases: Partial<Record<PoiType, string>> = {
  [PoiType.WayPoint]: POILayerImages.POI_WayPoint,
  [PoiType.TripleTurnTable]: POILayerImages.POI_SquareBox,
  [PoiType.ParkingSpaceLarge]: POILayerImages.POI_LargeBox,
};
